import React from 'react';
import { BaseSidebarFilter, SidebarModalFilter } from '../types';
import { FilterGroup } from '../FilterGroup';
import { DateRangeFilter } from '../../DateRangeFilter/DateRangeFilter';
import { DateRangeTableFilter } from '../../types';

type DateFilterGroupProps = BaseSidebarFilter &
  SidebarModalFilter & {
    onChange: DateRangeTableFilter['onChange'];
    value: DateRangeTableFilter['value'];
    label: string;
    placeholder?: string;
  };

export const DateFilterGroup = ({
  label,
  modalAnchorEl,
  modalStyle,
  onChange,
  value,
  placeholder,
}: DateFilterGroupProps) => {
  return (
    <FilterGroup label={label}>
      <DateRangeFilter
        field={label}
        showFieldLabelWhenFilled={false}
        placeholder={placeholder ?? 'Select a Date Range'}
        style={modalStyle}
        modalAnchorEl={modalAnchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onChange={onChange}
        value={value}
      />
    </FilterGroup>
  );
};
