import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { Box } from '@material-ui/core';
import React from 'react';
import { UpdateButton } from './UpdateButton';
import { PromoteToReleaseButton } from './PromoteToReleaseButton';
import { RowMenu } from './RowMenu';

interface Props {
  release: ReleaseAndDeployments;
}

export const ReleaseActions: React.FC<Props> = ({ release }) => {
  const isSnapshot = release.metadata.isSnapshot;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      style={{ gap: 8 }}
    >
      {isSnapshot && (
        <>
          <PromoteToReleaseButton release={release} />
          <UpdateButton release={release} />
        </>
      )}
      <RowMenu release={release} />
    </Box>
  );
};
