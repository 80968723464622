import React from 'react';
import {
  TableCellProps,
  WbTableWithFilters,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { RoleDetails } from '@agilelab/plugin-wb-rbac-common';
import { usePermissionDrawerContext } from '../Context/PermissionDrawerTableContext';

const useTableColumnSpecs = (): Array<TableCellProps<RoleDetails>> => {
  const truncatedClass = useTruncatedStyles();

  return [
    {
      field: 'display_name',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Permission',
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Description',
    },
  ];
};

export const PermissionsDrawerTable = () => {
  const {
    roles,
    setPagination,
    pagination,
    rolesCount,
    filters,
    changeFilters,
  } = usePermissionDrawerContext();

  return (
    <WbTableWithFilters<RoleDetails>
      containerStyle={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
      }}
      filters={{
        searchValue: filters.text || '',
        onSearchChange: (search: string) => {
          changeFilters('text', search);
        },
        activeFilters: 0,
        onFiltersClick: () => {},
        show: false,
      }}
      table={{
        components: {
          tableLoader: { loading: false },
          tableContent: {
            columns: useTableColumnSpecs(),
            rows: roles,
          },
        },
        pagination: {
          count: rolesCount || 0,
          limit: pagination.limit,
          offset: pagination.offset,
          onPageChange: (page: number) => {
            setPagination({
              ...pagination,
              offset: page * pagination.limit,
            });
          },
          onRowsPerPageChange: (rowsPerPage: number) => {
            setPagination({
              offset: 0,
              limit: rowsPerPage,
            });
          },
        },
      }}
    />
  );
};
