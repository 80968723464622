import { useApi } from '@backstage/core-plugin-api';
import React, { useCallback, useState } from 'react';
import { panelCatalogApiRef } from '../../../../api';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import {
  generateURNByKind,
  ProvisioningPreviewResponse,
} from '@agilelab/plugin-wb-builder-common';
import yaml from 'yaml';
import { Target } from '@agilelab/plugin-wb-builder-backend';

export type DeploymentPreviewContextProps = {
  isAdvanced: boolean;
  setIsAdvanced: React.Dispatch<React.SetStateAction<boolean>>;

  checkPreview: (
    targetStatus: Target[],
  ) => Promise<ProvisioningPreviewResponse>;
  partialProvision: (
    targetStatus: Target[],
  ) => Promise<ProvisioningPreviewResponse>;
};

export const DeploymentPreviewContext =
  React.createContext<DeploymentPreviewContextProps>(
    {} as DeploymentPreviewContextProps,
  );

type Props = {
  children?: React.ReactNode;
};

export const DeploymentPreviewContextProvider: React.FC<Props> = ({
  children,
}) => {
  const { environment, queryParamEnvironment, release, currentDescriptor } =
    useReleaseDetailPageContext();
  const [isAdvanced, setIsAdvanced] = useState<boolean>(false);
  const panelCatalogApi = useApi(panelCatalogApiRef);

  const deploymentUnitId = generateURNByKind(
    release.metadata.projectName ?? release.metadata.dataProductName,
    release.metadata.projectKind ?? 'system',
  );
  const selectedEnvironment = queryParamEnvironment || environment.name;

  const checkPreview = useCallback(
    async (targetStatus: Target[]) =>
      panelCatalogApi.getDeploymentUnitsProvisioningPreview(
        deploymentUnitId,
        selectedEnvironment,
        {
          descriptor: yaml.parse(currentDescriptor),
          targetStatus,
          removeData: false,
          preventRedeploy: false,
        },
      ),
    [currentDescriptor, deploymentUnitId, panelCatalogApi, selectedEnvironment],
  );

  const partialProvision = useCallback(
    async (targetStatus: Target[]) =>
      panelCatalogApi.partialProvision(
        deploymentUnitId,
        selectedEnvironment,
        {
          descriptor: yaml.parse(currentDescriptor),
          targetStatus,
          removeData: false,
          preventRedeploy: false,
        },
        false,
      ),
    [currentDescriptor, deploymentUnitId, panelCatalogApi, selectedEnvironment],
  );

  return (
    <DeploymentPreviewContext.Provider
      value={{
        isAdvanced,
        setIsAdvanced,

        checkPreview,
        partialProvision,
      }}
    >
      {children}
    </DeploymentPreviewContext.Provider>
  );
};
