/*
Custom Presentation Retriver
*/
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import yaml from 'yaml';

export type CustomPresentationRetrieverApi = {
  retrieve(id: string, typeId?: string, templateId?: string): Promise<any>;
};

export class CustomPresentationRetriever {
  static cacheDownloads: Record<string, Promise<Response>> = {};
  static cacheText: Record<string, Promise<string>> = {};
  static cache: Record<string, any> = {};
  private readonly discoveryApi;
  private readonly fetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  getIncludes(view: any) {
    const getIncludes: Function = this.getIncludes.bind(this);
    return (view.children || [])
      .filter(
        (node: any) =>
          (node.type || '') === 'include' || (node.include || '') !== '',
      )
      .concat(
        (view.children || [])
          .map(getIncludes)
          .reduce((a: [], b: []) => a.concat(b), []),
      );
  }

  async retrieve(
    id: string,
    typeId: string = '',
    templateId: string = '',
  ): Promise<any> {
    const getIncludes: Function = this.getIncludes.bind(this);
    const retrieve: Function = this.retrieve.bind(this);

    const baseUrl = await this.discoveryApi.getBaseUrl('platform');

    const cacheKey = [id, typeId, templateId].filter(a => !!a).join('/');

    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (!(cacheKey in CustomPresentationRetriever.cacheDownloads)) {
      const url = `${baseUrl}/custom_presentation/${cacheKey}`;
      CustomPresentationRetriever.cacheDownloads[cacheKey] =
        this.fetchApi.fetch(url);
    }
    const result: Response | undefined =
      await CustomPresentationRetriever.cacheDownloads[cacheKey].catch(
        () => undefined,
      );
    // if another call already resolved the processing
    if (cacheKey in CustomPresentationRetriever.cache)
      return CustomPresentationRetriever.cache[cacheKey];
    if (result) {
      if (result.status === 200) {
        if (!(cacheKey in CustomPresentationRetriever.cacheText))
          CustomPresentationRetriever.cacheText[cacheKey] = result.text();
        const data = yaml.parse(
          await CustomPresentationRetriever.cacheText[cacheKey],
        );
        CustomPresentationRetriever.cache[cacheKey] = data.view;
        await Promise.all(
          (
            await Promise.all(getIncludes(data.view))
          ).map(async (subView: any) => {
            const subnode = await retrieve(
              subView.id || subView.include,
              subView.typeId === undefined ? typeId : subView.typeId,
              subView.templateId === undefined
                ? templateId
                : subView.templateId,
            );
            if (subnode) {
              subView.children = subnode.children;
            }
            return true;
          }),
        );
        return data.view;
      }
    }
    return null;
  }
}
