import React from 'react';
import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core';
import { FilterGroup } from '../FilterGroup';
import { BaseSidebarFilter } from '../types';

type BooleanFilterGroupProps = BaseSidebarFilter & SwitchProps;

export const BooleanFilterGroup = ({
  label,
  ...switchProps
}: BooleanFilterGroupProps) => {
  return (
    <FilterGroup label={label}>
      <FormControlLabel
        control={<Switch {...switchProps} />}
        label={`Show only ${label}`}
      />
    </FilterGroup>
  );
};
