import { NotificationType } from '@agilelab/plugin-wb-platform-common';

export type ACLEntity = {
  refs: string;
  id_output_port: number;
  locked: boolean;
};

export interface ACLFilters {
  kind?: string;
  access?: string[];
  status?: string[];
}

export interface ACLByRefFilters {
  type?: string[];
  outputPort?: string[];
  access?: string[];
  status?: string[];
}

export type AccessAuthorizationEntity = {
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
  status: string;
};

export type AccessControlRequest = {
  identities: string[];
  requester: string;
  motivation: string;
  outputport: {
    name: string;
    output_port_type: string;
  };
  dataproduct: {
    id: number;
    name: string;
    display_name?: string;
    domain: string;
    version: string;
    data_product_owner: string;
    data_product_display_name: string;
  };
  environment: string;
  id_output_port: number;
  requester_display_name: string;
  id_dataproduct_instance: number;
  displayFields: AccessControlDisplayField[];
};

export type AccessControlResponse = AccessControlRequest & {
  is_requester: boolean;
  status: 'SUCCESS' | 'FAILURE' | 'REJECTED';
};

export enum AccessControlRequestStatus {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export type AccessControlAuthorization = {
  status: AccessControlRequestStatus;
  timestamp: string;
  authorized_by: string;
  authorized_by_display_name: string;
};

export type AccessRequestsList = {
  AccessRequest: AccessRequestEntity[];
};

export type AccessRequestEntity = {
  id: number;
  idOutputPort: number;
  accessAuthorization?: Partial<AccessAuthorizationEntity>;
};

export type AccessRequestNotification = Omit<
  Notification,
  'kind | notification_request | notification_response'
> & {
  kind: NotificationType.ACCESS_REQUEST;
  notification_request: AccessControlRequest;
  notification_response: AccessControlAuthorization | null;
};

export type AccessControlDisplayField = {
  title: string;
  text?: string;
  textList?: string[];
  attachment?: AccessControlAttachment;
};

export type AccessControlAttachment = {
  fileName: string;
  fileContent: string;
};

export type AclEntity = {
  refs: string;
  port_id: number;
  locked: boolean;
};
