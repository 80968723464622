import {
  Environment,
  handleFailedResponse,
} from '@agilelab/plugin-wb-platform-common';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

type Credentials = {
  token?: string;
};

export interface EnvironmentApi {
  getEnvironments(credentials: Credentials): Promise<Environment[]>;
}

export class DefaultEnvironmentApi implements EnvironmentApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('environment');
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  async getEnvironments(credentials: Credentials): Promise<Environment[]> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/environments`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${credentials.token}`,
        'Content-Type': 'application/json',
      },
    });
    await handleFailedResponse(response);

    return response.json();
  }
}
