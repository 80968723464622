import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useMarketplaceStyle } from '../../themes';
import { stringAvatar } from '../../utils';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      rating: {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize * 1.5,
      },
      reviewsButton: {
        marginRight: theme.spacing(2),
      },
      table: {
        '& td': {
          padding: '0.5rem 0.5rem',
        },
      },
      input: {
        '&::placeholder': {
          fontSize: theme.typography.fontSize,
        },
      },
      actionsContainer: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
      },
    }),
  { name: 'Question form' },
);

export type QuestionFormProps = {
  askedByDisplayName: string;
  onCancel: any;
  onSubmit: any;
  errorText?: string;
};

export const QuestionForm = (props: QuestionFormProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const commonClasses = useMarketplaceStyle();

  const errorBox =
    props.errorText && props.errorText.length > 0 ? (
      <Grid item xs>
        <Alert severity="error" className={commonClasses.text}>
          {props.errorText}
        </Alert>
      </Grid>
    ) : (
      ''
    );

  return (
    <Card
      elevation={0}
      style={{
        borderTop: '1px solid lightgray',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      }}
    >
      <form onSubmit={props.onSubmit}>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Grid container direction="column">
                <Grid item xs>
                  <Typography className={commonClasses.text}>
                    Ask data product owner about any aspect of this data
                    product. You will be notified when the DPO answers your
                    question. After answering, your question will be visible to
                    all users.
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Avatar {...stringAvatar(props.askedByDisplayName)} />
                    </Grid>
                    <Grid item>
                      <Typography className={commonClasses.text}>
                        {props.askedByDisplayName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <TextField
                    id="new-question-body"
                    name="new-question-body"
                    type="text"
                    required
                    variant="filled"
                    multiline
                    rows={6}
                    style={{ width: '100%' }}
                    label="Ask a question"
                    InputProps={{
                      style: { fontSize: theme.typography.fontSize },
                    }}
                    InputLabelProps={{
                      style: { fontSize: theme.typography.fontSize },
                    }}
                  />
                </Grid>
                {errorBox}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actionsContainer}>
          <WbCardActionButton
            label="Cancel"
            variant="outlined"
            onClick={props.onCancel}
          />
          <WbCardActionButton
            type="submit"
            label="Send"
            variant="contained"
            onClick={props.onCancel}
          />
        </CardActions>
      </form>
    </Card>
  );
};
