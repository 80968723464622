import { ThemeProvider, useTheme } from '@material-ui/core';
import React from 'react';

export interface ScaffolderThemeProviderProps {
  children: React.ReactNode;
  autoWidth?: boolean;
}

export const ScaffolderThemeProvider: React.FC<
  ScaffolderThemeProviderProps
> = ({ children, autoWidth = false }) => {
  const theme = useTheme();
  return (
    <ThemeProvider
      theme={{
        ...theme,
        overrides: {
          ...theme.overrides,
          MuiFormHelperText: {
            contained: {
              marginLeft: 0,
              marginRight: 0,
            },
          },
          MuiFormControl: {
            root: {
              width: autoWidth ? 'auto' : '100%',
              height: '100%',
            },
          },
          MuiTypography: {
            h1: {
              fontWeight: 900,
            },
            h2: {
              fontWeight: 800,
            },
            h3: {
              fontWeight: 700,
            },
            h4: {
              fontWeight: 600,
            },
            h5: {
              fontWeight: 500,
            },
            h6: {
              fontWeight: 400,
            },
          },
        },
      }}
    >
      {children}
    </ThemeProvider>
  );
};
