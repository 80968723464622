import React from 'react';
import {
  Box,
  Button,
  DialogActions,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core';
import { WbCardContent } from '../WbCard';
import { WbCardActionButton } from '../WbCardActionButton';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '../WbDockable';
import { SelectedValues } from './SelectedEntities';
import { useMultiSelectionDialogPickerContext } from './MultiSelectionPickerDialogContext';
import { AiIcon } from '../Icons/AiIcon';
import { BooleanFilter } from '../WbTableFilters';

interface DialogContentProps {
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  children: React.ReactNode;
  title: string;
  aiEnabled: boolean;
}

const useStyles = makeStyles(
  theme => ({
    cardContent: {
      padding: theme.spacing(0),
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    childrenBox: {
      paddingTop: theme.spacing(1),
      overflow: 'auto',
    },
    aiFilterButton: {
      marginTop: theme.spacing(1),
      width: 'fit-content',
      border: 'solid 1px transparent',
      borderRadius: '4px',
      background:
        'linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, #EE72D3, #7C4DFF) border-box',
    },
  }),
  { name: 'DialogContent' },
);

export function MultiSelectionDialogContent(props: DialogContentProps) {
  const classes = useStyles();
  const theme = useTheme();
  const { setOpenDialog, children, title, aiEnabled } = props;
  const {
    setSelectedValues,
    contextSelected,
    setContextSelected,
    fetchAiBestMatch,
    aiState,
    setAiState,
  } = useMultiSelectionDialogPickerContext();

  return (
    <WbCardContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '65vh',
        padding: 0,
      }}
    >
      <WbDockableGroup direction="horizontal">
        <WbDockablePanel defaultSize={70}>
          <WbCardContent className={classes.cardContent}>
            <Typography variant="body1" style={{ fontWeight: '700' }}>
              Search for {title}
            </Typography>

            {aiEnabled && (
              <BooleanFilter
                className={classes.aiFilterButton}
                color={theme.palette.accent.main}
                startIcon={
                  <AiIcon style={{ color: theme.palette.accent.main }} />
                }
                value={aiState}
                field="AI Best Match"
                onChange={checked => {
                  if (checked) {
                    fetchAiBestMatch();
                  }
                  setAiState(checked ?? false);
                }}
              />
            )}

            <Box className={classes.childrenBox}>{children}</Box>
          </WbCardContent>
        </WbDockablePanel>

        <WbDockableResizeHandle
          showIconOnHover
          divider
          size={4}
          direction="horizontal"
        />

        <WbDockablePanel maxSize={30}>
          <SelectedValues
            values={contextSelected}
            setValues={setContextSelected}
          />
        </WbDockablePanel>
      </WbDockableGroup>
      <DialogActions
        style={{
          height: '56px',
          position: 'sticky',
          bottom: 0,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.white,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Cancel
        </Button>
        <WbCardActionButton
          onClick={() => {
            setSelectedValues(contextSelected);
            setOpenDialog(false);
          }}
          variant="contained"
          size="small"
          color="primary"
          label="Apply"
        />
      </DialogActions>
    </WbCardContent>
  );
}
