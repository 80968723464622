import { useCallback, useEffect, useRef } from 'react';
import { Log } from '../types';

type LogTimestampRegistry = { [taskName: string]: Date };

export type PollingConfig = {
  /**
   * It will be called within each polling cycle to fetch for logs.
   * Logs of a certain task (`task` field in the `Log` object) with a timestamp later
   * than the last timestamp seen by the logging session (in a previous polling cycle) on that task, will be ignored:
   * i.e. the polling session keeps a record of the last timestamp seen for each task
   */
  fetchLogs: () => Promise<Log[]>;
  /**
   * Logs with a timestamp less than this will be ignored
   */
  minTimestamp?: Date;
};

/**
 * Utility to interact with a general-purpose, customizable, logs polling mechanism
 */
export const useLogsPolling = (props: {
  /**
   * Minimum time-distance (milliseconds) between a polling cycle and the next one.
   * Note that this is not a fixed interval; actual interval lengths depend on the duration of each cycle:
   * a cycle starts only when the previous one is terminated.
   */
  pollingIntervalMs: number;
  /**
   * Called when new logs, that have never been emitted since the polling session has started, are available
   */
  onNewLogs: (newLogs: Log[]) => Promise<any>;
  /**
   * Called in case of errors during the execution of a polling session
   */
  onPollingError: (error: Error) => Promise<any>;
}): {
  /**
   * Starts a new polling session with the provided configurations.
   * When a session is already running, it will be stopped before starting the new one.
   */
  start: (config: PollingConfig) => Promise<void>;
  /**
   * Stops the current running polling session and resets any reference to last seen timestamps
   */
  stopAndReset: () => Promise<void>;
} => {
  const { pollingIntervalMs, onNewLogs, onPollingError } = props;
  const pollingSessionId = useRef<number>();
  const isFetching = useRef(false);
  const lastSeenLogTimestamps = useRef<LogTimestampRegistry>({});
  const timerRef = useRef<NodeJS.Timer>();

  /**
   * Executes the provided callback only if the calling session is the current polling session.
   * This avoids the execution of callbacks called by tasks still running from a previous polling session
   * (this could happen when a session is stopped and immediately started again)
   */
  const sessionGuardedCallback = (
    callingSession: number,
    callback: () => any,
  ) => {
    if (callingSession === pollingSessionId.current) {
      callback();
    }
  };

  const reset = () => {
    lastSeenLogTimestamps.current = {};
  };

  const stopAndReset = useCallback(async () => {
    if (pollingSessionId.current) {
      pollingSessionId.current = undefined;
      if (timerRef.current) {
        clearInterval(timerRef.current);
        timerRef.current = undefined;
      }
      reset();
    }
  }, []);

  const maxDate = (d1: Date, d2: Date) => {
    return d1 > d2 ? d1 : d2;
  };

  /**
   * Filters new logs and updates the log timestamp registry
   */
  const detectNewLogs = useCallback(
    (logs: Log[], minTimestamp: Date): Log[] => {
      const lastSeenTimestampUpdates: LogTimestampRegistry = {};
      const newLogs = logs.filter((log: Log) => {
        const taskName = log.task ?? '';
        const lastSeenTimestamp = lastSeenLogTimestamps.current[taskName];
        const timestamp = new Date(log.timestamp);
        if (
          timestamp >= minTimestamp &&
          (!lastSeenTimestamp || timestamp > lastSeenTimestamp)
        ) {
          lastSeenTimestampUpdates[taskName] = maxDate(
            timestamp,
            lastSeenTimestampUpdates[taskName] ?? new Date(0),
          );
          return true;
        }
        return false;
      });
      lastSeenLogTimestamps.current = {
        ...lastSeenLogTimestamps.current,
        ...lastSeenTimestampUpdates,
      };
      return newLogs;
    },
    [],
  );

  /**
   * @param pollingSession id of the polling session this function has been called from
   */
  const fetchAndEmitNewLogs = useCallback(
    async (configs: PollingConfig, pollingSession: number) => {
      // Avoid overlaps with other fetching tasks
      if (isFetching.current) return;
      isFetching.current = true;
      try {
        const planLogs = await configs.fetchLogs();
        const newLogs = detectNewLogs(
          planLogs,
          configs.minTimestamp ?? new Date(0),
        );
        if (newLogs.length > 0) {
          sessionGuardedCallback(pollingSession, () => onNewLogs(newLogs));
        }
      } catch (error) {
        sessionGuardedCallback(pollingSession, () => {
          onPollingError(error);
          stopAndReset();
        });
      } finally {
        isFetching.current = false;
      }
    },
    [detectNewLogs, onNewLogs, onPollingError, stopAndReset],
  );

  const start = useCallback(
    async (config: PollingConfig) => {
      // Stop any polling session that is currently running
      if (pollingSessionId.current) await stopAndReset();
      // Start a new polling session
      const newSessionId = new Date().getTime();
      pollingSessionId.current = newSessionId;
      timerRef.current = setInterval(
        () => fetchAndEmitNewLogs(config, newSessionId),
        pollingIntervalMs,
      );
    },
    [fetchAndEmitNewLogs, pollingIntervalMs, stopAndReset],
  );

  useEffect(() => {
    return () => timerRef.current && clearInterval(timerRef.current);
  }, []);

  return { start, stopAndReset };
};
