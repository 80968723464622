import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const CollapseDockablePanelIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-882.5 -1623.461)">
      <path
        d="M11.008,11.008V.786L7.076,4.718,2.359,0,0,2.359,4.718,7.076.786,11.008Z"
        transform="translate(906.5 1647.461) rotate(180)"
      />
      <path
        d="M22.578,21.936V11.714l-3.931,3.931-4.718-4.718-2.359,2.359L16.288,18l-3.931,3.931Z"
        transform="translate(870.929 1612.533)"
      />
    </g>
  </svg>,
  'CollapseDockablePanelIcon',
);
