/*
 * This class is copied from Backstage 1.24.0.
 * CHANGES:
 * - removed the card wrapper: this is will be a part fo the card above
 * - removed 'variant' attribute since it was needed for the card component
 * - added the defaultStepper attribute to the props (needed for reuse of the component)
 */

import { InfoCardVariants } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo } from 'react';
import { catalogImportApiRef } from '../../api';
import { ImportFlows, ImportState, useImportState } from '../useImportState';
import {
  defaultGenerateStepper,
  StepConfiguration,
  StepperProvider,
} from './defaults';

const useStyles = makeStyles(theme => ({
  stepperRoot: {
    padding: 0,
    background: theme.palette.white,
  },
}));

/**
 * Props for {@link ImportStepper}.
 *
 * @public
 */
export interface ImportStepperProps {
  initialUrl?: string;
  generateStepper?: (
    flow: ImportFlows,
    defaults: StepperProvider,
  ) => StepperProvider;
  defaultStepper: StepperProvider;
  variant?: InfoCardVariants;
}

/**
 * The stepper that holds the different import stages.
 *
 * @public
 */
export const ImportStepper = (props: ImportStepperProps) => {
  const {
    initialUrl,
    generateStepper = defaultGenerateStepper,
    defaultStepper,
  } = props;

  const catalogImportApi = useApi(catalogImportApiRef);
  const classes = useStyles();
  const state = useImportState({ initialUrl });

  const states = useMemo<StepperProvider>(
    () => generateStepper(state.activeFlow, defaultStepper),
    [defaultStepper, generateStepper, state.activeFlow],
  );

  const render = (step: StepConfiguration) => {
    return (
      <Step>
        {step.stepLabel}
        <StepContent>{step.content}</StepContent>
      </Step>
    );
  };

  return (
    <Stepper
      classes={{ root: classes.stepperRoot }}
      activeStep={state.activeStepNumber}
      orientation="vertical"
    >
      {render(
        states.analyze(
          state as Extract<ImportState, { activeState: 'analyze' }>,
          { apis: { catalogImportApi } },
        ),
      )}
      {render(
        states.prepare(
          state as Extract<ImportState, { activeState: 'prepare' }>,
          { apis: { catalogImportApi } },
        ),
      )}
      {render(
        states.review(
          state as Extract<ImportState, { activeState: 'review' }>,
          { apis: { catalogImportApi } },
        ),
      )}
      {render(
        states.finish(
          state as Extract<ImportState, { activeState: 'finish' }>,
          { apis: { catalogImportApi } },
        ),
      )}
    </Stepper>
  );
};
