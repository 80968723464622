/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Box } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { DismissableBanner } from '@backstage/core-components';

type TaskErrorsProps = {
  error?: Error;
};
const isJson = (error: string): boolean => {
  try {
    JSON.parse(error);
  } catch {
    return false;
  }
  return true;
};

export const TaskErrors = ({ error }: TaskErrorsProps) => {
  const id = useRef('');
  const [formattedError, setFormattedError] = useState<string | undefined>();
  useEffect(() => {
    id.current = String(Math.random());
  }, [error]);

  // Needed to handle nested Backstage errors coming for instance from the Backstage register action
  useEffect(() => {
    if (!error) {
      return undefined;
    }

    if (isJson(error.message)) {
      const jsonError = JSON.parse(error.message);
      if (jsonError?.error?.message && isJson(jsonError.error.message)) {
        const nestedJsonError = JSON.parse(jsonError.error.message);
        if (nestedJsonError?.error?.message) {
          setFormattedError(nestedJsonError.error.message);
          return undefined;
        }
      }
      setFormattedError(jsonError.error.message);
      return undefined;
    }

    setFormattedError(error.message);
    return undefined;
  }, [error]);

  if (!error) return null;

  return (
    <Box>
      <DismissableBanner
        id={id.current}
        variant="error"
        message={formattedError}
      />
    </Box>
  );
};
