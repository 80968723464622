import { styled } from '@material-ui/core';
import React from 'react';
import { IconType } from '../types';
import { WbSidebarItem } from './WbSidebarItem';
import NotificationsIcon from '@material-ui/icons/Notifications';

type WbSidebarNotificationsItemProps = {
  to: string;
  icon?: IconType;
  text?: string;
  unreadNotifications?: number;
};

const NotificationsBadge = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.accent.main,
  color: theme.palette.white,
  borderRadius: '4px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '24px',
  width: '32px',
  fontSize: theme.typography.body2.fontSize,
}));

export const WbSidebarNotificationsItem = ({
  unreadNotifications,
  icon = NotificationsIcon,
  text = 'Notifications',
  ...props
}: WbSidebarNotificationsItemProps) => {
  return (
    <WbSidebarItem
      hasNotifications={!!unreadNotifications}
      icon={icon}
      text={text}
      {...props}
    >
      <NotificationsBadge>{unreadNotifications}</NotificationsBadge>
    </WbSidebarItem>
  );
};
