import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const ForceTerminateIcon = createSvgIcon(
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6122 3L3 10.6122V21.3878L10.6122 29H21.3878L29 21.3878V10.6122L21.3878 3M10.8144 8.77778L16 13.9633L21.1856 8.77778L23.2222 10.8144L18.0367 16L23.2222 21.1856L21.1856 23.2222L16 18.0367L10.8144 23.2222L8.77778 21.1856L13.9633 16L8.77778 10.8144"
        fill="currentColor"
      />
    </svg>
  </>,
  'ForceTerminateIcon',
);
