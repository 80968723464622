import React from 'react';
import { useTheme } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import InfoIcon from '@material-ui/icons/Info';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  NotBlockingErrorIcon,
  RunningIcon,
  TerminatingIcon,
} from '../ProvisioningCard/LatestProvisioningAccordion/Icons';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';

export function useStepIcons(): Record<DagStatus, JSX.Element> {
  const theme = useTheme();
  return {
    NOT_BLOCKING_ERROR: (
      <NotBlockingErrorIcon
        style={{
          color: theme.palette.error.main,
          stroke: theme.palette.error.main,
        }}
      />
    ),
    OK: (
      <CheckCircleOutlineIcon style={{ color: theme.palette.success.main }} />
    ),
    FAILED: (
      <RadioButtonCheckedIcon style={{ color: theme.palette.error.main }} />
    ),
    INFO: <InfoIcon color="primary" />,
    WARNING: <ErrorOutlineIcon style={{ color: theme.palette.warning.main }} />,
    RUNNING: <RunningIcon style={{ color: theme.palette.accent.main }} />,
    WAITING: <RunningIcon style={{ color: theme.palette.grey[500] }} />,
    COMPLETED: (
      <CheckCircleOutlineIcon style={{ color: theme.palette.success.main }} />
    ),
    NOT_EXECUTED: (
      <RemoveCircleIcon style={{ color: theme.palette.grey[500] }} />
    ),
    NOT_STARTED: (
      <RemoveCircleIcon style={{ color: theme.palette.grey[500] }} />
    ),
    ERROR: (
      <RadioButtonCheckedIcon style={{ color: theme.palette.error.main }} />
    ),
    TERMINATED: (
      <HighlightOffIcon style={{ color: theme.palette.error.main }} />
    ),
    TERMINATING: (
      <TerminatingIcon style={{ color: theme.palette.error.main }} />
    ),
  };
}
