import React from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core';

export const SelectIcon: React.FC = (props: any) => {
  const theme = useTheme();
  return (
    <>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.grey[400]}`,
          height: 15,
          position: 'absolute',
          right: 35,
        }}
      />
      <ExpandMoreIcon {...props} style={{ transform: 'none' }} />
    </>
  );
};
