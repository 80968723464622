import {
  CompoundEntityRef,
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';

export function stringifyEntityRefSafe(
  ref:
    | Entity
    | {
        kind: string;
        namespace?: string;
        name: string;
      },
): string | undefined {
  try {
    const stringified = stringifyEntityRef(ref);
    return stringified;
  } catch (e) {
    return undefined;
  }
}

export function expandEntityRefSafe(ref: string): string | null {
  try {
    const expandedRef = expandEntityRef(ref);
    return expandedRef;
  } catch (e) {
    return null;
  }
}

/**
 * Generates a reference to an entity in the catalog that always has kind, namespace, name
 * @param ref - input reference to an entity
 * @returns reference to an entity with kind, namespace, name
 */
export function expandEntityRef(ref: string): string {
  return stringifyEntityRef(parseEntityRef(ref));
}

/**
 * Takes an entityRef and transforms it a compact entity ref like "user:name.surname.." used in the marketplace.
 *
 * @param entityRef
 */
export function compactStringRef(entityRef: string) {
  const compoundEntityRef = parseEntityRef(entityRef);
  return compactCompoundEntityRef(compoundEntityRef);
}

/**
 * Takes an entityRef and transforms it a compact entity ref like "user:name.surname.." used in the marketplace.
 *
 * @param compoundEntityRef
 */
export function compactCompoundEntityRef(compoundEntityRef: CompoundEntityRef) {
  return `${compoundEntityRef.kind.toLowerCase()}:${compoundEntityRef.name.toLowerCase()}`;
}
