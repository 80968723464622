import { z } from 'zod';

export const userAcceptanceZod = z.object({
  id: z.number().nonnegative(),
  document_id: z.number().nonnegative(),
  // Replace for this when we can test with dummy data
  // user_ref: z.preprocess(
  //     value => compactStringRef(value as string),
  //     z.string().regex(new RegExp('^user:.+$')),
  //   ),
  user_ref: z.string(),
  date: z.date().optional(),
});

export type UserAcceptance = z.infer<typeof userAcceptanceZod>;
