/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { catalogPlatformDeletePermission } from '@agilelab/plugin-wb-rbac-common';
import { IconComponent, useApi } from '@backstage/core-plugin-api';
import { catalogEntityDeletePermission } from '@backstage/plugin-catalog-common/alpha';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useEntityPermission } from '@backstage/plugin-catalog-react/alpha';
import { usePermission } from '@backstage/plugin-permission-react';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Tooltip,
} from '@material-ui/core';
import BugReportIcon from '@material-ui/icons/BugReport';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { useCallback, useState } from 'react';
import { UnregisterEntity, UnregisterEntityOptions } from './UnregisterEntity';

/** @public */
export type EntityContextMenuClassKey = 'button';

// NOTE(freben): Intentionally not exported at this point, since it's part of
// the unstable extra context menu items concept below
interface ExtraContextMenuItem {
  title: string;
  Icon: IconComponent;
  onClick: () => void;
  disabled?: boolean;
}

interface EntityContextMenuProps {
  UNSTABLE_extraContextMenuItems?: ExtraContextMenuItem[];
  UNSTABLE_contextMenuOptions?: UnregisterEntityOptions;
  onUnregisterEntity: () => void;
  onInspectEntity: () => void;
  additionalItems?: React.ReactNode;
}

export function EntityContextMenu(props: EntityContextMenuProps) {
  const {
    UNSTABLE_extraContextMenuItems,
    UNSTABLE_contextMenuOptions,
    onUnregisterEntity,
    onInspectEntity,
    additionalItems,
  } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const { entity } = useEntity();

  const unregisterPermission = useEntityPermission(
    catalogEntityDeletePermission,
  );

  const platformEntityDelete = usePermission({
    permission: catalogPlatformDeletePermission,
  }).allowed;

  const deletePermission =
    entity.kind === 'System' || entity.kind === 'Component'
      ? unregisterPermission.allowed
      : unregisterPermission.allowed && platformEntityDelete;

  const onOpen = (event: React.SyntheticEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  const alertApi = useApi(customAlertApiRef);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(window.location.toString()).then(() =>
      alertApi.post({
        message: 'Copied!',
        severity: 'info',
        timeoutMillis: 5000,
      }),
    );
  }, [alertApi]);

  const extraItems = UNSTABLE_extraContextMenuItems && [
    ...UNSTABLE_extraContextMenuItems.map(item => (
      <Tooltip
        title={item.disabled ? 'You are not allowed to run this action' : ''}
        key={item.title}
      >
        <div>
          <MenuItem
            key={item.title}
            onClick={() => {
              onClose();
              item.onClick();
            }}
            disabled={item.disabled}
          >
            <ListItemIcon>
              <item.Icon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={item.title} />
          </MenuItem>
        </div>
      </Tooltip>
    )),
    UNSTABLE_extraContextMenuItems.length ? (
      <Divider key="the divider is here!" />
    ) : null,
  ];

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        aria-expanded={!!anchorEl}
        role="button"
        size="small"
        onClick={onOpen}
        data-testid="menu-button"
        id="long-menu"
      >
        <MoreVert />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        aria-labelledby="long-menu"
      >
        <MenuList>
          {extraItems}
          <UnregisterEntity
            unregisterEntityOptions={UNSTABLE_contextMenuOptions}
            isUnregisterAllowed={deletePermission}
            onUnregisterEntity={onUnregisterEntity}
            onClose={onClose}
          />
          <MenuItem
            onClick={() => {
              onClose();
              onInspectEntity();
            }}
          >
            <ListItemIcon>
              <BugReportIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Inspect entity" />
          </MenuItem>
          <MenuItem
            onClick={() => {
              onClose();
              copyToClipboard();
            }}
          >
            <ListItemIcon>
              <FileCopyTwoToneIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Copy entity URL" />
          </MenuItem>
          {additionalItems}
        </MenuList>
      </Popover>
    </>
  );
}
