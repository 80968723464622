import {
  Box,
  IconButton,
  makeStyles,
  Tooltip,
  useTheme,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import { BackstageTheme } from '@backstage/theme';

export enum Severity {
  Info,
  Warning,
  Error,
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'inline-flex',
    verticalAlign: 'inherit',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(0.25),
  },
}));

interface Props {
  severity: Severity;
  label: string;
  iconSize?: 'default' | 'inherit' | 'large' | 'medium' | 'small';
  icon?: JSX.Element;
  to?: string;
}

interface GenericProps {
  label: string;
  icon: React.ReactElement;
  textColor: string;
  backgroundColor: string;
  iconColor: string;
  to?: string;
}

const GenericTooltip: React.FC<GenericProps> = ({
  label,
  icon,
  textColor,
  backgroundColor,
  iconColor,
  to,
}) => {
  const classes = useStyles();

  const StyledTooltip = withStyles({
    tooltip: {
      color: textColor,
      backgroundColor: backgroundColor,
    },
  })(Tooltip);

  return (
    <StyledTooltip title={label}>
      <Box color={iconColor} className={classes.container}>
        {!to ? (
          icon
        ) : (
          <IconButton href={to} size="small">
            {icon}
          </IconButton>
        )}
      </Box>
    </StyledTooltip>
  );
};

export const SeverityTooltip: React.FC<Props> = ({
  severity,
  label,
  iconSize,
  icon,
  to,
}) => {
  const theme = useTheme() as BackstageTheme;

  switch (severity) {
    case Severity.Info:
      return (
        <GenericTooltip
          label={label}
          icon={icon ?? <InfoIcon fontSize={iconSize} />}
          backgroundColor={theme.palette.info.light}
          textColor={theme.palette.info.contrastText}
          iconColor={theme.palette.info.main}
          to={to}
        />
      );
    case Severity.Warning:
      return (
        <GenericTooltip
          label={label}
          icon={icon ?? <WarningIcon fontSize={iconSize} />}
          backgroundColor={theme.palette.warning.light}
          textColor={theme.palette.warning.contrastText}
          iconColor={theme.palette.warning.main}
          to={to}
        />
      );
    default:
      return (
        <GenericTooltip
          label={label}
          icon={icon ?? <ErrorIcon fontSize={iconSize} />}
          backgroundColor={theme.palette.banner.error}
          textColor={theme.palette.banner.text}
          iconColor={theme.palette.error.main}
          to={to}
        />
      );
  }
};
