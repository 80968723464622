import { createApiRef, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { EnvironmentApi } from './api';

export const wbEnvironmentPlugin = createPlugin({
  id: 'wb-environment',
  routes: {
    root: rootRouteRef,
  },
});

export const environmentApiRef = createApiRef<EnvironmentApi>({
  id: 'wb-environment',
});
