import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { ApolloError } from '@apollo/client';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Box, Tooltip as MaterialTooltip, useTheme } from '@material-ui/core';
import React from 'react';
import { ResponsiveContainer, Treemap, Tooltip } from 'recharts';
import { SystemDistribution } from './types/system-distribution';
import _ from 'lodash';
import { ErrorPanel } from '@backstage/core-components';
import { Skeleton } from '@material-ui/lab';
import { ChartDetail, OverviewComponent } from './OverviewComponent';

function extractChartDetails(
  data: SystemDistribution,
  colors: string[],
): ChartDetail[] {
  const systemsCount = _.countBy(data.systems, 'type');
  return Object.entries(systemsCount).map(([key, value], index) => ({
    label: key as string,
    value: value as number,
    color: colors[index % colors.length],
    percentage: Math.round(((value as number) / data.systems.length) * 100),
  }));
}

function parseSystemDistributionData(data: SystemDistribution) {
  const systemsCount = _.countBy(data.systems, 'type');
  return Object.entries(systemsCount).map(([key, value]) => ({
    name: key,
    children: [
      {
        name: key,
        size: value,
      },
    ],
  }));
}

const CustomizedContent = (props: any) => {
  const { depth, x, y, width, height, index, colors, name, value, total } =
    props;
  // This is a workaround to not display the label if the rect area is less then the below threshold.
  // We need to find a better way to implement this behaviour.
  const threshold = 5000;
  const rectArea = width * height;
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill:
            depth < 2 ? colors[index % colors.length] : 'rgba(255,255,255,0)',
          stroke: '#fff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 && rectArea >= threshold && (
        <text
          x={x + width / 2}
          y={y + height / 2 - 6}
          textAnchor="middle"
          fill="#fff"
          fontSize={16}
        >
          {Math.round((value / total) * 100)}%
        </text>
      )}
      {depth === 1 && rectArea >= threshold && (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={12}
        >
          {name}
        </text>
      )}
    </g>
  );
};

export const SystemsDistributionCard = (props: {
  animationsDuration?: number;
  data?: SystemDistribution;
  error?: ApolloError;
  loading: boolean;
}) => {
  const { data, error, loading, animationsDuration } = props;
  const theme = useTheme();
  const chartColors = theme.palette.charts;

  return (
    <WbCard
      title="Systems Distribution"
      icon={
        <MaterialTooltip title="Percentage of cross Data Landscape systems">
          <InfoOutlinedIcon color="primary" />
        </MaterialTooltip>
      }
    >
      <WbCardContent
        style={{ height: '425px', display: 'flex', flexDirection: 'column' }}
      >
        {!!error && <ErrorPanel error={error} />}
        {!!loading && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="circle"
              width="200px"
              height="200px"
              style={{ alignSelf: 'center' }}
            />
          </Box>
        )}
        {!!data && (
          <ResponsiveContainer width="100%" height={200}>
            <Treemap
              data={parseSystemDistributionData(data)}
              dataKey="size"
              nameKey="name"
              animationDuration={animationsDuration}
              content={
                <CustomizedContent
                  colors={chartColors}
                  total={data.systems.length}
                />
              }
            >
              <Tooltip />
            </Treemap>
          </ResponsiveContainer>
        )}
        {!!data && (
          <OverviewComponent
            chartDetails={extractChartDetails(data, chartColors as string[])}
          />
        )}
      </WbCardContent>
    </WbCard>
  );
};
