import { BehaviorSubject } from './subjects';
import { SessionState } from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';

export class SessionStateTracker {
  private readonly subject = new BehaviorSubject<SessionState>(
    SessionState.SignedOut,
  );

  private signedIn: boolean = false;

  setIsSignedIn(isSignedIn: boolean) {
    if (this.signedIn !== isSignedIn) {
      this.signedIn = isSignedIn;
      this.subject.next(
        this.signedIn ? SessionState.SignedIn : SessionState.SignedOut,
      );
    }
  }

  sessionState$(): Observable<SessionState> {
    return this.subject;
  }
}
