export const GET_REVIEWS_BY_INSTANCE_ID = `
  query GetReviewsByInstanceId($id: bigint!) {
    instance: marketplace_instances(
      where: { id: { _eq: $id } }
    ) {
      reviews {
        long_comment
        reviewer
        reviewer_display_name
        score
        short_comment
        timestamp
      }
      reviews_aggregate {
        aggregate {
          avg {
            score
          }
        }
      }
    }
  }
`;
