import { RefObject, useMemo, useState } from 'react';
import useResizeObserver from './useResizeObserver';
import { throttle } from 'lodash';

export function useElementSize<T extends HTMLElement>(
  ref: RefObject<T>,
  throttleMs = 300,
) {
  const [size, setSize] = useState({
    clientWidth: 0,
    scrollWidth: 0,
    clientHeight: 0,
    scrollHeight: 0,
    boundingClientRect: new DOMRect(),
  });

  const cb = useMemo(
    () =>
      throttle((target: T) => {
        const { clientWidth, scrollWidth, clientHeight, scrollHeight } = target;

        setSize({
          clientWidth,
          scrollWidth,
          clientHeight,
          scrollHeight,
          boundingClientRect: target.getBoundingClientRect(),
        });
      }, throttleMs),
    [throttleMs],
  );

  useResizeObserver(cb, ref);

  return size;
}
