import {
  ComponentRegister,
  ComponentRegisters,
} from '@agilelab/plugin-wb-platform-common';

type CtrlEntry = {
  id: string;
  typeId?: string;
  templateId?: string;
  function?: Function;
};

class CustomViewCtrlsRegister {
  private registry: ComponentRegister;
  constructor() {
    this.registry = ComponentRegisters.getComponentRegister('customCtrls');
  }
  exists(
    CtrlId: string,
    typeId: string = '',
    templateId: string = '',
  ): boolean {
    return this.registry.exists([CtrlId, typeId, templateId]);
  }
  get(
    CtrlId: string,
    typeId: string = '',
    templateId: string = '',
  ): Function | undefined {
    return this.registry.get([CtrlId, typeId, templateId]);
  }
  register(entry: CtrlEntry): void {
    this.registry.register({
      id: [entry.id, entry.typeId, entry.templateId],
      function: entry.function,
    });
  }
}

export const CustomViewCtrls = new CustomViewCtrlsRegister();
