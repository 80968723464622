/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 */

import {
  useEntityList,
  useStarredEntities,
  EntityUserFilter,
} from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import { BooleanFilter } from '../WbTableFilters';

export const UserListPicker: React.FC = () => {
  const { updateFilters, filters } = useEntityList();
  const { starredEntities } = useStarredEntities();

  useEffect(() => {
    if (!starredEntities.size)
      updateFilters({
        user: EntityUserFilter.all(),
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [starredEntities]);

  return (
    <BooleanFilter
      value={filters.user?.value === 'starred'}
      field="Favourites"
      onChange={checked => {
        updateFilters({
          user: checked
            ? EntityUserFilter.starred(Array.from(starredEntities))
            : EntityUserFilter.all(),
        });
      }}
    />
  );
};
