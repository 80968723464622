/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import { LinkButton } from '@backstage/core-components';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React, { ComponentProps } from 'react';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

export const NextButton = (
  props: ComponentProps<typeof Button> & { loading?: boolean },
) => {
  const { loading, ...buttonProps } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        color="primary"
        variant="contained"
        {...buttonProps}
        disabled={props.disabled || props.loading}
      />
      {props.loading && (
        <CircularProgress size="1.5rem" className={classes.buttonProgress} />
      )}
      {props.loading}
    </div>
  );
};

export const BackButton = (props: ComponentProps<typeof Button>) => {
  const classes = useStyles();

  return (
    <Button variant="outlined" className={classes.button} {...props}>
      {props.children || 'Back'}
    </Button>
  );
};

export const ViewComponentButton = (
  props: ComponentProps<typeof LinkButton>,
) => {
  const classes = useStyles();

  return (
    <LinkButton
      color="primary"
      variant="contained"
      className={classes.button}
      {...props}
    >
      {props.children}
    </LinkButton>
  );
};
