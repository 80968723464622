import React, { ReactElement, useState } from 'react';
import { Box, Popover, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  onlyIcon: {
    minWidth: 0,
    borderRadius: '50%',
    padding: '4px',
  },
  option: {
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
  },
}));

export type ContextMenuItem = {
  label: string;
  action: Function;
};
export const ContextMenu = ({
  menu,
  children,
}: {
  children: ReactElement;
  menu: ContextMenuItem[];
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (anchorEl) setAnchorEl(undefined);
    else setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  return (
    <div aria-hidden="true" onClick={onClick} onKeyDown={() => true}>
      {children}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {menu.map((m: any, i: number) => (
            <Box
              key={i}
              className={classes.option}
              onClick={() => {
                m.action();
                onClose();
              }}
            >
              {m.label}
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
};
