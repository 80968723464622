import React, { ReactElement } from 'react';
import { useState, useContext, createContext } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  useTheme,
  Typography,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

type DialogOptions = {
  title: string;
};
type dialogStackElement = {
  dialogContent: ReactElement | undefined;
  dialogOption: DialogOptions | undefined;
};
const DialogContext = createContext({});
const dialogPromisesResolvers: Function[] = [];
const dialogStack: dialogStackElement[] = [];
export const DialogContextProvider = (props: Record<string, any>) => {
  const theme = useTheme();
  const [dialogContent, setDialogContent] = useState<ReactElement | undefined>(
    undefined,
  );
  const [dialogOption, setDialogOption] = useState<DialogOptions | undefined>(
    undefined,
  );
  const onClose = () => {
    const resolver = dialogPromisesResolvers.pop();
    const { dialogContent: oldDialogContent, dialogOption: oldDialogOption } =
      dialogStack.pop() || {
        dialogContent: undefined,
        dialogOption: undefined,
      };
    setDialogContent(oldDialogContent);
    setDialogOption(oldDialogOption);
    if (resolver) resolver(true);
  };
  const ctrl = {
    openDialog: (content: ReactElement, options: DialogOptions) => {
      if (dialogContent) dialogStack.push({ dialogContent, dialogOption });
      setDialogContent(content);
      setDialogOption(options);
    },
  };
  return (
    <DialogContext.Provider value={ctrl}>
      <Dialog
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: 1,
            backgroundColor: theme.palette.background.default,
          },
        }}
        open={!!dialogContent}
        onClose={onClose}
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <DialogTitle style={{ backgroundColor: 'rgb(230, 247, 249)' }}>
          <Typography variant="body1">{dialogOption?.title}</Typography>
          <IconButton
            size="small"
            aria-label="close"
            onClick={onClose}
            style={{
              position: 'absolute',
              right: 16,
              top: 16,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{}}>{dialogContent || <></>}</DialogContent>
      </Dialog>
      {props.children}
    </DialogContext.Provider>
  );
};

export const useDialog = (options: DialogOptions): Function => {
  const contextDialog: any = useContext(DialogContext);
  return (content: ReactElement) => {
    return new Promise(resolv => {
      dialogPromisesResolvers.push(resolv);
      contextDialog.openDialog(content, options);
    });
  };
};
