import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1.5),
    },
    row: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
    },
  }),
  { name: 'CheckboxFilterGroupLoader' },
);

export const CheckboxLoader = () => {
  const classes = useStyles();

  return (
    <Box className={classes.row}>
      <Skeleton variant="rect" width={18} height={18} />
      <Skeleton variant="text" style={{ flex: 1 }} />
    </Box>
  );
};

export const CheckboxFilterGroupLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Skeleton variant="text" width={60} />
      <Skeleton variant="rect" height={45} />
      <Skeleton variant="rect" width={38} />
      <CheckboxLoader />
      <CheckboxLoader />
      <CheckboxLoader />
      <CheckboxLoader />
      <CheckboxLoader />
    </Box>
  );
};
