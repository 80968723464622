import {
  UserConfigApi,
  UserConfigs,
} from '@agilelab/plugin-wb-user-config-client';
import {
  GetUserHeadersMapParams,
  GetUserHeadersParams,
  RemoveUserHeadersParams,
  StoreUserHeadersParams,
  UserHeadersApi,
} from './api/UserHeadersApi';
import {
  UserHeader,
  UserHeaderConfig,
  UserHeaderName,
  UserHeaderNameWithPrefix,
} from './types/types';
import { validateHeader } from './utils';
import { InvalidHeaderError } from './types/errors';

/**
 * Prefix to use when saving an user header as a user configuration.
 * It is used to identify user headers among other configurations, but it is not part of the user header logical name
 */
export const USER_HEADERS_CONFIG_PREFIX = '$USER_HEADER_';

/**
 * Encodes a user header name in a configuration key
 */
function configKeyFromUserHeaderName(headerName: string): string {
  return USER_HEADERS_CONFIG_PREFIX + headerName;
}

/**
 * Converts a user configuration into a user header
 * @param config user configuration to analyze
 * @param redactWith string used to redact the value of sensitive headers. If not provided, the value will be returned in plain text.
 * @returns a user header object if the provided configuration is a valid user header
 */
function configToUserHeader(config: UserConfigs): UserHeader | undefined {
  if (config.key.startsWith(USER_HEADERS_CONFIG_PREFIX)) {
    return {
      name: config.key.replace(USER_HEADERS_CONFIG_PREFIX, ''),
      value: config.value,
      isSensitive: config.isSensitive,
    };
  }
  return undefined;
}

/**
 *  @throws {InvalidHeaderError} in case of invalid header
 */
function validate(header: UserHeader) {
  const validationErrors = validateHeader(header);
  if (validationErrors.length > 0) {
    const errorMsgs: string[] = [];
    validationErrors.forEach(err => {
      const errorField = err.field ? `(field: ${err.field}) ` : '';
      errorMsgs.push(errorField + err.errors.join(`, ${errorField}`));
    });
    throw new InvalidHeaderError(
      `Invalid header \`${header.name}\`: ${errorMsgs.join(',')}`,
    );
  }
}

/**
 * Implementation of the `UserHeadersApi` that stores user headers as user configurations
 */
export class UserHeadersClient implements UserHeadersApi {
  constructor(
    private readonly userConfigApi: UserConfigApi,
    private readonly config: UserHeaderConfig,
  ) {}
  getUserHeadersPrefix(): string {
    return this.config.prefix;
  }
  areUserHeadersEnabled(): boolean {
    return this.config.enabled;
  }
  async getUserHeaders(params: GetUserHeadersParams): Promise<UserHeader[]> {
    const userConfigs = await this.userConfigApi.getAllUserConfigs({
      token: params.token,
      redactWith: params.redactWith,
    });
    return userConfigs.flatMap(config => configToUserHeader(config) ?? []);
  }
  async getUserHeadersMap(
    params: GetUserHeadersMapParams,
  ): Promise<{ [headerName: UserHeaderNameWithPrefix]: string }> {
    const userHeaders = await this.getUserHeaders({ token: params.token });
    return userHeaders.reduce((map, header) => {
      const completeHeaderName = `${this.getUserHeadersPrefix()}-${
        header.name
      }`;
      return {
        ...map,
        [completeHeaderName]: header.value,
      };
    }, {});
  }
  async storeUserHeaders(params: StoreUserHeadersParams): Promise<string[]> {
    for (const header of params.userHeaders) {
      validate(header);
      await this.userConfigApi.storeUserConfig({
        token: params.token,
        key: configKeyFromUserHeaderName(header.name),
        value: header.value,
        isSensitive: header.isSensitive,
      });
    }
    return params.userHeaders.map(header => header.name);
  }
  async removeUserHeaders(
    params: RemoveUserHeadersParams,
  ): Promise<UserHeaderName[]> {
    for (const headerName of params.userHeadersToRemove) {
      await this.userConfigApi.deleteUserConfig({
        token: params.token,
        key: configKeyFromUserHeaderName(headerName),
      });
    }
    return params.userHeadersToRemove;
  }
}
