import { Box } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const WbCardContent: React.FC<Props> = ({
  children,
  style,
  className,
}) => {
  return (
    <Box className={clsx(className)} style={{ padding: '12px 16px', ...style }}>
      {children}
    </Box>
  );
};
