import { entityRefToUrn } from '@agilelab/plugin-wb-builder-common';
import { ConsumableMode } from '@agilelab/plugin-wb-marketplace-common';
import { useEnvironmentsContext, WbSearch } from '@agilelab/plugin-wb-platform';
import {
  ALL_TAXONOMIES_FILTER,
  TaxonomySelector,
  useTaxonomySelection,
} from '@agilelab/plugin-wb-practice-shaper';
import {
  WitboostSearchApi,
  witboostSearchApiRef,
} from '@agilelab/plugin-wb-search';
import {
  AtomicFilter,
  CollatorType,
  ComplexFilter,
  FilterOperator,
  INDEX_FIELDS_CONFIG_KEY,
  JsonObjectSearchFilterVisitor,
  MARKETPLACE_PROJECTS_CONSUMABLE,
  MARKETPLACE_PROJECTS_DATA_LANDSCAPE,
  MARKETPLACE_PROJECTS_ENVIRONMENT,
  QueryOperator,
  SEARCH_CONFIG_ROOT,
  WitboostMarketplaceSearchResultSet,
} from '@agilelab/plugin-wb-search-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import lodash from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';

interface SearchPageAutocompleteProps {
  onChange: (value: string) => void;
  query: string;
  handleSubmit: (event: any, value: string) => void;
}

const useStyles = makeStyles(
  theme => ({
    optionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
    },
    highlight: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    searchBox: {
      width: '60%',
      boxShadow: theme.shadows[2],
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
    },
    box: {
      border: '1px solid rgba(0, 0, 0, 0.23)',
      marginRight: '-1px',
      display: 'flex',
      alignContent: 'center',
      justifyItems: 'center',
      padding: '0px 12px',
      background: 'white',
      '&:hover': {
        borderColor: theme.palette.secondary.main,
        zIndex: 1,
      },
    },
  }),
  { name: 'WbSearchAutocomplete' },
);

export function getDocumentFieldValues(
  document: any,
  condition: (el: string, key: string) => boolean,
  key: string = '',
): string[] {
  if (Array.isArray(document)) {
    return document.flatMap((value: any) => {
      return getDocumentFieldValues(value, condition, key);
    });
  } else if (typeof document === 'object') {
    return lodash.toPairs(document).flatMap(([k, value]: [string, any]) => {
      return getDocumentFieldValues(
        value,
        condition,
        key !== '' ? `${key}.${k}` : k,
      );
    });
  }
  const stringValue = document.toString();
  if (condition(stringValue, key)) {
    return [stringValue];
  }
  return [];
}

export function finalizeTagString(
  valueWithTags: string,
  maxLength: number,
  startTag: string,
  endTag: string,
): string {
  const matchedValue = valueWithTags;
  const valueWithoutTags = valueWithTags
    .replaceAll(endTag, '')
    .replaceAll(startTag, '');

  // if the string is longer than the max length, we need to cut it
  if (valueWithoutTags.length > maxLength) {
    const preTagIndex = matchedValue.indexOf(startTag);
    let postTagIndex = matchedValue.lastIndexOf(endTag);
    let valueBetweenTags = matchedValue.slice(preTagIndex, postTagIndex);
    let valueBetweenTagsWithoutTags = valueBetweenTags
      .replaceAll(endTag, '')
      .replaceAll(startTag, '');

    // keep removing the last tag until the string is within the max length
    while (
      valueBetweenTagsWithoutTags.length > maxLength &&
      postTagIndex !== -1
    ) {
      const relativePostTagIndex = valueBetweenTags.lastIndexOf(endTag);
      if (relativePostTagIndex !== -1) {
        postTagIndex = preTagIndex + relativePostTagIndex;
        valueBetweenTags = matchedValue.slice(preTagIndex, postTagIndex);
        valueBetweenTagsWithoutTags = valueBetweenTags
          .replaceAll(endTag, '')
          .replaceAll(startTag, '');
      } else {
        postTagIndex = -1;
      }
    }

    // if the string is still longer than the max length, we cut it to the maximum possible value, without tags
    if (valueBetweenTagsWithoutTags.length > maxLength || postTagIndex === -1) {
      return valueBetweenTagsWithoutTags.slice(0, maxLength);
    }

    // from this point on, the first and last tag indexes are guaranteed to be within the max length
    // remove all leading and trailing tags
    const refined: string =
      matchedValue
        .slice(0, preTagIndex)
        .replaceAll(startTag, '')
        .replaceAll(endTag, '') +
      matchedValue.slice(preTagIndex, postTagIndex + endTag.length) +
      matchedValue
        .slice(postTagIndex + endTag.length)
        .replaceAll(startTag, '')
        .replaceAll(endTag, '');

    // calculate the remaining length: this is the number of additional characters we can return before and after the tags
    const remainingTotalLength = maxLength - valueBetweenTagsWithoutTags.length;

    // calculate the left index bound
    const calculateLeftBound = (
      input: string,
      remaining: number,
      startIndex: number,
    ) => {
      const leftIndexBound =
        startIndex - remaining < 0 ? 0 : startIndex - remaining;
      if (leftIndexBound === 0) {
        return 0;
      }
      const sliceIndex = input
        .slice(0, startIndex)
        .indexOf(' ', leftIndexBound);
      return sliceIndex !== -1 ? sliceIndex + 1 : startIndex;
    };
    const leftBound = calculateLeftBound(
      refined,
      remainingTotalLength,
      preTagIndex,
    );

    // calculate the right index bound
    const remainingRightLength =
      remainingTotalLength - (preTagIndex - leftBound);

    const calculateRightBound = (
      input: string,
      remaining: number,
      endIndex: number,
      endTagLength: number,
    ) => {
      const rightIndexBound =
        endIndex + endTagLength + remaining > input.length
          ? input.length
          : endIndex + endTagLength + remaining;
      if (rightIndexBound === input.length) {
        return input.length;
      }
      const sliceIndexRight = input.lastIndexOf(' ', rightIndexBound);
      return sliceIndexRight !== -1 ? sliceIndexRight : endIndex;
    };
    const rightBound = calculateRightBound(
      refined,
      remainingRightLength,
      postTagIndex,
      endTag.length,
    );

    // return the refined string sliced between the two boundaries
    return refined.slice(leftBound, rightBound);
  }

  return matchedValue;
}

export const SearchPageAutocomplete = ({
  onChange,
  query,
  handleSubmit,
}: SearchPageAutocompleteProps) => {
  const [autocompleteValues, setAutoCompleteValues] =
    useState<WitboostMarketplaceSearchResultSet>();
  const [open, setOpen] = useState(false);
  const { priorityEnvironment } = useEnvironmentsContext();
  const searchApi: WitboostSearchApi = useApi(witboostSearchApiRef);
  const configApi = useApi(configApiRef);
  const classes = useStyles();
  const [highlight, setHighlight] = useState<string>('');
  const indexFields: string[] = (
    configApi.getOptionalConfigArray(
      `${SEARCH_CONFIG_ROOT}.${CollatorType.MARKETPLACE_PROJECTS}.${INDEX_FIELDS_CONFIG_KEY}`,
    ) ?? []
  ).map(indexField => indexField.getString('path'));
  const { selectedTaxonomyRef, shouldDisable } = useTaxonomySelection();
  const [isReady] = useDebounce(
    () => {
      const taxonomyFilter =
        selectedTaxonomyRef === ALL_TAXONOMIES_FILTER
          ? null
          : new AtomicFilter(
              MARKETPLACE_PROJECTS_DATA_LANDSCAPE.field,
              FilterOperator.IN,
              [entityRefToUrn(selectedTaxonomyRef)],
            );
      const complexFilter = new ComplexFilter(QueryOperator.AND, [
        new AtomicFilter(
          MARKETPLACE_PROJECTS_ENVIRONMENT.field,
          FilterOperator.EQ,
          priorityEnvironment.name,
        ),
        new AtomicFilter(
          MARKETPLACE_PROJECTS_CONSUMABLE.field,
          FilterOperator.IN,
          [ConsumableMode.Consumable, ConsumableMode.HasConsumableChild],
        ),
        ...(taxonomyFilter ? [taxonomyFilter] : []),
      ]).accept(new JsonObjectSearchFilterVisitor());

      const getResults = async () => {
        const res = (await searchApi.query({
          term: query,
          filters: complexFilter,
          useHighlight: true,
          types: [CollatorType.MARKETPLACE_PROJECTS],
          pageLimit: 8,
          orderBy: { field: 'rank', order: 'desc' },
        })) as WitboostMarketplaceSearchResultSet;
        setAutoCompleteValues(res);
      };
      if (!query || query === '') {
        setAutoCompleteValues(undefined);
        return;
      }
      getResults();
    },
    500,
    [query],
  );
  useEffect(() => {
    if (!query || query === '') {
      setOpen(false);
    }
  }, [query, selectedTaxonomyRef]);

  const [mappedAutocompleteValues, preTag, postTag]: [
    string[],
    string,
    string,
  ] = useMemo(() => {
    if (autocompleteValues) {
      const maxLength = 99;
      let startTag = '';
      let endTag = '';
      const resultSet: string[] = autocompleteValues?.results.flatMap(res => {
        startTag = res.highlight?.preTag ?? '';
        endTag = res.highlight?.postTag ?? '';
        if (!startTag || !endTag) return [];
        return getDocumentFieldValues(
          res.highlight?.fields ?? {},
          (el, key) =>
            indexFields.includes(key) &&
            el?.includes(startTag) &&
            el?.includes(endTag),
        ).map(valueWithTags => {
          return finalizeTagString(valueWithTags, maxLength, startTag, endTag);
        });
      });
      return [[...new Set(resultSet)], startTag, endTag];
    }
    return [[], '', ''];
  }, [autocompleteValues, indexFields]);

  const getAutocompleteString = (option: string) => {
    if (!query || query === '') return <></>;

    const regex = new RegExp(`(${query})`, 'gi');
    const parts = option.split(regex);

    const elements = parts.map((part, index) =>
      part.toLowerCase() === query.toLowerCase() ? (
        <span className={classes.highlight} key={index}>
          {part}
        </span>
      ) : (
        part
      ),
    );

    return <>{elements}</>;
  };

  return (
    <>
      {!shouldDisable && (
        <Box className={classes.box}>
          <TaxonomySelector showLabel={false} />
        </Box>
      )}
      <Autocomplete
        className={classes.searchBox}
        options={[
          ...(query !== '' &&
          (isReady() || mappedAutocompleteValues.length) &&
          (query !==
            mappedAutocompleteValues[0]
              ?.replaceAll(preTag, '')
              .replaceAll(postTag, '') ||
            !mappedAutocompleteValues[0])
            ? [query]
            : []),
          ...new Set(
            mappedAutocompleteValues.map(el =>
              el?.replaceAll(preTag, '').replaceAll(postTag, ''),
            ),
          ),
        ]}
        freeSolo
        disableClearable
        autoComplete
        onHighlightChange={(_event, newValue) => {
          if (newValue) setHighlight(newValue);
        }}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        noOptionsText="No Suggested Description"
        value={query || ''}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            setOpen(false);
            handleSubmit(
              event,
              highlight !== '' &&
                highlight.length >=
                  (event.target as HTMLInputElement)?.value.length
                ? highlight
                : (event.target as HTMLInputElement)?.value,
            );
          }
        }}
        onChange={(_event, newValue, reason) => {
          if (typeof newValue === 'string') {
            onChange(newValue);
            if (reason === 'select-option') {
              handleSubmit(
                _event,
                newValue.replaceAll(preTag, '').replaceAll(postTag, ''),
              );
            }
          }
        }}
        filterOptions={x => x}
        getOptionLabel={option => {
          return typeof option === 'string' ? option : option;
        }}
        renderOption={option => (
          <Box
            className={classes.optionContainer}
            onClick={() => {
              if (option === query) {
                handleSubmit(null, query);
              }
            }}
          >
            <Typography>{getAutocompleteString(option)}</Typography>
          </Box>
        )}
        renderInput={params => (
          <WbSearch
            {...params}
            value={query}
            onChange={onChange}
            submit={() => handleSubmit(null, query)}
            placeholder="Type a specific keyword or click on the arrow to view the full list"
            showEnter
            style={{
              borderTopRightRadius: '4px',
              borderBottomRightRadius: '4px',
            }}
          />
        )}
      />
    </>
  );
};
