import {
  Entity,
  entityEnvelopeSchemaValidator,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostVersionedEntity.v1alpha1.schema.json';

export interface WitboostVersionedEntity extends Entity {
  spec: {
    mesh: {
      name: string;
      version: string;
    } & Record<string, any>;
  } & Record<string, any>;
}

export const applyWitboostVersionedEntity =
  entityEnvelopeSchemaValidator<WitboostVersionedEntity>(schema);

export const witboostVersionedEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
