import React from 'react';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { Box } from '@material-ui/core';
import {
  WbBreadcrumb,
  WbBreadcrumbs,
  WbEnvironmentSelector,
  WbHeader,
  WbHeaderActions,
} from '@agilelab/plugin-wb-platform';
import { useSearchParams } from 'react-router-dom';

export const ReleaseDetailHeader: React.FC = () => {
  const { release, queryParamVersion, queryParamEnvironment, projectName } =
    useReleaseDetailPageContext();

  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <WbHeader
      title={
        <Box>
          {projectName} <b>{queryParamVersion || release.metadata.version}</b>
        </Box>
      }
      breadcrumbs={
        <WbBreadcrumbs aria-label="breadcrumb">
          <WbBreadcrumb>Builder</WbBreadcrumb>
          <WbBreadcrumb to="/catalog">Software Catalog</WbBreadcrumb>

          <WbBreadcrumb
            to={`/catalog/${release.metadata.namespace || 'default'}/${
              release.metadata.projectKind ?? 'System'
            }/${
              release.metadata.projectName ?? release.metadata.dataProductName
            }`}
          >
            {projectName}
          </WbBreadcrumb>

          <WbBreadcrumb
            to={`/catalog/${release.metadata.namespace || 'default'}/${
              release.metadata.projectKind ?? 'System'
            }/${
              release.metadata.projectName ?? release.metadata.dataProductName
            }/release`}
          >
            Release
          </WbBreadcrumb>

          <WbBreadcrumb>
            {queryParamVersion || release.metadata.version}
          </WbBreadcrumb>
        </WbBreadcrumbs>
      }
      pageTitleOverride={release.metadata.name}
    >
      <WbHeaderActions>
        <WbEnvironmentSelector
          disabled={Boolean(queryParamEnvironment && queryParamVersion)}
          onChange={e => {
            if (searchParams.get('env')) setSearchParams({ env: e });
          }}
        />
      </WbHeaderActions>
    </WbHeader>
  );
};
