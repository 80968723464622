import { Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';
import { EntityTextFilter } from '@backstage/plugin-catalog-react';

export class EntitySearchTextFilter extends EntityTextFilter {
  constructor(value: string, _: ConfigApi) {
    super(value);
  }

  filterEntity(_: Entity): boolean {
    return true;
  }

  /**
   * Delegate search to backend by passing the search terms and fields to search in.
   */
  getFullTextFilters(): { term: string; fields: string[] } {
    const customFilters = super.getFullTextFilters();
    customFilters.fields = [];
    customFilters.fields.push('metadata.name');
    customFilters.fields.push('metadata.tags');
    customFilters.fields.push('metadata.description');
    customFilters.fields.push('spec.mesh.name');
    customFilters.fields.push('spec.mesh.tags');
    return customFilters;
  }
}
