import { useEntityList } from '@backstage/plugin-catalog-react';
import { PracticeShaperFilters } from '../types';
import { useEffect, useMemo, useState } from 'react';
import { OutputTypeFilter, OutputTypeFilterValue } from '../filters';

export function useOutputTypeFilter(opts: {
  initialValue: OutputTypeFilterValue;
  allowedTypes?: string[];
}): {
  selectedType: OutputTypeFilterValue;
  setSelectedType: (types: OutputTypeFilterValue) => void;
} {
  const { initialValue, allowedTypes } = opts;
  const {
    filters,
    queryParameters: { generates },
    updateFilters,
  } = useEntityList<PracticeShaperFilters>();

  const queryParam = useMemo(() => {
    if (generates === 'all') return 'all';
    else if (generates !== undefined) return generates as string;
    return undefined;
  }, [generates]);

  const [selectedType, setSelectedType] = useState<string>(
    queryParam ?? filters.generates?.value ?? initialValue,
  );

  // Set selected types on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParam) {
      setSelectedType(queryParam);
    }
  }, [queryParam]);

  useEffect(() => {
    updateFilters({
      generates: new OutputTypeFilter(selectedType as string, allowedTypes),
    });
  }, [selectedType, allowedTypes, updateFilters]);

  return {
    selectedType,
    setSelectedType,
  };
}
