import { WittyMessage } from '@agilelab/plugin-wb-platform';
import { MetadataPayload, MetadataResponse } from './PrototypeApi';
import { Prototype } from './types';
import {
  METADATA_GENERATION_PROMPT,
  NOT_SATISFIED,
} from './components/PrototypePage/components/PrototypeChat/constants';
import { sleep } from '@agilelab/plugin-wb-platform-common';
// import { EMPTY_PROTOTYPE } from './utils';

const METADATA_GENERATION_PROMPT_ANSWER: WittyMessage = {
  contentMessage: `Please provide specific details such as the name, domain, description, and purpose of the prototype to flesh it out.`,
  sender: 'witty',
};

const NOT_SATISFIED_ANSWER: WittyMessage = {
  contentMessage: `Please provide some extra details to improve the prototype`,
  sender: 'witty',
};

const mockPrototypeEmpty: Prototype = {
  businessConcepts: null,
  dataSources: null,
  keyResults: null,
  type: null,
  name: null,
  domain: null,
  description: null,
  purpose: null,
};

const mockPrototypeFull: Prototype = {
  businessConcepts: [],
  dataSources: [],
  keyResults: [],
  type: 'Data product',
  name: 'ATM Manager',
  domain: 'Financial Sector',
  description:
    'A system designed to operate within the financial sector, providing users with financial suggestions',
  purpose:
    'To offer financial suggestions to users, enhancing their decision-making process in financial matters',
};

export async function getMockResponse(
  payload: MetadataPayload,
): Promise<MetadataResponse> {
  await sleep(500);

  if (payload.message === NOT_SATISFIED.contentMessage)
    return {
      prototype: mockPrototypeEmpty,
      message: NOT_SATISFIED_ANSWER.contentMessage,
    };
  if (payload.message === METADATA_GENERATION_PROMPT.contentMessage)
    return {
      prototype: mockPrototypeEmpty,
      message: METADATA_GENERATION_PROMPT_ANSWER.contentMessage,
    };
  return {
    prototype: mockPrototypeFull,
    message:
      'Based on the updated details, here is the modified overview of the system prototype:\n\n- **Type**: Data Product\n- **Name**: ATM Manager\n- **Domain**: Financial Sector\n- **Description**: A system designed to provide financial suggestions to users through ATM interfaces.\n- **Purpose**: To offer personalized financial advice and suggestions to users based on their banking transactions and behaviors.\n',
  };
}
