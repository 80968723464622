import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert, Rating } from '@material-ui/lab';
import React, { useState } from 'react';
import { useMarketplaceStyle } from '../../themes';
import { stringAvatar } from '../../utils';
import { WbCardActionButton } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      rating: {
        fontWeight: 'bold',
        fontSize: theme.typography.fontSize * 1.5,
      },
      table: {
        '& td': {
          padding: '0.5rem 0.5rem',
        },
      },
      input: {
        '&::placeholder': {
          fontSize: theme.typography.fontSize,
        },
      },
      actionsContainer: {
        backgroundColor: theme.palette.background.default,
        display: 'flex',
      },
    }),
  {
    name: 'RatingForm',
  },
);

export type RatingFormProps = {
  reviewerDisplayName: string;
  onCancel: any;
  onSubmit: any;
  errorText?: string;
};

export const RatingForm = (props: RatingFormProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const commonClasses = useMarketplaceStyle();
  const [score, setScore] = useState(0);

  const errorBox =
    props.errorText && props.errorText.length > 0 ? (
      <Grid item xs>
        <Alert severity="error" className={commonClasses.text}>
          {props.errorText}
        </Alert>
      </Grid>
    ) : (
      ''
    );

  return (
    <Card
      elevation={0}
      style={{
        borderTop: '1px solid lightgray',
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
      }}
    >
      <form onSubmit={props.onSubmit}>
        <CardContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs>
              <Grid container direction="column">
                <Grid item xs>
                  <Typography className={commonClasses.text}>
                    Share your opinion about your experience with this data
                    product
                  </Typography>
                </Grid>
                <Grid item xs>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item>
                      <Avatar {...stringAvatar(props.reviewerDisplayName)} />
                    </Grid>
                    <Grid item>
                      <Typography className={commonClasses.text}>
                        {props.reviewerDisplayName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Rating
                    id="new-review-score"
                    name="new-review-score"
                    value={score}
                    onChange={(_, newValue) => {
                      setScore(newValue ?? 0);
                    }}
                    precision={1}
                    defaultValue={0}
                    size="medium"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    id="new-review-comment"
                    name="new-review-comment"
                    type="text"
                    required
                    variant="filled"
                    multiline
                    maxRows={10}
                    style={{ width: '100%', whiteSpace: 'pre-wrap' }}
                    label="Tell us your experience"
                    InputProps={{
                      style: { fontSize: theme.typography.fontSize },
                    }}
                    InputLabelProps={{
                      style: { fontSize: theme.typography.fontSize },
                    }}
                  />
                </Grid>

                {errorBox}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.actionsContainer}>
          <WbCardActionButton
            label="Cancel"
            variant="outlined"
            onClick={props.onCancel}
          />
          <WbCardActionButton
            type="submit"
            label="Publish"
            variant="contained"
            onClick={props.onCancel}
          />
        </CardActions>
      </form>
    </Card>
  );
};
