import { gql } from '@apollo/client';
import { GOVERNANCE_POLICY_FRAGMENT } from '../../../fragments/governance-policy';
import { GOVERNANCE_RESULT_FRAGMENT } from '../../../fragments/governance-result';
import { GOVERNANCE_RESOURCE_FRAGMENT } from '../../../fragments/governance-resource';

export const GET_EVALUATION_RESULT_BY_ID = gql`
  query GET_EVALUATION_RESULT_BY_ID($resultId: bigint!) {
    cgp_evaluation_result_by_pk(id: $resultId) {
      ${GOVERNANCE_RESULT_FRAGMENT}
      governance_entity {
        ${GOVERNANCE_POLICY_FRAGMENT}
      }
      resource {
        ${GOVERNANCE_RESOURCE_FRAGMENT}
      }
    }
  }
`;

export const GET_EVALUATION_RESULT_BY_UUID = gql`
  query GET_EVALUATION_RESULT_BY_UUID($uuid: String!) {
    cgp_evaluation_result(where: { uuid: { _eq: $uuid } }) {
      ${GOVERNANCE_RESULT_FRAGMENT}
      governance_entity {
        ${GOVERNANCE_POLICY_FRAGMENT}
      }
      resource {
        ${GOVERNANCE_RESOURCE_FRAGMENT}
      }
    }
  }
`;
