import React from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import { WbSearch } from '../WbSearch';

interface CommonProps {
  children?: React.ReactNode;
  onClear?: () => void;
  style?: React.CSSProperties;
}

type ConditionalProps =
  | {
      onSearch: (value: string) => void | undefined;
      searchValue: string | undefined;
    }
  | {
      onSearch?: never;
      searchValue?: never;
    };

type Props = CommonProps & ConditionalProps;

export const WbTableFilters: React.FC<Props> = ({
  children,
  onClear,
  searchValue,
  onSearch,
  style,
}) => {
  const theme = useTheme();
  return (
    <Box style={{ display: 'flex', marginBottom: theme.spacing(2), ...style }}>
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: theme.spacing(2),
          alignItems: 'center',
        }}
      >
        {onSearch && (
          <WbSearch
            fullWidth={false}
            value={searchValue}
            onChange={onSearch}
            debounced
          />
        )}

        {children}
      </Box>

      {React.Children.count(children) > 0 && (
        <Box
          style={{
            flex: 1,
            // height equal to an EnumFilter so that the button is centered in respect to the first row only
            height: '45px',
            minWidth: '100px',
            marginLeft: '20px',
            justifyContent: 'flex-end',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {onClear && (
            <Button
              variant="outlined"
              size="small"
              color="primary"
              onClick={onClear}
            >
              Clear filters
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
