import React, { ReactNode, useRef, useState } from 'react';
import { FilterListModal } from '../../FilterListModal';
import { FilterGroup } from '../FilterGroup';
import { CheckboxTree } from '../../CheckboxTree/CheckboxTree';
import { FilterInput } from '../../FilterInput';
import { useCheckboxTree } from '../../CheckboxTree/hooks/useCheckboxTree';
import { WbChip } from '../../../WbTextField';
import { CheckboxNode, FilterFn } from '../../CheckboxTree';
import { Typography } from '@material-ui/core';
import { WbCardActionButton } from '../../../WbCardActionButton';
import { BaseSidebarFilter, SidebarModalFilter } from '../types';

type RenderingContext = 'modal' | 'preview';

type CheckboxTreeFilterPropsGroup<T> = {
  countToShow?: number;
  searchPlaceholder?: string;
  placeholder?: string;
  values: CheckboxNode<T>[];
  disabled?: string[];
  selected: string[];
  onSelectionChange: (selected: string[]) => void;
  renderLabel?: (
    node: CheckboxNode<T>,
    renderingContext: RenderingContext,
  ) => ReactNode;
  style?: React.CSSProperties;
  countToShowWithoutModal?: number;
  searchMatchFn?: FilterFn<T>;
  defaultExpanded?: string[];
} & BaseSidebarFilter &
  SidebarModalFilter;

export function CheckboxTreeFilterGroup<T>({
  countToShowWithoutModal = 8,
  label,
  values,
  selected,
  onSelectionChange,
  placeholder,
  searchPlaceholder,
  renderLabel,
  modalAnchorEl,
  modalStyle,
  disabled,
  defaultExpanded = [],
}: CheckboxTreeFilterPropsGroup<T>) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const defaultAnchorRef = useRef<HTMLDivElement>(null);

  const [modalSearch, setModalSearch] = useState('');

  const [modalSelected, setModalSelected] = React.useState<string[]>([]);

  const [modalExpanded, setModalExpanded] =
    React.useState<string[]>(defaultExpanded);

  // main tree
  const { flatSelectionPreview, clearAll } = useCheckboxTree({
    items: values,
    selected: selected,
    onSelectionChange: onSelectionChange,
    expanded: modalExpanded,
    onExpandChange: setModalExpanded,
  });

  // modal tree
  const {
    selectAll: modalSelectAll,
    clearAll: modalClearAll,
    expandAll,
    collapseAll,
  } = useCheckboxTree({
    items: values,
    selected: modalSelected,
    disabled,
    onSelectionChange: setModalSelected,
    expanded: modalExpanded,
    onExpandChange: setModalExpanded,
  });

  const open = Boolean(anchorEl);

  const handleShowMore = () => {
    setModalSelected([...selected]);
    setAnchorEl(modalAnchorEl ?? defaultAnchorRef.current);
  };

  const showMoreEnabled = flatSelectionPreview.length > countToShowWithoutModal;

  return (
    <div ref={defaultAnchorRef}>
      <FilterGroup
        label={label}
        hideShowMore={!showMoreEnabled}
        onShowMore={handleShowMore}
      >
        <FilterInput
          onClick={handleShowMore}
          isOpened={open}
          onClear={() => undefined}
          selected={!!flatSelectionPreview.length}
          placeholder={placeholder ?? 'Select some values'}
          hideClear
        >
          <Typography
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {flatSelectionPreview.length} Selected
          </Typography>
        </FilterInput>
        {!!flatSelectionPreview.length && (
          <WbCardActionButton
            variant="text"
            label="Clear All"
            onClick={clearAll}
          />
        )}
        {(showMoreEnabled
          ? flatSelectionPreview.slice(0, countToShowWithoutModal)
          : flatSelectionPreview
        ).map(s => {
          let selectionLabel: ReactNode = s.label ?? s.id;
          if (renderLabel) selectionLabel = renderLabel(s, 'preview');
          return (
            <WbChip
              style={{
                maxWidth: 'fit-content',
                marginRight: 0,
              }}
              onClick={() => {
                s.expandTo();
                handleShowMore();
              }}
              key={s.id}
              label={selectionLabel}
              onDelete={s.uncheck}
            />
          );
        })}
      </FilterGroup>
      <FilterListModal
        searchPlaceholder={searchPlaceholder}
        label={label}
        searchDebounced
        searchValue={modalSearch}
        onSearch={setModalSearch}
        onSelectAll={modalSelectAll}
        onClear={modalClearAll}
        onApply={() => {
          setAnchorEl(null);
          onSelectionChange(modalSelected);
        }}
        extraActions={[
          { label: 'Expand All', action: expandAll },
          { label: 'Collapse All', action: collapseAll },
        ]}
        style={modalStyle}
        PopoverProps={{
          open,
          anchorEl,
          onClose: () => setAnchorEl(null),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <CheckboxTree
          items={values}
          disabled={disabled}
          filterValue={modalSearch}
          selected={modalSelected}
          expanded={modalExpanded}
          renderLabel={
            renderLabel ? node => renderLabel(node, 'modal') : undefined
          }
          onExpandChange={setModalExpanded}
          onSelectionChange={setModalSelected}
        />
      </FilterListModal>
    </div>
  );
}
