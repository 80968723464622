/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { makeStyles } from '@material-ui/core/styles';
import { BackstagePalette, BackstageTheme } from '@backstage/theme';
import React, { PropsWithChildren } from 'react';

const useStyles = (getColor: CustomStatusGetColor) => {
  return makeStyles<BackstageTheme>(theme => ({
    status: {
      fontWeight: 500,
      '&::before': {
        width: '0.7em',
        height: '0.7em',
        display: 'inline-block',
        marginRight: 8,
        borderRadius: '50%',
        content: '""',
      },
    },
    dot: {
      '&::before': {
        backgroundColor: getColor(theme.palette),
      },
    },
  }));
};

export type CustomStatusGetColor = (
  args: BackstagePalette,
) => React.CSSProperties['color'];

export function CustomStatus(
  props: PropsWithChildren<{ getColor: CustomStatusGetColor }>,
) {
  const classes = useStyles(props.getColor)(props.children);
  return (
    <span
      className={`${classes.status} ${classes.dot}`}
      aria-label="Status"
      aria-hidden="true"
      {...{ children: props.children }}
    />
  );
}
