import { CustomView } from '@agilelab/plugin-wb-platform';
import React from 'react';

export const DataProductPage = (props: Record<string, any>) => {
  return (
    <CustomView
      id="builder_system"
      typeId={props.data.entity.spec.type}
      templateId={props.data.entity.spec.mesh.useCaseTemplateId}
      data={props.data}
      actions={props.actions || {}}
    />
  );
};
