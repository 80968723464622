export interface TimestampStore {
  save(date: Date): void;
  delete(): void;
  get(): Date | null;
}

export class DefaultTimestampStore implements TimestampStore {
  constructor(private readonly key: string) {}

  save(date: Date): void {
    localStorage.setItem(this.key, date.toJSON());
  }

  delete(): void {
    localStorage.removeItem(this.key);
  }

  get(): Date | null {
    const timestamp = localStorage.getItem(this.key);

    return timestamp !== null ? new Date(Date.parse(timestamp)) : null;
  }
}
