import React, { useEffect } from 'react';
import { ResourceType, MigrationStep, TemplateEntityUpdate } from '../types';
import { AsyncState } from 'react-use/lib/useAsyncFn';
import { useFetchResourceTypes } from '../hooks/migration/useFetchResourceTypes';
import { useFetchTemplateUpdates } from '../hooks/migration/useFetchTemplateUpdates';
import { useFetchMigrationSteps } from '../hooks/migration/useFetchMigrationSteps';

export type MigrationContextProps = {
  fetchResourceTypes: () => Promise<ResourceType[]>;
  fetchResourceTypesState: AsyncState<ResourceType[]>;

  fetchTemplateUpdates: () => Promise<TemplateEntityUpdate[]>;
  fetchTemplateUpdatesState: AsyncState<TemplateEntityUpdate[]>;

  fetchMigrationSteps: () => Promise<MigrationStep[]>;
  fetchMigrationStepsState: AsyncState<MigrationStep[]>;
};

export const MigrationContext = React.createContext<MigrationContextProps>(
  {} as MigrationContextProps,
);

interface Props {
  children?: React.ReactNode;
}

export const MigrationContextProvider: React.FC<Props> = ({ children }) => {
  const { fetchResourceTypesState, fetchResourceTypes } =
    useFetchResourceTypes();

  const { fetchTemplateUpdatesState, fetchTemplateUpdates } =
    useFetchTemplateUpdates();

  const { fetchMigrationStepsState, fetchMigrationSteps } =
    useFetchMigrationSteps({ fetchTemplateUpdates, fetchResourceTypes });

  useEffect(() => {
    fetchMigrationSteps();
  }, [fetchMigrationSteps]);

  return (
    <MigrationContext.Provider
      value={{
        fetchResourceTypes,
        fetchResourceTypesState,
        fetchTemplateUpdates,
        fetchTemplateUpdatesState,
        fetchMigrationSteps,
        fetchMigrationStepsState,
      }}
    >
      {children}
    </MigrationContext.Provider>
  );
};
