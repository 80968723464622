import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import { RbacApi } from '@agilelab/plugin-wb-rbac-common';
import { CatalogApi } from '@backstage/catalog-client';
import { ANNOTATION_ORIGIN_LOCATION, Entity } from '@backstage/catalog-model';
import { ConfigApi } from '@backstage/core-plugin-api';

export const unregisterReleasesForDataProduct = async (
  configApi: ConfigApi,
  catalogApi: CatalogApi,
  entity: Entity,
) => {
  if (
    !configApi.getOptionalBoolean(
      'catalog.onDataProductUnregister.unregisterReleases',
    )
  ) {
    return;
  }

  // unregister systems and headless components releases
  const releases = await catalogApi.getEntities({
    filter: [
      { kind: ['Release'], 'metadata.dataProductName': entity.metadata.name },
      { kind: ['Release'], 'metadata.projectName': entity.metadata.name },
    ],
  });

  const locationsPromises = releases.items.map(release =>
    catalogApi
      .getLocationByRef(
        release.metadata.annotations?.[ANNOTATION_ORIGIN_LOCATION]!,
      )
      .then(location => catalogApi.removeLocationById(location?.id!)),
  );

  await Promise.all(locationsPromises);
};

export const onProjectUnregister = async (
  configApi: ConfigApi,
  catalogApi: CatalogApi,
  entity: Entity,
) => {
  unregisterReleasesForDataProduct(configApi, catalogApi, entity);
};

export const onDomainUnregister = async (removeDomain: any, entity: Entity) => {
  removeDomain({
    variables: {
      domainId: generateURNByKind(entity.metadata.name, 'Domain'),
    },
  });
};

export const onEntityUnregister = async (
  rbacApi: RbacApi,
  entity: Entity,
  token?: string,
) => {
  const urn = generateURNByKind(entity.metadata.name, entity.kind);
  await rbacApi.deleteRolesSubjects({
    filters: { entityRef: urn },
    options: { token },
  });
};
