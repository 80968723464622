import {
  WbAccordion,
  WbBreadcrumb,
  WbBreadcrumbs,
  WbFavoriteEntityButton,
} from '@agilelab/plugin-wb-platform';
import { Box, createStyles, ListItem, makeStyles } from '@material-ui/core';
import React from 'react';
import { EntityIcon } from '../../Entity/EntityIcon';
import { SearchFlagBox } from '../components/SearchFlagBox';
import { TypeBadge } from '../../Entity/TypeBadge';
import { CustomViews } from '../CustomViews';
import { MarketplaceSearchResultEnriched, SearchResultInfo } from '../types';
import { EntitySummary } from '../../Entity/EntitySummary';

/**
 * Props for {@link MarketplaceSearchListItem}.
 *
 * @public
 */
export interface MarketplaceSearchListItemProps {
  searchResult: MarketplaceSearchResultEnriched;
}

export const KIND_TO_INFO_COMPONENT: Record<string, SearchResultInfo> = {
  system: CustomViews.SearchResultSystemInfo,
  component: CustomViews.SearchResultComponentInfo,
};

const useStyles = makeStyles(theme =>
  createStyles({
    actions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(2),
    },
  }),
);

/** @public */
export function MarketplaceSearchListItem(
  props: MarketplaceSearchListItemProps,
) {
  const { document: searchDocument } = props.searchResult;
  const { location, _nameWithVersion } = searchDocument;
  const documentKind = searchDocument._computedInfo.kind;
  const classes = useStyles();

  const InfoComponent = KIND_TO_INFO_COMPONENT[documentKind];
  return (
    <ListItem style={{ paddingLeft: 0 }}>
      <WbAccordion
        title={
          <EntitySummary
            location={location}
            leading={
              <EntityIcon
                title={_nameWithVersion}
                useCaseTemplateId={searchDocument.useCaseTemplateId}
                kind={documentKind}
                style={{ flexShrink: 0 }}
              />
            }
            title={_nameWithVersion}
            breadcrumbs={
              <WbBreadcrumbs variant="secondary" disableLastItemHighlight>
                {searchDocument._resolvedDomains.map(d => (
                  <WbBreadcrumb key={d}>{d}</WbBreadcrumb>
                ))}
                {searchDocument._computedInfo.parent?.displayName && (
                  <WbBreadcrumb>
                    {searchDocument._computedInfo.parent.displayName}
                  </WbBreadcrumb>
                )}
              </WbBreadcrumbs>
            }
          />
        }
        actions={
          <Box className={classes.actions}>
            <SearchFlagBox externalId={searchDocument.id} />
            <TypeBadge
              typeDisplayName={searchDocument._resolvedType}
              kind={documentKind}
            />

            <WbFavoriteEntityButton
              entity={`search:${props.searchResult.document.id}`}
            />
          </Box>
        }
        renderDynamicContent={isExpanded => (
          <InfoComponent
            data={{
              ...searchDocument,
              _expanded: isExpanded || undefined,
            }}
          />
        )}
        placeholderHeight="144px" // This is the height of the collapsed accordion to avoid snapping on render
      />
    </ListItem>
  );
}
