import React, { useState } from 'react';
import StarIcon from '@material-ui/icons/Star';
import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ratingStar: {
      position: 'relative',
      color: theme.palette.favs,
      height: '1em',
      width: '1em',
    },

    ratingStarSmall: {
      fontSize: theme.typography.fontSize * 1.5,
    },

    ratingStarMedium: {
      fontSize: theme.typography.fontSize * 2,
    },

    ratingEmptyStar: {
      color: theme.palette.text.primary,
      opacity: '.12',
    },
  }),
);

export const RatingStars = (props: {
  rating: number;
  clickable: boolean;
  variant?: 'small' | 'medium';
}) => {
  const MAX_STARS: number = 5;

  const classes = useStyles();
  const starSizeClass =
    props.variant && props.variant === 'medium'
      ? classes.ratingStarMedium
      : classes.ratingStarSmall;

  const [state, setState] = useState({
    rating: props.rating,
  });

  const range = (start: number, end: number) => {
    return Array(end - start + 1)
      .fill(1)
      .map((_, idx) => start + idx);
  };

  const rate = (value: number) => {
    setState({ rating: value });
  };

  const createStar = (value: number) => {
    return (
      <StarIcon
        key={value}
        onClick={_ => (props.clickable ? rate(value) : undefined)}
        className={
          value <= Math.ceil(state.rating)
            ? `${classes.ratingStar} ${starSizeClass}`
            : `${classes.ratingStar} ${classes.ratingEmptyStar} ${starSizeClass}`
        }
      />
    );
  };

  return <span>{range(1, MAX_STARS).map(createStar)}</span>;
};
