import { Button, ButtonProps, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React from 'react';

import { useButtonStyle } from '../../themes';

export const EditButton = (props: ButtonProps) => {
  const classes = useButtonStyle();

  return (
    <Button size="small" {...props}>
      <EditIcon
        htmlColor="inherit"
        fontSize="small"
        className={classes.meshButtonIcon}
      />
      {props.children}
    </Button>
  );
};

export const StdButton = (props: ButtonProps) => {
  const classes = useButtonStyle();

  const cssClassName =
    props.variant === 'outlined' || props.variant === 'text'
      ? classes.meshButton
      : classes.meshButtonContained;

  return (
    <Button
      // color="primary"
      size="small"
      {...props}
      disableFocusRipple
      className={`${cssClassName} ${props.className}`}
    >
      <Typography>{props.children}</Typography>
    </Button>
  );
};
