import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const DeployAgainIcon = createSvgIcon(
  <>
    <svg viewBox="12 4 82 82" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.5577 71.4342C33.4966 73.7553 37.988 74.9801 42.5635 74.9808L42.5667 74.9937C47.4071 74.9929 52.1479 73.6224 56.2379 71.0416C60.3279 68.4607 63.5992 64.7755 65.6716 60.4143H52.7153C50.9916 60.4143 49.3385 59.7316 48.1196 58.5164C46.9008 57.3012 46.216 55.653 46.216 53.9345V45.0443C46.216 43.3257 46.9008 41.6776 48.1196 40.4624C49.3385 39.2472 50.9916 38.5645 52.7153 38.5645H65.6683C63.7074 34.4429 60.6731 30.9215 56.882 28.3676C53.0908 25.8136 48.6817 24.3208 44.1147 24.0447C39.5476 23.7687 34.9899 24.7195 30.917 26.7981C26.8442 28.8767 23.4054 32.0068 20.9594 35.862C18.5134 39.7172 17.1499 44.1561 17.011 48.7158C16.8722 53.2754 17.9631 57.7886 20.17 61.7846C22.3769 65.7807 25.6188 69.1131 29.5577 71.4342ZM94.0831 47.3024L82.9954 36.2447C82.4132 35.6643 81.6236 35.3382 80.8002 35.3382C79.9769 35.3382 79.1873 35.6643 78.6051 36.2447C78.0229 36.8252 77.6959 37.6124 77.6959 38.4333C77.6959 39.2541 78.0229 40.0414 78.6051 40.6218L84.396 46.3953H54.6456C53.8225 46.3953 53.0332 46.7212 52.4512 47.3015C51.8692 47.8818 51.5422 48.6687 51.5422 49.4893C51.5422 50.31 51.8692 51.0969 52.4512 51.6772C53.0332 52.2575 53.8225 52.5834 54.6456 52.5834H84.396L78.6051 58.3601C78.0334 58.9426 77.7153 59.7265 77.7198 60.5415C77.7244 61.3565 78.0513 62.1368 78.6296 62.7128C79.2078 63.2889 79.9907 63.6143 80.8081 63.6182C81.6255 63.6222 82.4115 63.3044 82.9954 62.734L94.0831 51.6795C94.3715 51.3922 94.6003 51.051 94.7563 50.6755C94.9124 50.3 94.9928 49.8975 94.9928 49.491C94.9928 49.0845 94.9124 48.682 94.7563 48.3065C94.6003 47.9309 94.3715 47.5898 94.0831 47.3024Z"
        fill="currentColor"
      />
    </svg>
  </>,
  'DeployAgainIcon',
);
