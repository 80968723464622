import React, { useEffect, useState } from 'react';
import { WbTextField } from '@agilelab/plugin-wb-platform';
import { Box, IconButton, Typography } from '@material-ui/core';
import { CircularProgress, Fade, useTheme } from '@material-ui/core';
import { FileStatus } from './types';

import CloseIcon from '@material-ui/icons/Close';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { FilePickerUploadButton } from './FilePickerUploadButton/FilePickerUploadButton';
import { printFileSize, toMB } from './utils';

interface Props {
  title: string;
  status: FileStatus;
  progress: number;
  fileName?: string;
  animation?: boolean;
  required?: boolean;
  /**
   * File size in bytes
   */
  fileSize?: number;
  disabled?: boolean;
  error?: Error;
  openFileDialog: (e: any) => void;
  reset: (e: any) => void;
  placeholder?: string;
  description?: string;
  customProps?: any;
}

const textByStatus: Record<FileStatus, (props: Props) => React.ReactNode> = {
  [FileStatus.Empty]: ({ description }) => description || null,
  [FileStatus.Loading]: ({ progress, description }) => (
    <>
      {description && (
        <>
          {description}
          <br />
        </>
      )}
      <Typography component="span" variant="caption" color="primary">
        Loading - {`${progress}%`}
      </Typography>
    </>
  ),
  [FileStatus.LoadedWithSuccess]: ({ fileSize, description }) => (
    <>
      {description && (
        <>
          {description}
          <br />
        </>
      )}
      {fileSize && (
        <>
          {printFileSize({ sizeMegaBytes: toMB(fileSize), digits: 2 })}MB -{' '}
          <Typography
            component="span"
            variant="caption"
            style={{ color: 'green' }}
          >
            Completed
          </Typography>
        </>
      )}
    </>
  ),
  [FileStatus.LoadedWithError]: ({ error, description }) => {
    const theme = useTheme();
    return (
      <>
        {description && (
          <>
            {description}
            <br />
          </>
        )}
        <Typography
          variant="caption"
          component="span"
          style={{ color: theme.palette.error.main }}
        >
          {error?.message}
        </Typography>
      </>
    );
  },
};

const iconsByStatus: Record<FileStatus, (props: Props) => React.ReactNode> = {
  [FileStatus.Empty]: ({ openFileDialog, disabled }) => (
    <FilePickerUploadButton onClick={openFileDialog} disabled={disabled} />
  ),
  [FileStatus.Loading]: () => (
    <>
      <CircularProgress size={16} />
      <FilePickerUploadButton />
    </>
  ),
  [FileStatus.LoadedWithSuccess]: ({ reset, openFileDialog, animation }) => {
    const theme = useTheme();

    const [checkFadeIn, setCheckFadeIn] = useState(true);
    const [closeFadeIn, setCloseFadeIn] = useState(false);

    useEffect(() => {
      if (!animation) {
        setCheckFadeIn(false);
        setCloseFadeIn(true);
      } else {
        setTimeout(() => {
          setCheckFadeIn(false);
          setTimeout(() => {
            setCloseFadeIn(true);
          }, 500);
        }, 2000);
      }
    }, [animation]);

    return (
      <>
        {animation && (
          <Fade in={checkFadeIn}>
            <IconButton size="small">
              <CheckCircleIcon
                fontSize="small"
                htmlColor={theme.palette.success.main}
              />
            </IconButton>
          </Fade>
        )}
        {closeFadeIn && (
          <Fade in={closeFadeIn}>
            <IconButton onClick={reset} size="small">
              <CloseIcon color="secondary" fontSize="small" />
            </IconButton>
          </Fade>
        )}
        <FilePickerUploadButton onClick={openFileDialog} />
      </>
    );
  },
  [FileStatus.LoadedWithError]: ({ openFileDialog }) => (
    <FilePickerUploadButton onClick={openFileDialog} />
  ),
};

export const FilePickerInput: React.FC<Props> = props => {
  return (
    <WbTextField
      placeholder={props.placeholder}
      label={props.title}
      value={props.fileName || ''}
      disabled={props.disabled}
      required={props.required}
      error={props.error ? true : undefined}
      onClick={props.openFileDialog}
      cursorType="pointer"
      helperText={
        props.error ? props.error.message : textByStatus[props.status](props)
      }
      FormHelperTextProps={{
        style: { cursor: 'pointer' },
      }}
      InputProps={{
        endAdornment: !props.disabled && (
          <Box style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
            {iconsByStatus[props.status](props)}
          </Box>
        ),
        readOnly: true,
        disableUnderline: true,
      }}
      {...props.customProps}
    />
  );
};
