import { z } from 'zod';
import {
  RoleEntity,
  RoleSubject,
  RoleSubjectEntity,
  RoleVisibility,
} from './model';

export const RolesSubjectsFiltersSchema = z
  .object({
    subject: z.string(),
    roleId: z.string(),
    entityRef: z.string().optional().nullable(),
    enabled: z.enum(['true', 'false']).default('false'),
  })
  .partial();

export type RolesSubjectsFilters = z.infer<typeof RolesSubjectsFiltersSchema>;

export type DeleteRolesSubjectsParams = {
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesSubjectsParams = {
  filters?: RolesSubjectsFilters;
  options?: { token?: string };
};

export type GetRolesParams = {
  searchKeyword?: string;
  options?: {
    visibility?: RoleVisibility;
    offset?: number;
    limit?: number;
    token?: string;
  };
};

export type AddRolesSubjectsParams = {
  rolesSubjects: RoleSubject[];
  options?: { upsert?: boolean; token?: string };
};

/**
 * RBAC Api definition for frontend clients
 */
export interface RbacApi {
  /**
   * Deletes roles subjects rows according to defined filters
   * @param options
   */
  deleteRolesSubjects(
    params: DeleteRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRolesSubjects(
    params: GetRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;

  getRoles(params: GetRolesParams): Promise<RoleEntity[]>;

  addRolesSubjects(
    params: AddRolesSubjectsParams,
  ): Promise<Array<RoleSubjectEntity>>;
}
