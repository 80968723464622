import React from 'react';
import { createSvgIcon } from '@material-ui/core/utils';
/* eslint-disable */
export const PreviewIcon = createSvgIcon(
  <>
    <path
      d="M34.009 42.47v1.754c0 .329-.271.6-.6.6H3.605c-.329 0-.6-.271-.6-.6V3.6c0-.329.271-.6.6-.6H24.8v6.041c.005 1.739 1.434 3.168 3.173 3.173h6.032v4.369c0 .412.339.752.752.752.016 0 .032-.001.048-.002.262-.017.527-.026.793-.026.209 0 .418.006.624.016.013.001.026.001.039.001.413 0 .752-.339.753-.752v-4.82c.001-.6-.237-1.176-.661-1.6L26.865.661C26.442.238 25.867 0 25.268 0H3.606C1.628 0 0 1.628 0 3.606v40.62c.006 1.976 1.63 3.6 3.606 3.606h29.809c1.728-.006 3.22-1.256 3.529-2.957.028-.176-.035-.356-.167-.476l-2.064-1.939c-.289-.289-.7-.224-.7.011l-.004-.001Zm12.706 1.681-7.205-6.769c-.49-.46-1.229-.544-1.809-.205l-1.38-1.3c1.294-1.764 1.993-3.895 1.993-6.083 0-5.64-4.641-10.281-10.281-10.281-5.64 0-10.281 4.641-10.281 10.281 0 5.64 4.641 10.281 10.281 10.281 2.387 0 4.702-.831 6.543-2.351l1.38 1.3c-.308.6-.182 1.336.307 1.8l7.205 6.77c.606.569 1.572.542 2.144-.061l1.163-1.233c.569-.606.542-1.572-.061-2.144l.001-.005Zm-13.43-19.92c1.528 1.444 2.396 3.456 2.396 5.559 0 4.195-3.452 7.647-7.647 7.647s-7.647-3.452-7.647-7.647c0-1.953.748-3.834 2.089-5.253 2.881-3.048 7.76-3.186 10.809-.306M6.942 14.756c-.366-.017-.681.269-.7.635V16.8c.019.366.334.651.7.635h16.444c.366.016.681-.269.7-.635v-1.409c-.019-.366-.334-.651-.7-.635H6.942Zm13.402 5.151H6.942c-.366-.017-.681.269-.7.635v1.412c.019.366.334.651.7.635h10.841c.345-.497.725-.969 1.137-1.412.441-.459.917-.884 1.424-1.27ZM6.879 25c-.348.001-.634.287-.635.635v1.412c.001.348.287.634.635.635h8.789c.158-.921.42-1.82.781-2.682h-9.57Zm0 10.631c-.348.001-.634.287-.635.635v1.414c.001.348.287.634.635.635h11.978c-.752-.806-1.394-1.707-1.91-2.681H6.879v-.003Zm0-5.151c-.348.001-.634.287-.635.635v1.412c.001.348.287.634.635.635h9.087c-.243-.875-.392-1.774-.442-2.681H6.879v-.001Z"
      transform="matrix(.50864 0 0 .49996 0 -6e-8)"
      id="Path_58"
    />
  </>,
  'PreviewIcon',
);

export const KoFilledIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_155" data-name="Rectangle 155" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="Group_256" data-name="Group 256" clipPath="url(#clip-path)">
      <path
        id="Path_67"
        data-name="Path 67"
        d="M23.623 9c-.034-.137-.074-.269-.114-.406a11.958 11.958 0 0 0-3.023-5.074c-.274-.269-.554-.531-.851-.777s-.606-.474-.926-.691c-.16-.109-.32-.211-.486-.314-.331-.2-.669-.383-1.017-.554A12 12 0 0 0 12.006 0a12.115 12.115 0 0 0-3.572.537 11.386 11.386 0 0 0-1.1.406l-.537.24c-.251.12-.5.251-.737.389s-.48.286-.709.44c-.114.08-.234.16-.343.234-.223.16-.44.326-.651.509l-.32.269a11.982 11.982 0 0 0-1.674 16.12c.006.006 0 .006 0 .006a11.377 11.377 0 0 0 1.08 1.257c.189.194.389.383.594.571a12.725 12.725 0 0 0 1.314 1.011c.234.154.469.3.709.44s.486.269.737.389l.537.24a11.385 11.385 0 0 0 1.1.406 12.115 12.115 0 0 0 3.572.536 12 12 0 0 0 5.2-1.183c.349-.171.686-.354 1.017-.554.166-.1.326-.206.486-.314a12.2 12.2 0 0 0 1.777-1.469 11.955 11.955 0 0 0 2.977-4.914c.046-.143.086-.28.126-.423.074-.291.143-.577.2-.874A12.315 12.315 0 0 0 24 12a12.088 12.088 0 0 0-.377-3M8.149 12.663l-.874.926V16H5.931V8.28h1.343v3.62l.737-.914 2.263-2.7H11.9L9.029 11.7l3.028 4.3h-1.588Zm10.709-.32a4.714 4.714 0 0 1-.394 1.989 2.918 2.918 0 0 1-1.12 1.314 3.295 3.295 0 0 1-3.349 0 3 3 0 0 1-1.137-1.309 4.678 4.678 0 0 1-.406-1.954v-.434a4.759 4.759 0 0 1 .4-1.994 3 3 0 0 1 1.131-1.32 3.267 3.267 0 0 1 3.343-.006 2.965 2.965 0 0 1 1.126 1.3 4.66 4.66 0 0 1 .406 1.977Zm-3.2-3.057a1.583 1.583 0 0 0-1.36.68 3.279 3.279 0 0 0-.5 1.931v.446a3.342 3.342 0 0 0 .5 1.966 1.592 1.592 0 0 0 1.365.691 1.569 1.569 0 0 0 1.371-.68 3.4 3.4 0 0 0 .48-1.977v-.406a3.351 3.351 0 0 0-.486-1.966 1.573 1.573 0 0 0-1.371-.686"
      />
    </g>
  </>,
  'KoFilledIcon',
);

export const KoIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_153" data-name="Rectangle 153" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="ICONA_KO" data-name="ICONA KO">
      <g id="Group_253" data-name="Group 253" clipPath="url(#clip-path)">
        <path
          id="Path_61"
          data-name="Path 61"
          d="M12 24a12 12 0 1 1 12-12 12.014 12.014 0 0 1-12 12m0-22.56A10.56 10.56 0 1 0 22.56 12 10.572 10.572 0 0 0 12 1.44"
        />
        <path
          id="Path_62"
          data-name="Path 62"
          d="m14.916 21.106-.889.944v2.45h-1.364v-7.851h1.364v3.681l.755-.933 2.3-2.749h1.65l-2.922 3.478 3.084 4.374h-1.617Z"
          transform="translate(-6.585 -8.657)"
        />
        <path
          id="Path_63"
          data-name="Path 63"
          d="M32.992 20.661a4.814 4.814 0 0 1-.4 2.024 3.013 3.013 0 0 1-1.14 1.337 3.342 3.342 0 0 1-3.407 0 3.057 3.057 0 0 1-1.154-1.329 4.661 4.661 0 0 1-.411-1.993v-.442a4.777 4.777 0 0 1 .407-2.027 3.056 3.056 0 0 1 1.148-1.345 3.351 3.351 0 0 1 3.4-.006 3.025 3.025 0 0 1 1.146 1.326 4.765 4.765 0 0 1 .409 2.014Zm-1.364-.41a3.429 3.429 0 0 0-.493-2 1.741 1.741 0 0 0-2.782 0 3.366 3.366 0 0 0-.509 1.959v.453a3.4 3.4 0 0 0 .5 2 1.622 1.622 0 0 0 1.4.706 1.6 1.6 0 0 0 1.393-.69 3.481 3.481 0 0 0 .488-2.016Z"
          transform="translate(-13.77 -8.541)"
        />
      </g>
    </g>
  </>,
  'KoIcon',
);

export const OkFilledIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_156" data-name="Rectangle 156" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="Group_258" data-name="Group 258" clipPath="url(#clip-path)">
      <path
        id="Path_68"
        data-name="Path 68"
        d="M14.534 16.936a3.351 3.351 0 0 1 .486 1.964v.406a3.4 3.4 0 0 1-.48 1.977 1.569 1.569 0 0 1-1.371.68 1.585 1.585 0 0 1-1.371-.691 3.342 3.342 0 0 1-.5-1.966v-.446a3.279 3.279 0 0 1 .5-1.931 1.707 1.707 0 0 1 2.731.006"
        transform="translate(-4.843 -6.964)"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m-.48 12.343a4.714 4.714 0 0 1-.394 1.989 2.953 2.953 0 0 1-1.12 1.314 3.11 3.11 0 0 1-1.68.457 3.1 3.1 0 0 1-1.669-.457 3 3 0 0 1-1.137-1.309 4.591 4.591 0 0 1-.406-1.954v-.434a4.672 4.672 0 0 1 .4-1.994 3 3 0 0 1 1.131-1.32 3.04 3.04 0 0 1 1.669-.463 3.091 3.091 0 0 1 1.674.457 2.965 2.965 0 0 1 1.126 1.3 4.66 4.66 0 0 1 .406 1.977ZM17.48 16l-2.32-3.337-.874.926V16h-1.343V8.28h1.343v3.62l.737-.914 2.263-2.7h1.623L16.04 11.7l3.029 4.3Z"
      />
    </g>
  </>,
  'OkFilledIcon',
);

export const OkIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_154" data-name="Rectangle 154" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="ICONA_OK" data-name="ICONA OK">
      <g id="Group_254" data-name="Group 254" clipPath="url(#clip-path)">
        <path
          id="Path_64"
          data-name="Path 64"
          d="M12 24a12 12 0 1 1 12-12 12.014 12.014 0 0 1-12 12m0-22.56A10.56 10.56 0 1 0 22.56 12 10.572 10.572 0 0 0 12 1.44"
        />
        <path
          id="Path_65"
          data-name="Path 65"
          d="M16.991 21.125a5.025 5.025 0 0 1-.416 2.112 3.148 3.148 0 0 1-1.19 1.395 3.489 3.489 0 0 1-3.555 0 3.2 3.2 0 0 1-1.2-1.386 4.862 4.862 0 0 1-.43-2.076v-.461a4.988 4.988 0 0 1 .424-2.115 3.2 3.2 0 0 1 1.2-1.4 3.5 3.5 0 0 1 3.55-.005 3.153 3.153 0 0 1 1.2 1.383 4.972 4.972 0 0 1 .428 2.1Zm-1.423-.425a3.58 3.58 0 0 0-.515-2.087 1.817 1.817 0 0 0-2.9 0 3.512 3.512 0 0 0-.532 2.045v.473a3.553 3.553 0 0 0 .526 2.087 1.693 1.693 0 0 0 1.46.737 1.673 1.673 0 0 0 1.454-.72 3.628 3.628 0 0 0 .509-2.1Z"
          transform="translate(-5.302 -8.686)"
        />
        <path
          id="Path_66"
          data-name="Path 66"
          d="m29.848 21.59-.928.984v2.554H27.5v-8.19h1.42v3.842l.788-.973 2.4-2.868h1.721l-3.043 3.628L34 25.128h-1.688Z"
          transform="translate(-14.298 -8.808)"
        />
      </g>
    </g>
  </>,
  'OkIcon',
);

export const ProgressSuccessIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_157" data-name="Rectangle 157" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="ICONA_PROGRESS_SUCCESS" data-name="ICONA PROGRESS SUCCESS">
      <g id="Group_260" data-name="Group 260" clipPath="url(#clip-path)">
        <path
          id="Path_70"
          data-name="Path 70"
          d="M12 24a12 12 0 1 1 12-12 12.014 12.014 0 0 1-12 12m0-22.054A10.054 10.054 0 1 0 22.054 12 10.065 10.065 0 0 0 12 1.946"
        />
        <path
          id="Path_71"
          data-name="Path 71"
          d="m12.569 22.074-5.12-5.12 1.444-1.445 3.677 3.666 7.772-7.775 1.441 1.454Z"
          transform="translate(-2.617 -4.006)"
        />
      </g>
    </g>
  </>,
  'ProgressSuccessIcon',
);

export const ProgressRunningIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_158" data-name="Rectangle 158" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="Group_265" data-name="Group 265" clipPath="url(#clip-path)">
      <path
        id="Path_72"
        data-name="Path 72"
        d="M12 1.946a10.054 10.054 0 0 0-7.109 17.163A10.054 10.054 0 1 0 19.109 4.891 9.987 9.987 0 0 0 12 1.946M12 0A12 12 0 1 1 0 12 12 12 0 0 1 12 0"
      />
      <path
        id="Path_73"
        data-name="Path 73"
        d="M23.163 11.393A7.458 7.458 0 0 0 18.2 7.715l-.707 1.849a5.514 5.514 0 1 1-3.866 10.067l-.707 1.849a7.46 7.46 0 0 0 10.243-10.087"
        transform="translate(-4.54 -2.711)"
      />
      <path
        id="Path_74"
        data-name="Path 74"
        d="M21.336 15.121a3.742 3.742 0 0 0-4.365-1.764l.344 1.946a1.8 1.8 0 0 1 1.615 3.2 1.819 1.819 0 0 1-1.012.221l.344 1.946a3.7 3.7 0 0 0 1.615-.467 3.74 3.74 0 0 0 1.459-5.085"
        transform="translate(-5.963 -4.636)"
      />
    </g>
  </>,
  'ProgressRunningIcon',
);

export const ProgressErrorIcon = createSvgIcon(
  <>
    <g
      id="ICONA_PROGRESS_ERROR"
      data-name="ICONA PROGRESS ERROR"
      transform="translate(4015 -20685)"
      stroke="#currentColor"
    >
      <g
        id="Ellipse_37"
        data-name="Ellipse 37"
        transform="translate(-4015 20685)"
        fill="none"
        strokeWidth="2"
      >
        <circle cx="12" cy="12" r="12" stroke="none" />
        <circle cx="12" cy="12" r="11" />
      </g>
      <g
        id="Ellipse_36"
        data-name="Ellipse 36"
        transform="translate(-4010.459 20689.541)"
        strokeWidth="3"
      >
        <circle cx="7.459" cy="7.459" r="7.459" stroke="none" />
        <circle cx="7.459" cy="7.459" r="5.959" fill="none" />
      </g>
    </g>
  </>,
  'ProgressErrorIcon',
);

export const NotStartedIcon = createSvgIcon(
  <>
    <path
      id="RemoveCircle"
      fill="#808080"
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M18,13.2H6v-2.4
	h12L18,13.2z"
    />
  </>,
  'NotStartedIcon',
);

export const TerminatedIcon = createSvgIcon(
  <>
    <path
      id="RemoveCircle"
      fill="#808080"
      d="M12,0C5.4,0,0,5.4,0,12s5.4,12,12,12s12-5.4,12-12C24,5.4,18.6,0,12,0z M18,13.2H6v-2.4
	h12L18,13.2z"
      transform="rotate(45 12 12)"
    />
  </>,
  'TerminatedIcon',
);

export const OkPlusIcon = createSvgIcon(
  <>
    <g>
      <path d="M11.666 23.539a11.538 11.538 0 0 1-8.249-3.447 11.843 11.843 0 0 1 0-16.644 11.591 11.591 0 0 1 14.739-1.46l-1.4.814A10.187 10.187 0 0 0 4.425 4.464a10.394 10.394 0 0 0 0 14.61 10.177 10.177 0 0 0 14.482 0 10.3 10.3 0 0 0 2.775-5.138l1.586-.924a11.776 11.776 0 0 1-3.768 7.482 11.584 11.584 0 0 1-7.834 3.045Z" />
      <path d="M11.345 11.773a4.392 4.392 0 0 1-.364 1.846 2.751 2.751 0 0 1-1.04 1.219 2.86 2.86 0 0 1-1.556.425 2.867 2.867 0 0 1-1.551-.425 2.793 2.793 0 0 1-1.053-1.211 4.255 4.255 0 0 1-.373-1.815v-.4a4.355 4.355 0 0 1 .371-1.849 2.79 2.79 0 0 1 1.045-1.23 2.851 2.851 0 0 1 1.551-.425 2.877 2.877 0 0 1 1.551.42 2.755 2.755 0 0 1 1.045 1.21 4.345 4.345 0 0 1 .374 1.836Zm-1.244-.375a3.13 3.13 0 0 0-.45-1.824 1.472 1.472 0 0 0-1.276-.634 1.467 1.467 0 0 0-1.261.632 3.069 3.069 0 0 0-.465 1.787v.413a3.106 3.106 0 0 0 .459 1.826 1.48 1.48 0 0 0 1.276.644 1.463 1.463 0 0 0 1.271-.629 3.173 3.173 0 0 0 .445-1.839Zm4.614.674-.811.86v2.232H12.66V8.006h1.248v3.358l.688-.851 2.095-2.508h1.5l-2.66 3.173 2.813 3.988h-1.476ZM20.73 7.557l-1.806-.52.326-1 1.789.692-.09-2.035h1.06l-.089 2.071 1.759-.68.32 1.018-1.836.521 1.185 1.574-.859.628-1.066-1.706-1.054 1.653-.865-.6Z" />
    </g>
  </>,
  'OkPlusIcon',
);
/* eslint-enable */
