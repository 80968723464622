import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useButtonStyle = makeStyles((theme: Theme) =>
  createStyles({
    meshButton: {
      borderColor: 'inherit',
      fontSize: theme.typography.fontSize * 0.9,
      fontWeight: 'bold',
      color: theme.palette.primary.main,
    },
    meshButtonContained: {
      borderColor: 'inherit',
      fontSize: theme.typography.fontSize * 0.9,
      fontWeight: 'bold',
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    meshButtonIcon: {
      fontSize: '1.4em',
      width: '1em',
      height: '1em',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: '4px',
      fontWeight: 'bold',
    },
  }),
);
