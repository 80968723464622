import {
  createApiRef,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import { PrototypeApi } from './PrototypeApi';

import { rootRouteRef } from './routes';

export const wbPrototypePlugin = createPlugin({
  id: 'wb-prototype',
  routes: {
    root: rootRouteRef,
  },
});

export const prototypeApiRef = createApiRef<PrototypeApi>({
  id: 'plugin.prototype',
});

export const WbPrototypePage = wbPrototypePlugin.provide(
  createRoutableExtension({
    name: 'WbPrototypePage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
