import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React from 'react';

interface Props {
  release: ReleaseAndDeployments;
}

export const ReleaseUpdate: React.FC<Props> = ({ release }) => {
  return (
    <Typography>
      {format(
        parseISO(
          release.metadata.isSnapshot && release.metadata.committedAt
            ? release.metadata.committedAt
            : release.metadata.createdAt,
        ),
        'yyyy/MM/dd HH:mm:ss',
      )}
    </Typography>
  );
};
