import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/ReverseProvisioningTemplate.v1alpha1.schema.json';
import { JsonObject } from '@backstage/types';

export const ReverseProvisioningTemplateFileName =
  'reverse-provisioning-template.yaml';
declare interface ReverseProvisioningTemplateEntity extends Entity {
  apiVersion: 'witboost.com/v1';
  kind: 'ReverseProvisioningTemplate';
  metadata: EntityMeta;
  spec: {
    owner?: string;
    domain?: string;
    /**
     * witboost use case template references
     */
    useCaseTemplateRef?: string[];
    /**
     * Use Case Template Ids
     */
    useCaseTemplateId?: string[];
    /**
     * JSONSchemas which is used to render a form in the frontend
     * to collect user input and validate it against that schema.
     */
    parameters?: JsonObject;
  };
}

export type { ReverseProvisioningTemplateEntity as ReverseProvisioningTemplate };

export const reverseProvisioningTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
