import React from 'react';
import { Engine, Policy } from '@agilelab/plugin-wb-governance-common';
import { GovernanceTestOverviewPolicyInfo } from './GovernanceTestOverviewPolicyInfo';
import { PolicyCueScriptEditor } from '../../../PolicyDetail';
import { Box } from '@material-ui/core';

interface Props {
  policy: Policy;
  onUpdate: (code?: string) => void;
  editable: boolean;
  saving: boolean;
  handleSave: () => void;
  showSaveButton: boolean;
}

export const GovernanceTestOverviewPolicySimple: React.FC<Props> = props => {
  return (
    <>
      <GovernanceTestOverviewPolicyInfo {...props} />

      {props.policy.engine === Engine.Cue && (
        <Box mt={2}>
          <PolicyCueScriptEditor
            cueScript={props.policy.cueSpecific.cueScript}
            {...props}
          />
        </Box>
      )}
    </>
  );
};
