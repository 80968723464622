import { createTopic } from '../lib';
import { z } from 'zod';

const userSettingsNavigatedEventSchema = z.object({
  path: z.string(),
});

export type UserSettingsNavigatedEvent = z.infer<
  typeof userSettingsNavigatedEventSchema
>;

export const userSettingsNavigatedTopic =
  createTopic<UserSettingsNavigatedEvent>(
    {
      namespace: 'settings',
      name: 'user-settings-navigated',
      description: 'User settings has been navigated',
    },
    userSettingsNavigatedEventSchema,
  );
