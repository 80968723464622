import { Box, Button, ButtonProps, useTheme } from '@material-ui/core';
import React from 'react';

interface Props {
  cancel: ButtonProps;
  apply: ButtonProps;
  containerStyle?: React.CSSProperties;
}

export const FiltersButton: React.FC<Props> = ({
  cancel,
  apply,
  containerStyle,
}) => {
  const theme = useTheme();

  return (
    <Box
      style={{
        padding: '8px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderTop: `1px solid ${theme.palette.grey[300]}`,
        ...containerStyle,
      }}
    >
      <Button
        style={{ flex: 1 }}
        size="small"
        variant="outlined"
        color="primary"
        {...cancel}
      >
        Cancel
      </Button>
      <Button
        style={{ flex: 1 }}
        size="small"
        variant="contained"
        color="primary"
        {...apply}
      >
        Apply
      </Button>
    </Box>
  );
};
