import { useContext, useEffect, useState } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  ComponentInstanceEntities,
  ComponentInstanceEntity,
  GET_OUTPUT_PORTS_BY_INSTANCE_ID,
} from '@agilelab/plugin-wb-marketplace-common';
import { gql, useQuery } from '@apollo/client';
import useAsync from 'react-use/lib/useAsync';
import {
  compactStringRef,
  expandEntityRef,
  resolveGroupsHierarchy,
} from '@agilelab/plugin-wb-platform-common';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { SystemContext } from './DataProductContext';
import { isEqual } from 'lodash';

export const useOutputPorts = (
  username: string,
  selectedOutputportIds?: number[],
) => {
  const catalogApi = useApi(catalogApiRef);
  const [selectedOutputPorts, setSelectedOutputPorts] = useState<
    ComponentInstanceEntity[]
  >([]);
  const dpInstance = useContext(SystemContext);

  const {
    value: groupsHierarchy,
    loading: groupsHierarchyLoading,
    error: groupsHierarchyError,
  } = useAsync(async () => {
    const resolvedGroupsHierarchy = await resolveGroupsHierarchy(
      expandEntityRef(username),
      catalogApi,
    );
    return resolvedGroupsHierarchy.map(compactStringRef);
  }, [catalogApi, username]);

  const {
    data: opsWithACLs,
    loading: opsLoading,
    error: opsError,
  } = useQuery<ComponentInstanceEntities>(
    gql(GET_OUTPUT_PORTS_BY_INSTANCE_ID),
    {
      variables: {
        id: dpInstance.id,
        identities: groupsHierarchy ?? [],
      },
      skip: !groupsHierarchy,
    },
  );

  useEffect(() => {
    const newFilteredOutputPorts =
      selectedOutputportIds && opsWithACLs?.instances
        ? opsWithACLs.instances.filter(d =>
            selectedOutputportIds.includes(d.id),
          )
        : [];

    if (!isEqual(newFilteredOutputPorts, selectedOutputPorts)) {
      setSelectedOutputPorts(newFilteredOutputPorts);
    }
  }, [opsWithACLs?.instances, selectedOutputportIds, selectedOutputPorts]);

  return {
    loading:
      groupsHierarchyLoading || opsLoading || !groupsHierarchy || !opsWithACLs,
    error: opsError ?? groupsHierarchyError,
    selectedOutputPorts: selectedOutputPorts,
    allOutputPorts: opsWithACLs?.instances,
    dpInstance,
  };
};
