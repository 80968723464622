import { MenuItem, useTheme } from '@material-ui/core';
import React from 'react';
import { useRef, useState } from 'react';

type ClickableMenuItemProps = {
  label: string | undefined;
  onClick: () => void;
};

export const ClickableMenuItem = (props: ClickableMenuItemProps) => {
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  return (
    <MenuItem
      {...props}
      ref={ref}
      onMouseEnter={() => {
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      style={{
        background: isHovered ? theme.palette.bkg.primary : theme.palette.white,
        color: isHovered
          ? theme.palette.primary.main
          : theme.palette.secondary.main,
        fontSize: 14,
        fontWeight: 500,
      }}
    >
      {props.label}
    </MenuItem>
  );
};
