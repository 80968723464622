import { AccessControlRequest } from '@agilelab/plugin-wb-access-control-common';
import { Chip, Tooltip, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { useNotificationStyle } from './NotificationsPageStyle';
import LaunchIcon from '@material-ui/icons/LaunchOutlined';

export const AccessControlDisplayFields = (props: {
  notification: AccessControlRequest;
}): JSX.Element => {
  const theme = useTheme();
  const classes = useNotificationStyle();

  if (!props.notification.displayFields) {
    return <></>;
  }

  return (
    <>
      {props.notification.displayFields
        ?.filter(section => section.text || section.textList) // either text or textList should be defined
        .map(section => (
          <div className={classes.contentElementHeader}>
            <Typography
              variant="h6"
              color="primary"
              style={{ marginBottom: theme.spacing(1) }}
            >
              {section.title}
            </Typography>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
              }}
            >
              {section.text}
            </div>
            <div
              style={{
                paddingTop: theme.spacing(1),
                display: 'flex',
                flexWrap: 'wrap',
                gap: theme.spacing(1),
              }}
            >
              {section.textList &&
                section.textList.map(i => (
                  <Chip title={i} key={i} label={i} size="small" />
                ))}
            </div>
            {section.attachment && (
              <div
                style={{
                  paddingTop: theme.spacing(1),
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: theme.spacing(1),
                }}
              >
                <a
                  href={section.attachment.fileContent}
                  target="_blank"
                  download={section.attachment.fileName}
                >
                  <Tooltip title={`Download ${section.attachment.fileName}`}>
                    <LaunchIcon />
                  </Tooltip>
                </a>
              </div>
            )}
          </div>
        ))}
    </>
  );
};

// <a href="data:image/png;base64,..." download="abrakadabra_iLoveCats.jpg">download id</a>
