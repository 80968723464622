import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => {
  const MIN_HEIGHT = 45;
  const FONT_SIZE = theme.typography.body1.fontSize;
  const FONT_WEIGHT = 'normal';

  return {
    // Textfield
    root: {
      minHeight: MIN_HEIGHT,
      '&.Mui-focused button': {
        visibility: 'visible',
        pointerEvents: 'initial',
      },
      '&:hover button': {
        visibility: 'visible',
        pointerEvents: 'initial',
      },
    },
    pointerCursor: {
      cursor: 'pointer', // used by file picker to have an alternative cursor type on hover
    },
    inputRoot: {
      fontSize: FONT_SIZE,
      color: theme.palette.secondary.main,
      minHeight: MIN_HEIGHT,
    },
    inputLabelRoot: {
      color: theme.palette.secondary.main,
      fontWeight: 700,
      fontSize: FONT_SIZE,
    },
    input: {
      fontWeight: FONT_WEIGHT,
    },
    selectRoot: {
      '&:focus': {
        background: 'transparent',
      },
      '&$selectOutlined': {
        paddingRight: 50,
      },
    },
    selectOutlined: {},
    chipLabel: {
      wordBreak: 'break-all',
      paddingBlock: theme.spacing(0.5),
    },

    // Multiselect
    multiSelectChip: {
      borderRadius: '4px',
      backgroundColor: theme.palette.bkg.primary,
      '&:hover': {
        backgroundColor: theme.palette.bkg.primary,
      },
      fontWeight: 'normal',
      marginRight: 9,
      '&.MuiChip-deletable': {
        gap: theme.spacing(1),
      },
    },

    // Autocomplete
    autocompleteRoot: {
      minHeight: MIN_HEIGHT,
    },
    autocompleteEndAdornment: {
      right: '8px !important',
    },
    popupIndicator: {
      '&:before': {
        content: '""',
        position: 'absolute',
        borderLeft: `1px solid ${theme.palette.grey[400]}`,
        left: '-2px !important',
        height: '15px !important',
      },
      '&[title="Close"]': {
        '&:before': {
          transform: 'translate(31px)',
          transition: 'none',
        },
      },
    },
    clearIndicator: {
      marginRight: '2px !important',
      '& > span > svg': {
        fontSize: '1.125rem !important',
      },
    },
    autocompleteInputRoot: {
      paddingLeft: '9px !important',
    },
    smallAutocompleteInputRoot: {
      padding: '0px !important',
      paddingLeft: '9px !important',
    },
  };
});
