import { Box, Button, useTheme } from '@material-ui/core';
import React, { useState } from 'react';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { Deploy } from '../../types';
import { WbCardActionButton, WbDivider } from '@agilelab/plugin-wb-platform';
import { TerminateDialog } from './TerminateDialog';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';

interface Props {
  deploy: Deploy;
}

export const LatestProvisioningActions: React.FC<Props> = ({ deploy }) => {
  const { setIsLogsDrawerOpen, setSelectedDeploy } =
    useReleaseDetailPageContext();
  const theme = useTheme();

  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        style={{ gap: 16 }}
      >
        {deploy.status === DagStatus.RUNNING && (
          <>
            <WbCardActionButton
              color="primary"
              variant="contained"
              label="Terminate"
              onClick={() => setOpenDialog(true)}
              style={{
                backgroundColor: theme.palette.error.main,
              }}
            />
            <WbDivider />
          </>
        )}

        <Button
          variant="text"
          color="primary"
          onClick={() => {
            setSelectedDeploy(deploy);
            setIsLogsDrawerOpen(true);
          }}
        >
          Log File
        </Button>
      </Box>
      <TerminateDialog open={openDialog} onClose={() => setOpenDialog(false)} />
    </>
  );
};
