import cronstrue from 'cronstrue/i18n';
import cron from 'cron-validate';
import { Options } from 'cronstrue/dist/options';

const CRON_PRESET_CONFIG = {
  presetId: 'Governance-preset',
  useSeconds: true,
  useYears: false,
  useAliases: false,
  useBlankDay: true,
  allowOnlyOneBlankDayField: true,
  mustHaveBlankDayField: true,
  useLastDayOfMonth: false,
  useLastDayOfWeek: false,
  useNearestWeekday: false,
  useNthWeekdayOfMonth: false,
  seconds: {
    minValue: 0,
    maxValue: 59,
    lowerLimit: 0,
    upperLimit: 59,
  },
  minutes: {
    minValue: 0,
    maxValue: 59,
    lowerLimit: 0,
    upperLimit: 59,
  },
  hours: {
    minValue: 0,
    maxValue: 23,
    lowerLimit: 0,
    upperLimit: 23,
  },
  daysOfMonth: {
    minValue: 1,
    maxValue: 31,
    lowerLimit: 1,
    upperLimit: 31,
  },
  months: {
    minValue: 1,
    maxValue: 12,
    lowerLimit: 1,
    upperLimit: 12,
  },
  daysOfWeek: {
    minValue: 0,
    maxValue: 6,
    lowerLimit: 0,
    upperLimit: 6,
  },
  years: {
    minValue: 1970,
    maxValue: 2099,
    lowerLimit: 1970,
    upperLimit: 2099,
  },
};

export const parsedCron = (cronString: string) => {
  return cron(cronString, { preset: CRON_PRESET_CONFIG });
};

export const parseCron = (cronString: string, options?: Options): string =>
  cronstrue.toString(cronString, { monthStartIndexZero: false, ...options });

const getCronError = (cronString: string): string | undefined => {
  if (!cronString) return 'Cron string is required';

  const parsed = parsedCron(cronString);
  if (parsed.isError()) {
    return [...new Set(parsed.getError())].toString();
  }

  try {
    parseCron(cronString);
  } catch (e) {
    return e instanceof Error ? e.message : String(e);
  }

  return undefined;
};

export const isValidCron = (cronString: string): boolean => {
  return !getCronError(cronString);
};

export const getCronValidationMessage = (cronString: string) => ({
  message: getCronError(cronString),
});

export const getCronResult = (val: string | undefined): string => {
  if (!val) return ' ';

  if (isValidCron(val)) {
    return `${parseCron(val)} UTC`;
  }

  return ' ';
};

export type CronPreset = 'halfday' | 'day' | 'twodays' | 'week';

const PRESET_MAP: Record<CronPreset, string> = {
  halfday: '0 0 0,12 ? * *',
  day: '0 0 0 ? * *',
  twodays: '0 0 0 ? * 0,2,4,6',
  week: '0 0 0 ? * 0',
};

export function isCronPreset(value: string): value is CronPreset {
  return value in PRESET_MAP;
}

export const fromPresetToExp = (preset: CronPreset): string => {
  return PRESET_MAP[preset];
};

export const fromExpToPreset = (exp: string | undefined) => {
  if (exp === undefined) return 'none';
  const preset = Object.entries(PRESET_MAP).find(
    ([, value]) => value === exp,
  )?.[0] as CronPreset | undefined;
  return preset ?? 'custom';
};
