import {
  PolicyViolationOutcome,
  PolicyViolationsCountMap,
} from '@agilelab/plugin-wb-governance-common';
import { GovernancePolicyFlags } from '@agilelab/plugin-wb-marketplace-common';
import { Box, createStyles, makeStyles, Tooltip } from '@material-ui/core';
import FlagIcon from '@material-ui/icons/Flag';
import SyncProblemOutlinedIcon from '@material-ui/icons/SyncProblemOutlined';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(theme =>
  createStyles({
    iconBase: {
      // fix extra margin under the icon
      display: 'block',
    },
    infoIcon: {
      color: theme.palette.info.main,
    },
    warningIcon: {
      color: theme.palette.warning.main,
    },
    errorIcon: {
      color: theme.palette.error.main,
    },
    unavailableFlagsIcon: {
      color: theme.palette.softened.secondary,
    },
  }),
);

const getTooltip = (
  count: number,
  type: 'info' | 'warning' | 'error',
): string => {
  if (count === 1) return `${count} validation ${type}`;
  if (count > 1) return `${count} validation ${type}s`;
  return '';
};

function generateTooltipAndIcon(
  classes: ReturnType<typeof useStyles>,
  errorCount: number,
  warningCount: number,
  infoCount: number,
): { tooltipContent: ReactNode | undefined; iconClass: string | undefined } {
  if (errorCount > 0) {
    return {
      tooltipContent: (
        <div style={{ whiteSpace: 'pre-line' }}>
          {getTooltip(errorCount, 'error')} {'\n'}
        </div>
      ),
      iconClass: clsx(classes.iconBase, classes.errorIcon),
    };
  } else if (warningCount > 0) {
    return {
      tooltipContent: getTooltip(warningCount, 'warning'),
      iconClass: clsx(classes.iconBase, classes.warningIcon),
    };
  } else if (infoCount > 0) {
    return {
      tooltipContent: getTooltip(infoCount, 'info'),
      iconClass: clsx(classes.iconBase, classes.infoIcon),
    };
  }
  return {
    tooltipContent: undefined,
    iconClass: undefined,
  };
}

function violationsCountToGovernanceFlags(
  count?: Map<PolicyViolationOutcome, number>,
): GovernancePolicyFlags | undefined {
  if (!count) return undefined;
  return {
    info: count.get('info') ?? 0,
    warning:
      (count.get('warning') ?? 0) + (count.get('not_blocking_error') ?? 0),
    error: count.get('error') ?? 0,
  };
}

export type FlagBoxProps = {
  externalId: string;
  policyViolationsCountMap?: PolicyViolationsCountMap;
  error?: Error;
  loading?: boolean;
  iconStyle?: React.CSSProperties;
};

export const FlagBox = (props: FlagBoxProps) => {
  const { loading, error, policyViolationsCountMap: flagsMap } = props;
  const flags = violationsCountToGovernanceFlags(
    flagsMap?.get(props.externalId),
  );
  const classes = useStyles();

  const { tooltipContent, iconClass } = generateTooltipAndIcon(
    classes,
    flags?.error ?? 0,
    flags?.warning ?? 0,
    flags?.info ?? 0,
  );

  if (loading) return <></>;

  const flagIconWithTooltip = tooltipContent && (
    <Tooltip title={tooltipContent}>
      <FlagIcon style={props.iconStyle} className={iconClass} />
    </Tooltip>
  );

  return (
    <Box>
      {error && (
        <Tooltip title="Flags are temporarily unavailable">
          <SyncProblemOutlinedIcon
            style={props.iconStyle}
            className={clsx(classes.iconBase, classes.unavailableFlagsIcon)}
          />
        </Tooltip>
      )}

      {flagIconWithTooltip}
    </Box>
  );
};
