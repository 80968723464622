import React, { Fragment } from 'react';

import { useMarketplaceStyle } from '../../themes';

type Props = {
  value: string;
  href?: string;
};

export const SimpleItem = ({ value, href }: Props) => {
  const classes = useMarketplaceStyle();

  return href ? (
    <a target="_blank" rel="noreferrer" href={href} className={classes.href}>
      {value || '-'}
    </a>
  ) : (
    <Fragment>{value}</Fragment>
  );
};
