import { SvgIcon } from '@material-ui/core';
import React from 'react';

export const CheckLockIcon = () => (
  <SvgIcon>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1223_30034)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.2486 11.7248H20.4978C21.1606 11.726 21.7959 11.9864 22.2648 12.4489C22.7337 12.9113 22.9981 13.5383 23 14.1927V16.0619C19.0537 16.554 16 19.9204 16 24C16 25.8919 16.6567 27.6304 17.7546 29H5.49969C4.83733 28.9981 4.20265 28.7375 3.73429 28.275C3.26593 27.8126 3.00195 27.186 3 26.5321V14.1927C3.00195 13.5387 3.26593 12.9121 3.73429 12.4497C4.20265 11.9873 4.83733 11.7267 5.49969 11.7248H6.75015V9.25688C6.73854 8.43937 6.8916 7.62774 7.20044 6.86915C7.50928 6.11055 7.96774 5.42011 8.54919 4.83795C9.13064 4.2558 9.82347 3.79353 10.5874 3.478C11.3514 3.16248 12.1713 3 12.9994 3C13.8275 3 14.6474 3.16248 15.4113 3.478C16.1753 3.79353 16.8681 4.2558 17.4496 4.83795C18.031 5.42011 18.4895 6.11055 18.7983 6.86915C19.1072 7.62774 19.2602 8.43937 19.2486 9.25688V11.7248ZM11.6088 22.415C12.0199 22.6861 12.5031 22.8309 12.9975 22.8309L12.9988 22.8297C13.6607 22.8274 14.2949 22.5668 14.7629 22.1047C15.231 21.6426 15.495 21.0165 15.4972 20.363C15.4972 19.8749 15.3506 19.3977 15.076 18.9919C14.8013 18.5861 14.4109 18.2697 13.9541 18.083C13.4974 17.8962 12.9948 17.8473 12.5099 17.9425C12.025 18.0377 11.5796 18.2728 11.23 18.6179C10.8804 18.9631 10.6423 19.4028 10.5459 19.8815C10.4494 20.3603 10.4989 20.8565 10.6881 21.3074C10.8773 21.7584 11.1977 22.1438 11.6088 22.415ZM9.12566 11.7248H16.8719V9.25688C16.8849 8.74662 16.7944 8.23894 16.6056 7.76377C16.4168 7.28859 16.1336 6.85553 15.7726 6.49009C15.4117 6.12464 14.9803 5.83422 14.5039 5.63592C14.0275 5.43763 13.5158 5.33547 12.9988 5.33547C12.4818 5.33547 11.97 5.43763 11.4936 5.63592C11.0172 5.83422 10.5858 6.12464 10.2249 6.49009C9.86397 6.85553 9.58076 7.28859 9.39197 7.76377C9.20319 8.23894 9.11264 8.74662 9.12566 9.25688V11.7248Z"
          fill="#4E6980"
        />
        <rect
          x="17.5"
          y="17.5"
          width="13"
          height="13"
          rx="6.5"
          fill="#00C853"
        />
        <path
          d="M27.6968 22.5952L27.0585 23.2375L24.1926 26.122L23.487 26.8321C23.4339 26.8857 23.3703 26.9275 23.3002 26.9548C22.8743 27.1575 22.4895 26.6289 22.2168 26.3543L20.8345 24.9628C20.6556 24.7832 20.4142 24.5486 20.5305 24.2641C20.561 24.1674 20.6166 24.0806 20.6915 24.0128C20.7665 23.945 20.858 23.8986 20.9568 23.8783C21.0555 23.858 21.1578 23.8647 21.2531 23.8975C21.3484 23.9304 21.4333 23.9882 21.499 24.0651L22.7683 25.3423L23.0882 25.6644L25.3881 23.3494L26.6685 22.0605C26.9515 21.7755 27.3279 21.2738 27.7715 21.6142C27.7938 21.6315 27.8145 21.6505 27.8337 21.6711C27.8542 21.6903 27.8731 21.7113 27.8902 21.7337C28.1353 22.0567 27.9257 22.3624 27.6945 22.5952H27.6968Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1223_30034">
          <rect
            width="29"
            height="29"
            fill="white"
            transform="translate(3 3)"
          />
        </clipPath>
      </defs>
    </svg>
  </SvgIcon>
);
