import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import {
  ErrorIcon,
  FailedIcon,
  InformationIcon,
  NotBlockingIcon,
  NotStartedIcon,
  OkIcon,
  PassedIcon,
  RunningIcon,
  TerminatedIcon,
  TerminatingIcon,
  WarningIcon,
} from './Icons';
import {
  Severity,
  Status as PolicyStatus,
} from '@agilelab/plugin-wb-governance-common';

const useStyles = makeStyles((theme: Theme) => ({
  testStatusIcon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
}));

const iconByDagStatus: Record<
  DagStatus,
  ((props: Props) => JSX.Element) | undefined
> = {
  [DagStatus.FAILED]: props => <FailedIcon {...props} />,
  [DagStatus.ERROR]: props => <ErrorIcon {...props} />,
  [DagStatus.PASSED]: props => <PassedIcon {...props} />,
  [DagStatus.NOT_EXECUTED]: props => <NotStartedIcon {...props} />,
  [DagStatus.NOT_STARTED]: props => <NotStartedIcon {...props} />,
  [DagStatus.TERMINATED]: props => <TerminatedIcon {...props} />,
  [DagStatus.TERMINATING]: props => <TerminatingIcon {...props} />,
  [DagStatus.RUNNING]: props => <RunningIcon {...props} />,
  [DagStatus.WAITING]: props => <RunningIcon {...props} />,
  [DagStatus.INFO]: props => <InformationIcon {...props} />,
  [DagStatus.NOT_BLOCKING_ERROR]: props => <NotBlockingIcon {...props} />,
  [DagStatus.OK]: props => <OkIcon {...props} />,
  [DagStatus.WARNING]: props => <WarningIcon {...props} />,
};

interface Props {
  status: DagStatus;
  okWithWarnings?: boolean;
  filled?: boolean;
  severity?: Severity;
  policyStatus?: PolicyStatus;
  style?: React.CSSProperties;
  variant?: 'default' | 'reduced';
}

export const TestStatus: React.FC<Props> = props => {
  const classes = useStyles();
  const statusIcon = iconByDagStatus[props.status];
  return (
    <div className={classes.testStatusIcon}>
      {statusIcon ? statusIcon(props) : <></>}
    </div>
  );
};
