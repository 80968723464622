import { Box, Divider, Typography } from '@material-ui/core';
import React from 'react';

export type ChartDetail = {
  color: string;
  label: string;
  value: number;
  percentage: number;
};

function renderLegend(details: ChartDetail[]) {
  return details.map(detail => (
    <>
      <Box
        component="div"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box
          component="div"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Box
            component="div"
            style={{
              width: '16px',
              height: '16px',
              background: detail.color,
              borderRadius: '5px',
              marginRight: '5px',
            }}
          />
          <Typography>{detail.label}</Typography>
        </Box>
        <Box
          component="div"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Typography style={{ marginRight: '5px' }}>{detail.value}</Typography>
          <Typography>({detail.percentage}%)</Typography>
        </Box>
      </Box>
      <Divider style={{ margin: '9px', width: 'calc(100% - 20px)' }} />
    </>
  ));
}

export interface OverviewComponentProps {
  chartDetails: ChartDetail[];
}

export const OverviewComponent = (props: OverviewComponentProps) => {
  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
        overflowY: 'auto',
      }}
    >
      {renderLegend(props.chartDetails)}
    </Box>
  );
};
