import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  WbAsyncSelect,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';
import {
  catalogApiRef,
  entityRouteRef,
  getEntitySourceLocation,
} from '@backstage/plugin-catalog-react';
import { EntityWithIcon } from '../../types';
import {
  CARD_AVATAR_DIMENSION,
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
  CARD_REDUCED_HEIGHT,
} from './const';
import { parseEntityRef, stringifyEntityRef } from '@backstage/catalog-model';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { scmIntegrationsApiRef } from '@backstage/integration-react';
import CodeIcon from '@material-ui/icons/Code';
import { panelCatalogApiRef } from '../../../../api';
import parseGitUrl from 'git-url-parse';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import { checkExistingEditTemplate } from '../../../common/utils';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import CenterFocusStrong from '@material-ui/icons/CenterFocusStrong';
import CenterFocusWeakIcon from '@material-ui/icons/CenterFocusWeak';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import { useLocation, useNavigate } from 'react-router';

type StylesProps = { disabled?: boolean } | undefined;

const useStyles = makeStyles(theme => ({
  container: (props: StylesProps = { disabled: false }) => ({
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    marginBottom: CARD_MARGIN_BOTTOM,
    minHeight: props?.disabled ? CARD_REDUCED_HEIGHT : CARD_HEIGHT,
    maxHeight: props?.disabled ? CARD_REDUCED_HEIGHT : CARD_HEIGHT,
    height: props?.disabled ? CARD_REDUCED_HEIGHT : CARD_HEIGHT,
    borderRadius: '4px',
    minWidth: 'fit-content',
  }),
  avatar: {
    width: CARD_AVATAR_DIMENSION,
    height: CARD_AVATAR_DIMENSION,
    borderRadius: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '12px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    border: `1px solid ${theme.palette.grey[200]}`,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
  },
}));

interface Props {
  entity: EntityWithIcon;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  short?: boolean;
}

const Avatar: React.FC<{ name: string; icon?: string }> = ({ icon, name }) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.avatar}
      style={{
        backgroundImage: icon ? `url(${icon})` : '',
      }}
    >
      {!icon &&
        name
          .split(' ')
          .slice(0, 2)
          .map(s => s.charAt(0))}
    </Box>
  );
};

export const EntityCard: React.FC<Props> = ({
  entity,
  style,
  className,
  disabled,
  short = false,
}) => {
  const classes = useStyles({ disabled });
  const panelCatalog = useApi(panelCatalogApiRef);
  const {
    changeBranches,
    currRelease,
    editorRef,
    focusedEntityUrn,
    setFocusedEntityUrn,
    parent,
  } = useEditorPageContext();
  const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
  const catalogApi = useApi(catalogApiRef);
  const name: string = (entity?.spec?.mesh as any)?.name || '';
  const icon = entity?.icon;
  const [errorMessage, setErrorMessage] = useState('');
  const entitySourceLocation = useMemo(() => {
    return entity ? getEntitySourceLocation(entity, scmIntegrationsApi) : null;
  }, [entity, scmIntegrationsApi]);

  const entityUrn = generateURNByKind(entity.metadata.name, entity.kind);
  const entityRef = entity
    ? stringifyEntityRef({
        name: entity?.metadata?.name || '',
        kind: entity?.kind,
        namespace: entity?.metadata?.namespace || '',
      })
    : undefined;
  const entityRoute = useRouteRef(entityRouteRef);

  const defaultBranch = useMemo(() => {
    const createdFrom = currRelease?.metadata?.createdFrom?.find(
      f => f.entityRef === entityRef,
    );
    const branchFromCreatedFrom = createdFrom?.branch?.replace('%2F', '/');
    const branchFromLocationUrl = entitySourceLocation?.locationTargetUrl
      ? parseGitUrl(entitySourceLocation.locationTargetUrl).ref ?? 'master'
      : 'master';

    const rightBranch = branchFromCreatedFrom ?? branchFromLocationUrl;
    return rightBranch.replace('refs/heads/', '');
  }, [
    entitySourceLocation?.locationTargetUrl,
    currRelease?.metadata?.createdFrom,
    entityRef,
  ]);

  const [targetBranch, setTargetBranch] = useState<string>(defaultBranch);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  /**
   * this effects sets the right default branch when the editor tab opens.
   * This is because `master` is not always the default branch, for example for
   * dp created using the `new version` feature. Another case is that, after a new draft release
   * is created from different branches, when refreshing the editor you want to see set as default
   * the branches from which that draft release was created.
   */
  useEffect(() => {
    if (defaultBranch !== 'master' && defaultBranch !== '' && entityRef) {
      changeBranches(entityRef, defaultBranch);
      setTargetBranch(defaultBranch);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultBranch, entityRef]);

  const route = useMemo(() => {
    if (entity) {
      const data = {
        name: entity?.metadata.name ?? '',
        kind: entity?.kind,
        namespace: entity?.metadata.namespace ?? '',
      };
      const ref = parseEntityRef(data);
      const preUrl = location.pathname.includes('my-projects')
        ? '/my-projects'
        : '';

      return `${preUrl}${entityRoute(ref)}/editor`;
    }
    return '/';
  }, [entity, entityRoute, location.pathname]);

  const editorWizardRoute = useMemo(() => {
    if (!entity) return '#';
    const { namespace = 'default', name: entityName } = entity.metadata;
    const kindLowerCase = entity.kind.toLowerCase();

    const hrefEditorWizard = `wb-scaffolder/${encodeURIComponent(
      namespace,
    )}/${encodeURIComponent(kindLowerCase)}/${encodeURIComponent(
      entityName,
    )}/editor-wizard?targetBranch=${targetBranch}`;

    return hrefEditorWizard;
  }, [entity, targetBranch]);

  useEffect(() => {
    checkExistingEditTemplate({ catalogApi, entity, setErrorMessage });
  }, [entity, setErrorMessage, catalogApi]);

  const getBranches = useCallback(async () => {
    const result = await panelCatalog.getBranches(
      entitySourceLocation?.locationTargetUrl!,
    );

    return Object.keys(result);
  }, [entitySourceLocation?.locationTargetUrl, panelCatalog]);

  const highlightHandleClick = useCallback(() => {
    if (editorRef?.current) {
      const entityIdLine = editorRef.current.findLineContainingId(entityUrn);
      const lineRanges =
        editorRef.current.selectLinesOfOtherSections(entityUrn);

      if (lineRanges.ranges()?.length !== 0) {
        setFocusedEntityUrn(entityUrn);
        editorRef.current.highlightLineRanges(lineRanges, 'blur-line');
        editorRef.current.goToLine(entityIdLine, false, 200);
      }
    }
  }, [entityUrn, editorRef, setFocusedEntityUrn]);

  useEffect(() => {
    if (!disabled && parent) {
      highlightHandleClick();
    }
  }, [disabled, highlightHandleClick, parent]);

  const locationTargetUrl = useMemo(() => {
    const str = entitySourceLocation?.locationTargetUrl;
    const lastIndex = str?.lastIndexOf(defaultBranch);
    if (lastIndex === undefined || lastIndex === -1) {
      return str;
    }
    return `${str?.substring(0, lastIndex)}${targetBranch}${str?.substring(
      lastIndex + defaultBranch.length,
    )}`;
  }, [entitySourceLocation?.locationTargetUrl, targetBranch, defaultBranch]);

  const onFocusToggle = () => {
    if (focusedEntityUrn === entityUrn) {
      setFocusedEntityUrn(null);
      editorRef?.current?.highlightLines([]);
      return;
    }
    highlightHandleClick();
  };

  const focused = entityUrn === focusedEntityUrn;

  const editTooltip =
    errorMessage === '' ? `Edit on branch ${targetBranch}` : errorMessage;

  const focusTooltip = focused ? 'Unfocus' : 'Focus in the descriptor';

  const viewCodeTooltip = 'View Source';

  return (
    <Box style={style} className={clsx(classes.container, className)}>
      <Avatar name={name} icon={icon} />

      <Box style={{ flex: 1 }} className={classes.column}>
        <Link to={route}>
          <WbTruncatedTypographyWrapper
            value={name}
            maxLines={1}
            style={{ fontWeight: 700 }}
            enableTooltip
            placement="top-start"
          />
        </Link>

        {!disabled && (
          <WbAsyncSelect<string>
            field={!short ? 'Branch' : undefined}
            defaultValue={defaultBranch}
            onChange={value => {
              changeBranches(entityRef!, value);
              setTargetBranch(value);
              setFocusedEntityUrn(null);
            }}
            getOptions={() => getBranches()}
            renderOption={o => (
              <WbTruncatedTypographyWrapper
                style={{ display: 'inline' }}
                value={o}
              />
            )}
            renderValue={o => o}
            onSearch={(v, op) => op.filter(o => new RegExp(v, 'ig').test(o))}
            closeOnSelect
          />
        )}
      </Box>

      <Box style={{ display: 'flex', gap: '2px' }}>
        {short ? (
          <>
            <IconButton
              onClick={openMenu}
              size="small"
              style={{ height: 'fit-content' }}
            >
              <MoreVertIcon style={{ fontSize: '16px' }} />
            </IconButton>

            <Menu
              open={open}
              anchorEl={anchorEl}
              getContentAnchorEl={null}
              onClose={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                e.stopPropagation();
                closeMenu();
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
              <Tooltip title={editTooltip} placement="top">
                <div>
                  <MenuItem
                    disabled={errorMessage !== ''}
                    onClick={() => {
                      navigate(`/${editorWizardRoute}`);
                      closeMenu();
                    }}
                  >
                    <Typography>Edit</Typography>
                  </MenuItem>
                </div>
              </Tooltip>
              {!parent && (
                <MenuItem
                  onClick={() => {
                    onFocusToggle();
                    closeMenu();
                  }}
                >
                  <Tooltip title={focusTooltip} placement="top">
                    <Typography>{focused ? 'Unfocus' : 'Focus'}</Typography>
                  </Tooltip>
                </MenuItem>
              )}
              <MenuItem disabled={!locationTargetUrl}>
                <Tooltip title={viewCodeTooltip} placement="top">
                  <a href={locationTargetUrl} target="_blank" rel="noreferrer">
                    <Typography>{viewCodeTooltip}</Typography>
                  </a>
                </Tooltip>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Tooltip title={editTooltip} placement="top">
              <span>
                <IconButton
                  style={{ height: 'fit-content' }}
                  size="small"
                  onClick={() => navigate(`/${editorWizardRoute}`)}
                  disabled={errorMessage !== ''}
                >
                  <FormatListBulleted style={{ fontSize: '16px' }} />
                </IconButton>
              </span>
            </Tooltip>
            {!parent && (
              <Tooltip title={focusTooltip} placement="top">
                <span>
                  <IconButton
                    disabled={!!parent}
                    size="small"
                    style={{ height: 'fit-content' }}
                    onClick={onFocusToggle}
                  >
                    {focused ? (
                      <CenterFocusStrong style={{ fontSize: '18px' }} />
                    ) : (
                      <CenterFocusWeakIcon style={{ fontSize: '18px' }} />
                    )}
                  </IconButton>
                </span>
              </Tooltip>
            )}

            <Tooltip title={viewCodeTooltip} placement="top">
              <IconButton
                component={Link}
                aria-label={viewCodeTooltip}
                disabled={!locationTargetUrl}
                to={locationTargetUrl ?? '#'}
                size="small"
                style={{ height: 'fit-content' }}
              >
                <CodeIcon style={{ fontSize: '18px' }} />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Box>
    </Box>
  );
};
