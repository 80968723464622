import React, { RefObject, useEffect, useRef } from 'react';
import { TextBox } from './TextBox';
import { Box, IconButton, makeStyles, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { WittyIcon } from './icons/WittyIcon';
import {
  ChatContextProvider,
  ChatContextType,
  useChatContext,
} from './ChatContext';
import { Message } from './Messages';
import { getOnHoverScrollbarStyle } from '../../styles';
import { IncomingMessage } from './Messages/IncomingMessage';

type ChatProps = Partial<ChatContextType> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      height: '100%',
      width: 'inherit',
      borderLeft: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.background.default,
      display: 'flex',
      flexDirection: 'column',
      paddingRight: theme.spacing(0.5),
      paddingBlock: theme.spacing(2),
    },
    boxChat: {
      paddingInline: theme.spacing(2),
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      ...getOnHoverScrollbarStyle(theme, false),
    },
    wittyHeader: {
      paddingBottom: theme.spacing(1),
      paddingInline: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
    },
    fillMessageContainerTop: {
      flex: 1,
    },
    textboxWrapper: {
      width: '100%',
      paddingInline: theme.spacing(2),
    },
  }),
  { name: 'witty-chat' },
);

const WittyHeader = ({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Box className={classes.wittyHeader}>
      <WittyIcon style={{ color: theme.palette.witboostLogo }} />
      <IconButton
        aria-label="clear"
        onClick={() => setOpen(false)}
        size="small"
        color="primary"
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

const WittyContent = () => {
  const classes = useStyles();
  const chatEndRef: RefObject<HTMLDivElement> = useRef(null);
  const { messages, incomingMessage } = useChatContext();

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);
  return (
    <>
      <Box className={classes.boxChat}>
        <Box className={classes.fillMessageContainerTop} />
        {messages?.map(m => (
          <Message sender={m.sender} contentMessage={m.contentMessage} />
        ))}
        {incomingMessage && <IncomingMessage />}
        <div ref={chatEndRef} />
      </Box>
      <Box className={classes.textboxWrapper}>
        <TextBox />
      </Box>
    </>
  );
};

export const WittyChat: React.FC<ChatProps> = ({ setOpen, ...props }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <ChatContextProvider {...props}>
        <WittyHeader setOpen={setOpen} />
        <WittyContent />
      </ChatContextProvider>
    </Box>
  );
};
