import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const DraftIcon = createSvgIcon(
  <>
    <g id="Layer_1-2">
      <g id="Group_535">
        <path d="m8.03.66l-2.25,2.25c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0L10.81.26c.14-.14.34-.23.54-.24.66-.03,1.32-.01,1.97.05l-5.15,5.15c-.33.33-.33.86,0,1.19h0c.33.33.86.33,1.19,0L14.96.81c.21-.21.52-.3.81-.21.42.14.83.3,1.23.48l-6.5,6.5c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0l6.37-6.37c.29-.29.74-.32,1.07-.08,0,0,0,0,.01,0,.39.29.43.88.08,1.22l-6.37,6.37c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0l6.38-6.38c.35-.35.93-.31,1.22.08,0,0,0,0,0,.01.24.33.21.78-.08,1.07l-6.37,6.37c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0l6.51-6.51c.19.39.35.8.49,1.21.1.29.01.6-.2.82l-5.64,5.64c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0l5.2-5.2c.07.64.09,1.3.07,1.95,0,.2-.1.4-.24.54l-3.86,3.86c-.32.32-.32.83,0,1.15l.04.04c.32.32.83.32,1.15,0l2.29-2.29c-.58,1.68-1.53,3.26-2.88,4.6-4.68,4.68-12.28,4.69-16.97,0s-4.68-12.29,0-16.97c1.32-1.32,2.86-2.27,4.52-2.85Z" />
      </g>
    </g>
  </>,
  'DraftIcon',
);
