import {
  ConfirmDialog,
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import React, { useState } from 'react';
import { panelCatalogApiRef } from '../../../../api';
import { useReleasePageContext } from '../../context/useReleasePageContext';
import { Tooltip, Typography } from '@material-ui/core';
import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { releaseDetailRouteRef } from '../../../../routes';
import { generateUpdateTooltip, getReleaseInfo } from '../../../../utils';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { usePermission } from '@backstage/plugin-permission-react';
import { builderDpCommitPermission } from '@agilelab/plugin-wb-rbac-common';

interface Props {
  release: ReleaseAndDeployments;
}

export const UpdateButton: React.FC<Props> = ({ release }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const {
    entity,
    isCommitting,
    setIsCommitting,
    isLoading,
    addRelease,
    processorErrors,
  } = useReleasePageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);
  const createdFrom = release?.metadata?.createdFrom;
  const setBranches = new Set(
    createdFrom?.map(cf => cf.branch.replace('%2F', '/')),
  );

  const { allowed: canCommit } = usePermission({
    permission: builderDpCommitPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const [_, commitSnapshot] = useAsyncFn(async () => {
    setIsCommitting(true);
    try {
      const newRelease = await panelCatalog.commitSnapshot(
        entity.metadata.name,
        entity.kind,
        createdFrom,
      );
      addRelease({ ...newRelease, deployments: release.deployments });

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
          />
        ),

        message: 'Draft-Release updated.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsCommitting(false);
      setOpenDialog(false);
    }
  }, [
    addRelease,
    alertApi,
    entity.metadata.name,
    panelCatalog,
    setIsCommitting,
    release.deployments,
  ]);

  return (
    <>
      <Tooltip
        title={generateUpdateTooltip(
          canCommit,
          undefined,
          false,
          isLoading,
          processorErrors,
          [...setBranches],
        )}
      >
        <span>
          <WbCardActionButton
            label="Update"
            color="primary"
            size="small"
            loading={isCommitting}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setOpenDialog(true);
            }}
            disabled={!canCommit || isLoading || !!processorErrors?.length}
          />
        </span>
      </Tooltip>
      <ConfirmDialog
        title="A draft release already exists!"
        description={
          <>
            <Typography>
              By updating you will lose the current draft release and a new one
              will be created.
            </Typography>
            <Typography style={{ fontWeight: '700' }}>
              Are you sure you want to proceed with the update?
            </Typography>
          </>
        }
        open={openDialog}
        onConfirm={commitSnapshot}
        onClose={() => setOpenDialog(false)}
        inProgress={isCommitting}
        confirmButtonText="Yes, update"
      />
    </>
  );
};
