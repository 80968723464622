import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { EntityKindSelector } from './EntityKindSelector';
import { useGeneralPurposePickerContext } from '../GeneralPurposePickerContext';
import { SelectableEntityTable } from './SelectableEntityTable';
import { GenericEntityType, Provider, TextFieldValue } from '../types';
import { WbCardContent } from '../../WbCard';
import { WbNoData } from '../../WbNoData';
import { ProviderFilters } from './CommonFilter';

interface SingleSelectionProps {
  availableKinds: Record<string, any>[];
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  selectedEntityProvider?: JSX.Element;
  setRenderTable: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
}

const EntityList = ({
  selectedKind,
  providerMapper,
  entities,
  setEntities,
}: {
  selectedKind: string;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  entities: TextFieldValue[];
  setEntities: (values: TextFieldValue[]) => void;
}) => {
  const provider = providerMapper.get(selectedKind);
  const context = provider?.useContext();
  return (
    <SelectableEntityTable
      loading={context?.entitiesState.loading ?? false}
      data={(context?.entitiesState.value as GenericEntityType[]) ?? []}
      entities={entities}
      onChangeHandler={setEntities}
      filtersSection={provider?.filtersSection!}
      pagination={context?.pagination!}
      setPagination={context?.setPagination!}
      count={context?.count ?? 0}
      nextCursor={context?.nextCursor}
      prevCursor={context?.prevCursor}
      columns={context?.columns ?? []}
      templateEntity={context?.templateKind!}
    />
  );
};

export function SingleSelection(props: SingleSelectionProps) {
  const {
    availableKinds,
    providerMapper,
    selectedEntityProvider,
    setRenderTable,
  } = props;
  const { selectedValues, setSelectedValues, setOpenDialog, selectedKind } =
    useGeneralPurposePickerContext();

  useMemo(() => {
    setRenderTable(
      <EntityList
        selectedKind={selectedKind}
        providerMapper={providerMapper}
        entities={selectedValues?.tableValues ?? []}
        setEntities={e => {
          setSelectedValues({ tableValues: e });
          setOpenDialog(false);
        }}
      />,
    );
  }, [
    providerMapper,
    selectedKind,
    selectedValues?.tableValues,
    setOpenDialog,
    setRenderTable,
    setSelectedValues,
  ]);

  return (
    <WbCardContent
      style={{
        height: '64vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box display="flex" justifyContent="space-between" paddingY="8px">
        <Typography variant="body1">
          Select an entity from the list below
        </Typography>
        {availableKinds.length > 1 && (
          <EntityKindSelector availableKinds={availableKinds} />
        )}
      </Box>

      {selectedEntityProvider ?? <WbNoData />}
    </WbCardContent>
  );
}
