import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'builder_entity_content',
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="catalog_warnings" />
        <CustomViewComponent type="include" id="builder_system_general" />
      </>
    );
  },
});
CustomViewPages.register({
  id: 'builder_component',
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="catalog_warnings" />
        <CustomViewComponent type="include" id="builder_component_general" />
        <CustomViewComponent type="catalog_components" />
      </>
    );
  },
});
