import { FormControl, FormHelperText } from '@material-ui/core';
import { FieldProps } from '@rjsf/core';
import React, { useCallback, useEffect, useState } from 'react';
import lodash from 'lodash';
import { WbAutocomplete, WbTextField } from '@agilelab/plugin-wb-platform';
import {
  Descriptor,
  DescriptorPickerSchema,
  DescriptorPickerSchemaZod,
  Option,
  SourceType,
} from './types';
import { fromZodError } from 'zod-validation-error';
import { getSourceType } from './sourceTypes';
import { extractCustomProperties, isHidden } from '../../utils';

export function isObjectInPath(
  descriptor: { [key: string]: any },
  path: string,
): boolean {
  return lodash.isPlainObject(lodash.get(descriptor, path, undefined));
}

export function isArrayInPath(
  descriptor: { [key: string]: any },
  path: string,
): boolean {
  return Array.isArray(lodash.get(descriptor, path, undefined));
}

function parseOptionsFromArray(
  descriptor: { [key: string]: any } | undefined,
  optionsAt: string,
  optionsDisplayNameAt?: string,
): Option[] {
  return lodash
    .get(descriptor, optionsAt, [])
    .map((item: { [x: string]: any }, index: number) => {
      const optionDisplayName =
        optionsDisplayNameAt && item[optionsDisplayNameAt]
          ? item[optionsDisplayNameAt]
          : item;

      return {
        label:
          typeof optionDisplayName === 'string'
            ? optionDisplayName
            : String(index),
        key: String(index),
        value: item,
      };
    });
}

function parseOptionsFromObject(
  descriptor: { [key: string]: any } | undefined,
  optionsAt: string,
  optionsDisplayNameAt?: string,
): Option[] {
  const objectParsed: { [key: string]: any } = lodash.get(
    descriptor,
    optionsAt,
    {},
  );

  return Object.keys(objectParsed).map(key => ({
    label: optionsDisplayNameAt
      ? lodash.get(objectParsed[key], optionsDisplayNameAt, key)
      : key,
    key: key,
    value: objectParsed[key],
  }));
}

function getOptionKey(
  formContext: { [key: string]: any },
  fieldName: string,
): string | undefined {
  return (formContext[fieldName] as Option)?.key;
}

export function parseOptions(
  descriptor: { [key: string]: any } | undefined,
  optionsAt: string,
  optionsDisplayNameAt?: string,
): Option[] {
  if (!descriptor || Object.keys(descriptor).length === 0) {
    return [];
  }

  const parseArray = isArrayInPath(descriptor, optionsAt);
  const parseObject = isObjectInPath(descriptor, optionsAt);

  if (parseArray) {
    return parseOptionsFromArray(descriptor, optionsAt, optionsDisplayNameAt);
  }

  if (parseObject) {
    return parseOptionsFromObject(descriptor, optionsAt, optionsDisplayNameAt);
  }

  if (!parseArray && !parseObject) {
    throw new Error(
      `The path specified at 'optionsAt' is neither an object nor a list. Please contact the platform team. (optionsAt = "${optionsAt}")`,
    );
  }

  return [];
}

export function getSourceDescriptor(
  formContext: { [key: string]: any },
  sourceType: SourceType,
  source: string,
): Descriptor | undefined {
  const parsedDescriptor = lodash.get(
    formContext,
    sourceType.resolveSource(source),
    undefined,
  );

  return sourceType.mapDescriptor(parsedDescriptor);
}

function parseOption(input: any): Option | null {
  const optionKeys = ['key', 'label', 'value'];
  if (
    input &&
    typeof input === 'object' &&
    optionKeys.every(key => key in input)
  ) {
    return input as Option;
  }
  return null;
}

export const DescriptorPicker = ({
  onChange,
  required,
  schema,
  uiSchema,
  rawErrors,
  idSchema,
  formData,
  formContext,
}: FieldProps<string>) => {
  const { title, description } = schema;

  const [pickerConfig, setPickerConfig] = useState<
    DescriptorPickerSchema | undefined
  >(undefined);
  const [pickerError, setPickerError] = useState<Error | undefined>(undefined);
  const customProps = extractCustomProperties(uiSchema);

  useEffect(() => {
    const parsedPickerConfig = DescriptorPickerSchemaZod.safeParse(schema);
    if (!parsedPickerConfig.success) {
      setPickerConfig(undefined);
      const errorMessage = `This field has been disabled due to a misconfiguration error. ${
        fromZodError(parsedPickerConfig.error).message
      }. Please contact the platform team
          to address this issue.`;
      setPickerError(new Error(errorMessage));
      return;
    }
    setPickerConfig(parsedPickerConfig.data);
  }, [schema]);

  const [descriptor, setDescriptor] = useState<Descriptor | undefined>();
  const [options, setOptions] = useState<Option[]>([]);
  const [value, setValue] = useState<Option | null | undefined>(
    parseOption(formData),
  );

  /**
   * A watch value to track changes from. If this value changes then the selected option in the descriptor picker is reset
   */
  const [watchOptionKey, setWatchOptionKey] = useState<string | undefined>(
    pickerConfig && pickerConfig.sourceType === 'field'
      ? getOptionKey(formContext, pickerConfig.source)
      : undefined,
  );

  /**
   * Checks whether the dependent field changes its value, if so, the selected value for this field is set to none
   * If the field does not depend on any field, this effect has no effect (seems a joke, right?)
   */
  useEffect(() => {
    // TODO is there a smarter way?
    if (pickerConfig?.sourceType === 'field') {
      if (!formContext[pickerConfig.source]) {
        setOptions([]);
        setWatchOptionKey(undefined);
        setValue(null);
        onChange(undefined);
        return;
      }

      if (watchOptionKey !== formContext[pickerConfig.source]?.key) {
        setOptions([]);
        setWatchOptionKey(formContext[pickerConfig.source]?.key);
        setValue(null);
        onChange(undefined);
      }
    }
  }, [
    formContext,
    onChange,
    pickerConfig,
    pickerConfig?.sourceType,
    pickerConfig?.source,
    watchOptionKey,
  ]);

  useEffect(() => {
    if (pickerConfig?.sourceType) {
      const sourceType = getSourceType(pickerConfig.sourceType);
      if (!sourceType) {
        setPickerError(
          new Error(
            `The sourceType specified is invalid. Please contact the platform team. (sourceType = "${pickerConfig.sourceType}")`,
          ),
        );
        return;
      }

      const parsedDescriptor = getSourceDescriptor(
        formContext,
        sourceType,
        pickerConfig.source,
      );
      if (!parsedDescriptor && pickerConfig.sourceType !== 'field') {
        setPickerError(
          new Error(
            `Could not find any source descriptor in the context. Please contact the platform team. (Having sourceType = "${pickerConfig.sourceType}" and source = "${pickerConfig.source}")`,
          ),
        );
        return;
      }

      setDescriptor(parsedDescriptor);

      try {
        const parsedOptions = parseOptions(
          parsedDescriptor,
          pickerConfig.optionsAt,
          pickerConfig.optionsDisplayNameAt,
        );
        setOptions(parsedOptions);
      } catch (error) {
        setPickerError(error);
        return;
      }
    }
  }, [formContext, pickerConfig]);

  const onSelect = useCallback(
    (_: any, option: Option | null) => {
      if (!option || !descriptor) {
        onChange(undefined);
        setValue(null);
        return;
      }

      const onChangeVal = {
        ...option,
        value: option.value,
      };

      onChange(onChangeVal);
      setValue(option);
    },
    [descriptor, onChange],
  );

  if (pickerError) {
    return (
      <FormControl
        style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
        margin="normal"
        required={required}
        error
      >
        <WbTextField
          label={title}
          margin="dense"
          helperText={description}
          FormHelperTextProps={{
            margin: 'dense',
            style: { marginLeft: 0 },
          }}
          variant="outlined"
          required={required}
          disabled
          {...customProps}
        />
        <FormHelperText id="component-error-text">
          {pickerError.message}
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !value}
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
    >
      <WbAutocomplete
        disabled={!descriptor}
        id={idSchema?.$id}
        value={value || null}
        onChange={onSelect}
        options={options || []}
        getOptionLabel={(option: Option) => option.label}
        getOptionSelected={(option: Option, selectedValue: Option) =>
          option.key === selectedValue.key
        }
        clearOnBlur
        autoSelect
        freeSolo={false}
        helperText={description}
        label={title}
        required={required}
        {...customProps}
      />
    </FormControl>
  );
};
