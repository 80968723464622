import './DateRangeFilter.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import React, { useState } from 'react';
import {
  Box,
  Popover,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { FilterInput } from '../FilterInput';
import { DateRangePicker, Range } from 'react-date-range';
import { FiltersButton } from '../FilterButtons';
import { DateRange, DateRangeTableFilter } from '../types';

const useStyles = makeStyles(
  theme => ({
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    dateRange: {
      flex: 1,
    },
    modalRoot: {
      background: theme.palette.background.default,
      fontFamily: theme.typography.fontFamily,
      '--font-family': theme.typography.fontFamily,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'DateRangeFilter' },
);

const formatRange = (range: DateRange) => {
  if (!range.startDate || !range.endDate) return '';

  const from = range.startDate.toLocaleDateString();
  const to = range.endDate.toLocaleDateString();

  if (from === to) return from;

  return `${from} - ${to}`;
};

const DateRangeFilterValue = ({
  field,
  value,
  showFieldLabelWhenFilled,
}: DateRangeTableFilter) => {
  const classes = useStyles();

  const filled = value && value.startDate && value.endDate;

  return (
    <Typography className={classes.ellipsis}>
      {(showFieldLabelWhenFilled ?? true) && field}
      {filled && (
        <>
          {(showFieldLabelWhenFilled ?? true) && (
            <Typography component="span">:</Typography>
          )}
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            component="span"
          >
            {formatRange(value)}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const DateRangeFilter = (filter: DateRangeTableFilter) => {
  const theme = useTheme();

  const classes = useStyles();

  // WORKAROUND TO FIX REACT-DATE-RANGE EMPTY SELECTION BUG
  const defaultValue = [
    {
      startDate: filter.value?.startDate || null,
      endDate: filter.value?.endDate || new Date(''),
      key: 'selection',
    },
  ];

  const [value, setValue] = useState(defaultValue);
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(filter.modalAnchorEl ?? event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(defaultValue);
    }, 500);
  };

  React.useEffect(() => {
    setValue([
      {
        startDate: filter.value?.startDate || null,
        endDate: filter.value?.endDate || new Date(''),
        key: 'selection',
      },
    ]);
  }, [filter.value]);

  return (
    <Box>
      <FilterInput
        onClick={handleClick}
        onClear={() => {
          filter.onChange(undefined);
          setValue(defaultValue);
        }}
        isOpened={open}
        selected={Boolean(
          filter.value && filter.value.startDate && filter.value.endDate,
        )}
        placeholder={filter.placeholder}
        hideClear={filter.hideClear}
      >
        <DateRangeFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={
          filter.anchorOrigin ?? {
            vertical: 'bottom',
            horizontal: 'left',
          }
        }
        transformOrigin={filter.transformOrigin}
      >
        <Box className={classes.modalRoot} style={filter.style}>
          <DateRangePicker
            inputRanges={[]}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            color={theme.palette.primary.main}
            className={classes.dateRange}
            ranges={value as Range[]}
            onChange={rangesByKey => {
              const newValue = {
                startDate: rangesByKey.selection.startDate
                  ? new Date(
                      rangesByKey.selection.startDate.setHours(0, 0, 0, 0),
                    )
                  : null,
                endDate: new Date(
                  rangesByKey.selection.endDate?.setHours(23, 59, 59, 999) ??
                    '',
                ),
                key: 'selection',
              };
              setValue([newValue]);
            }}
            rangeColors={[theme.palette.primary.main]}
          />
          <FiltersButton
            containerStyle={{
              justifyContent: 'flex-end',
            }}
            cancel={{
              onClick: handleClose,
              style: { flex: 'none', width: '120px' },
            }}
            apply={{
              onClick: () => {
                filter.onChange({
                  startDate: value[0].startDate || undefined,
                  endDate: value[0].endDate,
                });
                setAnchorEl(null);
              },
              disabled: !value[0].startDate,
              style: { flex: 'none', width: '120px' },
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
