import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { useTheme } from '@material-ui/core';

export function useDagStatusColor(): Record<DagStatus, string> {
  const theme = useTheme();

  return {
    NOT_BLOCKING_ERROR: theme.palette.error.main,
    OK: theme.palette.success.main,
    FAILED: theme.palette.error.main,
    INFO: theme.palette.primary.main,
    WARNING: theme.palette.warning.main,
    RUNNING: theme.palette.accent.main,
    WAITING: theme.palette.grey[500],
    COMPLETED: theme.palette.success.main,
    NOT_EXECUTED: theme.palette.grey[500],
    NOT_STARTED: theme.palette.grey[500],
    ERROR: theme.palette.error.main,
    TERMINATED: theme.palette.error.main,
    TERMINATING: theme.palette.error.main,
  };
}
