import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { WbCardContent } from '../../../WbCard';
import { WbNoData } from '../../../WbNoData';
import { WbChip } from '../../../WbTextField';
import { TextFieldValue } from '../../types';

const useStyles = makeStyles(
  () => ({
    cardContent: {
      height: '100%',
      padding: '0px',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    selected: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      overflow: 'auto',
      height: '100%',
    },
  }),
  { name: 'SelectedEntitiesMultiPicker' },
);

export const SelectedEntities = ({
  entities,
  setEntities,
}: {
  entities: TextFieldValue[];
  setEntities: (entities: TextFieldValue[]) => void;
}) => {
  const classes = useStyles();
  const handleDelete = (indexToDelete: number) => () => {
    setEntities(entities.filter((_, index) => index !== indexToDelete));
  };

  const adaptLabel = (option: TextFieldValue) => {
    const labelKind = option?.kindDisplayName ?? option?.kind;
    return `${labelKind ? `${labelKind}:` : ''} ${option?.label}`;
  };

  return (
    <WbCardContent className={classes.cardContent}>
      <Box display="flex" justifyContent="space-between" marginBottom="16px">
        <Typography variant="body1"> Your selection</Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          disabled={!entities?.length}
          onClick={() => setEntities([])}
        >
          Clear all
        </Button>
      </Box>

      {!entities?.length ? (
        <WbNoData
          fillContainer
          header="You haven't made any selection yet."
          text="Select the entities you need from the list on the left."
        />
      ) : (
        <Box className={classes.selected}>
          {entities?.map((e: TextFieldValue, index: number) => (
            <WbChip
              key={e.label}
              label={adaptLabel(e)}
              onDelete={handleDelete(index)}
              style={{ width: 'fit-content' }}
            />
          ))}
        </Box>
      )}
    </WbCardContent>
  );
};
