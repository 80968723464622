import { transformUrnToWitboostId } from '@agilelab/plugin-wb-builder-common';
import { expandEntityRef } from '@agilelab/plugin-wb-platform-common';
import { CatalogApi } from '@backstage/catalog-client';
import _ from 'lodash';

/**
 * Resolves a list of objects and entityRefs by returning the related entities.
 * @param inputValues: a list of objects and entityRefs
 * @param catalogApi
 * @param token
 * @returns
 */
export async function resolveEntities(
  inputValues: (Record<string, any> | string)[],
  options: { catalogApi: CatalogApi; token?: string },
): Promise<Record<string, any>[]> {
  const { catalogApi, token } = options;
  const [strings, objects] = _.partition(
    inputValues,
    val => typeof val === 'string',
  ) as [string[], Record<string, any>[]];

  async function resolveEntityRefs(rawEntityRefs: string[]) {
    const entityRefs = rawEntityRefs.map(expandEntityRef);
    return (
      await catalogApi.getEntitiesByRefs({ entityRefs }, { token })
    ).items.filter(item => item !== undefined) as Record<string, any>[];
  }

  const resolvedStrings = strings.length
    ? await resolveEntityRefs(
        strings.map(s => transformUrnToWitboostId(s) ?? s),
      )
    : [];

  return resolvedStrings.concat(objects);
}

/**
 * Compute the property label to visualize in the EntitySelectionPicker field.
 * @param propertyValue
 * @returns
 */
export function extractArrayLabel(
  objectLabelResolver: (
    obj: Record<string, any> | string,
  ) => string | undefined,
  defaultValue?: string,
): (inputValues: any[]) => string {
  return (inputValues: any[]) => {
    if (inputValues.length === 1) {
      const element = inputValues[0];
      if (element) {
        const label = objectLabelResolver(element);
        return label ?? '1 item selected';
      }
      return defaultValue ?? 'Value not found';
    }
    const resolvedValues = inputValues.flatMap(pv => {
      const resolved = objectLabelResolver(pv);
      return resolved ? [resolved] : [];
    });
    return resolvedValues.length === inputValues.length
      ? resolvedValues.join(', ')
      : `${inputValues.length} items selected`;
  };
}

/**
 * Build an object starting from the 'mapTo' configuration of the EntitySelectionPicker
 * @param mapTo
 * @param item
 * @returns
 */
export function buildResolvedObject(
  item: Record<string, any> | undefined,
  mapTo: Record<string, any> | '.',
): Record<string, any> {
  if (mapTo === '.') {
    return item ?? {};
  }

  return Object.entries(mapTo).reduce(
    (returningObject, [key, path]) => ({
      ...returningObject,
      [key]: _.get(item ?? {}, path),
    }),
    {},
  );
}
