export const initFiltersSessionStorage = <T>(item: string, filters: T) => {
  sessionStorage.setItem(item, JSON.stringify(filters));
};
export const loadFiltersFromSessionStorage = <T>(item: string) => {
  try {
    const serializedState = sessionStorage.getItem(item);

    if (!serializedState) {
      return undefined;
    }
    return JSON.parse(serializedState) as T;
  } catch (e) {
    return undefined;
  }
};

export const saveFiltersToSessionStorage = <T>(
  item: string,
  callback: (f: T | undefined) => T | undefined,
) => {
  const prevFilters = loadFiltersFromSessionStorage<T>(item);
  const newFilters = callback(prevFilters);
  if (newFilters) {
    sessionStorage.setItem(item, JSON.stringify(newFilters));
  }
};

export const isStateEmpty = (item: string) =>
  !loadFiltersFromSessionStorage(item);
