/*
 * Copied from Backstage 1.8.2 "/Meta/LinksGroup.ts"
 */
import { EntityLink } from '@backstage/catalog-model';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import LanguageIcon from '@material-ui/icons/Language';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import React from 'react';

const WebLink = ({
  href,
  Icon,
  text,
}: {
  href: string;
  text?: string;
  Icon?: IconComponent;
}) => (
  <ListItem button component="a" key={href} href={href}>
    <ListItemIcon>{Icon ? <Icon /> : <LanguageIcon />}</ListItemIcon>
    <ListItemText>{text}</ListItemText>
  </ListItem>
);

export const LinksGroup = ({ links }: { links?: EntityLink[] }) => {
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    key ? app.getSystemIcon(key) ?? LanguageIcon : LanguageIcon;

  if (links === undefined) {
    return null;
  }

  return (
    <>
      <Divider />
      {links.map(link => {
        return (
          <WebLink
            key={link.url}
            href={link.url}
            text={link.title}
            Icon={iconResolver(link.icon)}
          />
        );
      })}
    </>
  );
};
