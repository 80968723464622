/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 * - Add useEffect to allow filter clear
 */

import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import {
  useEntityList,
  useEntityTypeFilter,
} from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import { SelectFilter } from '../WbTableFilters';

export const EntityTypePicker: React.FC = () => {
  const alertApi = useApi(alertApiRef);
  const { filters } = useEntityList();
  const { error, availableTypes, setSelectedTypes } = useEntityTypeFilter();

  useEffect(() => {
    if (error) {
      alertApi.post({
        message: `Failed to load entity types`,
        severity: 'error',
      });
    }
  }, [error, alertApi, setSelectedTypes]);

  // TODO - understand the expected behavior
  // if (availableTypes.length === 0 || error) return null;

  const items = [
    ...availableTypes.map((type: string) => ({
      value: type,
      label: type,
    })),
  ];

  const getLabel = (option: string) => {
    const item = items.find(e => e.value === option);
    return item ? snakeCaseToTitleCase(item.label) : '';
  };

  return (
    <SelectFilter<string>
      field="Type"
      value={filters.type?.value[0]}
      options={items.map(item => item.value)}
      renderOption={getLabel}
      renderValue={getLabel}
      onChange={v => {
        setSelectedTypes(!Boolean(v) ? [] : [String(v)]);
      }}
      onSearch={v =>
        items.filter(o => new RegExp(v, 'ig').test(o.label)).map(o => o.value)
      }
    />
  );
};
