import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';
import {
  ACLObject,
  ACLRequestObject,
  ACLSystemOwned,
  GetACLBySystemUrnResponse,
  RequestAndACLsByOp,
  RequestAndACLsByRef,
} from '../zodSchema/ACLObject';

/**
 * Options you can pass into a request for additional information.
 *
 * @public
 */
export interface RequestOptions {
  token?: string;
}
export interface AccessControlListApi {
  getACLs(
    componentExternalId: string,
    environment: string,
    options?: RequestOptions,
  ): Promise<ACLObject[]>;

  getACLRequestsByRefs(
    externalId: string,
    environmentName: string,
    refs: string[],
    requestId: string,
    options?: RequestOptions,
  ): Promise<ACLRequestObject[]>;

  getACLRequestRow(
    externalId: string,
    environmentName: string,
    ref: string,
    requestId: string,
    options?: RequestOptions,
  ): Promise<ACLRequestObject>;

  getACLAndRequestsByOp(
    idComponent: string,
    options?: RequestOptions,
  ): Promise<RequestAndACLsByOp>;

  getACLAndRequestsByRef(
    ref: string,
    environment: string,
    dataProductInstanceId?: number,
    options?: RequestOptions,
  ): Promise<RequestAndACLsByRef>;

  getACLSystemsOwnedByRefs(
    refs: string[],
    environment: string,
    options?: RequestOptions,
  ): Promise<ACLSystemOwned[]>;

  getACLBySystemUrn(
    systemUrn: string,
    options?: RequestOptions,
  ): Promise<GetACLBySystemUrnResponse>;

  addACLs(objects: any, options?: RequestOptions): Promise<any>;

  upsertACLRequest(objects: any, options?: RequestOptions): Promise<any>;
}

export class AccessControlListClient implements AccessControlListApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: { getBaseUrl(pluginId: string): Promise<string> };
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  async addACLs(objects: any, options?: RequestOptions): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(`${baseUrl}/ACL`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify({ objects: objects }),
    });

    if (!response.ok) {
      throw new Error(`Unable to add ACL, reason: ${response.statusText}`);
    }

    return response.json();
  }

  async upsertACLRequest(objects: any, options?: RequestOptions): Promise<any> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(`${baseUrl}/ACLRequests`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify({ objects: objects }),
    });

    if (!response.ok) {
      throw new Error(
        `Unable to upsert the ACL Requests rows, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLs(
    componentExternalId: string,
    environment: string,
    options?: RequestOptions,
  ): Promise<ACLObject[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACL/${environment}/${componentExternalId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `This field has been disabled due to a network error. Please try again or contact the platform team. HTTP Response: ${response.statusText}.`,
      );
    }

    return response.json();
  }

  async getACLRequestsByRefs(
    externalId: string,
    environmentName: string,
    refs: string[],
    requestId: string,
    options?: RequestOptions,
  ): Promise<ACLRequestObject[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const data = {
      externalId,
      environmentName,
      refs,
      requestId,
    };

    const response = await this.fetchApi.fetch(`${baseUrl}/ACLRequestsByRefs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request with output port externalId ${externalId} and environment ${environmentName}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLRequestRow(
    externalId: string,
    environmentName: string,
    ref: string,
    requestId: string,
    options?: RequestOptions,
  ): Promise<ACLRequestObject> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACLRequests/${environmentName}/${externalId}/${ref}/${requestId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request row with output port external id ${externalId}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLAndRequestsByOp(
    idComponent: string,
    options?: RequestOptions,
  ): Promise<RequestAndACLsByOp> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACLAndRequestsByOp/${idComponent}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request and ACLs with output port id ${idComponent}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLAndRequestsByRef(
    ref: string,
    environment: string,
    dataProductInstanceId?: number,
    options?: RequestOptions,
  ): Promise<RequestAndACLsByRef> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACLAndRequestsByRef/${ref}?environment=${environment}&dataProductInstanceId=${dataProductInstanceId}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL_Request and ACLs with ref ${ref}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }

  async getACLBySystemUrn(
    systemUrn: string,
    options?: RequestOptions,
  ): Promise<GetACLBySystemUrnResponse> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');

    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACL/by-system/${systemUrn}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL by system URN ${systemUrn}, reason: ${response.statusText}`,
      );
    }

    const result: ACLObject[] = await response.json();
    return result.flatMap(aclobject => ({
      userRef: aclobject.refs,
      idOutputPort: aclobject.port_id,
    }));
  }

  async getACLSystemsOwnedByRefs(
    refs: string[],
    environment: string,
    options?: RequestOptions,
  ): Promise<ACLSystemOwned[]> {
    const baseUrl = await this.discoveryApi.getBaseUrl('marketplace');
    const body = {
      environment,
      refs,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/ACLSystemsOwnedByRefs`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
        body: JSON.stringify(body),
      },
    );

    if (!response.ok) {
      throw new Error(
        `Unable to get ACL Systems Owned with body ${JSON.stringify(
          body,
        )}, reason: ${response.statusText}`,
      );
    }

    return response.json();
  }
}
