import { GeneralInfo } from '../components/types';

export function isValidInfo(info: GeneralInfo): boolean {
  return (
    typeof info === 'object' && !!info.label && !!info.value && !!info.type
  );
}

export function isNonEmptyInfo(info: GeneralInfo, isLink: boolean): boolean {
  return (
    !!info.value &&
    info.value !== '' &&
    (!isLink || (isLink && info.href !== ''))
  );
}
