import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import dagre from 'dagre';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { usePracticeShaperGraph } from '../../context/PracticeShaperGraphContext';
import {
  BASE_MARKER,
  buildNodesAndEdges,
} from '../../utils/practiceShaperGraph';
import ReactFlow, {
  NodeProps,
  Node,
  Edge,
  Controls,
  EdgeProps,
  useNodesState,
  useEdgesState,
} from 'reactflow';
import {
  EntityNode,
  ENTITY_NODE_HEIGHT,
  ENTITY_NODE_WIDTH,
  SELF_CONNECTING_ENTITY_NODE_WIDTH,
} from './nodes/EntityNode';
import {
  ComponentType,
  DomainType,
  SystemType,
  Taxonomy,
} from '@agilelab/plugin-wb-builder-common';
import { EntityEdge } from './edges/EntityEdge';
import { makeStyles, Typography, useTheme } from '@material-ui/core';

export type EntityNodeData = { label: string; error: boolean } & (
  | Taxonomy
  | DomainType
  | SystemType
  | ComponentType
);

export interface IEntityNode extends Node<EntityNodeData, CustomNodeTypes> {
  type: CustomNodeTypes;
  data: EntityNodeData;
}
export enum CustomNodeTypes {
  entity = 'entity',
}

export enum CustomEdgeTypes {
  entityEdge = 'entity_edge',
}

export const NODE_TYPES: Record<
  CustomNodeTypes,
  FunctionComponent<NodeProps<EntityNodeData>>
> = {
  entity: EntityNode,
};

export const EDGES_TYPES: Record<
  CustomEdgeTypes,
  FunctionComponent<EdgeProps>
> = {
  entity_edge: EntityEdge,
};

const getElementsWithLayout = (
  nodes: Omit<IEntityNode, 'position'>[],
  edges: Edge[],
  direction: 'TB' | 'LR' | 'BT' | 'RL',
): [IEntityNode[], Edge[]] => {
  const g = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  const selfConnectingNodes = new Set();

  edges.forEach(edge => {
    if (edge.source === edge.target) selfConnectingNodes.add(edge.source);
    g.setEdge(edge.source, edge.target, {
      minlen: 6,
      weight: 1,
      width: 100,
      heigth: 50,
    });
  });

  g.setGraph({ rankdir: direction, ranksep: 30 });

  nodes.forEach(node => {
    let width = ENTITY_NODE_WIDTH;
    // increase the box size prediction for this node if self connecting because of the circular extra edge it will have
    if (selfConnectingNodes.has(node.id))
      width = SELF_CONNECTING_ENTITY_NODE_WIDTH;
    g.setNode(node.id, {
      width,
      height: ENTITY_NODE_HEIGHT,
    });
  });

  dagre.layout(g);

  return [
    nodes.map(node => {
      const { x, y } = g.node(node.id);

      return {
        ...node,
        position: { x, y },
      };
    }),
    edges,
  ];
};

const useStyle = makeStyles(() => ({
  noDataContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  graphContainer: { width: '100%', height: '100%' },
}));

export const PracticesShaperGraph = () => {
  const { data, filteredRelations } = usePracticeShaperGraph();

  const [nodes, setNodes, onNodesChange] = useNodesState<EntityNodeData>([]);

  const theme = useTheme();

  const [edges, setEdges] = useEdgesState<Edge[]>([]);

  const classes = useStyle();

  const generateGraph = useCallback(() => {
    if (!data.data) return;

    const build = buildNodesAndEdges(
      [
        ...data.data.taxonomies,
        ...data.data.domains,
        ...data.data.components,
        ...data.data.systems,
      ],
      filteredRelations,
      primary => ({
        ...BASE_MARKER,
        color: primary ? theme.palette.secondary.dark : theme.palette.grey[500],
      }),
    );

    const [layoutNodes, layoutEdges] = getElementsWithLayout(
      build.nodes,
      build.edges,
      'BT',
    );

    setNodes(layoutNodes);
    setEdges(layoutEdges);
  }, [data, filteredRelations, setEdges, setNodes, theme]);

  useEffect(() => {
    generateGraph();
  }, [generateGraph]);

  if (data.error) return <ResponseErrorPanel error={data.error as Error} />;
  if (data.isLoading) return <Progress />;

  if (!nodes.length)
    return (
      <div className={classes.noDataContainer}>
        <Typography variant="body1">
          The Practice Shaper is not yet initialized, refer to the documentation
          for additional details on this
        </Typography>
      </div>
    );
  return (
    <div className={classes.graphContainer}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        fitView
        edgesUpdatable={false}
        nodesConnectable={false}
        nodeTypes={NODE_TYPES}
        edgeTypes={EDGES_TYPES}
      >
        <Controls />
      </ReactFlow>
    </div>
  );
};
