import React, { useMemo } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { useNavigate } from 'react-router';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      root: {
        width: '100%',
        padding: '24px 0px',
        backgroundColor: 'white',
      },
      button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
      },
      bold: {
        fontWeight: 700,
      },
      resetContainer: {
        padding: theme.spacing(3),
      },
    }),
  { name: 'PresetStepper' },
);

export default function PresetStepper({
  repoUrl,
  importPageRef,
  graphPageRef,
}: {
  repoUrl: string;
  importPageRef: string;
  graphPageRef: string;
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const steps: {
    title: string;
    subTitle?: string;
    content: string;
    actions: { url: string; label: string; external?: boolean }[];
  }[] = useMemo(() => {
    return [
      {
        title: 'Select a Data Landscape preset',
        content:
          'A preset includes the catalog info files for all the type entites composing a data landscape. Choose a preset from the referenced repository and refer to the related installation guide for detailed instructions.',
        actions: [{ url: repoUrl, label: 'Go to Repository', external: true }],
      },
      {
        title: 'Register the Entities',
        content:
          "It's strongly recommended to clone the repository in a one owned by your organization. You can then register the preset entities by providing the URL to their catalog info file to the import wizard.",
        actions: [{ url: importPageRef, label: 'Register Entity' }],
      },
      {
        title: 'Check the graph',
        content:
          'When a new Practice Shaper entity is registered, it will appear in the Practice Shaper Graph. A data landscape preset is correctly registered when all its entities are visible in the graph.',
        actions: [
          {
            url: graphPageRef,
            label: 'View Graph',
          },
        ],
      },
    ];
  }, [graphPageRef, importPageRef, repoUrl]);

  return (
    <WbCard title="Register a Data Landscape">
      <WbCardContent>
        <Stepper orientation="vertical" className={classes.root}>
          {steps.map(step => (
            <Step active key={step.title}>
              <StepLabel>
                <Typography variant="body1" className={classes.bold}>
                  {step.title}
                </Typography>
                {step.subTitle && (
                  <Typography variant="body2" className={classes.bold}>
                    {step.subTitle}
                  </Typography>
                )}
              </StepLabel>
              <StepContent>
                <Typography>{step.content}</Typography>
                <Box marginTop="16px">
                  {step.actions?.map(
                    (action: {
                      url: string;
                      label: string;
                      external?: boolean;
                    }) => (
                      <Button
                        key={action.url}
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          action.external
                            ? window.open(action.url, '_blank')
                            : navigate(action.url)
                        }
                        className={classes.button}
                      >
                        {action.label}
                      </Button>
                    ),
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>{' '}
      </WbCardContent>
    </WbCard>
  );
}
