import { Content, Page } from '@backstage/core-components';
import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import Icon from './MaintenanceIllustration.svg';
import { Logo } from '../WbAppToolbar/Logo/Logo';

export function MaintenancePage() {
  const theme = useTheme();
  return (
    <Page themeId="maintenance">
      <Content>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            style={{
              padding: theme.spacing(1),
              height: '100px',
            }}
          >
            <Logo />
          </Box>
          <Typography variant="h2">
            We are currently under maintenance!
          </Typography>
          <img
            src={Icon}
            alt="Illustration describing that the test history is empty"
          />
          <Typography variant="body2">
            Apologies for the inconvenience...we will back soon.
          </Typography>
        </Box>
      </Content>
    </Page>
  );
}
