import { Badge, Box, Button } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { SearchComponent } from '../Search/SearchComponent';

export interface TableFiltersContainerProps {
  searchValue: string;
  onSearchChange: (search: string) => void;
  activeFilters: number;
  onFiltersClick: (event: React.MouseEvent<HTMLElement>) => void;
  show?: boolean;
}

export function TableFiltersContainer({
  searchValue,
  onSearchChange,
  activeFilters,
  onFiltersClick,
  show = true,
}: TableFiltersContainerProps) {
  const [search, setSearch] = useState<string>(searchValue);

  const handleDebounceFn = React.useCallback(
    (value: string) => {
      onSearchChange(value);
    },
    [onSearchChange],
  );

  const debounceFn = React.useMemo(
    () => debounce(handleDebounceFn, 500),
    [handleDebounceFn],
  );
  const handleChangeSearch = (e: any) => {
    const newValue = e.currentTarget.value;
    setSearch(newValue);
    debounceFn(newValue);
  };

  const handleClearSearch = () => {
    onSearchChange('');
    setSearch('');
  };

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="16px"
      paddingTop="24px"
      width="100%"
    >
      <Box style={{ width: '320px' }}>
        <SearchComponent
          searchValue={search}
          handleChange={handleChangeSearch}
          handleClear={handleClearSearch}
        />
      </Box>
      {show && (
        <Box paddingRight="12px">
          <Badge badgeContent={activeFilters} color="primary">
            <Button
              startIcon={<FilterListIcon />}
              variant="outlined"
              color="primary"
              size="small"
              onClick={onFiltersClick}
            >
              Filters
            </Button>
          </Badge>
        </Box>
      )}
    </Box>
  );
}
