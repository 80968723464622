import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { useIdleTimerContext } from 'react-idle-timer';

export interface StillTherePromptProps {
  promptTimeoutMillis: number;
  remainingTime: number;
  setRemainingTime: (amount: number) => void;
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const StillTherePrompt = (props: StillTherePromptProps) => {
  const {
    setOpen,
    open,
    promptTimeoutMillis,
    remainingTime,
    setRemainingTime,
  } = props;
  const idleTimer = useIdleTimerContext();

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(Math.ceil(idleTimer.getRemainingTime()));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [idleTimer, setRemainingTime]);

  const handleStillHere = () => {
    setOpen(false);
    idleTimer.activate();
  };

  const timeTillPrompt = Math.max(
    remainingTime - promptTimeoutMillis / 1000,
    0,
  );
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

  return (
    <Dialog open={open}>
      <DialogTitle>Logging out due to inactivity</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to be disconnected in{' '}
          <b>
            {Math.ceil(remainingTime / 1000)} {seconds}
          </b>
          . Are you still there?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleStillHere}
          color="secondary"
          variant="contained"
          size="large"
        >
          Yes! Don't log me out
        </Button>
      </DialogActions>
    </Dialog>
  );
};
