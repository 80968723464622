import {
  Box,
  Popover,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import { SelectTableFilter } from './types';
import { FilterInput } from './FilterInput';
import { isEqual } from 'lodash';
import CheckIcon from '@material-ui/icons/Check';
import { FiltersButton } from './FilterButtons';
import { WbSearch } from '../WbSearch';

const useStyles = makeStyles((theme: Theme) => ({
  option: {
    cursor: 'pointer',
    padding: '8px 16px',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
}));

const SelectFilterValue = <T,>({
  field,
  value,
  renderValue,
}: SelectTableFilter<T>) => {
  const ellipsis: React.CSSProperties = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  return (
    <Typography style={ellipsis}>
      {field}
      {value && (
        <>
          <Typography component="span">:</Typography>
          <Typography
            style={{ fontWeight: '600', marginLeft: '5px' }}
            component="span"
          >
            {renderValue(value)}
          </Typography>
        </>
      )}
    </Typography>
  );
};

export const SelectFilter = <T,>(filter: SelectTableFilter<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState(filter.options);
  const [value, setValue] = useState(filter.value);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const onSearch = (search: string) => {
    setSearchValue(search);
    if (filter.onSearch) setOptions(filter.onSearch(search));
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(filter.value);
      onSearch('');
    }, 500);
  };
  const onInputClear = () => {
    filter.onChange(undefined);
    setValue(undefined);
  };

  const onSelect = (option: T) => {
    setValue(option);
  };

  const onApply = () => {
    filter.onChange(value);
    setTimeout(() => {
      onSearch('');
    }, 500);
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setValue(filter.value);
  }, [filter.value]);

  React.useEffect(() => {
    setOptions(filter.options);
  }, [filter.options]);

  return (
    <Box>
      <FilterInput
        onClick={openMenu}
        onClear={onInputClear}
        isOpened={open}
        selected={Boolean(filter.value)}
        hideClear={filter.hideClear}
      >
        <SelectFilterValue {...filter} />
      </FilterInput>

      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          style={{ width: '280px', background: 'white', overflow: 'hidden' }}
        >
          {filter.onSearch && (
            <Box
              style={{
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                padding: '8px 16px',
              }}
            >
              <WbSearch value={searchValue} onChange={onSearch} />
            </Box>
          )}

          <Box
            style={{
              maxHeight: '200px',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {options.map((option, index) => (
              <Box
                className={classes.option}
                key={index}
                onClick={() => {
                  onSelect(option);
                }}
              >
                <Box className={classes.optionLabel}>
                  {filter.renderOption(option)}
                </Box>
                {isEqual(value, option) && (
                  <CheckIcon color="primary" fontSize="small" />
                )}
              </Box>
            ))}
          </Box>

          <FiltersButton
            cancel={{
              onClick: closeMenu,
            }}
            apply={{
              onClick: onApply,
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};
