import { Link } from '@backstage/core-components';
import { makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { SidebarConfig, WbSidebarConfigContext } from '../../config';
import { Logo } from './Logo';
import { useWbSidebarOpenState } from '../../WbSidebarOpenStateContext';

type WbSidebarLogoProps = {
  renderLogo?: (isOpen: boolean) => React.ReactNode;
  to: string;
};

const useStyle = makeStyles<Theme, SidebarConfig>(() => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80px',
  },
  drawerWidth: props => ({
    width: props.drawerWidthClosed,
  }),
  drawerOpen: props => ({
    width: props.drawerWidthOpen,
  }),
}));

export const WbSidebarLogo = ({ renderLogo, to }: WbSidebarLogoProps) => {
  const { isOpen } = useWbSidebarOpenState();

  const { sidebarConfig } = useContext(WbSidebarConfigContext);

  const classes = useStyle(sidebarConfig);

  if (renderLogo) return <>{renderLogo(isOpen)}</>;
  return (
    <Link
      className={clsx(
        classes.root,
        classes.drawerWidth,
        isOpen && classes.drawerOpen,
      )}
      underline="none"
      aria-label="Home"
      to={to}
    >
      <Logo full={isOpen} />
    </Link>
  );
};
