import { ConfigApi } from '@backstage/core-plugin-api';
import {
  CustomMicrofrontendConfiguration,
  CustomMicrofrontendConfigurationZod,
  CustomMicrofrontendOptions,
  CustomMicrofrontendTarget,
} from './CustomMicrofrontendConfiguration';
import { CustomAlertApi } from '../../apis';
import { get, set } from 'lodash';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { ZodError } from 'zod';

import { createApiRef } from '@backstage/core-plugin-api';

export type CustomFrontendApi = {
  /**
   * Generate the custom microfrontend options needed for a specific page.
   * This method should be used by passing first the target (where the custom pages should be rendered).
   * Then passing the input object (when available) to the function to extract the actual options.
   *
   * An example could be:
   * ```typescript
   * const useCustomFrontend = customFrontendApi.makeCustomMicrofrontends(
   *   CustomMicrofrontendTarget.MarketplaceOutputPortPage,
   * );
   * const customMicrofrontendConfigurations = useCustomFrontends(object);
   * ```
   */
  makeCustomMicrofrontends(
    target: CustomMicrofrontendTarget,
  ): (data?: any) => CustomMicrofrontendOptions[];
};

/**
 * The API reference for the CustomFrontendApi.
 * @public
 */
export const customFrontendApiRef = createApiRef<CustomFrontendApi>({
  id: 'plugin.platform.custom-frontend',
});

export class CustomFrontendClient implements CustomFrontendApi {
  constructor(
    private readonly configApi: ConfigApi,
    private readonly alertApi: CustomAlertApi,
  ) {}

  extractDataFromInput(
    input: any,
    config: CustomMicrofrontendConfiguration,
  ): any {
    const data = {};
    config.parameters.forEach(parameter => {
      set(data, parameter.name, get(input, parameter.valuePath));
    });
    return data;
  }

  generateErrorMessage(error: ZodError): string {
    const fieldErrors = error.flatten().fieldErrors;
    return [
      'Please fix the following configuration errors for the microfrontend definition:',
      ...Object.keys(fieldErrors).map(key => {
        return `- ${key}: ${fieldErrors[key]}`;
      }),
    ].join('\n');
  }

  makeCustomMicrofrontends(
    target: CustomMicrofrontendTarget,
  ): (data?: any) => CustomMicrofrontendOptions[] {
    const configurations =
      this.configApi.getOptionalConfigArray(`mesh.customPages.${target}`) ?? [];
    return (data: any) => {
      return configurations.flatMap(configuration => {
        const parsed = CustomMicrofrontendConfigurationZod.safeParse(
          configuration.get<CustomMicrofrontendConfiguration>(''),
        );
        if (!parsed.success) {
          this.alertApi.post({
            error: new CustomError(
              'Failed to load configuration for microfrontend',
              this.generateErrorMessage(parsed.error),
            ),
            severity: 'error',
          });
          return [];
        }
        const config = parsed.data;
        set(
          config,
          'data',
          data ? this.extractDataFromInput(data, config) : undefined,
        );
        return [config as CustomMicrofrontendOptions];
      });
    };
  }
}
