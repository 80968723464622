import { ErrorResponseBody } from '@backstage/errors';

export function parseErrorMessage(error: any) {
  function parseDefaultError(e: any) {
    return e.data?.error?.message ?? e.message;
  }
  try {
    const errorResponse: ErrorResponseBody = JSON.parse(error.message);
    return errorResponse?.error?.message ?? parseDefaultError(error);
  } catch (_parseError) {
    return parseDefaultError(error);
  }
}
