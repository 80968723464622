import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { useMemo } from 'react';
import {
  CollatorType,
  readCollatorFiltersConfigOptions,
} from '@agilelab/plugin-wb-search-common';

export const useSearchFilterConfig = () => {
  const configApi = useApi(configApiRef);
  return useMemo(
    () =>
      readCollatorFiltersConfigOptions(configApi)[
        CollatorType.MARKETPLACE_PROJECTS
      ],
    [configApi],
  );
};
