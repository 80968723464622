import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { CircularProgress, TextField } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import useDebounce from 'react-use/lib/useDebounce';
import { Schema, WitBoostOutputPort } from './DataContractEditor';

export function updateOutputPortWithNewSchema(
  schema: Schema,
  op: WitBoostOutputPort,
): WitBoostOutputPort {
  return {
    ...op,
    spec: {
      ...op.spec,
      mesh: {
        ...op.spec.mesh,
        dataContract: {
          ...op.spec.mesh.dataContract,
          schema: schema,
        },
      },
    },
  };
}

function addFieldDescriptionToSchema(
  schema: Schema,
  description: string,
  field: string,
): Schema {
  return schema.map(s => ({
    ...s,
    description: s.name === field ? description : s.description,
  }));
}

export function DescriptionField(props: {
  field: string;
  value?: string;
  loading?: boolean;
  businessTerms: string[];
  dataProduct: WitboostEntity;
  outputPort: WitBoostOutputPort;
  onChange: (desc: string) => void;
}) {
  const {
    field,
    value,
    loading: loadingGenerate,
    businessTerms,
    onChange,
    dataProduct,
    outputPort,
  } = props;
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const fetchApi = useApi(fetchApiRef);

  useDebounce(
    () => {
      const fetchData = async () => {
        setLoading(true);
        try {
          const newSchema = addFieldDescriptionToSchema(
            outputPort.spec.mesh.dataContract!.schema,
            value || '',
            field,
          );

          const refinedDescriptionResponse = await fetchApi.fetch(
            `https://schemaassistant.azurewebsites.net/api/RefineDescription?code=h4ncK5AeibjxFqL0dgodWOSgOpILrdRpseo5G4_P7La6AzFu9sc9ew==`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                dataProduct,
                outputPort: updateOutputPortWithNewSchema(
                  newSchema,
                  outputPort,
                ),
                field,
                businessTerms,
              }),
            },
          );
          const refinedDescription =
            (await refinedDescriptionResponse.json()) as Record<string, string>;

          setOptions([refinedDescription[field]]);
        } finally {
          setLoading(false);
        }
      };

      if (!open) {
        return;
      }

      fetchData();
    },
    500,
    [businessTerms, dataProduct, field, value, open, outputPort],
  );

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [businessTerms, open]);

  if (loadingGenerate && !value) {
    return <Skeleton />;
  }

  return (
    <Autocomplete
      options={options}
      filterOptions={x => x}
      freeSolo
      disableClearable
      style={{ width: '100%' }}
      open={open}
      loading={loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      noOptionsText="No Suggested Description"
      value={value || ''}
      onChange={(_event, newValue) => {
        onChange(newValue);
      }}
      onInputChange={(_event, newInputValue) => {
        onChange(newInputValue);
      }}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
