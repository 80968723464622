/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 */

import { useApi } from '@backstage/core-plugin-api';
import {
  useEntityList,
  catalogApiRef,
  EntityTagFilter,
} from '@backstage/plugin-catalog-react';
import React, { useMemo, useState, useEffect } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { EnumFilter } from '../WbTableFilters';

export const EntityTagPicker: React.FC = () => {
  const {
    updateFilters,
    filters,
    queryParameters: { tags: tagsParameter },
  } = useEntityList();

  const catalogApi = useApi(catalogApiRef);
  const { value: availableTags } = useAsync(async () => {
    const facet = 'metadata.tags';
    const { facets } = await catalogApi.getEntityFacets({
      facets: [facet],
      filter: filters.kind?.getCatalogFilters(),
    });

    return Object.fromEntries(
      facets[facet].map(({ value, count }) => [value, count]),
    );
  }, [filters.kind]);

  const queryParamTags = useMemo(
    () => [tagsParameter].flat().filter(Boolean) as string[],
    [tagsParameter],
  );

  const [selectedTags, setSelectedTags] = useState(
    queryParamTags.length ? queryParamTags : filters.tags?.values ?? [],
  );

  useEffect(() => {
    if (filters.tags === undefined) {
      setSelectedTags([]);
    }
  }, [filters.tags]);

  // Set selected tags on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamTags.length) {
      setSelectedTags(queryParamTags);
    }
  }, [queryParamTags]);

  useEffect(() => {
    const tags = Object.keys(availableTags ?? {});
    updateFilters({
      tags:
        selectedTags.length && tags.length
          ? new EntityTagFilter(selectedTags)
          : undefined,
    });
  }, [selectedTags, updateFilters, availableTags]);

  // TODO - understand the expected behavior
  // if (!Object.keys(availableTags ?? {}).length) return null;

  return (
    <EnumFilter<string>
      field="Tags"
      value={selectedTags}
      onChange={t => setSelectedTags(t || [])}
      renderOption={o => o}
      renderValue={o => o}
      options={Object.keys(availableTags ?? {})}
      onSearch={v =>
        Object.keys(availableTags ?? {}).filter(o =>
          new RegExp(v, 'ig').test(o),
        )
      }
    />
  );
};
