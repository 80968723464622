import React from 'react';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
  Tooltip,
  createStyles,
  makeStyles,
  useTheme,
  withStyles,
} from '@material-ui/core';

export const ErrorTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#FDECE9',
    textColor: 'black',
    color: 'black',
  },
  arrow: {
    color: '#FDECE9',
  },
}))(Tooltip);

const useStyles = makeStyles(theme =>
  createStyles({
    iconContainer: {
      position: 'absolute',
      right: -20,
      top: -16,
      borderRadius: '100%',
      background: theme.palette.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '2px solid white',
      zIndex: 1,
    },
  }),
);

interface Props {
  hide?: boolean;
  label: string;
}

export const Error: React.FC<Props> = ({ hide, label }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <ErrorTooltip
        placement="top"
        title={label}
        disableHoverListener={hide}
        arrow
      >
        <div className={classes.iconContainer}>
          {hide && (
            <ErrorOutlineIcon
              style={{
                color: 'white',
                fontSize: 50,
                opacity: 0.8,
                position: 'absolute',
                zIndex: 2,
              }}
            />
          )}

          <ErrorOutlineIcon
            style={{ color: theme.palette.relationsGraph.error, fontSize: 30 }}
          />
        </div>
      </ErrorTooltip>
    </>
  );
};
