import React from 'react';
import { Box, ListItem, Typography, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { WbAccordion } from '@agilelab/plugin-wb-platform';
import { Loader as InfoLoader } from '../CustomViews/Loader';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      width: '100%',
      alignItems: 'center',
    },
    headerContainer: {
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      marginBottom: theme.spacing(0.2),
    },
    borderRadius: {
      borderRadius: theme.spacing(0.5),
    },
  }),
  { name: 'Loader' },
);

export const Title = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.headerContainer}>
        <Skeleton
          variant="rect"
          width={24}
          height={24}
          className={classes.borderRadius}
        />
        <Typography variant="h5">
          <Skeleton variant="text" width={200} />
        </Typography>
      </Box>
      <Skeleton variant="rect" width={200} />
    </Box>
  );
};

export const Actions = () => {
  const classes = useStyles();

  return (
    <Skeleton
      variant="rect"
      className={classes.borderRadius}
      width={87}
      height={27}
    />
  );
};

export const MarketplaceSearchListItemLoader = () => {
  return (
    <ListItem style={{ paddingLeft: 0 }}>
      <WbAccordion
        expanded={false}
        title={<Title />}
        actions={<Actions />}
        renderDynamicContent={() => <InfoLoader />}
      />
    </ListItem>
  );
};
