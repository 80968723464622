import React, { ReactNode } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { Separator, Variant } from './types';
import { MainSeparator } from './separators/MainSeparator';
import { SecondarySeparator } from './separators/SecondarySeparator';

type WbBreadcrumbsProps = {
  children: ReactNode;
  variant?: Variant;
  disableLastItemHighlight?: boolean;
};

const useStyles = makeStyles<Theme, { i?: number }>(
  theme => ({
    root: {
      '& > ul': {
        listStyle: 'none',
        display: 'flex',
        height: '100%',
        margin: 0,
        padding: 0,
      },
    },
    main: {
      height: '40px',
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: theme.palette.white,

      '& $firstItem': {
        paddingLeft: '16px',
        backgroundColor: theme.palette.bkg.secondary,
      },
      '& $lastItem': {
        fontWeight: theme.typography.fontWeightMedium as number,
        color: theme.palette.secondary.dark,
      },
      '& $itemContainer': {
        paddingInline: '16px',
      },
    },

    secondary: {
      height: '18px',
      '& > ul': {
        gap: theme.spacing(1),
      },

      '& $lastItem': {
        color: theme.palette.primary.main,
      },
      '& $firstItem': {},
    },
    item: {
      height: '100%',
      color: theme.palette.secondary.main,
      display: 'flex',
      ...theme.typography.body2,
    },
    itemContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    firstItem: {},
    lastItem: {},
  }),
  { name: 'WbBreadcrumbs' },
);

const WbBreadcrumbWrapper = ({
  children,
  i,
  last,
  disableLastItemHighlight,
}: {
  children?: ReactNode;
  variant: Variant;
  i: number;
  last?: boolean;
  disableLastItemHighlight?: boolean;
}) => {
  const classes = useStyles({ i });

  return (
    <Box
      component="li"
      className={clsx(
        classes.item,
        i === 0 && classes.firstItem,
        last && !disableLastItemHighlight && classes.lastItem,
      )}
    >
      <Box className={clsx(classes.itemContainer)}>{children}</Box>
    </Box>
  );
};

const separators: Record<Variant, Separator> = {
  main: MainSeparator,
  secondary: SecondarySeparator,
};

const insertSeparators = (
  items: React.JSX.Element[],
  variant: Variant,
  insertAtTheEnd = false,
) => {
  const SeparatorComponent = separators[variant];

  return items.reduce<React.JSX.Element[]>((acc, current, index) => {
    let next = acc;

    if (index < items.length - 1 || insertAtTheEnd) {
      next = next.concat(
        current,
        <SeparatorComponent i={index} key={`separator-${index}`} />,
      );
    } else {
      next.push(current);
    }

    return next;
  }, []);
};

export const WbBreadcrumbs = ({
  children,
  variant = 'main',
  disableLastItemHighlight,
}: WbBreadcrumbsProps) => {
  const classes = useStyles({ i: 0 });

  const elements = React.Children.toArray(children);

  let allItems = elements
    .filter(child => {
      return React.isValidElement(child);
    })
    .map((child, index) => (
      <WbBreadcrumbWrapper
        disableLastItemHighlight={disableLastItemHighlight}
        variant={variant}
        key={index}
        i={index}
        last={index === elements.length - 1}
      >
        {child}
      </WbBreadcrumbWrapper>
    ));

  // if breadcrumbs are of variant
  const insertSeparatorAtTheEnd = variant === 'main' && allItems.length === 1;

  allItems = insertSeparators(allItems, variant, insertSeparatorAtTheEnd);

  return (
    <Box component="nav" className={clsx(classes.root, classes[variant])}>
      <Box component="ul">{allItems}</Box>
    </Box>
  );
};
