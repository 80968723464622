import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const DisabledIcon = createSvgIcon(
  <>
    <g id="Layer_1-2">
      <g id="Group_535">
        <circle id="Ellipse_19" cx="12" cy="12" r="12" fill="#dcdcdc" />
        <path
          fill="#fff"
          id="Path_78"
          d="m18.32,8.88l-1.17,1.17-5.26,5.26-1.29,1.29c-.1.1-.21.17-.34.22-.78.37-1.49-.59-1.99-1.09l-2.54-2.54c-.33-.33-.77-.76-.56-1.27.17-.55.76-.85,1.3-.68.18.06.35.17.47.31l2.33,2.33.59.59,4.22-4.22,2.35-2.35c.52-.52,1.21-1.43,2.02-.81.04.03.08.06.11.1.04.04.07.07.1.11.45.59.07,1.15-.36,1.57h0Z"
        />
      </g>
    </g>
  </>,
  'DisabledIcon',
);
