export const EXTENDS = 'wb-extends';
export const EXTENDED_BY = 'wb-extendedBy';

export const PART_OF = 'wb-partOf';
export const HAS_PART = 'wb-hasPart';

export const INSTANCE_OF = 'wb-instanceOf';
export const CLASS_OF = 'wb-classOf';

export const GENERATES = 'wb-generates';
export const GENERATED_BY = 'wb-generatedBy';

export const LINKED_TO_GROUP = 'wb-linkedToGroup';

export const BELONGS_TO = 'wb-belongsTo';
export const HAS_CHILD = 'wb-hasChild';

export const OWNED_BY = 'wb-ownedBy';
export const OWNER_OF = 'wb-ownerOf';

export const READS_FROM = 'wb-readsFrom';
export const READ_BY = 'wb-readBy';

export const DEPENDS_ON = 'wb-dependsOn';
export const HAS_DEPENDENCY = 'wb-hasDependency';
