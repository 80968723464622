import React from 'react';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';

type IssuesProps = {
  issues: string[] | undefined;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    issueContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: theme.spacing(1),
    },
  }),
  { name: 'Issues' },
);

export const Issues: React.FC<IssuesProps> = ({ issues }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {issues
        ?.map(i => `${i}`)
        .map(i => (
          <Tooltip key={i} title={i}>
            <Box className={classes.issueContainer}>
              <Typography variant="body2">•</Typography>
              <Typography variant="body2">{i}</Typography>
            </Box>
          </Tooltip>
        ))}
    </Box>
  );
};
