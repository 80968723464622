import { useLazyQuery } from '@apollo/client';
import {
  CircularProgress,
  Collapse,
  IconButton,
  List,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { format, parseISO } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { Task, Test } from '../types';
import {
  GOVERNANCE_ENTITIES,
  GovernanceEntitiesQueryType,
} from '../../../graphql/governanceEntities';
import { SelectedRow } from './TestTable';
import { TestTableListItem } from './TestTableListItem/TestTableListItem';
import { TestTableListItemEmpty } from './TestTableListItem/TestTableListItemEmpty';
import { useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import { useTestCard } from '../TestCard/useTestCard';
import useInterval from 'react-use/lib/useInterval';
import { ErrorPanel } from '@backstage/core-components';
import { DetailIcon } from './DetailIcon';
import { DagStatus, isRunningStatus } from '@agilelab/plugin-wb-builder-common';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';
import { customAlertApiRef } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    spinnerWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    list: {
      width: '100%',
    },
    rowWithBorder: {
      '& td': {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      },
    },
  }),
);

function AsyncTestRow(props: { test: Test; selectedRow: SelectedRow | null }) {
  const { test, selectedRow } = props;
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const classes = useStyles();
  const { fetchTests } = useTestCard();

  useInterval(async () => {
    panelCatalog
      .getValidateDescriptorStatus(test.id)
      .then(response => {
        if (response.status !== DagStatus.RUNNING) {
          if (!response.validationSummaryStatus)
            throw new Error('Missing validation summary in the response');
          fetchTests();
        }
      })
      .catch(error => {
        alertApi.post({ error, severity: 'error' });
      });
  }, 2000);

  return (
    <>
      <TableRow
        selected={selectedRow?.testId === test.id}
        className={classes.rowWithBorder}
      >
        <TableCell width="5%">
          <DetailIcon status={DagStatus.RUNNING} />
        </TableCell>
        <TableCell width="40%">
          <Typography>
            Started at:{' '}
            {format(parseISO(test.startDate), 'yyyy/MM/dd HH:mm:ss')}
          </Typography>
        </TableCell>
        <TableCell colSpan={2} width="45%">
          <Typography>Validation in progress</Typography>
        </TableCell>
      </TableRow>
    </>
  );
}

function CompletedTestRow(props: {
  test: Test;
  selectedRow: SelectedRow | null;
  setSelectedRow: (value: SelectedRow | null) => void;
  setSelectedTask: (value: Task | null) => void;
  openDrawer: () => void;
}) {
  const { test, selectedRow, setSelectedRow, setSelectedTask, openDrawer } =
    props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [fetchPolicies, { loading, error, data }] =
    useLazyQuery<GovernanceEntitiesQueryType>(GOVERNANCE_ENTITIES, {
      variables: {
        ids: test.tasks
          .filter(t => !!t.governanceEntityId)
          .map(p => p.governanceEntityId),
      },
    });

  useEffect(() => {
    if (open && !data) {
      fetchPolicies();
    }
  }, [fetchPolicies, data, open]);

  const handleToggleExpandRow = () => {
    if (open) {
      setSelectedRow(null);
    } else {
      setSelectedRow({
        testId: test.id,
        detailIndex: undefined,
      });
    }
    setOpen(!open);
  };

  const isFailureStatus = (status: DagStatus) =>
    [DagStatus.ERROR, DagStatus.NOT_EXECUTED].includes(status);

  const policiesFailed = useMemo(
    () =>
      test.tasks.filter(
        t =>
          !!t.governanceEntityId &&
          isFailureStatus(t.status) &&
          t.governanceEntityType === GovernanceEntityType.Policy,
      ).length,
    [test.tasks],
  );

  const metricsFailed = useMemo(
    () =>
      test.tasks.filter(
        t =>
          !!t.governanceEntityId &&
          isFailureStatus(t.status) &&
          t.governanceEntityType === GovernanceEntityType.Metric,
      ).length,
    [test.tasks],
  );

  const policiesWithWarnings = useMemo(
    () =>
      test.tasks.filter(
        t =>
          (!!t.governanceEntityId &&
            t.status === DagStatus.NOT_BLOCKING_ERROR) ||
          t.status === DagStatus.INFO ||
          t.status === DagStatus.WARNING,
      ).length,
    [test.tasks],
  );

  const componentsFailed = useMemo(
    () =>
      test.tasks.filter(
        t => !t.governanceEntityId && t.status === DagStatus.FAILED,
      ).length,
    [test.tasks],
  );

  const isListEmpty = useMemo(
    () =>
      !(
        (data?.cgp_governance_entity?.length || 0) +
        test.tasks.filter(t => !!t.governanceEntityId).length
      ),
    [data, test.tasks],
  );

  const handleDetailClick = (task: Task) => {
    setSelectedTask(task);
    setSelectedRow({
      testId: test.id,
      detailIndex: task.id,
    });
    if (task.governanceEntityId || task.errors.length > 0) {
      openDrawer();
    }
  };

  return (
    <>
      <TableRow selected={selectedRow?.testId === test.id}>
        <TableCell width="5%">
          <DetailIcon
            status={test.status}
            okWithWarnings={!!policiesWithWarnings}
          />
        </TableCell>
        <TableCell width="40%">
          <Typography>
            <strong>
              {format(parseISO(test.startDate), 'yyyy/MM/dd HH:mm:ss')}
            </strong>
          </Typography>
        </TableCell>
        <TableCell width="45%">
          <>
            {!policiesFailed && !componentsFailed && !policiesWithWarnings ? (
              <Typography>Success</Typography>
            ) : (
              <div>
                {!!policiesFailed && (
                  <Typography>
                    {`${policiesFailed} ${
                      policiesFailed < 2 ? 'policy' : 'policies'
                    }  check failed`}
                  </Typography>
                )}
                {!!metricsFailed && (
                  <Typography>
                    {`${metricsFailed} ${
                      metricsFailed < 2 ? 'metric' : 'metrics'
                    }  check failed`}
                  </Typography>
                )}
                {!!policiesWithWarnings && (
                  <Typography>
                    {`${policiesWithWarnings} ${
                      policiesWithWarnings < 2 ? 'policy' : 'policies'
                    }  check with warnings`}
                  </Typography>
                )}
                {!!componentsFailed && (
                  <Typography>
                    {`${componentsFailed} ${
                      componentsFailed < 2 ? 'component' : 'components'
                    }  check failed`}
                  </Typography>
                )}
              </div>
            )}
          </>
        </TableCell>
        <TableCell width="5%">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleToggleExpandRow}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          }}
          colSpan={4}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            {loading && (
              <div className={classes.spinnerWrapper}>
                <CircularProgress />
              </div>
            )}
            {error && <ErrorPanel error={error} />}
            <List
              component="nav"
              aria-label="detail list"
              className={classes.list}
            >
              {!loading &&
                !error &&
                test.tasks.map((t, i) => (
                  <TestTableListItem
                    selected={Boolean(
                      selectedRow?.testId === test.id &&
                        selectedRow.detailIndex === t.id,
                    )}
                    key={`${t.id}_${i}`}
                    onClick={handleDetailClick}
                    task={t}
                    policies={data?.cgp_governance_entity || []}
                  />
                ))}
              {!loading && !error && isListEmpty && <TestTableListItemEmpty />}
            </List>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export function TestRow(props: {
  test: Test;
  selectedRow: SelectedRow | null;
  setSelectedRow: (value: SelectedRow | null) => void;
  setSelectedTask: (value: Task | null) => void;
  openDrawer: () => void;
}) {
  const { test, selectedRow, setSelectedRow, setSelectedTask, openDrawer } =
    props;

  if (
    isRunningStatus(test.status) ||
    test.tasks.some(task => isRunningStatus(task.status))
  )
    return <AsyncTestRow test={test} selectedRow={selectedRow} />;

  return (
    <CompletedTestRow
      test={test}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
      setSelectedTask={setSelectedTask}
      openDrawer={openDrawer}
    />
  );
}
