import { z } from 'zod';

export const answerNotificationRequestDataZod = z.object({
  answer: z.string(),
  timestamp: z.string(),
  answered_by: z.string(),
  answered_by_display_name: z.string(),
  data_product_urn: z.string(),
});

export type AnswerNotificationRequestData = z.infer<
  typeof answerNotificationRequestDataZod
>;
