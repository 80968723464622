import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const CollapseAllIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.41667 16.6666L6.25 15.5L10 11.75L13.75 15.5L12.5833 16.6666L10 14.0833L7.41667 16.6666ZM10 8.24998L6.25 4.49998L7.41667 3.33331L10 5.91665L12.5833 3.33331L13.75 4.49998L10 8.24998Z"
      fill="currentColor"
    />
  </svg>,
  'CollapseAllIcon',
);
