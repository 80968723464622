import { AccessControlRequestTemplate } from '@agilelab/plugin-wb-builder-common';
import { MAX_ACCESS_REQUEST_TEMPLATE_AVAILABLE } from '@agilelab/plugin-wb-marketplace-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';

export type AccessControlRequestTemplateResponse = {
  template: AccessControlRequestTemplate | undefined;
  error: string | undefined;
};

/**
 * This hook is responsible of taking the current Access Control Request template from the catalog API.
 * If the returned array contains more than 1 value, it means that there is something wrong with the value
 * returned and this hook will return an undefined. Otherwise it return the first and only element that corresponds
 * to the AccessControlRequestTemplate.
 * @returns an AccessRequest template or undefined
 */
export function useAccessControlRequestTemplate(options: {
  templateType: 'grant' | 'revoke';
}): AccessControlRequestTemplateResponse {
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const { value: accessControlRequestTemplates, error } = useAsync(async () => {
    const token = (await identityApi.getCredentials()).token;
    return (
      await catalogApi.getEntities(
        {
          filter: {
            kind: 'AccessControlRequestTemplate',
            'spec.type': options.templateType,
          },
        },
        { token },
      )
    ).items;
  }, [catalogApi, identityApi]);

  if (error) {
    return {
      template: undefined,
      error: `Error while trying to fetch the current Access Control Request template: ${error.message}`,
    };
  }

  if (!accessControlRequestTemplates) {
    return {
      template: undefined,
      error: `Something went wrong while trying to fetch the current Access Control Request template.`,
    };
  }

  if (
    accessControlRequestTemplates.length < MAX_ACCESS_REQUEST_TEMPLATE_AVAILABLE
  ) {
    return {
      template: undefined,
      error:
        'There is no registered access control request template. Please, contact the platform team.',
    };
  }

  if (
    accessControlRequestTemplates.length > MAX_ACCESS_REQUEST_TEMPLATE_AVAILABLE
  ) {
    return {
      template: undefined,
      error:
        'There is more than one registered access control request template. Please, contact the platform team.',
    };
  }

  return {
    template: accessControlRequestTemplates[0] as AccessControlRequestTemplate,
    error: undefined,
  };
}
