import React, { ReactNode } from 'react';
import { useEnabledFeatures, Features } from '../../context';
import { ErrorPanel } from '@backstage/core-components';

export interface WbRequirePermissionProps {
  when: (context: Features) => boolean;
  children: ReactNode;
}

export function WbRequirePermission(
  props: WbRequirePermissionProps,
): JSX.Element | null {
  const { when } = props;

  const { loading, error, data } = useEnabledFeatures();

  if (loading) {
    return null;
  } else if (when(data)) {
    return <>{props.children}</>;
  }

  if (error) {
    return <>{error}</>;
  }

  return (
    <ErrorPanel
      error={{
        name: 'Not allowed',
        message: 'You are not allowed to see this page',
      }}
    />
  );
}
