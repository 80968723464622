import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React from 'react';

interface Props {
  onClear: React.MouseEventHandler<HTMLButtonElement> | undefined;
  style?: React.CSSProperties;
}

const useStyles = makeStyles(() => ({
  iconButton: {
    visibility: 'hidden',
    pointerEvents: 'none',
  },
}));

export const ClearButton: React.FC<Props> = ({ onClear, style }) => {
  const classes = useStyles();
  return (
    <IconButton
      style={style}
      className={classes.iconButton}
      size="small"
      onClick={onClear}
    >
      <ClearIcon fontSize="inherit" />
    </IconButton>
  );
};
