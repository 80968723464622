import React from 'react';
import { Editor } from '../common/Editor/Editor';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

interface PolicyCueScriptEditorProps {
  onUpdate?: (code?: string) => void;
  editable?: boolean;
  cueScript: string;
  saving?: boolean;
  handleSave?: () => void;
  showSaveButton?: boolean;
}

export const PolicyCueScriptEditor: React.FC<
  PolicyCueScriptEditorProps
> = props => {
  return (
    <WbCard title="Cue script">
      <WbCardContent>
        <Editor
          id="policy-editor"
          content={props.cueScript}
          onUpdate={props.onUpdate}
          editable={props.editable}
          saving={props.saving}
          handleSave={props.handleSave}
          showSaveButton={props.showSaveButton}
          parser="go"
        />
      </WbCardContent>
    </WbCard>
  );
};
