import { Entity } from '@backstage/catalog-model';
import React, { useContext, useState } from 'react';
import { usePracticeShaperGraphData } from '../hooks/practice-shaper-graph/usePracticeShaperGraphData';

export type PracticeShaperGraphProps = {
  data: ReturnType<typeof usePracticeShaperGraphData>;
  filteredRelations: string[];
  setFilteredRelations: React.Dispatch<React.SetStateAction<string[]>>;
  selectedEntity: Entity | null;
  setSelectedEntity: React.Dispatch<React.SetStateAction<Entity | null>>;
};

export const PracticeShaperGraphContext =
  React.createContext<PracticeShaperGraphProps>({} as PracticeShaperGraphProps);

interface Props {
  children?: React.ReactNode;
}

const DEFAULT_EXCLUDED_RELATIONS = ['hasPart', 'hasChild', 'readBy'];

export const HIGHLIGHTED_RELATIONS = ['partOf', 'belongsTo'];

export const PracticeShaperGraphProvider: React.FC<Props> = ({ children }) => {
  const data = usePracticeShaperGraphData();
  const [filteredRelations, setFilteredRelations] = useState(
    DEFAULT_EXCLUDED_RELATIONS,
  );

  const [selectedEntity, setSelectedEntity] = useState<Entity | null>(null);

  return (
    <PracticeShaperGraphContext.Provider
      value={{
        data,
        filteredRelations,
        setFilteredRelations,
        selectedEntity,
        setSelectedEntity,
      }}
    >
      {children}
    </PracticeShaperGraphContext.Provider>
  );
};

export const usePracticeShaperGraph = () =>
  useContext(PracticeShaperGraphContext);
