import { gql } from '@apollo/client/core';

export const GET_LAST_FAILED_EVALUATIONS_METRICS = gql`
  query GET_LAST_FAILED_EVALUATIONS_METRICS(
    $dataProductUrn: String!
    $environment: String!
  ) {
    cgp_evaluation_result(
      distinct_on: [governance_entity_id]
      order_by: [
        { governance_entity_id: desc }
        { evaluation_report: { creation_time: desc } }
      ]
      where: {
        evaluation_report: {
          scope: { _eq: "Evaluation" }
          environmentByEnvironment: { name: { _eq: $environment } }
        }
        governance_entity: {
          status: { _in: ["grace", "enabled", "deprecated"] }
        }
        governance_entity_type: { _eq: "metric" }
        resource_name: { _eq: $dataProductUrn }
        outcome: { _nin: ["pending"] }
      }
    ) {
      result
      id
      governance_entity {
        name
        description
        version
      }
    }
  }
`;
