export function stringToColor(word: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < word.length; i += 1) {
    hash = word.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substring(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  const validatedName = name.length > 0 ? name : 'Z';

  const segments = name.split(' ');
  const firstLetter = segments[0][0];
  const secondLetter = segments.length > 1 ? segments[1][0] : '';

  return {
    sx: {
      bgcolor: stringToColor(validatedName),
    },
    children: `${firstLetter}${secondLetter}`,
  };
}
