import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { getTruncateMaxLinesStyle } from '../../styles';

export interface PreviewMessage {
  type: 'error' | 'warning' | 'info' | 'success';
  title: React.ReactNode;
  content: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  success: {
    background: theme.palette.bkg.success,
    color: theme.palette.secondary.dark,
  },
  info: {
    background: theme.palette.grey[100],
    '&$defaultVariant': {
      border: `1px solid ${theme.palette.softened.secondary}`,
    },
  },
  error: {
    background: theme.palette.bkg.error,
    '&$defaultVariant': {
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  warning: {
    background: theme.palette.bkg.warning,
    '&$defaultVariant': {
      border: `1px solid ${theme.palette.warning.main}`,
    },
  },
  compact: {
    '& $content': {
      ...getTruncateMaxLinesStyle(1),
    },
  },
  default: {
    alignItems: 'normal',
    gap: '16px',
    margin: '12px 16px',
    borderRadius: '8px',
    flexDirection: 'column',
    boxShadow: '0px 2px 8px 0px #00000026',
  },
  content: {},
}));

interface Props {
  message: PreviewMessage;
  variant?: 'compact' | 'default';
  style?: React.CSSProperties;
}

export const CustomSnackbarError: React.FC<Props> = ({
  message,
  style,
  variant = 'default',
}) => {
  const classes = useStyles();

  const classByType: Record<PreviewMessage['type'], string> = {
    info: classes.info,
    error: classes.error,
    warning: classes.warning,
    success: classes.success,
  };

  const variantClass =
    variant === 'default' ? classes.default : classes.compact;

  const content = (
    <Typography className={classes.content}>{message.content}</Typography>
  );

  return (
    <Box
      className={clsx(
        variantClass,
        classes.container,
        classByType[message.type],
      )}
      style={style}
    >
      <Box className={classes.title}>
        <Typography style={{ fontWeight: 700 }}>
          {message.title}
          {variant === 'compact' && ':'}
        </Typography>
      </Box>
      {/* If variant is compact show the tooltip */}
      {variant === 'compact' && message.content ? (
        <Tooltip title={message.content}>{content}</Tooltip>
      ) : (
        content
      )}
    </Box>
  );
};
