import {
  createApiFactory,
  createApiRef,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';
import { GovernanceApi, GovernanceClient } from './api';

import { rootRouteRef } from './routes';

/**
 * Utility API reference for the {@link GovernanceApi}.
 *
 * @public
 */
export const governanceApiRef = createApiRef<GovernanceApi>({
  id: 'plugin.governance.service',
});

export const governancePlugin = createPlugin({
  id: 'wb-governance',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: governanceApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new GovernanceClient({ discoveryApi, fetchApi }),
    }),
  ],
});

/**
 * The Router and main entrypoint to the plugin.
 *
 * @public
 */
export const GovernancePage = governancePlugin.provide(
  createRoutableExtension({
    name: 'GovernancePage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
