import { DataContractPage } from './legacy-data-contract/DataContractPage';
import { DataProductPage } from './DataProductPage';
import { OutputPortPage } from './OutputPortPage';
import { DataContractDrawer } from './data-contract/DataContractDrawer';

export * from './components';
export * from './legacy-data-contract/DataContractCustomView';
export * from './legacy-data-contract/DataContractView';
export * from './data-contract/DataContractDrawerCustomView';
export * from './data-contract/DataContractDrawerCtrl';
export * from './VisualDiscovery/DataContractPopoverOverview';
export * from './DataProductCustomView';
export * from './DataProductSubpages';
export * from './DataProductView';
export * from './OutportPortSubpages';
export * from './OutputPortCustomView';
export * from './OutputPortView';
export * from './schemaComponents';
export * from './SubcomponentsDrawerCustomView';
export * from './OutputCardZoomInCustomView';
export * from './SystemZoomInCustomView';

export const CustomViews = {
  DataContractPage,
  DataProductPage,
  OutputPortPage,
  DataContractDrawer,
};
