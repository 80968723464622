import { DateRange } from '@agilelab/plugin-wb-platform';

export enum DeploymentUnitStatus {
  DEPLOYED = 'DEPLOYED',
  PARTIALLY_DEPLOYED = 'PARTIALLY_DEPLOYED',
  NOT_DEPLOYED = 'NOT_DEPLOYED',
  PROVISIONING_IN_PROGRESS = 'PROVISIONING_IN_PROGRESS',
  CORRUPT = 'CORRUPT',
}

export interface UserFilterValue {
  name: string;
  ref: string;
}

export interface ReleaseFilters {
  update?: DateRange;
  createdBy?: UserFilterValue[];
  deploymentStatus?: DeploymentUnitStatus[];
}

export interface ReleaseFilterBy {
  by: string;
  values: string[];
}
