import {
  TableCellProps,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';
import { RELATION_OWNED_BY, Entity } from '@backstage/catalog-model';
import { OverflowTooltip } from '@backstage/core-components';
import {
  EntityRefLinks,
  getEntityRelations,
  EntityRefLink,
} from '@backstage/plugin-catalog-react';
import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

export const createEntityRefColumn = (options: {
  defaultKind?: string;
}): TableCellProps<any> => {
  const { defaultKind } = options;

  return {
    headerName: 'Name',
    sortable: true,
    field: 'metadata',
    fieldRender: entity => (
      <EntityRefLink
        entityRef={entity}
        defaultKind={defaultKind}
        title={entity.metadata?.title}
      />
    ),
  };
};

export const createNameColumn = (): TableCellProps<any> => {
  return {
    headerName: 'Name',
    field: 'spec',
    cellProps: {
      width: '30%',
    },
    fieldRender: e => {
      const title = e.metadata?.name || e.spec?.mesh?.name;

      return (
        <Tooltip placement="bottom-start" title={title!}>
          <Typography>{title}</Typography>
        </Tooltip>
      );
    },
  };
};

export const createOwnerColumn = (): TableCellProps<Entity> => {
  return {
    headerName: 'Owner',
    field: 'spec',
    cellProps: {
      width: '30%',
    },

    fieldRender: e => (
      <EntityRefLinks
        entityRefs={getEntityRelations(e, RELATION_OWNED_BY)}
        defaultKind="group"
      />
    ),
  };
};

export const createSpecTypeColumn = (): TableCellProps<Entity> => {
  return {
    headerName: 'Type',
    field: 'spec',
    fieldRender: e => (
      <Typography
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {e?.spec?.type as string | undefined}
      </Typography>
    ),
    cellProps: {
      width: '15%',
    },
  };
};

export const createSpecLifecycleColumn = (): TableCellProps<Entity> => {
  return {
    headerName: 'Lifecycle',
    field: 'spec',
    fieldRender: e => (
      <Typography
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {e?.spec?.lifecycle as string | undefined}
      </Typography>
    ),
  };
};

export const createMetadataDescriptionColumn = (): TableCellProps<Entity> => {
  return {
    headerName: 'Description',
    field: 'metadata',
    fieldRender: entity => (
      <OverflowTooltip
        text={entity.metadata.description}
        placement="bottom-start"
        line={2}
      />
    ),
  };
};

export const createDescColumn = (): TableCellProps<Entity> => {
  return {
    headerName: 'Description',
    field: 'kind',
    cellProps: {
      width: '40%',
    },
    fieldRender: e => (
      <WbTruncatedTypographyWrapper value={e.metadata.description ?? ''} />
    ),
  };
};
