import {
  AsyncEnumFilter,
  DateRangeFilter,
  EnumFilter,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import {
  WITBOOST_NAME,
  snakeCaseToTitleCase,
} from '@agilelab/plugin-wb-platform-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React from 'react';
import { useReleasePageContext } from './context/useReleasePageContext';
import { DeploymentUnitStatus, UserFilterValue } from './types';

export const ReleaseFilters: React.FC = () => {
  const { filters, changeFilters, resetFilters } = useReleasePageContext();
  const catalogApi = useApi(catalogApiRef);
  const configApi = useApi(configApiRef);

  return (
    <WbTableFilters onClear={resetFilters}>
      <DateRangeFilter
        field="Update"
        onChange={update => {
          changeFilters('update', update);
        }}
        value={filters.update}
      />

      <AsyncEnumFilter<UserFilterValue>
        field="Created By"
        getOptions={() =>
          catalogApi.getEntities({ filter: { kind: 'user' } }).then(response =>
            response.items
              .map(item => {
                const ref = stringifyEntityRef({
                  kind: 'User',
                  name: item.metadata.name,
                  namespace: item.metadata.namespace,
                });
                const name = (item.spec?.profile as any)?.displayName;
                return { name, ref };
              })
              .concat({
                name: configApi.getOptionalString('app.title') ?? 'Witboost',
                ref: WITBOOST_NAME,
              }),
          )
        }
        onChange={createdBy => {
          changeFilters('createdBy', createdBy);
        }}
        value={filters.createdBy}
        renderOption={o => o.name}
        renderValue={o => o.name}
        onSearch={(search, options) =>
          options.filter(option => new RegExp(search, 'ig').test(option.name))
        }
      />

      <EnumFilter<DeploymentUnitStatus>
        field="Deployment Status"
        options={Object.values(DeploymentUnitStatus)}
        onChange={deploymentStatus => {
          changeFilters('deploymentStatus', deploymentStatus);
        }}
        value={filters.deploymentStatus}
        renderOption={o => snakeCaseToTitleCase(o.toLowerCase())}
        renderValue={o => snakeCaseToTitleCase(o.toLowerCase())}
        onSearch={v =>
          Object.values(DeploymentUnitStatus).filter(o =>
            new RegExp(v, 'ig').test(o),
          )
        }
      />
    </WbTableFilters>
  );
};
