import { Dag, TaskAction } from '@agilelab/plugin-wb-builder-common';
import { ConfigApi } from '@backstage/core-plugin-api';
import lodash from 'lodash';
import * as yaml from 'yaml';

export function extractDagName(dag: Dag): string {
  if (dag.action === TaskAction.PROVISION_DATAPRODUCT) {
    return 'System Provision';
  }
  if (dag.action === TaskAction.UNPROVISION_DATAPRODUCT) {
    return 'System Unprovision';
  }
  if (dag.action === TaskAction.PROVISION) {
    return `${dag.name.split(':').at(-1) || dag.name} Provision`;
  }
  if (dag.action === TaskAction.UNPROVISION) {
    return `${dag.name.split(':').at(-1) || dag.name} Unprovision`;
  }
  return dag.name;
}

function isValidJSON(json: string) {
  try {
    JSON.parse(json);
  } catch (e) {
    return false;
  }
  return true;
}

export function toYAML(str: string) {
  return isValidJSON(str) ? yaml.stringify(JSON.parse(str)) : str;
}

/** Processes a stringified result to extract the correct errors, if any.
 *
 * @param result the original result that needs to be parsed
 * @returns an array containing the parsed error strings, if present
 */
export function getErrors(result: string | null): string[] | null {
  if (!result || !isValidJSON(result)) return null;

  const json = JSON.parse(result);

  if (json.error) {
    if (typeof json.error === 'string') return [json.error];

    const errors = json.error.errors;

    if (errors && Array.isArray(errors)) return errors;
  }

  return null;
}

/** Processes a stringified result to extract the correct result field, if specified from config.
 *
 * @param result the original result that needs to be parsed
 * @param configApi configApi to retrieve the result field to extract
 * @returns the parsed result field extracted from the stringified result
 */
export function getParsedResultField(
  result: string | null,
  configApi: ConfigApi,
): string | undefined {
  const resultFieldPath = configApi.getOptionalString(
    'mesh.provisioner.deployStep.resultField',
  );

  if (!result || !isValidJSON(result) || !resultFieldPath) return undefined;

  const json = JSON.parse(result);

  const resultField = lodash.get(json, resultFieldPath);

  return typeof resultField === 'string'
    ? resultField
    : JSON.stringify(resultField);
}
