import { WbCard } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { GenericContent } from './GenericContent';

type Props = {
  title: string;
  values: any;
  defaultText?: string;
};

export const GenericCard = (props: Props) => {
  return (
    <WbCard title={props.title}>
      <GenericContent {...props} />
    </WbCard>
  );
};
