import { builderDpSnapshotCreatePermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import { Box, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import {
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import { Progress } from '@backstage/core-components';
import { panelCatalogApiRef } from '../../api';
import { useEditorPageContext } from './context/useEditorPageContext';
import { releaseDetailRouteRef } from '../../routes';
import { generateSnapshotButtonTooltip, getReleaseInfo } from '../../utils';

export const NewSnapshotButton = () => {
  const [isCreatingSnapshot, setIsCreatingSnapshot] = useState(false);
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const {
    releases,
    fetchReleaseState,
    entity,
    setVersion,
    environment,
    addRelease,
    fetchDescriptor,
    entitiesAndBranches,
    setCurrRelease,
    actionError,
    descriptorState,
    processorErrors,
  } = useEditorPageContext();

  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);

  const { allowed: canCreateSnapshot } = usePermission({
    permission: builderDpSnapshotCreatePermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const createSnapshot = async () => {
    try {
      if (!entity) return;

      const entities = Object.entries(entitiesAndBranches).map(
        ([key, value]) => ({ entityRef: key, branch: value }),
      );

      setIsCreatingSnapshot(true);
      const newRelease = await panelCatalog.createSnapshot(
        entity.metadata.name,
        entity.kind,
        entities,
      );

      setVersion?.(newRelease.metadata.version);
      setCurrRelease(newRelease);
      addRelease(newRelease);

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      await fetchDescriptor();
      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
          />
        ),
        message: 'New Draft-Release created successfully.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsCreatingSnapshot(false);
    }
  };

  if (fetchReleaseState?.loading) {
    return (
      <div style={{ width: '100%' }}>
        <Progress />
      </div>
    );
  }

  if (!releases || !environment) return null;

  return (
    <Box style={{ paddingRight: '5px' }}>
      <Tooltip
        title={generateSnapshotButtonTooltip(
          canCreateSnapshot,
          false,
          processorErrors,
          descriptorState.loading,
          actionError,
        )}
      >
        <span>
          <WbCardActionButton
            label="New draft release"
            disabled={
              isCreatingSnapshot ||
              !canCreateSnapshot ||
              !!actionError ||
              descriptorState.loading ||
              !!processorErrors?.length
            }
            onClick={createSnapshot}
            loading={isCreatingSnapshot}
            icon={<AddCircle />}
          />
        </span>
      </Tooltip>
    </Box>
  );
};
