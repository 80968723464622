import { TableCellProps } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import {
  ReleaseName,
  ReleaseUpdate,
  ReleaseCreatedBy,
  ReleaseDeploymentStatus,
  ReleaseActions,
} from '../ReleaseTableColumns';

export function useReleasesTableColumns(): TableCellProps<ReleaseAndDeployments>[] {
  return [
    {
      field: 'release' as keyof ReleaseAndDeployments,
      fieldRender: field => <ReleaseName release={field} />,
      headerName: 'Release',
      sortable: true,
    },
    {
      field: 'update' as keyof ReleaseAndDeployments,
      fieldRender: field => <ReleaseUpdate release={field} />,
      headerName: 'Update',
      sortable: true,
    },
    {
      field: 'createdBy' as keyof ReleaseAndDeployments,
      fieldRender: field => <ReleaseCreatedBy release={field} />,
      headerName: 'Created By',
      sortable: true,
    },
    {
      field: 'deploymentStatus' as keyof ReleaseAndDeployments,
      fieldRender: field => <ReleaseDeploymentStatus release={field} />,
      headerName: 'Deployment Status',
      sortable: false,
    },
    {
      field: 'actions' as keyof ReleaseAndDeployments,
      fieldRender: field => <ReleaseActions release={field} />,
      headerName: '',
      cellProps: {
        width: '30%',
      },
      sortable: false,
    },
  ];
}
