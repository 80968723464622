import React from 'react';
import { makeStyles, createStyles, Box, useTheme } from '@material-ui/core';
import {
  TestStatus,
  WbTag,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';
import { Task } from '@agilelab/plugin-wb-builder-catalog';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';

interface Props {
  policy: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles(() =>
  createStyles({
    detailName: {
      flex: 1,
    },
    chip: {
      margin: 0,
    },
  }),
);

export const PolicyItem: React.FC<Props> = ({ policy, task }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          width: '100%',
          gap: theme.spacing(1),
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <TestStatus
            status={task.status}
            filled
            severity={policy.content.severity}
            policyStatus={task.governanceEntityStatus}
          />
          <WbTag
            tag={{
              tagFQN: 'Policy',
            }}
          />
        </Box>

        <div className={classes.detailName}>
          <WbTruncatedTypographyWrapper
            style={{
              fontWeight: 700,
            }}
            value={policy.name}
          />
          <WbTruncatedTypographyWrapper value={policy.description ?? ''} />
        </div>
      </Box>
    </>
  );
};
