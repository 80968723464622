import { renderMessage } from '@agilelab/plugin-wb-platform';
import { Typography, TypographyProps } from '@material-ui/core';
import React from 'react';

import { toDateString } from '../../utils';
import { ExpandableItem } from './ExpandableItem';
import { SimpleItem } from './SimpleItem';

type Props = {
  value: string;
  type: string;
  href?: string;
  expandable?: boolean;
} & TypographyProps;

export const GenericItem = ({
  value,
  type,
  href,
  expandable = true,
  ...typographyProps
}: Props) => {
  if (type === 'date')
    return (
      <Typography variant="body1" {...typographyProps}>
        {toDateString(value)}
      </Typography>
    );

  return (
    <>
      {expandable ? (
        <ExpandableItem href={href}>
          <Typography variant="body1" {...typographyProps}>
            {renderMessage(value)}
          </Typography>
        </ExpandableItem>
      ) : (
        <SimpleItem value={value} href={href} />
      )}
    </>
  );
};
