import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const ExpandAllIcon = createSvgIcon(
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 17.5L6.25 13.75L7.45833 12.5416L10 15.0833L12.5417 12.5416L13.75 13.75L10 17.5ZM7.45833 7.54165L6.25 6.33331L10 2.58331L13.75 6.33331L12.5417 7.54165L10 4.99998L7.45833 7.54165Z"
      fill="currentColor"
    />
  </svg>,
  'ExpandAllIcon',
);
