import { z } from 'zod';

export const DescriptorPickerSchemaZod = z.object({
  sourceType: z.enum(['descriptor', 'field']),
  source: z.string(),
  optionsAt: z.string(),
  optionsDisplayNameAt: z.string().optional(),
});

export type DescriptorPickerSchema = z.infer<typeof DescriptorPickerSchemaZod>;

export type Option = {
  /**
   * The display name of the option
   * if `optionDisplayNameAt` property is not used in the configuration for the DescriptorPicker:
   * - if the options are parsed from an array of items, the default display name is the item index in the array
   * - if the options are parsed from a dictionary of items, the default display name is the key of the item in the dictionary
   */
  label: string;

  /**
   * Unique identifier of the option among the list of options
   * for options coming from an array, this is the index
   * for options coming from a dictionary, this is its key
   */
  key: string;

  /**
   * The portion of the descriptor at the same level of the selected option
   */
  value: Descriptor;
};

export type Descriptor = { [key: string]: any };

export type SourceType = {
  /**
   * Maps a source name to a new source name
   * This is useful if you want to give the platform team the possibility to specify values in the `source` field that hides internal implementation details
   * For instance, for getting a descriptor from the context, the platform team specified `sourceType` = `descriptor` and `source` = `.`. Behind the scenes, this translates into
   * taking the `__consumableInterface__` property that must be present in the `formContext`
   * @param source
   * @returns a modified or identical source
   */
  resolveSource: (source: string) => string;
  /**
   * If you want to manipulate the descriptor coming from the `formContext` right before the parsing of the options start, this is the place to do that.
   * For instance, when `sourceType` = `field` and `source` = any field, this function checks whether the specified field is a descriptor picker field or a generic field and takes
   * a decision on the property to use for parsing accordingly.
   * @param descriptor
   * @returns a modified or identical descriptor ready to be used by the descriptor picker to parse the options
   */
  mapDescriptor: (descriptor: Descriptor) => Descriptor;
};
