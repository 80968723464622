import React, { useMemo } from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';
import { Box, useTheme } from '@material-ui/core';
import { ObjectFieldTemplateProps } from '@rjsf/core';
import { z } from 'zod';
import { isHidden } from '../../utils';

const DEFAULT_ELEMENTS_PER_ROW = 2;
const DEFAULT_ELEMENT_MIN_WIDTH = 200;
const DEFAULT_DISPLAY_TITLE = true;

const DEFAULT_UI_OPTIONS = {
  elementsPerRow: DEFAULT_ELEMENTS_PER_ROW,
  minElementWidth: DEFAULT_ELEMENT_MIN_WIDTH,
  displayTitle: DEFAULT_DISPLAY_TITLE,
};

const UiOptionsZodValidator = z.object({
  elementsPerRow: z
    .number()
    .gt(0)
    .int()
    .optional()
    .default(DEFAULT_ELEMENTS_PER_ROW),
  minElementWidth: z
    .number()
    .gt(0)
    .optional()
    .default(DEFAULT_ELEMENT_MIN_WIDTH),
  displayTitle: z.boolean().optional().default(DEFAULT_DISPLAY_TITLE),
});

type HorizontalTemplateProperties = z.infer<typeof UiOptionsZodValidator>;

function parseHorizontalTemplateProperties(
  uiOptions: ObjectFieldTemplateProps['uiSchema']['ui:options'],
): HorizontalTemplateProperties {
  let properties = DEFAULT_UI_OPTIONS;

  const uiOptionsParsed = UiOptionsZodValidator.safeParse(uiOptions);

  // if ui:options config zod validation is successful, use the config values instead of the default values
  if (uiOptionsParsed.success) properties = uiOptionsParsed.data;

  return properties;
}

export const HorizontalTemplate: LayoutTemplate = (
  props: ObjectFieldTemplateProps,
) => {
  const theme = useTheme();

  const uiOptions = props?.uiSchema?.['ui:options'];

  const { elementsPerRow, displayTitle, minElementWidth } = useMemo(
    () => parseHorizontalTemplateProperties(uiOptions || {}),
    [uiOptions],
  );

  const {
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    idSchema,
    required,
  } = props;

  const columnGap = 10;
  const gapCount = elementsPerRow - 1;
  const totalGapWidth = gapCount * columnGap;

  const columnMaxWidth = `calc((100% - ${totalGapWidth}px) / ${elementsPerRow})`;

  return (
    <Box style={{ display: isHidden(props.uiSchema) ? 'none' : undefined }}>
      {displayTitle && (
        <Box margin={`${theme.spacing(1)}px 0px`}>
          <TitleField
            id={`${idSchema.$id}-title`}
            title={title}
            required={required}
          />
          {description && (
            <DescriptionField
              id={`${idSchema.$id}-description`}
              description={description}
            />
          )}
        </Box>
      )}
      <div
        style={{
          marginTop: 20,
          display: 'grid',
          gridTemplateColumns: `repeat(auto-fill, minmax(max(${minElementWidth}px, ${columnMaxWidth}), 1fr))`,
          gridGap: columnGap,
          rowGap: 20,
        }}
      >
        {properties.map(p => p.content)}
      </div>
    </Box>
  );
};

export const HorizontalTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'HorizontalTemplate',
    component: HorizontalTemplate,
  }),
);
