import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import React from 'react';

export type MockApolloProviderProps = {
  children: any;
};

export const MockApolloProvider = (props: MockApolloProviderProps) => {
  return (
    <ApolloProvider
      client={
        new ApolloClient({
          cache: new InMemoryCache({
            addTypename: false,
          }),
        })
      }
    >
      {props.children}
    </ApolloProvider>
  );
};
