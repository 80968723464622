import React from 'react';
import Typography from '@material-ui/core/Typography';
import WarningIcon from '@material-ui/icons/Warning';
import { Box, useTheme } from '@material-ui/core';

interface WarningMessageProps {
  message: string;
}

export const WarningMessage: React.FC<WarningMessageProps> = ({ message }) => {
  const theme = useTheme();
  return (
    <Box
      style={{
        width: '100%',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <WarningIcon
        style={{ color: theme.palette.warning.main, marginRight: '10px' }}
      />
      <Typography variant="body1" style={{ fontWeight: 'bold' }}>
        {message}
      </Typography>
    </Box>
  );
};
