import { AsyncState } from 'react-use/lib/useAsyncFn';

export interface CustomSelectedValues {
  tableValues: TextFieldValue[];
  arbitraryValues?: TextFieldValue[];
}

export enum KindEnum {
  arbitrary = 'Arbitrary',
  system = 'System',
  domain = 'Domain',
  resource = 'Resource',
  consumable = 'Consumable',
}

export interface TextFieldValue {
  kind: KindEnum;
  label: string;
  value?: string | GenericEntityType;
  kindDisplayName?: string;
}

export type Pagination = {
  limit: number;
  currentPage: number;
  cursor?: string;
  countlessOptions?: { hasNextPage: () => boolean; isFirstPage: () => boolean };
};

export type TemplateColumn = {
  name: string;
} & (
  | {
      path: string;
    }
  | {
      value: string;
    }
);

export type Column = {
  name: string;
  path: string;
  type?: string;
};
export interface GenericProviderType<T, F> {
  columns: Column[];
  entitiesState: AsyncState<T>;
  filters: F;
  changeFilters: <K extends keyof F>(key: K, value: F[K]) => void;
  resetFilters: () => void;
  pagination: Pagination;
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>;
  count: number;
  nextCursor?: string;
  prevCursor?: string;
  templateKind?: TemplateEntity;
}

export interface Provider<T, F> {
  useContext: () => GenericProviderType<T, F>;
  renderContextProvider: (
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
    parentTemplateRef?: string,
  ) => React.JSX.Element;
  filtersSection: () => React.JSX.Element;
  /**
   * Resolve entity ref/urn or throw new error if any entity id founded .
   *
   * @param {string} ref - Entity ref or urn .
   * @returns
   * - `undefined`: If the ref was rejected, the entity provider could not process it.
   * - `NotFoundException`: If the ref was handled but no entity was found
   * - An instance of @link {TextFieldValue} if the entity was found
   *
   * @throws {NotFoundException} If the reference was handled but entity doesn't exist.
   */
  resolve: (ref: string) => Promise<TextFieldValue | undefined>;
}
export interface GenericEntityType {
  __metadata: {
    name: string;
    kind: KindEnum;
  };
  [key: string]: any;
}

export interface TemplateEntity {
  type: string;
  displayName: string;
  returnField?: 'urn' | 'full' | 'ref';
  displayField?: string;
  userFilters?: string[];
  columns?: TemplateColumn[];
}
