import {
  EnumFilter,
  TableCellProps,
  ThresholdResult,
  WbCard,
  WbCardContent,
  WbTable,
  WbTableFilters,
  WbTruncatedTypographyWrapper,
  getObjectPropFromString,
  useDrawersContext,
} from '@agilelab/plugin-wb-platform';
import React, { useEffect, useState } from 'react';
import {
  GET_EVALUATION_REPORT_BY_UUID,
  GET_METRIC_BY_METRIC_ID_AND_ENVS,
  TestDetailDrawer,
} from '@agilelab/plugin-wb-governance';
import { Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import {
  MetricByIdAndEnvsQuery,
  MetricItem as GovernanceMetricItem,
  MetricResult,
  Timing,
  EvaluationReportByUUIDQuery,
} from '@agilelab/plugin-wb-governance-common';
import _ from 'lodash';
import useAsync from 'react-use/lib/useAsync';
import { useApolloClient } from '@apollo/client';

export interface Filters {
  text?: string;
  result?: string[];
  timing?: string[];
}

type MetricItem = GovernanceMetricItem & { id: string };

type FilterField = 'result' | 'timing';

const changeFilter = (
  field: FilterField,
  value: string[] | undefined,
  setFilters: (filters: Filters) => void,
  currentFilters: Filters,
) => {
  setFilters({ ...currentFilters, [field]: value });
};

export const MetricsTable = ({
  metricResults,
  allowLink,
}: {
  metricResults: MetricResult[];
  allowLink: boolean;
}) => {
  const columns: TableCellProps<MetricItem>[] = [
    {
      field: 'name',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper
          value={`${field.name} v:${field.version}`}
        />
      ),
      headerName: 'Metric',
      cellProps: {
        align: 'left',
        width: '20%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'timing',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper value={field.timing} />
      ),
      headerName: 'Timing',
      cellProps: {
        align: 'left',
        width: '15%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'description',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper value={field.description} />
      ),
      headerName: 'Description',
      cellProps: {
        align: 'left',
        width: '20%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'date',
      fieldRender: field => (
        <Typography component="div">
          {format(parseISO(field.date ?? ''), 'yyyy/MM/dd HH:mm:ss')}
        </Typography>
      ),
      headerName: 'Date',
      cellProps: {
        align: 'left',
        width: '15%',
        size: 'small',
      },
    },
    {
      field: 'result',
      fieldRender: (field: any) => {
        return (
          <ThresholdResult
            value={field.value}
            result={field.result}
            hasError={field.error}
          />
        );
      },
      headerName: 'Result',
      cellProps: {
        align: 'left',
        width: '30%',
        size: 'small',
      },
      sortable: true,
    },
  ];
  const apolloClient = useApolloClient();
  const { drawers, toggleBoolean } = useDrawersContext();
  const [dataTable, setDataTable] = useState<MetricItem[]>([]);
  const [evaluationId, setEvaluationId] = useState<string>();
  const [filters, setFilters] = useState<Filters>({});
  const [pagination, setPagination] = useState({
    limit: 25,
    offset: 0,
  });

  const { value: data, loading } = useAsync(async () => {
    const res = metricResults.map(async result => {
      const metric = (
        await apolloClient.query<MetricByIdAndEnvsQuery>({
          query: GET_METRIC_BY_METRIC_ID_AND_ENVS,
          variables: { metricId: result.metricId },
        })
      ).data.cgp_governance_entity[0];
      const report = (
        await apolloClient.query<EvaluationReportByUUIDQuery>({
          query: GET_EVALUATION_REPORT_BY_UUID,
          variables: { uuid: result.reportId },
        })
      ).data.cgp_evaluation_report[0];
      return {
        id: result.evaluationResultId,
        name: metric.name,
        result: result.evaluationResult.thresholdResult,
        value: result.evaluationResult.value,
        description: metric.description,
        version: metric.version,
        error: !!result.evaluationResult.errors.length,
        date: report.update_time,
        timing: metric.timing as Timing,
      } as MetricItem;
    });
    return Promise.all(res);
  }, [metricResults]);

  useEffect(() => {
    if (!data) return;
    const fields = columns.map(col => col.field || '');
    Object.keys(filters).forEach(key =>
      filters[key as keyof Filters] === undefined
        ? delete filters[key as keyof Filters]
        : {},
    );

    const filtered = data?.filter(row => {
      const rowItems = fields.map(field => getObjectPropFromString(row, field));

      return rowItems.find(
        item => item && item.includes(filters?.text?.toLowerCase() ?? ''),
      );
    });

    let filteredData = filtered;
    if (filters.timing) {
      const filterTimings = (filters.timing || []).map(tim =>
        _.capitalize(tim.toLocaleLowerCase()),
      );

      filteredData = filteredData.filter(row => {
        const rowTiming = _.capitalize(row.timing?.toLocaleLowerCase() ?? '');

        const timingMatches =
          filterTimings.length === 0 || filterTimings.includes(rowTiming);

        return timingMatches;
      });
    }

    setDataTable(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, allowLink, filters]);

  const timingOptions = ['Runtime', 'Deployment'];

  const filterConfigurations = [
    {
      field: 'Timing',
      options: timingOptions,
      value: filters.timing,
      onChange: (timing: string[] | undefined) =>
        changeFilter('timing', timing, setFilters, filters),
      onSearch: (v: string | RegExp) =>
        timingOptions.filter(o => new RegExp(v, 'ig').test(o)),
    },
  ];

  return (
    <>
      <WbCard title="Metrics">
        <WbCardContent>
          <WbTableFilters
            searchValue={filters.text}
            onSearch={search => {
              setFilters({ text: search });
            }}
            onClear={() => setFilters({})}
          >
            {filterConfigurations.map((config, index) => (
              <EnumFilter<string>
                key={index}
                field={config.field}
                options={config.options}
                onChange={config.onChange}
                value={config.value}
                renderOption={(o: any) => o}
                renderValue={(o: any) => o}
                onSearch={config.onSearch}
              />
            ))}
          </WbTableFilters>
          <WbTable<MetricItem>
            components={{
              tableLoader: { loading },
              tableContent: {
                columns,
                rows: dataTable.sort(
                  (a, b) =>
                    new Date(b.date ?? 0).getTime() -
                    new Date(a.date ?? 0).getTime(),
                ),
                onRowClick: e => {
                  setEvaluationId(e.id);
                  toggleBoolean(1);
                },
              },
            }}
            pagination={{
              count: dataTable?.length || 0,
              limit: pagination.limit,
              offset: pagination.offset,
              onPageChange: (page: number) => {
                setPagination({
                  ...pagination,
                  offset: page * pagination.limit,
                });
              },
              onRowsPerPageChange: (rowsPerPage: number) => {
                setPagination({
                  offset: 0,
                  limit: rowsPerPage,
                });
              },
            }}
          />
        </WbCardContent>
      </WbCard>

      <TestDetailDrawer
        evaluationId={evaluationId!}
        idType="external"
        open={drawers.get(1) ?? false}
        setOpen={() => toggleBoolean(1)}
      />
    </>
  );
};
