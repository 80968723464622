import React, { useContext, useState } from 'react';

export interface DrawersContextType {
  drawers: Map<number, boolean>;
  toggleBoolean: (key: number) => void;
  onCloseAll: () => void;
}

export const DrawersContext = React.createContext<DrawersContextType>(
  {} as DrawersContextType,
);

export interface DrawersContextProviderProps {
  children?: React.ReactNode;
}

export const DrawersContextProvider: React.FC<DrawersContextProviderProps> = ({
  children,
}) => {
  const [drawers, setDrawers] = useState(new Map<number, boolean>());
  const keysArray = Array.from(drawers.keys());

  const toggleBoolean = (key: number) => {
    setDrawers(prevState => {
      const newState = new Map(prevState);
      newState.set(key, !newState.get(key));
      return newState;
    });
  };

  const onCloseAll = () => {
    keysArray.reverse().forEach(key => {
      const state = drawers.get(key);
      if (state) toggleBoolean(key);
    });
  };

  return (
    <DrawersContext.Provider
      value={{
        drawers,
        toggleBoolean,
        onCloseAll,
      }}
    >
      {children}
    </DrawersContext.Provider>
  );
};

export const useDrawersContext = () => useContext(DrawersContext);
