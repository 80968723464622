import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

import { ReactNode, useContext } from 'react';
import { WbSidebarConfigContext } from '../config';
import clsx from 'clsx';
import { getOnHoverScrollbarStyle } from '../../../styles';

type WbSidebarScrollWrapperProps = {
  hoverOnlyScrollbar?: boolean;
  children?: ReactNode;
};

const useStyles = makeStyles<
  Theme,
  { hoverOnlyScrollbar: boolean; itemHeight: number }
>(theme => ({
  root: props => ({
    flex: '0 1 auto !important',
    overflowX: 'hidden',
    width: '100%',
    minHeight: props.itemHeight,
    ...getOnHoverScrollbarStyle(theme, props.hoverOnlyScrollbar),
  }),
}));

export const WbSidebarScrollWrapper = ({
  hoverOnlyScrollbar = false,
  children,
}: WbSidebarScrollWrapperProps) => {
  const {
    sidebarConfig: { itemHeight },
  } = useContext(WbSidebarConfigContext);

  const classes = useStyles({ hoverOnlyScrollbar, itemHeight });

  return <div className={clsx(classes.root)}>{children}</div>;
};
