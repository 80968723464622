import { z } from 'zod';
import { GovernanceEntityType, Outcome, Status, Timing } from '../../enums';
import { ResourceIdentifier, ResourceIdentifierSchema } from '../resource';
import {
  BaseEvaluationResult,
  EvaluationResultResult,
  ResourceSnapshot,
} from '../evaluation-result';

// Types

export type ResourceStatusIdentifier = {
  resourceIdentifier: ResourceIdentifier;
  governanceEntityId: string;
};

// Requests

export const ResourceStatusIdentifierSchema = z.object({
  resourceIdentifier: ResourceIdentifierSchema,
  governanceEntityId: z.string(),
});

export const StatusFilterSchema = z.object({
  identifierIn: z.array(ResourceStatusIdentifierSchema).min(1),
});

export const ResourceFilterSchema = z.object({
  identifierIn: z.array(ResourceIdentifierSchema).min(1),
});

export const GovernanceEntityFilterSchema = z.object({
  idIn: z.array(z.string()).min(1).optional(),
  typeIn: z.array(z.nativeEnum(GovernanceEntityType)).min(1).optional(),
  timingIn: z.array(z.nativeEnum(Timing)).min(1).optional(),
  statusIn: z.array(z.nativeEnum(Status)).min(1).optional(),
});

export const ResultFilterSchema = z.object({
  outcomeIn: z.array(z.nativeEnum(Outcome)).min(1).optional(),
});

export const FindResourceStatusRequestFiltersSchema = z.object({
  statusFilter: StatusFilterSchema.optional(),
  resourceFilter: ResourceFilterSchema.optional(),
  governanceEntityFilter: GovernanceEntityFilterSchema.optional(),
  resultFilter: ResultFilterSchema.optional(),
});

export const FindResourceStatusRequestResultOptionsSchema = z.object({
  additionalFields: z
    .array(z.enum(['LAST_RESULT_RESOURCE_SNAPSHOT', 'LAST_RESULT_CONTENT']))
    .min(1)
    .optional(),
  pagination: z
    .object({
      pageSize: z.number().min(1),
      cursor: z.string().optional(),
    })
    .optional(),
});

export const FindResourceStatusRequestSchema = z.object({
  filters: FindResourceStatusRequestFiltersSchema,
  resultOptions: FindResourceStatusRequestResultOptionsSchema,
});

export type FindResourceStatusRequest = z.infer<
  typeof FindResourceStatusRequestSchema
>;

// Responses

export type FindResourceStatusResponseLastResult = {
  details: BaseEvaluationResult;
  resourceSnapshot?: ResourceSnapshot;
  resultContent?: EvaluationResultResult;
};

export type ResultScheduling = {
  /**
   * Cron expression in Quartz-like syntax with 6 fields that go from seconds to day of week in the following order: Seconds (0-59), Minutes (0-59), Hour of Day (0-23), Day of Month (1-31), Month (1-12), Day Of Week (0-6 where 0 is Monday).
   * Time zone: UTC
   */
  frequency: string;
  /**
   * An ISO-8601 duration string (`PnDTnHnMnS` format) that specifies the length of the tolerance window after each scheduled `frequency` time.
   * The tolerance window starts at the exact time of the cron expression, and results are accepted as "on time" if they are received
   * within this period. If no result is received within this window, a delay is reported.
   * For example, `PT1H` means a tolerance window of 1 hour.
   */
  toleranceWindowLength: string;
  /**
   * The scheduled time — ISO-8601 format — by which the next evaluation result is expected. This represents the target time for receiving the result.
   */
  expectedResultTime: string;
  /**
   * The latest acceptable time — ISO-8601 format — for receiving the evaluation result, including any tolerance window. If the result is not received by this time, the evaluation is considered overdue until the result is eventually received.
   */
  acceptableResultTimeLimit: string;
  /**
   * The current timing status of the evaluation relative to `expectedResultTime` and `acceptableResultTimeLimit`. This status will change depending on whether the result has been received:
   * - `ON_TIME` — no delays for the evaluation
   * - `IN_TOLERANCE_WINDOW` — the evaluation result has not yet been received, but it is still within the allowable tolerance period (between `expectedResultTime` and `acceptableResultTimeLimit`)
   * - `LATE` — the result has not been received and the tolerance period (`acceptableResultTimeLimit`) has expired, marking the evaluation as overdue. Once the result is eventually received, the status will revert to `ON_TIME`
   */
  status: 'ON_TIME' | 'IN_TOLERANCE_WINDOW' | 'LATE';
};

export type FindResourceStatusResponse = {
  data: {
    identifier: ResourceStatusIdentifier;
    resultScheduling?: ResultScheduling | null;
    lastResult?: FindResourceStatusResponseLastResult | null;
  }[];
  meta: {
    pagination?: {
      pageSize: number;
      nextCursor?: string;
    };
  };
};
