import React from 'react';
import { useStyles } from './style';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import { IconLabelBox } from '../../IconLabelBox';
import { useTheme } from '@material-ui/core';

interface Props {
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const RunningIcon: React.FC<Props> = ({ style, variant }) => {
  const classes = useStyles();
  const theme = useTheme();

  return variant === 'reduced' ? (
    <AutorenewIcon
      className={`${classes.icon} ${classes.animatedIcon}`}
      style={style}
    />
  ) : (
    <IconLabelBox
      label="Running"
      backgroundColor={theme.palette.accent.light}
      icon={<AutorenewIcon style={style} htmlColor={theme.palette.black} />}
    />
  );
};
