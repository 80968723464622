import {
  Box,
  makeStyles,
  TableCell,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import TabIcon from '@material-ui/icons/SubdirectoryArrowRight';
import React, { useMemo } from 'react';
import { DeploymentPreview } from '../types';
import {
  Action,
  DeploymentUnitStatus,
  WitboostSubcomponent,
} from '@agilelab/plugin-wb-builder-common';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ComponentDependencies } from '../ProvisioningCard/ComponentsTable/ComponentDependencies';
import { DeploymentPreviewChip } from './DeploymentPreviewChip';
import LinkIcon from '@material-ui/icons/Link';
import { WbSelect } from '@agilelab/plugin-wb-platform';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const useStyles = makeStyles(() => ({
  select: {
    '& > div': {
      padding: '5px',
    },
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  cell: {
    padding: '0px 24px 0px 16px',
  },
}));

interface Props {
  componentPreview: DeploymentPreview;
  subComponent: WitboostSubcomponent;
  keyProp: string;
  advanced?: boolean;
}

export const SubComponentRow: React.FC<Props> = ({
  subComponent,
  componentPreview,
  keyProp,
  advanced = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const preview: DeploymentPreview = useMemo(() => {
    return {
      ...subComponent,
      status: subComponent.status,
      statusVersion: subComponent.statusVersion,
      newState: componentPreview.newState,
      action: componentPreview.action,
    };
  }, [subComponent, componentPreview.newState, componentPreview.action]);

  return (
    <TableRow
      key={keyProp}
      style={{
        background: preview.autoUpdated ? theme.palette.grey[100] : 'inherit',
      }}
    >
      <TableCell>
        <Box className={classes.nameCell}>
          <TabIcon fontSize="small" htmlColor={theme.palette.grey[500]} />

          {preview.autoUpdated && <LinkIcon color="primary" />}
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            {preview.name}
          </Typography>
        </Box>
      </TableCell>
      <TableCell>
        <Typography variant="body2">
          {snakeCaseToTitleCase(preview.kind)}
        </Typography>
      </TableCell>
      <TableCell>
        <ComponentDependencies preview={preview} />
      </TableCell>
      <TableCell>
        <DeploymentPreviewChip
          status={preview.status}
          version={preview.statusVersion}
        />
      </TableCell>
      <TableCell>
        <Box
          style={{
            display: 'flex',
            gap: '16px',
            alignItems: 'center',
            marginLeft: '-32px',
          }}
        >
          <ArrowForwardIcon htmlColor={theme.palette.accent.main} />

          <DeploymentPreviewChip
            status={preview.newState as unknown as DeploymentUnitStatus}
          />
        </Box>
      </TableCell>
      <TableCell
        style={advanced ? { maxWidth: 300, minWidth: 300, width: 300 } : {}}
      >
        {advanced ? (
          <WbSelect
            value={preview.action}
            disabled
            options={[
              Action.DEPLOY,
              Action.NO_ACTION,
              Action.UNDEPLOY,
              Action.UNDEPLOY_AND_REMOVE_DATA,
            ]}
            getOptionLabel={value => snakeCaseToTitleCase(value)}
            InputProps={{
              style: {
                fontSize: '14px',
                minHeight: 'auto',
                marginTop: '4px',
              },
            }}
          />
        ) : (
          <Typography variant="body2" style={{ fontWeight: 700 }}>
            {preview.action && snakeCaseToTitleCase(preview.action)}
          </Typography>
        )}
      </TableCell>
    </TableRow>
  );
};
