import React, { useState } from 'react';
import {
  WbCard,
  WbCardContent,
  WbTabs,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { GovernanceTestOverviewMetricDetails } from './GovernanceTestOverviewMetricDetails';
import { Metric } from '@agilelab/plugin-wb-governance-common';
import { GovernanceTestOverviewMetricInfo } from './GovernanceTestOverviewMetricInfo';
import { Box } from '@material-ui/core';

interface Props {
  metric: Metric;
  infoContent?: JSX.Element;
  test: {
    errors?: string[];
    content: string;
    name: string;
  };
}

enum Tab {
  Result,
  Entity,
}

export const GovernanceTestOverviewMetricControlPanel: React.FC<
  Props
> = props => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Result);
  return (
    <WbCard
      title="Overview"
      toolsComponents={
        <WbTabs
          tabs={[
            {
              name: 'Result',
              action: () => {
                setSelectedTab(Tab.Result);
              },
              selected: selectedTab === Tab.Result,
            },
            {
              name: 'Metric',
              action: () => {
                setSelectedTab(Tab.Entity);
              },
              selected: selectedTab === Tab.Entity,
            },
          ]}
        />
      }
    >
      <WbCardContent>
        {selectedTab === Tab.Result ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <WbWidget title="Info">
              <WbCardContent>{props.infoContent}</WbCardContent>
            </WbWidget>

            <GovernanceTestOverviewMetricDetails
              {...props}
              test={{
                errors: props.test.errors || [],
                content: props.test.content,
                name: props.test.name,
              }}
            />
          </Box>
        ) : (
          <GovernanceTestOverviewMetricInfo {...props} />
        )}
      </WbCardContent>
    </WbCard>
  );
};
