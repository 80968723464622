import { ApolloProvider } from '@apollo/client';
import { Progress } from '@backstage/core-components';
import {
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Alert } from '@material-ui/lab';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';
import { auditApiRef, customAlertApiRef } from '../../apis';
import { createApolloClient, getHasuraUrlFromConfig } from './client';

export type MeshApolloProviderProps = {
  children: any;
};

export const useMeshApolloClient = () => {
  const configApi = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);
  const auditApi = useApi(auditApiRef);

  const {
    loading,
    error,
    value: apolloClient,
  } = useAsync(async () => {
    return createApolloClient(
      auditApi,
      alertApi,
      identityApi,
      getHasuraUrlFromConfig(configApi),
    );
  }, [configApi]);

  return { loading, error, client: apolloClient };
};

/**
 * Allows children components (i.e. all plugins across the entire app) to use hooks from Apollo Client, such as: useQuery.
 * @param props
 * @returns
 */
export const MeshApolloProvider = (props: MeshApolloProviderProps) => {
  const { loading, error, client } = useMeshApolloClient();

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        Could not find a valid Apollo Client for MeshApolloProvider
      </Alert>
    );
  }

  return <ApolloProvider client={client!}>{props.children}</ApolloProvider>;
};
