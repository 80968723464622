/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { SelectItem } from '@backstage/core-components';
import FormControl from '@material-ui/core/FormControl';
import React from 'react';

import { RepoUrlPickerState } from './types';
import { WbSelect, WbTextField } from '@agilelab/plugin-wb-platform';
import { MenuItem } from '@material-ui/core';
import { useFormExtraConfigContext } from '../../../contexts/FormExtraConfigContext';

export const GitlabRepoPicker = (props: {
  fieldsLabels?: {
    allowedOwnersLabel?: string;
    ownerInputLabel?: string;
    ownerInputHelper?: string;
  };
  allowedOwners?: string[];
  allowedRepos?: string[];
  state: RepoUrlPickerState;
  onChange: (state: RepoUrlPickerState) => void;
  rawErrors: string[];
  title?: string;
  description?: string;
}) => {
  const {
    allowedOwners = [],
    state,
    onChange,
    rawErrors,
    fieldsLabels,
  } = props;
  const ownerItems: SelectItem[] = allowedOwners
    ? allowedOwners.map(i => ({ label: i, value: i }))
    : [{ label: 'Loading...', value: 'loading' }];

  const { owner } = state;

  const helperText =
    fieldsLabels?.ownerInputHelper ??
    `A group or a user that the repository belongs to, e.g.
  'group/sub-group/sub-sub-group' or 'name.surname'. There are two
  ways of creating a new component. One way of creating it is by
  making a monorepo (in that way you will never change the field
  'Repository' and it will always be a repository containing your data
  product and you will only need to change the root directory). The
  second way of creating a new component is by doing it always in
  another repository (in that case the root directory will always be
  '.' and you will always change the repository field`;

  const { debounceMs } = useFormExtraConfigContext();

  return (
    <>
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !owner}
      >
        {allowedOwners?.length ? (
          <WbSelect
            label={fieldsLabels?.allowedOwnersLabel ?? 'Owner Available'}
            value={owner}
            disabled={allowedOwners.length === 1}
            onChange={e => {
              onChange({
                owner: e.target.value,
              });
            }}
            helperText={helperText}
          >
            {ownerItems.map(o => (
              <MenuItem value={o.value} key={o.value}>
                {o.label}
              </MenuItem>
            ))}
          </WbSelect>
        ) : (
          <WbTextField
            label={fieldsLabels?.ownerInputLabel ?? 'User/Group'}
            value={owner}
            debounceMs={debounceMs}
            onChange={e => onChange({ owner: e.target.value })}
            id="ownerInput"
            helperText={helperText}
          />
        )}
      </FormControl>
    </>
  );
};
