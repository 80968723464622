import { z } from 'zod';

export const informativeNotificationRequestDataZod = z.object({
  text: z.string(),
  html: z.string().optional(),
  json: z.any().optional(),
});

export type InformativeNotificationRequestData = z.infer<
  typeof informativeNotificationRequestDataZod
>;
