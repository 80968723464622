import {
  entityEnvelopeSchemaValidator,
  Entity,
  EntityMeta,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostSystem.v1alpha1.schema.json';

export interface WitboostSystem extends Entity {
  apiVersion:
    | 'backstage.io/v1alpha1'
    | 'backstage.io/v1beta1'
    | 'witboost.com/v1';
  kind: 'System';
  metadata: EntityMeta & {
    classDetails?: { pluralizedDisplayName?: string; displayName?: string };
  };
  spec: {
    owner: string;
    domain: string;
    type: string;
    mesh: {
      consumable?: boolean;
      shoppable?: boolean;
      name: string;
      version: string;
      useCaseTemplateId?: string;
      infrastructureTemplateId?: string;
      dataProductOwner?: string;
      projectOwner?: string;
      developmentGroup?: string;
    } & Record<string, any>;
    instanceOf?: string;
    lifecycle?: string;
  } & Record<string, any>;
  witboost?: {
    parameters?: Record<string, any>;
  };
}

// TODO add the dependency to the Entity schema when this issue is completed
// https://github.com/backstage/backstage/issues/17779
export const applyWitboostSystem =
  entityEnvelopeSchemaValidator<WitboostSystem>(schema);

export const witboostSystemV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
