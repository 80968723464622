import React from 'react';
import { get } from 'lodash';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';
import { GenericWidget } from '../generic';
import { Grid } from '@material-ui/core';
import { NoDataPanel } from '../generic/NoDataPanel';
import { ComponentInstanceEntity } from '@agilelab/plugin-wb-marketplace-common';

export const DataQualityCard = (props: {
  outputPortEntity: ComponentInstanceEntity & Record<string, any>;
}) => {
  const { outputPortEntity: op } = props;
  const qualityValues = get(op.descriptor, 'quality') ?? get(op, 'quality');

  return (
    <WbCard title="Data Quality">
      {qualityValues ? (
        <WbCardContent>
          <Grid container spacing={2} alignItems="stretch">
            {qualityValues?.map((item: Record<string, any>, index: number) => {
              const { code, ...rest } = item;
              return (
                <Grid item xs={12} key={index.toFixed(1)}>
                  <GenericWidget
                    title={`${
                      code
                        ? camelToSpacedCase(code)
                        : `Check quality ${index + 1}`
                    }`}
                    values={rest}
                  />
                </Grid>
              );
            })}
          </Grid>
        </WbCardContent>
      ) : (
        <NoDataPanel title="Data Quality" />
      )}
    </WbCard>
  );
};
