import { DiffEditor, DiffEditorProps } from '@monaco-editor/react';
import type monaco from 'monaco-editor';
import React from 'react';

export type WbDiffEditorProps = DiffEditorProps;
export type MonacoDiffEditor = monaco.editor.IStandaloneDiffEditor;

/**
 * A wrapper component for the DiffEditor component from the @monaco-editor/react library.
 * @param {WbDiffEditorProps} props - The props for the WbDiffEditor component.
 * @returns {JSX.Element} - A React component that renders the DiffEditor component.
 */
export const WbDiffEditor = (props: WbDiffEditorProps): JSX.Element => {
  const {
    original,
    modified,
    theme,
    language,
    onMount: customOnMount,
    ...wbProps
  } = props;

  return (
    <DiffEditor
      original={original}
      modified={modified}
      theme={theme ?? 'default'}
      language={language ?? 'yaml'}
      onMount={(editorInstance, monaco) => {
        if (editorInstance) {
          editorInstance.updateOptions({ scrollBeyondLastLine: false });
          if (customOnMount) customOnMount(editorInstance, monaco);
        }
      }}
      {...wbProps}
    />
  );
};
