import { gql } from '@apollo/client/core';

export const GET_DOWNTIMES_BY_INSTANCE_ID_AND_ENV_NAME = gql`
  query GetDowntimesByInstanceIdAndEnvName($id: bigint!, $envName: String!) {
    observability: marketplace_observability_registry(
      where: { instance_id: { _eq: $id } }
    ) {
      observability_instances(
        where: { environment: { name: { _eq: $envName } } }
      ) {
        observability_data(where: { metric: { _eq: "DataDowntime" } }) {
          value
          timestamp
          metric
          unit
        }
      }
    }
  }
`;

export const GET_STATUS_BY_INSTANCE_ID_AND_ENV_NAME_AND_OBS_ID = gql`
  query GetStatusByInstanceIdAndEnvName($id: bigint!, $envName: String!) {
    observability: marketplace_observability_registry(
      where: {
        _and: [{ instance_id: { _eq: $id } }, { name: { _eq: "DQ Metrics" } }]
      }
    ) {
      observability_instances(
        where: { environment: { name: { _eq: $envName } } }
      ) {
        observability_data(where: { metric: { _eq: "Status" } }) {
          value
          timestamp
          metric
        }
      }
    }
  }
`;
