import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme =>
  createStyles({
    detailContainer: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '16px',
    },
    version: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
    },
    row: {
      cursor: 'pointer',
      '&:hover': {
        background: theme.palette.bkg.primary,
      },
    },
  }),
);
