import React, { useState } from 'react';
import { WbCard, WbCardContent, WbTabs } from '@agilelab/plugin-wb-platform';
import { GovernanceTestOverviewInfo } from '../GovernanceTestOverviewInfo';
import { GovernanceTestOverviewMetricDetails } from './GovernanceTestOverviewMetricDetails';
import {
  EvaluationResultSpecific,
  Metric,
} from '@agilelab/plugin-wb-governance-common';
import { GovernanceTestOverviewMetricInfo } from './GovernanceTestOverviewMetricInfo';
import { Box } from '@material-ui/core';

interface Props {
  result: EvaluationResultSpecific;
  metric: Metric;
}

enum Tab {
  Result,
  Entity,
}

export const GovernanceTestOverviewMetricTabs: React.FC<Props> = props => {
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Result);
  return (
    <WbCard
      cardStyle={{ height: '100%' }}
      title="Overview"
      toolsComponents={
        <WbTabs
          tabs={[
            {
              name: 'Result',
              action: () => {
                setSelectedTab(Tab.Result);
              },
              selected: selectedTab === Tab.Result,
            },
            {
              name: 'Metric',
              action: () => {
                setSelectedTab(Tab.Entity);
              },
              selected: selectedTab === Tab.Entity,
            },
          ]}
        />
      }
    >
      <WbCardContent>
        {selectedTab === Tab.Result ? (
          <Box
            style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <GovernanceTestOverviewInfo {...props} />
            <GovernanceTestOverviewMetricDetails
              {...props}
              test={{
                errors: props.result.result?.errors || [],
                content: props.result.resource_content,
                name:
                  props.result.resource_display_name ||
                  props.result.resource.external_id,
              }}
            />
          </Box>
        ) : (
          <GovernanceTestOverviewMetricInfo {...props} />
        )}
      </WbCardContent>
    </WbCard>
  );
};
