import { platformCustomViewEditPermission } from '@agilelab/plugin-wb-rbac-common';
import { usePermission } from '@backstage/plugin-permission-react';
import {
  Box,
  IconButton,
  makeStyles,
  MenuList,
  Paper,
  Popover,
} from '@material-ui/core';
import MoreVert from '@material-ui/icons/MoreVert';
import React, { ReactElement, useState } from 'react';
import { downloadFile, dumpJsxAsYaml } from '../../utils';
import { CustomViewDownloadItem } from './CustomViewDownloadItem';
import { CustomViewSubMenu } from './CustomViewSubMenu';
import { CustomViewPages } from './pages';
import { extractIncludeFromCode, idToLabelMap } from './utils';

/*
NOTE:
when dumping the segment recorded in the registry, we need to recreate the right conditions, inducing a fake rendering:
the use of two fake components instead of one is due to need attach the right DataRootPathContextProvider
*/
const saveYaml = (filename: string, yamldata: string) => {
  downloadFile(yamldata, `${filename}.yaml`, 'application/yaml');
};

const useStyles = makeStyles(theme => ({
  standardPositioned: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  onlyIcon: {
    minWidth: 0,
    borderRadius: '50%',
    padding: '4px',
  },
  option: {
    padding: '8px',
    cursor: 'pointer',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
  },
}));

const SegmentDownloader = ({
  children,
  filename,
  done,
}: {
  children: ReactElement;
  filename: string;
  done: Function;
}) => {
  if (children) {
    saveYaml(filename, dumpJsxAsYaml(children));
    done();
  }
  return <></>;
};

export const CustomViewDownloader = ({
  customViewIds,
  withMenu,
}: {
  customViewIds: string[];
  withMenu?: boolean;
}) => {
  const [dumpSegment, setDumpSegment] = useState<{ fn: Function | undefined }>({
    fn: undefined,
  });
  const [dumpFileName, setDumpFileName] = useState<string>('');
  const { allowed: canEdit } = usePermission({
    permission: platformCustomViewEditPermission,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement>();
  const open = Boolean(anchorEl);
  const onClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (anchorEl) setAnchorEl(undefined);
    else setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(undefined);
  };

  const classes = useStyles();
  const customViewChildren = customViewIds?.map(customViewId => {
    const getCustomViewChildren = CustomViewPages?.get(customViewId);
    return getCustomViewChildren!();
  });
  const included = customViewChildren?.flatMap(child =>
    extractIncludeFromCode(child as ReactElement, '', ''),
  );
  const menu = customViewIds
    ?.map(id => {
      const getCustomViewChildren = CustomViewPages?.get(id);
      const child = getCustomViewChildren!();
      return {
        id: id,
        label: idToLabelMap[id] ?? id,
        action: () => {
          saveYaml(id, dumpJsxAsYaml(child));
        },
      };
    })
    .concat(
      included?.map((inc: any) => ({
        id: inc.id,
        label: idToLabelMap[inc.id] ?? inc.id,
        action: () => {
          setDumpFileName(inc.id);
          setDumpSegment({
            fn: CustomViewPages.get(inc.id, inc.typeId, inc.templateId),
          });
        },
      })),
    );

  if (!canEdit) return <></>;
  const subMenu = (
    <>
      <CustomViewSubMenu>
        <MenuList>
          <Paper style={{ borderRadius: 0, background: 'white' }}>
            {menu?.map((el, i) => (
              <CustomViewDownloadItem key={i} item={el} included={included} />
            ))}
          </Paper>
        </MenuList>
      </CustomViewSubMenu>
      <SegmentDownloader
        filename={dumpFileName}
        done={() => setDumpSegment({ fn: undefined })}
      >
        {dumpSegment.fn ? dumpSegment.fn() : undefined}
      </SegmentDownloader>
    </>
  );
  if (!withMenu) return subMenu;

  return (
    <Box className={classes.standardPositioned}>
      <Box onClick={onClick} onKeyDown={() => true}>
        <IconButton size="small">
          <MoreVert />
        </IconButton>
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {subMenu}
        </Box>
      </Popover>
    </Box>
  );
};
