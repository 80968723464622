import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';
import { Box } from '@material-ui/core';
import React from 'react';
import { CustomViewRegister, processComplexCondition } from '../CustomView';
import { WbFieldLabel } from '../WbFieldLabel';
import { WbTagsArray } from '../WbTag';

CustomViewRegister.register({
  id: 'tags',
  function: function TagView({
    value,
    filter,
    displayValue,
    mode,
    title,
  }: {
    value: any[];
    filter?: string | Record<string, any>;
    displayValue?: string;
    mode?: string;
    title?: string;
  }) {
    const tags = (value ?? [])
      .filter((tag: any) => {
        if (filter === undefined) return true;
        if (typeof filter === 'string') {
          const parsedCondition = parseNunjucks(filter, tag, true);
          return parsedCondition === 'true';
        }
        return processComplexCondition(tag, filter);
      })
      .map((tag: any) => {
        const tagDisplayValue = displayValue
          ? parseNunjucks(displayValue, tag, true) ?? ''
          : undefined;
        return typeof tag === 'string'
          ? { tagFQN: tag }
          : { ...tag, tagFQN: tagDisplayValue ?? tag.tagFQN };
      });

    switch (mode) {
      case 'table':
        return <WbTagsArray tags={tags} />;
      default:
    }

    return (
      <Box
        style={{
          marginTop: '10px',
          marginBottom: '10px',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
        }}
      >
        <WbFieldLabel label={title ?? 'Tags'} />
        <WbTagsArray tags={tags} />
      </Box>
    );
  },
});
