export const REMOVE_DOMAIN = `
mutation REMOVE_DOMAIN($domainId: String) {
  deletePartoOfDomainRelation: delete_marketplace_relations(where: { name: {_eq: "partOfDomain"}, instanceByTargetInstanceId: { external_id: { _eq: $domainId } } }) {
    affected_rows
  }
  deleteSubDomainOfRelation: delete_marketplace_relations(where: { name: { _eq: "subDomainOf" }, _or: [
    { instance: { external_id: {_eq: $domainId}} }
    { instanceByTargetInstanceId: { external_id: { _eq: $domainId } } }
  ] }) {
    affected_rows
  }

  deleteDomainInstance: delete_marketplace_instances(where: { external_id: { _eq: $domainId } }) {
    affected_rows
  }
}
`;

export const SEARCH_FOR_PARENT_DOMAIN = `
  query SEARCH_FOR_PARENT_DOMAIN($sourceInstanceExternalId: String) {
    domainData: marketplace_relations(where: { _and: [{instance: { external_id: { _eq: $sourceInstanceExternalId } }}, { name: { _eq: "subDomainOf" } }] }) {
      data: instanceByTargetInstanceId {
        name
        external_id
      }
    }
  }
`;
