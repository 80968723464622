import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const AiIcon = createSvgIcon(
  <>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.15952 1.8984C4.01579 1.58856 3.51124 1.58856 3.36752 1.8984C3.20433 2.25016 3.00758 2.60719 2.81084 2.8027C2.61409 2.99821 2.2548 3.19374 1.90083 3.35591C1.58904 3.49874 1.58905 4.00013 1.90084 4.14296C2.25482 4.30511 2.61409 4.50063 2.81084 4.69614C3.00758 4.89165 3.20433 5.24868 3.36752 5.60044C3.51124 5.91028 4.01579 5.91028 4.15952 5.60044C4.32269 5.24868 4.51945 4.89165 4.71619 4.69614C4.91555 4.49803 5.26626 4.29992 5.6067 4.13651C5.90946 3.99118 5.90946 3.5077 5.6067 3.36236C5.26627 3.19894 4.91555 3.00082 4.71619 2.8027C4.51945 2.60719 4.32269 2.25016 4.15952 1.8984Z"
        fill="currentColor"
      />
      <path
        d="M9.33582 6.02643C9.23707 5.76945 8.84509 5.76945 8.74633 6.02643C8.32873 7.11336 7.52587 9.00358 6.72302 9.79597C5.92016 10.5884 4.00499 11.3808 2.90373 11.7931C2.64337 11.8906 2.64337 12.2775 2.90375 12.3749C4.005 12.7871 5.92016 13.5794 6.72302 14.3718C7.52587 15.1642 8.32873 17.0545 8.74633 18.1414C8.84509 18.3983 9.23707 18.3983 9.33582 18.1414C9.75342 17.0545 10.5563 15.1642 11.3591 14.3718C12.1641 13.5775 13.9827 12.783 15.019 12.3717C15.272 12.2713 15.272 11.8966 15.019 11.7962C13.9828 11.3849 12.1641 10.5905 11.3591 9.79597C10.5563 9.00358 9.75342 7.11336 9.33582 6.02643Z"
        fill="currentColor"
      />
      <path
        d="M15.5801 1.87694C15.462 1.59571 15.0183 1.59571 14.9002 1.87694C14.6552 2.46127 14.3074 3.17935 13.9598 3.52355C13.6121 3.86775 12.8867 4.21197 12.2965 4.45465C12.0125 4.57147 12.0125 5.01083 12.2965 5.12763C12.8867 5.3703 13.6121 5.71449 13.9598 6.0587C14.3074 6.40289 14.6552 7.12097 14.9002 7.7053C15.0183 7.98653 15.462 7.98653 15.5801 7.7053C15.8252 7.12097 16.1729 6.40289 16.5205 6.0587C16.8703 5.71236 17.5668 5.36605 18.1267 5.12315C18.4026 5.00344 18.4026 4.57886 18.1267 4.45914C17.5668 4.21621 16.8703 3.86988 16.5205 3.52355C16.1729 3.17935 15.8252 2.46127 15.5801 1.87694Z"
        fill="currentColor"
      />
    </svg>
  </>,
  'AiIcon',
);
