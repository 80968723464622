import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';

export type WbExpandToggleProps = {
  expanded: boolean;
  onExpandChange: () => void;
  className?: string;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(
  theme => ({
    root: {},
    expandIcon: {
      transform: 'rotate(0deg)',
      '&$expanded': {
        transform: 'rotate(90deg)',
      },
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expanded: {},
    icon: {
      fontSize: theme.typography.body1.fontSize,
    },
  }),
  { name: 'WbExpandToggle' },
);

export const WbExpandToggle: React.FC<WbExpandToggleProps> = ({
  expanded,
  onExpandChange,
  className,
  style,
}) => {
  const classes = useStyles();
  return (
    <IconButton
      aria-label={expanded ? 'collapse' : 'expand'}
      className={clsx(
        className,
        classes.expandIcon,
        expanded && classes.expanded,
      )}
      size="small"
      style={style}
      onClick={onExpandChange}
    >
      <KeyboardArrowRightIcon className={classes.icon} />
    </IconButton>
  );
};
