import { FormValidation } from '@rjsf/core';
import { ApiHolder } from '@backstage/core-plugin-api';
import { RegexValidationRequest } from '../../../types';

export const isValidString = (value: unknown, regex: RegExp): boolean => {
  const res = typeof value === 'string' && regex.test(value);
  return res;
};

export const regExPickerValidation = async (
  value: string,
  fieldValidation: FormValidation,
  _context: { apiHolder: ApiHolder },
  { validation }: { validation: RegexValidationRequest },
  __formContext: any,
  fieldName?: any,
) => {
  if (
    value &&
    !isValidString(
      value ?? '',
      new RegExp(validation?.regularExpression, validation?.flags),
    )
  ) {
    // for field arrays, fieldName represents the index
    if (fieldName) {
      fieldValidation[fieldName].addError(
        `${validation?.errorMessage ?? 'Regular expression validation failed'}`,
      );
    } else {
      fieldValidation.addError(
        `${validation?.errorMessage ?? 'Regular expression validation failed'}`,
      );
    }
  }
};
