import {
  ComponentInstanceEntity,
  InstanceEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { anyCaseToCapitalCase } from '@agilelab/plugin-wb-platform-common';
import { CustomViewCtrls } from '@agilelab/plugin-wb-platform';

CustomViewCtrls.register({
  id: 'marketplace_system',
  function: ({
    dpData,
    componentsList,
    selectedComponents,
    consumableInterfaceTypeField,
    kindsLabels,
  }: {
    dpData: InstanceEntity;
    componentsList: ComponentInstanceEntity[] | undefined;
    selectedComponents: ComponentInstanceEntity[];
    consumableInterfaceTypeField: string;
    kindsLabels: Record<string, string>;
  }) => {
    const orderFn = (a: any, b: any) => {
      if (a.type < b.type) return -1;
      if (a.type > b.type) return 1;
      return 0;
    };
    const components = componentsList
      ?.map(component => ({
        ...component,
        selected: selectedComponents.some(s => s.id === component.id),
        access: !!component.acls?.length,
        type:
          typeof component.descriptor[consumableInterfaceTypeField] === 'string'
            ? anyCaseToCapitalCase(
                component.descriptor[consumableInterfaceTypeField],
              )
            : undefined,
      }))
      .sort(orderFn);
    const kinds = components
      ?.map(o => o.descriptor.kind.toLowerCase() || '')
      .filter((val, idx, list) => list.indexOf(val) === idx);
    const componentsByKind = kinds?.map(kind =>
      Object.assign(
        { kind, label: kindsLabels[kind] || kind },
        {
          components: components
            ?.filter(o => o.descriptor.kind.toLowerCase() === kind)
            .sort(orderFn),
        },
      ),
    );
    const data = {
      ...dpData.descriptor,
      _computedInfo: dpData,
      _selectedComponents: selectedComponents,
      _componentsByKind: componentsByKind,
      _components: components,
    } as Record<string, any>;
    if (!data.version) data.version = dpData.version;
    return data;
  },
});
