import React from 'react';
import {
  makeStyles,
  Theme,
  Typography,
  useTheme,
  Paper,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

type Props = {
  text?: string;
  title?: string;
};

export const NoDataPanel = ({ title, text }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper className={classes.paper}>
      <Typography component="p" style={{ color: theme.palette.grey[600] }}>
        {text || `No ${title || ''} metadata available`}
      </Typography>
    </Paper>
  );
};
