import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { UserHeader } from '@agilelab/plugin-wb-user-headers-common';
import { ConfigForm, ConfigInput } from '../ConfigForm';
import { HeaderInputSchema } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: '100%',
    margin: theme.spacing(2, 0),
  },
  formRow: {
    display: 'flex',
  },
  description: {
    margin: theme.spacing(0, 0, 2),
  },
}));

type HeaderInput = ConfigInput & {
  newHeader: boolean;
  version: number;
};

export const HeadersPicker = (props: {
  prefix?: string;
  initialUserHeaders: UserHeader[];
  isSaving: boolean;
  onHeadersSaved: (userHeaders: UserHeader[]) => Promise<{ success: boolean }>;
  onHeadersDeleted: (
    userHeadersToDelete: string[],
  ) => Promise<{ success: boolean }>;
}) => {
  const classes = useStyles();
  const [editMode, setEditMode] = useState<number>();
  const [headerForms, setHeaderForms] = useState<HeaderInput[]>(
    props.initialUserHeaders.map(header => ({
      key: header.name,
      value: header.value,
      isSensitive: header.isSensitive,
      newHeader: false,
      version: 1,
    })),
  );
  const removeHeaderForm = (index: number) => {
    setHeaderForms(headers => {
      const newHeaders = [...headers];
      newHeaders.splice(index, 1);
      return newHeaders;
    });
  };
  const updateHeaderForm = (
    index: number,
    oldValue: HeaderInput,
    newValue: ConfigInput,
  ) => {
    setHeaderForms(headers => {
      const newHeaders = [...headers];
      const updatedHeader = {
        ...newValue,
        newHeader: false,
        version: oldValue.version + 1,
      };
      newHeaders[index] = updatedHeader;
      return newHeaders;
    });
  };
  const addEmptyHeader = () => {
    setHeaderForms(headers => {
      const newHeaderForms = [
        ...headers,
        {
          key: '',
          value: '',
          isSensitive: false,
          newHeader: true,
          version: 1,
        },
      ];
      setEditMode(newHeaderForms.length - 1);
      return newHeaderForms;
    });
  };

  return (
    <Card>
      <CardHeader title="User headers" />
      <CardContent>
        <Typography className={classes.description}>
          HTTP headers to be included in each interaction with the provisioning
          module
        </Typography>
        {headerForms.length > 0 ? (
          headerForms.map((field, index) => (
            <ConfigForm
              key={field.key + field.version}
              active={editMode === index}
              editableKey
              keyPlaceholder="name"
              keyHelperText="Header name"
              valuePlaceholder="value"
              valueHelperText="Header value"
              sensitiveValuePlaceholder="sensitive value"
              sensitiveValueHelperText="Header sensitive value"
              deleteDialogContent={
                <span>
                  Are you sure you want to delete header{' '}
                  <strong>
                    {props.prefix ? `${props.prefix}-` : ''}
                    {field.key}
                  </strong>
                  ?
                </span>
              }
              deleteDialogTitle="Delete user header"
              actionsEnabled={editMode === undefined}
              isSaving={props.isSaving}
              initialValue={field}
              keyPrefix={props.prefix ? `${props.prefix}-` : undefined}
              validator={HeaderInputSchema}
              onStartEditMode={() => {
                setEditMode(index);
              }}
              onCancelEditMode={() => {
                setEditMode(undefined);
                // remove the form if it is a new header
                if (field.newHeader) removeHeaderForm(index);
              }}
              onDeleteAction={async () => {
                const { success } = await props.onHeadersDeleted([field.key]);
                if (success) removeHeaderForm(index);
              }}
              onSaveAction={async updatedValue => {
                updatedValue.key = updatedValue.key.toLowerCase();
                const hasHeaderNameChanged =
                  !field.newHeader &&
                  field.key.toLowerCase() !== updatedValue.key;
                let deleteOperationOK = true;
                if (hasHeaderNameChanged) {
                  // Delete the old header
                  const res = await props.onHeadersDeleted([field.key]);
                  deleteOperationOK = res.success;
                }
                // Save the new header
                if (deleteOperationOK) {
                  const { success } = await props.onHeadersSaved([
                    {
                      name: updatedValue.key,
                      value: updatedValue.value,
                      isSensitive: updatedValue.isSensitive,
                    },
                  ]);
                  if (success) {
                    updateHeaderForm(index, field, updatedValue);
                    setEditMode(undefined);
                  }
                }
              }}
              onValidate={updatedValue => {
                if (
                  headerForms
                    .filter((_v, idx) => idx !== index)
                    .map(f => f.key.toLowerCase())
                    .includes(updatedValue.key.toLowerCase())
                ) {
                  return [
                    {
                      fieldName: 'key',
                      error: 'Header name already used',
                    },
                  ];
                }
                return [];
              }}
            />
          ))
        ) : (
          <Chip label="No headers defined" />
        )}
      </CardContent>
      <CardActions
        disableSpacing
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          title="Add header"
          aria-label="add header"
          disabled={editMode !== undefined}
          variant="text"
          style={{ marginRight: 'auto' }}
          onClick={addEmptyHeader}
        >
          Add header +
        </Button>
        {props.isSaving ? <CircularProgress /> : <></>}
      </CardActions>
    </Card>
  );
};
