import React from 'react';
import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { KoFilledIcon, KoIcon } from './Icons';
import { useStyles } from './style';
import { IconLabelBox } from '../../IconLabelBox';

interface Props {
  filled?: boolean;
  variant?: 'reduced' | 'default';
  style?: React.CSSProperties;
}

export const FailedIcon: React.FC<Props> = ({
  filled,
  style,
  variant = 'default',
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Tooltip title="Failed. You need to solve the errors reported since they will prevent the deployment">
      <span>
        {/* eslint-disable-next-line no-nested-ternary */}
        {variant === 'reduced' ? (
          filled ? (
            <KoFilledIcon
              htmlColor={theme.palette.error.main}
              className={classes.icon}
              style={style}
            />
          ) : (
            <KoIcon
              htmlColor={theme.palette.error.main}
              className={classes.icon}
              style={style}
            />
          )
        ) : (
          <IconLabelBox
            label="Failure"
            backgroundColor={theme.palette.error.main}
            icon={
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.black,
                  fontWeight: '700',
                }}
              >
                KO
              </Typography>
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
