import { deconstructVersion } from '@agilelab/plugin-wb-builder-common';

export function compareVersions(
  releaseVersion: string | undefined,
  deployedVersion: string | undefined,
) {
  if (!releaseVersion || !deployedVersion) return false;

  const a = deconstructVersion(releaseVersion);
  const b = deconstructVersion(deployedVersion);

  return `${a.major}.${a.minor || 0}` === `${b.major}.${b.minor || 0}`;
}
