import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React from 'react';
import PresetStepper from './PresetStepper';

export function PracticeShaperPreset({
  importPageRef,
  graphPageRef,
}: {
  importPageRef: string;
  graphPageRef: string;
}) {
  const configApi = useApi(configApiRef);

  const repoUrl = configApi.getOptionalString('practiceShaper.preset.repoUrl');

  return (
    repoUrl && (
      <PresetStepper
        repoUrl={repoUrl}
        importPageRef={importPageRef}
        graphPageRef={graphPageRef}
      />
    )
  );
}
