import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { TableRow, TableCell, Typography } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { TestStatus } from '@agilelab/plugin-wb-platform';

interface Props {
  startDate?: string;
}

export const TestTableLoadingRow: React.FC<Props> = ({
  startDate = new Date().toISOString(),
}) => {
  return (
    <TableRow>
      <TableCell width="5%">
        <TestStatus status={DagStatus.RUNNING} variant="reduced" />
      </TableCell>
      <TableCell width="40%">
        <Typography>
          Started at: {format(parseISO(startDate), 'yyyy/MM/dd HH:mm:ss')}
        </Typography>
      </TableCell>
      <TableCell colSpan={2} width="45%">
        <Typography>Validation in progress</Typography>
      </TableCell>
    </TableRow>
  );
};
