import {
  AddRolesSubjectsParams,
  DeleteRolesSubjectsParams,
  GetRolesParams,
  GetRolesSubjectsParams,
  RbacApi,
  RoleEntity,
  RoleSubjectEntity,
} from '@agilelab/plugin-wb-rbac-common';
import { ResponseError } from '@backstage/errors';
import { DiscoveryApi } from '@backstage/plugin-permission-common';
import fetch from 'cross-fetch';

type SendRequestOptions = {
  path: string;
  method: 'POST' | 'GET' | 'DELETE';
  body?: BodyInit | null | undefined;
  token?: string;
};

export class RbacClient implements RbacApi {
  constructor(private readonly discoveryApi: DiscoveryApi) {}

  private composeQueryString(queryObject?: any): URLSearchParams {
    const queryString = new URLSearchParams();

    if (!queryObject) {
      return queryString;
    }
    Object.keys(queryObject).forEach(key => {
      if (queryObject[key]) {
        queryString.append(key, queryObject[key] as string);
      }
    });

    return queryString;
  }

  private async sendRequest<T>({
    path,
    method,
    token,
    body,
  }: SendRequestOptions): Promise<T> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('rbac')}/`;
    const url = new URL(path, baseUrl);

    const response = await fetch(url.toString(), {
      method: method,
      headers: token
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: body,
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json() as Promise<T>;
  }

  async addRolesSubjects({
    rolesSubjects,
    options,
  }: AddRolesSubjectsParams): Promise<RoleSubjectEntity[]> {
    const addResponse = await this.sendRequest<{
      rolesSubjects: RoleSubjectEntity[];
    }>({
      path: `roles-subjects`,
      method: 'POST',
      body: JSON.stringify({ rolesSubjects, options }),
      token: options?.token,
    });

    return addResponse.rolesSubjects;
  }

  async deleteRolesSubjects({
    filters,
    options,
  }: DeleteRolesSubjectsParams): Promise<RoleSubjectEntity[]> {
    const queryString = this.composeQueryString(filters);

    const deleteResponse = await this.sendRequest<{
      rolesSubjects: RoleSubjectEntity[];
    }>({
      path: `roles-subjects?${queryString}`,
      method: 'DELETE',
      token: options?.token,
    });

    return deleteResponse.rolesSubjects;
  }

  async getRolesSubjects({
    filters,
    options,
  }: GetRolesSubjectsParams): Promise<RoleSubjectEntity[]> {
    const queryString = this.composeQueryString(filters);

    const getResponse = await this.sendRequest<{
      rolesSubjects: RoleSubjectEntity[];
    }>({
      path: `roles-subjects?${queryString}`,
      method: 'GET',
      token: options?.token,
    });

    return getResponse.rolesSubjects;
  }

  async getRoles({
    searchKeyword,
    options,
  }: GetRolesParams): Promise<RoleEntity[]> {
    const queryString = new URLSearchParams();
    if (searchKeyword) {
      queryString.append('searchKeyword', searchKeyword);
    }

    if (options && options.limit && options.offset) {
      queryString.append('limit', String(options.limit));
      queryString.append('offset', String(options.offset));
    }

    if (options && options.visibility) {
      queryString.append('visibility', options.visibility);
    }

    const { roles } = await this.sendRequest<{ roles: RoleEntity[] }>({
      path: `roles?${queryString}`,
      method: 'GET',
      token: options?.token,
    });

    return roles;
  }
}
