import {
  addHlines,
  CustomViewComponent,
  CustomViewPages,
  getCustomViewInfoFromObject,
  useCustomViewInfoFromConfig,
  useDataPath,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React, { ReactElement } from 'react';

CustomViewPages.register({
  id: 'marketplace_subcomponent_drawer',
  function: function Entity() {
    const configApi = useApi(configApiRef);
    const subcomponent = useDataPath('');
    const dataSharingAgreementFieldName =
      configApi.getOptionalString(
        'mesh.marketplace.ui.subcomponentDrawer.dataSharingAgreementField',
      ) ?? 'dataSharingAgreement';
    const dataSharing = subcomponent?.[dataSharingAgreementFieldName];
    const descriptorInfo: ReactElement[] = getCustomViewInfoFromObject(
      subcomponent || {},
      '',
      {
        description: { colSpan: '2' },
        _exclude:
          configApi.getOptionalStringArray(
            'mesh.marketplace.ui.subcomponentDrawer.generalInfo.excludeDescriptorPaths',
          ) ?? [],
      },
    );

    const sideInfo: ReactElement[] = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.subcomponentDrawer.sideInfo',
    );

    const additionalGeneralInfo: ReactElement[] = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.subcomponentDrawer.additionalGeneralInfo',
      { returnOnlyRawInfo: true },
    );

    const gridInfo: ReactElement[] = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.subcomponentDrawer.gridInfo',
      { returnOnlyRawInfo: true },
    );

    const generalInfo = addHlines(
      descriptorInfo.concat(gridInfo, additionalGeneralInfo),
      4,
    );

    return (
      <>
        <CustomViewComponent type="card" title="General Information">
          <CustomViewComponent type="hbox">
            <CustomViewComponent type="container" size={3}>
              {generalInfo}
            </CustomViewComponent>
            {sideInfo.length > 0 && (
              <CustomViewComponent type="container" borderLeft>
                {sideInfo}
              </CustomViewComponent>
            )}
          </CustomViewComponent>
          <CustomViewComponent
            showWhenExists="tags"
            showWhen={{ value: '{{ tags.length }}', notEquals: 0 }}
            label="Tags"
            type="tags"
            path="tags"
          />
        </CustomViewComponent>
        <CustomViewComponent type="vspace" />
        <CustomViewComponent type="marketplace_tech_card" />
        <CustomViewComponent type="vspace" />
        <CustomViewComponent
          type="card"
          title="Data Contract"
          showWhenExists="dataContract"
        >
          <CustomViewComponent
            type="sub_card"
            title="SLA"
            showWhenExists="dataContract.SLA"
          >
            <CustomViewComponent type="container">
              <CustomViewComponent type="new_root" path="dataContract.SLA">
                <CustomViewComponent
                  label="upTime"
                  type="string"
                  path="upTime"
                />
                <CustomViewComponent
                  label="timeliness"
                  type="string"
                  path="timeliness"
                />
                <CustomViewComponent
                  label="interval of change"
                  type="string"
                  path="intervalOfChange"
                />
              </CustomViewComponent>
            </CustomViewComponent>
            <CustomViewComponent
              type="hline"
              showWhenExists="dataContract.termsAndConditions"
            />
            <CustomViewComponent
              label="Terms and Conditions"
              type="string"
              path="dataContract.termsAndConditions"
              showWhenExists="dataContract.termsAndConditions"
              colSpan={4}
            />
          </CustomViewComponent>
          <CustomViewComponent type="vspace" />
          <CustomViewComponent type="schema_list" path="dataContract.schema">
            <CustomViewComponent type="grid_sequence" path="">
              <CustomViewComponent type="card" title="{{ name }}">
                <CustomViewComponent
                  type="table"
                  path="columns"
                  treeview="children"
                  hideEmptyColumns
                >
                  <CustomViewComponent
                    type="string"
                    path="dataType"
                    width="15%"
                    label="Type"
                  />
                  <CustomViewComponent
                    type="string"
                    path="name"
                    width="30%"
                    label="Name"
                  />
                  <CustomViewComponent
                    type="string"
                    path="description"
                    width="40%"
                    label="Description"
                  />
                  <CustomViewComponent
                    type="tags"
                    path="tags"
                    width="10%"
                    label="Tags"
                  />
                  <CustomViewComponent
                    type="row_link"
                    label="Semantic Link"
                    path="_semanticlink"
                    align="right"
                    width="5%"
                    click="showSemanticLink"
                  />
                </CustomViewComponent>
              </CustomViewComponent>
              <CustomViewComponent type="vspace" />
            </CustomViewComponent>
          </CustomViewComponent>
        </CustomViewComponent>
        <CustomViewComponent type="vspace" />
        <CustomViewComponent
          showWhenExists="dataSharingAgreement"
          type="card"
          title="Data Sharing Agreement"
        >
          <CustomViewComponent
            type="new_root"
            path={dataSharingAgreementFieldName}
          >
            <CustomViewComponent type="container">
              {getCustomViewInfoFromObject(
                dataSharing,
                '',
                { _default: { colSpan: '2' }, hlineEveryNElements: 4 },
                false,
              )}
            </CustomViewComponent>
          </CustomViewComponent>
        </CustomViewComponent>
        <CustomViewComponent type="data_preview" title="Data Preview" />
      </>
    );
  },
});
