import { createTopic } from '../lib';
import { z } from 'zod';

const notificationCenterNavigatedEventSchema = z.object({
  path: z.string(),
});

export type NotificationCenterNavigatedEvent = z.infer<
  typeof notificationCenterNavigatedEventSchema
>;

export const notificationCenterNavigatedTopic =
  createTopic<NotificationCenterNavigatedEvent>(
    {
      namespace: 'settings',
      name: 'notification-center-navigated',
      description: 'Notification center has been navigated',
    },
    notificationCenterNavigatedEventSchema,
  );
