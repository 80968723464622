import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const KoBugIcon = createSvgIcon(
  <>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 15.375H11.25V12.625H16.75V15.375ZM22.25 14.0308C19.3895 14.2668 16.9082 15.8405 15.4334 18.125H11.25V20.875H14.2523C14.0874 21.5563 14 22.268 14 23C14 24.7012 14.472 26.2921 15.2921 27.649C14.8714 27.7155 14.4398 27.75 14 27.75C10.9475 27.75 8.29375 26.0862 6.86375 23.625H3V20.875H5.87375C5.805 20.4212 5.75 19.9675 5.75 19.5V18.125H3V15.375H5.75V14C5.75 13.5325 5.805 13.0787 5.87375 12.625H3V9.875H6.86375C7.4825 8.8025 8.335 7.88125 9.3525 7.18L7.125 4.93875L9.06375 3L12.0613 5.98375C13.3398 5.67358 14.674 5.67358 15.9525 5.98375L18.9363 3L20.875 4.93875L18.6337 7.18C19.6605 7.87805 20.5161 8.79939 21.1363 9.875H25V12.625H22.1262C22.195 13.0787 22.25 13.5325 22.25 14V14.0308ZM23 30C26.866 30 30 26.866 30 23C30 19.134 26.866 16 23 16C19.134 16 16 19.134 16 23C16 26.866 19.134 30 23 30Z"
        fill="currentColor"
      />
      <path
        d="M27 20.455L25.545 19L23 21.545L20.455 19L19 20.455L21.545 23L19 25.545L20.455 27L23 24.455L25.545 27L27 25.545L24.455 23L27 20.455Z"
        fill="white"
      />
    </svg>
  </>,
  'KoBugIcon',
);
