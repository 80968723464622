/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { Box, LinearProgress, MenuItem } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import yaml from 'yaml';
import {
  WbCard,
  WbCardContent,
  WbSelect,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { FieldExtensionOptions } from '../../extensions';
import { LayoutOptions } from '../../layouts';
import { TemplateEditorForm } from './TemplateEditorForm';
import { TemplateEditorTextArea } from './TemplateEditorTextArea';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { Content } from '@backstage/core-components';

const EXAMPLE_TEMPLATE_PARAMS_YAML = `# Edit the template parameters below to see how they will render in the scaffolder form UI
parameters:
  - title: Fill in some steps
    required:
      - name
    properties:
      name:
        title: Name
        type: string
        description: Unique name of the component
      owner:
        title: Owner
        type: string
        description: Owner of the component
        ui:field: OwnerPicker
        ui:options:
          allowedKinds:
            - Group
  - title: Choose a location
    required:
      - repoUrl
    properties:
      repoUrl:
        title: Repository Location
        type: string
        ui:field: RepoUrlPicker
        ui:options:
          allowedHosts:
            - github.com
steps:
  - id: fetch-base
    name: Fetch Base
    action: fetch:template
    input:
      url: ./template
      values:
        name: \${{parameters.name}}
`;

type TemplateOption = {
  label: string;
  value: Entity;
};

export const TemplateFormPreviewer = ({
  defaultPreviewTemplate = EXAMPLE_TEMPLATE_PARAMS_YAML,
  customFieldExtensions = [],
  layouts = [],
}: {
  defaultPreviewTemplate?: string;
  customFieldExtensions?: FieldExtensionOptions<any, any>[];
  onClose?: () => void;
  layouts?: LayoutOptions[];
}) => {
  const alertApi = useApi(customAlertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [errorText, setErrorText] = useState<string>();
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const [templateYaml, setTemplateYaml] = useState(defaultPreviewTemplate);
  const [formState, setFormState] = useState({});

  const { loading } = useAsync(
    () =>
      catalogApi
        .getEntities({
          filter: { kind: 'template' },
          fields: [
            'kind',
            'metadata.namespace',
            'metadata.name',
            'metadata.title',
            'spec.parameters',
            'spec.steps',
            'spec.output',
          ],
        })
        .then(({ items }) =>
          setTemplateOptions(
            items.map(template => ({
              label:
                template.metadata.title ??
                humanizeEntityRef(template, { defaultKind: 'template' }),
              value: template,
            })),
          ),
        )
        .catch(e =>
          alertApi.post({
            error: new CustomError(
              'Error while loading the existing templates',
              e.message,
            ),
            severity: 'error',
          }),
        ),
    [catalogApi],
  );

  const handleSelectChange = useCallback(
    (selected: any) => {
      setSelectedTemplate(selected);
      setTemplateYaml(yaml.stringify(selected.spec));
    },
    [setTemplateYaml],
  );

  return (
    <>
      {loading && <LinearProgress />}

      <Content>
        <WbCard title="Editor template form" cardStyle={{ height: '100%' }}>
          <WbCardContent
            style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}
          >
            <WbSelect
              id="load-existing-template"
              onChange={e => handleSelectChange(e.target.value)}
              value={selectedTemplate}
              label="Load Existing Template"
              style={{ width: '400px' }}
            >
              {templateOptions.map((option, idx) => (
                <MenuItem key={idx} value={option.value as any}>
                  {option.label}
                </MenuItem>
              ))}
            </WbSelect>

            <Box display="flex" height="100%" style={{ gap: '24px' }}>
              <TemplateEditorTextArea
                content={templateYaml}
                onUpdate={content => {
                  setTemplateYaml(content!);
                }}
                errorText={errorText}
                containerStyle={{ height: '100%' }}
              />
              <TemplateEditorForm
                content={templateYaml}
                contentIsSpec
                fieldExtensions={customFieldExtensions}
                data={formState}
                onUpdate={setFormState}
                setErrorText={setErrorText}
                layouts={layouts}
              />
            </Box>
          </WbCardContent>
        </WbCard>
      </Content>
    </>
  );
};
