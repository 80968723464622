import { Box, makeStyles } from '@material-ui/core';
import React, { ReactNode } from 'react';

type WbHeaderActionsProps = {
  children: ReactNode;
  separator?: ReactNode;
  containerStyle?: React.CSSProperties;
};

type Items = ReturnType<typeof React['Children']['toArray']>;

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
    },
    separator: {
      height: '20px',
      width: '1px',
      backgroundColor: theme.palette.softened.secondary,
    },
  }),
  { name: 'WbHeaderActions' },
);

const Separator = () => {
  const classes = useStyles();
  return <Box className={classes.separator} />;
};

function insertSeparators(items: Items, separator: ReactNode) {
  return items.reduce<Items>((acc, current, index) => {
    let res = acc;
    if (index < items.length - 1) {
      res = acc.concat(
        current,
        <React.Fragment key={`separator${index}`}>{separator}</React.Fragment>,
      );
    } else {
      res.push(current);
    }

    return res;
  }, []);
}

export const WbHeaderActions = ({
  children,
  separator = <Separator />,
  containerStyle,
}: WbHeaderActionsProps) => {
  const classes = useStyles();

  const elements = React.Children.toArray(children);
  const separatedElements = insertSeparators(elements, separator);
  return (
    <Box className={classes.root} style={containerStyle}>
      {separatedElements}
    </Box>
  );
};
