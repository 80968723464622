import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import {
  createApiRef,
  createComponentExtension,
  createPlugin,
} from '@backstage/core-plugin-api';
import { rootRouteRef } from './routes';

export const practiceShaperPlugin = createPlugin({
  id: 'wb-practice-shaper',
  routes: {
    root: rootRouteRef,
  },
});

/**
 * The API reference for the PracticeShaperApi.
 * @public
 */
export const practiceShaperApiRef = createApiRef<PracticeShaperApi>({
  id: 'plugin.practice-shaper',
});

/**
 * @public
 */
export const PracticeShaperSettingsPage = practiceShaperPlugin.provide(
  createComponentExtension({
    name: 'PracticeShaperSettingsPage',
    component: {
      lazy: () =>
        import('./components/SettingsPage/SettingsPage').then(
          m => m.PracticeShaperSettingsPage,
        ),
    },
  }),
);
