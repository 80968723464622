import { createApiRef, createPlugin } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { EventsApi } from '@agilelab/plugin-wb-events-common';

/**
 * The API reference for the EventsApi.
 * @public
 */
export const eventsApiRef = createApiRef<EventsApi>({
  id: 'plugin.events',
});

export const wbEventsPlugin = createPlugin({
  id: 'wb-events',
  routes: {
    root: rootRouteRef,
  },
});
