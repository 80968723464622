import { Content, Header, Page } from '@backstage/core-components';
import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { CustomMicrofrontendComponent } from './CustomMicrofrontendComponent';
import { CustomMicrofrontendOptions } from './CustomMicrofrontendConfiguration';

export const CustomMicrofrontendPage = (props: CustomMicrofrontendOptions) => {
  const theme = useTheme();
  return (
    <Page themeId="home">
      <Header title={props.title} />
      <Content>
        <Box
          display="flex"
          flexDirection="column"
          style={{ gap: theme.spacing(2) }}
        >
          <CustomMicrofrontendComponent key={props.identifier} {...props} />
        </Box>
      </Content>
    </Page>
  );
};
