import React from 'react';

export const SystemIcon = () => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.2504 7.5L4.5 24L14.2504 40.5H33.7496L43.5 24L33.7496 7.5H14.2504Z"
      fill="#7C4DFF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98465 24L15.9622 37.5H32.0378L40.0154 24L32.0378 10.5H15.9622L7.98465 24ZM14.2504 7.5L4.5 24L14.2504 40.5H33.7496L43.5 24L33.7496 7.5H14.2504Z"
      fill="#4E6980"
    />
  </svg>
);

export const ComponentIcon = () => (
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 4L44 24L24 44L4 24L24 4ZM7.9245 24L24 40.0755L40.0755 24L24 7.9245L7.9245 24Z"
      fill="#4E6980"
    />
  </svg>
);
