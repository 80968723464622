import { parseUrn } from '@agilelab/plugin-wb-builder-common';
import {
  Column,
  encodeBase64PageCursor,
  GenericEntityType,
  GenericProviderType,
  getLabelAndValue,
  KindEnum,
  NotFoundException,
  Pagination,
  Provider,
  selectTemplateEntity,
  TextFieldValue,
} from '@agilelab/plugin-wb-platform';
import { Environment } from '@agilelab/plugin-wb-platform-common';
import { READS_FROM } from '@agilelab/plugin-wb-practice-shaper-common';
import {
  WitboostSearchApi,
  witboostSearchApiRef,
} from '@agilelab/plugin-wb-search';
import {
  AtomicFilter,
  CollatorType,
  ComplexFilter,
  FilterOperator,
  JsonObjectSearchFilterVisitor,
  PROVISIONING_CONSUMABLE_DOMAIN,
  PROVISIONING_CONSUMABLE_ENVIRONMENT,
  PROVISIONING_CONSUMABLE_KIND,
  PROVISIONING_CONSUMABLE_PARENT,
  PROVISIONING_CONSUMABLE_URN,
  QueryOperator,
} from '@agilelab/plugin-wb-search-common';
import { parseEntityRef } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { TemplateEntity } from '../../EntitySearchPicker';
import { contextProviderFactory } from '../entityProvider.factory';
import {
  ConsumableFiltersSection,
  ConsumablesFilters,
} from './ConsumablesFilters';

export type ConsumableTypeEntity = {} & GenericEntityType;

interface ConsumableContextType
  extends GenericProviderType<ConsumableTypeEntity[], ConsumablesFilters> {
  availableEnvs?: {
    name: string;
    priority: number;
  }[];
}

const [useConsumableContext, ConsumableContextProvider] =
  contextProviderFactory<ConsumableContextType>();

export default useConsumableContext;

export interface ConsumableProviderProps {
  templateKind: TemplateEntity | undefined;
  children: React.ReactNode;
  parentTemplateRef?: string;
  environments: Environment[];
}

const defaultColumns = new Map<string, string>([
  ['name', '{{ name }}'],
  ['description', '{{ description }}'],
]);

const toConsumableTypeEntity = (
  consumable: Record<string, any>,
): ConsumableTypeEntity => {
  return {
    ...consumable,
    __metadata: { kind: KindEnum.consumable, name: consumable.name },
  };
};

export const ConsumablesProvider: React.FC<ConsumableProviderProps> = props => {
  const { children, templateKind, parentTemplateRef, environments } = props;
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [prevCursor, setPrevCursor] = useState<string | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    currentPage: 0,
    cursor: encodeBase64PageCursor(0),
  });
  const searchApi = useApi(witboostSearchApiRef);
  const [count, _setCount] = useState<number>(-1);
  const [filters, setFilters] = useState<ConsumablesFilters>({});
  const [readableResourceTypes, setReadableResourceTypes] = useState<
    string[] | undefined
  >([]);
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);
  const preFilters = templateKind?.filter;
  const [availableEnvsState, setAvailableEnvsState] = useState<any>();

  useEffect(() => {
    setAvailableEnvsState(environments);
  }, [environments]);

  useEffect(() => {
    if (
      !availableEnvsState?.loading &&
      availableEnvsState?.data?.marketplace_environments.length
    ) {
      setFilters({
        environment: availableEnvsState?.data.marketplace_environments[0],
      });
    }
  }, [availableEnvsState]);

  const getReadableResourceTypes = useCallback(
    async (componentTypeRef: string) => {
      const { token } = await identityApi.getCredentials();
      const parsedEntityRef = parseEntityRef(componentTypeRef);
      if (parsedEntityRef.kind.toLowerCase() !== 'componenttype') {
        return [];
      }
      const componentType = await catalogApi.getEntityByRef(parsedEntityRef, {
        token,
      });
      const readsFromRelations =
        componentType?.relations?.filter(rel => rel.type === READS_FROM) ?? [];
      const readableTypes = readsFromRelations.map(rel => rel.targetRef);
      return (
        await catalogApi.getEntitiesByRefs(
          { entityRefs: readableTypes },
          { token },
        )
      ).items
        .filter(item => item !== null)
        .map(item => item?.spec?.resourceTypeId) as string[];
    },
    [catalogApi, identityApi],
  );

  const fetchResourceTypesCompatibleWithCurrentTemplate =
    useCallback(async () => {
      const { token } = await identityApi.getCredentials();
      if (!parentTemplateRef) return [];

      const parent = await catalogApi.getEntityByRef(parentTemplateRef, {
        token,
      });

      if (!parent || !parent.spec?.generates) return [];

      return getReadableResourceTypes(parent.spec.generates as string);
    }, [catalogApi, getReadableResourceTypes, identityApi, parentTemplateRef]);

  const fetchResourceTypesFromCanBeReadFrom = useCallback(
    async (readableEntityRef: string) => {
      return getReadableResourceTypes(readableEntityRef);
    },
    [getReadableResourceTypes],
  );

  useEffect(() => {
    const getProvisioningComponents = async () => {
      let resourceTypes: string[] | undefined = undefined;

      const kind = preFilters?.practiceShaper?.kind;

      if (kind === 'canBeReadFromCurrentInstance') {
        resourceTypes =
          (await fetchResourceTypesCompatibleWithCurrentTemplate()) ?? [];
      }

      if (
        kind === 'canBeReadFrom' &&
        preFilters?.practiceShaper.canBeReadFrom
      ) {
        resourceTypes =
          (await fetchResourceTypesFromCanBeReadFrom(
            preFilters?.practiceShaper.canBeReadFrom,
          )) ?? [];
      }

      setReadableResourceTypes(resourceTypes);
    };

    getProvisioningComponents();
  }, [
    fetchResourceTypesCompatibleWithCurrentTemplate,
    fetchResourceTypesFromCanBeReadFrom,
    preFilters?.practiceShaper.canBeReadFrom,
    preFilters?.practiceShaper?.kind,
  ]);

  const consumableState = useAsync(async () => {
    const adaptedFilter = [];

    if (readableResourceTypes?.length === 0) {
      return undefined;
    }

    if (filters.domain)
      adaptedFilter.push(
        new AtomicFilter(
          PROVISIONING_CONSUMABLE_DOMAIN.field,
          FilterOperator.IN,
          filters.domain.map(d => d.name),
        ),
      );

    if (filters.environment)
      adaptedFilter.push(
        new AtomicFilter(
          PROVISIONING_CONSUMABLE_ENVIRONMENT.field,
          FilterOperator.IN,
          [filters.environment.name],
        ),
      );

    if (filters.system)
      adaptedFilter.push(
        new AtomicFilter(
          PROVISIONING_CONSUMABLE_PARENT.field,
          FilterOperator.IN,
          filters.system.map(s => s.urn),
        ),
      );

    if (readableResourceTypes) {
      adaptedFilter.push(
        new AtomicFilter(
          PROVISIONING_CONSUMABLE_KIND.field,
          FilterOperator.IN,
          readableResourceTypes,
        ),
      );
    }

    const jsonSearchFilter = new ComplexFilter(QueryOperator.AND, [
      ...adaptedFilter,
    ]).accept(new JsonObjectSearchFilterVisitor());

    const response = await searchApi.query({
      term: filters.search ?? '',
      filters: jsonSearchFilter,
      pageCursor: pagination.cursor,
      pageLimit: pagination.limit,
      types: [CollatorType.PROVISIONING_CONSUMABLES],
      orderBy: { field: 'rank', order: 'desc' },
    });

    setNextCursor(response.nextPageCursor);
    setPrevCursor(response.previousPageCursor);
    setPagination(newPagination => ({
      ...newPagination,
      countlessOptions: {
        hasNextPage: () => response.nextPageCursor !== undefined,
        isFirstPage: () => response.previousPageCursor === undefined,
      },
    }));
    return response.results.map((r: any) => toConsumableTypeEntity(r.document));
  }, [filters, pagination.cursor, pagination.limit, readableResourceTypes]);

  const columns: Column[] = useMemo(() => {
    if (templateKind?.columns) {
      return templateKind?.columns.map(c => ({
        name: c.name,
        path: 'path' in c ? c.path : defaultColumns.get(c.value)!,
      }));
    }
    return Array.from(defaultColumns, ([name, value]) => ({
      name,
      path: value,
    }));
  }, [templateKind?.columns]);

  return (
    <ConsumableContextProvider
      value={{
        entitiesState: consumableState,
        columns,
        filters,
        changeFilters: <K extends keyof ConsumablesFilters>(
          key: K,
          filterValue: ConsumablesFilters[K],
        ) => {
          setPagination(p => ({ ...p, currentPage: 0, cursor: undefined }));
          setFilters(f => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setFilters({
            environment: availableEnvsState?.data?.marketplace_environments[0]!,
          });
        },
        templateKind,
        pagination,
        setPagination,
        count,
        nextCursor,
        prevCursor,
        availableEnvs: availableEnvsState?.data?.marketplace_environments,
      }}
    >
      {children}
    </ConsumableContextProvider>
  );
};

export class ConsumablesHandlerProvider
  implements Provider<ConsumableTypeEntity[], ConsumablesFilters>
{
  private readonly searchApi: WitboostSearchApi;
  private readonly availableKinds: TemplateEntity[];
  private readonly environments: Environment[];

  constructor(
    searchApi: WitboostSearchApi,
    availableKinds: TemplateEntity[],
    environments: Environment[],
  ) {
    this.searchApi = searchApi;
    this.availableKinds = availableKinds;
    this.environments = environments;
  }

  useContext = useConsumableContext;

  renderContextProvider(
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
    parentTemplateRef?: string,
  ) {
    return (
      <ConsumablesProvider
        templateKind={templateKind}
        parentTemplateRef={parentTemplateRef}
        environments={this.environments}
      >
        {children}
      </ConsumablesProvider>
    );
  }

  filtersSection = ConsumableFiltersSection;

  async resolve(urn: string): Promise<TextFieldValue | undefined> {
    const availableEnvs = this.environments;
    try {
      const parsedUrn = parseUrn(urn);
      if (parsedUrn.kind === 'cmp') {
        const jsonSearchFilter = new ComplexFilter(QueryOperator.AND, [
          new AtomicFilter(
            PROVISIONING_CONSUMABLE_URN.field,
            FilterOperator.EQ,
            urn,
          ),
        ]).accept(new JsonObjectSearchFilterVisitor());

        try {
          const data = await this.searchApi.query({
            term: '',
            filters: jsonSearchFilter,
            types: [CollatorType.PROVISIONING_CONSUMABLES],
            orderBy: { field: 'rank', order: 'desc' },
          });
          let consumable: any = undefined;
          for (const env of availableEnvs) {
            const possibleConsumable = data.results?.find(res => {
              return (res.document as any).environment === env.name;
            });
            if (possibleConsumable) {
              consumable = possibleConsumable;
              break;
            }
          }

          if (!consumable) {
            throw new NotFoundException(
              `Cannot find a Consumable entity with ref: ${urn}`,
            );
          }
          return getLabelAndValue(
            consumable.document,
            selectTemplateEntity(this.availableKinds, 'Consumable'),
          );
        } catch (error) {
          throw new Error(`Error while resolve entity with ref: ${urn}`);
        }
      }
    } catch (e) {
      return undefined;
    }
    return undefined;
  }
}
