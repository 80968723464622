import { TableContentLoader } from '@agilelab/plugin-wb-platform';
import React from 'react';

export function TestTableLoader({ rows = 25 }: { rows?: number }) {
  return (
    <TableContentLoader
      rows={rows}
      tableCells={[
        {
          cellProps: {
            size: 'small',
            style: {
              width: '84px',
            },
          },
          skeletonProps: {
            animation: 'wave',
            variant: 'circle',
            width: 32,
            height: 32,
          },
        },
        {
          cellProps: {
            size: 'small',
          },
          skeletonProps: {
            animation: 'wave',
            variant: 'text',
          },
        },
        {
          cellProps: {
            size: 'small',
          },
          skeletonProps: {
            animation: 'wave',
            variant: 'text',
          },
        },
      ]}
    />
  );
}
