import {
  Entity,
  entityEnvelopeSchemaValidator,
} from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/WitboostEntity.v1alpha1.schema.json';

export interface WitboostEntity extends Entity {
  spec: {
    mesh: {
      name: string;
    } & Record<string, any>;
  } & Record<string, any>;
}

export const applyWitboostEntity =
  entityEnvelopeSchemaValidator<WitboostEntity>(schema);

export const witboostEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
