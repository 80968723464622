/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import { WbMarkdownHelperText } from '@agilelab/plugin-wb-platform';
import { FieldProps } from '@rjsf/core';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  markdown: {
    '& p': {
      ...theme.typography.body1,
    },
    '& li': {
      ...theme.typography.body1,
    },
  },
}));

export const DescriptionField = ({ description, className }: FieldProps) => {
  const classes = useStyles();
  return (
    <WbMarkdownHelperText
      helperText={description}
      className={clsx(className, classes.markdown)}
    />
  );
};
