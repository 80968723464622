/**
 * This class handles a very simple LDAP login.
 * The session is stored locally, and it is refreshed each time the internal Backstage token expires.
 * To refresh the session we pass to the backend provider the needed information through HTTP headers.
 * Scopes are currently not handled; they are defined here since the classes where we took the code
 * handled also toke scopes.
 * The connector and session manager used are enhanced from the Backstage ones.
 */

import { AuthApiCreateOptions } from '@backstage/core-app-api';
import {
  ApiRef,
  BackstageIdentityApi,
  BackstageIdentityResponse,
  createApiRef,
  ProfileInfo,
  ProfileInfoApi,
  SessionApi,
  SessionState,
} from '@backstage/core-plugin-api';
import { Observable } from '@backstage/types';
import jwtDecoder from 'jwt-decode';
import {
  GetSessionOptions,
  LdapDefaultAuthConnector,
  LdapRefreshingAuthSessionManager,
  SessionManager,
} from '../../lib';

/**
 * OAuth2 create options.
 * @public
 */
export type LdapAuthCreateOptions = AuthApiCreateOptions;

export type LdapAuthResponse = {
  profile: ProfileInfo;
  backstageIdentity: BackstageIdentityResponse;
};

export type LdapAuthSession = {
  profile: ProfileInfo;
  backstageIdentity: BackstageIdentityResponse;
};

const DEFAULT_PROVIDER = {
  id: 'simple_ldap',
  title: 'LDAP',
  icon: () => null,
};

/**
 * Implements a generic LDAP flow for auth.
 *
 * @public
 */
export default class LdapAuth
  implements ProfileInfoApi, BackstageIdentityApi, SessionApi
{
  static create(options: LdapAuthCreateOptions) {
    const {
      discoveryApi,
      provider = DEFAULT_PROVIDER,
      environment = 'development',
    } = options;

    const connector = new LdapDefaultAuthConnector({
      discoveryApi,
      environment,
      provider,
      sessionTransform(res: LdapAuthResponse): LdapAuthSession {
        return res;
      },
      extractHeaders(session: LdapAuthSession): any {
        return {
          'x-ldap-mail': session.profile.email,
          'x-ldap-name': session.profile.displayName,
          Authorization: `Bearer ${session.backstageIdentity?.token}`,
        };
      },
    });

    const sessionManager = new LdapRefreshingAuthSessionManager({
      connector,
      storageKey: `${provider.id}Session`,
      sessionShouldRefresh: (session: LdapAuthSession) => {
        if (!session.backstageIdentity?.token) {
          return true;
        }
        const decodedToken: Record<string, string> = jwtDecoder(
          session.backstageIdentity.token,
        );
        const expiresInSec =
          (new Date(Number(decodedToken.exp) * 1000).getTime() - Date.now()) /
          1000;
        return expiresInSec < 60 * 5;
      },
    });

    return new LdapAuth(sessionManager);
  }

  private readonly sessionManager: SessionManager<LdapAuthSession>;

  private constructor(sessionManager: SessionManager<LdapAuthSession>) {
    this.sessionManager = sessionManager;
  }

  async signIn() {
    await this.getBackstageIdentity();
  }

  async signOut() {
    await this.sessionManager.removeSession();
  }

  sessionState$(): Observable<SessionState> {
    return this.sessionManager.sessionState$();
  }

  async getBackstageIdentity(
    options: GetSessionOptions = {},
  ): Promise<BackstageIdentityResponse | undefined> {
    const session = await this.sessionManager.getSession(options);
    return session?.backstageIdentity;
  }

  async getProfile(options: GetSessionOptions = {}) {
    const session = await this.sessionManager.getSession(options);
    return session?.profile;
  }
}

export const ldapAuthApiRef: ApiRef<
  ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'core.auth.ldap',
});
