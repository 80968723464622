import { Box } from '@material-ui/core';
import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const EditorComponentPageLoader: React.FC = () => {
  return (
    <Box>
      <Skeleton variant="rect" height="50vh" />
    </Box>
  );
};
