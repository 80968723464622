import {
  ComponentType,
  DomainType,
  SystemType,
  Taxonomy,
} from '@agilelab/plugin-wb-builder-common';
import { Edge, EdgeMarker, MarkerType } from 'reactflow';
import {
  CustomNodeTypes,
  IEntityNode,
} from '../components/PracticeShaperOverview/PracticeShaperGraph';
import { HIGHLIGHTED_RELATIONS } from '../context/PracticeShaperGraphContext';

type InputEntity = { error: boolean } & (
  | SystemType
  | DomainType
  | ComponentType
  | Taxonomy
);

const formatRelationType = (relation: string) => relation.split('-')[1];

const formatTargetId = (targetRef: string) =>
  `${targetRef.split('/')[1]}/${targetRef.split(':')[0]}`;

export const BASE_MARKER: EdgeMarker = {
  type: MarkerType.ArrowClosed,
  width: 20,
  height: 20,
};
const BASE_EDGE_PROPS = {
  type: 'entity_edge',
  focusable: false,
};

export const buildNodesAndEdges = (
  entities: InputEntity[],
  excludedEdgesTypes: string[] = [],
  getMarker?: (primaryEdge?: boolean) => EdgeMarker,
) => {
  const nodeSet = new Set<string>(
    entities.map(e => `${e.metadata.name}/${e.kind.toLowerCase()}`),
  );
  const nodes: Omit<IEntityNode, 'position'>[] = [];
  const edges: Edge[] = [];

  const excludedEdgesTypeSet = new Set(excludedEdgesTypes);

  const selfEdges = new Map<string, string>();

  entities.forEach(node => {
    const id = `${node.metadata.name}/${node.kind.toLowerCase()}`;
    nodes.push({
      id,
      type: 'entity' as CustomNodeTypes,
      data: {
        ...node,
        label: (node.metadata as any).displayName || node.metadata.name,
      },
    });
    node.relations?.forEach(r => {
      const targetId = formatTargetId(r.targetRef);
      const relationType = formatRelationType(r.type);
      const selfConnecting = id === targetId;

      const primary = HIGHLIGHTED_RELATIONS.includes(relationType);
      if (!(nodeSet.has(targetId) && !excludedEdgesTypeSet.has(relationType)))
        // exclude relations which are not between the provided nodes
        return;

      // for self connecting edges, don't add them to the edges list yet, but group their labels into one edge
      if (selfConnecting) {
        const base = selfEdges.get(id) ? `${selfEdges.get(id)}, \n` : '';
        selfEdges.set(id, base + relationType);
        return;
      }

      edges.push({
        ...BASE_EDGE_PROPS,
        source: id,
        target: targetId,
        label: relationType,
        markerEnd: getMarker?.(primary) ?? BASE_MARKER,
        data: { primary: primary },
        sourceHandle: selfConnecting ? 'self-source' : 'top',
        targetHandle: selfConnecting ? 'self-target' : 'bottom',
        id: `${id}-${targetId}`,
      });
    });
  });

  // add self connecting edges at the end with a label for all self connecting relations
  selfEdges.forEach((val, key) => {
    edges.push({
      ...BASE_EDGE_PROPS,
      source: key,
      target: key,
      label: val,
      markerEnd: getMarker?.() ?? BASE_MARKER,
      sourceHandle: 'self-source',
      targetHandle: 'self-target',
      id: `${key}-${key}`,
    });
  });

  return {
    nodes,
    edges,
  };
};
