import { Card, Grid, Paper } from '@material-ui/core';
import React from 'react';
import {
  GenericField,
  MeshCardHeader,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { useDataContractEditorContext } from './context/useDataContractEditorContext';
import { camelToSpacedCase } from '@agilelab/plugin-wb-platform-common';
import MissingKeyCardContent from './MissingKeyCardContent';

export const DataQualityCard = () => {
  const paperStyle = {
    background: 'white',
    boxShadow: 'none',
    marginTop: '10px',
    marginBottom: '10px',
    marginRight: '15px',
    marginLeft: '15px',
  } as React.CSSProperties;

  const { formData } = useDataContractEditorContext();

  return (
    <Card
      style={{
        marginTop: '20px',
      }}
    >
      <MeshCardHeader title="Data Quality" />
      {formData.qualityValues ? (
        formData.qualityValues?.map(
          (item: Record<string, any>, index: number) => (
            <div style={{ marginRight: '10px' }} key={index.toFixed(1)}>
              <WbWidget
                title={`${
                  item.code
                    ? camelToSpacedCase(item.code)
                    : `Check quality ${index + 1}`
                }`}
                cardStyle={{ margin: '5px' }}
              >
                <Paper style={paperStyle}>
                  {Object.entries(item).map(([key, value]) => {
                    const isHandledKey = typeof key === 'string';
                    const isHandledValue = typeof value === 'string';
                    if (!isHandledKey || !isHandledValue || key === 'code') {
                      return <div key={key} />;
                    }
                    return (
                      <Grid container spacing={2} key={key}>
                        <Grid item xs={6}>
                          <GenericField
                            value={camelToSpacedCase(key)}
                            contentProps={{
                              style: { wordBreak: 'break-word' },
                            }}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <GenericField
                            contentProps={{
                              style: { wordBreak: 'break-word' },
                            }}
                            value={value}
                          />
                        </Grid>
                      </Grid>
                    );
                  })}
                </Paper>
              </WbWidget>
            </div>
          ),
        )
      ) : (
        <MissingKeyCardContent keyProp="Data Quality" />
      )}
    </Card>
  );
};
