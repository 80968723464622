import { EventEmitter } from 'events';

type RegisterEntry = {
  id: string | (string | undefined)[];
  function?: Function;
  metadata?: Record<string, any>;
};
export class ComponentRegister extends EventEmitter {
  private readonly components: Record<string, RegisterEntry>;
  constructor() {
    super();
    this.components = {};
  }
  register(entry: RegisterEntry): void {
    const componentKey =
      typeof entry.id === 'string'
        ? entry.id
        : entry.id.filter(s => (s || '') !== '').join('/');
    this.components[componentKey] = entry;
    this.emit('newcomponent', componentKey);
  }
  exists(componentId: string | (string | undefined)[]): boolean {
    const componentKey =
      typeof componentId === 'string'
        ? [componentId]
        : componentId.filter(s => (s || '') !== '');
    while (componentKey.length > 0) {
      if (componentKey.join('/') in this.components) return true;
      componentKey.pop();
    }
    return false;
  }
  get(componentId: string | (string | undefined)[]): Function | undefined {
    const componentKey =
      typeof componentId === 'string'
        ? [componentId]
        : componentId.filter(s => (s || '') !== '');
    while (componentKey.length > 0) {
      if (componentKey.join('/') in this.components)
        return this.components[componentKey.join('/')].function;
      componentKey.pop();
    }
    return undefined;
  }
  getMetadata(componentId: string | string[]): Record<string, any> | undefined {
    const componentKey =
      typeof componentId === 'string'
        ? [componentId]
        : componentId.filter(s => (s || '') !== '');
    while (componentKey.length > 0) {
      if (componentKey.join('/') in this.components)
        return this.components[componentKey.join('/')].metadata;
      componentKey.pop();
    }
    return undefined;
  }
  listIds(): string[] {
    return Object.keys(this.components);
  }
}
export class ComponentRegisters {
  static registers: Record<string, ComponentRegister> = {};
  static getComponentRegister(id: string): ComponentRegister {
    if (!(id in ComponentRegisters.registers)) {
      ComponentRegisters.registers[id] = new ComponentRegister();
    }
    return ComponentRegisters.registers[id];
  }
}
