import { DependencyGraphTypes, Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import React from 'react';

import { dataProductRouteRef } from '../../routes';
import { useDependenciesStyle } from '../../themes';
import { Tooltip, useTheme } from '@material-ui/core';

export const RenderedNode = (
  props: DependencyGraphTypes.RenderNodeProps<any>,
) => {
  const theme = useTheme();
  const classes = useDependenciesStyle();
  const dataProductRoute = useRouteRef(dataProductRouteRef);
  const kind = props.node.kind || 'component';
  const MAX_NAME_LENGTH = 17;
  const truncatedNodeName =
    props.node.name.length < MAX_NAME_LENGTH
      ? props.node.name
      : `${props.node.name.slice(0, MAX_NAME_LENGTH)}...`;
  let nodeClass = classes.componentNode;

  switch (kind) {
    case 'component':
      nodeClass = classes.dataproduct;
      break;
    case 'main':
      nodeClass = classes.main;
      break;
    case 'resource':
      nodeClass = classes.external;
      break;
    default:
      nodeClass = classes.componentNode;
  }

  const rect = (
    <g className={nodeClass}>
      <rect width={220} height={40} rx={20} />

      {kind === 'main' || kind === 'component' ? (
        <svg y={8} x={12}>
          <path d="M21,16.5C21,16.88 20.79,17.21 20.47,17.38L12.57,21.82C12.41,21.94 12.21,22 12,22C11.79,22 11.59,21.94 11.43,21.82L3.53,17.38C3.21,17.21 3,16.88 3,16.5V7.5C3,7.12 3.21,6.79 3.53,6.62L11.43,2.18C11.59,2.06 11.79,2 12,2C12.21,2 12.41,2.06 12.57,2.18L20.47,6.62C20.79,6.79 21,7.12 21,7.5V16.5Z" />
        </svg>
      ) : (
        <svg y={8} x={12}>
          <path d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z" />
        </svg>
      )}

      <text
        x={50}
        y={25}
        alignmentBaseline="baseline"
        style={{ fontWeight: 'bold' }}
      >
        {truncatedNodeName}
      </text>
    </g>
  );

  return (
    <>
      {kind === 'component' ? (
        <Tooltip title={props.node.name}>
          <Link
            style={{ textDecoration: 'none' }}
            to={dataProductRoute({
              id: props.node.dataProductInstanceId ?? props.node.id,
            })}
          >
            {rect}
          </Link>
        </Tooltip>
      ) : (
        <Tooltip title={props.node.name}>{rect}</Tooltip>
      )}
      <text
        fill={theme.palette.secondary.main}
        x={110}
        y={50}
        textAnchor="middle"
        alignmentBaseline="hanging"
      >
        {props.node.domain || 'External'}
      </text>
    </>
  );
};
