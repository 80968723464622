/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import { configApiRef, errorApiRef, useApi } from '@backstage/core-plugin-api';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AnalyzeResult, catalogImportApiRef } from '../../api';
import { NextButton } from '../Buttons';
import { asInputRef } from '../helpers';
import { ImportFlows, PrepareResult } from '../useImportState';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import { parseErrorMessage } from '../utils';

type FormData = {
  url: string;
};

/**
 * Props for {@link StepInitAnalyzeUrl}.
 *
 * @public
 */
export interface StepInitAnalyzeUrlProps {
  onAnalysis: (
    flow: ImportFlows,
    url: string,
    result: AnalyzeResult,
    opts?: { prepareResult?: PrepareResult },
  ) => void;
  beforeAnalysis?: (url: string) => Promise<void>;
  disablePullRequest?: boolean;
  analysisUrl?: string;
  exampleLocationUrl?: string;
  locationKindsToImport?: string[];
}

/**
 * Checks whether the URN of the incoming domain is in conflict with an existing domain URN.
 * This check only applies to domains, as they are the only entities in which we apply name normalization
 * @see generateURNByKind
 * @param domainEntityName
 * @param catalogApi
 */
async function existsDomain(
  domainEntityName: string,
  catalogApi: CatalogApi,
): Promise<boolean> {
  const registeredDomainsNames = (
    await catalogApi.getEntities({
      filter: { kind: 'Domain' },
      fields: ['metadata.name'],
    })
  ).items.map(item => item.metadata.name);

  const domainUrn = generateURNByKind(domainEntityName, 'Domain');
  const registeredDomainsUrns = registeredDomainsNames.map(name =>
    generateURNByKind(name, 'Domain'),
  );

  return registeredDomainsUrns.includes(domainUrn);
}

/**
 * A form that lets the user input a url and analyze it for existing locations or potential entities.
 *
 * @param onAnalysis - is called when the analysis was successful
 * @param analysisUrl - a url that can be used as a default value
 * @param disablePullRequest - if true, repositories without entities will abort the wizard
 * @param locationKindsToExclude - a list of location kinds to exclude from the Analyze step
 * @public
 */
export const StepInitAnalyzeUrl = (props: StepInitAnalyzeUrlProps) => {
  const {
    onAnalysis,
    beforeAnalysis,
    analysisUrl = '',
    disablePullRequest = false,
    exampleLocationUrl = 'https://github.com/backstage/backstage/blob/master/catalog-info.yaml',
    locationKindsToImport,
  } = props;

  const errorApi = useApi(errorApiRef);
  const configApi = useApi(configApiRef);
  const catalogImportApi = useApi(catalogImportApiRef);
  const catalogApi = useApi(catalogApiRef);

  const defaultCatalogRules = configApi.getOptional('catalog.rules') as
    | [{ allow: string[] }]
    | undefined;

  const allowedLocationKinds = useMemo(() => {
    return locationKindsToImport || defaultCatalogRules?.flatMap(v => v.allow);
  }, [defaultCatalogRules, locationKindsToImport]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>({
    mode: 'onTouched',
    defaultValues: {
      url: analysisUrl,
    },
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const handleResult = useCallback(
    async ({ url }: FormData) => {
      setSubmitted(true);

      try {
        if (typeof beforeAnalysis === 'function') {
          await beforeAnalysis(url);
        }

        const analysisResult = await catalogImportApi.analyzeUrl(url);

        switch (analysisResult.type) {
          case 'repository':
            if (
              !disablePullRequest &&
              analysisResult.generatedEntities.length > 0
            ) {
              onAnalysis('no-location', url, analysisResult);
            } else {
              setError("Couldn't generate entities for your repository");
              setSubmitted(false);
            }
            break;

          case 'locations': {
            if (allowedLocationKinds) {
              for (const location of analysisResult.locations) {
                for (const entity of location.entities) {
                  if (!allowedLocationKinds.includes(entity.kind)) {
                    const err = `Unable to import ${
                      entity.kind
                    } kind, the allowed kinds are [${allowedLocationKinds.join(
                      ', ',
                    )}].`;
                    throw new Error(err);
                  }

                  if (
                    entity.kind.toLocaleLowerCase() === 'domain' &&
                    (await existsDomain(entity.name, catalogApi))
                  ) {
                    throw new Error(
                      `Cannot import ${entity.name} because its URN is conflicting with an already existing domain`,
                    );
                  }
                }
              }
            }
            if (analysisResult.locations.length === 1) {
              onAnalysis('single-location', url, analysisResult, {
                prepareResult: analysisResult,
              });
            } else if (analysisResult.locations.length > 1) {
              onAnalysis('multiple-locations', url, analysisResult);
            } else {
              setError('There are no entities at this location');
              setSubmitted(false);
            }
            break;
          }

          default: {
            const err = `Received unknown analysis result of type ${
              (analysisResult as any).type
            }. Please contact the support team.`;
            setError(err);
            setSubmitted(false);

            errorApi.post(new Error(err));
            break;
          }
        }
      } catch (e: any) {
        setError(parseErrorMessage(e));
        setSubmitted(false);
      }
    },
    [
      allowedLocationKinds,
      catalogApi,
      catalogImportApi,
      disablePullRequest,
      errorApi,
      onAnalysis,
      beforeAnalysis,
    ],
  );

  return (
    <form onSubmit={handleSubmit(handleResult)}>
      <TextField
        {...asInputRef(
          register('url', {
            required: true,
            validate: {
              httpsValidator: (value: any) =>
                (typeof value === 'string' &&
                  value.match(/^http[s]?:\/\//) !== null) ||
                'Must start with http:// or https://.',
            },
          }),
        )}
        fullWidth
        id="url"
        label="Repository URL"
        placeholder={exampleLocationUrl}
        helperText="Enter the full path to your entity file to start tracking your component"
        margin="normal"
        variant="outlined"
        error={Boolean(errors.url)}
        required
      />

      {errors.url && (
        <FormHelperText error>{errors.url.message}</FormHelperText>
      )}

      {error && <FormHelperText error>{error}</FormHelperText>}

      <Grid container spacing={0}>
        <NextButton
          disabled={Boolean(errors.url) || !watch('url')}
          loading={submitted}
          type="submit"
        >
          Analyze
        </NextButton>
      </Grid>
    </form>
  );
};
