/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import { Entity, CompoundEntityRef } from '@backstage/catalog-model';
import { useApp } from '@backstage/core-plugin-api';
import {
  EntityRefLink,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';

const useStyles = makeStyles(theme => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function sortEntities(entities: Array<CompoundEntityRef | Entity>) {
  return entities.sort((a, b) =>
    humanizeEntityRef(a).localeCompare(humanizeEntityRef(b)),
  );
}

/**
 * Props for {@link EntityListComponent}.
 *
 * @public
 */
export interface EntityListComponentProps {
  locations: Array<{
    target: string;
    entities: (Entity | CompoundEntityRef)[];
  }>;
  locationListItemIcon: (target: string) => React.ReactElement;
  collapsed?: boolean;
  firstListItem?: React.ReactElement;
  onItemClick?: (target: string) => void;
  withLinks?: boolean;
}

/**
 * Shows a result list of entities.
 *
 * @public
 */
export const EntityListComponent = (props: EntityListComponentProps) => {
  const {
    locations,
    collapsed = false,
    locationListItemIcon,
    onItemClick,
    firstListItem,
    withLinks = false,
  } = props;

  const app = useApp();
  const classes = useStyles();

  const [expandedUrls, setExpandedUrls] = useState<string[]>([]);

  const handleClick = (url: string) => {
    setExpandedUrls(urls =>
      urls.includes(url) ? urls.filter(u => u !== url) : urls.concat(url),
    );
  };

  return (
    <List>
      {firstListItem}
      {locations.map(r => (
        <React.Fragment key={r.target}>
          <ListItem
            dense
            button={Boolean(onItemClick) as any}
            onClick={() => onItemClick?.(r.target)}
          >
            <ListItemIcon>{locationListItemIcon(r.target)}</ListItemIcon>

            <ListItemText
              primary={r.target}
              secondary={`Entities: ${r.entities.length}`}
            />

            {collapsed && (
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleClick(r.target)}>
                  {expandedUrls.includes(r.target) ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>

          <Collapse
            in={!collapsed || expandedUrls.includes(r.target)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding dense>
              {sortEntities(r.entities).map(entity => {
                const Icon = app.getSystemIcon(
                  `kind:${entity.kind.toLocaleLowerCase('en-US')}`,
                );
                return (
                  <ListItem
                    key={humanizeEntityRef(entity)}
                    className={classes.nested}
                    {...(withLinks
                      ? {
                          component: EntityRefLink,
                          entityRef: entity,
                          button: withLinks as any,
                        }
                      : {})}
                  >
                    <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
                    <ListItemText primary={humanizeEntityRef(entity)} />
                  </ListItem>
                );
              })}
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  );
};
