import { usePublishEvent } from '@agilelab/plugin-wb-events';
import { marketplaceSystemNavigatedTopic } from '@agilelab/plugin-wb-events-common';
import { CustomView } from '@agilelab/plugin-wb-platform';
import { default as React, useEffect } from 'react';
import { useLocation } from 'react-router';

export const DataProductPage = (props: Record<string, any>) => {
  const { publish } = usePublishEvent({
    generatorSystem: 'marketplace',
    keyMarketplace: props.data?.dpData?.descriptor?.id,
  });

  const location = useLocation();

  useEffect(() => {
    if (props.data?.dpData?.descriptor?.id) {
      publish(marketplaceSystemNavigatedTopic, {
        path: location.pathname,
        entityUrn: props.data?.dpData?.descriptor?.id,
      });
    }
  }, [location.pathname, props.data?.dpData?.descriptor?.id, publish]);

  return (
    <CustomView
      id="marketplace_system"
      typeId={props.data?.dpData?.descriptor?.kind}
      templateId={props.data?.dpData?.descriptor?.useCaseTemplateId}
      data={props.data}
      actions={props.actions || {}}
    />
  );
};
