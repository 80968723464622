import {
  parseEntityRef,
  DEFAULT_NAMESPACE,
  Entity,
  CompoundEntityRef,
} from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import {
  entityRouteRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';

interface Props {
  entityRef: Entity | CompoundEntityRef | string;
  defaultKind?: string;
}

export function useEntityRefLink({ entityRef, defaultKind }: Props): {
  formattedEntityRefTitle: string;
  to: string;
} {
  const entityRoute = useRouteRef(entityRouteRef);

  let kind;
  let namespace;
  let name;

  if (typeof entityRef === 'string') {
    const parsed = parseEntityRef(entityRef);
    kind = parsed.kind;
    namespace = parsed.namespace;
    name = parsed.name;
  } else if ('metadata' in entityRef) {
    kind = entityRef.kind;
    namespace = entityRef.metadata.namespace;
    name = entityRef.metadata.name;
  } else {
    kind = entityRef.kind;
    namespace = entityRef.namespace;
    name = entityRef.name;
  }

  kind = kind.toLocaleLowerCase('en-US');
  namespace = namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE;

  const routeParams = { kind, namespace, name };

  const formattedEntityRefTitle = humanizeEntityRef(
    { kind, namespace, name },
    { defaultKind },
  );

  return {
    formattedEntityRefTitle,
    to: entityRoute(routeParams),
  };
}
