import React from 'react';
import { Avatar, AvatarProps } from '@material-ui/core';
import { useUserProfile } from '../../hooks';

type WbUserAvatarProps = Omit<AvatarProps, 'alt' | 'src'>;

export const WbUserAvatar = ({ ...props }: WbUserAvatarProps) => {
  const { displayName, profile } = useUserProfile();

  return (
    <Avatar
      {...props}
      alt={`${displayName} profile image`}
      src={profile.picture}
    />
  );
};
