/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 * - Add useEffect to allow filter clear
 */

import {
  useEntityList,
  EntityOwnerFilter,
  humanizeEntityRef,
  catalogApiRef,
  CATALOG_FILTER_EXISTS,
} from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AsyncEnumFilter } from '../WbTableFilters';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { RELATION_SYSTEM_OWNERSHIP } from '@agilelab/plugin-wb-builder-common';

export const EntityOwnerPicker: React.FC = () => {
  const {
    updateFilters,
    filters,
    queryParameters: { owners: ownersParameter },
  } = useEntityList();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);

  const queryParamOwners = useMemo(
    () => [ownersParameter].flat().filter(Boolean) as string[],
    [ownersParameter],
  );

  const [selectedOwners, setSelectedOwners] = useState(
    queryParamOwners.length ? queryParamOwners : filters.owners?.values ?? [],
  );

  // Set selected owners on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParamOwners.length) {
      setSelectedOwners(queryParamOwners);
    }
  }, [queryParamOwners]);

  const getAvailableOwners = useCallback(async () => {
    const credentials = await identityApi.getCredentials();
    const response = await catalogApi.getEntities(
      {
        filter: {
          kind: ['user', 'group'],
          [`relations.${RELATION_SYSTEM_OWNERSHIP.SYSTEM_OWNER_OF}`]:
            CATALOG_FILTER_EXISTS,
        },
      },
      credentials,
    );
    return response.items
      .map(entity => humanizeEntityRef(entity, { defaultKind: 'group' }))
      .sort((a, b) => a.localeCompare(b));
  }, [catalogApi, identityApi]);

  useEffect(() => {
    updateFilters({
      owners: selectedOwners.length
        ? new EntityOwnerFilter(selectedOwners)
        : undefined,
    });
  }, [selectedOwners, updateFilters]);

  return (
    <AsyncEnumFilter<string>
      field="Owner"
      onChange={value => setSelectedOwners(value ?? [])}
      getOptions={getAvailableOwners}
      renderOption={o => o}
      renderValue={v => v}
      value={selectedOwners}
      onSearch={(searchValue, options) =>
        options.filter(o => new RegExp(searchValue, 'ig').test(o))
      }
    />
  );
};
