import { entityRefToUrn, SystemType } from '@agilelab/plugin-wb-builder-common';
import { ALL_TAXONOMIES_FILTER } from '@agilelab/plugin-wb-practice-shaper';

/**
 * Parses optional config
 *
 *
 * @param config - an optional boolean config
 * @returns true if config is true or undefined
 */
export function parseOptionalBooleanConfig(config?: boolean): boolean {
  if (config === undefined) return true;
  return config;
}

export function getCardLabel(systemTypes: SystemType[]): string {
  if (systemTypes.length === 1) {
    return (
      systemTypes[0].metadata?.displayName ??
      systemTypes[0].metadata?.name ??
      'System'
    );
  }

  return 'System';
}

export function buildDashboardWhereQuery(
  taxonomyUrn: string | undefined,
  additionalConditions: Record<string, any>[] = [],
): Record<string, any> {
  let where = {
    _and: [{ kind: { _eq: 'system' } }],
  };

  if (taxonomyUrn) {
    (where._and as any[]).push({
      taxonomy: { external_id: { _eq: taxonomyUrn } },
    });
  }

  if (additionalConditions.length > 0) {
    where = { _and: [...(where._and as any[]), ...additionalConditions] };
  }

  return where;
}

export function extractTaxonomyUrn(taxonomyRef: string): string | undefined {
  if (taxonomyRef === ALL_TAXONOMIES_FILTER) {
    return undefined;
  }
  return entityRefToUrn(taxonomyRef);
}
