import { Box } from '@material-ui/core';
import React, { useMemo } from 'react';
import { Metric } from '@agilelab/plugin-wb-governance-common';
import { ErrorBox } from '../../../common/ErrorBox/ErrorBox';
import { Editor } from '../../../common/Editor/Editor';
import { useEditorScroll } from '../../../../hooks/useEditorScroll';
import { extractGovernanceErrorInfo } from '../../../../utils/governanceUtils';

interface Props {
  metric: Metric;
  test: {
    errors?: string[];
    content: string;
    name: string;
  };
}

export const GovernanceTestOverviewMetricDetails: React.FC<Props> = props => {
  const { test, metric } = props;
  const [currentErrorIndex, setCurrentErrorIndex] = React.useState<number>(0);

  const handleScroll = useEditorScroll('cm-scroller');

  const errors = useMemo(
    () =>
      (test?.errors || []).map(error =>
        extractGovernanceErrorInfo({ policyId: metric.metricId, error }),
      ),
    [metric.metricId, test?.errors],
  );

  const currentError = useMemo(
    () => errors?.at(currentErrorIndex),
    [currentErrorIndex, errors],
  );

  const handleSwitch = (index: number) => {
    setCurrentErrorIndex(index);
  };

  React.useEffect(() => {
    const descriptorRow = currentError?.descriptorErrorRows?.at(0);

    if (`descriptor-${props.metric.groupId}` && descriptorRow) {
      handleScroll(descriptorRow, `descriptor-${props.metric.groupId}`);
    }
  }, [currentError?.descriptorErrorRows, handleScroll, props.metric.groupId]);

  return (
    <Box>
      {!!currentError && (
        <ErrorBox
          policyEditorId={`policy-${props.metric.groupId}`}
          descriptorEditorId={`descriptor-${props.metric.groupId}`}
          headerTitle="Error Info"
          error={currentError}
          handleScroll={handleScroll}
          navigation={{
            current: currentErrorIndex,
            total: errors?.length || 0,
            handleSwitch: handleSwitch,
          }}
        />
      )}

      {test.content && (
        <Box mt={2}>
          <Editor
            id={`descriptor-${metric.groupId}`}
            name={`Descriptor: ${test.name}`}
            editable={false}
            content={test.content}
            highlightLines={currentError?.descriptorErrorRows}
          />
        </Box>
      )}
    </Box>
  );
};
