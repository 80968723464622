import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import { TemplateEntityUpdate } from '../../types';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import {
  PanelCatalogApi,
  panelCatalogApiRef,
} from '@agilelab/plugin-wb-builder-catalog';
import yaml from 'yaml';
import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import { practiceShaperApiRef } from '../../../../plugin';
import {
  Entity,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';

const fetchResourceTypesMap = async (
  practiceShaperApi: PracticeShaperApi,
  identityApi: IdentityApi,
) => {
  const credentials = await identityApi.getCredentials();
  const { items: systemTypes } = await practiceShaperApi.getSystemTypes(
    {},
    credentials,
  );
  const { items: componentTypes } = await practiceShaperApi.getComponentTypes(
    {},
    credentials,
  );
  return [...systemTypes, ...componentTypes].reduce((map, type) => {
    map.set(type.spec.resourceTypeId, type);
    return map;
  }, new Map<String, Entity>());
};

const fetchTemplateUpdatesFn = async (
  catalogApi: CatalogApi,
  panelCatalogApi: PanelCatalogApi,
  practiceShaperApi: PracticeShaperApi,
  identityApi: IdentityApi,
) => {
  const credentials = await identityApi.getCredentials();
  const { items: registeredTemplates } = await catalogApi.getEntities(
    {
      filter: { kind: 'Template' },
      fields: ['metadata', 'apiVersion', 'kind', 'spec.type', 'spec.generates'],
    },
    credentials,
  );
  const typesMap = await fetchResourceTypesMap(practiceShaperApi, identityApi);
  return Promise.all(
    registeredTemplates.map(async template => {
      const resourceTypeId = template.spec?.type as string;
      if (template.spec?.generates) {
        // Template already updated
        const referencedTypeRef = template.spec.generates as string; // system or component type instantiated by this template
        const entityRef = parseEntityRef(referencedTypeRef);
        return {
          kind: 'Template',
          name: template.metadata.title,
          description: template.metadata.description ?? '',
          resourceType: resourceTypeId,
          originalEntity: template,
          generates: entityRef,
          updateAvailable: false, // already updated (generates field filled)
        } as TemplateEntityUpdate;
      }
      // Template to be updated
      const referencedType = typesMap.get(resourceTypeId);
      const targetReferencedTypeRef = referencedType
        ? stringifyEntityRef(referencedType)
        : undefined;
      return {
        kind: 'Template',
        name: template.metadata.title,
        description: template.metadata.description ?? '',
        fetchCatalogInfoContent: async () => {
          const catalogInfo = await panelCatalogApi.fetchCatalogInfo(template);
          const previousCatalogInfo = yaml.parse(catalogInfo.content);
          const { spec: previousSpecProp = {} } = previousCatalogInfo;
          const newCatalogInfo = {
            ...previousCatalogInfo,
            spec: {
              generates: targetReferencedTypeRef ?? '',
              ...previousSpecProp,
            },
          };
          return {
            newContent: newCatalogInfo,
            previousContent: previousCatalogInfo,
            repositoryFile: catalogInfo,
          };
        },
        resourceType: resourceTypeId,
        updateAvailable: targetReferencedTypeRef !== undefined,
        originalEntity: template,
      } as TemplateEntityUpdate;
    }),
  );
};

export const useFetchTemplateUpdates = () => {
  const catalogApi = useApi(catalogApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);
  const [fetchTemplateUpdatesState, fetchTemplateUpdates] = useAsyncFn(
    () =>
      fetchTemplateUpdatesFn(
        catalogApi,
        panelCatalogApi,
        practiceShaperApi,
        identityApi,
      ),
    [catalogApi, panelCatalogApi, identityApi, practiceShaperApi],
  );
  return {
    fetchTemplateUpdates,
    fetchTemplateUpdatesState,
  };
};
