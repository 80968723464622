import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React, { useState } from 'react';
import CodeIcon from '@material-ui/icons/Code';
import CloseIcon from '@material-ui/icons/Close';
import { WbEditor } from '../Editors/WbEditor';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

interface StatusFieldProps {
  value: string;
  title: string;
  parser?: string;
}
export const CodeField: React.FC<StatusFieldProps> = props => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Button
        color="primary"
        variant="text"
        endIcon={<CodeIcon style={{ fontSize: 14 }} />}
        onClick={() => setOpenDialog(true)}
        style={{ padding: theme.spacing(0, 1) }}
      >
        Show code
      </Button>
      <Dialog
        PaperProps={{
          style: {},
        }}
        fullWidth
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      >
        <DialogTitle>
          {props.title}
          <IconButton
            onClick={() => setOpenDialog(false)}
            size="small"
            className={classes.closeIcon}
            aria-label="close-score-dialog"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(0, 3, 2),
          }}
        >
          <WbEditor
            height="50vh"
            value={props.value}
            options={{ readOnly: true, minimap: { enabled: false } }}
            language={props.parser}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
