import {
  EvaluationReportByUUIDQuery,
  PolicyByIdAndEnvsQuery,
  PolicyViolation,
  Timing,
} from '@agilelab/plugin-wb-governance-common';
import { format, parseISO } from 'date-fns';
import {
  EnumFilter,
  TableCellProps,
  TestStatus,
  WbCard,
  WbCardContent,
  WbTable,
  WbTableFilters,
  WbTruncatedTypographyWrapper,
  getObjectPropFromString,
  useDrawersContext,
} from '@agilelab/plugin-wb-platform';
import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  GET_EVALUATION_REPORT_BY_UUID,
  GET_POLICY_BY_POLICY_ID_AND_ENVS,
  TestDetailDrawer,
} from '@agilelab/plugin-wb-governance';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import _ from 'lodash';
import useAsync from 'react-use/lib/useAsync';
import { useApolloClient } from '@apollo/client';

interface PolicyItem {
  result?: DagStatus;
  id: string;
  name: string;
  version?: number;
  date: string;
  timing: Timing | undefined;
  description: string | null;
  linkAllowed: boolean;
}

export interface Filters {
  text?: string;
  result?: string[];
  timing?: string[];
}

type FilterField = 'result' | 'timing';

const changeFilter = (
  field: FilterField,
  value: string[] | undefined,
  setFilters: (filters: Filters) => void,
  currentFilters: Filters,
) => {
  setFilters({ ...currentFilters, [field]: value });
};

export const PoliciesTable = ({
  policyViolations,
  allowLink,
}: {
  policyViolations: PolicyViolation[];
  allowLink: boolean;
}) => {
  const columns: TableCellProps<PolicyItem>[] = [
    {
      field: 'name',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper
          value={`${field.name} v:${field.version}`}
        />
      ),
      headerName: 'Policy',
      cellProps: {
        align: 'left',
        width: '20%',
        size: 'small',
      },
      sortable: true,
    },

    {
      field: 'timing',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper value={field.timing} />
      ),
      headerName: 'Timing',
      cellProps: {
        align: 'left',
        width: '15%',
        size: 'small',
      },
      sortable: true,
    },

    {
      field: 'description',
      fieldRender: (field: any) => (
        <WbTruncatedTypographyWrapper value={field.description} />
      ),
      headerName: 'Description',
      cellProps: {
        align: 'left',
        width: '20%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'date',
      fieldRender: field => (
        <Typography component="div">
          {format(parseISO(field.date), 'yyyy/MM/dd HH:mm:ss')}
        </Typography>
      ),
      headerName: 'Date',
      cellProps: {
        align: 'left',
        width: '15%',
        size: 'small',
      },
      sortable: true,
    },
    {
      field: 'result',
      fieldRender: (field: any) => {
        return <TestStatus status={field.result} />;
      },
      headerName: 'Result',
      cellProps: {
        align: 'left',
        width: '30%',
        size: 'small',
      },
      sortable: true,
    },
  ];

  const apolloClient = useApolloClient();
  const { drawers, toggleBoolean } = useDrawersContext();
  const [dataTable, setDataTable] = useState<PolicyItem[]>([]);
  const [evaluationId, setEvaluationId] = useState<string>();
  const [filters, setFilters] = useState<Filters>({});
  const [pagination, setPagination] = useState({
    limit: 25,
    offset: 0,
  });
  const resultsOptions = ['Info', 'Warning', 'Error'];
  const timingOptions = ['Runtime', 'Deployment'];

  const { value: data, loading } = useAsync(async () => {
    const res = policyViolations.map(async violation => {
      const policy = (
        await apolloClient.query<PolicyByIdAndEnvsQuery>({
          query: GET_POLICY_BY_POLICY_ID_AND_ENVS,
          variables: { policyId: violation.policyId },
        })
      ).data.cgp_governance_entity[0];
      const report = (
        await apolloClient.query<EvaluationReportByUUIDQuery>({
          query: GET_EVALUATION_REPORT_BY_UUID,
          variables: { uuid: violation.reportId },
        })
      ).data.cgp_evaluation_report[0];
      return {
        id: violation.evaluationResultId,
        name: policy.name,
        version: policy.version,
        date: report.update_time,
        description: policy.description,
        result: violation.outcome.toUpperCase() as DagStatus,
        timing: policy.timing as Timing,
        linkAllowed: allowLink,
      };
    });
    return Promise.all(res);
  }, [policyViolations]);

  useEffect(() => {
    if (!data) return;
    const fields = columns.map(col => col.field || '');
    Object.keys(filters).forEach(key =>
      filters[key as keyof Filters] === undefined
        ? delete filters[key as keyof Filters]
        : {},
    );
    const filtered = data?.filter(row => {
      const rowItems = fields.map(field =>
        getObjectPropFromString(row, field).toLowerCase(),
      );

      return rowItems.find(
        item => item && item.includes(filters?.text?.toLowerCase() ?? ''),
      );
    });

    let filteredData = filtered;
    if (filters.result || filters.timing) {
      const filterResults = (filters.result || []).map(res =>
        _.capitalize(res.toLocaleLowerCase()),
      );

      const filterTimings = (filters.timing || []).map(tim =>
        _.capitalize(tim.toLocaleLowerCase()),
      );

      filteredData = filteredData.filter(row => {
        const rowResult = _.capitalize(row.result?.toLocaleLowerCase() ?? '');
        const rowTiming = _.capitalize(row.timing?.toLocaleLowerCase() ?? '');

        const resultMatches =
          filterResults.length === 0 || filterResults.includes(rowResult);
        const timingMatches =
          filterTimings.length === 0 || filterTimings.includes(rowTiming);

        return resultMatches && timingMatches;
      });
    }

    setDataTable(filteredData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, allowLink, filters]);

  const filterConfigurations = [
    {
      field: 'Result',
      options: resultsOptions,
      value: filters.result,
      onChange: (result: string[] | undefined) =>
        changeFilter('result', result, setFilters, filters),
      onSearch: (v: string | RegExp) =>
        resultsOptions.filter(o => new RegExp(v, 'ig').test(o)),
    },
    {
      field: 'Timing',
      options: timingOptions,
      value: filters.timing,
      onChange: (timing: string[] | undefined) =>
        changeFilter('timing', timing, setFilters, filters),
      onSearch: (v: string | RegExp) =>
        timingOptions.filter(o => new RegExp(v, 'ig').test(o)),
    },
  ];

  return (
    <>
      <WbCard title="Policies">
        <WbCardContent>
          <WbTableFilters
            searchValue={filters.text}
            onSearch={search => {
              setFilters({ text: search });
            }}
            onClear={() => setFilters({})}
          >
            {filterConfigurations.map((config, index) => (
              <EnumFilter<string>
                key={index}
                field={config.field}
                options={config.options}
                onChange={config.onChange}
                value={config.value}
                renderOption={(o: any) => o}
                renderValue={(o: any) => o}
                onSearch={config.onSearch}
              />
            ))}
          </WbTableFilters>
          <WbTable<PolicyItem>
            components={{
              tableLoader: { loading },
              tableContent: {
                columns,
                rows: dataTable.sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime(),
                ),
                onRowClick: e => {
                  setEvaluationId(e.id);
                  toggleBoolean(1);
                },
              },
            }}
            pagination={{
              count: dataTable?.length || 0,
              limit: pagination.limit,
              offset: pagination.offset,
              onPageChange: (page: number) => {
                setPagination({
                  ...pagination,
                  offset: page * pagination.limit,
                });
              },
              onRowsPerPageChange: (rowsPerPage: number) => {
                setPagination({
                  offset: 0,
                  limit: rowsPerPage,
                });
              },
            }}
          />
        </WbCardContent>
      </WbCard>
      <TestDetailDrawer
        evaluationId={evaluationId!}
        idType="external"
        open={drawers.get(1) ?? false}
        setOpen={() => toggleBoolean(1)}
      />
    </>
  );
};
