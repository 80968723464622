import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const WittyIcon = createSvgIcon(
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2945 0.588485C14.2253 0.439255 13.9823 0.439255 13.9131 0.588485C13.8345 0.757906 13.7397 0.929863 13.645 1.02403C13.5502 1.11819 13.3772 1.21237 13.2067 1.29047C13.0565 1.35927 13.0565 1.60075 13.2067 1.66954C13.3772 1.74764 13.5502 1.84181 13.645 1.93598C13.7397 2.03014 13.8345 2.2021 13.9131 2.37152C13.9823 2.52075 14.2253 2.52075 14.2945 2.37152C14.3731 2.2021 14.4679 2.03014 14.5626 1.93598C14.6587 1.84056 14.8276 1.74514 14.9915 1.66644C15.1374 1.59644 15.1374 1.36358 14.9915 1.29358C14.8276 1.21487 14.6587 1.11945 14.5626 1.02403C14.4679 0.929863 14.3731 0.757906 14.2945 0.588485Z"
      fill="url(#paint0_linear_243_5624)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.88295 2.483C11.4548 2.483 12.9388 2.85949 14.2495 3.52721C13.3997 4.15735 11.9493 4.75296 11.0664 5.0835C10.8264 5.17334 10.8264 5.52997 11.0664 5.61981C12.0815 5.99975 13.8469 6.73009 14.587 7.46053C15.327 8.19098 16.0671 9.93342 16.452 10.9353C16.5431 11.1721 16.9044 11.1721 16.9954 10.9353C17.322 10.0853 17.9043 8.70209 18.5253 7.85625C19.1595 9.14059 19.5159 10.5867 19.5159 12.116C19.5159 17.4362 15.2031 21.749 9.88295 21.749C4.56282 21.749 0.25 17.4362 0.25 12.116C0.25 6.79585 4.56282 2.483 9.88295 2.483ZM9.88295 17.7604C13.0002 17.7604 15.5272 15.2333 15.5272 12.116C15.5272 8.9987 13.0002 6.47166 9.88295 6.47166C6.76572 6.47166 4.23866 8.9987 4.23866 12.116C4.23866 15.2333 6.76572 17.7604 9.88295 17.7604Z"
      fill="url(#paint1_linear_243_5624)"
    />
    <path
      d="M16.7876 2.57669C16.7401 2.45292 16.5513 2.45292 16.5037 2.57669C16.3026 3.10019 15.9159 4.01059 15.5292 4.39223C15.1425 4.77389 14.2201 5.15555 13.6897 5.35412C13.5643 5.40106 13.5643 5.5874 13.6897 5.63435C14.2201 5.83286 15.1425 6.21447 15.5292 6.59613C15.9159 6.97778 16.3026 7.88821 16.5037 8.41169C16.5513 8.53544 16.7401 8.53544 16.7876 8.41169C16.9888 7.88821 17.3754 6.97778 17.7621 6.59613C18.1498 6.21353 19.0257 5.83088 19.5249 5.6328C19.6467 5.58443 19.6467 5.40398 19.5249 5.35561C19.0258 5.15753 18.1498 4.77488 17.7621 4.39223C17.3754 4.01059 16.9888 3.10019 16.7876 2.57669Z"
      fill="url(#paint2_linear_243_5624)"
    />
    <path
      d="M19.7951 0.578152C19.7382 0.442699 19.5245 0.442699 19.4676 0.578152C19.3496 0.859587 19.1821 1.20544 19.0147 1.37122C18.8472 1.53699 18.4979 1.70278 18.2136 1.81967C18.0768 1.87593 18.0768 2.08754 18.2136 2.1438C18.4979 2.26068 18.8472 2.42645 19.0147 2.59223C19.1821 2.75801 19.3496 3.10386 19.4676 3.38529C19.5245 3.52075 19.7382 3.52075 19.7951 3.38529C19.9131 3.10386 20.0806 2.75801 20.248 2.59223C20.4165 2.42543 20.752 2.25863 21.0216 2.14164C21.1545 2.08398 21.1545 1.87949 21.0216 1.82183C20.752 1.70483 20.4165 1.53802 20.248 1.37122C20.0806 1.20544 19.9131 0.859587 19.7951 0.578152Z"
      fill="url(#paint3_linear_243_5624)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_243_5624"
        x1="-1.49243e-07"
        y1="2.11878e-07"
        x2="21"
        y2="23.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_243_5624"
        x1="-1.49243e-07"
        y1="2.11878e-07"
        x2="21"
        y2="23.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_243_5624"
        x1="-1.49243e-07"
        y1="2.11878e-07"
        x2="21"
        y2="23.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_243_5624"
        x1="-1.49243e-07"
        y1="2.11878e-07"
        x2="21"
        y2="23.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EE72D3" />
        <stop offset="1" stopColor="#7C4DFF" />
      </linearGradient>
    </defs>
  </svg>,
  'WittyIcon',
);
