export enum AccessStatusEnum {
  Locked = 'Locked',
  Provided = 'Provided',
  Disabled = 'Not Provided',
}

export enum RequestStatusEnum {
  PENDING = 'Pending Request',
  WAITING = 'Waiting Request',
  PROVISIONING = 'Provisioning',
  GRANT = 'Granting',
  REVOKE = 'Revoking',
  OTHER = 'Other',
}

export interface ACLRequest {
  id: number;
  verb?: string;
  requestId: string;
  status: RequestStatusEnum;
  createdAt: string | Date;
  createdBy?: string;
  requesterDisplayName: string;
  ref?: string;
}

export interface ACL {
  displayName: string;
  kind?: string;
  verb?: string;
  access?: AccessStatusEnum;
  status?: RequestStatusEnum;
  requests?: ACLRequest[];
}
