import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import { RbacApi, RoleSubject } from '@agilelab/plugin-wb-rbac-common';
import { CustomAlertApi } from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';
import { CustomError } from '@agilelab/plugin-wb-platform-common';

import { NewVersion } from '../../api';

function updateProjectNameWithNewVersion(entityName: string, version: string) {
  const domainIdVersionArray = entityName?.split('.');
  return `${domainIdVersionArray[0]}.${domainIdVersionArray[1]}.${version}`;
}

export const onNewVersionCloneRbacPermissions = async (
  response: NewVersion,
  entity: Entity,
  rbacApi: RbacApi,
  alertApi: CustomAlertApi,
  token?: string,
) => {
  const urn = generateURNByKind(entity.metadata.name, entity.kind);
  const newEntityName = updateProjectNameWithNewVersion(
    response.projectName,
    response.version,
  );
  const newUrn = generateURNByKind(newEntityName, entity.kind);

  try {
    const rolesSubjects = await rbacApi.getRolesSubjects({
      filters: {
        entityRef: urn,
      },
      options: { token },
    });

    const updatedRolesSubjects: RoleSubject[] = rolesSubjects.map(
      roleSubject => ({
        subject: roleSubject.subject!,
        enabled: true,
        roleId: roleSubject.roleId!,
        entityRef: newUrn,
      }),
    );

    if (updatedRolesSubjects.length === 0) {
      return;
    }

    await rbacApi.addRolesSubjects({
      rolesSubjects: updatedRolesSubjects,
      options: {
        upsert: true,
        token,
      },
    });
  } catch (error) {
    alertApi.post({
      error: new CustomError(
        `Could not clone RBAC assignations from older version of the entity: ${urn}. You should manually create them`,
        error.message,
      ),
      severity: 'error',
    });
    return;
  }
};

export const newVersionCallback = async (
  response: NewVersion,
  entity: Entity,
  rbacApi: RbacApi,
  alertApi: CustomAlertApi,
  token?: string,
) => {
  await onNewVersionCloneRbacPermissions(
    response,
    entity,
    rbacApi,
    alertApi,
    token,
  );
};
