import { Box, makeStyles, useTheme } from '@material-ui/core';
import React, { useRef } from 'react';
import { EntityWithIcon } from '../../types';
import { EntityCard } from './EntityCard';
import { HorizontalLine } from './HorizontalLine';
import { VerticalLine } from './VerticalLine';
import { Entity } from '@backstage/catalog-model';
import { useElementSize } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(theme => ({
  selected: { border: 'none', background: theme.palette.bkg.primary },
}));

interface Props {
  entities: EntityWithIcon[];
  entity: Entity;
}

export const StructureList: React.FC<Props> = ({ entities, entity }) => {
  const classes = useStyles();
  const subEntities: number = entities?.length - 1;
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);

  const { clientWidth } = useElementSize(containerRef);

  const extraSmall = clientWidth < theme.breakpoints.values.sm / 2;

  if (entities?.length === 0) return <></>;

  return (
    <div
      ref={containerRef}
      style={{
        flex: 1,
        overflowY: 'auto',
        position: 'relative',
      }}
    >
      <EntityCard
        disabled={entity.metadata.name !== entities[0].metadata.name}
        className={
          entity.metadata.name === entities[0].metadata.name
            ? classes.selected
            : ''
        }
        entity={entities[0]}
        short={extraSmall}
      />

      {!!subEntities && (
        <VerticalLine
          cards={subEntities}
          i={entities.findIndex(e => entity.metadata.name === e.metadata.name)}
        />
      )}

      {entities?.slice(1, entities?.length).map((relatedEntity, i) => (
        <Box key={i} style={{ marginLeft: '22px', position: 'relative' }}>
          <HorizontalLine />
          <EntityCard
            short={extraSmall}
            disabled={
              entity.metadata.name !== entities[0].metadata.name &&
              entity.metadata.name !== relatedEntity.metadata.name
            }
            entity={relatedEntity}
            className={
              entity.metadata.name === relatedEntity.metadata.name
                ? classes.selected
                : ''
            }
          />
        </Box>
      ))}
    </div>
  );
};
