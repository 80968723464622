import {
  WbCardActionButton,
  WbCardContent,
  WbMarkdownHelperText,
  WbWidget,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import RemoveIcon from '@material-ui/icons/Delete';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { ArrayFieldTemplateProps } from '@rjsf/core';
import { Fragment, default as React } from 'react';
import { createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';

const DEFAULT_DESCRIPTION_ROWS = 3;

interface TableTemplateProperties {
  maxDescriptionRows: number;
}

function parseTableTemplateProperties(
  props: ArrayFieldTemplateProps,
): TableTemplateProperties {
  const inputMaxDescriptionRows =
    props.uiSchema?.['ui:options']?.maxDescriptionRows;
  const maxDescriptionRows: number =
    inputMaxDescriptionRows &&
    Number.isFinite(inputMaxDescriptionRows) &&
    (inputMaxDescriptionRows as number) > 0
      ? (inputMaxDescriptionRows as number)
      : DEFAULT_DESCRIPTION_ROWS;
  return {
    maxDescriptionRows,
  };
}
const useStyles = makeStyles(theme => ({
  capitalized: {
    textTransform: 'none',
  },
  rowButtonsCell: {
    position: 'sticky',
    right: 0,
    background: theme.palette.white,
    border: `1px solid ${theme.palette.grey[300]}`,
    borderTop: 'none',
    borderRight: 'none',
    padding: '0 12px',
  },
  iconsContainer: {
    display: 'flex',
    justifyContent: 'center',
    columnGap: theme.spacing(1),
    alignItems: 'center',
  },
  divider: {
    height: '20px',
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    margin: '0px 5px',
  },
  table: {
    height: '100%',
    borderSpacing: 0,
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderCollapse: 'separate',
  },
  whiteBg: {
    background: theme.palette.white,
  },
  headerCell: {
    padding: '12px 16px',
    textAlign: 'left',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  headerFirstCell: {
    position: 'sticky',
    left: 0,
    padding: '12px 16px',
    textAlign: 'left',
    textTransform: 'uppercase',
    verticalAlign: 'top',
    background: theme.palette.white,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  actionsCell: {
    textAlign: 'center',
    position: 'sticky',
    right: 0,
    background: theme.palette.white,
    padding: '12px 16px',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const RowButtons = (props: ArrayFieldTemplateProps['items'][0]) => {
  const classes = useStyles();
  return (
    <td className={classes.rowButtonsCell}>
      <div className={classes.iconsContainer}>
        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveUp}
            onClick={props.onReorderClick(props.index, props.index - 1)}
            style={{ padding: 0 }}
          >
            <KeyboardArrowUp fontSize="small" />
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveDown}
            onClick={props.onReorderClick(props.index, props.index + 1)}
            style={{ padding: 0 }}
          >
            <KeyboardArrowDown fontSize="small" />
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <div className={classes.divider} />
        )}

        {props.hasRemove && (
          <IconButton
            size="small"
            tabIndex={-1}
            color="secondary"
            disabled={props.disabled || props.readonly}
            onClick={props.onDropIndexClick(props.index)}
          >
            <Tooltip title="Remove Row" placement="bottom-start">
              <RemoveIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
      </div>
    </td>
  );
};

interface ColumnHeader {
  property: string;
  title: string;
  required: boolean;
  description?: string;
}

/**
 * Extracts the column headers form the input properties.
 * The column headers are sorted in the same way they are defined in the schema.
 * @param props a complex object containing all the properties definitions, schema, etc
 * @returns a map containing for each property an object that represents it.
 */
function extractColumnHeaders(
  props: ArrayFieldTemplateProps,
): Map<string, ColumnHeader> {
  const distinctColumns = new Map<string, ColumnHeader>();

  // get the required properties. We do not extract the conditional ones since they can be misleading for the rows that do not have such value
  const requiredFields = (props.schema.items as any).required;

  // extract all the properties (even the conditional ones) sorted depending on how they are defined in the schema
  const sortedKeys = Object.keys(props.idSchema).reduce(
    (m, e, i) => m.set(e, i),
    new Map(),
  );

  // we need to show only the columns that have at least one value.
  // the conditional ones are a problem since they can be rendered only if we have at least one row for which they need to be displayed.
  if (
    props.items.reduce((x, y) => x + React.Children.count(y.children), 0) === 0
  ) {
    // if there are no rows (children) we can display only the defined ones
    const tableRowItems = (props.schema.items as any)?.properties;
    if (tableRowItems) {
      Object.keys(tableRowItems).forEach(itemName => {
        if (!distinctColumns.has(itemName)) {
          distinctColumns.set(itemName, {
            property: itemName,
            title: tableRowItems[itemName].title,
            required: requiredFields.includes(itemName),
            description: tableRowItems[itemName].description,
          });
        }
      });
    }
  } else {
    // if there are children, we need to display the union of all the columns of all the rows
    props.items.forEach(item =>
      React.Children.forEach(item.children, child => {
        const childProperties = child.props.schema.properties;
        if (childProperties) {
          Object.keys(childProperties).forEach(property => {
            if (!distinctColumns.has(property)) {
              distinctColumns.set(property, {
                property: property,
                title: childProperties[property].title,
                required: requiredFields.includes(property),
                description: childProperties[property].description,
              });
            }
          });
        }
      }),
    );
  }

  // since the columns can be added to the map in the wrong order (when they are extracted from the children)
  // we need to sort them using the default properties sorting we extracted
  const sortedDistinctColumns = new Map(
    [...distinctColumns.entries()].sort(
      ([keyA, _], [keyB]) => sortedKeys.get(keyA) - sortedKeys.get(keyB),
    ),
  );

  return sortedDistinctColumns;
}
export const ArrayTableContent = (props: ArrayFieldTemplateProps) => {
  const classes = useStyles();
  const { maxDescriptionRows } = parseTableTemplateProperties(props);
  const rowClasses = useTruncatedStyles(maxDescriptionRows);
  const columnHeaders = extractColumnHeaders(props);

  return (
    <WbCardContent
      style={{ overflow: 'auto', background: 'white', padding: 0 }}
    >
      {props.items.length > 0 && (
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.whiteBg}>
              {Array.from(columnHeaders.values()).map((header, index) => (
                <TableCell
                  key={index}
                  className={
                    index === 0 ? classes.headerFirstCell : classes.headerCell
                  }
                >
                  <Typography
                    style={{ fontWeight: 500 }}
                    variant="caption"
                    color="primary"
                  >
                    {header.title} {header.required ? '*' : ''}
                  </Typography>
                  {header.description && (
                    <Tooltip
                      placement="bottom-start"
                      title={
                        <React.Fragment>
                          <WbMarkdownHelperText
                            helperText={header.description}
                          />
                        </React.Fragment>
                      }
                    >
                      <div>
                        <WbMarkdownHelperText
                          className={`${classes.capitalized} ${rowClasses.truncated}`}
                          helperText={header.description}
                        />
                      </div>
                    </Tooltip>
                  )}
                </TableCell>
              ))}

              <TableCell className={classes.actionsCell}>
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="caption"
                  color="secondary"
                >
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {props.items.map(item => (
              <Fragment key={item.key}>
                {React.Children.map(item.children, child =>
                  React.cloneElement(child, {
                    schema: {
                      ...child.props.schema,
                      columnHeaders: columnHeaders,
                      RowButtons: <RowButtons {...item} />,
                    },
                  }),
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      )}
    </WbCardContent>
  );
};

export const ArrayTableTemplate = (props: ArrayFieldTemplateProps) => {
  return (
    <WbWidget
      title={props.uiSchema['ui:title'] || props.title}
      cardStyle={{ borderBottom: props.items.length > 0 ? 'none' : 'auto' }}
      actions={
        props.canAdd ? (
          <WbCardActionButton
            label="Add"
            disabled={!props.canAdd}
            icon={<AddIcon />}
            onClick={props.onAddClick}
            color="secondary"
          />
        ) : undefined
      }
    >
      <ArrayTableContent {...props} />
    </WbWidget>
  );
};

export const ArrayTableTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'ArrayTableTemplate',
    component: ArrayTableTemplate as any,
  }),
);
