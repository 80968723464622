import { parseNunjucks } from '@agilelab/plugin-wb-platform-common';
import { get } from 'lodash';
import { useAction, useDataPath } from '../../context';

function not(
  func: (data: any, props: Record<string, any>) => boolean,
): (data: any, props: Record<string, any>) => boolean {
  return (data: any, props: Record<string, any>) => !func(data, props);
}

export function processComplexCondition(
  data: any,
  condition: Record<string, any>,
): boolean {
  const parsedValue = parseNunjucks(condition.value, data, true);
  if (parsedValue) {
    const parsedEqual = condition.equals?.toString();
    const parsedNotEqual = condition.notEquals?.toString();
    return (
      (!parsedEqual && !parsedNotEqual) ||
      (parsedEqual && parsedValue === parsedEqual) ||
      (parsedNotEqual && parsedValue !== parsedNotEqual)
    );
  }
  return false;
}

const useProcessMode = (_: any, props: Record<string, any>) => {
  const mode = useAction('getMode')() || '';
  return { ...props, mode };
};

const processDynamicProps = (data: any, props: Record<string, any>) => {
  const dynamicProps = ['label', 'title', 'value', 'href'].reduce(
    (acc: any, prop: string) => {
      if (props[prop]) acc[prop] = parseNunjucks(props[prop], data, true);
      return acc;
    },
    {},
  );
  return { ...props, ...dynamicProps };
};

const processPathValue = (data: any, props: Record<string, any>) => {
  const { path } = props;
  if (path !== undefined) {
    const value = path === '' ? data : get(data, path);
    return { ...props, value };
  }
  return props;
};

const processDefaultValue = (_: any, props: Record<string, any>) => {
  const { default: defaultValue } = props;
  if (defaultValue && !props.value) {
    return { ...props, value: defaultValue };
  }
  return props;
};

const processors: ((
  data: any,
  props: Record<string, any>,
) => Record<string, any>)[] = [
  useProcessMode,
  processDynamicProps,
  processPathValue,
  processDefaultValue,
];

const evaluateShowWhenExists = (
  data: any,
  props: Record<string, any>,
): boolean => {
  const { showWhenExists } = props;
  if (showWhenExists) {
    const showWhenExistsList =
      typeof showWhenExists === 'string' ? [showWhenExists] : showWhenExists;
    return showWhenExistsList.some((showWhenExistsPath: string) => {
      const obj = get(data, showWhenExistsPath);
      if (obj) {
        if (typeof obj === 'object') {
          return !Object.values(obj).every(
            val => val === undefined || val === null,
          );
        }
        return true;
      }
      return false;
    });
  }
  return true;
};

const evaluateHideWhenExists = (
  data: any,
  props: Record<string, any>,
): boolean => {
  const { hideWhenExists } = props;
  return hideWhenExists && get(data, hideWhenExists) !== undefined;
};

const evaluateShowWhen = (data: any, props: Record<string, any>): boolean => {
  const { showWhen } = props;
  if (showWhen?.value) {
    return processComplexCondition(data, showWhen);
  }
  return true;
};

const evaluateShowWhenHasValue = (
  _: any,
  props: Record<string, any>,
): boolean => {
  return !props.showWhenHasValue || props.value;
};

const hideFunctions: ((data: any, props: Record<string, any>) => boolean)[] = [
  not(evaluateShowWhenExists),
  evaluateHideWhenExists,
  not(evaluateShowWhen),
  not(evaluateShowWhenHasValue),
];

export const useCustomViewPreProcessor = (
  originalProps: Record<string, any>,
) => {
  const inputProps = { ...originalProps };
  const data = useDataPath('');
  const props = processors.reduce((properties, fn: Function) => {
    return fn(data, properties);
  }, inputProps);
  const hidden = hideFunctions.some((fn: Function) => fn(data, props));
  return { props, hidden };
};
