import { createTopic } from '../lib';
import { z } from 'zod';

const userSignedOutEventSchema = z.object({});

export type UserSignedOutEvent = z.infer<typeof userSignedOutEventSchema>;

export const userSignedOutTopic = createTopic<UserSignedOutEvent>(
  {
    namespace: 'settings',
    name: 'user-signed-out',
    description: 'User signed out',
  },
  userSignedOutEventSchema,
);
