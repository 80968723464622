import {
  CheckboxNode,
  CheckboxNodeWithParent,
  FilterFn,
  FilterState,
} from './types';

export function getSubtreeSet<T>(root: CheckboxNode<T>) {
  const res = new Set<string>();

  const traverse = (node: CheckboxNode<T>) => {
    res.add(node.id);
    node.children.forEach(c => {
      traverse(c);
    });
  };

  traverse(root);

  return res;
}

export function getLeaves<T>(
  root: CheckboxNode<T>,
  filterFn?: (node: CheckboxNode<T>) => boolean,
) {
  const res = new Set<string>();

  const traverse = (node: CheckboxNode<T>) => {
    // pick only the leaves that match the filter function
    if (!node.children.length && (filterFn ? filterFn(node) : true))
      res.add(node.id);
    node.children.forEach(c => {
      traverse(c);
    });
  };

  traverse(root);

  return res;
}

// builds a new array of roots in which every node has a reference to its parent as well
export function buildItemsWithParents<T>(items: CheckboxNode<T>[]) {
  const clone: CheckboxNodeWithParent<T>[] = structuredClone(items);

  function traverse(node: CheckboxNodeWithParent<T>) {
    node.children.forEach(c => {
      c.parent = node;
      traverse(c);
    });
  }

  clone.forEach(i => traverse(i));

  return clone;
}

// given a node with parent, expand all its ancestors to show the node
export function expandToItem<T>(
  item: CheckboxNodeWithParent<T>,
  onExpandToggle: (expandedIds: string[]) => void,
) {
  const expandedIds: string[] = [];

  let parent = item.parent;

  while (parent) {
    expandedIds.push(parent.id);
    parent = parent.parent;
  }

  onExpandToggle(expandedIds);
}

// returns the nodes to show for a flat selection preview
export function getFlatSelectionPreview<T>(
  items: CheckboxNodeWithParent<T>[],
  selected: Set<string>,
  onSelectionChange: (selection: string[]) => void,
  onExpandToggle: (expandedIds: string[]) => void,
) {
  const res: (CheckboxNodeWithParent<T> & {
    uncheck: () => void;
    expandTo: () => void;
  })[] = [];

  function traverse(node: CheckboxNodeWithParent<T>) {
    if (selected.has(node.id)) {
      res.push({
        ...node,
        uncheck: () =>
          onSelectionChange(Array.from(selected).filter(i => i !== node.id)),
        expandTo: () => expandToItem(node, onExpandToggle),
      });
      return;
    }

    node.children.forEach(c => {
      traverse(c);
    });
  }

  items.forEach(i => traverse(i));

  return res;
}

export function handleCheck<T>(
  checked: boolean,
  subtreePartiallyDisabled: boolean,
  indeterminate: boolean,
  item: CheckboxNodeWithParent<T>,
  selectedIds: string[],
  disabledSet: Set<string>,
  onItemCheck: (checkedIds: string[]) => void,
) {
  // wether the current action will remove or add elements to the currently selected elements
  let add = checked;

  // for an element with both disabled and selected elements in its subtree, selecting the whole subtree isn't possibile so we deselect it instead
  if (checked && indeterminate && subtreePartiallyDisabled) add = false;

  if (add) {
    // filters out the leaves that are disabled when selecting
    const elementsToCheck = getLeaves(
      item,
      node => !node.disabled && !disabledSet.has(node.id),
    );
    onItemCheck(
      Array.from(new Set([...selectedIds, ...Array.from(elementsToCheck)])),
    );
  } else {
    const elementsToUncheck = getLeaves(item);
    onItemCheck(selectedIds.filter(id => !elementsToUncheck.has(id)));
  }
}

function defaultFilterFn<T>(search: string, node: CheckboxNode<T>): boolean {
  return new RegExp(search, 'ig').test(node.label ?? node.id);
}
/**
 * Given a search value, nodes and a filterFn, returns a filterState,
 * containing the ids of the nodes that must be shown and the ids of the nodes that must be expanded to show the values that match the filterFn
 * @param items
 * @param filterFn
 * @returns filterState containing the ids of the nodes that must be shown and the ids of the nodes that must be expanded to show the values that match the filterFn
 */
export function filter<T>(
  search: string,
  items: CheckboxNode<T>[],
  filterFn: FilterFn<T> = defaultFilterFn,
): FilterState {
  const filteredNodes = new Set<string>();

  const expandedNodes = new Set<string>();

  function traverse(node: CheckboxNode<T>, ancestorMatch?: boolean): boolean {
    const match = filterFn(search, node);

    let childrenMatch = false;

    node.children.forEach(child => {
      const childMatch = traverse(child, match || ancestorMatch);
      childrenMatch = childrenMatch || childMatch;
    });

    // if the node has children that match in its subtree we need to expand it
    if (childrenMatch) expandedNodes.add(node.id);
    // if an anchestor matches, the node itself matches or a node from its subtree matches, we will show the node
    if (ancestorMatch || match || childrenMatch) filteredNodes.add(node.id);
    return match || childrenMatch;
  }

  items.forEach(c => traverse(c));

  return { filteredNodes, expandedNodes };
}

/**
 * Given some nodes, recursively delete all the nodes that do not match the filterFn and do not have descendants that match it
 * @param nodes
 * @param filterFn
 * @returns the remaining nodes (with their children pruned as well)
 */
export function prune<T>(
  roots: CheckboxNode<T>[],
  filterFn: (node: CheckboxNode<T>) => boolean,
): CheckboxNode<T>[] {
  function traverse(nodes: CheckboxNode<T>[]): CheckboxNode<T>[] {
    const prunedNodes = nodes
      .map(n => {
        return { ...n, children: traverse(n.children) };
      })
      .filter(n => filterFn(n) || n.children.length > 0);
    return prunedNodes;
  }

  return traverse(roots);
}
