import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-governance',
});
export const registryRouteRef = createSubRouteRef({
  id: 'wb-governance-registry',
  parent: rootRouteRef,
  path: '/registry',
});
export const addPolicyRouteRef = createSubRouteRef({
  id: 'wb-governance-add-policy',
  parent: rootRouteRef,
  path: '/registry/add-policy',
});

export const editPolicyRouteRef = createSubRouteRef({
  id: 'wb-governance-edit-policy',
  parent: rootRouteRef,
  path: '/registry/edit-policy/:policyId',
});

export const testPolicyRouteRef = createSubRouteRef({
  id: 'wb-governance-test-policy',
  parent: rootRouteRef,
  path: '/registry/test-policy/:policyId/:env',
});
export const overviewPolicyRouteRef = createSubRouteRef({
  id: 'wb-governance-overview-policy',
  parent: rootRouteRef,
  path: '/registry/overview-policy/:policyId',
});
export const resultsRouteRef = createSubRouteRef({
  id: 'wb-governance-policies-overview',
  parent: rootRouteRef,
  path: '/policies-overview',
});
export const specificResultRouteRef = createSubRouteRef({
  id: 'wb-governance-specific-result',
  parent: rootRouteRef,
  path: '/results/:resultId',
});
export const searchRouteRef = createSubRouteRef({
  id: 'wb-governance-search-results',
  parent: rootRouteRef,
  path: '/search-results',
});
export const searchPolicyRouteRef = createSubRouteRef({
  id: 'wb-governance-search-policies',
  parent: rootRouteRef,
  path: '/search-results/policies',
});
export const searchMetricRouteRef = createSubRouteRef({
  id: 'wb-governance-search-metrics',
  parent: rootRouteRef,
  path: '/search-results/metrics',
});

export const metricsRouteRef = createSubRouteRef({
  id: 'wb-governance-registry-metrics',
  parent: rootRouteRef,
  path: '/registry/metrics',
});

export const addMetricRouteRef = createSubRouteRef({
  id: 'wb-governance-add-metric',
  parent: rootRouteRef,
  path: '/registry/metrics/add-metric',
});

export const editMetricRouteRef = createSubRouteRef({
  id: 'wb-governance-edit-metric',
  parent: rootRouteRef,
  path: '/registry/edit-metric/:metricId',
});

export const testMetricRouteRef = createSubRouteRef({
  id: 'wb-governance-test-metric',
  parent: rootRouteRef,
  path: '/registry/test-metric/:metricId/:env',
});

export const overviewMetricRouteRef = createSubRouteRef({
  id: 'wb-governance-overview-metric',
  parent: rootRouteRef,
  path: '/registry/overview-metric/:metricId',
});
