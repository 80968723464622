import { makeStyles, Typography } from '@material-ui/core';
import React, { ReactNode, useMemo } from 'react';
import { DomainTree, FilterState, SearchFiltersContext } from '../../types';

import {
  BooleanFilterGroup,
  BooleanFilterGroupLoader,
  CheckboxFilterGroup,
  CheckboxFilterGroupLoader,
  CheckboxTreeFilterGroup,
  DateFilterGroup,
  getOnHoverScrollbarStyle,
  TextFilterGroup,
  WbCardActionButton,
  WbCardContent,
  WbDivider,
} from '@agilelab/plugin-wb-platform';
import {
  MARKETPLACE_FAVORITES,
  SearchFilterConfig,
  SearchFilterConfigType,
  TextFilterMatch,
} from '@agilelab/plugin-wb-search-common';
import { ErrorPanel } from '@backstage/core-components';
import clsx from 'clsx';
import { cleanFilters, unsupportedSearchFilterConfigType } from '../../utils';
import { DefaultLoader, FiltersHeaderLoader } from './SearchFiltersLoader';

export type SearchFiltersProps = {
  context: SearchFiltersContext;
  modalAnchorEl?: Element | null;
  modalHeight?: number;
  children?: ReactNode;
  hiddenFilterLabels?: string[];
  filters: FilterState | undefined;
  setFilters: React.Dispatch<React.SetStateAction<FilterState | undefined>>;
  favoriteFilter: boolean;
  setFavoriteFilter: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles(
  theme => ({
    divider: {
      marginBlock: theme.spacing(1.5),
    },
    filtersSidebar: {
      ...getOnHoverScrollbarStyle(theme),
      scrollPaddingRight: '5px',
      flex: 1,
      overflowX: 'hidden',
      overflowY: 'scroll',
    },
    filtersHeader: {
      marginBlock: theme.spacing(1),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    filtersHeaderText: {
      color: theme.palette.secondary.dark,
      fontWeight: 500,
    },
  }),
  { name: 'SearchFilters' },
);

export const SearchFilters = ({
  hiddenFilterLabels = [],
  context,
  filters = {},
  setFilters,
  setFavoriteFilter,
  favoriteFilter,
  modalAnchorEl,
  modalHeight,
  children,
}: SearchFiltersProps) => {
  const classes = useStyles();

  const error = context.availableValues.error || context.facetedValues.error;

  const loading =
    context.availableValues.loading || context.facetedValues.loading;

  const renderFilter = (filter: SearchFilterConfig): ReactNode => {
    const { type } = filter;
    switch (type) {
      case SearchFilterConfigType.DOMAIN: {
        if (loading) return <DefaultLoader />;
        const values = (context.enrichedValues[filter.label] ??
          []) as DomainTree[];
        if (!values.length) return null;
        return (
          <CheckboxTreeFilterGroup
            key={filter.label}
            modalAnchorEl={modalAnchorEl}
            label={filter.label}
            values={values}
            selected={filters[filter.label] ?? []}
            renderLabel={(node, renderingContext) => {
              // renders unique label for parent selector nodes in modal
              if (
                renderingContext === 'modal' &&
                node.data?.isParentSelectorNode
              )
                return <i>{node.label} other elements</i>;
              return node.label;
            }}
            modalStyle={{ height: modalHeight }}
            searchPlaceholder={`Search ${filter.label}`}
            onSelectionChange={selection => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: selection,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.TYPE:
      case SearchFilterConfigType.CHOICE: {
        if (loading) return <CheckboxFilterGroupLoader />;
        const values = context.enrichedValues[filter.label] ?? [];
        if (!values.length) return null;
        return (
          <CheckboxFilterGroup
            key={filter.label}
            modalAnchorEl={modalAnchorEl}
            label={filter.label}
            values={values}
            selected={filters[filter.label] ?? []}
            modalStyle={{ height: modalHeight }}
            searchPlaceholder={`Search ${filter.label}`}
            onSelectionChange={selection => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: selection,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.TEXT: {
        if (loading) return <DefaultLoader />;

        const placeholders: Record<TextFilterMatch, string> = {
          [TextFilterMatch.EXACT]: `Exact match...`,
          [TextFilterMatch.CONTAINS]: `Contains...`,
          [TextFilterMatch.BEGINS]: `Begins with...`,
          [TextFilterMatch.ENDS]: `Ends with...`,
        };
        return (
          <TextFilterGroup
            label={filter.label}
            value={filters[filter.label] ?? ''}
            placeholder={placeholders[filter.match ?? TextFilterMatch.EXACT]}
            onChange={v => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: v,
                }),
              );
            }}
          />
        );
      }
      case SearchFilterConfigType.FAVORITES:
        if (loading) return <BooleanFilterGroupLoader />;

        return (
          <BooleanFilterGroup
            label={filter.label}
            checked={favoriteFilter}
            onChange={() => setFavoriteFilter(!favoriteFilter)}
          />
        );
      case SearchFilterConfigType.CONSUMABLE:
      case SearchFilterConfigType.BOOLEAN:
        if (loading) return <BooleanFilterGroupLoader />;

        return (
          <BooleanFilterGroup
            label={filter.label}
            checked={filters[filter.label] === 'true'}
            onChange={e => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: e.target.checked ? 'true' : 'false',
                }),
              );
            }}
          />
        );
      case SearchFilterConfigType.DATE:
        if (loading) return <DefaultLoader />;

        return (
          <DateFilterGroup
            label={filter.label}
            modalAnchorEl={modalAnchorEl}
            modalStyle={{ height: modalHeight }}
            onChange={range => {
              setFilters(old =>
                cleanFilters({
                  ...old,
                  [filter.label]: {
                    startDate: range?.startDate,
                    endDate: range?.endDate,
                  },
                }),
              );
            }}
            value={{
              startDate: filters[filter.label]?.startDate
                ? new Date(filters[filter.label].startDate)
                : undefined,
              endDate: filters[filter.label]?.endDate
                ? new Date(filters[filter.label].endDate)
                : undefined,
            }}
          />
        );

      default:
        return unsupportedSearchFilterConfigType(type);
    }
  };

  const hiddenSet = useMemo(
    () => new Set(hiddenFilterLabels),
    [hiddenFilterLabels],
  );
  const shownFilters = context.config.filter(c => !hiddenSet.has(c.label));

  // check if any sidebar filter is set by the user, if it's not we won't show the "Clear Filters" button
  const clearFiltersButtonHidden = useMemo(() => {
    // check if any value aside from the hidden (the are handled outside the sidebar) is set in filters object
    for (const label of Object.keys(filters)) {
      // we do not consider the favourites filter because it's always present as false in the filters object and its value handled in a separate state
      if (!hiddenSet.has(label) && label !== MARKETPLACE_FAVORITES.label)
        return false;
    }

    if (favoriteFilter) return false;

    return true;
  }, [filters, favoriteFilter, hiddenSet]);

  if (error) return <ErrorPanel error={error} />;

  return (
    <>
      <WbCardContent className={classes.filtersHeader}>
        {loading ? (
          <FiltersHeaderLoader />
        ) : (
          <>
            <Typography className={classes.filtersHeaderText}>
              Filters
            </Typography>
            {!clearFiltersButtonHidden && (
              <WbCardActionButton
                variant="text"
                label="Clear Filters"
                onClick={() => {
                  setFavoriteFilter(false);
                  setFilters(undefined);
                }}
              />
            )}
          </>
        )}
      </WbCardContent>
      <WbCardContent className={clsx(classes.filtersSidebar)}>
        {children}
        {children && (
          <WbDivider orientation="horizontal" className={classes.divider} />
        )}
        {shownFilters.map((f, i) => {
          const filter = renderFilter(f);
          if (!filter) return null;
          return (
            <React.Fragment key={f.label}>
              {renderFilter(f)}
              {i < shownFilters.length - 1 && (
                <WbDivider
                  orientation="horizontal"
                  className={classes.divider}
                />
              )}
            </React.Fragment>
          );
        })}
      </WbCardContent>
    </>
  );
};
