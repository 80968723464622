import {
  CustomViewComponent,
  CustomViewPages,
} from '@agilelab/plugin-wb-platform';
import React from 'react';

CustomViewPages.register({
  id: 'practice_shaper_content',
  function: function Entity() {
    return (
      <>
        <CustomViewComponent type="include" id="builder_system_general" />
      </>
    );
  },
});
