import { Descriptor, SourceType } from './types';

export const CONSUMABLE_INTERFACE_FORM_CONTEXT_PATH = '__consumableInterface__';
export const ACCESS_CONTROL_CONTEXT_FORM_CONTEXT_PATH = '__context__';

const identity = <T>() => {
  return (value: T) => value;
};

const descriptorSourceType: SourceType = {
  resolveSource: (_source: string) => CONSUMABLE_INTERFACE_FORM_CONTEXT_PATH,
  mapDescriptor: identity<Descriptor>(),
};

const fieldSourceType: SourceType = {
  resolveSource: identity<string>(),
  mapDescriptor: (descriptor: Descriptor) => descriptor?.value ?? descriptor,
};

/**
 * Whenever a new source type needs to be added, this is place to start
 */
const sourceTypes: { [key: string]: SourceType } = {
  descriptor: descriptorSourceType,
  field: fieldSourceType,
};

/**
 * A function that resolves a source type into the source type implementation
 * if a sourceType is not available the resolver will return undefined
 * @param sourceType
 * @param source
 * @returns
 */
export function getSourceType(sourceType: string): SourceType | undefined {
  return sourceTypes[sourceType] ?? undefined;
}
