import { DeploymentUnitStatus } from '@agilelab/plugin-wb-builder-common';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { Box, Typography, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { VersionCaption } from '../ReleaseDeploymentStatus';

interface Props {
  status?: DeploymentUnitStatus;
  version?: string;
}

const useStyles = makeStyles(theme => ({
  card: {
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: 'white',
    width: 'max-content',
    cursor: 'default',
  },
  deployed: {
    background: theme.palette.success.main,
    color: theme.palette.black,
  },
  notDeployed: {
    background: theme.palette.blueGrey[200],
    color: theme.palette.black,
  },
  partiallyDeployed: {
    color: theme.palette.black,
    border: `1px solid ${theme.palette.success.main}`,
  },
  provisioningInProgress: {
    background: theme.palette.accent.main,
    color: theme.palette.white,
  },
  corrupt: {
    background: theme.palette.error.main,
    color: theme.palette.black,
  },
  bold: {
    fontWeight: 500,
  },
}));

export const DeploymentPreviewChip: React.FC<Props> = ({ status, version }) => {
  const classes = useStyles();

  switch (status) {
    case 'DEPLOYED':
      return (
        <Box className={clsx(classes.card, classes.deployed)}>
          <Typography className={classes.bold} variant="caption">
            {snakeCaseToTitleCase(status)}
          </Typography>
          <VersionCaption version={version} />
        </Box>
      );
    case 'NOT_DEPLOYED':
      return (
        <Box className={clsx(classes.card, classes.notDeployed)}>
          <Typography className={classes.bold} variant="caption">
            {snakeCaseToTitleCase(status)}
          </Typography>
        </Box>
      );
    case 'PARTIALLY_DEPLOYED':
      return (
        <Box className={clsx(classes.card, classes.partiallyDeployed)}>
          <Typography className={classes.bold} variant="caption">
            {snakeCaseToTitleCase(status)}
          </Typography>
          <VersionCaption version={version} />
        </Box>
      );
    case 'PROVISIONING_IN_PROGRESS':
      return (
        <Box className={clsx(classes.card, classes.provisioningInProgress)}>
          <Typography className={classes.bold} variant="caption">
            {snakeCaseToTitleCase(status)}
          </Typography>
          <VersionCaption version={version} />
        </Box>
      );
    case 'CORRUPT':
      return (
        <Box className={clsx(classes.card, classes.corrupt)}>
          <Typography className={classes.bold} variant="caption">
            {snakeCaseToTitleCase(status)}
          </Typography>
          <VersionCaption version={version} />
        </Box>
      );
    default:
      return <></>;
  }
};
