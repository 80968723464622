import { WbAutocomplete } from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

type DataProductFieldProps = {
  idSchema: string;
  domain: string | undefined;
  dataProduct: string | undefined;
  setDataProduct: React.Dispatch<React.SetStateAction<string | undefined>>;
};

export const DataProductField = ({
  idSchema,
  domain,
  dataProduct,
  setDataProduct,
}: DataProductFieldProps) => {
  const catalogApi = useApi(catalogApiRef);
  const [dataProductState, setDataProductState] = useState<string | undefined>(
    undefined,
  );
  const { value: dataProducts, loading } = useAsync(async () => {
    if (!domain) return undefined;

    return catalogApi.getEntities({
      filter: { kind: ['System'], 'spec.domain': domain },
    });
  }, [domain]);

  useEffect(() => {
    setDataProductState(dataProduct);
  }, [dataProduct]);

  const dataProductRef = dataProducts?.items.map(dataProductEntity =>
    humanizeEntityRef(dataProductEntity),
  );
  const onSelect = useCallback(
    (_: any, value: string | null) => {
      setDataProduct(value || undefined);
    },
    [setDataProduct],
  );

  return (
    <WbAutocomplete
      id={`${idSchema}-dataproduct`}
      disabled={!domain}
      value={dataProductState || ''}
      loading={loading}
      onChange={onSelect}
      options={dataProductRef || []}
      autoSelect
      freeSolo={false}
      required
      helperText="The Data Product that the Output Port belongs to"
      label="Data Product"
    />
  );
};
