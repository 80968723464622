import {
  WbCardActionButton,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { IconButton, Theme, Tooltip } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDropDown';
import RemoveIcon from '@material-ui/icons/Delete';
import { createStyles, makeStyles } from '@material-ui/styles';
import { ArrayFieldTemplateProps } from '@rjsf/core';
import React from 'react';
import AddIcon from '@material-ui/icons/AddCircle';
import { isHidden } from '../../utils';

// TODO manuel: unify with wb-marketplace stylings, once we have a common core plugin
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.text.secondary,
      textTransform: 'uppercase',
      fontSize: theme.typography.fontSize * 1.2,
      fontWeight: 'bold',
      letterSpacing: 0.5,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    text: {
      overflow: 'hidden',
      fontSize: theme.typography.fontSize * 1.1,
      wordBreak: 'break-word',
    },
  }),
);

const DefaultArrayItem = (props: any) => {
  return (
    <div
      key={props.key}
      style={{ display: 'flex', width: '100%', background: 'white' }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid #cbcbcb',
          padding: '12px 16px',
          flex: '1',
        }}
      >
        {props.children}
      </div>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 20,
          paddingRight: 20,
          borderLeft: 'none',
          background: 'rgb(238 238 238 / 30%)',
        }}
      >
        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveUp}
            onClick={props.onReorderClick(props.index, props.index - 1)}
            style={{ padding: 0 }}
          >
            <Tooltip title="Move Up" placement="bottom-start">
              <ArrowUpwardIcon fontSize="medium" />
            </Tooltip>
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <IconButton
            color="secondary"
            size="small"
            tabIndex={-1}
            disabled={props.disabled || props.readonly || !props.hasMoveDown}
            onClick={props.onReorderClick(props.index, props.index + 1)}
            style={{ padding: 0 }}
          >
            <Tooltip title="Move Down" placement="bottom-start">
              <ArrowDownwardIcon fontSize="medium" />
            </Tooltip>
          </IconButton>
        )}

        {(props.hasMoveUp || props.hasMoveDown) && (
          <div
            style={{
              height: '20px',
              borderRight: '1px solid #cbcbcb',
              margin: '0px 5px',
            }}
          />
        )}

        {props.hasRemove && (
          <IconButton
            size="small"
            tabIndex={-1}
            color="secondary"
            disabled={props.disabled || props.readonly}
            onClick={props.onDropIndexClick(props.index)}
          >
            <Tooltip title="Remove Item" placement="bottom-start">
              <RemoveIcon fontSize="small" />
            </Tooltip>
          </IconButton>
        )}
      </div>
    </div>
  );
};

export function DefaultArrayFieldTemplate(props: ArrayFieldTemplateProps) {
  const classes = useStyles();
  const { DescriptionField } = props;

  return (
    <WbWidget
      title={props.uiSchema['ui:title'] || props.title}
      cardStyle={{ display: isHidden(props.uiSchema) ? 'none' : undefined }}
      actions={
        props.canAdd ? (
          <WbCardActionButton
            label="Add"
            onClick={props.onAddClick}
            icon={<AddIcon />}
            disabled={props.disabled || props.readonly}
            color="secondary"
          />
        ) : undefined
      }
    >
      <WbCardContent
        style={{
          background: 'rgb(238 238 238 / 30%)',
        }}
      >
        <div key={`array-item-list-${props.idSchema.$id}`}>
          {(props.uiSchema['ui:description'] || props.schema.description) && (
            <div>
              <DescriptionField
                id={`${props.idSchema.$id}-array-description`}
                description={
                  props.uiSchema['ui:description'] || props.schema.description
                }
              />
            </div>
          )}

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 15,
              marginTop: 10,
            }}
          >
            {props.items &&
              props.items.map((element: any) =>
                // eslint-disable-next-line new-cap
                DefaultArrayItem({ ...element, classes }),
              )}
          </div>
        </div>
      </WbCardContent>
    </WbWidget>
  );
}
