import { makeStyles, Theme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { EditorInfo } from './EditorInfo';
import {
  MonacoEditor,
  WbEditor,
  WbMonacoEditor,
  WbWidget,
} from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
  },
  edit: {
    boxShadow: `0 0 0 1px ${theme.palette.primary.main}`,
  },
  readOnly: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(2),
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    minHeight: '56px',
    color: theme.palette.text.primary,
  },
  editor: {
    color: 'rgb(144, 164, 174)',
    background: theme.palette.background.paper,
    fontFamily: 'monospace',
    fontSize: '0.75em',
    lineHeight: '1.5em',
    tabSize: 2,
    '& .ace_marker-layer .ace_active-line': {
      background: theme.palette.bkg.primary,
      opacity: 0.6,
    },
  },
  hideCursor: {
    '& .ace_cursor-layer': {
      display: 'none',
    },
  },
  editButtons: {
    position: 'relative',
    top: '60px',
    zIndex: 1,
    paddingRight: '8px',
  },
}));

interface EditorProps {
  name?: string;
  offset?: boolean;
  content: string;
  onUpdate?: (code?: string) => void;
  editable?: boolean;
  highlightLines?: Array<number>;
  id?: string;
  saving?: boolean;
  handleSave?: () => void;
  showSaveButton?: boolean;
  parser?: string;
}
export const Editor: React.FC<EditorProps> = props => {
  const classes = useStyles();
  const [monacoEditor, setMonacoEditor] = useState<MonacoEditor>();

  useEffect(() => {
    if (monacoEditor && props.highlightLines) {
      const editor = new WbMonacoEditor(monacoEditor);
      editor.highlightLines(props.highlightLines);
      editor.goToLine(props.highlightLines.at(0));
    }
  }, [props.highlightLines, monacoEditor]);

  return (
    <WbWidget
      cardStyle={{ height: '100%', overflow: 'hidden' }}
      title={props.name ?? ''}
      actions={
        <EditorInfo
          editable={props.editable}
          clipboardText={props.content}
          className={props.offset ? classes.editButtons : undefined}
          loading={props.saving}
          handleSave={props.handleSave}
          showSaveButton={props.showSaveButton}
        />
      }
    >
      <WbEditor
        value={props.content}
        options={{ readOnly: !props.editable, minimap: { enabled: false } }}
        onChange={props.onUpdate}
        height="600px"
        onMount={setMonacoEditor}
        language={props.parser}
      />
    </WbWidget>
  );
};
