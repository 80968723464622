import { useLazyQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import {
  TableRow,
  TableCell,
  Collapse,
  CircularProgress,
  List,
  useTheme,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { TaskListItem } from './TaskListItem/TaskListItem';
import { Test } from '../types';
import {
  GOVERNANCE_ENTITIES,
  GovernanceEntitiesQueryType,
} from '../../../graphql/governanceEntities';
import { useEditorPageContext } from '../context/useEditorPageContext';

interface Props {
  test: Test;
}

export const TaskAccordion: React.FC<Props> = ({ test }) => {
  const theme = useTheme();
  const { selectedTestRow } = useEditorPageContext();
  const open = selectedTestRow === test.id;

  const [fetchPolicies, { loading, error, data }] =
    useLazyQuery<GovernanceEntitiesQueryType>(GOVERNANCE_ENTITIES, {
      variables: {
        ids: test.tasks
          .filter(t => !!t.governanceEntityId)
          .map(p => p.governanceEntityId),
      },
    });

  useEffect(() => {
    if (open && !data) {
      fetchPolicies();
    }
  }, [fetchPolicies, data, open]);

  return (
    <TableRow>
      <TableCell
        style={{
          paddingBottom: 0,
          paddingTop: 0,
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
        }}
        colSpan={4}
      >
        <Collapse in={open} timeout="auto" unmountOnExit>
          {loading && (
            <div>
              <CircularProgress />
            </div>
          )}
          {error && <ErrorPanel error={error} />}
          <List component="nav" aria-label="detail list">
            {!loading &&
              !error &&
              test.tasks.map((t, i) => (
                <TaskListItem
                  key={`${t.id}_${i}`}
                  task={t}
                  governanceEntities={data?.cgp_governance_entity || []}
                />
              ))}
          </List>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
