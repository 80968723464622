import { Box, Button, capitalize } from '@material-ui/core';
import { useNotification } from './useNotificationProvider';
import React from 'react';
import { SelectFilter } from '@agilelab/plugin-wb-platform';
import {
  RequestType,
  StatusType,
} from '@agilelab/plugin-wb-notification-common';
import { NotificationType } from '@agilelab/plugin-wb-platform-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const NotificationsFilters: React.FC = () => {
  const configApi = useApi(configApiRef);
  const {
    kind,
    setKind,
    readStatus,
    setReadStatus,
    requestStatus,
    setRequestStatus,
    clearFilters,
  } = useNotification();

  const revokeRequestEnabled =
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.accessControl.enableRevokeRequest',
    ) ?? false;

  return (
    <Box display="flex">
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <SelectFilter<StatusType>
          field="Status"
          options={[StatusType.Read, StatusType.Unread]}
          renderOption={o => capitalize(o)}
          renderValue={v => capitalize(v)}
          onChange={el => setReadStatus(el)}
          value={readStatus as StatusType}
        />
        <SelectFilter<NotificationType>
          field="Kind"
          options={[
            NotificationType.ACCESS_REQUEST,
            NotificationType.ACCESS_RESPONSE,
            NotificationType.INFORMATIVE,
            NotificationType.QUESTION,
            ...(revokeRequestEnabled
              ? [
                  NotificationType.REVOKE_REQUEST,
                  NotificationType.REVOKE_RESPONSE,
                ]
              : []),
          ]}
          renderOption={o => capitalize(o)}
          renderValue={v => capitalize(v)}
          onChange={el => setKind(el)}
          value={kind}
        />

        {kind === NotificationType.ACCESS_REQUEST && (
          <>
            <SelectFilter<RequestType>
              field="Request"
              options={[RequestType.Accepted, RequestType.Rejected]}
              renderOption={o => capitalize(o)}
              renderValue={v => capitalize(v)}
              onChange={el => setRequestStatus(el)}
              value={requestStatus as RequestType}
            />
          </>
        )}
        {kind === NotificationType.QUESTION && (
          <>
            <SelectFilter<RequestType>
              field="Request"
              options={[RequestType.Answered, RequestType.ToAnswer]}
              renderOption={o => capitalize(o)}
              renderValue={v => capitalize(v)}
              onChange={el => setRequestStatus(el)}
              value={requestStatus as RequestType}
            />
          </>
        )}
      </Box>
      <Box
        style={{
          flex: 1,
          minWidth: '100px',
          marginLeft: '20px',
          justifyContent: 'flex-end',
          display: 'flex',
          alignItems: 'baseline',
        }}
      >
        <Button
          style={{ marginTop: '8px' }}
          variant="outlined"
          size="small"
          color="primary"
          onClick={clearFilters}
        >
          Clear filters
        </Button>
      </Box>
    </Box>
  );
};
