export const parseInfrastructureTemplateIdFromPreprocessing = (
  script: string[] | undefined,
): string | undefined => {
  if (!script) return undefined;
  const regex = /(?<=component\.infrastructureTemplateId == ").*?(?=")/m;

  return script[0].match(regex)?.[0];
};

export const parseUseCaseTemplateIdFromPreprocessing = (
  script: string[] | undefined,
): string | undefined => {
  if (!script) return undefined;
  const regex = /(?<=component\.useCaseTemplateId == ").*?(?=")/m;

  return script[0].match(regex)?.[0];
};
