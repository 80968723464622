import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  capitalize,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { CodeSnippet } from '../CodeSnippet';
import FileCopy from '@material-ui/icons/FileCopy';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import { BackstageTheme } from '@backstage/theme';
import { CoordinatorError } from '@agilelab/plugin-wb-platform-common';
import { WbCard, WbCardContent } from '../WbCard';
import { WbTabs } from '../WbTabs';
import { WbErrorWidget } from '../WbWidget';
import clsx from 'clsx';

type Props = DialogProps & {
  errorInfo: CoordinatorError;
};

enum Tab {
  Overview,
  Input,
}

const useStyles = makeStyles((theme: BackstageTheme) => ({
  paper: {
    width: '70%',
    background: theme.palette.banner.error,
  },
  paperHeight: {
    height: '80%',
  },
  titleIcon: {
    top: '0.2em',
    position: 'relative',
    marginRight: theme.spacing(1.5),
    color: theme.palette.error.main,
    fontSize: '22px',
  },
  title: {
    color: theme.palette.banner.text,
    fontSize: theme.typography.body1.fontSize,
  },
  tabsBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'start',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    fontWeight: 700,
    width: '100%',
    color: theme.palette.banner.text,
  },
  statusIcon: {
    color: 'inherit',
  },
  textColor: {
    color: theme.palette.banner.text,
  },
  pl: {
    paddingLeft: theme.spacing(2.5),
  },
  bg: {
    background: theme.palette.banner.error,
  },
  selected: {
    color: theme.palette.error.light,
  },
}));

export function CustomAlertDetails(props: Props): JSX.Element {
  const { open, errorInfo, onClose } = props;
  const theme = useTheme();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Overview);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={false}
      PaperProps={{
        className: clsx(
          classes.paper,
          !!errorInfo.input && classes.paperHeight,
        ),
      }}
    >
      <DialogTitle style={{ padding: '8px 16px' }}>
        <ErrorIcon className={classes.titleIcon} />
        <span className={classes.title}>
          {capitalize(errorInfo.message || '')}
        </span>
      </DialogTitle>
      <DialogContent style={{ padding: '4px 16px 16px' }}>
        <WbCard
          title="Details"
          toolsComponents={
            errorInfo.input ? (
              <Box className={classes.tabsBox}>
                <WbTabs
                  tabs={[
                    {
                      name: 'Overview',
                      action: () => {
                        setSelectedTab(Tab.Overview);
                      },
                      selected: selectedTab === Tab.Overview,
                    },
                    {
                      name: 'Input',
                      action: () => {
                        setSelectedTab(Tab.Input);
                      },
                      selected: selectedTab === Tab.Input,
                    },
                  ]}
                  containerClassName={classes.bg}
                  selectedClassName={classes.selected}
                />
              </Box>
            ) : undefined
          }
          statusComponent={
            <div className={classes.statusContainer}>
              <p>Error code: {errorInfo.code}</p>
              <IconButton
                title="Copy to clipboard"
                onClick={() => navigator.clipboard.writeText(errorInfo.code)}
                className={classes.statusIcon}
              >
                <FileCopy />
              </IconButton>
            </div>
          }
          cardClassName={classes.textColor}
          cardStyle={{ height: '100%' }}
          headerStyle={{
            backgroundColor: theme.palette.softened.error,
          }}
          typographyProps={{ className: classes.textColor }}
        >
          <WbCardContent style={{ overflow: 'auto' }}>
            {selectedTab === Tab.Overview ? (
              <>
                <WbErrorWidget title="Problems">
                  <ul style={{ margin: '0px', paddingLeft: '30px' }}>
                    {errorInfo.moreInfo?.problems.map((e, i) => (
                      <li key={i}>
                        <p>{e}</p>
                      </li>
                    ))}
                  </ul>
                </WbErrorWidget>
                <WbErrorWidget
                  title="Solutions"
                  cardStyle={{ marginTop: '12px' }}
                >
                  {errorInfo.moreInfo?.solutions.length > 0 ? (
                    <ul style={{ margin: '0px', paddingLeft: '30px' }}>
                      {errorInfo.moreInfo?.solutions.map((e, i) => (
                        <li key={i}>
                          <p>{e}</p>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className={classes.pl}>No solutions available</p>
                  )}
                </WbErrorWidget>
              </>
            ) : (
              !!errorInfo.input && (
                <>
                  {!!errorInfo.inputErrorField && (
                    <WbErrorWidget
                      title="Error field"
                      cardStyle={{ marginBottom: '12px' }}
                    >
                      <WbCardContent>{errorInfo.inputErrorField}</WbCardContent>
                    </WbErrorWidget>
                  )}
                  <WbErrorWidget title="Descriptor">
                    <CodeSnippet
                      language="yaml"
                      text={errorInfo.input}
                      showLineNumbers
                    />
                  </WbErrorWidget>
                </>
              )
            )}
          </WbCardContent>
        </WbCard>
      </DialogContent>
    </Dialog>
  );
}
