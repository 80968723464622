import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useCallback } from 'react';
import { GeneratorSystem, Topic } from '@agilelab/plugin-wb-events-common';
import { eventsApiRef } from '../plugin';

/**
 * Check that every value in payload is not undefined or empty string
 * @param obj
 */
const isObjectValuesValid = (obj: any) => {
  if (!obj || typeof obj !== 'object') return false;
  return Object.values(obj).every(value => value !== undefined && value !== '');
};

/**
 * Parameters required for publishing an event.
 *
 * @template TPayload - The type of the payload for the event.
 */
interface PublishEventParams {
  /**
   * The system that generates the event.
   */
  generatorSystem: GeneratorSystem;
  keyMarketplace?: string;
  keyBuilderCatalog?: string;
}

/**
 * Custom hook to publish an event.
 *
 * @param {PublishEventParams} params - The parameters required to publish an event.
 * @returns {void}
 */
export const usePublishEvent = ({
  generatorSystem,
  keyBuilderCatalog,
  keyMarketplace,
}: PublishEventParams): {
  publish: <TPayload>(
    topic: Topic<TPayload>,
    payload: TPayload,
  ) => Promise<void>;
} => {
  const identityApi = useApi(identityApiRef);
  const eventsApi = useApi(eventsApiRef);

  const publish = useCallback(
    async <TPayload>(topic: Topic<TPayload>, payload: TPayload) => {
      const identity = await identityApi.getBackstageIdentity();
      const originatorUri = identity.userEntityRef;

      if (isObjectValuesValid(payload)) {
        await eventsApi.publish(
          topic.createEvent(payload, {
            generatorSystem,
            originatorUri,
            keyBuilderCatalog,
            keyMarketplace,
          }),
          await identityApi.getCredentials(),
        );
      }
    },
    [
      identityApi,
      eventsApi,
      generatorSystem,
      keyBuilderCatalog,
      keyMarketplace,
    ],
  );

  return { publish };
};
