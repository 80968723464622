import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const wmExternalResourcesPlugin = createPlugin({
  id: 'wb-external-resources',
  routes: {
    root: rootRouteRef,
  },
});

/**
 * The Router and main entrypoint to the plugin.
 *
 * @public
 */
export const PackageListPage = wmExternalResourcesPlugin.provide(
  createRoutableExtension({
    name: 'PackageListPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
