import {
  Box,
  CircularProgress,
  InputAdornment,
  Popover,
  TextField,
  Theme,
  Typography,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CheckIcon from '@material-ui/icons/Check';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { WbSearch } from '../WbSearch';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 8,
  },
  bold: {
    fontWeight: 500,
  },
  textField: {
    paddingBottom: '3px',
    cursor: 'pointer',
    fontSize: 14,
  },
  select: {
    cursor: 'pointer',
    width: 'fit-content',
    color: theme.palette.primary.main,
  },
  option: {
    gap: '4px',
    cursor: 'pointer',
    padding: '8px 16px',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionLabel: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  selected: {
    background: theme.palette.bkg.primary,
  },
}));

interface Props<T> {
  field?: string;
  defaultValue?: T | undefined;
  onChange: (option: T) => void;
  disabled?: boolean;
  getOptions: () => Promise<T[]>;
  renderOption: (option: T) => JSX.Element | React.ReactNode;
  renderValue: (option: T) => JSX.Element | React.ReactNode;
  onSearch?: (searchValue: string, options: T[]) => T[];
  closeOnSelect?: boolean;
}

export const WbAsyncSelect = <T,>({
  field,
  defaultValue,
  getOptions,
  onSearch,
  disabled,
  renderOption,
  renderValue,
  onChange,
  closeOnSelect,
}: Props<T>) => {
  const classes = useStyles();
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [loadedOptions, setLoadedOptions] = React.useState<T[]>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [options, setOptions] = React.useState<T[]>([]);
  const [val, setValue] = useState<T | undefined>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const DEFAULT_INPUT_WIDTH = 50;

  const [inputWidth, setInputWidth] = useState(DEFAULT_INPUT_WIDTH);

  useEffect(() => {
    if ((val as any)?.length * 8 > DEFAULT_INPUT_WIDTH) {
      const res = ((val as any)?.length + 1) * 8;
      setInputWidth(res);
    } else {
      setInputWidth(DEFAULT_INPUT_WIDTH);
    }
  }, [val]);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue, setValue]);

  async function loadData() {
    try {
      setLoading(true);
      const data = await getOptions();
      setLoadedOptions(data);
      setOptions(data);
    } finally {
      setLoading(false);
    }
  }

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    loadData();
  };

  const search = (s: string) => {
    setSearchValue(s);
    if (onSearch) setOptions(onSearch(s, loadedOptions));
  };

  const closeMenu = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setValue(val);
      search('');
    }, 500);
  };

  const onSelect = (option: T) => {
    onChange(option);
    setValue(option);
    if (closeOnSelect) {
      setAnchorEl(null);
    }
  };

  return (
    <>
      <Box className={classes.container} onClick={openMenu}>
        {field && <Typography className={classes.bold}>{field}:</Typography>}

        <TextField
          disabled={disabled}
          inputProps={{
            className: classes.textField,
            style: { width: `${inputWidth}px` },
          }}
          InputProps={{
            className: classes.select,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon
                  style={{ color: theme.palette.secondary.main }}
                />
              </InputAdornment>
            ),
          }}
          value={renderValue(val!)}
          size="small"
        />
      </Box>
      <Popover
        disableRestoreFocus
        open={open}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box
          style={{ width: '280px', background: 'white', overflow: 'hidden' }}
        >
          {onSearch && (
            <Box
              style={{
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
                padding: '8px 16px',
              }}
            >
              <WbSearch value={searchValue} onChange={search} />
            </Box>
          )}

          {loading ? (
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <CircularProgress size="2rem" />
            </Box>
          ) : (
            <>
              <Box
                style={{
                  maxHeight: '200px',
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {options.length <= 0 && (
                  <Typography style={{ padding: '16px 0px' }}>
                    No options
                  </Typography>
                )}
                {options.map((option, index) => (
                  <Box
                    className={clsx(
                      classes.option,
                      isEqual(val, option) && classes.selected,
                    )}
                    key={index}
                    onClick={() => {
                      onSelect(option);
                    }}
                  >
                    <Box className={classes.optionLabel}>
                      {renderOption(option)}
                    </Box>
                    {isEqual(val, option) && (
                      <CheckIcon color="primary" fontSize="small" />
                    )}
                  </Box>
                ))}
              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
};
