import Icon from '@material-ui/icons/Notifications';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, IconButton, IconButtonProps } from '@material-ui/core';
import { useNotification } from './useNotificationProvider';

const NotificationIconContent = (props: IconButtonProps) => {
  const navigate = useNavigate();
  const { unreadNotificationCount } = useNotification();

  return (
    <IconButton
      aria-label="notifications"
      {...props}
      onClick={() => {
        navigate('/notifications');
      }}
    >
      <Badge badgeContent={unreadNotificationCount} color="secondary">
        <Icon fontSize="large" />
      </Badge>
    </IconButton>
  );
};

export const NotificationsIcon = (props: IconButtonProps) => {
  return <NotificationIconContent {...props} />;
};
