import {
  WbTableFilters,
  AsyncEnumFilter,
  ProviderFilters,
  SearchFilter,
} from '@agilelab/plugin-wb-platform';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi, identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useSystemContext from './SystemProvider';
import { WitboostDomain } from '@agilelab/plugin-wb-builder-common';

export interface SystemFilters extends ProviderFilters {
  domain?: { name: string; ref: string }[];
  type?: { name: string; ref: string }[];
}

enum AvailableSystemFilters {
  search = 'search',
  type = 'type',
  domain = 'domain',
}

interface FilterProps {
  value: { name: string; ref: string }[];
  changeFilters: <K extends keyof SystemFilters>(
    key: K,
    value: SystemFilters[K],
  ) => void;
  getOptions: () => Promise<{ name: string; ref: string }[]>;
}

const DomainFilter: React.FC<FilterProps> = ({
  value,
  changeFilters,
  getOptions,
}) => {
  return (
    <AsyncEnumFilter<{ name: string; ref: string }>
      field="Domain"
      value={value ?? undefined}
      onChange={domain => {
        changeFilters('domain', domain);
      }}
      renderOption={o => o.name}
      renderValue={v => v.name}
      onSearch={(searchValue, options) =>
        options.filter(option =>
          new RegExp(searchValue, 'ig').test(option.name),
        )
      }
      getOptions={getOptions}
    />
  );
};

const TypeFilter: React.FC<FilterProps> = ({
  value,
  changeFilters,
  getOptions,
}) => {
  return (
    <AsyncEnumFilter<{ name: string; ref: string }>
      field="Type"
      value={value}
      onChange={type => {
        changeFilters('type', type);
      }}
      renderOption={o => o.name}
      renderValue={v => v.name}
      onSearch={(searchValue, options) =>
        options.filter(option =>
          new RegExp(searchValue, 'ig').test(option.name),
        )
      }
      getOptions={getOptions}
    />
  );
};

export const SystemFiltersSection = () => {
  const { filters, changeFilters, resetFilters, templateKind } =
    useSystemContext();
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);

  const [_, fetchAvailableResourceTypes] = useAsyncFn(async (): Promise<
    { name: string; ref: string }[]
  > => {
    const { resourceTypes } = await practiceShaperApi.getResourceTypes(
      {},
      await identityApi.getCredentials(),
    );
    return resourceTypes.map(rt => ({ name: rt.displayName, ref: rt.name }));
  }, []);

  const fetchAvailableDomains = useCallback(async () => {
    const response = await catalogApi.getEntities({
      filter: { kind: 'domain' },
    });
    return response.items.map(item => {
      const ref = stringifyEntityRef({
        kind: 'domain',
        name: item.metadata.name,
        namespace: item.metadata.namespace,
      });
      const domain = item as WitboostDomain;
      return { name: domain.spec.mesh.name, ref };
    });
  }, [catalogApi]);

  const filterElement = (key: string) => {
    if (key === AvailableSystemFilters.search)
      return (
        <SearchFilter
          key={key}
          value={filters.search ?? ''}
          changeFilters={changeFilters}
        />
      );
    if (key === AvailableSystemFilters.domain)
      return (
        <DomainFilter
          key={key}
          value={filters.domain ?? []}
          changeFilters={changeFilters}
          getOptions={fetchAvailableDomains}
        />
      );

    if (key === AvailableSystemFilters.type)
      return (
        <TypeFilter
          key={key}
          value={filters.type ?? []}
          changeFilters={changeFilters}
          getOptions={fetchAvailableResourceTypes}
        />
      );

    return <></>;
  };

  return (
    <WbTableFilters onClear={resetFilters}>
      {templateKind?.userFilters?.map(filter => filterElement(filter))}
    </WbTableFilters>
  );
};
