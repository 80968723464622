import { ApiHolder } from '@backstage/core-plugin-api';
import { JsonObject, JsonValue } from '@backstage/types';
import { CustomFieldValidator } from '../../extensions';
import { isObject } from './createValidator';

/**
 * This method is designed to handle asynchronous validation methods to give in input to the MultiStepJsonForm component
 * @param rootSchema
 * @param validators
 * @param context
 * @returns
 */
export const createValidatorAsync = (
  rootSchema: JsonObject,
  validators: Record<string, undefined | CustomFieldValidator<unknown>>,
  context: {
    apiHolder: ApiHolder;
  },
): ((formData: JsonObject) => Promise<any>) => {
  function validate(schema: JsonObject, formData: JsonObject): any {
    const schemaProps = schema.properties;
    const customObject = schema.type === 'object' && schemaProps === undefined;

    if (!isObject(schemaProps) && !customObject) {
      return undefined;
    }

    if (schemaProps) {
      for (const [key, propData] of Object.entries(formData)) {
        if (isObject(propData)) {
          const propSchemaProps = schemaProps[key];
          if (isObject(propSchemaProps)) {
            return validate(propSchemaProps, propData as JsonObject);
          }
        } else {
          const propSchema = schemaProps[key];
          const fieldName =
            isObject(propSchema) && (propSchema['ui:field'] as string);
          if (fieldName && typeof validators[fieldName] === 'function') {
            return validators[fieldName]!(
              propData as JsonValue,
              undefined,
              context,
              propSchema,
              formData,
              key,
            );
          }
        }
      }
    } else if (customObject) {
      const fieldName = schema['ui:field'] as string;
      if (fieldName && typeof validators[fieldName] === 'function') {
        return validators[fieldName]!(formData, undefined, context, null, null);
      }
    }

    return undefined;
  }

  return (formData: JsonObject) => {
    return validate(rootSchema, formData);
  };
};
