import { DependencyGraphTypes } from '@backstage/core-components';
import { useApp } from '@backstage/core-plugin-api';
import { humanizeEntityRef } from '@backstage/plugin-catalog-react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import React, {
  MouseEventHandler,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import WorkIcon from '@material-ui/icons/Work';

import clsx from 'clsx';

export function EntityKindIcon({
  kind,
  ...props
}: {
  kind: string;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
  className?: string;
}) {
  const app = useApp();
  const Icon =
    app.getSystemIcon(`kind:${kind.toLocaleLowerCase('en-US')}`) ?? WorkIcon;
  return <Icon {...props} />;
}

export type EntityNodeData = {
  /**
   * Name of the entity.
   */
  name: string;
  /**
   * Optional kind of the entity.
   */
  kind?: string;
  /**
   * Optional title of the entity.
   */
  title?: string;
  /**
   * Namespace of the entity.
   */
  namespace: string;
  /**
   * Whether the entity is focused, optional, defaults to false. Focused
   * entities are highlighted in the graph.
   */
  focused?: boolean;
  /**
   * Optional click handler.
   */
  onClick?: MouseEventHandler<unknown>;
};

const useStyles = makeStyles(
  (theme: Theme) => ({
    node: {
      fill: theme.palette.relationsGraph.resource,
      stroke: theme.palette.relationsGraph.resource,

      '&.system': {
        fill: theme.palette.relationsGraph.system,
        stroke: theme.palette.relationsGraph.system,
      },
      '&.component': {
        fill: theme.palette.relationsGraph.component,
        stroke: theme.palette.relationsGraph.component,
      },
      '&.domain': {
        fill: theme.palette.relationsGraph.domain,
        stroke: theme.palette.relationsGraph.domain,
      },
      '&.user': {
        fill: theme.palette.relationsGraph.user,
        stroke: theme.palette.relationsGraph.user,
      },
    },
    text: {
      fill: theme.palette.white,
      '&.focused': {
        fontWeight: 'bold',
      },
    },
    clickable: {
      cursor: 'pointer',
    },
  }),
  { name: 'PluginCatalogGraphCustomNode' },
);

export function CatalogGraphRenderNode({
  node: { id, kind, namespace, name, focused, title, onClick },
}: DependencyGraphTypes.RenderNodeProps<EntityNodeData>) {
  const classes = useStyles();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const idRef = useRef<SVGTextElement | null>(null);

  useLayoutEffect(() => {
    // set the width to the length of the ID
    if (idRef.current) {
      let { height: renderedHeight, width: renderedWidth } =
        idRef.current.getBBox();
      renderedHeight = Math.round(renderedHeight);
      renderedWidth = Math.round(renderedWidth);

      if (renderedHeight !== height || renderedWidth !== width) {
        setWidth(renderedWidth);
        setHeight(renderedHeight);
      }
    }
  }, [width, height]);

  const padding = 10;
  const iconSize = height;
  const paddedIconWidth = kind ? iconSize + padding : 0;
  const paddedWidth = paddedIconWidth + width + padding * 2;
  const paddedHeight = height + padding * 2;

  const displayTitle =
    title ??
    (kind && name && namespace
      ? humanizeEntityRef({ kind, name, namespace })
      : id);

  return (
    <g onClick={onClick} className={clsx(onClick && classes.clickable)}>
      <rect
        className={clsx(classes.node, kind?.toLowerCase())}
        width={paddedWidth}
        height={paddedHeight}
        rx={10}
      />
      {kind && (
        <EntityKindIcon
          kind={kind}
          y={padding}
          x={padding}
          width={iconSize}
          height={iconSize}
          className={clsx(
            classes.text,
            focused && 'focused',
            kind?.toLowerCase(),
          )}
        />
      )}
      <text
        ref={idRef}
        className={clsx(
          classes.text,
          focused && 'focused',
          kind?.toLowerCase(),
        )}
        y={paddedHeight / 2}
        x={paddedIconWidth + (width + padding * 2) / 2}
        textAnchor="middle"
        alignmentBaseline="middle"
      >
        {displayTitle}
      </text>
    </g>
  );
}
