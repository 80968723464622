import {
  ResourceTypeInfo,
  ResourceTypeQuery,
} from '@agilelab/plugin-wb-governance-common';
import { useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_RESOURCE_TYPES } from '../components/GovernanceRegistryPage/queries/resource-types.query';

type Props = {
  filters?: {
    name?: string;
  };
};

export const useResourceTypes = (props: Props) => {
  const [resourceTypes, setResourceTypes] = useState(
    new Map<string, ResourceTypeInfo>(),
  );

  const { data, loading, error } = useQuery<ResourceTypeQuery>(
    GET_RESOURCE_TYPES,
    {
      variables: {
        where: {
          name: { _eq: props.filters?.name },
        },
      },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (loading || error || !data) return;
    const resourceTypesMap = data.cgp_resource_type.reduce(
      (typesMap, resourceType) => {
        typesMap.set(resourceType.name, {
          uuid: resourceType.uuid,
          name: resourceType.name,
          displayName: resourceType.displayName,
        });
        return typesMap;
      },
      new Map<string, ResourceTypeInfo>(),
    );
    setResourceTypes(resourceTypesMap);
  }, [data, loading, error]);

  return resourceTypes;
};
