import { EventParams } from '../lib';
import { EventsApi, RequestOptions } from './EventsApi';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';
import { ResponseError } from '@backstage/errors';

export class EventsClient implements EventsApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('events');
  }

  async publish(
    eventParams: EventParams,
    options: RequestOptions,
  ): Promise<void> {
    const baseUrl = await this.baseUrlPromise;
    const headers = {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    };
    const response = await this.fetchApi.fetch(baseUrl, {
      method: 'POST',
      headers,
      body: JSON.stringify(eventParams),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}
