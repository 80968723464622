import { WbTable } from '@agilelab/plugin-wb-platform';
import { TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React from 'react';
import { Test } from '../types';

type Props = {
  children?: React.ReactNode;
};

export const TestTableLoading: React.FC<Props> = ({ children }) => {
  return (
    <WbTable<Test>
      stickyHeader
      components={{
        tableHeader: (
          <TableHead>
            <TableRow>
              <TableCell>Status</TableCell>
              <TableCell>ID / Date</TableCell>
              <TableCell>Results</TableCell>
            </TableRow>
          </TableHead>
        ),
        tableContent: {
          body: <TableBody>{children}</TableBody>,
        },
      }}
    />
  );
};
