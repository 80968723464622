import React from 'react';
import { BadgeProps, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type WbBadgeProps = BadgeProps;
const useStyles = makeStyles(
  theme => ({
    root: {
      borderRadius: theme.spacing(0.5),
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      ...theme.typography.body2,
      fontWeight: 500,
      whiteSpace: 'nowrap',
      display: 'inline-block',
      backgroundColor: theme.palette.softened.primary,
      color: theme.palette.secondary.dark,
    },
  }),
  { name: 'WbBadge' },
);

export const WbBadge: React.FC<WbBadgeProps> = ({
  className,
  style,
  children,
}) => {
  const classes = useStyles();
  return (
    <Box className={clsx(classes.root, className)} style={style}>
      {children}
    </Box>
  );
};
