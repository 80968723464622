import React from 'react';
import { Box, IconButton, makeStyles } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import ViewListIcon from '@material-ui/icons/ViewList';
import { useSearchContext } from '../context/SearchContext';

type ViewTypeSelectorProps = {};

const useStyles = makeStyles(
  () => ({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
  }),
  { name: 'ViewTypeSelector' },
);

export const ViewTypeSelector = ({}: ViewTypeSelectorProps) => {
  const classes = useStyles();

  const { view, setView } = useSearchContext();
  return (
    <Box className={classes.root}>
      <IconButton
        onClick={() => {
          setView('cards');
        }}
        color={view === 'cards' ? 'primary' : 'secondary'}
        size="small"
      >
        <ViewListIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          setView('table');
        }}
        color={view === 'table' ? 'primary' : 'secondary'}
        size="small"
      >
        <ListIcon />
      </IconButton>
    </Box>
  );
};
