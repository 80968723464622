import { gql } from '@apollo/client/core';

export const getGraphDataContractsBuildWhere = (
  env: string,
  taxonomy?: string,
) => {
  const taxonomyFilter = {
    taxonomy: { external_id: { _eq: taxonomy } },
  };

  const where: Record<string, any> = {
    _and: [
      { environment: { name: { _eq: env } } },
      { data_contract: { id: { _is_null: false } } },
    ],
  };

  if (taxonomy)
    where._and.push({
      relations: {
        // two possible cases to match the taxonomy filter (1 must be true)
        _or: [
          {
            _and: {
              // in case the data contract instance is a component, its parent system must match the taxonomy filter
              name: { _eq: 'partOfSystem' },
              instanceByTargetInstanceId: taxonomyFilter,
            },
          },
          {
            _and: {
              // in case the data contract instance is a subcomponent, the system whose parent component is part of must match the taxonomy filter
              name: { _eq: 'partOfComponent' },
              instanceByTargetInstanceId: {
                relations: {
                  name: { _eq: 'partOfSystem' },
                  instanceByTargetInstanceId: taxonomyFilter,
                },
              },
            },
          },
        ],
      },
    });

  return where;
};

export const getReadersBuildWhere = (taxonomy?: string) => {
  const taxonomyFilter = {
    taxonomy: { external_id: { _eq: taxonomy } },
  };

  const where: Record<string, any> = {
    _and: [{ name: { _eq: 'readsFrom' } }],
  };

  if (taxonomy) where._and.push({ instance: taxonomyFilter });

  return where;
};

export const getConsumedSubcomponentsBuildWhere = (taxonomy?: string) => {
  const where: Record<string, any> = {
    _and: [
      {
        name: { _eq: 'partOfComponent' },
        instance: {
          relationsByTargetInstanceId: getReadersBuildWhere(taxonomy),
        },
      },
    ],
  };

  return where;
};

export const DC_SYSTEM_PSEUDO_FRAGMENT = `
    id
    version
    display_name
    external_id
    kind
    type
    owner_display_name
    taxonomy {
      name
      external_id
    }
    domain: relations(where: { name: { _eq: "partOfDomain" } }) {
      data: instanceByTargetInstanceId {
        name
      }
    }
`;

export const GET_DATA_CONTRACTS_GRAPH = gql`
  query GetDataContractGraph(
    $dataContractsWhere: marketplace_instances_bool_exp
    $readersWhere: marketplace_relations_bool_exp
    $consumedSubcomponentsWhere: marketplace_relations_bool_exp
    $ingestionModePath: String
    $onBrokenContractPath: String
    $descriptionPath: String
  ) {
    marketplace_instances(where: $dataContractsWhere) {
      readers: relationsByTargetInstanceId(where: $readersWhere) {
        data: instance {
          ${DC_SYSTEM_PSEUDO_FRAGMENT}
        }
      }
      subcomponents: relationsByTargetInstanceId(
        where: $consumedSubcomponentsWhere
      ) {
        instance {
          readers: relationsByTargetInstanceId(where: $readersWhere) {
            data: instance {
              ${DC_SYSTEM_PSEUDO_FRAGMENT}
            }
          }
        }
      }
      dataContract: data_contract {
        policy_id
      }
      environment {
        name
      }
      id
      external_id
      display_name

      dcIngestionMode: descriptor(path: $ingestionModePath)
      dcOnBrokenContract: descriptor(path: $onBrokenContractPath)
      dcDescription: descriptor(path: $descriptionPath)

      version

      parentComponent: relations(where: { name: { _eq: "partOfComponent" } }) {
        data: instanceByTargetInstanceId {
          external_id
          system: relations(where: { name: { _eq: "partOfSystem" } }) {
            data: instanceByTargetInstanceId {
              ${DC_SYSTEM_PSEUDO_FRAGMENT}
            }
          }
        }
      }
      parentSystem: relations(where: { name: { _eq: "partOfSystem" } }) {
        data: instanceByTargetInstanceId {
          ${DC_SYSTEM_PSEUDO_FRAGMENT}
        }
      }
    }
  }
`;

export const GET_DATA_CONTRACT_BY_INSTANCE_ID = gql`
  query GetDataContractByInstanceId($instanceId: bigint!) {
    marketplace_data_contracts(where: { instance_id: { _eq: $instanceId } }) {
      instance_id
      policy_id
    }
  }
`;

export const GET_ALL_DATA_CONTRACTS_BY_ENV_NAME = gql`
  query GetAllDataContractsByEnvName($envName: String!) {
    marketplace_data_contracts(
      where: { instance: { environment: { name: { _eq: $envName } } } }
    ) {
      instance_id
      policy_id
    }
  }
`;
