import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';

const stringifyEntity = (entity: Entity) =>
  `${entity.kind.toLowerCase()}:default/${entity.metadata.name}`;

export function useEntitiesMembership() {
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  /**
   * It returns the flattened hierarchy of all the groups and related sub-groups
   * @param groups
   * @returns
   */
  const resolveGroupsHierarchy = async (
    groups: string[],
  ): Promise<string[]> => {
    const currentGroups = await catalogApi.getEntities({
      filter: [{ 'spec.parent': groups, kind: 'Group' }],
    });
    if (currentGroups.items.length) {
      return [
        ...groups,
        ...(await resolveGroupsHierarchy(
          currentGroups.items.map(stringifyEntity),
        )),
      ];
    }

    return groups;
  };

  /** Retrieve all group entity */
  const retrieveEntityGroups = async (groups: string[]) => {
    return catalogApi.getEntities({
      filter: [
        {
          'metadata.name': groups.map(group => parseEntityRef(group).name),
          kind: 'Group',
        },
      ],
    });
  };
  /** Retrieve all users entity belong to the every group in `groups` */
  const retrieveEntityUsers = async (groups: string[]) => {
    return catalogApi.getEntities({
      filter: [
        {
          'spec.memberOf': groups,
          kind: 'User',
        },
      ],
    });
  };

  const getMembersIdentities = async (options: {
    filter: { kind: string[] };
  }): Promise<Entity[]> => {
    const backstageIdentity = await identityApi.getBackstageIdentity();
    /**
     * This contains the groups and the sub-groups related to the logged user.
     */
    const overallGroups = await resolveGroupsHierarchy(
      backstageIdentity.ownershipEntityRefs.filter(entity =>
        entity.toLowerCase().startsWith('group'),
      ),
    );
    const identities = await (options.filter as any).kind
      .sort()
      .reduce(
        async (asyncAccumulator: Promise<string[]>, currentKind: string) => {
          const accumulator = await asyncAccumulator;
          switch (currentKind.toLowerCase()) {
            case 'group':
              return [
                ...new Set([
                  ...accumulator,
                  ...(await retrieveEntityGroups(overallGroups)).items,
                ]),
              ];
            case 'user':
              return [
                ...new Set([
                  ...accumulator,
                  ...(await retrieveEntityUsers(overallGroups)).items,
                ]),
              ];
            default:
              return accumulator;
          }
        },
        Promise.resolve([]),
      );

    return identities;
  };

  return { getMembersIdentities };
}
