import React from 'react';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { Acceptance_type } from '@agilelab/plugin-wb-documents-common';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';

interface DocumentsDeclineButtonProps {
  onClick: () => void;
}

interface DocumentsAcceptButtonProps {
  onClick: () => void;
  loading: boolean;
  label: string;
}

interface DocumentsAcceptanceActionsProps {
  type: Acceptance_type;
  onDeclineAction: () => void;
  onAcceptAction: () => void;
  loading: boolean;
}

export const DocumentsDeclineButton: React.FC<DocumentsDeclineButtonProps> = ({
  onClick,
}) => (
  <Button color="primary" variant="outlined" onClick={onClick}>
    <CloseIcon />
    <Typography>Decline</Typography>
  </Button>
);

export const DocumentsAcceptButton: React.FC<DocumentsAcceptButtonProps> = ({
  onClick,
  loading,
  label,
}) => (
  <Button
    color="primary"
    variant="contained"
    onClick={onClick}
    disabled={loading}
  >
    {loading ? (
      <CircularProgress size={24} color="inherit" />
    ) : (
      <>
        <CheckIcon />
        <Typography>{label}</Typography>
      </>
    )}
  </Button>
);

export const DocumentsAcceptanceActions: React.FC<
  DocumentsAcceptanceActionsProps
> = ({ type, onDeclineAction, onAcceptAction, loading }) => (
  <>
    {type === Acceptance_type.MANDATORY && (
      <DocumentsDeclineButton onClick={onDeclineAction} />
    )}
    <DocumentsAcceptButton
      onClick={onAcceptAction}
      loading={loading}
      label={type === Acceptance_type.MANDATORY ? 'Accept' : 'Acknowledge'}
    />
  </>
);
