export const thresholdValueToString = (value: number) => {
  const roundedVal = Math.round(value * 10) / 10;
  const metricLog = Math.log10(roundedVal);

  if (metricLog >= 3 && metricLog < 4) {
    return `${roundedVal.toString().at(0)}K`;
  }
  if (metricLog >= 4 && metricLog < 6) {
    return `${roundedVal.toString().at(0)}K+`;
  }
  if (metricLog >= 6 && metricLog < 7) {
    return `${roundedVal.toString().at(0)}M`;
  }
  if (metricLog >= 7) {
    return `${roundedVal.toString().at(0)}M+`;
  }
  return roundedVal.toString().substring(0, 3);
};
