import React, { ReactNode } from 'react';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { createSvgIcon } from '@material-ui/core/utils';

const useStyle = makeStyles(theme => ({
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  label: {
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(2),
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
  },
}));

export type LegendEntryType = {
  label: string;
  color?: string;
  labelContainerStyle?: React.CSSProperties;
  labelTextColor?: string;
  icon?: ReactNode;
  description: string;
};

const LegendIcon = (props: { icon: string; label: string }) => {
  const Icon = createSvgIcon(
    <g dangerouslySetInnerHTML={{ __html: props.icon }} />,
    `${props.label}Icon`,
  );
  return <Icon fontSize="small" />;
};

const LegendEntry = (props: { entry: LegendEntryType }) => {
  const { entry } = props;
  const classes = useStyle();
  const theme = useTheme();

  const renderIcon = () => {
    if (entry.icon === undefined) return null;
    if (typeof entry.icon === 'string')
      return <LegendIcon icon={entry.icon} label={entry.label} />;
    return entry.icon;
  };

  return (
    <Box className={classes.itemWrapper}>
      <Typography
        variant="body2"
        component="span"
        style={{
          backgroundColor: entry.color || 'transparent',
          paddingLeft: !entry.icon ? theme.spacing(1) : 0,
          color: entry.labelTextColor,
          ...(entry.labelContainerStyle || {}),
        }}
        className={classes.label}
      >
        {renderIcon()}
        <span style={{ marginLeft: entry.icon ? theme.spacing(1) : 0 }}>
          {entry.label}
        </span>
      </Typography>
      <Typography
        variant="caption"
        style={{ marginBottom: theme.spacing(1), wordBreak: 'break-word' }}
      >
        {entry.description}
      </Typography>
    </Box>
  );
};

export const WbLegend = ({ entries }: { entries: LegendEntryType[] }) => {
  return (
    <>
      {entries.map(e => (
        <LegendEntry entry={e} key={e.label} />
      ))}
    </>
  );
};
