import { makeStyles } from '@material-ui/core';

export const useNotificationStyle = makeStyles(theme => ({
  acceptButton: {
    color: `${theme.palette.success.main} !important`,
  },
  rejectButton: {
    color: `${theme.palette.error.main} !important`,
  },
  avatar: {
    fontSize: '12px',
    width: '24px',
    height: '24px',
    marginRight: '12px',
  },
  container: {
    marginBottom: '16px',
    borderBottom: '1px solid rgba(0,0,0,.12)',
  },
  header: {
    width: '100%',
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  userProfileContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    letterSpacing: 'normal',
  },
  userProfileContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    margin: '16px 0',
    marginLeft: '36px',
  },
  contentElementHeader: {
    display: 'flex',
    marginBottom: '16px',
    flexDirection: 'column',
  },
  contentElementSpan: {
    position: 'relative',
    fontWeight: 'bold',
    color: 'rgba(0,0,0,.54)',
    fontSize: '14px',
    textTransform: 'uppercase',
  },
  contentElementMargin: {
    marginRight: '32px',
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dialogContentContainer: {
    marginTop: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
  },
  dialogContentHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'nowrap',
  },
  notificationsContainer: {
    margin: '0 auto',
    padding: '16px 0',
    width: '680px',
  },
  questionParentContainer: {
    margin: '16px 0',
  },
  questionContainer: {
    marginLeft: '36px',
  },
  question: {
    marginBottom: '16px',
  },
  dialogActionStyle: {
    paddingRight: '1.5rem',
    paddingBottom: '1rem',
  },
  highlightQuestion: {
    fontWeight: 400,
    marginBottom: '16px',
  },
  fullWidth: {
    width: '100%',
  },
  buttonsContainer: {
    padding: '16px 30px',
  },
  textFieldContainer: {
    marginLeft: '36px',
  },
  summaryHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '20px',
    gap: '8px',
  },
  summaryDescription: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    '& svg': {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginRight: theme.spacing(2),
    },
  },
  summary: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryDescriptionText: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  notificationTime: {
    fontSize: theme.typography.caption.fontSize,
    color: theme.palette.text.secondary,
  },
}));
