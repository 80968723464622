import { z } from 'zod';

export interface SearchResourceType {
  id: number;
  name: string;
}

export interface ResourceTypeInfo {
  uuid: string;
  name: string;
  displayName: string;
}

export const UpsertResourceTypeRequestSchema = z.object({
  name: z.string({ description: 'Unique resource type name and identifier' }),
  displayName: z
    .string()
    .describe(
      'Resource type display name. It defaults to name if not specified',
    ),
  descriptorConfiguration: z
    .object({
      resourceNameField: z
        .string()
        .describe(
          'Field inside the resource descriptor that contains its unique identifier',
        )
        .default('id'),
      resourceDisplayNameFields: z
        .array(z.string())
        .describe(
          'List of fields in the resource descriptor to be used to generate a display name',
        )
        .default(['domain', 'name', 'version']),
      resourceFilterField: z
        .string()
        .describe(
          'Field inside the resource descriptor used to differentiate evaluation results for the same resource',
        )
        .optional(),
    })
    .describe(
      'Information about the shape of descriptors of this resource type',
    ),
  resolverConfiguration: z
    .object({
      url: z.string().describe('Base URL of the Perimeter Resolver service'),
      path: z
        .string()
        .describe('Path to the resolve endpoint')
        .default('v1/resolve'),
      batchSize: z
        .number()
        .describe(
          'Maximum number of resources requested to the perimeter resolver per request (page size)',
        )
        .optional(),
    })
    .describe('Details about the perimeter resolver'),
  cuePreprocessScript: z.string().optional(),
});

export type UpsertResourceTypeRequest = z.infer<
  typeof UpsertResourceTypeRequestSchema
>;

export type UpsertResourceTypeResponse = {
  name: string;
  displayName: string;
  descriptorConfiguration: {
    resourceNameField: string;
    resourceDisplayNameFields: string[];
    resourceFilterField?: string;
  };
  resolverConfiguration: {
    url: string;
    path: string;
    batchSize?: number;
  };
  cuePreprocessScript?: string;
};
