import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import ErrorIcon from '@material-ui/icons/Error';
import { Status as PolicyStatus } from '@agilelab/plugin-wb-governance-common';
import { IconLabelBox } from '../../IconLabelBox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

interface Props {
  policyStatus?: PolicyStatus;
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

function titleFromStatus(policyStatus?: PolicyStatus): string {
  switch (policyStatus) {
    case PolicyStatus.Grace: {
      return 'Failed. This policy is in the grace period, so it is not blocking the deployment. Check and solve the errors, since they will block the deployment when this will become enabled';
    }
    case PolicyStatus.Deprecated: {
      return 'Failed. This policy is deprecated, so it is not blocking the deployment. Check the errors, but remember that this policy will be removed';
    }
    default: {
      return 'Failed. This policy is in a state that does not prevent the deployment';
    }
  }
}

export const NotBlockingIcon: React.FC<Props> = ({
  policyStatus,
  style,
  variant = 'default',
}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Tooltip title={titleFromStatus(policyStatus)}>
      <span>
        {variant === 'reduced' ? (
          <ErrorIcon
            className={`${classes.icon}`}
            htmlColor={theme.palette.warning.main}
            style={{ ...style, transform: 'scale(1.2)' }}
          />
        ) : (
          <IconLabelBox
            label="Failure*"
            backgroundColor={theme.palette.warning.main}
            icon={
              <PriorityHighIcon style={style} htmlColor={theme.palette.black} />
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
