export class EntityNotFoundError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'EntityNotFoundError';
  }
}

export class CannotResolveGeneratedTypeError extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'CannotResolveGeneratedTypeError';
  }
}

export class InvalidEntityType extends Error {
  constructor(msg: string) {
    super(msg);
    this.name = 'InvalidEntityType';
  }
}
