import { useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { ResourceType, MigrationStep, TemplateEntityUpdate } from '../../types';

const entityKindExists = async (catalogApi: CatalogApi, kind: string) => {
  const entities = await catalogApi.getEntities({
    filter: { kind },
    limit: 1,
  });
  return entities.items.length > 0;
};

const atLeastOneActiveTaxonomy = async (catalogApi: CatalogApi) => {
  const taxonomies = await catalogApi.getEntities({
    filter: { kind: 'Taxonomy', 'spec.enabled': 'true' },
    limit: 1,
  });
  return taxonomies.items.length > 0;
};

const fetchMigrationStepsFn = async (
  catalogApi: CatalogApi,
  fetchTemplateUpdates: () => Promise<TemplateEntityUpdate[]>,
  fetchResourceTypes: () => Promise<ResourceType[]>,
) => {
  return [
    {
      description: 'At least one taxonomy has been registered',
      isComplete: await entityKindExists(catalogApi, 'Taxonomy'),
      additionalInfo:
        'You can find the default one in panel "New entities to register" on this page',
    },
    {
      description: 'At least one domain type has been registered',
      isComplete: await entityKindExists(catalogApi, 'DomainType'),
      additionalInfo:
        'You can find the default one in panel "New entities to register" on this page',
    },
    {
      description: 'All the detected resource types have been registered',
      isComplete:
        (await fetchResourceTypes()).filter(rt => rt.referencedBy === undefined)
          .length === 0,
      additionalInfo: 'Refer to panel "Detected resource types" on this page',
    },
    {
      description: 'All the registered templates have been updated',
      isComplete:
        (await fetchTemplateUpdates()).filter(tu => tu.generates === undefined)
          .length === 0,
      additionalInfo: 'Refer to panel "Templates to update" on this page',
    },
    {
      description:
        'At least one taxonomy is active (this closes the maintenance window for users)',
      isComplete: await atLeastOneActiveTaxonomy(catalogApi),
      additionalInfo:
        'A taxonomy can be enabled by setting its spec.enabled property to true',
    },
  ] as MigrationStep[];
};

export const useFetchMigrationSteps = (props: {
  fetchTemplateUpdates: () => Promise<TemplateEntityUpdate[]>;
  fetchResourceTypes: () => Promise<ResourceType[]>;
}) => {
  const catalogApi = useApi(catalogApiRef);

  const [fetchMigrationStepsState, fetchMigrationSteps] = useAsyncFn(
    () =>
      fetchMigrationStepsFn(
        catalogApi,
        props.fetchTemplateUpdates,
        props.fetchResourceTypes,
      ),
    [catalogApi, props.fetchTemplateUpdates, props.fetchResourceTypes],
  );
  return {
    fetchMigrationStepsState,
    fetchMigrationSteps,
  };
};
