import React from 'react';
import {
  buildReleaseVersion,
  DeployVersionStatus,
  ReleaseAndDeployments,
} from '@agilelab/plugin-wb-builder-common';
import { Box, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { DeploymentUnitStatus } from '../types';
import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { PartiallyDeployedIcon } from './icons/PartiallyDeployedIcon';
import { ProvisioningProgressIcon } from './icons/ProvisioningProgressIcon';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useRouteRef } from '@backstage/core-plugin-api';
import { releaseDetailRouteRef } from '../../../routes';

const useStyles = makeStyles(theme => ({
  card: {
    padding: '4px 8px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    color: 'white',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    gap: '4px',
    '&:hover': {
      'text-decoration': 'none !important',
    },
  },
  deployed: {
    background: theme.palette.success.main,
    color: theme.palette.black,
  },
  notDeployed: {
    background: theme.palette.blueGrey[200],
    color: theme.palette.black,
  },
  partiallyDeployed: {
    color: theme.palette.black,
    border: `1px solid ${theme.palette.success.main}`,
  },
  provisioningInProgress: {
    background: theme.palette.accent.main,
    color: theme.palette.white,
  },
  corrupt: {
    background: theme.palette.error.main,
    color: theme.palette.black,
  },
}));

interface Props {
  release: ReleaseAndDeployments;
}

interface StatusChipProps {
  icon: JSX.Element;
  env: string;
  className: string;
  status: DeploymentUnitStatus;
  releaseName: string;
  isDifferentDeployedVersion: boolean;
  version?: string;
}

interface DeploymentChipProps {
  deployment: DeployVersionStatus;
  releaseName: string;
  isDifferentDeployedVersion: boolean;
}

const StatusChip: React.FC<StatusChipProps> = ({
  env,
  icon,
  className,
  status,
  releaseName,
  version,
  isDifferentDeployedVersion,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);
  const statusSnakeCase = snakeCaseToTitleCase(status.toLowerCase());
  const tooltipTitle = `${statusSnakeCase}. Open detail page for ${version}`;
  return (
    <Tooltip title={tooltipTitle}>
      {isDifferentDeployedVersion ? (
        <Box
          className={clsx(classes.card, className)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            const namespace = 'default';
            const name = releaseName;
            const kind = 'Release';
            navigate({
              pathname: releaseDetailRoute({ kind, namespace, name }),
              search: createSearchParams({
                env,
                version: buildReleaseVersion({ v: version }) ?? '',
              }).toString(),
            });
          }}
        >
          {icon}
          <Typography variant="caption">
            {`${snakeCaseToTitleCase(env)} (${version})`}
          </Typography>
        </Box>
      ) : (
        <Box
          className={clsx(classes.card, className)}
          style={{ cursor: 'default' }}
        >
          {icon}
          <Typography variant="caption">
            {`${snakeCaseToTitleCase(env)} (${version})`}
          </Typography>{' '}
        </Box>
      )}
    </Tooltip>
  );
};

const DeploymentChip: React.FC<DeploymentChipProps> = ({
  deployment: d,
  releaseName,
  isDifferentDeployedVersion,
}) => {
  const classes = useStyles();

  switch (d.status) {
    case 'DEPLOYED':
      return (
        <StatusChip
          icon={<CheckIcon fontSize="small" />}
          env={d.environment}
          className={classes.deployed}
          status={d.status}
          releaseName={releaseName}
          version={d.version}
          isDifferentDeployedVersion={isDifferentDeployedVersion}
        />
      );
    case 'NOT_DEPLOYED':
      return <></>;
    case 'PARTIALLY_DEPLOYED':
      return (
        <StatusChip
          icon={<PartiallyDeployedIcon fontSize="small" />}
          env={d.environment}
          className={classes.partiallyDeployed}
          status={d.status}
          releaseName={releaseName}
          version={d.version}
          isDifferentDeployedVersion={isDifferentDeployedVersion}
        />
      );
    case 'PROVISIONING_IN_PROGRESS':
      return (
        <StatusChip
          icon={<ProvisioningProgressIcon fontSize="small" />}
          env={d.environment}
          className={classes.provisioningInProgress}
          status={d.status}
          releaseName={releaseName}
          version={d.version}
          isDifferentDeployedVersion={isDifferentDeployedVersion}
        />
      );
    case 'CORRUPT':
      return (
        <StatusChip
          icon={<ErrorOutlineIcon fontSize="small" />}
          env={d.environment}
          className={classes.corrupt}
          status={d.status}
          releaseName={releaseName}
          version={d.version}
          isDifferentDeployedVersion={isDifferentDeployedVersion}
        />
      );
    default:
      return <></>;
  }
};

export const ReleaseDeploymentStatus: React.FC<Props> = ({ release }) => {
  return (
    <Box display="flex" alignItems="center" style={{ gap: '8px' }}>
      {release?.deployments?.map((deployment, index) => {
        const version = deployment.version;
        const isDifferentDeployedVersion = version !== release.metadata.version;
        return (
          <DeploymentChip
            key={index}
            deployment={{ ...deployment, version }}
            releaseName={release.metadata.name}
            isDifferentDeployedVersion={isDifferentDeployedVersion}
          />
        );
      })}
    </Box>
  );
};
