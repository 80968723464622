import { useQuery } from '@apollo/client';
import { useApi } from '@backstage/core-plugin-api';
import {
  Box,
  CircularProgress,
  Theme,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import {
  TableCellProps,
  WbTable,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import {
  GET_OUTPUT_PORT_BY_EXTERNAL_ID,
  IGetOutputPortByExternalIdData,
  IGetOutputPortByExternalIdVars,
} from '../../../graphql/component.query';
import { useSemanticLinkingTableColumnSpecs } from './specs/useSemanticLinkingTableColumnSpecs';
import { Descriptor, Schema } from '@agilelab/plugin-wb-marketplace-common';

export interface SemanticLinkingVM {
  referenceId?: string;
  referenceFieldName: string;
  referenceOutputPort: string;
  domain?: string;
  projectName?: string;
  dataProductName?: string;
  dataProductInstanceId?: string;
  displayName?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginBottom: theme.spacing(2),
    },
    title: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      wordBreak: 'break-word',
    },
    subtitle: {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
      wordBreak: 'break-word',
    },
  }),
);

export const SemanticLinkingTable = (props: {
  descriptor: Descriptor;
  schema: Schema;
}) => {
  const { descriptor, schema } = props;
  const classes = useStyles();
  const alertApi = useApi(customAlertApiRef);
  const tableColumnsSpecs: Array<TableCellProps<SemanticLinkingVM>> =
    useSemanticLinkingTableColumnSpecs();

  const semanticLinkingReferences = descriptor.semanticLinking
    ? descriptor.semanticLinking.filter(s => s.fieldName === schema.name)
    : [];

  const { loading, data } = useQuery<
    IGetOutputPortByExternalIdData,
    IGetOutputPortByExternalIdVars
  >(GET_OUTPUT_PORT_BY_EXTERNAL_ID, {
    variables: {
      externalIds: semanticLinkingReferences.map(s => s.referenceOutputPort),
    },
    onError: error => {
      alertApi.post({
        error,
        severity: 'error',
      });
    },
  });

  if (loading) {
    return (
      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!data?.marketplace_instances) return <></>;

  const semanticLinkingData = semanticLinkingReferences.map(slr => {
    const details = data.marketplace_instances.find(
      s => s.external_id === slr.referenceOutputPort,
    );
    return {
      referenceFieldName: slr.referenceFieldName,
      referenceOutputPort: slr.referenceOutputPort,
      ...(details
        ? {
            referenceId: details.id,
            domain: details.system.at(0)?.data.domain.at(0)?.data.name,
            projectName: details.system.at(0)?.data.display_name,
            dataProductInstanceId: details.system.at(0)?.data.id.toString(),
            displayName: details.display_name,
          }
        : {}),
    };
  });

  return (
    <div className={classes.container}>
      <Typography variant="h6" className={classes.title}>
        Semantic linking for field: <b>{schema.name}</b>
      </Typography>
      <Typography variant="body1" className={classes.subtitle}>
        Here are listed the columns that have a semantic relationship with the
        field "{schema.name}". The listed columns can also belong to components
        of other domains and/or systems.
      </Typography>
      <WbTable<SemanticLinkingVM>
        components={{
          tableLoader: {
            loading,
          },
          tableContent: {
            columns: tableColumnsSpecs,
            rows: semanticLinkingData,
          },
        }}
      />
    </div>
  );
};
