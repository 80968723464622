import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import { CARD_TOP } from './const';

export const HorizontalLine: React.FC = () => {
  const theme = useTheme();
  return (
    <Box
      style={{
        background: theme.palette.grey[300],
        width: '10px',
        position: 'absolute',
        height: '2px',
        top: CARD_TOP,
        left: '-11px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          borderRadius: '100%',
          background: theme.palette.grey[300],
          width: '6px',
          height: '6px',
          marginRight: '-4px',
        }}
      />
    </Box>
  );
};
