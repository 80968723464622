import { makeStyles } from '@material-ui/core';
import React from 'react';

type SidebarDividerProps = {
  sideMargin?: boolean;
  noAboveMargin?: boolean;
  noUnderMargin?: boolean;
  invisible?: boolean;
};

const useStyle = makeStyles(theme => ({
  root: (props: SidebarDividerProps) => ({
    width: '100%',
    paddingInline: props.sideMargin ? '16px' : 0,
    marginTop: props.noAboveMargin ? 0 : theme.spacing(2),
    marginBottom: props.noUnderMargin ? 0 : '11px',
  }),
  divider: (props: SidebarDividerProps) => ({
    height: 1,
    width: '100%',
    background: props.invisible ? 'invisible' : theme.palette.bkg.secondary,
    border: 'none',
    margin: 0,
  }),
}));

export const WbSidebarDivider = (props: SidebarDividerProps) => {
  const classes = useStyle(props);
  return (
    <div className={classes.root}>
      <hr className={classes.divider} />
    </div>
  );
};
