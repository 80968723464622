/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import {
  IdentityApi,
  ProfileInfo,
  BackstageUserIdentity,
} from '@backstage/core-plugin-api';

/**
 * @deprecated Use `@backstage/plugin-auth-backend-module-guest-provider` instead.
 */
export class GuestUserIdentity implements IdentityApi {
  getUserId(): string {
    return 'guest';
  }

  async getIdToken(): Promise<string | undefined> {
    return undefined;
  }

  getProfile(): ProfileInfo {
    return {
      email: 'guest@example.com',
      displayName: 'Guest',
    };
  }

  async getProfileInfo(): Promise<ProfileInfo> {
    return {
      email: 'guest@example.com',
      displayName: 'Guest',
    };
  }

  async getBackstageIdentity(): Promise<BackstageUserIdentity> {
    const userEntityRef = `user:default/guest`;
    return {
      type: 'user',
      userEntityRef,
      ownershipEntityRefs: [userEntityRef],
    };
  }

  async getCredentials(): Promise<{ token?: string | undefined }> {
    return {};
  }

  async signOut(): Promise<void> {}
}
