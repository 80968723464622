import { BELONGS_TO } from '@agilelab/plugin-wb-practice-shaper-common';
import {
  CATALOG_FILTER_EXISTS,
  EntityFilter,
} from '@backstage/plugin-catalog-react';

export type ResourceTypeFilterValue = 'all' | string[];
export type OutputTypeFilterValue = 'all' | string;

/**
 * Filter type entities based on metadata.classDetails.
 * @public
 */
export class OutputTypeFilter implements EntityFilter {
  readonly valuesSet: string[];

  /**
   * @param value Selected value
   * @param allValues Finite set of of allowed values. When provided, the 'all' value will filter for all the provided values here
   */
  constructor(readonly value: OutputTypeFilterValue, allValues?: string[]) {
    this.valuesSet = allValues ?? [];
    if (this.value === 'all') this.value = 'all';
    else this.value = this.value;
  }

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    const filterValues = this.value === 'all' ? this.valuesSet : this.value;
    return {
      'spec.generates': filterValues,
    };
  }

  toQueryValue(): OutputTypeFilterValue {
    if (this.value === 'all') return 'all';
    return this.value;
  }
}

/**
 * Filter resource entities based on resource type id.
 * @public
 */
export class ResourceTypeFilter implements EntityFilter {
  readonly selectedValues: ResourceTypeFilterValue;
  readonly valuesSet: string[];

  /**
   * @param values Selected values
   * @param allValues Finite set of of allowed values. When provided, the 'all' value will filter for all the provided values here
   */
  constructor(
    private readonly values: ResourceTypeFilterValue,
    allValues?: string[],
  ) {
    this.valuesSet = allValues ?? [];
    if (this.values === 'all') this.selectedValues = 'all';
    else this.selectedValues = this.values;
  }

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    const filterValues =
      this.selectedValues === 'all' ? this.valuesSet : this.selectedValues;
    return {
      'spec.type': filterValues,
    };
  }

  toQueryValue(): ResourceTypeFilterValue {
    if (this.values === 'all') return 'all';
    return this.selectedValues;
  }
}

/**
 * Filters entities with a belongsTo relation towards one of the provided taxonomies
 */
export class TaxonomyFilter implements EntityFilter {
  constructor(private readonly taxonomyRefs: string[]) {}

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      [`relations.${BELONGS_TO}`]: this.taxonomyRefs.length
        ? this.taxonomyRefs
        : CATALOG_FILTER_EXISTS,
    };
  }
}

export class KindFilter implements EntityFilter {
  constructor(private readonly kinds: string[]) {}

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      kind: this.kinds,
    };
  }
}

export class TaxonomyTemplateFilter implements EntityFilter {
  constructor(private readonly taxonomyRefs: string[]) {}

  getCatalogFilters(): Record<string, string | symbol | (string | symbol)[]> {
    return {
      [`metadata.classDetails.taxonomy`]: this.taxonomyRefs,
    };
  }
}

export class TaxonomyBlueprintFilter extends TaxonomyTemplateFilter {}
