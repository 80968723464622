/*
 * This component is copied from Backstage 1.8.2.
 * CHANGES:
 * - Added custom UI
 * - Add useEffect to allow filter clear
 */

import React from 'react';
import { useTaxonomySelection, useOutputTypeFilter } from '../../hooks';
import { SelectFilter } from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { WitboostTemplate } from '@agilelab/plugin-wb-builder-common';
import { capitalize } from '@material-ui/core';

export interface OutputTypePickerProps {
  initialFilter?: string;
  allowedTypes?: any;
}

export const OutputTypePicker: React.FC<OutputTypePickerProps> = ({
  allowedTypes,
  initialFilter,
}) => {
  const catalogApi = useApi(catalogApiRef);
  const { selectedTaxonomyRef } = useTaxonomySelection();

  const filterQuery: Record<string, any> = {
    kind: ['Template'],
  };
  if (selectedTaxonomyRef !== 'all') {
    filterQuery['metadata.classDetails.taxonomy'] = selectedTaxonomyRef;
  }

  const extractType = (entity: WitboostTemplate) => {
    return entity.metadata.classDetails
      ? entity.metadata.classDetails.pluralizedDisplayName ??
          entity.metadata.classDetails.displayName
      : entity.metadata.name;
  };

  const { value: catalogResponse } = useAsync(() => {
    return catalogApi.getEntities({
      fields: ['metadata', 'spec.generates'],
      filter: filterQuery,
    });
  }, [catalogApi, selectedTaxonomyRef]);

  const availableTypes = new Map<string, string>();

  catalogResponse?.items.forEach(i => {
    const type = extractType(i as WitboostTemplate);
    const generates = (i.spec?.generates as string) ?? '';
    if (type !== undefined) {
      availableTypes.set(capitalize(type), generates);
    }
  });
  const items = [...availableTypes.keys()];

  const { setSelectedType, selectedType } = useOutputTypeFilter({
    initialValue: initialFilter ?? 'all',
    allowedTypes,
  });

  const getLabel = (option: string) => {
    return option;
  };

  const getKeyFromValue = (value: string) => {
    for (const [key, val] of availableTypes.entries()) {
      if (val === value) {
        return key;
      }
    }
    return undefined;
  };

  const getValue = (option: string) => {
    return availableTypes.get(option) ?? '';
  };

  const computeEnumFilterValue = () =>
    selectedType === 'all' ? '' : getKeyFromValue(selectedType);

  return (
    <SelectFilter<string>
      field="Type"
      value={computeEnumFilterValue()}
      options={items}
      renderOption={getLabel}
      renderValue={getLabel}
      onChange={v => {
        if (v === undefined) setSelectedType('all');
        else setSelectedType(getValue(v));
      }}
      onSearch={v => items.filter(o => new RegExp(v, 'ig').test(getLabel(o)))}
    />
  );
};
