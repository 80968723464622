import { MenuItem } from '@material-ui/core';
import React from 'react';
import { ActionsContextProvider } from '../../context';
import { useCustomActions } from './utils';

export const CustomViewDownloadItem = ({
  item,
  included,
}: {
  item: {
    id: string;
    label: string;
    action: () => void;
  };
  included: any[];
}) => {
  const { id, label, action } = item;
  const realActions = useCustomActions(id, included);
  return (
    <ActionsContextProvider actions={realActions}>
      <MenuItem onClick={action}>{label}</MenuItem>
    </ActionsContextProvider>
  );
};
