import { InstanceEntity } from '@agilelab/plugin-wb-marketplace-common';
import { UserEntity } from '@agilelab/plugin-wb-platform';
import { createContext } from 'react';

export const SystemContext = createContext<InstanceEntity>(
  void 0 as unknown as InstanceEntity,
);

export const UserEntityContext = createContext<UserEntity>(
  void 0 as unknown as UserEntity,
);
