import {
  ConfirmDialog,
  customAlertApiRef,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import { rbacApiRef } from '@agilelab/plugin-wb-rbac';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import React, { useEffect } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { panelCatalogApiRef } from '../../api';
import { newVersionCallback } from './newVersionCallbacks';
import { applyWitboostVersionedEntity } from '@agilelab/plugin-wb-builder-common';

export const EntityNewVersionDialog = (props: {
  open: boolean;
  onClose: () => void;
}) => {
  const { open, onClose } = props;
  const { entity } = useAsyncEntity();
  const panelCatalog = useApi(panelCatalogApiRef);
  const identityApi = useApi(identityApiRef);

  const rbacApi = useApi(rbacApiRef);
  const alertApi = useApi(customAlertApiRef);

  const [createNewVersionState, createNewVersion] = useAsyncFn(async () => {
    if (entity) {
      try {
        // TODO manuel: add precondition (read below)
        /**
         * user should only make new version starting from the latest version of the data product.
         * but right now the catalog api does not allow for a LIKE operator
         */
        const versionedEntity = applyWitboostVersionedEntity(entity);
        const { token } = await identityApi.getCredentials();

        const response = await panelCatalog.newEntityVersion(
          entity.metadata.name,
          entity.kind,
          versionedEntity.spec.mesh.version,
        );
        await newVersionCallback(response, entity, rbacApi, alertApi, token);

        return response;
      } catch (error) {
        onClose();

        alertApi.post({
          error,
          severity: 'error',
        });
      }
    }
    return null;
  }, [entity]);

  useEffect(() => {
    if (createNewVersionState.value) {
      const { projectName: entityName, version } = createNewVersionState.value;
      alertApi.post({
        actions: (
          <WbLink
            route={`/catalog/default/${
              entity?.kind ?? 'system'
            }/${entityName.replace(/[^.]+$/, version)}`}
            text={`Open new version ${version}`}
          />
        ),
        message: `New Version successfully created. It may take a while to display it.`,
        severity: 'success',
        timeoutMillis: 5000,
      });
      onClose();
    }
  }, [createNewVersionState, alertApi, onClose, entity?.kind]);

  return (
    <ConfirmDialog
      title="Do you really want to create a new version of this Project?"
      description={
        <span>
          <div>
            This action will create a new Project with the same content of the
            current one. This will result in a brand new Project and components,
            initially copied from the current ones. Once the new version is
            created, they will be treated as independent Project.
          </div>
          <div>Do you want to continue?</div>
        </span>
      }
      open={open}
      onConfirm={createNewVersion}
      onClose={onClose}
      inProgress={createNewVersionState.loading}
      confirmButtonText="Create"
    />
  );
};
