import { CustomLogoProps } from './types';

export const getCustomLogo = async (): Promise<CustomLogoProps | undefined> => {
  const extensions: string[] = [
    'png',
    'jpg',
    'jpeg',
    'gif',
    'webp',
    'bmp',
    'svg',
  ];

  let customLogo = undefined;

  for (const ext of extensions) {
    try {
      const res = await fetch(`/custom_style/logo.${ext}`);
      const imageBlob = await res.blob();
      if (imageBlob.type.includes('image')) {
        const bitmap = await createImageBitmap(imageBlob);
        const imageObjectURL = URL.createObjectURL(imageBlob);
        return {
          url: imageObjectURL,
          width: bitmap.width,
          height: bitmap.height,
        };
      }
    } catch (_error) {
      customLogo = undefined;
    }
  }

  return customLogo;
};
