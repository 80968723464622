import { Box } from '@material-ui/core';
import React from 'react';
import { QuickLink } from './QuickLink/QuickLink';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { useReleaseStatusAndVersion } from '../hooks/useReleaseStatusAndVersion';
import { DeploymentUnitStatus } from '@agilelab/plugin-wb-builder-common';
import { useNavigate } from 'react-router';

export const QuickLinks: React.FC = () => {
  const { isDeployingAll, isUndeployingAll, entity, deployAll, undeployAll } =
    useReleaseDetailPageContext();
  const navigate = useNavigate();
  const releaseStatusAndVersion = useReleaseStatusAndVersion();

  const hide =
    isDeployingAll ||
    isUndeployingAll ||
    releaseStatusAndVersion[0].status !== DeploymentUnitStatus.CORRUPT;

  const navigateToEditor = () => {
    navigate(
      `/catalog/${entity.metadata.namespace}/${entity.kind.toLowerCase()}/${
        entity.metadata.name
      }/editor`,
    );
  };

  return (
    <>
      {!hide && (
        <Box
          style={{
            display: 'grid',
            flexWrap: 'wrap',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            columnGap: '16px',
            rowGap: '16px',
            margin: '32px 0px',
          }}
        >
          <QuickLink type="DEPLOY_AGAIN" onClick={deployAll} />
          <QuickLink type="UNDEPLOY_ALL" onClick={undeployAll} />
          <QuickLink type="GO_TO_EDITOR" onClick={navigateToEditor} />
        </Box>
      )}
    </>
  );
};
