import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Status } from '@agilelab/plugin-wb-governance-common';
import { DeprecatedIcon } from './GovernanceRegistryPolicies/Icons/Deprecated';
import { DisabledIcon } from './GovernanceRegistryPolicies/Icons/Disabled';
import { DraftIcon } from './GovernanceRegistryPolicies/Icons/Draft';
import { EnabledIcon } from './GovernanceRegistryPolicies/Icons/Enabled';
import { GraceIcon } from './GovernanceRegistryPolicies/Icons/Grace';

interface Props {
  status: Status;
}

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface StatusProps {
  label: string;
  icon: React.ReactElement;
}

const StatusElement: React.FC<StatusProps> = ({ label, icon }) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      {icon}
      <Typography style={{ fontSize: 12 }}>{label}</Typography>
    </Box>
  );
};

export function useStatusInfo() {
  const theme = useTheme();

  const statusInfo: Record<
    Status,
    { icon: React.ReactElement; label: string }
  > = {
    [Status.Draft]: { icon: <DraftIcon color="secondary" />, label: 'Draft' },
    [Status.Grace]: { icon: <GraceIcon color="secondary" />, label: 'Grace' },
    [Status.Enabled]: {
      icon: <EnabledIcon htmlColor={theme.palette.success.main} />,
      label: 'Enabled',
    },
    [Status.Disabled]: {
      icon: <DisabledIcon />,
      label: 'Disabled',
    },
    [Status.Deprecated]: {
      icon: <DeprecatedIcon color="secondary" />,
      label: 'Deprecated',
    },
    [Status.Deleted]: {
      icon: <DeprecatedIcon color="secondary" />,
      label: 'Deleted',
    },
    [Status.DisabledGrace]: {
      icon: <DisabledIcon />,
      label: 'Disabled',
    },
  };

  return { statusInfo };
}

export const GovernanceRegistryStatus: React.FC<Props> = ({ status }) => {
  const { statusInfo } = useStatusInfo();

  return (
    <StatusElement
      icon={statusInfo[status].icon}
      label={statusInfo[status].label}
    />
  );
};
