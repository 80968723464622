import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/Taxonomy.v2alpha1.schema.json';

declare interface TaxonomyEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'Taxonomy';
  metadata: EntityMeta & { displayName?: string };
  spec: {
    enabled: boolean;
  };
}

export type { TaxonomyEntity as Taxonomy };

export const taxonomyEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
