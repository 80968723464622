import { WbCardActionButton, WbEditor } from '@agilelab/plugin-wb-platform';
import { Box, CircularProgress, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useEditorPageContext } from '../context/useEditorPageContext';
import CachedIcon from '@material-ui/icons/Cached';

export const DescriptorCard: React.FC = () => {
  const { descriptorState, fetchDescriptor, setMonacoEditorRef } =
    useEditorPageContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(descriptorState.loading);
  }, [descriptorState]);
  return (
    <Box height="100%">
      <Box
        style={{
          marginBottom: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '30px',
        }}
      >
        <Typography style={{ fontWeight: 500 }}>Descriptor</Typography>

        <Tooltip title="Refresh the descriptor by reading again all the values from the repositories. After you make a change on the repository files, click this button to reload and see the changes in the descriptor.">
          <WbCardActionButton
            variant="outlined"
            onClick={() => {
              setLoading(true);
              fetchDescriptor().finally(() => setLoading(false));
            }}
            label="Refresh"
            icon={<CachedIcon />}
            disabled={loading}
          />
        </Tooltip>
      </Box>

      <Box
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '90%',
          overflow: 'hidden',
        }}
      >
        {loading ? (
          <CircularProgress />
        ) : (
          <WbEditor
            theme="white"
            value={descriptorState.value || ''}
            options={{ readOnly: true }}
            onMount={setMonacoEditorRef}
          />
        )}
      </Box>
    </Box>
  );
};
