import { Config } from '@backstage/config';
import { DEFAULT_GLOBAL_PATH } from './constants';

/**
 * Resolves the paths for the data contract settings.
 * If the paths are not defined in the configuration, it will fallback to the default values.
 * The default values are:
 * - ingestionModePath: 'dataContract.settings.ingestionMode'
 * - onBrokenContractPath: 'dataContract.settings.onBrokenContract'
 * - descriptionPath: 'dataContract.settings.description'
 * @param config
 */
export function computeDataContractSettingsPaths(config: Config) {
  const dataContractSettingsConfig = config.getOptionalConfig(
    'mesh.marketplace.ui.dataContracts.settings',
  );
  // resolve first the global path
  const globalPath =
    dataContractSettingsConfig?.getOptionalString('globalPath') ??
    DEFAULT_GLOBAL_PATH;

  // then calculate the specific paths
  const ingestionModePath =
    dataContractSettingsConfig?.getOptionalString('ingestionModePath') ??
    `${globalPath}.ingestionMode`;
  const onBrokenContractPath =
    dataContractSettingsConfig?.getOptionalString('onBrokenContractPath') ??
    `${globalPath}.onBrokenContract`;
  const descriptionPath =
    dataContractSettingsConfig?.getOptionalString('descriptionPath') ??
    `${globalPath}.description`;

  return {
    ingestionModePath,
    onBrokenContractPath,
    descriptionPath,
  };
}
