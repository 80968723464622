import React, { useEffect, useState } from 'react';
import { DeploymentPreviewAccordion } from './DeploymentPreviewAccordion';
import { useDeploymentPreviewOperations } from '../hooks/useDeploymentPreviewOperations';
import { DeploymentPreviewTable } from './DeploymentPreviewTable';
import { useDeploymentPreviewContext } from './context/useDeploymentPreviewContext';
import { DeploymentPreview } from '../types';

interface Props {
  ids: string[];
  setCleanUpPreviews?: React.Dispatch<
    React.SetStateAction<DeploymentPreview[]>
  >;
}

export const CleanUpAccordion: React.FC<Props> = ({
  ids,
  setCleanUpPreviews,
}) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const { fetchPreviewOperations, previewOperations } =
    useDeploymentPreviewOperations(ids);
  const { isAdvanced } = useDeploymentPreviewContext();

  useEffect(() => {
    if (expanded) fetchPreviewOperations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  if (ids.length === 0) return <></>;

  return (
    <DeploymentPreviewAccordion
      expanded={expanded}
      setExpanded={setExpanded}
      label={`A previous failed or aborted provisioning operation left ${ids.length} components in a corrupt status.`}
      bold="They will be undeployed."
    >
      <DeploymentPreviewTable
        rows={previewOperations.value || []}
        loading={previewOperations.loading}
        enableCleanUpMultiSelection={isAdvanced}
        setCleanUpPreviews={setCleanUpPreviews}
      />
    </DeploymentPreviewAccordion>
  );
};
