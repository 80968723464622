import React from 'react';
import { WbTable } from '@agilelab/plugin-wb-platform';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { TestTableLoader } from './TestTableLoader';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { TestTableRow } from './TestTableRow';
import { TaskDrawer } from './TaskDrawer/TaskDrawer';
import { Test } from '../types';
import { TestHistoryEmptyState } from './TestHistoryEmptyState';

export function TestHistory() {
  const {
    testsState,
    testPagination,
    tests,
    onTestPageChange,
    onTestChangeRowsPerPage,
    selectedTask,
    setSelectedTask,
    monacoEditorRef,
  } = useEditorPageContext();

  if (!tests.length) return <TestHistoryEmptyState />;

  return (
    <>
      <WbTable<Test>
        stickyHeader
        components={{
          tableLoader: {
            loading: testsState.loading,
            loader: <TestTableLoader rows={5} />,
          },
          tableHeader: (
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>ID / Date</TableCell>
                <TableCell>Results</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          ),
          tableContent: {
            body: (
              <TableBody>
                {tests.map((t, i) => (
                  <TestTableRow key={i} test={t} />
                ))}
              </TableBody>
            ),
          },
        }}
        pagination={{
          rowsPerPageOptions: [5, 10, 25],
          count: testsState.value?.page?.count || 0,
          limit: testPagination.limit,
          offset: testPagination.offset,
          onPageChange: onTestPageChange,
          onRowsPerPageChange: onTestChangeRowsPerPage,
        }}
        styles={{
          container: { height: '100%', flex: '1 1 0' },
        }}
      />

      <TaskDrawer
        task={selectedTask}
        open={Boolean(selectedTask)}
        onClose={() => {
          setSelectedTask(null);
          monacoEditorRef.current?.applyDecorations(
            undefined,
            undefined,
            'white',
          );
        }}
      />
    </>
  );
}
