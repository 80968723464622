import {
  Collapse,
  CollapseProps,
  IconButton,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import clsx from 'clsx';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useElementSize } from '../../hooks';
import { WbCard, WbCardProps } from '../WbCard';

interface WbAccordionProps extends WbCardProps {
  children?: React.ReactNode;
  renderDynamicContent?: (expanded: boolean) => ReactNode;
  headerInfo?: JSX.Element;
  footer?: JSX.Element;
  cardClassName?: string;
  expanded?: boolean;
  collapseProps?: CollapseProps;
  handleChange?: () => void;
  placeholderHeight?: string;
}

const useStyles = makeStyles(theme => ({
  accordionHeader: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: theme.spacing(2),
    width: '100%',
    height: '20px',
  },
  accordionDetails: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0px 0px 0px',
  },
  card: {
    background: theme.palette.background.default,
  },
  footer: {
    textAlign: 'end',
    padding: '8px',
    background: theme.palette.background.default,
  },
  iconContainer: {
    padding: 0,
    marginLeft: 12,
    transform: 'rotate(0deg)',
    '&$expanded': {
      transform: 'rotate(90deg)',
    },
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  icon: {
    fontSize: '20px',
    color: theme.palette.secondary.main,
  },
  expanded: {},
}));

const CollapseDynamicContent = ({
  expanded,
  children,
  placeholderHeight,
}: {
  expanded: boolean;
  children: ReactNode;
  placeholderHeight?: string;
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const { clientHeight } = useElementSize(contentRef, 10);

  const [collapsedHeight, setCollapsedHeight] = useState(0);

  useEffect(() => {
    if (!expanded) setCollapsedHeight(clientHeight);
  }, [expanded, clientHeight]);

  return (
    <Collapse
      collapsedSize={
        !collapsedHeight && placeholderHeight
          ? placeholderHeight
          : collapsedHeight
      }
      in={expanded}
    >
      <div ref={contentRef}>{children}</div>
    </Collapse>
  );
};

export const WbAccordion: React.FC<WbAccordionProps> = ({
  title,
  children,
  footer,
  headerInfo,
  cardClassName,
  expanded: parentExpanded,
  handleChange: parentHandleChange,
  headerStyle,
  cardStyle,
  collapseProps,
  renderDynamicContent,
  placeholderHeight,
  ...props
}) => {
  const classes = useStyles();

  const [innerExpanded, setInnerExpanded] = useState(false);

  const innerOnChange = () => {
    setInnerExpanded(old => !old);
  };

  const theme = useTheme();

  const expanded = parentExpanded ?? innerExpanded;
  const handleChange = parentHandleChange ?? innerOnChange;

  return (
    <WbCard
      footer={footer}
      leading={
        <IconButton
          onClick={handleChange ?? innerOnChange}
          className={clsx(classes.iconContainer, expanded && classes.expanded)}
          size="small"
        >
          <KeyboardArrowRightIcon color="primary" className={classes.icon} />
        </IconButton>
      }
      title={title}
      icon={headerInfo}
      headerStyle={{
        background: theme.palette.grey[50],
        borderBottom:
          expanded || collapseProps?.collapsedSize || renderDynamicContent
            ? `1px solid ${theme.palette.divider}`
            : undefined,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        ...headerStyle,
      }}
      headerContentStyle={{
        height: 'auto',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }}
      cardStyle={{
        boxShadow: 'none',
        border: `1px solid ${theme.palette.divider}`,
        ...cardStyle,
      }}
      {...props}
    >
      {renderDynamicContent ? (
        <CollapseDynamicContent
          placeholderHeight={placeholderHeight}
          expanded={expanded}
        >
          {renderDynamicContent(expanded)}
        </CollapseDynamicContent>
      ) : (
        <Collapse {...collapseProps} in={expanded}>
          {children}
        </Collapse>
      )}
    </WbCard>
  );
};
