export enum ConsistencyStatus {
  Normal = 'Normal',
  DuplicatedOriginal = 'duplicated_original',
  DuplicatedInvalid = 'duplicated_invalid',
}

export const ENTITY_STATUS_PATH =
  'metadata.annotations.witboost/reservedFieldsStatus';
export const ENTITY_NAME_PATH =
  'metadata.annotations.witboost/reservedFieldsOriginalName';
export const ENTITY_KIND_PATH =
  'metadata.annotations.witboost/reservedFieldsOriginalKind';
