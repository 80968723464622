import { Policy } from '../policy';
import { z } from 'zod';

const todayEod: () => Date = () => {
  const today = new Date();
  today.setHours(23, 59, 59, 999); // Set time to 23:59:59.999
  return today;
};

const nDaysBeforeTodayEod: (n: number) => Date = n => {
  const today = todayEod();
  const targetDate = new Date(today);
  targetDate.setDate(today.getDate() - n);
  return targetDate;
};

export const TopFailedPoliciesRequestSchema = z
  .object({
    environment: z.string().min(1),
    startDate: z.string().transform(str => new Date(str)),
    endDate: z.string().transform(str => new Date(str)),
    limit: z.number().min(1).max(20),
    includePolicyDetails: z.boolean(),
  })
  .refine(data => data.startDate >= nDaysBeforeTodayEod(120), {
    path: ['startDate'],
    message: `Start date cannot be before ${nDaysBeforeTodayEod(120)}`,
  });

export type TopFailedPoliciesRequest = z.infer<
  typeof TopFailedPoliciesRequestSchema
>;

export type TopFailedPolicyInsight = {
  policyId: string;
  failureRate: number;
  policy?: Policy;
};

export const PolicyExecutionStatsRequestSchema = z
  .object({
    environment: z.string().min(1),
    startDate: z.string().transform(str => new Date(str)),
    endDate: z.string().transform(str => new Date(str)),
  })
  .refine(data => data.startDate >= nDaysBeforeTodayEod(120), {
    path: ['startDate'],
    message: `Start date cannot be before ${nDaysBeforeTodayEod(120)}`,
  });

export type PolicyExecutionStatsRequest = z.infer<
  typeof PolicyExecutionStatsRequestSchema
>;

export type PolicyExecutionStats = {
  resourceType: string;
  failed: number;
  total: number;
  noResult: number;
};

export const FailedPolicyEvaluationStatsRequestSchema = z
  .object({
    environment: z.string().min(1),
    startDate: z.string().transform(str => new Date(str)),
    endDate: z.string().transform(str => new Date(str)),
    reportLabels: z.array(z.string()).optional(),
  })
  .refine(data => data.startDate >= nDaysBeforeTodayEod(120), {
    path: ['startDate'],
    message: `Start date cannot be before ${nDaysBeforeTodayEod(120)}`,
  });

export type FailedPolicyEvaluationStatsRequest = z.infer<
  typeof FailedPolicyEvaluationStatsRequestSchema
>;

export type FailedPolicyEvaluationStats = {
  resourceType: string;
  failedEvaluations: number;
};
