import {
  ErrorInfo,
  ErrorNavigation as ErrorNavigationType,
} from '@agilelab/plugin-wb-governance-common';
import {
  CopyButton,
  WbCardContent,
  WbWidget,
  renderMessage,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Theme, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import { ErrorLink } from './ErrorLink';
import { ErrorNavigation } from './ErrorNavigation';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  endAdornment: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flex: 1,
    gap: theme.spacing(2),
    overflowY: 'auto',
  },
  errorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flex: '1 1 0',
  },
  errorColumn: {
    flex: 0.5,
    height: '100%',
  },
}));

const styles = {
  button: {
    minWidth: 0,
  },
  icon: {
    width: '22px',
    height: '22px',
  },
};

interface ErrorBoxProps {
  error: ErrorInfo;
  headerTitle: string;
  handleScroll?: (row: number, id: string) => void;
  navigation?: ErrorNavigationType;
  policyEditorId?: string;
  descriptorEditorId?: string;
}

export const ErrorBox: React.FC<ErrorBoxProps> = ({
  handleScroll,
  error,
  navigation,
  policyEditorId,
  descriptorEditorId,
}) => {
  const classes = useStyles();

  return (
    <WbWidget
      title="Error info"
      actions={
        <Box className={classes.header}>
          <Box className={classes.endAdornment}>
            {!!navigation && (
              <ErrorNavigation
                current={navigation.current}
                total={navigation.total}
                handleSwitch={navigation.handleSwitch}
              />
            )}
            <CopyButton
              text={error.message}
              hideIcon
              size="small"
              color="secondary"
              variant="text"
              style={styles.button}
            >
              <FileCopyIcon style={styles.icon} />
            </CopyButton>
          </Box>
        </Box>
      }
    >
      <Box className={classes.body}>
        <WbCardContent>
          <Typography component="div">
            {renderMessage(error.message)}
          </Typography>
          <Box className={classes.errorContainer}>
            <Box className={classes.errorColumn}>
              {error.policyErrorRows?.map((errorRow: number) => (
                <ErrorLink
                  key={errorRow}
                  title={`Policy:${errorRow}`}
                  onClick={_e => {
                    if (handleScroll && policyEditorId)
                      handleScroll(errorRow, policyEditorId);
                  }}
                />
              ))}
            </Box>
            <Box className={classes.errorColumn}>
              {error.descriptorErrorRows?.map((errorRow: number) => (
                <ErrorLink
                  key={errorRow}
                  title={`Descriptor:${errorRow}`}
                  onClick={_e => {
                    if (handleScroll && descriptorEditorId)
                      handleScroll(errorRow, descriptorEditorId);
                  }}
                />
              ))}
            </Box>
          </Box>
        </WbCardContent>
      </Box>
    </WbWidget>
  );
};
