export class IntRange {
  private readonly from: number;
  private readonly to: number;

  constructor(from: number, to: number) {
    const intFrom = Math.round(from);
    const intTo = Math.round(to);
    if (intFrom > intTo) {
      throw new Error(
        `Failed to create the range [${intFrom}, ${intTo}] since the lower bound is greater than the upper bound`,
      );
    }
    this.from = intFrom;
    this.to = intTo;
  }

  includes(value: number) {
    return value >= this.from && value <= this.to;
  }

  merge(other: IntRange): IntRange | IntRange[] {
    const [lower, upper] =
      this.from < other.from ? [this, other] : [other, this];
    if (lower.includes(upper.from) || lower.to === upper.from - 1) {
      return new IntRange(lower.from, Math.max(upper.to, lower.to));
    }
    return [lower, upper];
  }

  lower(): number {
    return this.from;
  }

  upper(): number {
    return this.to;
  }
}

export class IntRanges {
  private readonly values: IntRange[];

  private isRange(value: IntRange | IntRange[]): value is IntRange {
    return (value as IntRange).lower !== undefined;
  }

  private mergeRanges(intervals: IntRange[]): IntRange[] {
    intervals.sort((a, b) => a.lower() - b.lower());
    const merged: IntRange[] = [];

    for (const interval of intervals) {
      if (!merged.length) {
        merged.push(interval);
      } else {
        const union = merged[merged.length - 1].merge(interval);
        if (this.isRange(union)) {
          merged[merged.length - 1] = union;
        } else {
          merged.push(interval);
        }
      }
    }

    return merged;
  }

  constructor(values: IntRange[]) {
    this.values = this.mergeRanges(values);
  }

  add(other: IntRanges): IntRanges {
    const intervals = [...this.values, ...other.ranges()];
    return new IntRanges(intervals);
  }

  ranges(): IntRange[] {
    return this.values;
  }
}
