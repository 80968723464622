import { Theme } from '@material-ui/core';

type GetOnHoverScrollbarStyle = (theme: Theme, onHoverOnly?: boolean) => any;

export const getOnHoverScrollbarStyle: GetOnHoverScrollbarStyle = (
  theme,
  onHoverOnly = true,
) => ({
  '&::-webkit-scrollbar': {
    visibility: onHoverOnly ? 'hidden' : 'visible',
    backgroundColor: 'transparent',
    width: '5px',
    height: '5px',
    borderRadius: '5px',
  },
  '&::-webkit-scrollbar-thumb': {
    cursor: 'auto',
    visibility: onHoverOnly ? 'hidden' : 'visible',
    backgroundColor: theme.palette.grey[400],
    borderRadius: '5px',
  },
  '&:hover': {
    '&::-webkit-scrollbar': {
      visibility: 'visible',
    },
    '&::-webkit-scrollbar-thumb': {
      visibility: 'visible',
    },
  },
});
