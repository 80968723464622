/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React from 'react';
import { FieldExtensionComponentProps } from '../../../extensions';
import { WmUiSchema } from '../../../extensions/types';
import {
  WbMarkdownHelperText,
  WbTextField,
} from '@agilelab/plugin-wb-platform';
import { isHidden } from '../../utils';
import { useFormExtraConfigContext } from '../../../contexts/FormExtraConfigContext';

export const allowArbitraryValues = (uiSchema: WmUiSchema): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;

/**
 * EntityName Picker
 */
export const EntityNamePicker = (
  props: FieldExtensionComponentProps<string>,
) => {
  const {
    onChange,
    required,
    schema: { title = 'Name', description = 'Unique name of the component' },
    rawErrors,
    formData,
    uiSchema,
    idSchema,
    placeholder,
  } = props;

  const autoFocus = uiSchema['ui:autofocus'];
  const maxLength = uiSchema['ui:options']?.maxLength;

  const { debounceMs } = useFormExtraConfigContext();

  return (
    <>
      <WbTextField
        id={idSchema?.$id}
        style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
        label={title}
        placeholder={placeholder}
        disabled={!allowArbitraryValues(uiSchema)}
        required={required}
        value={formData ?? ''}
        debounceMs={debounceMs}
        onChange={({ target: { value } }) => {
          onChange(value === '' ? undefined : value);
        }}
        error={rawErrors?.length > 0 && !formData}
        inputProps={{ autoFocus, maxLength }}
      />
      <WbMarkdownHelperText helperText={description} />
    </>
  );
};
