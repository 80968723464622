import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { ConfigApi, IdentityApi } from '@backstage/core-plugin-api';
import { CustomAlertApi, mapToAuditEntry, MeshAuditApi } from '../../apis';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';

/**
 * Generates authorization header.
 * If provided, the Hasura admin secret takes precedence over the access token.
 * @param adminSecret
 * @param accessToken
 * @returns a correct authorization header
 */
function getAuthorizationHeaderForHasura(
  adminSecret: string | undefined,
  accessToken: string | undefined,
): Record<string, string> {
  if (!accessToken && !adminSecret) {
    return {};
  }

  if (!adminSecret) {
    return {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  return {
    'x-hasura-admin-secret': adminSecret,
  };
}

export async function createApolloClient(
  auditApi: MeshAuditApi,
  alertApi: CustomAlertApi,
  identityApi: IdentityApi,
  hasuraUrl: string,
  hasuraAdminSecret?: string,
): Promise<ApolloClient<NormalizedCacheObject>> {
  const httpLink = createHttpLink({
    uri: hasuraUrl,
    fetch: fetch,
  });

  const batchHttpLink = new BatchHttpLink({
    uri: hasuraUrl,
    batchMax: 100, // No more than 5 operations per batch
    batchInterval: 100, // Wait no more than 100ms after first batched operation
  });

  const getWsLink = async (): Promise<GraphQLWsLink> => {
    const credentials = await identityApi.getCredentials();
    return new GraphQLWsLink(
      createClient({
        url: hasuraUrl.replace('http', 'ws'),
        connectionParams: async () => {
          const token = credentials.token;
          return {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
        },
      }),
    );
  };

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    await getWsLink(),
    split(
      operation => operation.getContext().batched === true,
      batchHttpLink,
      httpLink,
    ),
  );

  const hasuraRemoteURL = new URL(hasuraUrl);
  const hasuraAddress = hasuraRemoteURL.origin;
  const hasuraEndpoint = hasuraRemoteURL.pathname;

  const auditLink = new ApolloLink((operation, forward) => {
    identityApi.getBackstageIdentity().then(backstageId => {
      const auditEntry = mapToAuditEntry(
        operation,
        backstageId,
        hasuraAddress,
        hasuraEndpoint,
      );

      if (auditEntry) auditApi.write(auditEntry);
    });

    return forward(operation);
  });

  const authLink = setContext(async (_: any, { headers = {} }: any) => {
    const credentials = await identityApi.getCredentials();

    const hasuraAuthHeader = getAuthorizationHeaderForHasura(
      hasuraAdminSecret,
      credentials.token,
    );

    return {
      headers: {
        ...headers,
        ...hasuraAuthHeader,
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (extensions.code === 'invalid-jwt') {
          alertApi.post({
            message:
              'Whoops! It looks like your access grant is expired. Please refresh the page',
            severity: 'warning',
          });

          // TODO (manuel): implement silent refresh token flow here
        }
      });
    }

    // TODO (manuel): check if network error needs to be handled differently or not handled at all (like now)
    // if (networkError) {
    //   console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    // }
    return forward(operation);
  });

  return new ApolloClient({
    connectToDevTools: false, // needs to be false in production env
    link: from([errorLink, auditLink, authLink, splitLink]),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
}

/**
 * Retrieves Hasura baseUrl from app-config.local using ConfigApi
 * @param configApi
 * @returns hasura baseUrl string
 */
export function getHasuraUrlFromConfig(configApi: ConfigApi) {
  return configApi.getString('mesh.marketplace.baseUrl');
}
