import React, { useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import {
  Avatar,
  Box,
  Popover,
  Typography,
  Link,
  ListItem,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { NavLink } from 'react-router-dom';
import { useUserProfile } from '@agilelab/plugin-wb-platform';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: '64px',
      textTransform: 'none',
      padding: 0,
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      pointerEvents: 'auto',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      backgroundColor: theme.palette.white,
      borderRadius: 0,
      minWidth: '120px',
      boxShadow: '0px 0px 50px #92929226',
    },
    icon: {
      color: theme.palette.primary.main,
      transition: 'transform 0.2s',
    },
    iconOpen: {
      transform: 'rotate(-90deg)',
    },
    menuSubItem: {
      opacity: '0.53',
      '&:hover': {
        opacity: '1',
      },
      '& p': {
        borderBottom: '1px solid',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        borderColor: theme.palette.primary.main,
      },
      '&:last-child': {
        '& p': {
          borderBottom: '0',
        },
      },
    },
  }),
);

export function UserSection() {
  const classes = useStyles();
  const [openPopOver, setOpenPopOver] = useState(false);
  const popoverAnchor = React.useRef(null);
  const { displayName, profile } = useUserProfile();
  const identityApi = useApi(identityApiRef);
  const theme = useTheme();

  const handlePopoverOpen = (
    _event: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    setOpenPopOver(true);
  };

  const handlePopoverClose = () => {
    setOpenPopOver(false);
  };

  return (
    <div style={{ marginLeft: theme.spacing(2) }}>
      <ListItem
        button
        onClick={handlePopoverOpen}
        ref={popoverAnchor}
        className={classes.button}
        onMouseLeave={handlePopoverClose}
        onMouseEnter={e => {
          if (openPopOver) handlePopoverOpen(e);
        }}
      >
        <Avatar
          alt="profile image"
          src={profile.picture}
          style={{ width: theme.spacing(6), height: theme.spacing(6) }}
        />
        <Box>
          <Typography
            style={{
              color: theme.palette.primary.main,
              padding: theme.spacing(2),
            }}
          >
            {displayName}
          </Typography>
        </Box>
        <ExpandMoreIcon
          className={clsx(classes.icon, openPopOver && classes.iconOpen)}
        />
      </ListItem>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={openPopOver}
        anchorEl={popoverAnchor.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        disableScrollLock
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          onMouseEnter: handlePopoverOpen,
          onMouseLeave: handlePopoverClose,
        }}
      >
        <Link
          component={NavLink}
          to="settings"
          underline="none"
          className={classes.menuSubItem}
          onClick={() => setOpenPopOver(false)}
          onTouchStart={e => e.stopPropagation()}
        >
          <Typography variant="body1">Settings and Profile</Typography>
        </Link>
        <Link
          component={NavLink}
          to="/"
          onClick={() => identityApi.signOut()}
          underline="none"
          aria-label="sign out"
          className={classes.menuSubItem}
          onTouchStart={e => e.stopPropagation()}
        >
          <Typography variant="body1">Sign Out</Typography>
        </Link>
      </Popover>
    </div>
  );
}
