import React from 'react';
import {
  NotStartedIcon,
  ProgressErrorIcon,
  ProgressRunningIcon,
  ProgressSuccessIcon,
  TerminatedIcon,
} from '../Icons';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { Theme, useTheme } from '@material-ui/core';
import { TerminatingIcon } from '../../ReleaseDetailPage/ProvisioningCard/LatestProvisioningAccordion/Icons';

export function StepIcon({ status }: { status: DagStatus }) {
  const theme = useTheme();

  switch (status) {
    case DagStatus.PASSED:
      return <ProgressSuccessIcon htmlColor={theme.palette.success.main} />;
    case DagStatus.FAILED:
      return (
        <ProgressErrorIcon
          htmlColor={theme.palette.error.main}
          style={{ stroke: theme.palette.error.main }}
        />
      );
    case DagStatus.TERMINATED:
      return <TerminatedIcon />;
    case DagStatus.RUNNING:
      return <ProgressRunningIcon htmlColor={theme.palette.accent.main} />;
    case DagStatus.WAITING:
      return <ProgressRunningIcon htmlColor={theme.palette.accent.main} />;
    case DagStatus.NOT_STARTED:
      return <NotStartedIcon />;
    case DagStatus.TERMINATING:
      return <TerminatingIcon />;
    default:
      return null;
  }
}

export function getDagStatusColor(status: DagStatus, theme: Theme) {
  switch (status) {
    case DagStatus.PASSED:
      return theme.palette.success.main;
    case DagStatus.FAILED:
      return theme.palette.error.main;
    case DagStatus.TERMINATING:
      return theme.palette.error.main;
    case DagStatus.RUNNING:
      return theme.palette.accent.main;
    default:
      return theme.palette.warning.main;
  }
}
