import {
  ValidationPhaseKind,
  ReleaseEntity,
  TaskAction,
  DagStatus,
  Dag,
} from '@agilelab/plugin-wb-builder-common';
import {
  GovernanceEntityType,
  Status as PolicyStatus,
} from '@agilelab/plugin-wb-governance-common';

export type MetricSpecificResult = {
  value?: number;
  details?: any;
  threshold: any;
};

export type Task = {
  id: string;
  action: TaskAction;
  validatedDescriptor?: string;
  startTime: string;
  name: string;
  displayName: string;
  status: DagStatus;
  errors: string[];
  details?: any;
  governanceEntityId?: string;
  governanceEntityStatus?: PolicyStatus;
  governanceEntityType?: GovernanceEntityType;
  validationPhaseKind?: ValidationPhaseKind;
  metricSpecificResult?: MetricSpecificResult;
  componentName?: string;
};

export type PublicInfo = {
  valid: boolean;
  error: { errors: string[] } | null;
};

export type SpecificCoordinatorResult = {
  status: DagStatus;
  info: {
    privateInfo: any;
    publicInfo: PublicInfo;
  };
};

export type Test = {
  id: string;
  startDate: string;
  status: DagStatus;
  tasks: Task[];
};

export type Descriptor = {
  name: string;
  code: string;
  language: string;
};

export type Deploy = {
  id: string;
  idRelease: string;
  version?: string;
  status: DagStatus;
  deployDate: string;
  deployEndDate?: string;
  action: string;
  steps: Dag[];
  token?: string;
};

export type ReleaseWithDeploys = ReleaseEntity & {
  deploys: Deploy[];
};

export enum TestType {
  POLICY = 'policy',
  COMPONENT = 'component',
}
