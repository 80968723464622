import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const UploadIcon = () => (
  <SvgIcon>
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="UploadIcon"
    >
      <path d="M5 20h14v-2H5v2zm0-10h4v6h6v-6h4l-7-7-7 7z" />
    </svg>
  </SvgIcon>
);
