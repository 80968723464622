import { Box, makeStyles } from '@material-ui/core';
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

const useStyles = (color?: string) =>
  makeStyles(theme => ({
    horizontalLine: {
      background: color || theme.palette.grey[300],
      position: 'absolute',
      height: '2px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    verticalLine: {
      background: color || theme.palette.grey[300],
      position: 'absolute',
      width: '2px',
    },
    dot: {
      borderRadius: '100%',
      background: color || theme.palette.grey[300],
      width: '6px',
      height: '6px',
      marginRight: '-4px',
      zIndex: 99,
    },
  }));

interface Props {
  children?: React.ReactNode;
  root: JSX.Element;
  left?: number;
  gap?: number;
  color?: string;
}

interface TreeNodeProps extends Props {
  node: React.ReactElement;
  index: number;
}

const TreeNode: React.FC<TreeNodeProps> = ({
  children,
  left = 20,
  gap = 10,
  node,
  index,
  color,
}) => {
  const classes = useStyles(color)();
  const ref = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number>(0);

  const getVerticalLineStyle = useCallback(
    (currentHeight: number) => {
      return {
        top: -gap,
        height:
          index + 1 < React.Children.count(children)
            ? currentHeight + gap
            : currentHeight / 2 + gap,
        left: -left / 2,
      };
    },
    [children, gap, index, left],
  );

  const getHorizontalLineStyle = useCallback(
    (currentHeight: number) => {
      return {
        top: currentHeight / 2,
        left: -(left / 2),
        width: left / 2,
      };
    },
    [left],
  );

  useLayoutEffect(() => {
    if (ref.current) {
      setHeight(ref.current.getBoundingClientRect().height);
    }
  }, []);

  return (
    <Box position="relative" style={{ marginLeft: left }}>
      <Box
        className={classes.verticalLine}
        style={getVerticalLineStyle(height)}
      />

      <Box
        className={classes.horizontalLine}
        style={getHorizontalLineStyle(height)}
      >
        <Box className={classes.dot} />
      </Box>

      <div ref={ref}>{node}</div>
    </Box>
  );
};

export const WbTree: React.FC<Props> = props => {
  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      style={{ gap: props.gap || 10 }}
    >
      {props.root}

      {React.Children.map(props.children, (child, i) => (
        <TreeNode
          key={i}
          index={i}
          node={child as React.ReactElement}
          {...props}
        />
      ))}
    </Box>
  );
};
