import {
  ComponentType,
  SystemType,
  WitboostComponent,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';

export function isSystemType(entity: Entity): entity is SystemType {
  return entity.kind.toLocaleLowerCase('en-US') === 'systemtype';
}

export function isSystemInstance(entity: Entity): entity is WitboostSystem {
  return entity.kind.toLocaleLowerCase('en-US') === 'system';
}

export function isComponentType(entity: Entity): entity is ComponentType {
  return entity.kind.toLocaleLowerCase('en-US') === 'componenttype';
}

export function isComponentInstance(
  entity: Entity,
): entity is WitboostComponent {
  return entity.kind.toLocaleLowerCase('en-US') === 'component';
}
