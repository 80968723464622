import React from 'react';
import {
  makeStyles,
  createStyles,
  Chip,
  Tooltip,
  Typography,
  Theme,
  useTheme,
} from '@material-ui/core';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import { Task } from '../../types';
import {
  ThresholdResult,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';

interface Props {
  metric: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailName: {
      width: '150px',
      minWidth: '150px',
      marginLeft: theme.spacing(2),
    },
    policyDesc: {
      flex: 1,
    },
    version: {
      padding: theme.spacing(0, 2),
    },
    chip: {
      margin: 0,
    },
  }),
);

export const MetricComponentItem: React.FC<Props> = ({ metric, task }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <ThresholdResult
        value={task.metricSpecificResult?.value || 0}
        result={task.metricSpecificResult?.threshold}
        hasError={task.errors && task.errors.length > 0}
        dimension={31.2}
      />
      <div className={classes.detailName}>
        <WbTruncatedTypographyWrapper
          style={{
            marginRight: theme.spacing(1),
          }}
          value={metric.name}
        />
      </div>
      <div className={classes.version}>
        <Tooltip title={`version: ${metric.version}`}>
          <Typography>{`v:${metric.version}`}</Typography>
        </Tooltip>
      </div>
      <div className={classes.policyDesc}>
        <WbTruncatedTypographyWrapper
          style={{
            marginRight: theme.spacing(1),
          }}
          value={metric.description ?? ''}
        />
      </div>
      <Chip label="Metric" className={classes.chip} color="primary" />
    </>
  );
};
