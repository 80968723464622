import { WbTable } from '@agilelab/plugin-wb-platform';
import React, { useEffect } from 'react';
import { useComponentsTableColumns } from '../../hooks/useComponentsTableColumns';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { ComponentWithStatus } from '../../types';
import { TableHead, TableRow, TableCell } from '@material-ui/core';

export const ComponentsTable: React.FC = () => {
  const { fetchReleaseDescriptor, components, currentDescriptor } =
    useReleaseDetailPageContext();
  const columns = useComponentsTableColumns();

  const style = { style: { fontWeight: 500 } };

  useEffect(() => {
    fetchReleaseDescriptor();
  }, [fetchReleaseDescriptor]);

  return (
    <WbTable<ComponentWithStatus>
      stickyHeader
      components={{
        tableLoader: {
          loading: currentDescriptor === '',
        },
        tableContent: {
          columns,
          rows: currentDescriptor !== '' ? components : undefined,
        },
        tableHeader: (
          <TableHead>
            <TableRow>
              <TableCell {...style}>Name</TableCell>
              <TableCell {...style}>Type</TableCell>
              <TableCell {...style}>Description</TableCell>
              <TableCell {...style}>Dependencies</TableCell>
              <TableCell {...style}>Status</TableCell>
            </TableRow>
          </TableHead>
        ),
      }}
      styles={{
        container: { height: '100%', flex: '1 1 0' },
      }}
    />
  );
};
