import React, { useMemo } from 'react';
import { Task } from '../../types';
import { createStyles, ListItem, makeStyles, Theme } from '@material-ui/core';
import { ComponentItem } from './ComponentItem';
import { PolicyComponentItem } from './PolicyComponentItem';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';
import { MetricComponentItem } from './MetricComponentItem';

interface Props {
  task: Task;
  policies: GovernanceEntitiesQueryType['cgp_governance_entity'];
  onClick: (task: Task) => void;
  selected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: '50px',
      '&:hover': {
        backgroundColor: theme.palette.bkg.primary,
        color: theme.palette.getContrastText(theme.palette.bkg.primary),
      },
    },
  }),
);

export const TestTableListItem: React.FC<Props> = ({
  task,
  policies,
  onClick,
  selected,
}) => {
  const classes = useStyles();
  const policy = useMemo(
    () =>
      policies.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Policy,
      ),
    [policies, task.governanceEntityId, task.governanceEntityType],
  );
  const metric = useMemo(
    () =>
      policies.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Metric,
      ),
    [policies, task.governanceEntityId, task.governanceEntityType],
  );

  return (
    <ListItem
      selected={selected}
      onClick={() => {
        onClick(task);
      }}
      className={classes.detailWrapper}
      style={{
        cursor:
          !!policy || !!metric || task.status === DagStatus.FAILED
            ? 'pointer'
            : 'auto',
      }}
    >
      {policy && <PolicyComponentItem policy={policy} task={task} />}
      {metric && <MetricComponentItem metric={metric} task={task} />}
      {!policy && !metric && (
        <ComponentItem
          description={task.displayName ?? task.name}
          componentName={task.componentName}
          status={task.status}
        />
      )}
    </ListItem>
  );
};
