import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const BookmarkIcon = createSvgIcon(
  <>
    <svg viewBox="0 0 24 24">
      <path d="M4.5 5.25C4.5 3.59315 5.84315 2.25 7.5 2.25H16.5C18.1569 2.25 19.5 3.59315 19.5 5.25V21.75L12 17.25L4.5 21.75V5.25Z" />
      <path
        d="M17.25 7.39034L10.8476 13.5L6.75 9.58355L8.19439 8.203L10.8476 10.7389L15.8056 6L17.25 7.39034Z"
        fill="white"
      />
    </svg>
  </>,
  'BookmarkIcon',
);
