import { z } from 'zod';

export type PermissionMetadata = {
  displayName?: string;
  description?: string;
  scope?: string;
};
export type PermissionEntity = PermissionMetadata & {
  id: string;
};

export type CompletePermission = PermissionEntity & {
  entityRef?: string;
};

export const RoleVisibilitySchema = z.enum(['internal', 'user']);

export type RoleVisibility = z.infer<typeof RoleVisibilitySchema>;

export const RoleSchema = z.object({
  id: z.string().min(1),
  displayName: z.string().min(1).optional(),
  description: z.string().min(1).optional(),
  visibility: RoleVisibilitySchema,
});

export type Role = z.infer<typeof RoleSchema>;

/**
 * Represents a Role entity read from database (thus including an id)
 * This is kind of a repetition, since the id is already in the Role type, and stays here just for improved readability of consumers of this type
 */
export type RoleEntity = Partial<Role> & { id: string };

export type GetRolesSubjects = {
  total: number;
  roles: RoleEntity[];
};

export type GetPermissionsSubjects = {
  total: number;
  permissions: PermissionEntity[];
};

export interface RolePermission {
  roleId: string;
  permissionId: string;
}

/**
 * Represents a Role Subject entity read from database (thus including an id)
 */
export type RoleSubjectEntity = { id: number } & Partial<RoleSubject>;

export const RoleSubjectSchema = z.object({
  subject: z.string(),
  roleId: z.string(),
  entityRef: z.string().optional().nullable(),
  enabled: z.boolean().default(false),
});

export type RoleSubject = z.infer<typeof RoleSubjectSchema>;

/**
 * List of supported entities kinds that can be used in the entity_ref field in subjects_roles table
 */
export const ScopeKindSchema = z.enum(['System', 'Resource', 'Domain']);

export type ScopeKind = z.infer<typeof ScopeKindSchema>;

export interface RoleSettingsView {
  id: string;
  roleId: string;
  subject: string;
  subjectdisplayname: string;
  subject_kind: string;
  roles: string;
  entity_ref: string;
  entity_ref_displayname: string;
  transformed_entity_ref: string;
  displayName: string;
  description: string;
  visibility: string;
  count: string;
  warning: boolean;
}

export interface PermissionSettingsView {
  id: string;
  subject: string[];
  entity_ref: string;
  display_name: string;
  description: string;
  count: string;
  transformed_entity_ref: string;
  subjectdisplayname: string[];
  entity_ref_displayname: string;
  subject_kind: string[];
  warning: boolean;
}

export interface PermissionDetail {
  subjectdisplayname: string;
  display_name: string;
  description: string;
}

export interface RoleDetails {
  display_name: string;
  description: string;
}
