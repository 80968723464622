import {
  GenericField,
  WbCard,
  WbCardActionButton,
  WbCardContent,
  WbTagsArray,
} from '@agilelab/plugin-wb-platform';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  Box,
  Link,
  makeStyles,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { omit } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router';
import { useReleaseDetailPageContext } from './context/useReleaseDetailPageContext';
import { Label } from './Label';
import { ReleaseDeploymentStatus } from './ReleaseDeploymentStatus';

const useStyles = makeStyles(() => ({
  ellipsis: {
    display: 'inline-block',
    maxWidth: 250,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  section: {
    display: 'grid',
    flexWrap: 'wrap',
    gridTemplateColumns: 'repeat(3, 1fr)',
    rowGap: '32px',
    padding: '16px 0px',
  },
}));

export const AboutCard: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { release, marketplaceLink, deploymentUnitStatusState } =
    useReleaseDetailPageContext();
  const navigate = useNavigate();
  const config = useApi(configApiRef);

  const marketplaceEnabled =
    config.getOptionalBoolean('mesh.marketplace.enabled') ?? true;

  const repoLink =
    release.metadata.annotations?.['backstage.io/source-location'].slice(4) ||
    release.metadata.repoUrl;
  let provisioningInfo =
    deploymentUnitStatusState.value?.provisioningDetails?.provisioningInfo
      ?.privateInfo;

  if (!marketplaceEnabled || !marketplaceLink) {
    provisioningInfo = omit(provisioningInfo, ['marketplaceInfo']);
  }

  return (
    <WbCard
      title="About"
      actions={
        marketplaceEnabled && marketplaceLink ? (
          <Tooltip title={marketplaceLink}>
            <WbCardActionButton
              label="View in Marketplace"
              variant="outlined"
              onClick={() => {
                if (marketplaceLink.startsWith('http')) {
                  window.location.href = marketplaceLink;
                  return;
                }
                navigate(marketplaceLink);
              }}
            />
          </Tooltip>
        ) : undefined
      }
    >
      <WbCardContent>
        <Box display="flex" flexDirection="row" style={{ overflow: 'auto' }}>
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            marginRight="16px"
          >
            <Box className={classes.section}>
              <Label title="Name">
                <Tooltip title={release.metadata.name}>
                  <Typography className={classes.ellipsis}>
                    {release.metadata.name}
                  </Typography>
                </Tooltip>
              </Label>
              <Label title="Created At">
                {format(
                  parseISO(
                    release.metadata.isSnapshot && release.metadata.committedAt
                      ? release.metadata.committedAt
                      : release.metadata.createdAt,
                  ),
                  'yyyy/MM/dd HH:mm:ss',
                )}
              </Label>
              {release.metadata.releasedAt && (
                <Label title="Released At">
                  {format(
                    parseISO(release.metadata.releasedAt),
                    'yyyy/MM/dd HH:mm:ss',
                  )}
                </Label>
              )}
            </Box>
            <Box
              style={{
                borderTop: `1px solid ${theme.palette.grey[200]}`,
                borderBottom: `1px solid ${theme.palette.grey[200]}`,
              }}
              className={classes.section}
            >
              <Label title="Repository Link">
                <Tooltip title={repoLink}>
                  <Link
                    href={repoLink}
                    target="_blank"
                    rel="noopener"
                    className={classes.ellipsis}
                  >
                    {repoLink}
                  </Link>
                </Tooltip>
              </Label>
              {Object.values(provisioningInfo ?? {}).map(info => {
                return (
                  <GenericField
                    key={info.label ?? ''}
                    label={info.label ?? '-'}
                    href={info.href}
                    value={info.value ?? info.href}
                    type={info.type ?? 'string'}
                    textVariant="body2"
                    labelStyle={{ paddingBottom: '4px' }}
                  />
                );
              })}
            </Box>
            <Box className={classes.section}>
              <Label title="Tags">
                <WbTagsArray
                  tags={(release.metadata.tags || []).map(t => ({ tagFQN: t }))}
                />
                {!release.metadata.tags?.length && <>No Tags</>}
              </Label>
            </Box>
          </Box>

          <Box
            display="grid"
            style={{
              borderLeft: `1px solid ${theme.palette.grey[200]}`,
              padding: '16px 16px',
              rowGap: '0px',
              minWidth: '20%',
            }}
          >
            <ReleaseDeploymentStatus />
          </Box>
        </Box>
      </WbCardContent>
    </WbCard>
  );
};
