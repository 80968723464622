/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import { Entity } from '@backstage/catalog-model';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
import YAML from 'yaml';
import { CodeSnippet } from '@backstage/core-components';
import { trimEnd } from 'lodash';
import { useCatalogFilename } from '../../hooks';

/**
 * Props for {@link PreviewCatalogInfoComponent}.
 *
 * @public
 */
export interface PreviewCatalogInfoComponentProps {
  repositoryUrl: string;
  entities: Entity[];
  classes?: { card?: string; cardContent?: string };
}

/**
 * Previews information about an entity to create.
 *
 * @public
 */
export const PreviewCatalogInfoComponent = (
  props: PreviewCatalogInfoComponentProps,
) => {
  const { repositoryUrl, entities, classes } = props;
  const catalogFilename = useCatalogFilename();

  return (
    <Card variant="outlined" className={classes?.card}>
      <CardHeader
        title={
          <code>{`${trimEnd(repositoryUrl, '/')}/${catalogFilename}`}</code>
        }
      />

      <CardContent className={classes?.cardContent}>
        <CodeSnippet
          text={entities
            .map(e => YAML.stringify(e))
            .join('---\n')
            .trim()}
          language="yaml"
        />
      </CardContent>
    </Card>
  );
};
