import { createTopic } from '../lib';
import { z } from 'zod';

const marketplaceConsumableInterfaceNavigatedEventSchema = z.object({
  entityUrn: z.string(),
  path: z.string(),
});

export type MarketplaceConsumableInterfaceNavigatedEvent = z.infer<
  typeof marketplaceConsumableInterfaceNavigatedEventSchema
>;

export const marketplaceConsumableInterfaceNavigatedTopic =
  createTopic<MarketplaceConsumableInterfaceNavigatedEvent>(
    {
      namespace: 'marketplace',
      name: 'consumable-interface-navigated',
      description:
        'User navigated to a consumable interface in the marketplace.',
    },
    marketplaceConsumableInterfaceNavigatedEventSchema,
  );
