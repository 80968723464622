import React from 'react';
import { IconButton, useTheme } from '@material-ui/core';

interface Props {
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  disabled?: boolean;
}

export const FilePickerUploadButton: React.FC<Props> = ({
  onClick,
  disabled,
}) => {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          borderLeft: `1px solid ${theme.palette.grey[400]}`,
          height: 15,
        }}
      />
      <IconButton
        size="small"
        onClick={onClick}
        disabled={disabled}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: 5,
          marginRight: -5,
        }}
      >
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="folder_browse-icon">
            <path
              id="Vector"
              d="M10.6918 6.95101C12.255 6.95101 13.5056 8.18876 13.5056 9.73595C13.5056 10.2806 13.3493 10.7942 13.0741 11.2212L15 13.1398L14.1309 14L12.18 12.1001C11.7485 12.3662 11.2358 12.5209 10.6918 12.5209C9.12863 12.5209 7.87807 11.2831 7.87807 9.73595C7.87807 8.18876 9.12863 6.95101 10.6918 6.95101ZM10.6918 8.18876C10.2772 8.18876 9.87964 8.35176 9.58648 8.64192C9.29332 8.93207 9.12863 9.32561 9.12863 9.73595C9.12863 10.1463 9.29332 10.5398 9.58648 10.83C9.87964 11.1201 10.2772 11.2831 10.6918 11.2831C11.1064 11.2831 11.504 11.1201 11.7972 10.83C12.0903 10.5398 12.255 10.1463 12.255 9.73595C12.255 9.32561 12.0903 8.93207 11.7972 8.64192C11.504 8.35176 11.1064 8.18876 10.6918 8.18876ZM6.00223 2L7.25279 3.23775H12.255C12.5867 3.23775 12.9048 3.36816 13.1393 3.60028C13.3738 3.8324 13.5056 4.14723 13.5056 4.4755V6.83342C12.7495 6.11358 11.7409 5.71203 10.6918 5.71325C9.6139 5.71325 8.58013 6.13707 7.81792 6.89147C7.05572 7.64587 6.62751 8.66906 6.62751 9.73595C6.62751 10.5343 6.85887 11.2831 7.25279 11.902H2.25056C1.91889 11.902 1.60081 11.7716 1.36628 11.5395C1.13175 11.3074 1 10.9925 1 10.6643V3.23775C1 2.5508 1.5565 2 2.25056 2H6.00223Z"
              fill={theme.palette.secondary.main}
            />
          </g>
        </svg>
      </IconButton>
    </div>
  );
};
