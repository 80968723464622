import { UserEntity } from '@backstage/catalog-model';
import { Avatar, Link } from '@backstage/core-components';
import { entityRouteParams } from '@backstage/plugin-catalog-react';
import {
  makeStyles,
  Theme,
  createStyles,
  Grid,
  Box,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { generatePath } from 'react-router';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[0],
      borderRadius: '4px',
      overflow: 'visible',
      position: 'relative',
      margin: theme.spacing(4, 1, 1),
      flex: '1',
      minWidth: '0px',
    },
    email: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      display: 'inline-block',
      maxWidth: '100%',
      '&:hover': {
        overflow: 'visible',
        whiteSpace: 'normal',
      },
    },
  }),
);

export function MemberComponent(props: { member: UserEntity }) {
  const classes = useStyles();
  const {
    metadata: { name: metaName, description },
    spec: { profile },
  } = props.member;
  const displayName = profile?.displayName ?? metaName;

  return (
    <Grid item container xs={12} sm={6} md={4} xl={2}>
      <Box className={classes.card}>
        <Box
          display="flex"
          flexDirection="column"
          m={3}
          alignItems="center"
          justifyContent="center"
        >
          <Avatar
            displayName={displayName}
            picture={profile?.picture}
            customStyles={{
              position: 'absolute',
              top: '-2rem',
            }}
          />
          <Box
            pt={2}
            sx={{
              maxWidth: '100%',
            }}
            textAlign="center"
          >
            <Typography variant="h5">
              <Link
                to={generatePath(
                  `/catalog/:namespace/user/${metaName}`,
                  entityRouteParams(props.member),
                )}
              >
                {displayName}
              </Link>
            </Typography>
            {profile?.email && (
              <Link className={classes.email} to={`mailto:${profile.email}`}>
                {profile.email}
              </Link>
            )}
            {description && (
              <Typography variant="subtitle2">{description}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
}
