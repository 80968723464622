import { IdentityApi } from '@backstage/core-plugin-api';
import { useEffect } from 'react';

import { TimestampStore } from './timestampStore';

export type UseLogoutDisconnectedUserEffectProps = {
  enableEffect: boolean;
  autologoutIsEnabled: boolean;
  idleTimeoutSeconds: number;
  lastSeenOnlineStore: TimestampStore;
  identityApi: IdentityApi;
};

export const useLogoutDisconnectedUserEffect = ({
  enableEffect,
  autologoutIsEnabled,
  idleTimeoutSeconds,
  lastSeenOnlineStore,
  identityApi,
}: UseLogoutDisconnectedUserEffectProps) => {
  useEffect(() => {
    /**
     * Considers disconnected users as inactive users.
     * If all witboost tabs are closed and idleTimeoutMinutes are passed then logout the user anyway.
     */
    if (autologoutIsEnabled && enableEffect) {
      const lastSeenOnline = lastSeenOnlineStore.get();
      if (lastSeenOnline) {
        const now = new Date();
        const nowSeconds = Math.ceil(now.getTime() / 1000);
        const lastSeenOnlineSeconds = Math.ceil(
          lastSeenOnline.getTime() / 1000,
        );
        if (nowSeconds - lastSeenOnlineSeconds > idleTimeoutSeconds) {
          identityApi.signOut();
        }
      }
      lastSeenOnlineStore.save(new Date()); // save for the first time when app is loaded, so that if user logs in and does nothing we still have a lastSeenOnline value in store
    } else {
      lastSeenOnlineStore.delete();
    }
  }, [
    autologoutIsEnabled,
    enableEffect,
    identityApi,
    idleTimeoutSeconds,
    lastSeenOnlineStore,
  ]);
};
