import {
  GenericEntityType,
  GenericProviderType,
  Provider,
  ProviderFilters,
  TemplateEntity,
} from '@agilelab/plugin-wb-platform';
import { Environment } from '@agilelab/plugin-wb-platform-common';
import { WitboostSearchApi } from '@agilelab/plugin-wb-search';
import { CatalogApi } from '@backstage/catalog-client';
import React from 'react';
import { ConsumablesHandlerProvider } from './ConsumableProvider/ConsumablesProvider';
import { DomainHandlerProvider } from './DomainProvider/DomainProvider';
import { ResourceHandlerProvider } from './ResourceProvider/ResourceProvider';
import { SystemHandlerProvider } from './SystemProvider/SystemProvider';

export enum AvailableEntities {
  SYSTEM = 'System',
  DOMAIN = 'Domain',
  RESOURCE = 'Resource',
  CONSUMABLE = 'Consumable',
}

export const entityHandler = (
  searchApi: WitboostSearchApi,
  catalogApi: CatalogApi,
  availableKinds: TemplateEntity[],
  environments: Environment[],
) =>
  new Map<string, Provider<GenericEntityType[], ProviderFilters>>([
    [
      AvailableEntities.SYSTEM,
      new SystemHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.DOMAIN,
      new DomainHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.RESOURCE,
      new ResourceHandlerProvider(catalogApi, availableKinds),
    ],
    [
      AvailableEntities.CONSUMABLE,
      new ConsumablesHandlerProvider(searchApi, availableKinds, environments),
    ],
  ]);

export function contextProviderFactory<
  T extends GenericProviderType<GenericEntityType[], ProviderFilters>,
>(): [() => T, React.Provider<T | undefined>] {
  const context = React.createContext<T | undefined>(undefined);

  const useProvider = () => {
    const c = React.useContext(context);

    if (c === undefined) {
      throw new Error(
        'The hook "useProvider" must be used within an existing context.',
      );
    }

    return c;
  };

  return [useProvider, context.Provider];
}
