import { CustomView } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { SearchResultInfo } from '../types';
import { Loader } from './Loader';

export const SearchResultComponentInfo: SearchResultInfo = ({ data }) => {
  return (
    <CustomView
      id="marketplace_search_component"
      loader={<Loader />}
      data={data}
    />
  );
};
