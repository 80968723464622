import React, { useEffect, useMemo } from 'react';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { isRunningStatus } from '@agilelab/plugin-wb-builder-common';
import { AsyncTestTableRow } from './AsyncTestTableRow';
import { TaskDrawer } from './TaskDrawer/TaskDrawer';
import { TestTableLoadingRow } from './TestTableLoadingRow';
import { TestTableLoading } from './TestTableLoading';
import { TestCurrentRow } from './TestCurrentRow';
import { TestCurrentEmptyState } from './TestCurrentEmptyState';
import { AssistantDrawer } from './AssistantDrawer/AssistantDrawer';

export const TestCurrent: React.FC = () => {
  const {
    selectedTask,
    setSelectedTask,
    showCurrentTest,
    lastTestState,
    isValidatingDescriptor,
    environment,
    monacoEditorRef,
    fetchAssistant,
  } = useEditorPageContext();

  useEffect(() => {
    fetchAssistant();
  }, [fetchAssistant]);

  const isRunning = useMemo(() => {
    return (
      lastTestState.value &&
      (isRunningStatus(lastTestState.value.status) ||
        lastTestState.value.tasks.some(task => isRunningStatus(task.status)))
    );
  }, [lastTestState.value]);

  if (isValidatingDescriptor)
    return (
      <TestTableLoading>
        <TestTableLoadingRow />
      </TestTableLoading>
    );

  if (
    !lastTestState.value ||
    !showCurrentTest ||
    lastTestState.value?.environment !== environment
  )
    return !showCurrentTest ? <TestCurrentEmptyState /> : <></>;

  return (
    <>
      <TestTableLoading>
        {isRunning ? (
          <AsyncTestTableRow test={lastTestState.value} />
        ) : (
          <TestCurrentRow test={lastTestState.value} />
        )}
      </TestTableLoading>

      <TaskDrawer
        task={selectedTask}
        open={Boolean(selectedTask)}
        onClose={() => {
          setSelectedTask(null);
          monacoEditorRef.current?.applyDecorations(
            undefined,
            undefined,
            'white',
          );
        }}
      />

      <AssistantDrawer />
    </>
  );
};
