import { z } from 'zod';
import { UserHeader } from './types';

export const HeaderZodValidators = {
  name: z
    .string()
    .min(1, 'The header name is required')
    .max(128, 'The header name must contain at most 128 characters')
    .regex(
      /^[A-Za-z][A-Za-z0-9-]*$/,
      'The header name must start with a letter and must not contain any whitespace or special characters except hyphens',
    ),
  value: z
    .string()
    .min(1, 'The header value is required')
    .max(4096, 'The header value must contain at most 4096 characters'),
};

/**
 * @returns a list of error objects (if any)
 */
export const validateHeader = (
  header: UserHeader,
): { field?: string; errors: string[] }[] => {
  const validator = z.object({
    name: HeaderZodValidators.name,
    value: HeaderZodValidators.value,
    isSensitive: z.boolean(),
  });
  const validationRes = validator.safeParse(header);
  if (!validationRes.success) {
    const errors = validationRes.error.format();
    const nameErrors = errors.name
      ? [
          {
            field: 'name',
            errors: errors.name!._errors,
          },
        ]
      : [];
    const valueErrors = errors.value
      ? [
          {
            field: 'value',
            errors: errors.value!._errors,
          },
        ]
      : [];
    return [...nameErrors, ...valueErrors];
  }
  return [];
};
