import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import {
  ApiHolder,
  IdentityApi,
  identityApiRef,
  useApi,
  useApiHolder,
} from '@backstage/core-plugin-api';
import {
  ComponentType,
  DomainType,
  SystemType,
  Taxonomy,
} from '@agilelab/plugin-wb-builder-common';
import { useQuery } from '@tanstack/react-query';
import { practiceShaperApiRef } from '../../../../plugin';
import { hasCatalogProcessingErrors } from '@agilelab/plugin-wb-builder-catalog';

const fetchData = async (
  api: PracticeShaperApi,
  identityApi: IdentityApi,
  apiHolder: ApiHolder,
) => {
  const credentials = await identityApi.getCredentials();

  const fetchError = async (
    item: Taxonomy | DomainType | ComponentType | SystemType,
  ) => {
    const error = await hasCatalogProcessingErrors(item, {
      apis: apiHolder,
    });
    return { ...item, error };
  };

  const [taxonomies, domains, components, systems] = await Promise.all([
    (async () =>
      Promise.all(
        (
          await api.getTaxonomies({}, credentials)
        ).items.map(t => fetchError(t)),
      ))(),
    (async () =>
      Promise.all(
        (
          await api.getDomainTypes({}, credentials)
        ).items.map(t => fetchError(t)),
      ))(),
    (async () =>
      Promise.all(
        (
          await api.getComponentTypes({}, credentials)
        ).items.map(t => fetchError(t)),
      ))(),
    (async () =>
      Promise.all(
        (
          await api.getSystemTypes({}, credentials)
        ).items.map(t => fetchError(t)),
      ))(),
  ]);
  return {
    taxonomies,
    domains,
    components,
    systems,
  };
};

export const usePracticeShaperGraphData = () => {
  const identityApi = useApi(identityApiRef);
  const api = useApi(practiceShaperApiRef);
  const apiHolder = useApiHolder();

  const data = useQuery({
    queryKey: ['practice-shaper-graph'],
    queryFn: async () => {
      return fetchData(api, identityApi, apiHolder);
    },
  });

  return data;
};
