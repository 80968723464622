import { ReviewEntity } from '@agilelab/plugin-wb-marketplace-common';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import React from 'react';

import { useMarketplaceStyle } from '../../themes';
import { stringAvatar, toDateString } from '../../utils';
import { RatingStars } from './RatingStars';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      '& td': {
        padding: '0.5rem 0.05rem',
      },
    },
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: theme.spacing(1),
    },
  }),
);

export const MAX_DISPLAYED_COMMENT_LENGTH = 1000;
export const MAX_COMMENT_LENGTH = 5000;
export const MIN_COMMENT_LENGTH = 4;

export type RatingDetailsProps = {
  review: ReviewEntity;
};

export const RatingDetails = (props: RatingDetailsProps) => {
  const commonClasses = useMarketplaceStyle();
  const classes = useStyles();
  const [showMore, setShowMore] = React.useState(false);

  return (
    <Card elevation={0}>
      <CardContent>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  className={classes.avatar}
                  {...stringAvatar(props.review.reviewer_display_name)}
                />
              </Grid>
              <Grid item>
                <Typography className={commonClasses.text}>
                  {props.review.reviewer_display_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={commonClasses.text}>
                  {toDateString(props.review.timestamp)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <RatingStars rating={props.review.score} clickable={false} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {props.review.long_comment.length >
              MAX_DISPLAYED_COMMENT_LENGTH && (
              <div>
                <Typography className={commonClasses.text}>
                  {showMore
                    ? props.review.long_comment
                    : `${props.review.long_comment.substring(
                        0,
                        MAX_DISPLAYED_COMMENT_LENGTH,
                      )}...`}
                </Typography>
                <Button onClick={() => setShowMore(!showMore)}>
                  {showMore ? `Show less` : `Show more`}
                </Button>
              </div>
            )}
            {props.review.long_comment.length <=
              MAX_DISPLAYED_COMMENT_LENGTH && (
              <Typography className={commonClasses.text}>
                {props.review.long_comment}
              </Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
