import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';

type Props = {
  children?: React.ReactNode;
  title: string;
};

export const Label: React.FC<Props> = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Box display="flex" flexDirection="column" style={{ gap: '4px' }}>
      <Typography
        style={{ color: theme.palette.secondary.dark, fontWeight: 600 }}
        variant="caption"
      >
        {title.toUpperCase()}
      </Typography>
      {children}
    </Box>
  );
};
