import {
  CreationMetric,
  Metric,
  MetricGql,
} from '@agilelab/plugin-wb-governance-common';

import {
  parseInfrastructureTemplateIdFromPreprocessing,
  parseUseCaseTemplateIdFromPreprocessing,
} from './utils';

export const toCreateEditMetric = (gqlMetric: MetricGql): CreationMetric => {
  let parsedMetadata = {};

  try {
    parsedMetadata = JSON.parse(gqlMetric.additional_metadata);
  } catch (error) {
    parsedMetadata = {};
  }

  const domain = gqlMetric.content.domain;

  return {
    metricId: gqlMetric.governance_entity_id,
    groupId: gqlMetric.group_id,
    status: gqlMetric.status,
    name: gqlMetric.name,
    description: gqlMetric.description || '',
    context: gqlMetric.context,
    strategy: gqlMetric.strategy,
    resourceType: [gqlMetric.resource_type],
    timing: gqlMetric.timing,
    version: gqlMetric.version,
    selectors: gqlMetric.selector?.map(s => ({ path: s.jsonPath, ...s })) || [],
    tags: gqlMetric.governance_entity_tags.map(t => t.tag.name),
    environments:
      gqlMetric.governance_entity_environments?.map(e => e.environment.name) ||
      [],
    updateTime: new Date(gqlMetric.update_time),
    cronExpression: gqlMetric.cron_expression || undefined,
    interactionType: gqlMetric.interaction_type,
    thresholds: (gqlMetric.content?.thresholds.range || []).map(t => ({
      ...t,
      value: String(t.value),
      color: t.labelColor,
    })),
    domain: {
      min:
        domain?.lowerBound !== null && domain?.lowerBound !== undefined
          ? String(gqlMetric.content.domain?.lowerBound)
          : '',
      max:
        domain?.upperBound !== null && domain?.upperBound !== undefined
          ? String(gqlMetric.content.domain?.upperBound)
          : '',
    },
    externalUrl: gqlMetric.content.externalUrl,
    infrastructureTemplateId: parseInfrastructureTemplateIdFromPreprocessing(
      gqlMetric.preprocessing,
    ),
    engine: gqlMetric.engine,
    useCaseTemplateId: parseUseCaseTemplateIdFromPreprocessing(
      gqlMetric.preprocessing,
    ),
    preprocessing: gqlMetric.preprocessing || undefined,
    additionalMetadata: parsedMetadata,
    trigger: gqlMetric.trigger,
    expression: gqlMetric.content?.thresholds?.expression,
  };
};

export const toGovernanceMetric = (gqlMetric: MetricGql): Metric => {
  let parsedMetadata = {};

  try {
    parsedMetadata = JSON.parse(gqlMetric.additional_metadata);
  } catch (error) {
    parsedMetadata = {};
  }

  const domain = gqlMetric.content.domain;

  return {
    metricId: gqlMetric.governance_entity_id,
    groupId: gqlMetric.group_id,
    status: gqlMetric.status,
    name: gqlMetric.name,
    description: gqlMetric.description || '',
    context: gqlMetric.context,
    strategy: gqlMetric.strategy,
    resourceType: gqlMetric.resource_type.name,
    timing: gqlMetric.timing,
    version: gqlMetric.version,
    selectors: gqlMetric.selector?.map(s => ({ path: s.jsonPath, ...s })) || [],
    tags: gqlMetric.governance_entity_tags.map(t => t.tag.name),
    environments:
      gqlMetric.governance_entity_environments?.map(e => e.environment.name) ||
      [],
    updateTime: new Date(gqlMetric.update_time),
    cronExpression: gqlMetric.cron_expression || undefined,
    interactionType: gqlMetric.interaction_type,
    thresholds: (gqlMetric.content?.thresholds.range || []).map(t => ({
      ...t,
      value: String(t.value),
      color: t.labelColor,
    })),
    domain: {
      min:
        domain?.lowerBound !== null && domain?.lowerBound !== undefined
          ? String(gqlMetric.content.domain?.lowerBound)
          : '',
      max:
        domain?.upperBound !== null && domain?.upperBound !== undefined
          ? String(gqlMetric.content.domain?.upperBound)
          : '',
    },
    externalUrl: gqlMetric.content.externalUrl,
    infrastructureTemplateId: parseInfrastructureTemplateIdFromPreprocessing(
      gqlMetric.preprocessing,
    ),
    engine: gqlMetric.engine,
    useCaseTemplateId: parseUseCaseTemplateIdFromPreprocessing(
      gqlMetric.preprocessing,
    ),
    preprocessing: gqlMetric.preprocessing || undefined,
    additionalMetadata: parsedMetadata,
    trigger: gqlMetric.trigger,
    expression: gqlMetric.content?.thresholds?.expression,
  };
};
