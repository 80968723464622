import {
  TableRow,
  TableCell,
  Box,
  Typography,
  makeStyles,
  Badge,
  Tooltip,
  withStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import {
  AccessStatusEnum,
  RequestStatusButton,
  RequestsDetailsModal,
} from '@agilelab/plugin-wb-platform';
import CancelIcon from '@material-ui/icons/Cancel';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import { ACLByDP } from './DataAccessTab';

const useStyles = makeStyles(theme => ({
  tableRow: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.bkg.primary,
    },
  },
  border: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
}));

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -3,
      top: 22,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      backgroundColor: theme.palette.success.main,
    },
  }),
)(Badge);

export const Row = (props: { row: ACLByDP }) => {
  const { row } = props;
  const classes = useStyles();
  const [openDetails, setOpenDetails] = React.useState<boolean>(false);

  const handleClick:
    | (React.MouseEventHandler<HTMLAnchorElement> &
        React.MouseEventHandler<HTMLSpanElement>)
    | undefined = e => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDetails(true);
  };
  const handleClose = () => {
    setOpenDetails(false);
  };

  return (
    <>
      <RequestsDetailsModal
        open={openDetails}
        onClose={handleClose}
        requests={row.requests!}
        displayName={row.displayName}
      />
      <TableRow className={clsx(classes.tableRow, classes.border)}>
        <TableCell size="medium" align="left">
          <Typography>{row.displayName}</Typography>
        </TableCell>
        <TableCell align="left">
          <Typography>{row.kind}</Typography>
        </TableCell>
        <TableCell align="left">
          <Box display="flex" alignItems="center" style={{ gap: 16 }}>
            {(row?.refs?.user || !!row?.refs?.groups?.length) && (
              <Box display="flex">
                {row?.refs?.user && <PersonIcon />}
                {row?.refs?.user && !!row?.refs?.groups?.length && (
                  <Typography>+</Typography>
                )}
                {!!row?.refs?.groups?.length && (
                  <>
                    <Tooltip
                      title={
                        <div style={{ whiteSpace: 'pre-line' }}>
                          {row?.refs?.groups?.join('\n')}
                        </div>
                      }
                    >
                      <StyledBadge
                        badgeContent={row.refs.groups?.length}
                        color="secondary"
                      >
                        <GroupIcon />
                      </StyledBadge>
                    </Tooltip>
                  </>
                )}
              </Box>
            )}
            {row.access === AccessStatusEnum.Disabled && (
              <CancelIcon color="disabled" />
            )}
            <Typography>{row.access}</Typography>
          </Box>
        </TableCell>

        <TableCell>
          {row.status && (
            <RequestStatusButton
              status={row.status}
              handleClick={handleClick}
              label={` ${row.requests?.length} Requests`}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
