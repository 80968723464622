import { makeStyles } from '@material-ui/core';

export const useMarketplaceStyle = makeStyles(theme => ({
  text: {
    overflow: 'hidden',
    fontSize: theme.typography.fontSize,
    wordBreak: 'break-word',
  },
  href: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  label: {
    color: theme.palette.text.secondary,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    lineHeight: '14px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '40vh',
  },
  fullHeightCard: {
    height: '100%',
  },
}));
