import { InputError } from '@backstage/errors';
import { JsonObject } from '@backstage/types';
import {
  AtomicFilter,
  ComplexFilter,
  Filter,
  FilterOperator,
  FilterValue,
  QueryOperator,
} from './SearchFilters';

export function parseFilterFromJsonObject(
  json: JsonObject,
): Filter | undefined {
  if (json.filters && json.operator) {
    return parseComplexFilter(json);
  } else if (json.field && json.operator) {
    return parseAtomicFilter(json);
  }
  return undefined;
}

function parseSearchFilterValue(value: any): FilterValue | FilterValue[] {
  if (Array.isArray(value)) {
    return value.flatMap(v => parseSearchFilterValue(v));
  }
  return value;
}

function parseComplexFilter(json: JsonObject): ComplexFilter {
  const operator = (json.operator as string).toUpperCase() as QueryOperator;

  if (!operator)
    throw new InputError(
      `Invalid operator ${json.operator} for the complex filter`,
    );
  const filters = (json.filters as JsonObject[])
    .map(f => parseFilterFromJsonObject(f))
    // Remove undefined
    .filter((f): f is Filter => f !== undefined);
  return new ComplexFilter(operator, filters);
}

function parseAtomicFilter(json: JsonObject): AtomicFilter | undefined {
  const field = json.field as string;

  const operator = (json.operator as string).toLowerCase() as FilterOperator;
  if (!operator) {
    throw new InputError(
      `Invalid operator ${json.operator} for the atomic filter`,
    );
  }
  // Empty arrays are deleted in the API request. If the operator was "IN" and had an empty array it means that the filter
  // should work as usual, and should match nothing.
  let value: FilterValue | FilterValue[];
  if (json.value) {
    value = parseSearchFilterValue(json.value);
  } else if ([FilterOperator.IN, FilterOperator.NIN].includes(operator)) {
    value = [];
  } else {
    return undefined;
  }

  return new AtomicFilter(field, operator, value);
}
