import {
  ACLRequest,
  AccessStatusEnum,
  AsyncEnumFilter,
  EnumFilter,
  RequestStatusEnum,
  TableCellProps,
  WbCardContent,
  WbPagination,
  WbTable,
  WbTableFilters,
  WbWidget,
  getObjectPropFromString,
  getRequestLabel,
} from '@agilelab/plugin-wb-platform';
import { useLazyQuery, gql } from '@apollo/client';
import React, { useEffect, useMemo, useState } from 'react';
import { ACLByDP } from './DataAccessTab';
import { TableBody } from '@material-ui/core';
import { Row } from './AccessControlRowByDP';
import {
  ACLInfoByOp,
  ACLSystemOwned,
  RequestAndACLsByOp,
} from '@agilelab/plugin-wb-access-control-common';
import {
  GET_OUTPUT_PORTS_BY_ID,
  OutputPortEntity,
} from '@agilelab/plugin-wb-marketplace-common';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

interface AccessControlListProps {
  selectedRow?: ACLSystemOwned;
}
interface ACLFilters {
  kind?: string[];
  access?: string[];
  status?: string[];
}

export const AccessControlList: React.FC<AccessControlListProps> = ({
  selectedRow,
}: AccessControlListProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [ACL, setACL] = useState<ACLByDP[]>([]);
  const [tableRows, setTableRows] = useState<ACLByDP[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filters, setFilters] = React.useState<ACLFilters>({});
  const [getOp] = useLazyQuery(gql(GET_OUTPUT_PORTS_BY_ID));
  const configApi = useApi(configApiRef);
  const consumableInterfaceTypeField = configApi.getString(
    'practiceShaper.migration.consumableInterfaceTypeField',
  );
  useEffect(() => {
    if (!selectedRow) setTableRows([]);
  }, [selectedRow, setTableRows]);

  const columns = useMemo<TableCellProps<ACLByDP>[]>(
    () => [
      {
        field: 'displayName',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'Name',
      },
      {
        field: 'kind',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'kind',
      },
      {
        field: 'access',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: '30%',
        },
        headerName: 'access',
      },
      {
        field: 'status',
        cellProps: {
          size: 'medium',
          align: 'left',
          width: 'auto',
        },
        headerName: 'Request status',
      },
    ],
    [],
  );

  const [getOutputporttypeList] = useLazyQuery(
    gql(`query GetOutputPortType($consumableInterfaceTypeField: String) {
      marketplace_instances {
        outputporttype: descriptor(path: $consumableInterfaceTypeField)
      }
    }`),
    {
      variables: {
        consumableInterfaceTypeField: `$.${consumableInterfaceTypeField}`,
      },
    },
  );

  const getAccessField = (aclInfo: ACLInfoByOp[]) => {
    return aclInfo.reduce((acc, curr) => {
      acc.groups = acc.groups || [];

      if (
        curr.ref.includes('user') &&
        curr.ACL &&
        !!Object.keys(curr.ACL).length
      )
        acc.user = true;
      if (
        curr.ref.includes('group') &&
        curr.ACL &&
        !!Object.keys(curr.ACL).length
      ) {
        acc.groups.push(curr.ref);
      }
      return acc;
    }, {} as { user?: boolean; groups?: string[] });
  };

  useEffect(() => {
    const fetchData = async (op: RequestAndACLsByOp) => {
      let opName = op.outputPortName;
      if (!opName) {
        const res = await getOp({
          variables: {
            id: op.outputPortId,
          },
        });
        const OP: OutputPortEntity = res.data?.outputPorts[0];
        opName = OP.displayName;
      }
      const displayName = opName ?? '';

      return {
        kind: 'Output Port',
        displayName,
        access: op.aclInfo?.every(
          acl => !acl.ACL || Object.keys(acl.ACL)?.length === 0,
        )
          ? AccessStatusEnum.Disabled
          : AccessStatusEnum.Provided,
        refs: getAccessField(op.aclInfo),
        status: op.aclInfo?.some(x => x.ACLRequest?.length > 0)
          ? RequestStatusEnum.OTHER
          : undefined,
        requests: op.aclInfo.reduce((acc, curr) => {
          if (curr.ACLRequest) {
            const requests = curr.ACLRequest?.map(r => ({
              ...r,
              ref: curr.ref,
              createdBy: r.requesterDisplayName ?? '',
              status: getRequestLabel(r),
            }));

            acc.push(...requests);
          }
          return acc;
        }, [] as ACLRequest[]),
      };
    };

    const updateACL = async () => {
      if (selectedRow) {
        const data = await Promise.all(
          selectedRow?.outputPorts?.map(fetchData),
        );
        setACL(data);
      }
    };

    updateACL();
  }, [selectedRow, getOp]);

  useMemo(() => {
    const fields = columns.map(col => col.field || '');
    const filtered = ACL?.filter(row => {
      const rowItems = fields.map(field =>
        getObjectPropFromString(row, field.toString()),
      );
      return rowItems.find(
        item => item && item?.toLowerCase().includes(search.toLowerCase()),
      );
    });
    Object.keys(filters).forEach(key =>
      filters[key as keyof ACLFilters] === undefined
        ? delete filters[key as keyof ACLFilters]
        : {},
    );

    let filteredRows = filtered;
    Object.keys(filters).forEach(key => {
      const filterValue = filters[key as keyof ACLFilters];

      if (Array.isArray(filterValue)) {
        filteredRows = filteredRows?.filter(row =>
          filterValue.includes(
            String(row[key as keyof Pick<ACLByDP, 'kind' | 'access'>]),
          ),
        );
      } else {
        filteredRows = filteredRows?.filter(row =>
          filterValue
            ? new RegExp(filterValue, 'ig').test(
                String(row[key as keyof ACLByDP]),
              )
            : true,
        );
      }
    });
    setTableRows(filteredRows ?? []);
  }, [ACL, filters, search, columns]);

  const body = (rows: ACLByDP[]) =>
    !!rows.length ? (
      <TableBody>
        {rows.map((row, index) => (
          <Row key={`table-row-${index}`} row={row} />
        ))}
      </TableBody>
    ) : undefined;

  return (
    <WbWidget
      cardStyle={{ height: '100%' }}
      headerStyle={{ overflow: 'none' }}
      title={`${selectedRow?.systemDisplayName ?? ''} Consumable Interfaces`}
      footer={
        <WbPagination
          count={tableRows.length ?? 0}
          offset={page * rowsPerPage}
          limit={rowsPerPage}
          onPageChange={newPage => {
            setPage(newPage);
          }}
          onRowsPerPageChange={newRowsPerPage => {
            setRowsPerPage(newRowsPerPage);
          }}
          style={{
            alignSelf: 'flex-end',
            backgroundColor: 'white',
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',
          }}
        />
      }
    >
      <WbCardContent style={{ padding: '0px', height: '100%' }}>
        <WbTableFilters
          style={{ padding: '12px 16px' }}
          onClear={() => {
            setFilters({});
          }}
          searchValue={search}
          onSearch={(value: string) => {
            setPage(0);
            setSearch(value);
          }}
        >
          <AsyncEnumFilter<string>
            field="Kind"
            getOptions={() =>
              getOutputporttypeList().then(
                (result: any) =>
                  (result && result.data
                    ? [
                        ...new Set(
                          result.data.marketplace_instances.filter.map(
                            (x: any) => x.outputporttype,
                          ),
                        ),
                      ]
                    : []) as string[],
              )
            }
            onChange={kind => setFilters({ ...filters, kind })}
            value={filters.kind}
            renderOption={o => o}
            renderValue={o => o}
            onSearch={(v, options) =>
              options.filter(o => new RegExp(v, 'ig').test(o))
            }
          />
          <EnumFilter<string>
            field="Access"
            options={[AccessStatusEnum.Disabled, AccessStatusEnum.Provided]}
            onChange={accessFilter =>
              setFilters({ ...filters, access: accessFilter })
            }
            value={filters.access}
            renderOption={o => o}
            renderValue={o => o}
            onSearch={v =>
              [AccessStatusEnum.Disabled, AccessStatusEnum.Provided].filter(o =>
                new RegExp(v, 'ig').test(o),
              )
            }
          />
        </WbTableFilters>
        <WbTable<ACLByDP>
          styles={{
            container: {
              maxHeight: '500px',
              overflow: 'auto',
            },
            header: { position: 'sticky', top: 0, zIndex: 1 },
          }}
          components={{
            tableContent: {
              body: body(
                tableRows?.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage,
                ) || [],
              ),
              columns,
            },
          }}
        />{' '}
      </WbCardContent>
    </WbWidget>
  );
};
