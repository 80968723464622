import { QuestionEntity } from '@agilelab/plugin-wb-marketplace-common';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import React from 'react';

import { useMarketplaceStyle } from '../../themes';
import { stringAvatar, toDateString } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(3),
      height: theme.spacing(3),
      fontSize: theme.spacing(1),
    },
    questionText: {
      fontWeight: 'bold',
    },
    noAnswer: {
      fontStyle: 'italic',
    },
    accordionSummaryContent: {
      overflow: 'hidden',
    },
  }),
);

export type QuestionDetailsProps = {
  question: QuestionEntity;
};

export const QuestionDetails = (props: QuestionDetailsProps) => {
  const classes = useStyles();
  const commonClasses = useMarketplaceStyle();

  if (!props.question) {
    return <></>;
  }

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        id="panel3a-header"
        expandIcon={<ArrowDropDownIcon />}
        classes={{ content: classes.accordionSummaryContent }}
        style={{ alignItems: 'flex-start' }}
      >
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Avatar
                  className={classes.avatar}
                  {...stringAvatar(props.question.asked_by)}
                />
              </Grid>
              <Grid item>
                <Typography className={commonClasses.text}>
                  {props.question.asked_by_display_name}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={commonClasses.text}>
                  {toDateString(props.question.timestamp)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography
              className={`${commonClasses.text} ${classes.questionText}`}
            >
              {props.question.question}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        {props.question.answer ? (
          <Typography className={commonClasses.text}>
            {props.question.answer.answer}
          </Typography>
        ) : (
          <Typography className={`${commonClasses.text} ${classes.noAnswer}`}>
            No answers yet
          </Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};
