import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';
import { useMemo } from 'react';
import { Task, Test } from '../../types';

const isFailureStatus = (status: DagStatus) =>
  [DagStatus.ERROR, DagStatus.NOT_EXECUTED].includes(status);

const byPoliciesFailed = (task: Task) =>
  !!task.governanceEntityId &&
  isFailureStatus(task.status) &&
  task.governanceEntityType === GovernanceEntityType.Policy;

const byMetricsFailed = (task: Task) =>
  !!task.governanceEntityId &&
  isFailureStatus(task.status) &&
  task.governanceEntityType === GovernanceEntityType.Metric;

const byPoliciesWithWarnings = (task: Task) =>
  (!!task.governanceEntityId && task.status === DagStatus.NOT_BLOCKING_ERROR) ||
  task.status === DagStatus.INFO ||
  task.status === DagStatus.WARNING;

const byComponentsFailed = (task: Task) =>
  !task.governanceEntityId && task.status === DagStatus.FAILED;

export function useTestResults(test: Test) {
  const policiesFailed = useMemo(
    () => test.tasks.filter(byPoliciesFailed).length,
    [test.tasks],
  );

  const metricsFailed = useMemo(
    () => test.tasks.filter(byMetricsFailed).length,
    [test.tasks],
  );

  const policiesWithWarnings = useMemo(
    () => test.tasks.filter(byPoliciesWithWarnings).length,
    [test.tasks],
  );

  const componentsFailed = useMemo(
    () => test.tasks.filter(byComponentsFailed).length,
    [test.tasks],
  );

  const success = Boolean(
    !policiesFailed && !componentsFailed && !policiesWithWarnings,
  );

  return {
    policiesFailed,
    metricsFailed,
    policiesWithWarnings,
    componentsFailed,
    success,
  };
}
