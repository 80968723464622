import {
  getCompoundEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';
import { PropTypes } from '@material-ui/core';
import { ErrorSchema, FormProps } from '@rjsf/core';
import { WmUiSchema } from '../../extensions/types';

export type Step = {
  schema: JsonObject;
  title: string;
  asyncValidate?: (formData: any) => Promise<ErrorSchema>;
} & Partial<Omit<FormProps<any>, 'schema'>>;

export type AdditionalStep = {
  title: string;
  component: JSX.Element;
  additionalActions?: JSX.Element[];
  backButtonText?: string;
  nextButtonText?: string;
  disableStepButtons: boolean;
  customDisableStepButtons?: {
    disableNext?: boolean;
    disableReset?: boolean;
    disableBack?: boolean;
  };
  analyticsEvent?: string;
  backAction?: () => void;
  nextAction?: () => Promise<void>;
  resetButtonText?: string;
  resetAction?: () => void;
  resetButtonVariant?: 'text' | 'outlined' | 'contained';
  resetButtonColor?: PropTypes.Color;
  taskFailed?: boolean;
};

function parseEntityRef(entityLike: any): string {
  try {
    return stringifyEntityRef(getCompoundEntityRef(entityLike));
  } catch (error) {
    return '<no name>';
  }
}

export function getReviewData(formData: Record<string, any>, steps: Step[]) {
  const uiSchemas = getUiSchemasFromSteps(steps);
  const reviewData: Record<string, any> = {};
  for (const key in formData) {
    if (formData.hasOwnProperty(key)) {
      const uiSchema = uiSchemas.find(us => us.name === key);

      if (!uiSchema) {
        reviewData[key] = formData[key];
        continue;
      }

      if (uiSchema['ui:widget'] === 'password') {
        reviewData[key] = '******';
        continue;
      }

      if (uiSchema['ui:field']?.toString().toLowerCase() === 'entitypicker') {
        reviewData[key] =
          formData[key] && typeof formData[key] === 'string'
            ? formData[key]
            : `<descriptor> ${parseEntityRef(formData[key])}`;
        continue;
      }

      if (uiSchema['ui:field']?.toString().toLowerCase() === 'filepicker') {
        reviewData[key] = `File: ${formData[key]?.fileName}` ?? 'File';
        continue;
      }

      if (!uiSchema['ui:backstage'] || !uiSchema['ui:backstage'].review) {
        reviewData[key] = formData[key];
        continue;
      }

      const review = uiSchema['ui:backstage'].review as JsonObject;
      if (review.mask) {
        reviewData[key] = review.mask;
        continue;
      }

      if (!review.show) {
        continue;
      }
      reviewData[key] = formData[key];
    }
  }
  return reviewData;
}

export function getUiSchemasFromSteps(steps: Step[]): WmUiSchema[] {
  const uiSchemas: Array<WmUiSchema> = [];
  steps.forEach(step => {
    const schemaProps = step.schema.properties as JsonObject;
    for (const key in schemaProps) {
      if (schemaProps.hasOwnProperty(key)) {
        const uiSchema =
          schemaProps[key] && typeof schemaProps[key] === 'object'
            ? (schemaProps[key] as WmUiSchema)
            : {};
        uiSchema.name = key;
        uiSchemas.push(uiSchema);
      }
    }
  });
  return uiSchemas;
}
