import { WittyMessage } from './Messages';

export const NOT_SUPPORTED_ANSWER: WittyMessage = {
  contentMessage: `I don't understand, please choose one of the above options`,
  sender: 'witty',
};

export const ERROR_FALLBACK: WittyMessage = {
  contentMessage: `Apologies, I’m not quite sure I understood that. Could you clarify or rephrase it for me?`,
  sender: 'witty',
};
