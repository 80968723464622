import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import clsx from 'clsx';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { WbCardActionButton } from '../WbCardActionButton';

import {
  makeSidebarConfig,
  makeSidebarSubmenuConfig,
  SidebarConfig,
  SidebarOptions,
  SubmenuConfig,
  SubmenuOptions,
  WbSidebarConfigContext,
} from './config';
import { useContent } from './layout/WbSidebarPage';
import { WbSidebarOpenStateProvider } from './WbSidebarOpenStateContext';
import { useWbSidebarPinState } from './WbSidebarPinStateContext';

/** @public */
export type SidebarClassKey = 'drawer' | 'drawerOpen';
const useStyles = makeStyles<Theme, { sidebarConfig: SidebarConfig }>(
  theme => ({
    drawer: {
      position: 'fixed',
      left: 0,
      top: 0,
      bottom: 0,
      zIndex: theme.zIndex.appBar,
      background: theme.palette.white,
      boxShadow: theme.shadows[23],
    },
    drawerContent: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'flex-start',
      overflowX: 'hidden',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      '& > *': {
        flexShrink: 0,
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      '@media print': {
        display: 'none',
      },
      height: '100%',
      borderRight: `1px solid ${theme.palette.bkg.secondary}`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    drawerWidth: props => ({
      width: props.sidebarConfig.drawerWidthClosed,
    }),
    drawerOpen: props => ({
      width: props.sidebarConfig.drawerWidthOpen,
    }),
    visuallyHidden: {
      top: 0,
      position: 'absolute',
      zIndex: 1000,
      transform: 'translateY(-200%)',
      '&:focus': {
        transform: 'translateY(5px)',
      },
    },
    pinIconButton: {
      position: 'absolute',
      top: '80px',
      transform: `translate(20%,-50%)`,
      right: 0,
      padding: 0,
      border: '1px solid white',
      zIndex: 1200,
    },
    pinIcon: {
      borderRadius: '100%',
      fontSize: '16px',
    },
  }),
  { name: 'WitboostSidebar' },
);

enum State {
  Closed,
  Idle,
  Open,
}

/** @public */
export type SidebarProps = {
  openDelayMs?: number;
  closeDelayMs?: number;
  sidebarOptions?: SidebarOptions;
  submenuOptions?: SubmenuOptions;
  disableExpandOnHover?: boolean;
  children?: React.ReactNode;
};

export type DesktopSidebarProps = {
  openDelayMs?: number;
  closeDelayMs?: number;
  disableExpandOnHover?: boolean;
  children?: React.ReactNode;
};

/**
 * Places the Sidebar & wraps the children providing context weather the `Sidebar` is open or not.
 *
 * Handles & delays hover events for expanding the `Sidebar`
 *
 * @param props `disableExpandOnHover` disables the default hover behaviour;
 * `openDelayMs` & `closeDelayMs` set delay until sidebar will open/close on hover
 * @returns
 * @internal
 */
const DesktopSidebar = (props: DesktopSidebarProps) => {
  const { sidebarConfig } = useContext(WbSidebarConfigContext);
  const {
    openDelayMs = sidebarConfig.defaultOpenDelayMs,
    closeDelayMs = sidebarConfig.defaultCloseDelayMs,
    disableExpandOnHover,
    children,
  } = props;

  const classes = useStyles({ sidebarConfig });
  const { isPinned, toggleSidebarPinState } = useWbSidebarPinState();
  const { isTemporarilyPinned } = useWbSidebarPinState();
  const [state, setState] = useState(isPinned ? State.Open : State.Closed);
  const hoverTimerRef = useRef<number>();

  // if a state change is already requested to happen, cancel it
  const cancelPendingStateChange = useCallback(() => {
    if (hoverTimerRef.current) {
      clearTimeout(hoverTimerRef.current);
      hoverTimerRef.current = undefined;
    }
  }, []);

  const handleOpen = () => {
    if (disableExpandOnHover) {
      return;
    }
    cancelPendingStateChange();
    if (state !== State.Open) {
      hoverTimerRef.current = window.setTimeout(() => {
        hoverTimerRef.current = undefined;
        setState(State.Open);
      }, openDelayMs);

      setState(State.Idle);
    }
  };

  const handleClose = () => {
    if (disableExpandOnHover) {
      return;
    }
    cancelPendingStateChange();
    if (state === State.Idle) {
      setState(State.Closed);
    } else if (state === State.Open) {
      hoverTimerRef.current = window.setTimeout(() => {
        hoverTimerRef.current = undefined;
        setState(State.Closed);
      }, closeDelayMs);
    }
  };

  const isOpen = state === State.Open || isPinned;

  /**
   * Close/Open Sidebar directly without delays. Also toggles `SidebarPinState` to avoid hidden content behind Sidebar.
   */
  const setPinned = (open: boolean) => {
    if (open) {
      setState(State.Open);
      toggleSidebarPinState();
    } else {
      setState(State.Closed);
      toggleSidebarPinState();
    }
  };

  const onPinButtonClick = () => {
    setPinned(!isPinned);
  };

  // prevent the drawer from accidentally closing when the user hovers on the button
  const handlePinButtonEnter = () => {
    if (disableExpandOnHover) {
      return;
    }
    cancelPendingStateChange();
  };

  const handlePinButtonLeave = () => {
    if (disableExpandOnHover) {
      return;
    }
    handleClose();
  };

  return (
    <nav style={{}} aria-label="sidebar nav">
      <A11ySkipSidebar />
      <WbSidebarOpenStateProvider
        value={{
          isOpen,
          setPinned,
          cancelPendingStateChange,
        }}
      >
        <Box className={classes.root} data-testid="sidebar-root">
          <Box className={clsx(classes.drawer)}>
            {!isTemporarilyPinned && (
              <WbCardActionButton
                data-testid="sidebar-expand-button"
                role="button"
                title={isPinned ? 'Collapse' : 'Expand'}
                aria-label={isPinned ? 'Collapse' : 'Expand'}
                className={classes.pinIconButton}
                style={{ position: 'absolute' }}
                onClick={onPinButtonClick}
                onMouseEnter={handlePinButtonEnter}
                onMouseLeave={handlePinButtonLeave}
                icon={
                  isPinned ? (
                    <ChevronLeft className={classes.pinIcon} />
                  ) : (
                    <ChevronRight className={classes.pinIcon} />
                  )
                }
              />
            )}
            <Box
              className={clsx(classes.drawerWidth, classes.drawerContent, {
                [classes.drawerOpen]: isOpen,
              })}
              onMouseEnter={disableExpandOnHover ? () => {} : handleOpen}
              onFocus={disableExpandOnHover ? () => {} : handleOpen}
              onMouseLeave={disableExpandOnHover ? () => {} : handleClose}
              onBlur={disableExpandOnHover ? () => {} : handleClose}
            >
              {children}
            </Box>
          </Box>
        </Box>
      </WbSidebarOpenStateProvider>
    </nav>
  );
};

/**
 * Passing children into the desktop or mobile sidebar depending on the context
 *
 * @public
 */
export const WbSidebar = (props: SidebarProps) => {
  const sidebarConfig: SidebarConfig = makeSidebarConfig(
    props.sidebarOptions ?? {},
  );
  const submenuConfig: SubmenuConfig = makeSidebarSubmenuConfig(
    props.submenuOptions ?? {},
  );
  const { children, disableExpandOnHover, openDelayMs, closeDelayMs } = props;
  return (
    <WbSidebarConfigContext.Provider value={{ sidebarConfig, submenuConfig }}>
      <DesktopSidebar
        openDelayMs={openDelayMs}
        closeDelayMs={closeDelayMs}
        disableExpandOnHover={disableExpandOnHover}
      >
        {children}
      </DesktopSidebar>
    </WbSidebarConfigContext.Provider>
  );
};

function A11ySkipSidebar() {
  const { sidebarConfig } = useContext(WbSidebarConfigContext);
  const { focusContent, contentRef } = useContent();
  const classes = useStyles({ sidebarConfig });

  if (!contentRef?.current) {
    return null;
  }
  return (
    <Button
      onClick={focusContent}
      variant="contained"
      className={clsx(classes.visuallyHidden)}
    >
      skipToContent
    </Button>
  );
}
