import { EntityProcessingErrorsPanel } from '@agilelab/plugin-wb-builder-catalog';
import { WitboostSystem } from '@agilelab/plugin-wb-builder-common';
import {
  CollapseButton,
  createRefreshEntityFunction,
  customAlertApiRef,
  CustomView,
  useRefreshPermission,
  WbCard,
  WbCardActionButton,
  WbCardContent,
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
  WbDrawer,
} from '@agilelab/plugin-wb-platform';
import { practiceShaperImportPermission } from '@agilelab/plugin-wb-rbac-common';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef, EntityProvider } from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import { Box, makeStyles, Tooltip } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'reactflow/dist/style.css';
import { usePracticeShaperGraph } from '../../context/PracticeShaperGraphContext';
import { Legend } from './Legend';
import { PracticesShaperGraph } from './PracticeShaperGraph';

const PAGE_HEIGHT_MINUS_CONTENT = 96 + 40 + 24;

const useStyles = makeStyles(
  theme => ({
    actions: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'PracticeShaperOverview' },
);

export function PracticeShaperOverview({
  importPageRef,
}: {
  importPageRef: string;
}) {
  const {
    selectedEntity,
    setSelectedEntity,
    data: { refetch, isFetching },
  } = usePracticeShaperGraph();
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const { allowed } = usePermission({
    permission: practiceShaperImportPermission,
  });

  const { allowRefresh } = useRefreshPermission(selectedEntity);

  const refreshEntity = createRefreshEntityFunction({
    entity: selectedEntity,
    catalogApi,
    alertApi,
  });

  const [legendCollapse, setLegendCollapse] = useState(true);

  const entityName = selectedEntity
    ? (selectedEntity.metadata as any).displayName ||
      selectedEntity.metadata.name
    : '';

  const navigate = useNavigate();

  const classes = useStyles();

  return (
    <>
      <WbDockableGroup
        className={classes.root}
        direction="horizontal"
        style={{ height: `calc(100vh - ${PAGE_HEIGHT_MINUS_CONTENT}px)` }}
      >
        <WbDockablePanel
          collapsible
          defaultSize={30}
          tab={{
            isCollapsed: legendCollapse,
            setCollapsed: isCollapsed => setLegendCollapse(isCollapsed),
            title: 'Legend',
            direction: 'horizontal',
          }}
        >
          <WbCard
            title="Legend"
            leading={<CollapseButton onClick={() => setLegendCollapse(true)} />}
            cardStyle={{ height: '100%' }}
          >
            <WbCardContent style={{ height: '100%', overflowY: 'auto' }}>
              <Legend />
            </WbCardContent>
          </WbCard>
        </WbDockablePanel>
        <WbDockableResizeHandle direction="horizontal" />
        <WbDockablePanel defaultSize={70} collapsible>
          <WbCard
            title="Design"
            cardStyle={{ height: '100%' }}
            actions={
              <Box className={classes.actions}>
                <Tooltip title="Refresh the Practice Shaper Entities">
                  <WbCardActionButton
                    variant="outlined"
                    onClick={() => refetch()}
                    label="Refresh"
                    icon={<CachedIcon />}
                    disabled={isFetching}
                  />
                </Tooltip>
                {allowed ? (
                  <Tooltip title="Register a new Practice Shaper entity">
                    <WbCardActionButton
                      label="Register new entity"
                      onClick={() => {
                        navigate(importPageRef);
                      }}
                    />
                  </Tooltip>
                ) : undefined}
              </Box>
            }
          >
            <WbCardContent
              style={{
                height: '100%',
              }}
            >
              <PracticesShaperGraph />
            </WbCardContent>
          </WbCard>
        </WbDockablePanel>
      </WbDockableGroup>
      <EntityProvider entity={selectedEntity ? selectedEntity : undefined}>
        <WbDrawer
          open={!!selectedEntity}
          setOpen={() => setSelectedEntity(null)}
          contentContainerStyle={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden',
            gap: '15px',
          }}
          title={entityName}
        >
          {selectedEntity && (
            <>
              <EntityProcessingErrorsPanel />
              <CustomView
                id="practice_shaper_content"
                transform="builder_system"
                typeId={selectedEntity.kind.toLocaleLowerCase()}
                data={{
                  entity: selectedEntity as WitboostSystem,
                  allowRefresh,
                }}
                actions={{ refreshEntity: refreshEntity }}
              />
            </>
          )}
        </WbDrawer>
      </EntityProvider>
    </>
  );
}
