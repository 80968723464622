import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  Acceptance_type,
  WitboostDocument,
  WitboostDocumentWithAcceptanceDate,
} from '../types';
import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';

export interface DocumentsApi {
  retrieveSingleDocumentBlob(document_id: number): Promise<Blob>;

  retrieveDocuments(queryParams: {
    not_accepted_only?: boolean;
    include_acceptance_date?: boolean;
    acceptance_types?: Acceptance_type[];
    enabled_only?: boolean;
    metadata_only?: boolean;
  }): Promise<WitboostDocumentWithAcceptanceDate[]>;

  acceptDocumentFromUser(document_id: number): Promise<{ id: number }>;
}

export class DocumentsClient implements DocumentsApi {
  private readonly baseUrlPromise: Promise<string>;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi }) {
    this.baseUrlPromise = options.discoveryApi.getBaseUrl('documents');
    this.fetchApi = options.fetchApi;
  }

  async retrieveSingleDocumentBlob(document_id: number): Promise<Blob> {
    const baseUrl = await this.baseUrlPromise;

    const response = await this.fetchApi.fetch(`${baseUrl}/${document_id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
      },
    });
    await handleFailedResponse(response);

    return response.blob();
  }

  async retrieveDocuments(queryParams: {
    not_accepted_only?: boolean;
    include_acceptance_date?: boolean;
    acceptance_types?: Acceptance_type[];
    enabled_only?: boolean;
    metadata_only?: boolean;
  }): Promise<WitboostDocumentWithAcceptanceDate[]> {
    const baseUrl = await this.baseUrlPromise;

    const urlParams = new URLSearchParams();
    Object.entries(queryParams).forEach(([key, value]) => {
      if (value) {
        const paramValue = Array.isArray(value)
          ? value.join(',')
          : value.toString();
        urlParams.append(key, paramValue);
      }
    });

    const response = await this.fetchApi.fetch(
      `${baseUrl}?${new URLSearchParams(urlParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      },
    );
    await handleFailedResponse(response);

    const documents: WitboostDocument[] = await response.json();
    const documentsUint8 = documents.map(doc => {
      return {
        ...doc,
        content: doc.content ? Buffer.from(doc.content) : {},
      };
    });

    return documentsUint8;
  }

  async acceptDocumentFromUser(document_id: number): Promise<{ id: number }> {
    const baseUrl = await this.baseUrlPromise;

    const body = {
      document_id: document_id,
    };

    const response = await this.fetchApi.fetch(`${baseUrl}/user-acceptance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    await handleFailedResponse(response);

    return response.json();
  }
}
