import {
  Pagination,
  WbTable,
  TableCellProps,
} from '@agilelab/plugin-wb-platform';
import {
  Box,
  Checkbox,
  createStyles,
  FormControl,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { isEqual } from 'lodash';
import { useMultiSelectionDialogPickerContext } from './MultiSelectionPickerDialogContext';

type GenericElement = {
  name: string;
  domain?: string;
  description?: string;
};

interface SelectableTableProps<T extends GenericElement> {
  loading: boolean;
  data: T[];
  columns?: TableCellProps<T>[];
  pagination?: Pagination;
  setPagination?: React.Dispatch<React.SetStateAction<Pagination>>;
}

const useStyles = makeStyles(
  theme =>
    createStyles({
      formControl: {
        width: '100%',
        overflow: 'auto',
        height: '100%',
        justifyContent: 'space-between',
      },
      boxBest: {
        padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
        backgroundColor: theme.palette.white,
        border: 'solid 1px transparent',
        borderRadius: '4px',
        background:
          'linear-gradient( #ffffff, #ffffff) padding-box,linear-gradient(90deg, #EE72D3, #7C4DFF) border-box',
        whiteSpace: 'nowrap',
      },
      aiSuggestedRow: {
        backgroundColor: `${theme.palette.bkg.accent} !important`,
        '& td': {
          borderBottom: `1px solid ${theme.palette.accent.light}`,
        },
      },
    }),
  { name: 'selectionTable' },
);

export function SelectableTable<T extends GenericElement>({
  loading,
  data,
  columns,
}: SelectableTableProps<T>) {
  const classes = useStyles();
  const { contextSelected, setContextSelected, isAiGenerated } =
    useMultiSelectionDialogPickerContext();

  const onChangeHandler = useCallback(
    (values: T[]) => {
      setContextSelected(values);
    },
    [setContextSelected],
  );

  const Selector = ({ row }: { row: T }) => {
    return (
      <>
        <Checkbox
          onChange={(event, checked) => {
            event.stopPropagation();

            if (checked) {
              onChangeHandler!([...contextSelected!, row]);
            } else {
              onChangeHandler!([
                ...(contextSelected?.filter(e => !isEqual(e, row)) ?? []),
              ]);
            }
          }}
          checked={
            !!contextSelected?.find((e: T) => {
              return isEqual(e, row);
            })
          }
          color="primary"
        />
      </>
    );
  };

  const defaultColumns = useMemo(() => {
    const cols = [
      {
        headerName: 'Name',
        field: 'name',
        fieldRender: (field: T) => {
          return (
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>{field.name}</Typography>
              {isAiGenerated(field) && (
                <Typography className={classes.boxBest} variant="caption">
                  Best match
                </Typography>
              )}
            </Box>
          );
        },
      },
    ] as TableCellProps<T>[];

    if (data.some(d => d.domain)) {
      cols.push({
        headerName: 'Domain',
        field: 'domain',
      });
    }
    if (data.some(d => d.description)) {
      cols.push({
        headerName: 'Description',
        field: 'description',
      });
    }

    return cols;
  }, [classes.boxBest, data, isAiGenerated]);

  const handleColumns = (): TableCellProps<any>[] => {
    return [
      {
        field: 'selector',
        fieldRender: (row: T) => <Selector row={row} />,
        headerName: '',
        cellProps: {
          width: '5%',
        },
      },
      ...(columns ?? defaultColumns),
    ];
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <WbTable
          stickyHeader
          styles={{
            container: {
              overflow: 'auto',
            },
          }}
          components={{
            tableLoader: { loading },
            tableContent: {
              columns: handleColumns(),
              rows: data,
              rowDynamicClassName: row => {
                return isAiGenerated(row) ? classes.aiSuggestedRow : undefined;
              },
            },
          }}
        />
      </FormControl>
    </>
  );
}
