import { z } from 'zod';

export const EntitySelectionPickerSchemaZod = z
  .object({
    'ui:fieldName': z.string(),
    'ui:property': z.string().optional(),
    mapTo: z.record(z.any()).or(z.string()).optional(),
    'ui:displayLabel': z.string().optional(),
  })
  .refine(
    schema =>
      (schema['ui:property'] !== undefined && schema.mapTo === undefined) ||
      (schema['ui:property'] === undefined && schema.mapTo !== undefined),
    {
      message:
        "You must define at maximum one between 'ui:property' and 'mapTo' configurations",
    },
  );

export type EntitySelectionPickerSchema = z.infer<
  typeof EntitySelectionPickerSchemaZod
>;
