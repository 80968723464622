import {
  GenericField,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Box, makeStyles, Theme } from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles((_theme: Theme) => ({
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
}));

export const RolesDetailDrawer = (props: {
  role: string;
  subject: string;
  roleDescription: string;
  target: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.drawerContent}>
      <WbCard title={props.role}>
        <WbCardContent>
          <Box className={classes.row}>
            <GenericField label="Subject" value={props.subject} type="text" />
            <GenericField
              label="Description"
              value={props.roleDescription}
              type="text"
            />
            <GenericField label="Target" value={props.target} type="text" />
          </Box>
        </WbCardContent>
      </WbCard>
    </Box>
  );
};
