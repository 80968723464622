import {
  createStyles,
  createTheme,
  Grid,
  LinearProgress,
  makeStyles,
  MuiThemeProvider,
  Theme,
  Tooltip,
  useTheme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    number: {
      fontWeight: 'bold',
      fontSize: theme.typography.fontSize,
      opacity: '.36',
    },
  }),
);

export type RatingHistogramProps = {
  reviews: Array<{ score: number }>;
};

export const RatingHistogram = (props: RatingHistogramProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const ratingTheme = createTheme({
    ...theme,
    palette: {
      ...theme.palette,
      primary: {
        ...theme.palette.primary,
        main: theme.palette.favs,
      },
    },
  });
  const reviewsByValue = props.reviews.reduce((prev, next) => {
    const map = { ...prev };

    if (map[next.score]) {
      map[next.score]++;
    } else {
      map[next.score] = 1;
    }

    return map;
  }, {} as Record<number, number>);

  const bars = [5, 4, 3, 2, 1].map(value => {
    return (
      <Grid key={value} item>
        <Grid container spacing={0} direction="row">
          <Grid item xs={1}>
            <span className={classes.number}>{value}</span>
          </Grid>
          <Grid item xs={11}>
            <MuiThemeProvider theme={ratingTheme}>
              <Tooltip
                title={`${
                  reviewsByValue[value]
                    ? (reviewsByValue[value] / props.reviews.length) * 100
                    : 0
                }%`}
              >
                <LinearProgress
                  style={{
                    height: '24px',
                    backgroundColor: theme.palette.grey[100],
                    borderRadius: 20,
                  }}
                  variant="determinate"
                  value={
                    reviewsByValue[value]
                      ? (reviewsByValue[value] / props.reviews.length) * 100
                      : 0
                  }
                />
              </Tooltip>
            </MuiThemeProvider>
          </Grid>
        </Grid>
      </Grid>
    );
  });

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {bars}
    </Grid>
  );
};
