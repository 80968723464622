import React from 'react';
import { CardContent, Paper, Typography, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

interface CustomMessageCardProps {
  keyProp: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: '100%',
    width: '100%',
    minHeight: '100px',
    backgroundColor: theme.palette.grey[100],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typography: {
    color: theme.palette.grey[600],
  },
}));

const MissingKeyCardContent: React.FC<CustomMessageCardProps> = ({
  keyProp,
}) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Paper className={classes.paper}>
        <Typography component="p" className={classes.typography}>
          {`No ${keyProp} in the output port`}
        </Typography>
      </Paper>
    </CardContent>
  );
};

export default MissingKeyCardContent;
