import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const EditOffIcon = () => (
  <SvgIcon>
    <svg
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      data-testid="EditOffIcon"
    >
      <path d="m12.126 8.125 1.937-1.937 3.747 3.747-1.937 1.938zM20.71 5.63l-2.34-2.34a1 1 0 0 0-1.41 0l-1.83 1.83 3.75 3.75L20.71 7a1 1 0 0 0 0-1.37zM2 5l6.63 6.63L3 17.25V21h3.75l5.63-5.62L18 21l2-2L4 3 2 5z" />
    </svg>
  </SvgIcon>
);
