import { UserHeadersApi } from '@agilelab/plugin-wb-user-headers-common';
import { createApiRef } from '@backstage/core-plugin-api';

/**
 * The API reference for the UserHeadersApi.
 * @public
 */
export const userHeadersApiRef = createApiRef<UserHeadersApi>({
  id: 'plugin.user-headers',
});
