import { Checkbox, MenuItem, MenuItemProps } from '@material-ui/core';
import React from 'react';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = MenuItemProps & { label: string; checked?: boolean };

export const WbCheckboxMenuItem = React.forwardRef(
  (
    { label, checked, button, ...props }: Props,
    ref?: React.Ref<HTMLInputElement>,
  ) => {
    return (
      <MenuItem {...props} innerRef={ref}>
        <Checkbox
          icon={icon}
          checkedIcon={checkedIcon}
          style={{ marginRight: 8 }}
          checked={checked}
        />

        {label}
      </MenuItem>
    );
  },
);
