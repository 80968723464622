import React from 'react';
import { GovernanceTestOverviewMetricInfo } from './GovernanceTestOverviewMetricInfo';
import { Metric } from '@agilelab/plugin-wb-governance-common';

interface Props {
  metric: Metric;
}

export const GovernanceTestOverviewMetricSimple: React.FC<Props> = props => {
  return <GovernanceTestOverviewMetricInfo {...props} />;
};
