/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { IconButton, makeStyles, Paper, Tooltip } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import { useKeyboardEvent } from '@react-hookz/web';
import React, { useEffect, useMemo, useState } from 'react';
import { useDirectoryEditor } from './DirectoryEditorContext';
import './styling/Editor.css';
import {
  MonacoEditor,
  WbEditor,
  IOverlayWidget,
  WbMonacoEditor,
} from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 58px)',
  },
  editor: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  errorPanel: {
    color: theme.palette.error.main,
    lineHeight: 2,
    margin: theme.spacing(0, 1),
  },
  floatingButtons: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(3),
  },
  floatingButton: {
    padding: theme.spacing(1),
  },
}));

/** A wrapper around moanco editor with an error panel and extra actions available */
export function TemplateEditorTextArea(props: {
  content?: string;
  onUpdate?: (content?: string) => void;
  errorText?: string;
  onSave?: () => void;
  onReload?: () => void;
  theme?: string;
  editable: boolean;
  highlightLines?: Array<number>;
  id?: string;
  containerStyle?: React.CSSProperties;
}) {
  const { errorText } = props;
  const classes = useStyles();
  const [monacoEditor, setMonacoEditor] = useState<MonacoEditor>();

  const errorPanel = useMemo(() => {
    if (!errorText || !monacoEditor) {
      return null;
    }

    const dom = document.createElement('div');
    dom.classList.add(classes.errorPanel);
    dom.textContent = errorText;

    return {
      dispose: () => {
        dom.remove();
      },
      getId: () => props.id ?? 'errorPanel',
      getDomNode: () => dom,
      getPosition: () => ({
        position: { above: monacoEditor.getPosition() },
      }),
    } as unknown as IOverlayWidget;
  }, [classes.errorPanel, errorText, monacoEditor, props.id]);

  useKeyboardEvent(
    e => e.key === 's' && (e.ctrlKey || e.metaKey),
    e => {
      e.preventDefault();
      if (props.onSave) {
        props.onSave();
      }
    },
  );

  useEffect(() => {
    if (monacoEditor && props.highlightLines) {
      const editor = new WbMonacoEditor(monacoEditor);
      editor.highlightLines(props.highlightLines);
      editor.goToLine(props.highlightLines.at(0));
    }
  }, [props.highlightLines, monacoEditor]);

  return (
    <div style={props.containerStyle} className={classes.container}>
      <WbEditor
        className={classes.editor}
        height="100%"
        language="yaml"
        options={{ readOnly: !props.editable, minimap: { enabled: false } }}
        value={props.content}
        onChange={props.onUpdate}
        onMount={editor => {
          setMonacoEditor(editor);
          if (errorPanel) editor.addOverlayWidget(errorPanel);
        }}
      />
      {(props.onSave || props.onReload) && (
        <div className={classes.floatingButtons}>
          <Paper>
            {props.onSave && (
              <Tooltip title="Save file">
                <IconButton
                  className={classes.floatingButton}
                  onClick={() => props.onSave?.()}
                >
                  <SaveIcon />
                </IconButton>
              </Tooltip>
            )}
            {props.onReload && (
              <Tooltip title="Reload file">
                <IconButton
                  className={classes.floatingButton}
                  onClick={() => props.onReload?.()}
                >
                  <RefreshIcon />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        </div>
      )}
    </div>
  );
}

/** A version of the TemplateEditorTextArea that is connected to the DirectoryEditor context */
export function TemplateEditorDirectoryEditorTextArea(props: {
  errorText?: string;
}) {
  const directoryEditor = useDirectoryEditor();

  const actions = directoryEditor.selectedFile?.dirty
    ? {
        onSave: () => directoryEditor.save(),
        onReload: () => directoryEditor.reload(),
      }
    : {
        onReload: () => directoryEditor.reload(),
      };

  return (
    <TemplateEditorTextArea
      errorText={props.errorText}
      content={directoryEditor.selectedFile?.content}
      onUpdate={content =>
        directoryEditor.selectedFile?.updateContent(content!)
      }
      {...actions}
    />
  );
}

TemplateEditorTextArea.DirectoryEditor = TemplateEditorDirectoryEditorTextArea;
TemplateEditorTextArea.defaultProps = {
  theme: 'dark',
  editable: true,
};
