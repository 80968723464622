import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React from 'react';
import { GenericField } from './GenericField';
import { Skeleton } from '@material-ui/lab';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles(() => ({
  listItem: {
    display: 'flex',
    alignItems: 'center',
  },
  listItemText: {
    margin: 0,
    alignItems: 'center',
    display: 'flex',
  },
  listItemIcon: {
    minWidth: '30px',
  },
  listSkeleton: {
    maxWidth: '300px',
  },
}));

export type GenericListItem = {
  icon: React.ReactNode;
  text: React.ReactNode;
  additionalInfo?: string;
};

export type GenericListProps = {
  label?: string;
  isLoading?: boolean;
  isError?: boolean;
  items?: GenericListItem[];
  error?: React.ReactNode;
  noData?: React.ReactNode;
};

const ListSkeleton = () => {
  const classes = useStyles();
  return (
    <Box className={classes.listSkeleton}>
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </Box>
  );
};

export const GenericList = ({
  label,
  items = [],
  error,
  isLoading = false,
  isError = false,
  noData,
}: GenericListProps): JSX.Element => {
  const classes = useStyles();

  const renderList = () => (
    <List>
      {items.map((item, idx) => (
        <ListItem key={idx} className={classes.listItem}>
          <ListItemIcon className={classes.listItemIcon}>
            {item.icon}
          </ListItemIcon>
          <ListItemText
            primary={
              <Box display="flex" alignItems="center">
                {item.text}
                {item.additionalInfo && (
                  <Tooltip title={item.additionalInfo}>
                    <Box display="flex" alignItems="center" ml={1}>
                      <InfoIcon fontSize="small" />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            }
            className={classes.listItemText}
          />
        </ListItem>
      ))}
    </List>
  );

  return (
    <GenericField
      label={label}
      value={
        <Box>
          {isLoading && <ListSkeleton />}
          {!isLoading && !isError && (items.length ? renderList() : noData)}
          {!isLoading && isError && error}
        </Box>
      }
    />
  );
};
