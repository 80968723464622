/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import {
  ImperativePanelHandle,
  Panel,
  PanelProps,
} from 'react-resizable-panels';
import { ExpandButton } from './WbDockableButtons';

export type WbDockablePanelHandle = ImperativePanelHandle;
export type DockDirection = 'vertical' | 'horizontal';
export const WbDockablePanel: React.FC<
  PanelProps & {
    isCardChildren?: boolean;
    expandOnClick?: boolean;
    showCollapsedContent?: boolean;
    expandOnResize?: boolean;
    tab?: {
      isCollapsed: boolean;
      setCollapsed: (isCollapsed: boolean) => void;
      title: string;
      direction: DockDirection;
    };
  }
> = ({
  tab,
  style,
  isCardChildren = false,
  expandOnClick = true,
  showCollapsedContent = false,
  expandOnResize = true,
  ...panelProps
}) => {
  const theme = useTheme();
  const innerRef = React.useRef<WbDockablePanelHandle>(null);
  const defaultStyle = {
    vertical: {
      width: '100%',
      height: '32px',
    },
    horizontal: {
      width: isCardChildren ? '72px' : '32px',
    },
  };

  React.useEffect(() => {
    if (tab?.isCollapsed) {
      innerRef.current?.collapse();
    }
    if (!tab?.isCollapsed) {
      innerRef.current?.expand();
    }
  }, [tab]);

  return (
    <>
      {tab?.isCollapsed && (
        <Box
          onClick={() => {
            if (expandOnClick) tab?.setCollapsed(false);
          }}
          style={{
            display: 'flex',
            justifyContent:
              tab?.direction === 'horizontal' ? 'center' : 'flex-start',
            alignItems: tab?.direction === 'vertical' ? 'center' : 'flex-start',
            background: isCardChildren ? 'none' : theme.palette.bkg.primary,
            cursor: 'auto',
            ...(tab?.direction ? { ...defaultStyle[tab?.direction] } : {}),
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexDirection: tab.direction === 'vertical' ? 'row' : 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: theme.spacing(2),
              ...(tab?.direction === 'horizontal'
                ? { paddingTop: 0 }
                : { paddingLeft: 0 }),
              ...(tab.direction === 'horizontal'
                ? { width: '100%' }
                : { height: '100%' }),
            }}
          >
            <ExpandButton
              background={false}
              onClick={() => tab?.setCollapsed(false)}
            />

            <Typography
              color="primary"
              component="p"
              variant="h6"
              style={{
                fontWeight: 500,
                ...(tab?.direction === 'horizontal'
                  ? {
                      whiteSpace: 'nowrap',
                      writingMode: 'vertical-rl',
                      textOrientation: 'mixed',
                    }
                  : {}),
              }}
            >
              {tab?.title}
            </Typography>
            {showCollapsedContent && panelProps.children}
          </Box>
        </Box>
      )}
      <Panel
        ref={innerRef}
        onCollapse={collapsed => {
          if (expandOnResize) {
            tab?.setCollapsed(collapsed);
          } else if (collapsed !== tab?.isCollapsed)
            tab?.setCollapsed(collapsed);
        }}
        onResize={() => {
          if (tab?.isCollapsed && expandOnResize) tab?.setCollapsed(false);
        }}
        style={{
          padding: tab?.isCollapsed || isCardChildren ? 0 : 3,
          display: tab?.isCollapsed ? 'none' : undefined,
          ...style,
        }}
        {...panelProps}
      >
        {panelProps.children}
      </Panel>
    </>
  );
};
