import React from 'react';
import { Button, useTheme } from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { RequestStatusEnum } from './types';

export interface RequestStatusButtonProps {
  status?: RequestStatusEnum;
  handleClick?: React.MouseEventHandler<HTMLAnchorElement> &
    React.MouseEventHandler<HTMLSpanElement>;
  label?: string;
}

export const RequestStatusButton: React.FC<RequestStatusButtonProps> = ({
  status,
  handleClick,
  label,
}) => {
  const theme = useTheme();

  switch (status) {
    case RequestStatusEnum.PENDING:
    case RequestStatusEnum.WAITING:
      return (
        <Button
          size="small"
          startIcon={<ErrorOutlineIcon />}
          variant="contained"
          color="primary"
          style={{
            cursor: handleClick ? 'pointer' : 'auto',
            backgroundColor: theme.palette.warning.main,
            color: 'black',
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          {status}
        </Button>
      );

    case RequestStatusEnum.GRANT:
      return (
        <Button
          size="small"
          startIcon={<CheckCircleOutlineIcon />}
          variant="contained"
          color="primary"
          style={{
            color: theme.palette.white,
            backgroundColor: theme.palette.success.main,
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          {status}
        </Button>
      );
    case RequestStatusEnum.REVOKE:
      return (
        <Button
          size="small"
          startIcon={<ErrorOutlineIcon />}
          variant="contained"
          color="primary"
          style={{
            color: theme.palette.white,
            backgroundColor: theme.palette.error.main,
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          {status}
        </Button>
      );

    case RequestStatusEnum.OTHER:
      return (
        <Button
          size="small"
          startIcon={<AssignmentIcon />}
          variant="contained"
          color="primary"
          style={{
            borderRadius: '4px',
          }}
          onClick={handleClick}
        >
          {label}
        </Button>
      );

    default:
      return <></>;
  }
};
