import React, { useMemo } from 'react';
import { stringifyLogs } from './utils';
import { WbCard } from '../../WbCard';
import { CopyButton } from '../../CopyButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { Log } from '../../../types';
import { WbLogsViewer } from '../WbLogsViewer';

const styles = {
  button: {
    minWidth: 0,
  },
  icon: {
    width: '20px',
    height: '20px',
  },
};

interface Props {
  title: string;
  logs?: Log[];
  text?: string;
  language?: string;
  style?: React.CSSProperties;
}

export const WbLogsCard: React.FC<Props> = ({
  logs,
  text,
  title,
  language,
  style,
}) => {
  const logText = useMemo(
    () =>
      logs && logs.length > 0
        ? stringifyLogs(logs)
        : text || 'No data available',
    [logs, text],
  );

  return (
    <WbCard
      cardStyle={{ ...style }}
      title={title}
      actions={
        (logs && logs.length > 0) || text ? (
          <CopyButton
            text={logText}
            hideIcon
            size="small"
            color="secondary"
            variant="text"
            style={styles.button}
          >
            <FileCopyIcon style={styles.icon} />
          </CopyButton>
        ) : undefined
      }
    >
      <WbLogsViewer text={logText} language={language} />
    </WbCard>
  );
};
