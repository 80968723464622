import React, { useState } from 'react';
import { Button, DialogActions, useTheme } from '@material-ui/core';
import { WbCardContent } from '../../../WbCard';
import { WbCardActionButton } from '../../../WbCardActionButton';
import {
  WbDockableGroup,
  WbDockablePanel,
  WbDockableResizeHandle,
} from '../../../WbDockable';
import { useGeneralPurposePickerContext } from '../../GeneralPurposePickerContext';
import { GenericEntityType, Provider, TextFieldValue } from '../../types';
import { EntityList } from './EntityList';
import { SelectedEntities } from './SelectedEntities';
import { ProviderFilters } from '../CommonFilter';

interface MultiSelectionProps {
  availableKinds: Record<string, any>[];
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  selectedEntityProvider?: JSX.Element;
  setRenderTable: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
}

export function MultiSelection(props: MultiSelectionProps) {
  const {
    providerMapper,
    availableKinds,
    selectedEntityProvider,
    setRenderTable,
  } = props;

  const { selectedValues, setSelectedValues, setOpenDialog, selectedKind } =
    useGeneralPurposePickerContext();

  const [entities, setEntities] = useState<TextFieldValue[]>(
    (selectedValues?.tableValues as TextFieldValue[]) ?? [],
  );
  const theme = useTheme();

  return (
    <WbCardContent
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '64vh',
        padding: 0,
      }}
    >
      <WbDockableGroup direction="horizontal">
        <WbDockablePanel defaultSize={70}>
          <EntityList
            availableKinds={availableKinds}
            providerMapper={providerMapper}
            selectedKind={selectedKind}
            entities={entities}
            setEntities={setEntities}
            selectedEntityProvider={selectedEntityProvider}
            setRenderTable={setRenderTable}
          />
        </WbDockablePanel>

        <WbDockableResizeHandle
          showIconOnHover
          divider
          size={4}
          direction="horizontal"
        />

        <WbDockablePanel maxSize={30}>
          <SelectedEntities
            entities={entities ?? []}
            setEntities={setEntities}
          />
        </WbDockablePanel>
      </WbDockableGroup>
      <DialogActions
        style={{
          height: '56px',
          position: 'sticky',
          bottom: 0,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.white,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setOpenDialog(false);
          }}
        >
          Cancel
        </Button>
        <WbCardActionButton
          onClick={() => {
            setSelectedValues(preValue => {
              return {
                tableValues: entities,
                arbitraryValues: preValue?.arbitraryValues ?? [],
              };
            });
            setOpenDialog(false);
          }}
          variant="contained"
          size="small"
          color="primary"
          label="Apply"
        />
      </DialogActions>
    </WbCardContent>
  );
}
