import React from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

export const EnvironmentButtons = React.forwardRef(
  (props: { onClick: (env: string) => void }, _ref) => {
    const { onClick } = props;
    const theme = useTheme();
    const configApi = useApi(configApiRef);
    const fetchedEnvs = configApi.getStringArray('mesh.builder.environments');

    return (
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center',
          maxWidth: '400px',
          gap: theme.spacing(2),
          marginTop: theme.spacing(2),
        }}
      >
        {fetchedEnvs?.map(env => (
          <Button
            key={env}
            color="primary"
            variant="contained"
            onClick={() => onClick(env)}
            size="small"
          >
            {env}
          </Button>
        ))}
      </Box>
    );
  },
);
