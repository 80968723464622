/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useState } from 'react';
import {
  ControlPanelPage,
  CustomViews,
  EditorPage,
  EditorPageContextProvider,
  EntityLayout,
  extensionApiRef,
  ReleasePage,
  ReleasePageContextProvider,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  DEFAULT_NAMESPACE,
  parseEntityRef,
  RELATION_HAS_PART,
} from '@backstage/catalog-model';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import {
  catalogApiRef,
  entityRouteRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';

import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import {
  applyWitboostVersionedEntity,
  WitboostComponent,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import {
  createRefreshEntityFunction,
  customAlertApiRef,
  useRefreshPermission,
  WbEnvironmentSelector,
  WbHeaderActions,
} from '@agilelab/plugin-wb-platform';
import {
  configApiRef,
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { useLocation, useNavigate } from 'react-router';

import useAsync from 'react-use/lib/useAsync';
import { CatalogClientExtension } from '@agilelab/plugin-wb-catalog-extension-common';
import { TechdocsWrapper } from './TechdocsWrapper';

type ProjectPageProps = {
  variant?: 'gridItem';
  title: string;
};

export function ProjectPage({
  variant = 'gridItem',
  title,
}: Readonly<ProjectPageProps>) {
  const { entity } = useEntity<WitboostSystem>();
  const versionedEntity = applyWitboostVersionedEntity(entity);
  const [version, setVersion] = useState(versionedEntity.spec.mesh.version);
  const entityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();
  const locations = useLocation();
  const configApi = useApi(configApiRef);
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(customAlertApiRef);
  const identityApi = useApi(identityApiRef);
  const catalogApiExt = useApi(extensionApiRef) as CatalogClientExtension;
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_HAS_PART,
    kind: 'Component',
  });

  const { value: processorErrors } = useAsync(async () => {
    const entityIds = entities?.flatMap(e =>
      e.metadata.uid ? [e.metadata.uid] : [],
    );
    entityIds?.push(entity.metadata.uid!);
    const { token } = await identityApi.getCredentials();
    const result = await catalogApiExt.getEntityErrors(entityIds!, { token });
    return (
      result
        .filter(r => r.errorMessage)
        .map(r => {
          const errorEntity = entities?.find(
            e => e.metadata.uid === r.entity_id,
          );
          return {
            message: r.errorMessage,
            entity: errorEntity ?? entity,
            parent: r.entity_id === entity.metadata.uid,
          };
        }) ?? []
    );
  }, [catalogApiExt, entities]);

  const { allowRefresh } = useRefreshPermission(entity);

  const refreshEntity = createRefreshEntityFunction({
    entity,
    catalogApi,
    alertApi,
  });
  const isEditorEnabled = configApi.getOptionalBoolean(
    'catalog.editor.enabled',
  );

  const components = (entities || []) as WitboostComponent[];

  if (loading) {
    return (
      <InfoCard variant={variant} title={title}>
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title={title}>
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  // For each component type we crate an independent table.
  // The table code is a replica of plugin-catalog/RelatedEntitiesCard.
  // We don't use RelatedEntitiesCard directly because here we load all entities just once
  // instead of doing it once for each component type.

  return (
    <EntityLayoutWrapper isWrappingProject version={version}>
      <EntityLayout.Route path="/" title="Overview">
        <CustomViews.DataProductPage
          data={{
            entity,
            componentsList: components,
            isEditorEnabled,
            allowRefresh,
          }}
          actions={{
            refreshEntity: refreshEntity,
            showComponent: (e: any) => {
              const data = {
                name: e?.metadata.name || '',
                kind: e?.kind,
                namespace: e?.metadata.namespace || '',
              };

              data.kind = data.kind.toLocaleLowerCase('en-US');
              data.namespace =
                data.namespace?.toLocaleLowerCase('en-US') ?? DEFAULT_NAMESPACE;

              const ref = parseEntityRef(data);
              const preUrl = locations.pathname.includes('my-projects')
                ? '/my-projects'
                : '';
              navigate(`${preUrl}${entityRoute(ref)}`);
            },
          }}
        />
      </EntityLayout.Route>
      <EntityLayout.Route path="/docs" title="Docs">
        <TechdocsWrapper entity={entity} />
      </EntityLayout.Route>

      {isEditorEnabled && (
        <EntityLayout.Route
          path="/editor"
          title="Edit and Test"
          overrideHeaderActions={
            <WbHeaderActions>
              <WbEnvironmentSelector />
            </WbHeaderActions>
          }
          tabProps={{ style: { textTransform: 'none' } }}
        >
          <EditorPageContextProvider
            entity={entity}
            relatedEntities={entities}
            setVersion={setVersion}
            version={version}
            processorErrors={processorErrors}
          >
            <EditorPage />
          </EditorPageContextProvider>
        </EntityLayout.Route>
      )}

      {isEditorEnabled && (
        <EntityLayout.Route path="/release" title="Deployment">
          <ReleasePageContextProvider
            entity={entity}
            relatedEntities={entities}
            setVersion={setVersion}
            processorErrors={processorErrors}
          >
            <ReleasePage />
          </ReleasePageContextProvider>
        </EntityLayout.Route>
      )}

      {!isEditorEnabled && (
        <EntityLayout.Route path="/deploy" title="Control Panel">
          <ControlPanelPage entity={versionedEntity} setVersion={setVersion} />
        </EntityLayout.Route>
      )}
    </EntityLayoutWrapper>
  );
}
