import {
  useDataPath,
  WbCard,
  WbCardContent,
  WbNoData,
} from '@agilelab/plugin-wb-platform';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => {
  const border = `1px solid ${theme.palette.grey[300]}`;
  return {
    iconContainer: {
      border: `1px solid ${theme.palette.softened.primary}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '16px',
      borderRadius: '80%',
      borderStyle: 'dashed',
      // to separate the icon from the header and text
      margin: theme.spacing(1),
    },
    icon: {
      fontSize: '80px',
      color: theme.palette.softened.primary,
    },
    textNoDataContent: {
      textAlign: 'center',
      maxWidth: '400px',
    },
    table: {
      minWidth: '800px',
      '& th': {
        borderBottom: border,
        borderTop: border,
        borderLeft: border,
        '&:last-child': {
          borderRight: border,
        },
      },
      '& td': {
        borderLeft: border,
        '&:last-child': {
          borderRight: border,
        },
      },
      '& tr:last-child': {
        '& td': {
          borderBottom: border,
        },
      },
    },
  };
});

export const DataPreviewCard = (props: {
  title?: string;
  path?: string;
  noDataLabel?: string;
  hideNoDataIcon?: boolean;
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const sampleData = useDataPath(props.path ?? 'sampleData');

  const title = props.title ?? 'Data Preview';
  const noDataLabel = props.noDataLabel ?? 'No sample data available';

  return (
    <div style={{ marginBottom: '16px' }}>
      <WbCard
        title={title}
        toolsStyle={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          padding: '8px 16px',
          wordBreak: 'break-word',
        }}
      >
        {!!sampleData &&
        Array.isArray(sampleData?.columns) &&
        Array.isArray(sampleData?.rows) &&
        (sampleData?.columns?.length || 0) > 0 ? (
          <WbCardContent>
            <TableContainer>
              <Table
                aria-label="data preview table"
                size="small"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    {sampleData?.columns.map((h: string) => (
                      <TableCell key={h}>
                        <Typography
                          variant="body1"
                          style={{
                            color: theme.palette.primary.main,
                            textTransform: 'uppercase',
                          }}
                        >
                          {h}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sampleData?.rows
                    .filter((row: string[]) => Array.isArray(row))
                    .map((row: string[], index: number) => (
                      <TableRow key={index}>
                        {row.map((value, key) => (
                          <TableCell key={key}>
                            <Typography>
                              {Array.isArray(value)
                                ? JSON.stringify(value)
                                    .replaceAll('"', '')
                                    .replaceAll(',', ', ')
                                : value}
                            </Typography>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </WbCardContent>
        ) : (
          <WbNoData
            fillContainer
            icon={
              !props.hideNoDataIcon ? (
                <Box className={classes.iconContainer}>
                  <LayersClearIcon className={classes.icon} />
                </Box>
              ) : (
                <></>
              )
            }
            text={
              <Typography className={classes.textNoDataContent}>
                {noDataLabel}
              </Typography>
            }
          />
        )}
      </WbCard>
    </div>
  );
};
