import React, { ReactNode } from 'react';
import { Tooltip, TooltipProps, makeStyles } from '@material-ui/core';

export type WbPopoverProps = Omit<
  TooltipProps,
  'arrow' | 'title' | 'classes'
> & {
  component: NonNullable<ReactNode>;
};

const useStyles = makeStyles(
  theme => ({
    // override the default tooltip wrapper style
    tooltip: {
      fontSize: 'inherit',
      color: 'inherit',
      padding: 0,
      backgroundColor: theme.palette.white,
      borderRadius: theme.spacing(0.5),
      boxShadow: theme.shadows[1],
      maxWidth: 'none',
    },
  }),
  { name: 'WbPopover' },
);

export const WbPopover: React.FC<WbPopoverProps> = ({
  component,
  ...props
}) => {
  const classes = useStyles();
  return <Tooltip {...props} classes={classes} title={component} />;
};
