import { useEntityList } from '@backstage/plugin-catalog-react';
import { PracticeShaperFilters } from '../types';
import { useEffect, useMemo, useState } from 'react';
import { ResourceTypeFilter, ResourceTypeFilterValue } from '../filters';

export function useResourceTypeFilter(opts: {
  initialValues: ResourceTypeFilterValue;
  allowedResourceTypes?: string[];
}): {
  selectedTypes: ResourceTypeFilterValue;
  setSelectedTypes: (types: ResourceTypeFilterValue) => void;
} {
  const { initialValues, allowedResourceTypes } = opts;
  const {
    filters,
    queryParameters: { resourceType: resourceTypeQueryParam },
    updateFilters,
  } = useEntityList<PracticeShaperFilters>();

  const queryParam = useMemo(() => {
    if (resourceTypeQueryParam === 'all') return 'all';
    else if (resourceTypeQueryParam !== undefined)
      return [resourceTypeQueryParam].flat();
    return undefined;
  }, [resourceTypeQueryParam]);

  const [selectedTypes, setSelectedTypes] = useState(
    queryParam ?? filters.resourceType?.selectedValues ?? initialValues,
  );

  // Set selected types on query parameter updates; this happens at initial page load and from
  // external updates to the page location.
  useEffect(() => {
    if (queryParam) {
      setSelectedTypes(queryParam);
    }
  }, [queryParam]);

  useEffect(() => {
    updateFilters({
      resourceType: new ResourceTypeFilter(selectedTypes, allowedResourceTypes),
    });
  }, [selectedTypes, allowedResourceTypes, updateFilters]);

  return {
    selectedTypes,
    setSelectedTypes,
  };
}
