import {
  CollapseButton,
  WbCard,
  WbCardContent,
  WbTabs,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { TestCardActions } from './TestCardActions';
import { Box } from '@material-ui/core';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { TestHistory } from './TestHistory';
import { TestCurrent } from './TestCurrent';

const contentByTab: Record<'current' | 'history', JSX.Element> = {
  current: <TestCurrent />,
  history: <TestHistory />,
};

export const TestCard: React.FC = () => {
  const { setTestCollapsed, selectedTestTab, setSelectedTestTab } =
    useEditorPageContext();

  return (
    <WbCard
      title="Test"
      actions={<TestCardActions />}
      leading={
        <CollapseButton
          onClick={() => {
            setTestCollapsed(true);
          }}
        />
      }
      toolsComponents={
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'end',
            width: '100%',
          }}
        >
          <WbTabs
            tabs={[
              {
                name: 'Current',
                selected: selectedTestTab === 'current',
                action: () => {
                  setSelectedTestTab('current');
                },
              },
              {
                name: 'History',
                selected: selectedTestTab === 'history',
                action: () => {
                  setSelectedTestTab('history');
                },
              },
            ]}
          />
        </Box>
      }
      toolsStyle={{
        border: 'none',
      }}
      toolsBarStyle={{
        width: '100%',
      }}
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
        }}
      >
        {contentByTab[selectedTestTab]}
      </WbCardContent>
    </WbCard>
  );
};
