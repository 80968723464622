import { builderDpSnapshotCreatePermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { usePermission } from '@backstage/plugin-permission-react';
import { Box, Tooltip } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import AddCircle from '@material-ui/icons/AddCircle';
import { panelCatalogApiRef } from '../../../api';
import { useControlPanel } from '../useControlPanel';
import { DeployStepDetailDrawer } from './DeployStepDetailDrawer';
import { ReleaseTable } from './ReleaseTable';
import { LogsDrawer } from './LogsDrawer/LogsDrawer';
import {
  WbCardActionButton,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { Progress } from '@backstage/core-components';

export function ReleaseCard() {
  const [isCreatingSnapshot, setIsCreatingSnapshot] = useState(false);
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const {
    releases,
    addRelease,
    fetchReleaseState,
    entity,
    environment,
    fetchPreviewDescriptor,
  } = useControlPanel();

  const { allowed: canCreateSnapshot } = usePermission({
    permission: builderDpSnapshotCreatePermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const createSnapshot = async () => {
    try {
      if (!entity) return;

      setIsCreatingSnapshot(true);
      const newRelease = await panelCatalog.createSnapshot(
        entity.metadata.name,
        entity.kind,
      );
      addRelease(newRelease);
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      // We are calling this function here in order to refresh Preview Descriptor window
      // with the latest version of the descriptor(latest version field particularly)
      await fetchPreviewDescriptor();
      setIsCreatingSnapshot(false);
    }
  };

  // snapshot creation is disabled if there is a already a snapshot
  const isCreateSnapshotDisabled = useMemo(() => {
    if (releases) {
      return !!releases.find(r => r.metadata.isSnapshot);
    }
    return false;
  }, [releases]);

  if (fetchReleaseState.loading) {
    return (
      <div style={{ width: '100%' }}>
        <Progress />
      </div>
    );
  }

  function generateSnapshotButtonTooltip(allowed: boolean, disabled: boolean) {
    if (!allowed) {
      return 'You are not allowed to create a new snapshot.';
    }
    if (!disabled) {
      return 'Create a new snapshot from the current code on the main repository. A snapshot is an intermediate state between the latest release and the new one. You can add the work done in the repository main branch using the commit operation, which will update the current snapshot.';
    }
    return "You can't create a new snapshot since there is already an active one. To create a new snapshot, you need to create a release from the current one first.";
  }

  if (!releases || !environment) return null;

  return (
    <WbCard
      title="Deploy"
      cardStyle={{ height: '100%' }}
      actions={
        <Tooltip
          title={generateSnapshotButtonTooltip(
            canCreateSnapshot,
            isCreateSnapshotDisabled,
          )}
        >
          <span>
            <WbCardActionButton
              label="New Snapshot"
              disabled={
                isCreatingSnapshot ||
                isCreateSnapshotDisabled ||
                !canCreateSnapshot
              }
              onClick={createSnapshot}
              loading={isCreatingSnapshot}
              icon={<AddCircle />}
            />
          </span>
        </Tooltip>
      }
    >
      <WbCardContent style={{ height: '100%' }}>
        <Box display="flex" flexDirection="column" height="100%">
          <ReleaseTable releases={releases} />
          <DeployStepDetailDrawer />
          <LogsDrawer />
        </Box>
      </WbCardContent>
    </WbCard>
  );
}
