import { useTheme } from '@material-ui/core';

export function useDefaultThresholdColors() {
  const theme = useTheme();

  const defaultThresholdColors = [
    { label: 'Red', value: theme.palette.error.main, defaultLabel: 'error' },
    {
      label: 'Yellow',
      value: theme.palette.warning.main,
      defaultLabel: 'warning',
    },
    {
      label: 'Green',
      value: theme.palette.success.main,
      defaultLabel: 'passed',
    },
  ];

  return { defaultThresholdColors };
}
