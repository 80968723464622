import { Outcome } from '@agilelab/plugin-wb-governance-common';
import { DataContractStatusSummary } from '../../hooks/useDataContractStatus';

export const extractErrorMessageFromDataContractResult = (
  result: DataContractStatusSummary,
) => {
  if (!result) return undefined;
  if (!result.isDataContractRoot) return undefined;
  if (!result.lastResult) return undefined;
  if (result.lastResult.details.parsingOutcome === 'failure')
    return 'Malformed Data Contract Evaluation';
  if (result.lastResult.outcome === Outcome.Error)
    return (
      result.lastResult.details.notes?.errorSummary?.message ??
      'Critical Data Contract Violation'
    );
  return undefined;
};
