import React, { useEffect } from 'react';
import { SemanticLinkingTable } from '../ComponentPage/OverviewPage/SemanticLinkingTable';
import { useLocation, useNavigate } from 'react-router';
import { usePublishEvent } from '@agilelab/plugin-wb-events';
import { marketplaceConsumableInterfaceNavigatedTopic } from '@agilelab/plugin-wb-events-common';
import { CustomView, useDialog } from '@agilelab/plugin-wb-platform';
import { useRouteRef } from '@backstage/core-plugin-api';
import { outputPortRouteRef } from '../../routes';
import { ComponentDetailsData } from './utils';

export const OutputPortPage = (props: {
  data: ComponentDetailsData;
  actions?: Record<string, any>;
}) => {
  const data = props.data;
  const semanticLinkingDialog = useDialog({ title: 'Semantic Linking' });
  const navigate = useNavigate();
  const consumableRouteRef = useRouteRef(outputPortRouteRef);
  const navigateToComponent = (id: number) =>
    navigate(consumableRouteRef({ id: id.toString() }));
  const { publish } = usePublishEvent({
    generatorSystem: 'marketplace',
    keyMarketplace: data?.componentInstance?.descriptor?.id,
  });

  const location = useLocation();

  useEffect(() => {
    if (data?.componentInstance?.descriptor?.id) {
      publish(marketplaceConsumableInterfaceNavigatedTopic, {
        path: location.pathname,
        entityUrn: data?.componentInstance?.descriptor?.id,
      });
    }
  }, [location.pathname, data?.componentInstance?.descriptor?.id, publish]);

  return (
    <CustomView
      id="marketplace_component"
      typeId={data.componentInstance?.descriptor?.kind.toLowerCase()}
      templateId={data.componentInstance?.descriptor?.useCaseTemplateId || ''}
      actions={
        props.actions || {
          showSubcomponent: (component: any) => {
            navigateToComponent(component.id);
          },
          showSemanticLink: async (field: any) => {
            await semanticLinkingDialog(
              <SemanticLinkingTable descriptor={data as any} schema={field} />,
            );
          },
        }
      }
      data={data}
    />
  );
};
