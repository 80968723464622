import {
  WbSelectInline,
  WbSelectInlineItem,
} from '@agilelab/plugin-wb-platform';
import {
  OrderByConfig,
  OrderByOption,
} from '@agilelab/plugin-wb-search-common';
import { ConfigApi, configApiRef, useApi } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import React from 'react';

const getOrdersFromConfig = (
  configApi: ConfigApi,
): { label: string; field: string; order: string }[] => {
  const configOrderByValues =
    configApi.getOptional<OrderByConfig[]>(
      'mesh.marketplace.ui.searchPage.orderBy',
    ) ?? [];
  return [
    { label: 'Relevance', field: 'rank', order: 'desc' },
    ...configOrderByValues
      .map(v => {
        const finalValues = [];
        if (v.descLabel) {
          finalValues.push({
            label: v.descLabel,
            field: v.field,
            order: 'desc',
          });
        }

        if (v.ascLabel) {
          finalValues.push({ label: v.ascLabel, field: v.field, order: 'asc' });
        }

        return finalValues;
      })
      .flat(),
  ];
};

export interface OrderBySelectorProps {
  orderByOption: OrderByOption;
  onChange: (value: OrderByOption) => void;
}

export const OrderBySelector: React.FC<OrderBySelectorProps> = ({
  onChange,
  orderByOption,
}) => {
  const configApi = useApi(configApiRef);
  const selectableOrderByFields = getOrdersFromConfig(configApi);

  const parseOption = (value: string): OrderByOption => {
    const selectedField = selectableOrderByFields.find(f => f.label === value);
    return {
      field: selectedField?.field.replaceAll('.', ','),
      order: selectedField?.order,
    };
  };

  const stringifyOption = (value: OrderByOption): string | undefined => {
    const selectedField = selectableOrderByFields.find(
      f =>
        f.field.replaceAll('.', ',') === value.field && f.order === value.order,
    );
    return selectedField?.label;
  };

  return (
    <WbSelectInline
      title="Order by:"
      value={stringifyOption(orderByOption)}
      onChange={e => {
        onChange(parseOption(e));
      }}
    >
      {...selectableOrderByFields.map(option => (
        <WbSelectInlineItem {...{ key: option.label, value: option.label }}>
          <Typography variant="body2">{option.label}</Typography>
        </WbSelectInlineItem>
      ))}
    </WbSelectInline>
  );
};
