import {
  EvaluationResultMetric,
  MetricItem,
} from '@agilelab/plugin-wb-governance-common';
import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { GET_LAST_FAILED_EVALUATIONS_METRICS } from '../queries/metrics.query';

export const useLazyMetrics = (dpId: string, env: string) => {
  const [metrics, setMetrics] = React.useState<Array<MetricItem> | undefined>(
    [],
  );
  const [fetchMetrics, { loading, error, data }] =
    useLazyQuery<EvaluationResultMetric>(GET_LAST_FAILED_EVALUATIONS_METRICS, {
      variables: {
        dataProductUrn: dpId,
        environment: env,
      },
      fetchPolicy: 'no-cache',
    });

  React.useEffect(
    () =>
      setMetrics(
        data?.cgp_evaluation_result.reduce((acc, metric) => {
          const metricItem = {
            id: metric.id,
            name: metric.governance_entity?.name,
            description: metric.governance_entity?.description,
            version: metric.governance_entity?.version,
          };
          if (metric.result?.errors?.length) {
            acc.push(
              Object.assign(metricItem, {
                error: !!metric.result.errors.length,
              }) as MetricItem,
            );
            return acc;
          }
          if (metric.result?.value) {
            acc.push(
              Object.assign(metricItem, {
                result: metric.result?.thresholdResult,
                value: metric.result?.value,
              }) as MetricItem,
            );
            return acc;
          }
          return acc;
        }, [] as Array<MetricItem>),
      ),
    [data],
  );

  return { metrics, loading, error, fetchMetrics };
};
