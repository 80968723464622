import {
  Entity,
  isApiEntity,
  isComponentEntity,
  isDomainEntity,
  isGroupEntity,
  isLocationEntity,
  isResourceEntity,
  isSystemEntity,
  isUserEntity,
} from '@backstage/catalog-model';
import { Box } from '@material-ui/core';
import React from 'react';

type OfUnion<T extends { type: string }> = {
  [P in T['type']]: Extract<T, { type: P }>;
};

type Handler<T> = {
  [P in keyof T]: (variant: T[P]) => any;
};

/** Utility to perform a matching algorithm on a union type.
 * Given a union type like U = A | B | C, where A { type: 'first' }, B { type: 'second' }, and C { type: 'third' },
 * you can match a variable of type U in the following way:
 * ```
 * match(variable, {
 *  first:  (a) => { ... },
 *  second: (b) => { ... },
 *  third:  (c) => { ... }
 * });
 * ```
 * Note that a will be of type A, b of type B, and c of type C in the lambdas.
 * This allows you to treat union type with the right types at runtime.
 *
 * N.B.: every type in the union must have a 'type' field that represents it, that will eb used in the handler keys.
 * @param obj the union type variable
 * @param handler an object that has for keys the 'type' field of each type in the union
 * @returns the result of the lambda applied to the right type. It's highly recommended to have all the lambdas return a common type.
 */
export function match<
  T extends { type: string },
  H extends Handler<OfUnion<T>>,
>(obj: T, handler: H): ReturnType<H[keyof H]> {
  return handler[obj.type as keyof H]?.(obj as any);
}

/**
 * Renders a message with proper newline and tab handling.
 *
 * @param {string} message - The message to be rendered.
 * @returns {JSX.Element[]} An array of JSX elements representing the message.
 */
export function renderMessage(message: string): JSX.Element[] {
  return message.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {index > 0 && <br />}
      {line.replace(/\t/g, '\u00A0\u00A0\u00A0')}
    </React.Fragment>
  ));
}

/**
 * Renders a message using different colors depending on the severity.
 * @param messages
 * @returns
 */
export function renderMessageWithSeverity(
  messages: { line: string; severity: 'info' | 'warning' | 'error' }[],
  theme: any,
) {
  const convertSeverityToColor = (severity: 'info' | 'warning' | 'error') => {
    switch (severity) {
      case 'info':
        return theme.palette.grey;
      case 'warning':
        return theme.palette.warning.main;
      case 'error':
        return theme.palette.error.main;
      default:
        throw new Error(`Invalid severity ${severity}`);
    }
  };

  return messages.map(({ line, severity }, index) => (
    <React.Fragment key={index}>
      <span style={{ color: convertSeverityToColor(severity) }}>
        {index > 0 && <br />}
        {line.replace(/\t/g, '\u00A0\u00A0\u00A0')}
      </span>
    </React.Fragment>
  ));
}

type EllipsisTextProps = {
  children: React.ReactNode;
  maxWidth: number;
};

/**
 * Renders the children using the 'ellipsis' style by truncated them after exceeding the maxWidth pixel limit.
 * @param props
 * @returns
 */
export function EllipsisText(props: EllipsisTextProps) {
  const { children, maxWidth } = props;

  return (
    <Box
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: `${maxWidth}px`,
      }}
    >
      {children}
    </Box>
  );
}

/**
 * Converts a value to an Entity if it matches any of the specified entity types.
 * @returns The converted Entity if the value matches any of the specified entity types, or `undefined` otherwise.
 */
export function asEntity(value: any): Entity | undefined {
  try {
    const isEntity = [
      isDomainEntity,
      isGroupEntity,
      isUserEntity,
      isResourceEntity,
      isApiEntity,
      isComponentEntity,
      isSystemEntity,
      isLocationEntity,
    ].some(check => check(value));
    return isEntity ? (value as Entity) : undefined;
  } catch (_e) {
    return undefined;
  }
}
