import { usePermission } from '@backstage/plugin-permission-react';
import { catalogEntityRefreshPermission } from '@backstage/plugin-catalog-common/alpha';
import {
  ANNOTATION_LOCATION,
  Entity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { catalogPlatformRefreshPermission } from '@agilelab/plugin-wb-rbac-common';

export const useRefreshPermission = (entity?: Entity | null) => {
  const { allowed: allowRefreshPermission } = usePermission({
    permission: catalogEntityRefreshPermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? '',
      namespace: 'default',
      name: entity?.metadata?.name ?? '',
    }),
  });

  const { allowed: allowedPlatformRefresh } = usePermission({
    permission: catalogPlatformRefreshPermission,
  });
  if (!entity) return { allowRefresh: false };

  const refreshPermission =
    entity.kind === 'System' || entity.kind === 'Component'
      ? allowRefreshPermission
      : allowRefreshPermission && allowedPlatformRefresh;

  const entityLocation = entity.metadata.annotations?.[ANNOTATION_LOCATION];

  const allowRefresh =
    refreshPermission &&
    (entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:'));

  return { allowRefresh };
};
