import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import ErrorIcon from '@material-ui/icons/Error';
import { Severity } from '@agilelab/plugin-wb-governance-common';
import { IconLabelBox } from '../../IconLabelBox';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

interface Props {
  severity?: Severity;
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const WarningIcon: React.FC<Props> = ({ severity, style, variant }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Tooltip
      title={`Failed with severity ${
        severity ?? 'WARNING'
      }. This policy is just a warning, and will not block the deployment, but remember to check what errors made it fail`}
    >
      <span>
        {variant === 'reduced' ? (
          <ErrorIcon
            className={`${classes.icon}`}
            htmlColor={theme.palette.warning.main}
            style={{ ...style, transform: 'scale(1.2)' }}
          />
        ) : (
          <IconLabelBox
            label="Warning"
            backgroundColor={theme.palette.warning.main}
            icon={
              <PriorityHighIcon style={style} htmlColor={theme.palette.black} />
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
