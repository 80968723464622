import { WbShowMoreElement } from '@agilelab/plugin-wb-platform';
import { Link } from '@backstage/core-components';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import React, { Fragment } from 'react';

type Props = {
  children?: React.ReactNode;
  href?: string;
};

export const ExpandableItem: React.FC<Props> = ({ children, href }) => {
  const configApi = useApi(configApiRef);
  const maxLines =
    configApi.getOptionalNumber('mesh.marketplace.maxLinesBeforeTruncate') || 3;
  const renderedValue = (
    <WbShowMoreElement maxLines={maxLines}>{children}</WbShowMoreElement>
  );

  return (
    <Fragment>
      {href ? (
        <Link target="_blank" rel="noreferrer" to={href}>
          {renderedValue}
        </Link>
      ) : (
        renderedValue
      )}
    </Fragment>
  );
};
