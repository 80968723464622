import React from 'react';
import { makeStyles } from '@material-ui/core';
import { WbBadge } from '../WbBadge';

type WbStatusBadgeProps = {
  ok: boolean;
};

const useStyles = makeStyles(
  theme => ({
    ok: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.black,
    },
    notOk: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.black,
    },
  }),
  { name: 'WbStatusBadge' },
);

export const WbStatusBadge: React.FC<WbStatusBadgeProps> = ({ ok }) => {
  const classes = useStyles();
  return (
    <WbBadge className={!ok ? classes.notOk : classes.ok}>
      {ok ? 'OK' : 'KO'}
    </WbBadge>
  );
};
