import {
  IconButton,
  InputAdornment,
  TextField,
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React from 'react';

const useStyles = makeStyles(() => ({
  resizeFont: {
    fontSize: 14,
  },
}));
interface SearchComponentProps {
  searchValue: string;
  handleChange: (event: any) => void;
  handleClear: () => void;
}

export const SearchComponent: React.FC<SearchComponentProps> = ({
  searchValue,
  handleChange,
  handleClear,
}) => {
  const classes = useStyles();
  return (
    <TextField
      value={searchValue}
      size="small"
      onChange={handleChange}
      placeholder="Search"
      InputProps={{
        classes: {
          input: classes.resizeFont,
        },
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton
            aria-label="clear"
            onClick={handleClear}
            disabled={!searchValue}
            size="small"
            style={{ visibility: searchValue ? 'visible' : 'hidden' }}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        ),
        'aria-label': 'search',
      }}
      style={{ width: '100%' }}
      aria-describedby="standard-weight-helper-text"
    />
  );
};
