import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupsIcon from '@material-ui/icons/Group';
import React, { useState } from 'react';
import {
  TableCellProps,
  WbTruncatedTypographyWrapper,
  WbCard,
  WbTableWithFilters,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { RoleSettingsView } from '@agilelab/plugin-wb-rbac-common';
import { useRoleContext } from '../Context/RoleContext';
import { DetailDrawer } from '../DetailDrawer';
import { RolesDetailDrawer } from './RolesDrawerHeader';
import { PermissionsDrawerTable } from '../PermissionsTable/PermissionsDrawerTable';
import { RoleDrawerContextProvider } from '../Context/PermissionDrawerTableContext';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useTableColumnSpecs = (): Array<TableCellProps<RoleSettingsView>> => {
  const truncatedClass = useTruncatedStyles();

  return [
    {
      field: 'id',
      fieldRender: (row: RoleSettingsView) =>
        row.subject_kind === 'Group' ? <GroupsIcon /> : <PersonIcon />,
      cellProps: {
        size: 'small',
        align: 'right',
        width: '1%',
        style: { wordBreak: 'break-word', paddingRight: 0 },
      },
      headerName: '',
    },
    {
      field: 'subjectdisplayname',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word', paddingLeft: 10 },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Subject',
      sortable: true,
    },
    {
      field: 'displayName',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Role',
      sortable: true,
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Description',
      sortable: true,
    },
    {
      field: 'entity_ref_displayname',
      fieldRender: (row: RoleSettingsView) => {
        return (
          <Tooltip
            title={
              row.warning
                ? 'Entity has not been found in the catalog. This role has no effect.'
                : row.transformed_entity_ref
            }
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <WbTruncatedTypographyWrapper
                value={row.transformed_entity_ref}
              />
              {row.warning && <ErrorOutlineIcon />}
            </div>
          </Tooltip>
        );
      },
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Scope',
      sortable: true,
    },
    {
      field: 'count',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '15%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Permissions',
    },
  ];
};

export const RolesTable: React.FC = () => {
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [roleDetailsDrawers, setRoleDetailsDrawers] = useState<{
    role: string;
    subject: string;
    roleDescription: string;
    target: string;
    roleId: string;
  }>();

  const [detailsDrawers, setDetailsDrawers] = useState<{
    subject: string;
    target: string;
  }>();

  const {
    roles,
    loading,
    setPagination,
    pagination,
    roleCount,
    filters,
    dispatchOrder,
    changeFilters,
  } = useRoleContext();

  return (
    <>
      <WbTableWithFilters<RoleSettingsView>
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
        filters={{
          searchValue: filters.text ?? '',
          onSearchChange: (search: string) => {
            changeFilters('text', search);
          },
          activeFilters: 0,
          onFiltersClick: () => {},
          show: false,
        }}
        table={{
          components: {
            tableLoader: { loading: loading },
            tableContent: {
              columns: useTableColumnSpecs(),
              rows: roles,
              onRowClick: (row: RoleSettingsView) => {
                setOpenDetailDrawer(true);
                setRoleDetailsDrawers({
                  role: row.displayName,
                  subject: row.subjectdisplayname,
                  roleDescription: row.description,
                  target: row.entity_ref_displayname,
                  roleId: row.roleId,
                });
                setDetailsDrawers({
                  subject: row.subject,
                  target: row.entity_ref,
                });
              },
            },
          },
          onSort: (_, field, direction) => {
            setPagination({
              limit: pagination.limit,
              offset: pagination.offset,
            });
            dispatchOrder({ field: field.headerName || '', direction });
          },
          pagination: {
            count: Number(roleCount) || 0,
            limit: pagination.limit,
            offset: pagination.offset,
            onPageChange: (page: number) => {
              setPagination({
                ...pagination,
                offset: page * pagination.limit,
              });
            },
            onRowsPerPageChange: (rowsPerPage: number) => {
              setPagination({
                offset: 0,
                limit: rowsPerPage,
              });
            },
          },
        }}
      />
      <DetailDrawer
        open={openDetailDrawer}
        onClose={() => {
          setOpenDetailDrawer(false);
        }}
      >
        <RolesDetailDrawer
          role={roleDetailsDrawers?.role ?? ''}
          roleDescription={roleDetailsDrawers?.roleDescription ?? ''}
          subject={roleDetailsDrawers?.subject ?? ''}
          target={roleDetailsDrawers?.target ?? ''}
        />
        <WbCard title="Permissions defined for this role">
          <RoleDrawerContextProvider
            subject={detailsDrawers?.subject ?? ''}
            target={detailsDrawers?.target ?? ''}
            role={roleDetailsDrawers?.roleId ?? ''}
          >
            <PermissionsDrawerTable />
          </RoleDrawerContextProvider>
        </WbCard>
      </DetailDrawer>
    </>
  );
};
