import React, { useContext, useEffect, useState } from 'react';
import { CustomSelectedValues, KindEnum, TextFieldValue } from './types';
import { toArray } from 'lodash';
import { PreviewMessage } from '../CustomSnackbarError';

interface GeneralPurposePickerContextType {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedValues: CustomSelectedValues;
  setSelectedValues: React.Dispatch<React.SetStateAction<CustomSelectedValues>>;
  selectedKind: string;
  setSelectedKind: React.Dispatch<React.SetStateAction<string>>;
  errorMessage?: PreviewMessage;
  setErrorMessage: React.Dispatch<
    React.SetStateAction<PreviewMessage | undefined>
  >;
}

export const GeneralPurposePickerContext =
  React.createContext<GeneralPurposePickerContextType>(
    {} as GeneralPurposePickerContextType,
  );

export interface GeneralPurposePickerProps {
  availableKinds: Record<string, any>;
  onSelect: (val: TextFieldValue[] | undefined) => void;
  children?: React.ReactNode;
  value?: TextFieldValue[];
  resolvedValues?: TextFieldValue[];
  templateError?: PreviewMessage;
}

export const GeneralPurposePickerContextProvider: React.FC<
  GeneralPurposePickerProps
> = ({
  availableKinds,
  onSelect,
  children,
  resolvedValues,
  templateError,
}: GeneralPurposePickerProps) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<PreviewMessage>();

  const [selectedKind, setSelectedKind] = useState<string>(
    availableKinds[0]?.type,
  );
  const [selectedValues, setSelectedValues] = useState<{
    tableValues: any[];
    arbitraryValues?: any[];
  }>({
    arbitraryValues:
      resolvedValues?.filter(val => val?.kind === KindEnum.arbitrary) ?? [],
    tableValues:
      resolvedValues?.filter(val => val?.kind !== KindEnum.arbitrary) ?? [],
  });

  useEffect(() => {
    if (templateError) {
      setErrorMessage({
        type: 'error',
        title: templateError.title,
        content: templateError.content,
      });
    }
  }, [templateError]);

  useEffect(() => {
    setSelectedValues({
      arbitraryValues:
        resolvedValues?.filter(val => val?.kind === KindEnum.arbitrary) ?? [],
      tableValues:
        resolvedValues?.filter(val => val?.kind !== KindEnum.arbitrary) ?? [],
    });
  }, [resolvedValues]);

  useEffect(() => {
    const values = toArray(selectedValues).flat();
    onSelect(values.length ? values : undefined);
  }, [onSelect, selectedValues]);

  return (
    <GeneralPurposePickerContext.Provider
      value={{
        openDialog,
        setOpenDialog,
        selectedValues,
        setSelectedValues,
        selectedKind,
        setSelectedKind,
        errorMessage,
        setErrorMessage,
      }}
    >
      {children}
    </GeneralPurposePickerContext.Provider>
  );
};

export const useGeneralPurposePickerContext = () =>
  useContext(GeneralPurposePickerContext);
