import { gql } from '@apollo/client';
import { GOVERNANCE_POLICY_FRAGMENT } from '../../../fragments/governance-policy';

export const GET_METRIC_BY_METRIC_ID_AND_ENVS = gql`
  query GET_METRIC_BY_METRIC_ID_AND_ENVS($metricId: String!) {
    cgp_governance_entity(where: { governance_entity_id: { _eq: $metricId } }) {
      ${GOVERNANCE_POLICY_FRAGMENT}
    }
  }
`;
