import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { ProvisioningCardActions } from './ProvisioningCardActions';
import { ComponentsTable } from './ComponentsTable/ComponentsTable';
import { EntityListProvider } from '@backstage/plugin-catalog-react';
import { LatestProvisioning } from './LatestProvisioningAccordion/LatestProvisioning';
import { Box, Typography } from '@material-ui/core';
import { QuickLinks } from './QuickLinks';

export const ProvisioningCard: React.FC = () => {
  return (
    <EntityListProvider>
      <WbCard
        cardStyle={{ display: 'block' }}
        title="Provisioning"
        actions={<ProvisioningCardActions />}
      >
        <WbCardContent>
          <LatestProvisioning />
          <QuickLinks />
          <Box
            style={{
              marginLeft: '16px',
              marginBottom: '8px',
              marginTop: '8px',
            }}
          >
            <Typography style={{ fontWeight: 500 }}>Components</Typography>
          </Box>
          <ComponentsTable />
        </WbCardContent>
      </WbCard>
    </EntityListProvider>
  );
};
