/**
 * This class handles a very simple Open ID Connect login.
 * The session is stored locally, and it is refreshed each time the internal Backstage token expires.
 * To refresh the session we pass to the backend provider the needed information through HTTP headers.
 * Scopes are currently not handled; they are defined here since the classes where we took the code
 * handled also toke scopes.
 * The connector and session manager used are enhanced from the Backstage ones.
 */

import {
  ApiRef,
  BackstageIdentityApi,
  createApiRef,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';

export const oidcAuthApiRef: ApiRef<
  ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'core.auth.oidc',
});
