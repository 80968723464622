import {
  SearchFilterConfig,
  SearchFilterConfigType,
} from '@agilelab/plugin-wb-search-common';
import {
  FilterState,
  FilterAvailableValuesByLabel,
  FilterValidatorsByType,
} from './types';

const CHOICE_VALIDATOR: FilterValidatorsByType[SearchFilterConfigType.CHOICE] =
  (availableValues, filterState) => {
    // if state is empty, it is valid
    if (!filterState) return { valid: true, cleanedValue: [] };

    let valid = true;
    const newValue: string[] = [];
    // get all the allowed available availableValues for this filter
    const allowedValues = new Set(availableValues.map(v => v.value));

    (filterState as string[]).forEach(v => {
      if (!allowedValues.has(v)) {
        valid = false;
        return;
      }
      newValue.push(v);
    });

    return { valid, cleanedValue: newValue };
  };

export const TYPE_VALIDATORS: FilterValidatorsByType = {
  [SearchFilterConfigType.CHOICE]: CHOICE_VALIDATOR,
  [SearchFilterConfigType.TYPE]: CHOICE_VALIDATOR,
};

/**
 * Tests the current filter state against the available values for every filter to check if all the current values are allowed
 * @param filterConfig
 * @param availableValues
 * @param filterState
 * @returns {{ valid: boolean; cleanedValue: FilterState }} valid - wheter the current filter state is valid, cleanedValue - the new filter state without the disallowed values
 */
export const validateFilterState = (
  filterConfig: SearchFilterConfig[],
  availableValues: FilterAvailableValuesByLabel,
  filterState: FilterState,
): { valid: boolean; cleanedValue: FilterState } => {
  let valid = true;

  const validState: FilterState = {};

  const allowedLabels = new Set(filterConfig.map(f => f.label));

  // check if in filter state there are filter labels not present in the config and thus not allowed
  if (Object.keys(filterState).some(label => !allowedLabels.has(label)))
    valid = false;

  filterConfig.forEach(f => {
    const filterValues = availableValues[f.label];
    const validator = TYPE_VALIDATORS[f.type];

    let validValue = filterState[f.label];

    if (validator) {
      // if there is a validator for this type of filter validate against it
      const validated = validator(filterValues, filterState[f.label], f);
      validValue = validated.cleanedValue;
      valid = valid && validated.valid;
    }

    validState[f.label] = validValue;
  });

  return { valid, cleanedValue: validState };
};
