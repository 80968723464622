import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/DomainType.v2alpha1.schema.json';

declare interface DomainTypeEntity extends Entity {
  apiVersion: 'witboost.com/v2';
  kind: 'DomainType';
  metadata: EntityMeta;
  spec?: {
    abstract: boolean;
    linkedToGroup?: string;
    partOfDomain?: string;
    extends?: string;
  };
}

export type { DomainTypeEntity as DomainType };

export const domainTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
