import {
  coreServices,
  createServiceFactory,
  createServiceRef,
} from '@backstage/backend-plugin-api';
import { CatalogApiExtension } from '../utils/types';
import { CatalogClientExtension } from '../utils/CatalogClientExtension';

export const extendedCatalogServiceRef = createServiceRef<CatalogApiExtension>({
  id: 'catalog-extended-client', // this service is a global service, so the owner is root
});

export const extendedCatalogServiceFactory = createServiceFactory({
  service: extendedCatalogServiceRef,
  deps: { discovery: coreServices.discovery },
  factory({ discovery }) {
    return new CatalogClientExtension({ discoveryApi: discovery });
  },
});
