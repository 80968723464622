import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

export const actionHandlerApiRef = createApiRef<ActionHandlerApi>({
  id: 'wb-actionhandler',
});

export interface ActionHandlerApi {
  startAccessRequestAction(
    request: Record<string, any>,
    options?: { token?: string },
  ): Promise<Response>;
}

export class ActionHandlerClient implements ActionHandlerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  async startAccessRequestAction(
    request: Record<string, any>,
    options?: { token?: string | undefined } | undefined,
  ): Promise<Response> {
    return this.startAction('accessRequest', request, options?.token);
  }

  private async startAction(
    actionName: string,
    request: Record<string, any>,
    token?: string,
  ): Promise<Response> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'actionHandler',
    )}/${actionName}/start`;

    return this.fetchApi.fetch(url, {
      method: 'POST',
      headers: token
        ? {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          }
        : { Accept: 'application/json', 'Content-Type': 'application/json' },
      body: JSON.stringify(request),
    });
  }
}
