import React from 'react';
import { CustomViewRegister } from '../CustomView/registry';
import { DataPathContextProvider } from '../../context';

CustomViewRegister.register({
  id: 'new_root',
  function: function NewRoot(props: Record<string, any>) {
    return (
      <DataPathContextProvider path={props.path}>
        {props.children}
      </DataPathContextProvider>
    );
  },
});
