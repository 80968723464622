import React from 'react';
import { useTheme } from '@material-ui/core';
import {
  KoBugIcon,
  NotStartedBugIcon,
  OkBugIcon,
  ProgressBugIcon,
  WarningBugIcon,
} from '../ProvisioningCard/BugIcons';

export function useBugIcons(): Record<string, JSX.Element> {
  const theme = useTheme();

  return {
    RUNNING: <ProgressBugIcon style={{ color: theme.palette.accent.main }} />,
    WAITING: <ProgressBugIcon style={{ color: theme.palette.accent.main }} />,
    COMPLETED: <OkBugIcon style={{ color: theme.palette.success.main }} />,
    FAILED: <KoBugIcon style={{ color: theme.palette.error.main }} />,
    TERMINATED: <KoBugIcon style={{ color: theme.palette.error.main }} />,
    NOT_STARTED: (
      <NotStartedBugIcon style={{ color: theme.palette.grey[500] }} />
    ),
    NOT_BLOCKING_ERROR: (
      <WarningBugIcon
        style={{
          color: theme.palette.warning.main,
        }}
      />
    ),
  };
}
