import {
  EntityLayout,
  EntityNewVersionDialog,
} from '@agilelab/plugin-wb-builder-catalog';
import { builderDpNewVersion } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useAsyncEntity } from '@backstage/plugin-catalog-react';
import { usePermission } from '@backstage/plugin-permission-react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React, { ReactNode, useMemo, useState } from 'react';

export const EntityLayoutWrapper = (props: {
  children?: ReactNode;
  isWrappingProject?: boolean;
  version?: string;
}) => {
  const { isWrappingProject, version } = props;
  const [newVersionDialogOpen, setNewVersionDialogOpen] = useState(false);
  const { entity } = useAsyncEntity();

  const { allowed: canCreateNewVersion } = usePermission({
    permission: builderDpNewVersion,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata?.name ?? '',
    }),
  });

  const extraMenuItems = useMemo(() => {
    return [
      ...(isWrappingProject
        ? [
            {
              title: 'New Version',
              Icon: FileCopyIcon,
              onClick: () => setNewVersionDialogOpen(true),
              disabled: !canCreateNewVersion,
            },
          ]
        : []),
    ];
  }, [isWrappingProject, canCreateNewVersion]);

  return (
    <>
      <EntityLayout
        UNSTABLE_extraContextMenuItems={extraMenuItems}
        version={version}
      >
        {props.children}
      </EntityLayout>
      {isWrappingProject && (
        <EntityNewVersionDialog
          open={newVersionDialogOpen}
          onClose={() => setNewVersionDialogOpen(false)}
        />
      )}
    </>
  );
};
