import { useEntitiesMembership } from '@agilelab/plugin-wb-platform';
import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { EntityRefBasePicker } from '../EntityRefPicker/EntityRefBasePicker';
import { extractCustomProperties, isHidden } from '../../utils';

const ALLOWED_KINDS = ['user', 'group'];

function validateUiOptions(
  uiSchema: WmCompleteUiSchema<IdentitiesPickerUiOptions>,
): Error | undefined {
  if (
    uiSchema['ui:options']?.allowedKinds !== undefined &&
    !uiSchema['ui:options']?.allowedKinds?.every(
      val => val.toLowerCase() === 'group' || val.toLowerCase() === 'user',
    )
  ) {
    return new Error(
      `This field has been disabled due to a misconfiguration. Please contact the platform team. Option 'allowedKinds' can assume either 'user' or 'group'`,
    );
  }

  if (
    uiSchema['ui:options']?.showOnlyUserMemberGroups !== undefined &&
    !(typeof uiSchema['ui:options']?.showOnlyUserMemberGroups === 'boolean')
  ) {
    return new Error(
      'This field has been disabled due to a misconfiguration. Please contact the platform team. Option `showOnlyUserMember` must be either `true` or `false`.',
    );
  }

  if (
    uiSchema['ui:options']?.maxIdentities !== undefined &&
    !(typeof uiSchema['ui:options']?.maxIdentities === 'number')
  ) {
    return new Error(
      'This field has been disabled due to a misconfiguration. Please contact the platform team. Option `maxIdentities` must be a number.',
    );
  }

  return undefined;
}

const allowedKinds = (
  uiSchema: WmCompleteUiSchema<IdentitiesPickerUiOptions>,
): string[] =>
  uiSchema['ui:options']?.allowedKinds?.map(kind => kind.toLowerCase()) ??
  ALLOWED_KINDS;

const showOnlyUserMemberGroups = (
  uiSchema: WmCompleteUiSchema<IdentitiesPickerUiOptions>,
): boolean => uiSchema['ui:options']?.showOnlyUserMemberGroups ?? false;

const fromEntityToString = (entity: Entity) =>
  `${entity.kind.toLowerCase()}:${entity.metadata.name}`;
interface IdentitiesPickerUiOptions {
  maxIdentities?: number;
  allowedKinds?: string[];
  showOnlyUserMemberGroups?: boolean;
}

export const IdentitiesPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    schema,
    uiSchema,
    formContext,
    onChange,
    required,
    disabled,
    title,
    idSchema,
    placeholder,
  } = props;

  const catalogApi = useApi(catalogApiRef);
  const [configError, setConfigError] = useState<Error | undefined>(undefined);
  const customProps = extractCustomProperties(uiSchema);

  const { getMembersIdentities } = useEntitiesMembership();

  useEffect(() => {
    const error = validateUiOptions(uiSchema);
    setConfigError(error);
  }, [uiSchema]);

  const { value: identities } = useAsync(async () => {
    const filter = { kind: allowedKinds(uiSchema) };
    if (showOnlyUserMemberGroups(uiSchema)) {
      return (await getMembersIdentities({ filter })).map(fromEntityToString);
    }

    return (await catalogApi.getEntities({ filter })).items.map(
      fromEntityToString,
    );
  }, [catalogApi]);

  return (
    <EntityRefBasePicker
      options={identities ?? []}
      hidden={isHidden(uiSchema)}
      prefillValues={formContext[props.name] ?? []}
      onChange={onChange}
      maxItems={uiSchema['ui:options'].maxIdentities}
      required={required}
      disabled={disabled || (configError !== undefined ? true : false)}
      title={title ?? ''}
      helperText={schema.description ?? 'Select the identities'}
      error={configError ? true : false}
      additionalErrors={configError ? [configError.message] : undefined}
      placeholder={placeholder}
      fieldId={idSchema.$id}
      customProps={customProps}
    />
  );
};
