import {
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { ClassNameMap } from '@material-ui/styles';
import React from 'react';
import { LayoutTemplate, createScaffolderLayout } from '../../../layouts';
import { scaffolderPlugin } from '../../../plugin';

const useStyles = makeStyles(theme => ({
  tableCell: {
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& div:first-child': {
      minHeight: 'auto',
    },
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
  },
  firstCell: {
    background: theme.palette.white,
    zIndex: 1,
    position: 'sticky',
    left: 0,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    '& div:first-child': {
      minHeight: 'auto',
    },
    border: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: 'none',
    borderTop: 'none',
  },
  tableRow: {
    background: theme.palette.white,
    '& .PrivateSwitchBase-root': {
      padding: 0,
    },
  },
}));

function getTableRowCell(
  element: {
    content: React.ReactElement;
    name: string;
    disabled: boolean;
    readonly: boolean;
    hidden: boolean;
  },
  theme: Theme,
  classes: ClassNameMap<any>,
  index: number,
) {
  const errors: string[] =
    (!('0' in element.content.props?.errorSchema)
      ? element.content.props?.errorSchema?.__errors
      : element.content.props?.errorSchema?.['0']?.__errors) ?? [];
  const duplicateElement = element.content;
  if (errors) duplicateElement.props.errorSchema.__errors = errors;
  const cloned = React.cloneElement(duplicateElement, {
    schema: {
      ...element.content.props.schema,
      description: '',
      title: '',
    },
    uiSchema: {
      ...element.content.props.uiSchema,
      'ui:style': {
        borderRadius: theme.spacing(1),
        width: '100%',
        height: '32px',
        minWidth: 200,
        minHeight: 'auto',
        ...element.content.props?.uiSchema?.['ui:style'],
      },
      'ui:popper': 'wide',
    },
  });
  return (
    <TableCell
      key={element.content.key}
      className={index === 0 ? classes.firstCell : classes.tableCell}
    >
      {errors.length ? (
        <Tooltip title={errors.join('\n')}>
          <div style={{ width: '100%', height: '100%' }}>{cloned}</div>
        </Tooltip>
      ) : (
        cloned
      )}
    </TableCell>
  );
}

export const TableRowTemplate: LayoutTemplate = props => {
  const theme = useTheme();
  const classes = useStyles();
  const headerProperties: string[] = (props.schema as any)?.columnHeaders
    ? Array.from((props.schema as any)?.columnHeaders?.keys())
    : [];
  const tableRowCells = headerProperties.map((headerProperty, index) => {
    const cell = props.properties.find(
      p => p.content.props.name === headerProperty,
    );
    if (cell) {
      return getTableRowCell(cell, theme, classes, index);
    }
    return <TableCell key={headerProperty} />;
  });
  return (
    <TableRow className={classes.tableRow}>
      {tableRowCells}
      {(props.schema as any).RowButtons}
    </TableRow>
  );
};

export const TableRowTemplateLayout = scaffolderPlugin.provide(
  createScaffolderLayout({
    name: 'TableRowTemplate',
    component: TableRowTemplate,
  }),
);
