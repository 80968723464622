import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  capitalize,
  useTheme,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {
  ACLRequest,
  RequestStatusButton,
  TableCellProps,
  WbTable,
} from '@agilelab/plugin-wb-platform';
import { DateTime } from 'luxon';

interface RequestsDetailsModalProps {
  displayName: string;
  open: boolean;
  onClose: () => void;
  requests: Array<ACLRequest>;
}

const cols: TableCellProps<ACLRequest>[] = [
  {
    field: 'createdBy',
    headerName: 'Requester',
    cellProps: {
      width: 'auto',
    },
    fieldRender: row => row.requesterDisplayName,
  },
  {
    field: 'createdAt',
    headerName: 'Created at',
    cellProps: {
      width: 'auto',
    },
    fieldRender: row => {
      return DateTime.fromISO(row.createdAt as string).toFormat(
        'dd/MM/yyyy, HH:mm',
      );
    },
  },
  {
    field: 'verb',
    headerName: 'Verb',
    cellProps: {
      width: 'auto',
    },
    fieldRender: row => {
      return capitalize(row.verb ?? '');
    },
  },
  {
    field: 'status',
    headerName: 'Request Status',
    cellProps: {
      width: 'auto',
    },
    fieldRender: row => {
      return <RequestStatusButton status={row.status} />;
    },
  },
];

export const RequestsDetailsModal: React.FC<RequestsDetailsModalProps> = ({
  open,
  onClose,
  requests,
  displayName,
}) => {
  const theme = useTheme();
  const [columns, setColumns] = useState<TableCellProps<ACLRequest>[]>([]);

  useEffect(() => {
    if (requests.some(r => r.ref)) {
      setColumns([
        ...cols,
        {
          field: 'ref',
          headerName: 'Ref',
          cellProps: {
            width: 'auto',
          },
          fieldRender: row => {
            return <Typography>{row.ref ?? ''}</Typography>;
          },
        },
      ]);
    }
  }, [requests]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          borderRadius: 1,
          backgroundColor: theme.palette.background.default,
        },
      }}
      open={open}
      onClose={onClose}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>
        <Typography>Requests for {displayName}</Typography>
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 16,
            top: 16,
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 15 }}>
          <WbTable<ACLRequest>
            components={{
              tableContent: {
                columns: columns,
                rows: requests,
              },
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
