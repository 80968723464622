import React from 'react';
import { CustomLogoProps, LogoDirection } from './types';
import { useApi, configApiRef } from '@backstage/core-plugin-api';
import { Box } from '@material-ui/core';

import poweredByHorizontal from './powered-by-witboost_horiz.svg';
import poweredByVertical from './powered-by-witboost_vert.svg';

interface Props {
  customLogo: CustomLogoProps;
}

const RowLogo: React.FC<Props> = ({ customLogo }) => {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        height: '100%',
      }}
    >
      <img
        style={{ height: 'auto', width: 'auto', maxWidth: '150px' }}
        src={customLogo.url}
        alt="custom logo"
      />
      <img
        style={{ height: '100%', width: 'auto' }}
        src={poweredByVertical}
        alt="powered by witboost"
      />
    </Box>
  );
};

const ColumnLogo: React.FC<Props> = ({ customLogo }) => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '4px',
        height: '100%',
      }}
    >
      <img
        style={{ maxHeight: '70%' }}
        src={customLogo.url}
        alt="custom logo"
      />
      <img
        style={{ maxHeight: '30%' }}
        src={poweredByHorizontal}
        alt="powered by witboost"
      />
    </Box>
  );
};

const AutoLogo: React.FC<Props> = ({ customLogo }) => {
  const ratio = Math.round(customLogo.width / customLogo.height);

  return ratio > 2 ? (
    <ColumnLogo customLogo={customLogo} />
  ) : (
    <RowLogo customLogo={customLogo} />
  );
};

const logoByDirection: Record<
  LogoDirection,
  (customLogo: CustomLogoProps) => JSX.Element
> = {
  row: customLogo => <RowLogo customLogo={customLogo} />,
  column: customLogo => <ColumnLogo customLogo={customLogo} />,
  auto: customLogo => <AutoLogo customLogo={customLogo} />,
};

export const CustomLogo: React.FC<Props> = ({ customLogo }) => {
  const configApi = useApi(configApiRef);

  const logoDirection: LogoDirection =
    (configApi.getOptionalString('mesh.logo.direction') as LogoDirection) ||
    'auto';

  return logoByDirection[logoDirection](customLogo);
};
