import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { WbTable } from '@agilelab/plugin-wb-platform';
import { useColumns } from './useColumns';
import { InferredComplianceValidation } from '@agilelab/plugin-wb-governance-common';
import clsx from 'clsx';

type DataContractStatusTableProps = {
  validations: InferredComplianceValidation[];
  expanded?: string[];
  onExpandChange?: (newExpanded: string[]) => void;
};

const useStyles = makeStyles(
  theme => ({
    row: {
      '& td': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    compliantRow: {
      color: theme.palette.secondary.main,
    },
    nonCompliantRow: {
      backgroundColor: theme.palette.errorBackground,
      '& td': {
        color: theme.palette.black,
      },
    },
  }),
  { name: 'DataContractStatusTable' },
);

export const DataContractStatusTable: React.FC<
  DataContractStatusTableProps
> = ({
  validations,
  expanded: expandedParent,
  onExpandChange: onExpandChangeOuter,
}) => {
  const classes = useStyles();

  const columns = useColumns();

  const [expandedInner, setExpandedInner] = useState<string[]>([]);

  const getRowId = (r: InferredComplianceValidation) => r.key;

  const getRowClass = (r: InferredComplianceValidation) =>
    clsx(
      classes.row,

      r.compliant === undefined || r.compliant
        ? classes.compliantRow
        : classes.nonCompliantRow,
    );

  const expanded = expandedParent ?? expandedInner;

  const setExpanded = onExpandChangeOuter ?? setExpandedInner;

  return (
    <WbTable
      styles={{
        container: {
          overflow: 'auto',
        },
      }}
      components={{
        tableContent: {
          treeProps: {
            expandedRowsIds: expanded,
            getRowChildren: r => r.children ?? [],
            onExpandChange: newExpanded => setExpanded(newExpanded),
          },
          rowDynamicClassName: getRowClass,
          getRowId: getRowId,
          columns: columns,
          rows: validations,
          disableRowHighlightOnHover: true,
        },
      }}
    />
  );
};
