import { gql } from '@apollo/client';
import { GOVERNANCE_RESULT_FRAGMENT } from '../../../fragments/governance-result';
import { GOVERNANCE_POLICY_FRAGMENT } from '../../../fragments/governance-policy';
import { GOVERNANCE_RESOURCE_FRAGMENT } from '../../../fragments/governance-resource';

export const GET_EVALUATION_REPORT_BY_UUID = gql`
  query GET_EVALUATION_REPORT_BY_UUID($uuid: String) {
    cgp_evaluation_report(where: { uuid: { _eq: $uuid } }) {
      id
      status
      creation_time
      update_time
      scope
      evaluation_results {
        ${GOVERNANCE_RESULT_FRAGMENT}
        governance_entity {
          ${GOVERNANCE_POLICY_FRAGMENT}
        }
        resource {
          ${GOVERNANCE_RESOURCE_FRAGMENT}
        }
      }
      is_incremental_report
      report_error
    }
  }
`;
