import React, { ReactNode } from 'react';
import { Box, Typography, makeStyles } from '@material-ui/core';
import { WbCardActionButton } from '../../WbCardActionButton';

type FilterGroupProps = {
  style?: React.CSSProperties;
  label: ReactNode;
  hideShowMore?: boolean;
  onShowMore?: () => void;
  children: ReactNode;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
    },
    label: {
      fontWeight: 700,
      color: theme.palette.secondary.dark,
    },
    filters: {},
  }),
  { name: 'FilterGroup' },
);

export const FilterGroup = ({
  label,
  style,
  hideShowMore,
  onShowMore,
  children,
}: FilterGroupProps) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} style={style}>
      {typeof label === 'string' ? (
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      ) : (
        label
      )}
      {children}
      {onShowMore && !hideShowMore && (
        <WbCardActionButton
          variant="text"
          label="Show More"
          onClick={onShowMore}
        />
      )}
    </Box>
  );
};
