import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/EditTemplate.v1alpha1.schema.json';
import { JsonObject } from '@backstage/types';
import { TemplateStep } from './types';

export const EditTemplateFileName = 'edit-template.yaml';

declare interface EditTemplateEntity extends Entity {
  apiVersion: 'witboost.com/v1';
  kind: 'EditTemplate';
  metadata: EntityMeta;
  spec: {
    owner?: string;
    domain?: string;
    /**
     * witboost use case template references
     */
    useCaseTemplateRef?: string[];
    /**
     * Use Case Template Ids
     */
    useCaseTemplateId?: string[];
    /**
     * JSONSchemas which is used to render a form in the frontend
     * to collect user input and validate it against that schema.
     */
    parameters?: JsonObject[];
    steps: Array<TemplateStep>;
  };
}

export type { EditTemplateEntity as EditTemplate };

export const editTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
