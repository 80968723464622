import { isEqual } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

interface MultiSelectionDialogPickerContextType<T> {
  setSelectedValues: React.Dispatch<React.SetStateAction<T>>;
  contextSelected: T[];
  setContextSelected: React.Dispatch<React.SetStateAction<T[]>>;
  fetchAiBestMatch: () => Promise<T>;
  suggestedItems: T[];
  setSuggestedItems: React.Dispatch<React.SetStateAction<T[]>>;
  aiState: boolean;
  setAiState: React.Dispatch<React.SetStateAction<boolean>>;
  isAiGenerated: (value: T) => boolean;
}

export const GeneralPurposePickerContext = React.createContext<
  MultiSelectionDialogPickerContextType<any>
>({} as MultiSelectionDialogPickerContextType<any>);

export interface GeneralPurposePickerProps<T> {
  children?: React.ReactNode;
  selectedValues: T[];
  setSelectedValues: React.Dispatch<React.SetStateAction<T>>;
  fetchAIData?: () => Promise<T[]>;
}

export function GeneralPurposePickerContextProvider<T>({
  children,
  selectedValues,
  setSelectedValues,
  fetchAIData,
}: GeneralPurposePickerProps<T>) {
  const [suggestedItems, setSuggestedItems] = useState<T[]>([]);
  const [aiState, setAiState] = useState<boolean>(false);
  const [contextSelected, setContextSelected] = useState<T[]>(
    selectedValues ?? [],
  );
  const [bestMatchState, fetchAiBestMatch] = useAsyncFn(async () => {
    return fetchAIData?.();
  }, []);

  const isAiGenerated = useCallback(
    (value: T) => {
      return suggestedItems.some(i => isEqual(i, value));
    },
    [suggestedItems],
  );

  useEffect(() => {
    if (!aiState) {
      setSuggestedItems([]);
    }
  }, [aiState]);

  useEffect(() => {
    if (!bestMatchState.loading && bestMatchState.value) {
      setSuggestedItems(bestMatchState.value as T[]);
    }
  }, [bestMatchState.loading, bestMatchState.value]);

  return (
    <GeneralPurposePickerContext.Provider
      value={{
        contextSelected,
        setContextSelected,
        setSelectedValues,
        fetchAiBestMatch,
        suggestedItems,
        setSuggestedItems,
        aiState,
        setAiState,
        isAiGenerated,
      }}
    >
      {children}
    </GeneralPurposePickerContext.Provider>
  );
}

export const useMultiSelectionDialogPickerContext = () =>
  useContext(GeneralPurposePickerContext);
