import React, { useEffect, useMemo } from 'react';
import { Test } from '../types';
import {
  TableRow,
  TableCell,
  useTheme,
  CircularProgress,
  List,
  IconButton,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { TestResults } from './TestResults';
import { ErrorPanel } from '@backstage/core-components';
import {
  GOVERNANCE_ENTITIES,
  GovernanceEntitiesQueryType,
} from '../../../graphql/governanceEntities';
import { useLazyQuery } from '@apollo/client';
import { TaskListItem } from './TaskListItem/TaskListItem';
import { TestStatus, WittyIcon } from '@agilelab/plugin-wb-platform';
import { useTestResults } from './hooks/useTestResult';
import { useEditorPageContext } from '../context/useEditorPageContext';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

interface Props {
  test: Test;
}

export const TestCurrentRow: React.FC<Props> = ({ test }) => {
  const theme = useTheme();
  const configApi = useApi(configApiRef);
  const { setIsAssistantDrawerOpen, assistantState } = useEditorPageContext();
  const { policiesWithWarnings } = useTestResults(test);

  const hasAssistant = useMemo(
    () =>
      configApi.getOptionalBoolean('assistant.enabled') &&
      configApi.getOptionalString('assistant.url') &&
      Boolean(localStorage.getItem('assistant')),
    [configApi],
  );

  const [fetchPolicies, { loading, error, data }] =
    useLazyQuery<GovernanceEntitiesQueryType>(GOVERNANCE_ENTITIES, {
      variables: {
        ids: test.tasks
          .filter(t => !!t.governanceEntityId)
          .map(p => p.governanceEntityId),
      },
    });

  useEffect(() => {
    if (!data) {
      fetchPolicies();
    }
  }, [fetchPolicies, data]);

  return (
    <>
      <TableRow selected>
        <TableCell width="5%">
          <TestStatus
            status={test.status}
            okWithWarnings={!!policiesWithWarnings}
            variant="reduced"
          />
        </TableCell>
        <TableCell>
          {format(parseISO(test.startDate), 'yyyy/MM/dd HH:mm:ss')}
        </TableCell>
        <TableCell>
          <TestResults test={test} />
        </TableCell>

        {hasAssistant && (
          <TableCell>
            {assistantState.loading ? (
              <CircularProgress size={25} />
            ) : (
              <IconButton
                size="small"
                color="secondary"
                onClick={() => {
                  setIsAssistantDrawerOpen(true);
                }}
              >
                <WittyIcon />
              </IconButton>
            )}
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
          }}
          colSpan={5}
        >
          {loading && (
            <div>
              <CircularProgress />
            </div>
          )}
          {error && <ErrorPanel error={error} />}
          <List component="nav" aria-label="detail list">
            {!loading &&
              !error &&
              test.tasks.map((t, i) => (
                <TaskListItem
                  key={`${t.id}_${i}`}
                  task={t}
                  governanceEntities={data?.cgp_governance_entity || []}
                />
              ))}
          </List>
        </TableCell>
      </TableRow>
    </>
  );
};
