import {
  EvaluationResultSpecific,
  Policy,
} from '@agilelab/plugin-wb-governance-common';
import React from 'react';
import { GovernanceTestOverviewPolicySimple } from './GovernanceTestOverviewPolicySimple';
import { GovernanceTestOverviewPolicyTabs } from './GovernanceTestOverviewPolicyTabs';
import { GovernanceTestOverviewPolicyControlPanel } from './GovernanceTestOverviewPolicyControlPanel';

interface CommonProps {
  children?: React.ReactNode;
}

type ConditionalProps =
  | {
      kind: 'simple';
      policy: Policy;
      onUpdate: (code?: string) => void;
      editable: boolean;
      saving: boolean;
      handleSave: () => void;
      showSaveButton: boolean;
    }
  | {
      kind: 'tabs';
      result: EvaluationResultSpecific;
      policy: Policy;
    }
  | {
      kind: 'controlPanel';
      infoContent?: JSX.Element;
      policy: Policy;
      test: {
        errors?: string[];
        content: string;
        name: string;
      };
    };

type Props = CommonProps & ConditionalProps;

export const GovernanceTestOverviewPolicy: React.FC<Props> = props => {
  switch (props.kind) {
    case 'simple':
      return <GovernanceTestOverviewPolicySimple {...props} />;
    case 'tabs':
      return <GovernanceTestOverviewPolicyTabs {...props} />;
    case 'controlPanel':
      return <GovernanceTestOverviewPolicyControlPanel {...props} />;
    default:
      return <></>;
  }
};
