import { WbAutocomplete } from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { usePrevious } from '../../hooks/useEventStream';
import { DataProductField } from './DataProductField';
import { DomainField } from './DomainField';
import { OutputPortField } from './OutputPortField';
import { transformReadsFromIdToURN } from '../../../lib/id.helpers';
import { isHidden } from '../../utils';

const useStyle = makeStyles((theme: Theme) => ({
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
}));

export const ReadsFromPicker = ({
  onChange,
  required,
  rawErrors,
  formData,
  idSchema,
  uiSchema,
}: WmFieldExtensionComponentProps<string, any>) => {
  const catalogApi = useApi(catalogApiRef);
  const [radioButtonValue, setRadioButtonValue] = useState(
    formData && formData.includes('component') ? 'outputport' : 'resource',
  );
  const [domain, setDomain] = useState<string | undefined>(undefined);
  const [dataProduct, setDataProduct] = useState<string | undefined>(undefined);
  const [outputPort, setOutputPort] = useState<string | undefined>(formData);

  const prevDomain = usePrevious(domain);
  const prevDataProduct = usePrevious(dataProduct);

  const classes = useStyle();

  const { value: resources, loading } = useAsync(async () => {
    const entityResources = await catalogApi.getEntities({
      filter: { kind: ['Resource'] },
    });
    return entityResources;
  });

  useEffect(() => {
    if (radioButtonValue === 'outputport') {
      if (prevDomain !== domain) {
        setDataProduct(undefined);
        setOutputPort(undefined);
        onChange(undefined);
      } else if (prevDataProduct !== dataProduct) {
        setOutputPort(undefined);
        onChange(undefined);
      } else {
        onChange(outputPort);
      }
    }
  }, [
    onChange,
    domain,
    dataProduct,
    outputPort,
    prevDataProduct,
    prevDomain,
    radioButtonValue,
  ]);

  const entityRefs = resources?.items.map(
    e => `${transformReadsFromIdToURN(humanizeEntityRef(e))}`,
  );

  const onSelect = useCallback(
    (_: any, value: string | null) => {
      onChange(value);
    },
    [onChange],
  );

  return (
    <FormControl
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <RadioGroup
        value={radioButtonValue}
        className={classes.radioGroup}
        onChange={event => {
          setDomain(undefined);
          setDataProduct(undefined);
          setOutputPort(undefined);
          onChange(undefined);
          setRadioButtonValue(event.target.value);
        }}
      >
        <FormControlLabel
          value="resource"
          control={<Radio />}
          label="Resource"
        />
        <FormControlLabel
          value="outputport"
          control={<Radio />}
          label="Output Port"
        />
      </RadioGroup>
      {radioButtonValue === 'outputport' && (
        <Grid container>
          <Grid item xs={3}>
            <DomainField
              idSchema={idSchema.$id}
              domain={domain}
              setDomain={setDomain}
            />
          </Grid>
          <Grid item xs={3}>
            <DataProductField
              idSchema={idSchema.$id}
              domain={domain}
              dataProduct={dataProduct}
              setDataProduct={setDataProduct}
            />
          </Grid>
          <Grid item xs={6}>
            <OutputPortField
              idSchema={idSchema.$id}
              dataProduct={dataProduct}
              outputPort={outputPort}
              setOutputPort={setOutputPort}
              formData={formData}
              required={required}
            />
          </Grid>
        </Grid>
      )}
      {radioButtonValue === 'resource' && (
        <WbAutocomplete
          id={idSchema?.$id}
          value={formData as string}
          loading={loading}
          onChange={onSelect}
          options={entityRefs || []}
          autoSelect
          freeSolo={false}
          required={required}
          label="Resource"
          helperText="The resource you want to add to this Workload"
        />
      )}
    </FormControl>
  );
};
