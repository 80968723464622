import {
  Box,
  Theme,
  Tooltip,
  Typography,
  TypographyProps,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

export interface WbCardProps {
  children?: React.ReactNode;
  title: ReactNode;
  icon?: JSX.Element;
  actions?: JSX.Element;
  footer?: JSX.Element;
  leading?: JSX.Element;
  toolsComponents?: JSX.Element;
  statusComponent?: JSX.Element;
  cardClassName?: string;
  cardStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  headerContentStyle?: React.CSSProperties;
  footerStyle?: React.CSSProperties;
  toolsStyle?: React.CSSProperties;
  toolsBarStyle?: React.CSSProperties;
  typographyProps?: TypographyProps;
  fillContainer?: boolean;
  titleContainerStyle?: React.CSSProperties;
}

type UseStylesProps = {
  hasTools: boolean;
  fillContainer: boolean;
};

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  card: ({ fillContainer }) => ({
    boxShadow: '0px 2px 5px #00000029',
    width: '100%',
    display: 'flex',
    height: fillContainer ? '100%' : undefined,
    flexDirection: 'column',
    background: theme.palette.background.default,
  }),
  header: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    background: theme.palette.bkg.primary,
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  footer: {
    background: theme.palette.bkg.primary,
  },
  headerContent: {
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    width: '100%',
    height: '48px',
  },
  headerTitleContent: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flex: '1 1 0',
    overflow: 'hidden',
  },
  bar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: `1px solid ${theme.palette.divider}`,
    borderLeft: 'none',
    borderRight: 'none',
  },
  toolsBar: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
  },
  statusBar: ({ hasTools }) => ({
    height: '48px',
    width: '30%',
    borderLeft: hasTools ? 'none' : `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
  }),
}));

export const WbCard: React.FC<WbCardProps> = ({
  title,
  icon,
  actions,
  leading,
  toolsComponents,
  statusComponent,
  cardClassName,
  children,
  cardStyle,
  headerStyle,
  headerContentStyle,
  footerStyle,
  toolsStyle,
  toolsBarStyle,
  typographyProps,
  footer,
  titleContainerStyle,
  fillContainer,
}) => {
  const classes = useStyles({
    hasTools: Boolean(toolsComponents),
    fillContainer: Boolean(fillContainer),
  });

  return (
    <Box className={clsx(classes.card, cardClassName)} style={cardStyle}>
      <Box className={classes.header} style={headerStyle}>
        {leading}
        <Box className={classes.headerContent} style={headerContentStyle}>
          <Box
            className={classes.headerTitleContent}
            style={titleContainerStyle}
          >
            {typeof title === 'string' ? (
              <Tooltip title={title}>
                <Typography
                  color="primary"
                  variant="h6"
                  noWrap
                  style={{ fontWeight: 500 }}
                  {...typographyProps}
                >
                  {title}
                </Typography>
              </Tooltip>
            ) : (
              title
            )}
            {icon}
          </Box>
          {actions}
        </Box>
      </Box>
      {!!toolsComponents || !!statusComponent ? (
        <Box className={classes.bar} style={toolsStyle}>
          {!!toolsComponents ? (
            <Box className={classes.toolsBar} style={toolsBarStyle}>
              {toolsComponents}
            </Box>
          ) : (
            <Box width="100%" />
          )}
          {!!statusComponent && (
            <Box className={classes.statusBar}>{statusComponent}</Box>
          )}
        </Box>
      ) : (
        <></>
      )}
      {children}
      <Box className={classes.footer} style={footerStyle}>
        {footer}
      </Box>
    </Box>
  );
};
