import React, { useMemo } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { useLocation, useNavigate } from 'react-router-dom';
import SettingsIcon from '@material-ui/icons/Settings';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      position: 'relative',
    },
    indicator: {
      position: 'absolute',
      bottom: '-2px',
      left: 0,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
      height: theme.spacing(0.5),
    },
  }),
);
export function PlatformSettingsIcon() {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  const active = useMemo(() => {
    return location.pathname.split('/')?.[1] === 'platform-settings';
  }, [location.pathname]);

  return (
    <div className={classes.icon}>
      <IconButton
        aria-label="platform settings"
        onClick={() => {
          navigate('/platform-settings');
        }}
      >
        <SettingsIcon
          fontSize="large"
          color={active ? 'primary' : 'inherit'}
          style={{
            color: active
              ? theme.palette.primary.main
              : theme.palette.secondary.main,
          }}
        />
      </IconButton>
      {active && <div className={classes.indicator} />}
    </div>
  );
}
