export const GOVERNANCE_RESOURCE_FRAGMENT = `
id
external_id
origin_id
environment {
    id
    name
}
resource_type {
  name
  uuid
}
`;
