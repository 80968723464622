import { DiscoveryApi } from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import fetch from 'node-fetch';

import {
  CompletePermission,
  RoleEntity,
  RoleSubject,
  RoleSubjectEntity,
  RoleVisibility,
} from './model';

/**
 * An API interface for Server-to-Server communications towards RBAC-backend
 */
export interface ServerRbacApi {
  addRolesSubjects(
    rolesSubjects: RoleSubject[],
    options: {
      upsert?: boolean;
      serverToken: string;
    },
  ): Promise<Array<RoleSubjectEntity>>;

  getRoles(options: {
    serverToken: string;
    visibility: RoleVisibility;
  }): Promise<Array<RoleEntity>>;

  getPermissions(
    userToken: string,
    options?: {
      permissionId?: string;
    },
  ): Promise<Array<CompletePermission>>;
}

export class ServerRbacClient implements ServerRbacApi {
  constructor(private readonly discoveryApi: DiscoveryApi) {}

  async getPermissions(
    userToken: string,
    options?: { permissionId?: string | undefined },
  ): Promise<Array<CompletePermission>> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('rbac')}/permissions`;

    const queryString = new URLSearchParams();

    if (options && options.permissionId) {
      queryString.append('permissionId', options.permissionId);
    }

    const response = await fetch(`${baseUrl}?${queryString}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userToken}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return (await response.json()).userPermissions;
  }

  async getRoles(options: {
    serverToken: string;
    visibility?: 'user' | 'internal';
  }): Promise<RoleEntity[]> {
    const baseUrl = `${await this.discoveryApi.getBaseUrl('rbac')}/roles`;

    const queryString = new URLSearchParams();

    if (options && options.visibility) {
      queryString.append('visibility', options.visibility);
    }

    const response = await fetch(`${baseUrl}?${queryString}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${options.serverToken}`,
      },
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return (await response.json()).roles;
  }

  /**
   * Allows backend services to register roles-subjects using node-fetch
   * @param rolesSubjects
   * @param options
   * @returns the new roles subjects entries or throws error in case of exceptions
   */
  addRolesSubjects = async (
    rolesSubjects: RoleSubject[],
    options: {
      upsert?: boolean;
      serverToken: string;
    },
  ): Promise<RoleSubjectEntity[]> => {
    const baseUrl = `${await this.discoveryApi.getBaseUrl(
      'rbac',
    )}/roles-subjects`;

    const requestBody = {
      rolesSubjects,
      options: {
        upsert: options.upsert,
      },
    };

    const response = await fetch(baseUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${options.serverToken}`,
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return (await response.json()).rolesSubjects;
  };
}
