import { SystemType } from '@agilelab/plugin-wb-builder-common';
import { practiceShaperApiRef } from '@agilelab/plugin-wb-practice-shaper';
import {
  GetTaxonomiesResponse,
  PracticeShaperApi,
} from '@agilelab/plugin-wb-practice-shaper-common';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type TaxonomyItem = GetTaxonomiesResponse['items'][number];

type Taxonomy = {
  selectedTaxonomyRef: string;
  selectableTaxonomies: TaxonomyItem[];
  setSelectedTaxonomyRef: (val: string) => void;
  systemTypes: SystemType[];
  shouldDisable: boolean;
};

export const DEFAULT_TAXONOMY_KEY = 'category';
export const ALL_TAXONOMIES_FILTER = 'all';

type Options = {
  queryParamKey?: string;
  localStorageKey?: string;
};

async function fetchTaxonomiesData(
  api: PracticeShaperApi,
): Promise<{ taxonomies: TaxonomyItem[]; systemTypes: SystemType[] }> {
  const taxonomies = await api.getTaxonomies({});
  const systemTypes = await api.getSystemTypes({});
  return {
    taxonomies: taxonomies?.items ?? [],
    systemTypes: systemTypes?.items ?? [],
  };
}

export const useTaxonomySelection = (options?: Options): Taxonomy => {
  const [queryParamKey, localStorageKey] = [
    options?.queryParamKey || DEFAULT_TAXONOMY_KEY,
    options?.localStorageKey || DEFAULT_TAXONOMY_KEY,
  ];

  const api = useApi(practiceShaperApiRef);

  const { data } = useQuery({
    queryKey: ['taxonomies'],
    queryFn: async () => {
      return fetchTaxonomiesData(api);
    },
  });

  const { taxonomies, systemTypes } = useMemo(() => {
    return data ? data : { taxonomies: [], systemTypes: [] };
  }, [data]);

  const validTaxonomies = useMemo(
    () => taxonomies.filter(t => t.spec.enabled),
    [taxonomies],
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const selected = useMemo(
    () => searchParams.get(queryParamKey),
    [searchParams, queryParamKey],
  );

  // sync the local storage with the query params when they change
  useEffect(() => {
    if (selected !== null) localStorage.setItem(localStorageKey, selected);
  }, [selected, localStorageKey]);

  // setter that sets the new taxonomy query param value by keeping the other query params unaltered
  const setSelectedTaxonomyRef = useCallback(
    (value: string) => {
      // get currents query params in the url as a js object
      const currentParams = {
        ...Object.fromEntries(new URLSearchParams(window.location.search)),
      };
      setSearchParams(
        // change only the taxonomy query param from the current params
        { ...currentParams, [queryParamKey]: value },
        // replace = true so it doesn't add a new navigation entry to the stack
        { replace: true },
      );
    },
    [setSearchParams, queryParamKey],
  );

  useEffect(() => {
    // if there is no value in query param but there is in local storage, set the local storage value as query param
    const localValue = localStorage.getItem(localStorageKey);
    if (selected === null && localValue !== null) {
      setSelectedTaxonomyRef(localValue);
      return;
    }

    // if there is only one available taxonomy and query param is not in the url or "all taxonomies" is selected, select that single taxonomy instead
    if (
      (selected === null || selected === ALL_TAXONOMIES_FILTER) &&
      validTaxonomies.length === 1
    ) {
      setSelectedTaxonomyRef(stringifyEntityRef(validTaxonomies[0]));
    }
  }, [selected, validTaxonomies, setSelectedTaxonomyRef, localStorageKey]);

  const selectedTaxonomy =
    selected ?? localStorage.getItem(localStorageKey) ?? ALL_TAXONOMIES_FILTER;

  const selectedSystemTypes =
    selectedTaxonomy === ALL_TAXONOMIES_FILTER
      ? systemTypes
      : systemTypes.filter(
          systemType => systemType.spec.belongsTo === selectedTaxonomy,
        );

  const isSelectedTaxonomyValid = validTaxonomies.some(
    t => stringifyEntityRef(t) === selectedTaxonomy,
  );

  // the selector should be disabled if there is only one available taxonomy,
  // but only if the currently selected one is already a valid one, so that if it isn't the user can choose the correct one
  const shouldDisable =
    !data || (validTaxonomies.length === 1 && isSelectedTaxonomyValid);

  return {
    selectableTaxonomies: validTaxonomies,
    selectedTaxonomyRef: selectedTaxonomy,
    setSelectedTaxonomyRef,
    systemTypes: selectedSystemTypes ?? [],
    shouldDisable,
  };
};
