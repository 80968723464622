import { Descriptor } from '@agilelab/plugin-wb-marketplace-common';
import { CustomView, useDialog } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { SemanticLinkingTable } from '../../ComponentPage/OverviewPage/SemanticLinkingTable';

export const DataContractPage = (props: {
  data: Record<string, any>;
  makeSpaceForDownloader?: boolean;
  actions?: Record<string, any>;
}) => {
  const data = props.data;
  const semanticLinkingDialog = useDialog({ title: 'Semantic Linking' });

  return (
    <CustomView
      id="marketplace_data_contract"
      typeId={data.descriptor.kind}
      templateId={data.descriptor.useCaseTemplateId}
      actions={{
        ...(props.actions || {}),
        showSemanticLink: async (field: any) => {
          await semanticLinkingDialog(
            <SemanticLinkingTable
              descriptor={data as Descriptor}
              schema={field}
            />,
          );
        },
      }}
      data={data}
    />
  );
};
