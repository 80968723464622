import { makeStyles } from '@material-ui/core';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { WbCardActionButton } from '../../../WbCardActionButton';
import { WbSearch } from '../../../WbSearch';
import { BaseCheckbox } from '../../BaseFilters/BaseCheckbox';
import { FilterListModal } from '../../FilterListModal';
import { FilterGroup } from '../FilterGroup';
import { BaseSidebarFilter, SidebarModalFilter } from '../types';

export type CheckboxValue = {
  value: string;
  label?: string;
  // to display next to the item label
  adornment?: ReactNode;
  disabled?: boolean;
};

type CheckboxFilterPropsGroup = {
  countToShow?: number;
  searchPlaceholder?: string;
  values: CheckboxValue[];
  selected: string[];
  disabled?: string[];
  onSelectionChange: (selected: string[]) => void;
  ignoreDisabled?: boolean;
  style?: React.CSSProperties;
  countToShowWithoutModal?: number;
} & BaseSidebarFilter &
  SidebarModalFilter;

const defaultOnSearch = (value: string, values: CheckboxValue[]) =>
  values.filter(o => new RegExp(value, 'ig').test(o.label ?? o.value));

const useStyles = makeStyles(
  () => ({
    root: {},
    checkboxContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'CheckboxFilterGroup' },
);

const sortOptions = (
  options: CheckboxValue[],
  checkedSet: Set<string>,
  isOptionDisabled: (option: CheckboxValue) => boolean,
) => {
  /**
   * the score is computed in a way that allows to achieve the following order:
   * selected elements (non disabled)
   * non disabled elements
   * selected disabled elements(shouldn't happen but it's handled anyway)
   * disabled elements
   */
  const computeSortScore = (option: CheckboxValue) => {
    let score = 0;
    if (!isOptionDisabled(option)) score += 2;
    if (checkedSet.has(option.value)) score += 1;
    return score;
  };

  return options.sort((a, b) => {
    return computeSortScore(b) - computeSortScore(a);
  });
};

export const CheckboxFilterGroup = ({
  countToShow = 5,
  countToShowWithoutModal = 10,
  label,
  values,
  selected,
  disabled,
  onSelectionChange,
  ignoreDisabled = false,
  style,
  searchPlaceholder,
  modalAnchorEl,
  modalStyle,
}: CheckboxFilterPropsGroup) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const [showMore, setShowMore] = useState(false);

  const [searchValue, setSearchValue] = React.useState('');
  const [modalSearch, setModalSearch] = useState('');

  const [modalChecked, setModalChecked] = React.useState<string[]>([]);

  const checkedSet = useMemo(() => new Set(selected), [selected]);

  const modalCheckedSet = useMemo(() => new Set(modalChecked), [modalChecked]);

  const disabledSet = useMemo(() => new Set(disabled ?? []), [disabled]);

  const defaultAnchorRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  const handleShowMore: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (values.length > countToShowWithoutModal) {
      setModalChecked([...selected]);
      setAnchorEl(modalAnchorEl ?? defaultAnchorRef.current);
      return;
    }
    setShowMore(old => !old);
  };

  const modalFilteredOptions = useMemo(
    () => defaultOnSearch(modalSearch, values),
    [values, modalSearch],
  );

  const isOptionDisabled = useCallback(
    (option: CheckboxValue) =>
      !ignoreDisabled && (option.disabled || disabledSet.has(option.value)),
    [disabledSet, ignoreDisabled],
  );

  const filteredOptions = useMemo(
    () =>
      sortOptions(
        defaultOnSearch(searchValue, values),
        checkedSet,
        isOptionDisabled,
      ),
    [values, searchValue, checkedSet, isOptionDisabled],
  );

  const onSelectionChangeInner = useCallback(
    (name: string, isChecked: boolean) => {
      const filtered = selected.filter(i => i !== name);

      onSelectionChange(isChecked ? [...selected, name] : filtered);
    },
    [selected, onSelectionChange],
  );

  const onSelectionChangeModal = useCallback(
    (name: string, isChecked: boolean) => {
      const filtered = modalChecked.filter(i => i !== name);

      setModalChecked(isChecked ? [...modalChecked, name] : filtered);
    },
    [modalChecked],
  );

  // if the number of values is under the treshold the search bar is hidden, so we need to also clear the search bar filter in case it was already set
  useEffect(() => {
    if (values.length <= countToShow) {
      setSearchValue('');
    }
  }, [values, countToShow]);

  return (
    <>
      <FilterGroup label={label}>
        {values.length > countToShow && (
          <WbSearch
            value={searchValue}
            onChange={setSearchValue}
            placeholder={searchPlaceholder}
          />
        )}

        {selected.length > 0 && (
          <WbCardActionButton
            variant="text"
            label="Clear All"
            onClick={() => onSelectionChange([])}
          />
        )}
        <div
          ref={defaultAnchorRef}
          className={classes.checkboxContainer}
          style={style}
        >
          {(filteredOptions.length > countToShow && !showMore
            ? filteredOptions.slice(0, countToShow)
            : filteredOptions
          ).map((option, index) => {
            return (
              <BaseCheckbox
                key={index}
                disabled={isOptionDisabled(option)}
                label={option.label ?? option.value}
                adornment={option.adornment}
                checked={checkedSet.has(option.value)}
                onChange={(_, isChecked) =>
                  onSelectionChangeInner(option.value, isChecked)
                }
              />
            );
          })}
          {filteredOptions.length > countToShow && (
            <WbCardActionButton
              variant="text"
              label={showMore ? 'Show Less' : 'Show More'}
              onClick={handleShowMore}
            />
          )}
        </div>
      </FilterGroup>
      <FilterListModal
        searchPlaceholder={searchPlaceholder}
        label={label}
        searchValue={modalSearch}
        onSearch={setModalSearch}
        onSelectAll={() => {
          setModalChecked(values.map(v => v.value));
        }}
        onClear={() => {
          setModalChecked([]);
        }}
        onApply={() => {
          setAnchorEl(null);
          onSelectionChange(modalChecked);
        }}
        style={modalStyle}
        PopoverProps={{
          open,
          anchorEl,
          onClose: () => setAnchorEl(null),
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        {modalFilteredOptions.map((option, index) => {
          return (
            <BaseCheckbox
              key={index}
              label={option.label ?? option.value}
              adornment={option.adornment}
              disabled={isOptionDisabled(option)}
              checked={modalCheckedSet.has(option.value)}
              onChange={(_, isChecked) =>
                onSelectionChangeModal(option.value, isChecked)
              }
            />
          );
        })}
      </FilterListModal>
    </>
  );
};
