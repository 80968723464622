import React, { createContext, ReactNode, useContext, useMemo } from 'react';

type FormExtraConfig = {
  debounceMs?: number;
};

const defaultFormExtraConfigContext = {};

export const FormExtraConfigContext = createContext<FormExtraConfig>(
  defaultFormExtraConfigContext,
);

export const useFormExtraConfigContext = () => {
  const contextValue = useContext(FormExtraConfigContext);

  return contextValue;
};

export const FormExtraConfigContextProvider = ({
  children,
  ...props
}: {
  children: ReactNode;
} & FormExtraConfig) => {
  const value = useMemo(() => ({ ...props }), [props]);

  return (
    <FormExtraConfigContext.Provider value={value}>
      {children}
    </FormExtraConfigContext.Provider>
  );
};
