/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { WebFileSystemAccess } from '../../lib/filesystem';

import PublishIcon from '@material-ui/icons/Publish';
import EditIcon from '@material-ui/icons/Edit';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  introText: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    fontWeight: 700,
  },
  card: {
    position: 'relative',
    maxWidth: 430,
    marginTop: theme.spacing(4),
    margin: theme.spacing(0, 2),
  },
  cardContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
  },
  infoIcon: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  actionButton: {
    width: '126px',
  },
}));

interface EditorIntroProps {
  style?: JSX.IntrinsicElements['div']['style'];
  onSelect?: (option: 'local' | 'form') => void;
}

export function TemplateEditorIntro(props: EditorIntroProps) {
  const classes = useStyles();
  const supportsLoad = WebFileSystemAccess.isSupported();
  const theme = useTheme();

  const cardLoadLocal = (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Typography
          variant="h6"
          gutterBottom
          color={supportsLoad ? undefined : 'textSecondary'}
          style={{ display: 'flex', flexFlow: 'row nowrap' }}
        >
          Load Template Directory
        </Typography>
        <PublishIcon
          htmlColor={theme.palette.primary.light}
          style={{ fontSize: '96px' }}
        />
        <Typography
          variant="body2"
          color={supportsLoad ? undefined : 'textSecondary'}
        >
          Load a local template directory, allowing you to both edit and try
          executing your own template.
        </Typography>

        <Button
          className={classes.actionButton}
          disabled={!supportsLoad}
          color="primary"
          variant="contained"
          onClick={() => props.onSelect?.('local')}
        >
          Load
        </Button>
      </CardContent>
      {!supportsLoad && (
        <div className={classes.infoIcon}>
          <Tooltip
            placement="top"
            title="Only supported in some Chromium-based browsers"
          >
            <InfoOutlinedIcon />
          </Tooltip>
        </div>
      )}
    </Card>
  );

  const cardFormEditor = (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Typography variant="h6" gutterBottom>
          Edit Template Form
        </Typography>
        <EditIcon
          htmlColor={theme.palette.primary.light}
          style={{ fontSize: '96px' }}
        />
        <Typography variant="body2">
          Preview and edit a template form, either using a sample template or by
          loading a template from the catalog.
        </Typography>
        <Button
          className={classes.actionButton}
          color="primary"
          variant="contained"
          onClick={() => props.onSelect?.('form')}
        >
          Edit
        </Button>
      </CardContent>
    </Card>
  );

  return (
    <div style={props.style}>
      <Typography variant="body1" className={classes.introText}>
        Get started by choosing one of the options below
      </Typography>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          alignContent: 'flex-start',
        }}
      >
        {supportsLoad && cardLoadLocal}
        {cardFormEditor}
        {!supportsLoad && cardLoadLocal}
      </div>
    </div>
  );
}
