import React, { ReactElement } from 'react';
import {
  CustomViewRegister,
  useAction,
  useDataPath,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Link } from '@backstage/core-components';
import {
  ScmIntegrationIcon,
  scmIntegrationsApiRef,
} from '@backstage/integration-react';
import { Box, IconButton } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';
import { useApi } from '@backstage/core-plugin-api';
import { getEntitySourceLocation } from '@backstage/plugin-catalog-react';
import { CatalogGraphCard } from '../CatalogGraphCard';
import { ComponentTypeSelectButton } from '../DataProductPageComponents';
import { EntityOrphanWarning } from '../EntityOrphanWarning';
import { EntityProcessingErrorsPanel } from '../EntityProcessingErrorsPanel';
import { ReservedFieldsError } from '../ReservedFieldsError';
import { HasComponentsCard } from '../HasComponentsCard';

CustomViewRegister.register({
  id: 'catalog_system_card',
  function: function AboutCardDp(props: Record<string, any>) {
    const allowRefresh = useDataPath('allowRefresh');
    const refreshAction = useAction('refreshEntity');
    const refreshEntity = async () => refreshAction(); // this makes typescript happy
    const entity = useDataPath('entity');
    const scmIntegrationsApi = useApi(scmIntegrationsApiRef);
    const entitySourceLocation = getEntitySourceLocation(
      entity,
      scmIntegrationsApi,
    );

    return (
      <WbCard
        cardStyle={{ height: '100%' }}
        title="About"
        actions={
          <Box style={{ display: 'flex' }}>
            <IconButton
              component={Link}
              aria-label="View Source"
              disabled={!entitySourceLocation}
              title="View Source"
              to={entitySourceLocation?.locationTargetUrl ?? '#'}
              size="small"
            >
              <ScmIntegrationIcon
                type={entitySourceLocation?.integrationType}
              />
            </IconButton>
            {allowRefresh && (
              <IconButton
                aria-label="Refresh"
                title="Schedule entity refresh"
                onClick={refreshEntity}
                size="small"
              >
                <CachedIcon />
              </IconButton>
            )}
          </Box>
        }
      >
        <WbCardContent>{props.children}</WbCardContent>
      </WbCard>
    );
  },
});

CustomViewRegister.register({
  id: 'catalog_warnings',
  function: function WarningsDp() {
    const warnings: ReactElement[] = [];
    const data = useDataPath('_errors');
    if (!data) return [];
    if (data.isOrphan) warnings.push(<EntityOrphanWarning />);
    if (data.hasCatalogProcessingErrors)
      warnings.push(<EntityProcessingErrorsPanel />);
    if (data.hasReservedFieldsError) warnings.push(<ReservedFieldsError />);

    return warnings;
  },
});

CustomViewRegister.register({
  id: 'catalog_relations',
  function: function Relations() {
    return <CatalogGraphCard />;
  },
});

CustomViewRegister.register({
  id: 'catalog_component_card',
  function: function ComponentCardView(props: Record<string, any>) {
    const entity = useDataPath('entity');
    return (
      <WbCard
        title={props.title || ''}
        actions={<ComponentTypeSelectButton system={entity} />}
      >
        <WbCardContent>{props.children}</WbCardContent>
      </WbCard>
    );
  },
});

CustomViewRegister.register({
  id: 'catalog_components',
  function: function ComponentsView() {
    return <HasComponentsCard />;
  },
});
