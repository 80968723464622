import { Box } from '@material-ui/core';
import React from 'react';
import { LaunchTestButton } from './LaunchTestButton';

export const TestCardActions: React.FC = () => {
  return (
    <Box display="flex" alignItems="center">
      <LaunchTestButton />
    </Box>
  );
};
