import {
  WbCard,
  WbCardActionButton,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { Grid, Tooltip, Typography, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { MigrationStepsWidget } from './MigrationStepsWidget';
import { DetectedResourceTypesWidget } from './DetectedResourceTypesWidget';
import { NewEntitiesWidget } from './NewEntitiesWidget';
import { CodeDrawer, CodeEntity } from './CodeDrawer';
import { TemplateUpdatesWidget } from './TemplateUpdatesWidget';
import CachedIcon from '@material-ui/icons/Cached';
import Priority from '@material-ui/icons/PriorityHigh';

import { Alert } from '@material-ui/lab';
import yaml from 'yaml';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';

const mainAlertContent = (
  <>
    Before starting with the migration make sure to align{' '}
    <code style={{ fontWeight: 'bold' }}>spec.type</code> in{' '}
    <code style={{ fontWeight: 'bold' }}>template.yaml</code> with{' '}
    <code style={{ fontWeight: 'bold' }}>spec.mesh.kind</code> in{' '}
    <code style={{ fontWeight: 'bold' }}>skeleton/catalog-info.yaml</code> (when
    this last property is defined). The value of{' '}
    <code style={{ fontWeight: 'bold' }}>spec.mesh.kind</code> is the one to be
    retained while <code style={{ fontWeight: 'bold' }}>spec.type</code> can be
    overwritten.
  </>
);

const useStyles = makeStyles(
  theme => ({
    root: {
      marginBottom: theme.spacing(2),
    },
  }),
  { name: 'PracticeShaperMigration' },
);

export const MigrationCard = (props: { importPageRef: string }) => {
  const [codeDrawerEntity, setCodeDrawerEntity] = useState<CodeEntity>();
  const alertApi = useApi(alertApiRef);

  const classes = useStyles();

  return (
    <>
      <WbCard
        title="Migration"
        cardClassName={classes.root}
        actions={
          <Tooltip title="Refresh ">
            <WbCardActionButton
              onClick={() => {
                location.reload();
              }}
              label="Refresh"
              icon={<CachedIcon />}
              disabled={false}
            />
          </Tooltip>
        }
      >
        <WbCardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Alert variant="outlined" icon={<Priority />} severity="warning">
                <Typography variant="body1">{mainAlertContent}</Typography>
              </Alert>
            </Grid>

            <Grid item xs={12} sm={4}>
              <MigrationStepsWidget />
            </Grid>
            <Grid container item xs={12} sm={8} spacing={3}>
              <Grid item xs={12}>
                <NewEntitiesWidget
                  importPageRef={props.importPageRef}
                  onEntitySelected={entity =>
                    setCodeDrawerEntity({
                      title: entity.name,
                      tag: entity.kind,
                      language: 'yaml',
                      fileName: 'catalog-info.yaml',
                      fileType: 'text/yaml',
                      fetchCode: async () => {
                        const { newContent } =
                          await entity.fetchCatalogInfoContent();
                        const yamlCode = yaml.stringify(newContent);
                        return {
                          code: yamlCode,
                        };
                      },
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <DetectedResourceTypesWidget />
              </Grid>
              <Grid item xs={12}>
                <TemplateUpdatesWidget
                  onViewUpdate={entity =>
                    setCodeDrawerEntity({
                      title: entity.name,
                      tag: entity.kind,
                      language: 'yaml',
                      fileName: 'catalog-info.yaml',
                      fileType: 'text/yaml',
                      entity: entity.originalEntity,
                      fetchCode: async () => {
                        const { newContent, previousContent, repositoryFile } =
                          await entity.fetchCatalogInfoContent();
                        const yamlCode = yaml.stringify(newContent);
                        const previousContentYaml = previousContent
                          ? yaml.stringify(previousContent)
                          : undefined;
                        return {
                          code: yamlCode,
                          previousCode: previousContentYaml,
                          repositoryFile,
                        };
                      },
                    })
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        </WbCardContent>
      </WbCard>

      <CodeDrawer
        codeEntity={codeDrawerEntity}
        onClose={() => setCodeDrawerEntity(undefined)}
        onSuccessfullySave={() => {
          alertApi.post({
            message:
              'Entity successfully updated. Wait a few seconds before refreshing this page to see the results reflected',
            severity: 'success',
          });
          setCodeDrawerEntity(undefined);
        }}
      />
    </>
  );
};
