import { MenuItem, withStyles } from '@material-ui/core';

export const WbSelectInlineItem = withStyles(theme => ({
  root: {
    borderRadius: '4px',
    marginBlock: '1px',
    '&:hover': {
      background: theme.palette.bkg.primary,
    },
    '&:focus': {
      background: theme.palette.softened.primary,
      '&:hover': {
        background: theme.palette.softened.primary,
      },
    },
  },
  gutters: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
}))(MenuItem);
