import { useApi } from '@backstage/core-plugin-api';
import { scaffolderApiRef } from '../../api';
import useAsync from 'react-use/lib/useAsync';

export const useTemplateParameterSchema = (templateRef: string) => {
  const scaffolderApi = useApi(scaffolderApiRef);
  const { value, loading, error } = useAsync(() => {
    if (templateRef !== '') {
      return scaffolderApi.getTemplateParameterSchema(templateRef);
    }
    return Promise.resolve(null);
  }, [scaffolderApi, templateRef]);

  return {
    schema: value,
    loading: value === null || loading,
    error,
  };
};
