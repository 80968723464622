import { Box } from '@material-ui/core';
import React from 'react';
import { WbTable, WbTableProps } from '../WbTable';
import {
  TableFiltersContainer,
  TableFiltersContainerProps,
} from './TableFiltersContainer';

interface WbTableWithFiltersProps<T> {
  filters: TableFiltersContainerProps;
  filtersComponent?: JSX.Element;
  table: WbTableProps<T>;
  containerStyle?: React.CSSProperties;
}

export function WbTableWithFilters<T>({
  filters,
  filtersComponent,
  table,
  containerStyle,
}: WbTableWithFiltersProps<T>) {
  return (
    <Box width="100%" style={{ overflowY: 'auto', ...containerStyle }}>
      <TableFiltersContainer {...filters} />
      {filtersComponent}
      <WbTable<T> {...table} />
    </Box>
  );
}
