import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid } from '@material-ui/core';
import React from 'react';
import { AverageQuestionsKpiCard } from './AverageQuestionsKpiCard';
import { EnvAlignmentKpiCard } from './EnvAlignmentKpiCard';
import { NetworkEffectKpiCard } from './NetworkEffectKpiCard';
import { parseOptionalBooleanConfig } from './utils';

export const HistoricalDataCard = (props: { animationsDuration?: number }) => {
  const configApi = useApi(configApiRef);
  const { animationsDuration } = props;

  const enabledEnvAlignmentKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.envAlignmentKpiCard.enabled',
    ),
  );
  const enabledNetworkEffectKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.networkEffectKpiCard.enabled',
    ),
  );
  const enabledAverageQuestionsKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.averageQuestionsKpiCard.enabled',
    ),
  );

  if (
    !enabledEnvAlignmentKpiCard &&
    !enabledNetworkEffectKpiCard &&
    !enabledAverageQuestionsKpiCard
  )
    return <></>;

  return (
    <Grid container direction="column" spacing={3}>
      {enabledEnvAlignmentKpiCard && (
        <Grid item md={12} xs={12}>
          <EnvAlignmentKpiCard animationsDuration={animationsDuration} />
        </Grid>
      )}
      {enabledNetworkEffectKpiCard && (
        <Grid item md={12} xs={12}>
          <NetworkEffectKpiCard animationsDuration={animationsDuration} />
        </Grid>
      )}
      {enabledAverageQuestionsKpiCard && (
        <Grid item md={12} xs={12}>
          <AverageQuestionsKpiCard animationsDuration={animationsDuration} />
        </Grid>
      )}
    </Grid>
  );
};
