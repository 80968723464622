import { Tooltip } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import GroupsIcon from '@material-ui/icons/Group';
import React, { useState } from 'react';
import {
  TableCellProps,
  WbTruncatedTypographyWrapper,
  WbCard,
  WbCardContent,
  WbTableWithFilters,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { PermissionSettingsView } from '@agilelab/plugin-wb-rbac-common';
import { usePermissionContext } from '../Context/PermissionContext';
import { DetailDrawer } from '../DetailDrawer';
import { RolesDrawerTable } from '../RolesTable/RolesDrawerTable';
import { PermissionsDetailDrawer } from './PermissionsDrawerHeader';
import { RoleDrawerContextProvider } from '../Context/RoleDrawerTableContext';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useTableColumnSpecs = (): Array<
  TableCellProps<PermissionSettingsView>
> => {
  const truncatedClass = useTruncatedStyles();
  return [
    {
      field: 'subject_kind',
      fieldRender: (row: PermissionSettingsView) =>
        row.subject_kind.includes('Group') ? <GroupsIcon /> : <PersonIcon />,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '1%',
        style: { wordBreak: 'break-word', paddingRight: 0 },
      },
      headerName: '',
    },
    {
      field: 'subjectdisplayname',
      fieldRender: (row: PermissionSettingsView) =>
        row.subjectdisplayname.length > 1
          ? `${row.subjectdisplayname.length} Subjects`
          : row.subjectdisplayname[0],
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word', paddingLeft: 10 },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Subject',
      sortable: true,
    },
    {
      field: 'display_name',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Permission',
      sortable: true,
    },
    {
      field: 'description',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Description',
      sortable: true,
    },
    {
      field: 'entity_ref_displayname',
      fieldRender: (row: PermissionSettingsView) => {
        return (
          <Tooltip
            title={
              row.warning
                ? 'Entity has not been found in the catalog. This permission has no effect.'
                : row.transformed_entity_ref
            }
          >
            <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
              <WbTruncatedTypographyWrapper
                value={row.transformed_entity_ref}
              />
              {row.warning && <ErrorOutlineIcon />}
            </div>
          </Tooltip>
        );
      },
      cellProps: {
        size: 'small',
        align: 'left',
        width: '20%',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'Scope',
      sortable: true,
    },
    {
      field: 'count',
      tooltip: true,
      cellProps: {
        size: 'small',
        align: 'left',
        width: '15%',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'Roles',
    },
  ];
};

export const PermissionsTable: React.FC = () => {
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);

  const [detailsDrawers, setDetailsDrawers] = useState<{
    permission: string;
    subject: string[];
    target: string;
  }>();

  const [permissionDetailsDrawers, setPermissionDetailsDrawers] = useState<{
    subject: string[];
    permission: string;
    permissionDescription: string;
    target: string;
  }>();

  const {
    loading,
    permissions,
    setPagination,
    pagination,
    permissionsCount,
    filters,
    changeFilters,
    dispatchOrder,
  } = usePermissionContext();

  return (
    <>
      <WbTableWithFilters<PermissionSettingsView>
        containerStyle={{
          display: 'flex',
          flexDirection: 'column',
          gap: 5,
        }}
        filters={{
          searchValue: filters.text ?? '',
          onSearchChange: (search: string) => {
            changeFilters('text', search);
          },
          activeFilters: 0,
          onFiltersClick: () => {},
          show: false,
        }}
        table={{
          components: {
            tableLoader: { loading: loading },
            tableContent: {
              columns: useTableColumnSpecs(),
              rows: permissions,
              onRowClick: (row: PermissionSettingsView) => {
                setOpenDetailDrawer(true);
                setDetailsDrawers({
                  permission: row.id,
                  subject: row.subject,
                  target: row.entity_ref,
                });
                setPermissionDetailsDrawers({
                  permission: row.display_name,
                  subject: row.subjectdisplayname,
                  permissionDescription: row.description,
                  target: row.entity_ref_displayname,
                });
              },
            },
          },
          onSort: (_, field, direction) => {
            setPagination({
              limit: pagination.limit,
              offset: pagination.offset,
            });
            dispatchOrder({ field: field.headerName || '', direction });
          },
          pagination: {
            count: Number(permissionsCount) || 0,
            limit: pagination.limit,
            offset: pagination.offset,
            onPageChange: (page: number) => {
              setPagination({
                ...pagination,
                offset: page * pagination.limit,
              });
            },
            onRowsPerPageChange: (rowsPerPage: number) => {
              setPagination({
                offset: 0,
                limit: rowsPerPage,
              });
            },
          },
        }}
      />
      <DetailDrawer
        open={openDetailDrawer}
        onClose={() => {
          setOpenDetailDrawer(false);
        }}
      >
        <PermissionsDetailDrawer
          permission={permissionDetailsDrawers?.permission ?? ''}
          permissionDescription={
            permissionDetailsDrawers?.permissionDescription ?? ''
          }
          subject={permissionDetailsDrawers?.subject ?? []}
          target={permissionDetailsDrawers?.target ?? ''}
        />
        <WbCard title="Roles that grant you the permission">
          <WbCardContent>
            <RoleDrawerContextProvider
              permission={detailsDrawers?.permission ?? ''}
              subject={detailsDrawers?.subject ?? []}
              target={detailsDrawers?.target ?? ''}
            >
              <RolesDrawerTable />
            </RoleDrawerContextProvider>
          </WbCardContent>
        </WbCard>
      </DetailDrawer>
    </>
  );
};
