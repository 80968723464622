import { ApolloError, useQuery } from '@apollo/client';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { Grid } from '@material-ui/core';
import React from 'react';
import { SYSTEMS_DISTRIBUTION } from '../../graphql';
import { SummaryKpiCard } from './SummaryKpiCard';
import { SystemsDistributionCard } from './SystemsDistributionCard';
import { SystemDistribution } from './types/system-distribution';
import { UsageKpiCard } from './UsageKpiCard';
import {
  buildDashboardWhereQuery,
  extractTaxonomyUrn,
  parseOptionalBooleanConfig,
} from './utils';
import _ from 'lodash';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';

export const RealTimeDataCard = (props: { animationsDuration?: number }) => {
  const configApi = useApi(configApiRef);
  const { animationsDuration } = props;
  const { selectedTaxonomyRef, systemTypes } = useTaxonomySelection();
  const {
    data,
    loading,
    error,
  }: { data?: SystemDistribution; loading: boolean; error?: ApolloError } =
    useQuery(SYSTEMS_DISTRIBUTION, {
      variables: {
        where: buildDashboardWhereQuery(
          extractTaxonomyUrn(selectedTaxonomyRef),
        ),
      },
    });
  const systemsDistribution: SystemDistribution | undefined = data
    ? {
        systems: data.systems.map(system => {
          return {
            type:
              systemTypes.find(type => type.metadata.name === system.type)
                ?.metadata.displayName ?? system.type,
          };
        }),
      }
    : undefined;

  const enabledSummaryKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.summaryKpiCard.enabled',
    ),
  );
  const enabledUsageKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.usageKpiCard.enabled',
    ),
  );
  const enabledSystemsDistributionKpiCard = parseOptionalBooleanConfig(
    configApi.getOptionalBoolean(
      'mesh.marketplace.ui.meshSupervisionPage.systemsDistributionKpiCard.enabled',
    ),
  );

  if (
    !enabledSummaryKpiCard &&
    !enabledUsageKpiCard &&
    !enabledSystemsDistributionKpiCard
  )
    return <></>;

  const getSystemCount = (): number => {
    const systemsCount = _.countBy(data?.systems, 'type');
    return Object.keys(systemsCount).length;
  };

  const getMdSize = () => {
    if (getSystemCount() > 1 && enabledSystemsDistributionKpiCard) {
      return 4;
    }

    return 6;
  };

  return (
    <Grid container direction="row" spacing={2}>
      {enabledSummaryKpiCard && (
        <Grid item md={getMdSize()} xs={12}>
          <SummaryKpiCard animationsDuration={animationsDuration} />
        </Grid>
      )}
      {enabledUsageKpiCard && (
        <Grid item md={getMdSize()} xs={12}>
          <UsageKpiCard />
        </Grid>
      )}
      {enabledSystemsDistributionKpiCard && getSystemCount() > 1 && (
        <Grid item md={4} xs={12}>
          <SystemsDistributionCard
            animationsDuration={animationsDuration}
            data={systemsDistribution}
            loading={loading}
            error={error}
          />
        </Grid>
      )}
    </Grid>
  );
};
