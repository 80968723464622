import { z } from 'zod';

export const DataContractIngestionModeSchema = z
  .enum(['DATA_AT_REST', 'PUSH'])
  .catch('DATA_AT_REST');

export type DataContractIngestionMode = z.infer<
  typeof DataContractIngestionModeSchema
>;

export const DataContractOnBrokenContractSchema = z
  .enum(['RED_FLAG', 'CIRCUIT_BREAK'])
  .catch('RED_FLAG');

export type DataContractOnBrokenContract = z.infer<
  typeof DataContractOnBrokenContractSchema
>;

export type DataContractSettings = {
  ingestionMode: DataContractIngestionMode;
  onBrokenContract: DataContractOnBrokenContract;
  description?: string;
};
