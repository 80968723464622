import {
  DEFAULT_NAMESPACE,
  GroupEntity,
  UserEntity,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { catalogApiRef, useEntity } from '@backstage/plugin-catalog-react';
import { Box, Grid, Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { MemberComponent } from './MemberComponent';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

/**
 * Props for {@link MemberListCard}.
 *
 * @public
 */
export interface MembersListCardProps {
  entityFilterKind?: string[];
  hideRelationsToggle?: boolean;
  relationsType?: string;
  memberDisplayTitle?: string;
  pageSize?: number;
}

/**
 * Exported publicly via the EntityUserProfileCard
 */
export function MembersListCard(props: MembersListCardProps) {
  const { memberDisplayTitle = 'Members', pageSize = 50 } = props;

  const { entity: groupEntity } = useEntity<GroupEntity>();
  const {
    metadata: { name: groupName, namespace: grpNamespace },
  } = groupEntity;
  const catalogApi = useApi(catalogApiRef);

  const groupNamespace = grpNamespace || DEFAULT_NAMESPACE;

  const [page, setPage] = React.useState(1);
  const pageChange = (_: React.ChangeEvent<unknown>, pageIndex: number) => {
    setPage(pageIndex);
  };

  const {
    loading,
    error,
    value: members,
  } = useAsync(async () => {
    const membersList = await catalogApi.getEntities({
      filter: {
        kind: 'User',
        'relations.memberof': [
          stringifyEntityRef({
            kind: 'group',
            namespace: groupNamespace.toLocaleLowerCase('en-US'),
            name: groupName.toLocaleLowerCase('en-US'),
          }),
        ],
      },
    });

    return membersList.items as UserEntity[];
  }, [catalogApi, groupEntity]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  const nbPages = Math.ceil((members?.length || 0) / pageSize);
  const paginationLabel = nbPages < 2 ? '' : `, page ${page} of ${nbPages}`;

  const pagination = (
    <Pagination
      count={nbPages}
      page={page}
      onChange={pageChange}
      showFirstButton
      showLastButton
    />
  );

  return (
    <Grid item>
      <WbCard
        title={`${memberDisplayTitle} (${
          members?.length || 0
        }${paginationLabel})`}
        footer={pagination}
        footerStyle={{
          display: 'flex',
          justifyContent: 'end',
          padding: 8,
        }}
      >
        <WbCardContent>
          <Grid container spacing={3}>
            {members && members.length > 0 ? (
              members
                .slice(pageSize * (page - 1), pageSize * page)
                .map(member => (
                  <MemberComponent member={member} key={member.metadata.uid} />
                ))
            ) : (
              <Box p={2}>
                <Typography>
                  This group has no {memberDisplayTitle.toLocaleLowerCase()}.
                </Typography>
              </Box>
            )}
          </Grid>
        </WbCardContent>
      </WbCard>
    </Grid>
  );
}
