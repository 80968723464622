/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import React from 'react';
import { MarkdownContent } from '@backstage/core-components';

/**
 * Props for {@link PreviewPullRequestComponent}.
 *
 * @public
 */
export interface PreviewPullRequestComponentProps {
  title: string;
  description: string;
  classes?: { card?: string; cardContent?: string };
}

/**
 * Previews a pull request.
 *
 * @public
 */
export const PreviewPullRequestComponent = (
  props: PreviewPullRequestComponentProps,
) => {
  const { title, description, classes } = props;
  return (
    <Card variant="outlined" className={classes?.card}>
      <CardHeader title={title} subheader="Create a new Pull Request" />
      <CardContent className={classes?.cardContent}>
        <MarkdownContent content={description} />
      </CardContent>
    </Card>
  );
};
