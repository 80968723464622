import {
  ComponentRegister,
  ComponentRegisters,
} from '@agilelab/plugin-wb-platform-common';
import React, { FC } from 'react';
import { useCustomViewPreProcessor } from './propertiesProcessor';

type ComponentEntry = {
  id: string;
  function?: (props: any) => JSX.Element | JSX.Element[];
};

export interface CustomViewComponentRegister {
  register(entry: ComponentEntry): void;
  get(componentId: string): Function | undefined;
}

class DefaultCustomViewComponentRegister
  implements CustomViewComponentRegister
{
  private registry: ComponentRegister;
  constructor() {
    this.registry = ComponentRegisters.getComponentRegister('customView');
  }

  get(componentId: string): Function | undefined {
    return this.registry.get(componentId);
  }

  register(entry: ComponentEntry): void {
    const renderComponent = (
      props: Record<string, any>,
      componentEntry: ComponentEntry,
    ) => {
      const Component: FC = componentEntry.function as FC;
      return <Component {...props} />;
    };

    this.registry.register({
      id: entry.id,
      function: function RenderComponent(props: Record<string, any>) {
        const { props: processedProps, hidden } =
          useCustomViewPreProcessor(props);
        if (hidden) {
          return <></>;
        }
        return renderComponent(processedProps, entry);
      },
    });
  }
}

export const CustomViewRegister: CustomViewComponentRegister =
  new DefaultCustomViewComponentRegister();
