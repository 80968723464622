import {
  SearchFilterConfig,
  SearchFilterConfigType,
} from '@agilelab/plugin-wb-search-common';
import {
  DomainTree,
  FacetedValues,
  FilterAvailableValuesByLabel,
} from '../types';
import { CheckboxNode, CheckboxValue } from '@agilelab/plugin-wb-platform';
import { useMemo } from 'react';

export const useFacetedValues = (
  allValues: FilterAvailableValuesByLabel | undefined,
  facetedValues: FacetedValues | undefined,
  config: SearchFilterConfig[],
): FilterAvailableValuesByLabel => {
  const updatedValues = useMemo(() => {
    if (!allValues) return {};

    if (!facetedValues) return allValues;

    const labelToType = config.reduce<Record<string, SearchFilterConfigType>>(
      (acc, curr) => {
        acc[curr.label] = curr.type;
        return acc;
      },
      {},
    );

    Object.keys(allValues).forEach(label => {
      const type = labelToType[label];

      switch (type) {
        case SearchFilterConfigType.DOMAIN: {
          const faceted = facetedValues[label];
          const addCount = (node: CheckboxNode) => {
            const newNode = { ...node };

            if (!node.children.length) {
              newNode.count = faceted[node.id] ?? 0;
            } else {
              newNode.children = node.children.map(addCount);
              newNode.count = newNode.children.reduce(
                (acc, curr) => acc + (curr.count ?? 0),
                0,
              );
            }

            newNode.disabled = newNode.count === 0;

            newNode.adornment = `(${newNode.count})`;
            return newNode;
          };
          const values: DomainTree[] = (allValues[label] ?? []).map(addCount);
          // update the values
          allValues[label] = values;
          break;
        }
        case SearchFilterConfigType.TYPE:
        case SearchFilterConfigType.CHOICE:
          {
            const faceted = facetedValues[label];

            const values: CheckboxValue[] = (allValues[label] ?? []).map(
              (v: CheckboxValue) => {
                const count = faceted[v.value] ?? 0;
                return {
                  ...v,
                  disabled: count === 0,
                  // add the count
                  adornment: `(${count})`,
                };
              },
            );
            // update the values
            allValues[label] = values;
          }
          break;
        default:
      }
    });

    return allValues;
  }, [allValues, facetedValues, config]);

  return updatedValues;
};
