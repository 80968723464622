import {
  TableCellProps,
  WbTruncatedTypographyWrapper,
  useTruncatedStyles,
} from '@agilelab/plugin-wb-platform';
import { Link } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { Typography } from '@material-ui/core';
import React from 'react';
import { dataProductRouteRef, outputPortRouteRef } from '../../../../routes';
import { SemanticLinkingVM } from '../SemanticLinkingTable';

export function useSemanticLinkingTableColumnSpecs(): Array<
  TableCellProps<SemanticLinkingVM>
> {
  const truncatedClass = useTruncatedStyles();
  const dataProductRoute = useRouteRef(dataProductRouteRef);
  const outputPortRoute = useRouteRef(outputPortRouteRef);

  return [
    {
      field: 'domain',
      cellProps: {
        size: 'small',
        style: { wordBreak: 'break-word' },
      },
      headerName: 'domain',
      fieldRender: field => (
        <WbTruncatedTypographyWrapper value={field.domain || '-'} />
      ),
    },
    {
      field: 'projectName',
      cellProps: {
        size: 'small',
      },
      headerName: 'System',
      fieldRender: field =>
        field.dataProductInstanceId ? (
          <Link
            to={dataProductRoute({
              id: field.dataProductInstanceId,
            })}
          >
            <WbTruncatedTypographyWrapper value={field.projectName ?? '-'} />
          </Link>
        ) : (
          <Typography>_</Typography>
        ),
    },
    {
      field: 'displayName',
      cellProps: {
        size: 'small',
      },
      headerName: 'Component',
      fieldRender: field =>
        field.referenceId ? (
          <Link to={outputPortRoute({ id: field.referenceId })}>
            <WbTruncatedTypographyWrapper value={field.displayName ?? ''} />
          </Link>
        ) : (
          <WbTruncatedTypographyWrapper value={field.referenceOutputPort} />
        ),
    },
    {
      field: 'referenceFieldName',
      tooltip: true,
      cellProps: {
        size: 'small',
        style: { wordBreak: 'break-word' },
      },
      typographyProps: {
        component: 'p',
        className: truncatedClass.truncated,
      },
      headerName: 'name',
    },
  ];
}
