import React from 'react';
import iconLogo from './icon-logo.svg';
import { CustomLogoProps } from './types';

const IconLogo = ({
  customIconLogo,
  style,
}: {
  customIconLogo: CustomLogoProps | undefined;
  style?: React.CSSProperties;
}) => {
  if (customIconLogo?.url) {
    return (
      <img
        style={style ?? { maxHeight: '45px', maxWidth: '32px' }}
        src={customIconLogo.url}
        alt="custom icon logo"
      />
    );
  }
  return (
    <img
      style={style ?? { maxHeight: '45px', maxWidth: '32px' }}
      src={iconLogo}
      alt="icon logo witboost"
    />
  );
};

export default IconLogo;
