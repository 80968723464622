import React, { useMemo } from 'react';
import { TextFieldProps } from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { WbMultiSelect, WbSelect } from '../WbTextField';
import { useEnvironmentsContext } from '../../context';

interface Props {
  onChange: (env: string) => void;
  multi?: boolean;
  value: string | string[];
  ref: React.Ref<unknown>;
  label?: string;
}

export const SelectEnvironment = React.forwardRef(
  (
    props: Props & Omit<TextFieldProps, 'value' | 'onChange' | 'select'>,
    ref: React.Ref<any>,
  ) => {
    const { onChange, multi, value, children, label, ...textFieldProps } =
      props;
    const handleChange = (event: React.ChangeEvent<any>) => {
      onChange(event.target.value);
    };
    const { environmentList } = useEnvironmentsContext();
    const fetchedEnvs = environmentList.map(env => env.name);
    const displayedEnvs = useMemo(() => fetchedEnvs, [fetchedEnvs]);

    return multi && Array.isArray(value) ? (
      <WbMultiSelect
        {...textFieldProps}
        disabled={!fetchedEnvs || fetchedEnvs.length === 0}
        ref={ref}
        value={value}
        id="select-env"
        label={label ?? 'Environment'}
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
      />
    ) : (
      <WbSelect
        {...textFieldProps}
        disabled={!fetchedEnvs || fetchedEnvs.length === 0}
        ref={ref}
        value={value}
        id="select-env"
        label={label ?? 'Environment'}
        options={displayedEnvs || []}
        getOptionLabel={env => snakeCaseToTitleCase(env)}
        onChange={handleChange}
      />
    );
  },
);
