import { WbCardContent } from '@agilelab/plugin-wb-platform';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import React from 'react';

import { NoDataPanel } from './NoDataPanel';

const useStyles = makeStyles(() => ({
  container: {
    maxHeight: '300px',
    overflow: 'auto',
  },
}));

type Props = {
  title: string;
  values: any;
  defaultText?: string;
};

export const GenericContent = ({ title, values, defaultText }: Props) => {
  const classes = useStyles();
  const theme = useTheme();

  const valueArray = Object.entries(values || {});

  const field = (label: string, value: any) => {
    if (typeof value === 'object') {
      return [];
    }
    return [
      <Typography
        component="p"
        style={{ marginBottom: theme.spacing(1) }}
        key={`${label}${value}`}
      >
        <span style={{ color: theme.palette.primary.main }}>
          {`${label}: `}{' '}
        </span>
        {value.toString()}
      </Typography>,
    ];
  };

  const displayValues = valueArray.flatMap(entry => field(entry[0], entry[1]));

  return displayValues && displayValues.length ? (
    <WbCardContent>
      <Box className={classes.container}>{displayValues}</Box>
    </WbCardContent>
  ) : (
    <NoDataPanel text={defaultText} title={title} />
  );
};
