import { FieldProps } from '@rjsf/core';
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FileStatus } from './types';
import { getFileSizeFromBase64String, printFileSize, toMB } from './utils';
import { FilePickerInput } from './FilePickerInput';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { getSystemMaxHttpPayloadSizeMb } from '@agilelab/plugin-wb-platform-common';
import { extractCustomProperties, isHidden } from '../../utils';

/**
 * Converts the accept parameter of an `input` HTML element into
 * a list of supported file types
 * @param accept
 * @returns
 */
export function getSupportedFileTypesMessage(accept: string): string {
  const extensions = accept.split(',').map(type => {
    // Check for wildcard types and handle accordingly
    if (type.includes('/*')) {
      const mediaType = type.split('/')[0];
      // return common extensions or the wildcard itself
      if (mediaType === 'image') return 'any image format e.g. JPG, PNG, GIF';
      if (mediaType === 'audio') return 'any audio format e.g. MP3, WAV';
      if (mediaType === 'video') return 'any video format e.g. MP4, AVI';
      return type.toUpperCase();
    }

    // Extracting extension or subtype
    const match = type.match(/\.([0-9a-z]+)(?:[\?#]|$)/i);
    if (match) return match[1].toUpperCase(); // File extension
    return type.split('/')[1]?.toUpperCase() ?? type.toUpperCase(); // MIME subtype
  });

  return `${extensions.join(', ')}`;
}

type FilePickerData = {
  /**
   * data-url formatted string of the file
   */
  fileContent: string;

  fileName: string;
};

export function buildPlaceholder(
  accept?: string,
  sizeLimit?: number,
): string | undefined {
  if (!accept && !sizeLimit) {
    return 'Browse and attach a file';
  }

  const acceptMessage = accept ? getSupportedFileTypesMessage(accept) : null;
  const sizeLimitMessage = sizeLimit ? `Max size: ${sizeLimit} MB` : null;

  return `Browser and attach a file (${[acceptMessage, sizeLimitMessage]
    .filter(item => item !== null)
    .join(' - ')})`;
}

type FilePickerOptions = {
  accept?: string;
  description?: string;
  title?: string;
  /**
   * A maximum size limit in megabytes
   */
  maxSizeMb?: number;
};

export const FilePicker = ({
  onChange,
  required,
  disabled,
  schema,
  uiSchema,
  formData,
}: FieldProps<string>) => {
  const { accept, description, title, maxSizeMb } = schema as FilePickerOptions;
  const config = useApi(configApiRef);
  const maxHttpPayloadSizeMb = getSystemMaxHttpPayloadSizeMb(config);
  const customProps = extractCustomProperties(uiSchema);

  const inputRef = useRef<HTMLInputElement>(null);
  const [fileStatus, setFileStatus] = useState<FileStatus>(FileStatus.Empty);
  const [fileName, setFileName] = useState<string | undefined>(undefined);
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [systemError, setSystemError] = useState<Error | undefined>(undefined);
  const [fileSize, setFileSize] = useState<number | undefined>(undefined);
  const [showAnimation, setShowAnimation] = useState<boolean>(true);

  useEffect(() => {
    const filePickerData = formData as unknown as FilePickerData;
    if (
      !filePickerData ||
      !filePickerData.fileContent ||
      !filePickerData.fileName
    ) {
      return;
    }

    setFileName(filePickerData.fileName);
    setFileStatus(FileStatus.LoadedWithSuccess);
    setProgress(100);
    setShowAnimation(false);
    setFileSize(getFileSizeFromBase64String(filePickerData.fileContent));
  }, [formData]);

  useEffect(() => {
    if (!maxSizeMb && typeof maxSizeMb !== 'number') {
      return;
    }

    if (maxSizeMb <= 0) {
      setSystemError(
        new Error(
          'This field has been disabled due to a misconfiguration error. Please contact the platform team. (The configured file size limit is invalid)',
        ),
      );
      return;
    }

    if (maxSizeMb > maxHttpPayloadSizeMb) {
      setSystemError(
        new Error(
          `This field has been disabled due to a misconfiguration error. Please contact the platform team. The configured file size limit (${maxSizeMb} MB) exceeds the system payload size limit (max: ${maxHttpPayloadSizeMb} MB)`,
        ),
      );
    }
  }, [schema, maxSizeMb, maxHttpPayloadSizeMb]);

  const reset = useCallback((e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setFileStatus(FileStatus.Empty);
    setFileName(undefined);
    setProgress(0);
    setError(undefined);
    setFileSize(undefined);
    setShowAnimation(true);

    if (inputRef && inputRef.current) {
      inputRef.current.value = '';
    }
  }, []);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const reader = new FileReader();

      reader.onloadstart = () => {
        setFileStatus(FileStatus.Loading);
        setFileName(undefined);
        setProgress(0);
        setError(undefined);
        setFileSize(undefined);
        setShowAnimation(true);
      };

      reader.onprogress = (progressEvent: ProgressEvent<FileReader>) => {
        if (progressEvent.lengthComputable) {
          const percentLoaded = Math.round(
            (progressEvent.loaded / progressEvent.total) * 100,
          );
          setProgress(percentLoaded);
        }
      };

      reader.onload = (loadEvent: ProgressEvent<FileReader>) => {
        const base64String = loadEvent.target?.result;
        if (base64String && typeof base64String === 'string') {
          const sizeBytes = getFileSizeFromBase64String(base64String);
          setFileSize(sizeBytes);
          const sizeMegaBytes = toMB(sizeBytes);
          if (maxSizeMb && sizeBytes && sizeMegaBytes > maxSizeMb) {
            setError(
              Error(
                `Exceeded file size limit. (File is ${printFileSize({
                  sizeMegaBytes,
                  sizeLimitMegaBytes: maxSizeMb,
                  digits: 2,
                })}MB)`,
              ),
            );
            setFileStatus(FileStatus.LoadedWithError);
            return;
          }
          onChange({
            fileContent: base64String,
            fileName: file.name,
          } as FilePickerData);
          setFileName(file.name);
          setFileStatus(FileStatus.LoadedWithSuccess);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div
      style={{
        display: isHidden(uiSchema) ? 'none' : undefined,
        position: 'relative',
        height: '100%',
        width: '100%',
      }}
    >
      <FilePickerInput
        title={title ?? 'Attachment'}
        status={fileStatus}
        fileName={fileName}
        progress={progress}
        fileSize={fileSize}
        required={required}
        openFileDialog={e => {
          e.preventDefault();
          e.stopPropagation();
          if (inputRef.current) {
            inputRef.current.click();
          }
        }}
        animation={showAnimation}
        error={systemError || error}
        reset={reset}
        disabled={systemError ? true : disabled}
        placeholder={buildPlaceholder(accept, maxSizeMb)}
        description={description}
        customProps={customProps}
      />

      <input
        style={{
          visibility: 'hidden',
          position: 'absolute',
          zIndex: -1,
          cursor: 'pointer',
        }}
        ref={inputRef}
        required={required}
        disabled={systemError ? true : disabled}
        type="file"
        onChange={handleFileChange}
        accept={accept ?? undefined}
      />
    </div>
  );
};
