import React, { useState, useEffect } from 'react';
import { useApi } from '@backstage/core-plugin-api';
import {
  WbCard,
  WbTable,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import {
  WitboostDocument,
  WitboostDocumentWithAcceptanceDate,
} from '@agilelab/plugin-wb-documents-common';
import { useDocumentsTabColumns } from './useDocumentsTabColumns';
import { documentsApiRef } from '@agilelab/plugin-wb-builder-catalog';

export interface DocumentsTableSort {
  field?: keyof WitboostDocument;
  direction?: 'asc' | 'desc';
}

export const DocumentsViewerTab = () => {
  const documentsApi = useApi(documentsApiRef);
  const alertApi = useApi(customAlertApiRef);
  const documentsColumns = useDocumentsTabColumns();
  const [documents, setDocuments] = useState<
    WitboostDocumentWithAcceptanceDate[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [sortBy, setSortBy] = useState<DocumentsTableSort>({
    field: 'name',
    direction: 'asc',
  });

  useEffect(() => {
    const fetchData = async () => {
      const fetchedDocuments = await documentsApi.retrieveDocuments({
        enabled_only: true,
        metadata_only: true,
        include_acceptance_date: true,
      });

      setDocuments(fetchedDocuments);
    };

    try {
      fetchData();
    } catch (error) {
      alertApi.post({
        error: error,
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [documentsApi, alertApi]);

  useEffect(() => {
    const sortedDocuments = documents.sort((a, b) => {
      if (!sortBy.field) {
        return 0;
      }
      const direction = sortBy.direction === 'asc' ? -1 : 1;
      return a[sortBy.field] > b[sortBy.field] ? direction : -direction;
    });

    setDocuments(sortedDocuments);
  }, [sortBy, documents]);

  return (
    <WbCard title="My Documents">
      <WbTable<WitboostDocument>
        components={{
          tableLoader: { loading },
          tableContent: {
            columns: documentsColumns,
            rows: documents,
          },
        }}
        onSort={(_, cell, direction) => {
          setSortBy({ field: cell.field, direction: direction ?? 'asc' });
        }}
      />
    </WbCard>
  );
};
