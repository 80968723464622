import {
  buildReleaseVersion,
  DeploymentUnitStatus,
} from '@agilelab/plugin-wb-builder-common';
import { useMemo } from 'react';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';

export function useReleaseStatusAndVersion() {
  const { queryParamVersion, release, deploymentUnitStatusState } =
    useReleaseDetailPageContext();

  return useMemo(() => {
    const releaseVersion = queryParamVersion
      ? buildReleaseVersion({ v: queryParamVersion })
      : buildReleaseVersion({ release });
    const responseVersion =
      deploymentUnitStatusState?.value?.provisioningDetails?.descriptorVersion;
    const sameVersion = releaseVersion === responseVersion;
    const notDeployed = {
      status: DeploymentUnitStatus.NOT_DEPLOYED,
      version: releaseVersion,
    };

    if (sameVersion)
      return [
        {
          status: deploymentUnitStatusState?.value?.status,
          version: releaseVersion,
        },
      ];
    if (!responseVersion) return [notDeployed];
    return [
      notDeployed,
      {
        status: deploymentUnitStatusState?.value?.status,
        version: responseVersion,
      },
    ];
  }, [
    queryParamVersion,
    release,
    deploymentUnitStatusState?.value?.provisioningDetails?.descriptorVersion,
    deploymentUnitStatusState?.value?.status,
  ]);
}
