import React from 'react';
import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import { OkFilledIcon } from './Icons';
import { IconLabelBox } from '../../IconLabelBox';

interface Props {
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const OkIcon: React.FC<Props> = ({ style, variant }) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <Tooltip title="Compliant">
      <span>
        {variant === 'reduced' ? (
          <OkFilledIcon
            htmlColor={theme.palette.success.main}
            className={classes.icon}
            style={style}
          />
        ) : (
          <IconLabelBox
            label="Success"
            backgroundColor={theme.palette.success.main}
            icon={
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.black,
                  fontWeight: '700',
                }}
              >
                OK
              </Typography>
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
