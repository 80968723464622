import { z } from 'zod';
import { EvaluationResultResult } from '../evaluation-result';
import { Timing } from '../../enums';

export const GetMetricResultsRequestSchema = z.object({
  resource: z.string().describe('Resource name'),
  environment: z.string(),
  metrics: z.array(z.string()).optional(),
  timing: z.nativeEnum(Timing).optional(),
});

export type GetMetricResultsRequest = z.infer<
  typeof GetMetricResultsRequestSchema
>;

export type MetricResultOutcome = 'error' | 'ok';

export type MetricResult = {
  resourceFilter?: string;
  resourceVariantId?: string;
  metricId: string;
  reportId: string;
  outcome: MetricResultOutcome;
  evaluationResultId: string;
  evaluationResult: EvaluationResultResult;
  evaluationTime: string;
};

export type MetricResults = {
  results: MetricResult[];
};
