import { ApiHolder, identityApiRef } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { FieldValidation } from '@rjsf/core';
import { FieldErrors } from '@rjsf/utils';

const MINIMUM_IDENTIFIER_LENGTH = 6; // 2 dots + 1 character for each of the 3 blocks at least
const TOTAL_BLOCKS = 3; // each block is separated by a dot in the identifier. e.g. finance.customer-0.0 -> blocks are: finance, customer-0, 0

// Checks whether the block is not only digits and contains both digits and letters or only letters
const isValidBlock = (block: string): boolean => {
  const hasDigit = /\d/.test(block);
  const hasLetter = /[a-zA-Z]/.test(block);
  return hasLetter || (hasDigit && hasLetter);
};

const isDigitOnlyBlock = (block: string): boolean => {
  return /^\d+$/.test(block);
};

export const isValidObjectName = (value: unknown): boolean => {
  if (typeof value !== 'string' || value.length < MINIMUM_IDENTIFIER_LENGTH) {
    return false;
  }

  const blocks = value.split('.');
  if (blocks.length !== TOTAL_BLOCKS) {
    return false;
  }

  // checks that domain and name blocks are composed by letters and numbers only, but not only numbers
  // also checks that the last block is digits only
  return (
    isValidBlock(blocks[0]) &&
    isValidBlock(blocks[1]) &&
    isDigitOnlyBlock(blocks[2])
  );
};

export const identifierPickerValidation = async (
  value: string,
  _validation: FieldValidation,
  context: { apiHolder: ApiHolder },
  _propSchema: any,
  _formContext: any,
  fieldName?: string,
): Promise<FieldErrors> => {
  const errorList = [];
  if (!isValidObjectName(value)) {
    errorList.push(
      `The identifier must have three sections separated by dots. Each sections can be letters and digits, but not only digits except for the last section that always ends with 0, representing the initial resource's version. Check that domain name and resource name contains letters and digits but not only digits.`,
    );
  }

  const catalogApi = context.apiHolder.get(catalogApiRef);
  const identityApi = context.apiHolder.get(identityApiRef);

  const token = identityApi
    ? (await identityApi.getCredentials()).token
    : undefined;

  const system = await catalogApi?.getEntityByRef(
    { name: value, namespace: 'default', kind: 'system' },
    { token },
  );

  const component = await catalogApi?.getEntityByRef(
    { name: value, namespace: 'default', kind: 'component' },
    { token },
  );

  if (system || component) {
    errorList.push('Identifier already exists!');
  }
  return {
    [fieldName!]: {
      __errors: errorList,
    },
  };
};
