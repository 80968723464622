import React from 'react';

export interface WbTabProps {
  children?: React.ReactNode;
  name: string;
  action?: () => void;
}

export const WbTab: React.FC<WbTabProps> = ({ children }) => {
  return <>{children}</>;
};
