import React from 'react';
import { FieldTemplateProps } from '@rjsf/core';
import { FormControl, List, ListItem, FormHelperText } from '@material-ui/core';
import WrapIfAdditional from './WrapIfAdditional';
import { WbMarkdownHelperText } from '@agilelab/plugin-wb-platform';

export interface DefaultFieldTemplateProps extends FieldTemplateProps {
  DescriptionField: React.FunctionComponent<{
    id: string;
    description:
      | string
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  }>;
}

export const DefaultFieldTemplate = (props: FieldTemplateProps) => {
  const {
    id,
    children,
    classNames,
    disabled,
    displayLabel,
    hidden,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    required,
    rawErrors = [],
    rawHelp,
    rawDescription,
    schema,
  } = props;

  if (hidden) {
    return children;
  }

  return (
    <WrapIfAdditional
      classNames={classNames}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
    >
      <FormControl fullWidth error={rawErrors.length > 0} required={required}>
        {children}
        {displayLabel && rawDescription && (
          <WbMarkdownHelperText helperText={rawDescription} />
        )}
        {rawErrors.length > 0 && (
          <List dense disablePadding>
            {rawErrors.map((error, i: number) => {
              return (
                <ListItem key={i} disableGutters>
                  <FormHelperText id={id}>{error}</FormHelperText>
                </ListItem>
              );
            })}
          </List>
        )}
        {rawHelp && <FormHelperText id={id}>{rawHelp}</FormHelperText>}
      </FormControl>
    </WrapIfAdditional>
  );
};

export default DefaultFieldTemplate;
