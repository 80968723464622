import { WbCard } from '@agilelab/plugin-wb-platform';
import { Dialog } from '@material-ui/core';
import React from 'react';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { AdvancedProvisioning } from './AdvancedProvisioning';
import { BasicProvisioning } from './BasicProvisioning';
import { useDeploymentPreviewContext } from './context/useDeploymentPreviewContext';

export const DeploymentPreviewModal: React.FC = () => {
  const { isAdvanced, setIsAdvanced } = useDeploymentPreviewContext();
  const { isDeploymentPreviewOpen, setIsDeploymentPreviewOpen } =
    useReleaseDetailPageContext();

  return (
    <Dialog
      open={isDeploymentPreviewOpen}
      onClose={() => {
        setIsDeploymentPreviewOpen(false);
        setIsAdvanced(false);
      }}
      maxWidth="xl"
      fullWidth
    >
      <WbCard
        title="Summary Preview"
        cardStyle={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
      >
        {isAdvanced ? <AdvancedProvisioning /> : <BasicProvisioning />}
      </WbCard>
    </Dialog>
  );
};
