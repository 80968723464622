import { Entity } from '@backstage/catalog-model';
import { z } from 'zod';

export const EntityRelationsPickerSchemaZod = z.object({
  schema: z.object({
    returnFormat: z.enum(['urn', 'ref']).optional(),
    relation: z.string().optional(),
  }),
  uiSchema: z.object({
    'ui:fieldName': z.string(),
    'ui:property': z.string().optional(),
    'ui:componentsFilter': z.string().optional(),
    'ui:filters': z
      .array(
        z.object({
          key: z.string(),
          value: z.string(),
        }),
      )
      .optional(),
  }),
});

export type EntityRelationsPickerSchema = z.infer<
  typeof EntityRelationsPickerSchemaZod
>;

export interface EntityRelationsPickerOptions {
  field: {
    name: string;
    path?: string;
  };
  relation: string;
  returnFormat: 'urn' | 'ref';
  filters: ((entity: Entity) => boolean)[];
}
