import { Box, Typography, useTheme } from '@material-ui/core';
import React from 'react';

interface Props {
  errorMessage: { status: string; message: string };
}

export const ValidationTestErrorHeader: React.FC<Props> = ({
  errorMessage,
}) => {
  const theme = useTheme();

  const getBkg = () => {
    switch (errorMessage.status) {
      case 'Failed.':
        return theme.palette.bkg.error;
      case 'Attention.':
        return theme.palette.bkg.warning;
      default:
        return theme.palette.bkg.primary;
    }
  };

  return (
    <Box
      style={{
        background: getBkg(),
        padding: '12px 16px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '8px',
        marginBottom: '32px',
        borderRadius: '4px',
      }}
    >
      <Typography style={{ fontWeight: 700 }}>
        {errorMessage.status}{' '}
        <Typography component="span">{errorMessage.message}</Typography>
      </Typography>
    </Box>
  );
};
