import { AclVerb } from '@agilelab/plugin-wb-access-control-common';
import {
  ComponentInstanceEntity,
  InstanceEntity,
  parseConsumableInterfacesUrns,
  toConsumableInterface,
} from '@agilelab/plugin-wb-marketplace-common';
import { UserEntity } from '@agilelab/plugin-wb-platform';
import {
  alertApiRef,
  AnalyticsContext,
  configApiRef,
  identityApiRef,
  useApi,
  useApiHolder,
  useElementFilter,
} from '@backstage/core-plugin-api';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from '@material-ui/core';
import { IChangeEvent } from '@rjsf/core';
import React, { useCallback, useEffect, useState } from 'react';

import { AccessControlRequestTemplate } from '@agilelab/plugin-wb-builder-common';
import { expandEntityRef } from '@agilelab/plugin-wb-platform-common';
import {
  createValidator,
  createValidatorAsync,
  CustomFieldValidator,
  DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS,
  LayoutOptions,
  LAYOUTS_KEY,
  LAYOUTS_WRAPPER_KEY,
  MultistepJsonForm,
  scaffolderApiRef,
  useTemplateParameterSchema,
} from '@agilelab/plugin-wb-scaffolder';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useOutlet } from 'react-router';
import useAsync from 'react-use/lib/useAsync';

type AccessControlRequestDialogProps = {
  isOpen: boolean;
  template: AccessControlRequestTemplate | undefined;
  systemInstance: InstanceEntity;
  selectedOutputPorts: ComponentInstanceEntity[];
  userEntity: UserEntity;
  onRequestSent: () => void;
  setIsOpen: (value: boolean) => void;
  setScaffolderTaskIds: (taskIds: string[]) => void;
  verb: AclVerb;
};

const customFieldComponents = Object.fromEntries(
  DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS.map(({ name, component }) => [
    name,
    component,
  ]),
);

const createContext = (
  systemInstance: InstanceEntity,
  outputPort: ComponentInstanceEntity,
  userEntity: UserEntity,
  requesterRawEntity: Entity | undefined,
  consumableInterfaceTypeField: string,
) => {
  const parsedConsumableInterface = toConsumableInterface(outputPort);
  return {
    id_output_port: outputPort?.id,
    id_dataproduct_instance: systemInstance.id,
    requester: userEntity.username,
    requester_display_name: userEntity.displayName,
    environment: systemInstance.environment.name,
    dataproduct: {
      id: systemInstance.id,
      name: systemInstance.name,
      domain: systemInstance.domains[0].data.name,
      version: systemInstance.version,
      display_name: systemInstance.display_name,
      data_product_owner: systemInstance.owner,
      data_product_display_name: systemInstance.owner_display_name,
      external_id: systemInstance.external_id,
    },
    outputport: {
      name: outputPort.name,
      output_port_type:
        outputPort.descriptor[consumableInterfaceTypeField] ?? 'unavailable',
      external_id: outputPort.external_id,
      consumableInterfaces: parsedConsumableInterface
        ? parseConsumableInterfacesUrns(parsedConsumableInterface)
        : [],
    },
    recipient: systemInstance.owner,
    sender: userEntity.username,
    requesterRawEntity: requesterRawEntity,
  };
};

const cloneFormWithDescriptor = (
  formData: Record<string, any>,
  selectedOutputPort: ComponentInstanceEntity,
  dataProductInstance: InstanceEntity,
  userEntity: UserEntity,
  requesterRawEntity: Entity | undefined,
  consumableInterfaceTypeField: string,
) => {
  return {
    ...formData,
    __consumableInterface__: selectedOutputPort.descriptor,
    __context__: createContext(
      dataProductInstance,
      selectedOutputPort,
      userEntity,
      requesterRawEntity,
      consumableInterfaceTypeField,
    ),
  };
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formRow: {
      marginBottom: theme.spacing(2),
    },
  }),
);

export const AccessControlRequestDialog = (
  props: AccessControlRequestDialogProps,
) => {
  const {
    template,
    selectedOutputPorts,
    systemInstance,
    userEntity,
    setIsOpen,
    onRequestSent,
    setScaffolderTaskIds,
  } = props;
  const alertApi = useApi(alertApiRef);
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const { value: requesterRawEntity } = useAsync(async () => {
    const { token } = await identityApi.getCredentials();
    return catalogApi.getEntityByRef(expandEntityRef(userEntity.username), {
      token,
    });
  }, [catalogApi]);

  const consumableInterfaceTypeField = configApi.getString(
    'practiceShaper.migration.consumableInterfaceTypeField',
  );

  const [formState, setFormState] = useState<Record<string, any>>({
    __consumableInterface__:
      selectedOutputPorts && selectedOutputPorts[0]
        ? selectedOutputPorts[0].descriptor
        : undefined,
    __context__: createContext(
      systemInstance,
      selectedOutputPorts[0],
      userEntity,
      requesterRawEntity,
      consumableInterfaceTypeField,
    ),
  });

  useEffect(() => {
    setFormState({
      __consumableInterface__:
        selectedOutputPorts && selectedOutputPorts[0]
          ? selectedOutputPorts[0].descriptor
          : undefined,
      __context__: createContext(
        systemInstance,
        selectedOutputPorts[0],
        userEntity,
        requesterRawEntity,
        consumableInterfaceTypeField,
      ),
    });
  }, [
    systemInstance,
    selectedOutputPorts,
    userEntity,
    props.isOpen,
    requesterRawEntity,
    consumableInterfaceTypeField,
  ]);

  const scaffolderApi = useApi(scaffolderApiRef);
  const templateRef = template
    ? stringifyEntityRef({
        name: template.metadata.name,
        kind: template.kind,
        namespace: template.metadata.namespace ?? 'default',
      })
    : '';
  const theme = useTheme();
  const classes = useStyles();
  const { schema } = useTemplateParameterSchema(templateRef);

  const handleChange = useCallback(
    (e: IChangeEvent) => {
      setFormState(e.formData);
    },
    [setFormState],
  );

  const outlet = useOutlet();
  const apiHolder = useApiHolder();
  const customLayouts = useElementFilter(outlet, elements =>
    elements
      .selectByComponentData({
        key: LAYOUTS_WRAPPER_KEY,
      })
      .findComponentData<LayoutOptions>({
        key: LAYOUTS_KEY,
      }),
  );

  const handleSendRequest = useCallback(async () => {
    try {
      const scaffolderResponses = await Promise.all(
        selectedOutputPorts.map(async outputPort => {
          return scaffolderApi.scaffold({
            templateRef,
            values: cloneFormWithDescriptor(
              formState,
              outputPort,
              systemInstance,
              userEntity,
              requesterRawEntity,
              consumableInterfaceTypeField,
            ),
          });
        }),
      );
      onRequestSent();
      const taskIds = scaffolderResponses.map(response => response.taskId);
      setScaffolderTaskIds(taskIds);
    } catch (error) {
      alertApi.post({
        message: error.message,
        severity: 'error',
      });
    }
    setFormState({});
    setIsOpen(false);
  }, [
    setIsOpen,
    scaffolderApi,
    templateRef,
    formState,
    selectedOutputPorts,
    onRequestSent,
    setScaffolderTaskIds,
    alertApi,
    systemInstance,
    requesterRawEntity,
    userEntity,
    consumableInterfaceTypeField,
  ]);

  const customFieldValidatorsSync = Object.fromEntries(
    DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS.filter(
      ({ asyncValidation }) => !asyncValidation,
    ).map(({ name, validation }) => [
      name,
      validation as CustomFieldValidator<any>,
    ]),
  );

  const customFieldValidatorsAsync = Object.fromEntries(
    DEFAULT_SCAFFOLDER_FIELD_EXTENSIONS.filter(
      ({ asyncValidation }) => asyncValidation,
    ).map(({ name, validation }) => [
      name,
      validation as CustomFieldValidator<any>,
    ]),
  );

  return (
    <Dialog open={props.isOpen} fullWidth maxWidth="sm">
      <DialogTitle>
        Access Control -{' '}
        {props.verb === AclVerb.Grant ? 'Request access' : 'Request a revoke'}
      </DialogTitle>
      <DialogContent style={{ overflowX: 'hidden' }}>
        <Box
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography style={{ marginBottom: theme.spacing(2) }}>
            {props.verb === AclVerb.Grant
              ? 'You are requesting access to: '
              : 'You are requesting a revoke for: '}
          </Typography>

          {selectedOutputPorts && (
            <Typography color="primary" component="span">
              {selectedOutputPorts
                .map(outputPort => outputPort.name)
                .join(', ')}
            </Typography>
          )}
        </Box>
        <Box className={classes.formRow}>
          <AnalyticsContext attributes={{ entityRef: templateRef }}>
            {schema && (
              <MultistepJsonForm
                rootStepperStyle={{ padding: '0' }}
                stepContentStyle={{ padding: '0', margin: '0' }}
                formData={formState}
                customOnSubmitFn={handleSendRequest}
                customBackButton={
                  <Button
                    color="primary"
                    style={{ marginTop: '10px', marginRight: '4px' }}
                    variant="outlined"
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </Button>
                }
                customNextButton={
                  <Button
                    color="primary"
                    style={{ marginTop: '10px' }}
                    type="submit"
                    variant="contained"
                  >
                    Send
                  </Button>
                }
                fields={customFieldComponents}
                onChange={handleChange}
                layouts={customLayouts}
                viewStepperLabel={false}
                steps={schema.steps.map(step => {
                  return {
                    ...step,
                    asyncValidate: createValidatorAsync(
                      step.schema,
                      customFieldValidatorsAsync,
                      { apiHolder },
                    ),
                    validate: createValidator(
                      step.schema,
                      customFieldValidatorsSync,
                      { apiHolder },
                    ),
                  };
                })}
              />
            )}
          </AnalyticsContext>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
