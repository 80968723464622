import { StatusData } from '@agilelab/plugin-wb-marketplace-common';
import { DateTime } from 'luxon';

export function toDateString(dateString: string): string {
  try {
    const userLang = navigator.language || 'en-GB';
    const isDateTime = dateString.includes('T');

    const dateFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };

    const datetimeFormat: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
    };

    return new Date(dateString).toLocaleDateString(
      userLang,
      isDateTime ? datetimeFormat : dateFormat,
    );
  } catch (err) {
    return '';
  }
}

export function getMonthsBetweenDateAndNow(timestamp: string): string[] {
  const dateArray: string[] = [];

  try {
    let currentMonth: DateTime = DateTime.fromISO(timestamp).startOf('month');
    const endMonth: DateTime = DateTime.now().startOf('month');

    while (currentMonth <= endMonth) {
      dateArray.push(currentMonth.toFormat('MMM yyyy'));
      currentMonth = currentMonth.plus({ months: 1 });
    }
  } catch (error) {
    return [];
  }

  return dateArray;
}

export function getTimestampsBetweenDateAndNow(timestamp: string): DateTime[] {
  const dateArray: DateTime[] = [];

  let currentDay: DateTime = DateTime.fromISO(timestamp).startOf('day');
  const endDay: DateTime = DateTime.now().startOf('day').minus({ days: 1 });

  while (currentDay <= endDay) {
    dateArray.push(currentDay.startOf('day'));
    currentDay = currentDay.plus({ days: 1 });
  }

  return dateArray;
}

export function discardOldValues(
  values: StatusData[],
  days: number,
): StatusData[] {
  const firstDate = DateTime.now().startOf('day').minus({ days: days });
  return values.filter(v => DateTime.fromISO(v.timestamp) > firstDate);
}
