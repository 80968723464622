import { RbacApi } from '@agilelab/plugin-wb-rbac-common';
import { ApiRef, createApiRef } from '@backstage/core-plugin-api';

export * from './api';
export * from './components/RolesTable/RolesTable';
export * from './components/PermissionsTable/PermissionsTable';
export * from './components/Context/RoleContext';
export * from './components/Context/PermissionContext';
export const rbacApiRef: ApiRef<RbacApi> = createApiRef({
  id: 'plugin.rbac.service',
});
