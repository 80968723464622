import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const ShoppableIcon = createSvgIcon(
  <path d="M24 10.335v.667a1 1 0 0 1-1 1h-.333l-1.088 7.616a2 2 0 0 1-1.98 1.717H4.4a2 2 0 0 1-1.979-1.717l-1.088-7.617H1a1 1 0 0 1-1-1v-.666a1 1 0 0 1 1-1h2.806l4.449-6.117a1.333 1.333 0 0 1 2.157 1.568L7.1 9.335h9.8l-3.312-4.55a1.333 1.333 0 0 1 2.157-1.567l4.449 6.118H23a1 1 0 0 1 1 .999Zm-11 7.333v-4.667a1 1 0 1 0-2 0v4.667a1 1 0 0 0 2 0Zm4.667 0v-4.667a1 1 0 1 0-2 0v4.667a1 1 0 0 0 2 0Zm-9.333 0v-4.667a1 1 0 1 0-2 0v4.667a1 1 0 0 0 2 0Z" />,
  'PreviewIcon',
);

export const ConsumableIcon = createSvgIcon(
  <path d="M12 4.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5zM12 17c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-8c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z" />,
  'PreviewIcon',
);
