export const GET_ENTITY_REF_BY_ACL_REQUEST_ACL = `
  query GetEntitiesRef($ids: [bigint!]) {
    marketplace_acls( where: {port_id: {_in: $ids}}, distinct_on: refs) {
      refs
    }
    marketplace_acl_requests(where: {port_id: {_in: $ids}, status: {_neq: "COMPLETED"}}, distinct_on: refs) {
        refs
    }
  }
`;
