import React from 'react';
import { useWbSidebarOpenState } from './WbSidebarOpenStateContext';
import { WbSidebarDivider } from './components/WbSidebarDivider';
import { WbSidebarItem } from './components/WbSidebarItem';

/**
 * Props for the `WbSidebarGroup`
 *
 * @public
 */
export interface WbSidebarGroupProps {
  text?: string;
  children?: React.ReactNode;
  invisibleDivider?: boolean;
}

/**
 * Groups items of the `WbSidebar` together.
 * @public
 */
export const WbSidebarGroup = (props: WbSidebarGroupProps) => {
  const { children, text, invisibleDivider } = props;
  const { isOpen } = useWbSidebarOpenState();
  return (
    <>
      {isOpen ? (
        <WbSidebarItem text={text} isHeader />
      ) : (
        <WbSidebarDivider sideMargin invisible={invisibleDivider} />
      )}

      {children}
    </>
  );
};
