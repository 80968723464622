import { ConfigApi } from '@backstage/core-plugin-api';
import { OutputPortWithWarnings } from '../../types';
import { CustomViewCtrls } from '@agilelab/plugin-wb-platform';

CustomViewCtrls.register({
  id: 'marketplace_data_contract',
  function: (
    data: OutputPortWithWarnings,
    { configApi }: { configApi: ConfigApi },
  ) => {
    const { mode } = data;
    const warnings = data._portWarnings || [];
    const icons: Record<string, string> = {
      push:
        configApi.getOptionalString(
          'mesh.marketplace.ui.dataContracts.demoPage.dataContractPushImageUrl',
        ) ?? '',
      pull:
        configApi.getOptionalString(
          'mesh.marketplace.ui.dataContracts.demoPage.dataContractPullImageUrl',
        ) ?? '',
    };
    const iconTitle: Record<string, string> = {
      push: 'push-img',
      pull: 'pull-img',
    };
    const modeDescription: Record<string, string> = {
      push: 'push',
    };
    const warnDescription: Record<string, string> = {
      blocking: 'Circuit Break',
    };
    const warnType = warnings.reduce(
      (acc, curr) => (curr.id > acc.id ? curr : acc),
      { id: -1, kind: '' },
    );

    return {
      _settings: {
        icon_title: iconTitle[mode] || '',
        warns: warnDescription[warnType.kind.toLowerCase()] || 'Red Flag',
        icon: icons[mode] || icons.pull || '',
        mode: modeDescription[mode] || 'data at rest',
      },
      _system: data?.system?.[0]?.data ?? {},
      _portWarnings: data?._portWarnings,
      ...data?.descriptor,
    };
  },
});
