import { DomainType } from '@agilelab/plugin-wb-builder-common';
import { DomainEntity } from '@agilelab/plugin-wb-marketplace-common';
import { ReportDetailsResponse } from '@agilelab/plugin-wb-metrics-common';
import { CheckboxValue } from '@agilelab/plugin-wb-platform';
import { camelCaseToCapitalCase } from '@agilelab/plugin-wb-platform-common';
import { ALL_TAXONOMIES_FILTER } from '@agilelab/plugin-wb-practice-shaper';
import { PracticeShaperApi } from '@agilelab/plugin-wb-practice-shaper-common';
import { WitboostMarketplaceSearchResult } from '@agilelab/plugin-wb-search-common';
import { ApolloClient } from '@apollo/client';
import { parseEntityRef } from '@backstage/catalog-model';
import { IdentityApi } from '@backstage/core-plugin-api';
import { loadDomainsMap, resolveDomainRelations } from '../../utils';
import { MarketplaceSearchResultEnriched } from './types';

const resolveTypeDisplayName = async (
  kind: string,
  type: string,
  practiceShaperApi: PracticeShaperApi,
) => {
  const filter = { resourceTypeId: type };

  let entity;

  switch (kind) {
    case 'system':
      entity = (await practiceShaperApi.getSystemTypes({ filter })).items[0];
      return (
        entity.metadata.displayName ||
        camelCaseToCapitalCase(entity.metadata.name)
      );

    case 'component':
      entity = (await practiceShaperApi.getComponentTypes({ filter })).items[0];
      return (
        entity.metadata.displayName ||
        camelCaseToCapitalCase(entity.metadata.name)
      );
    default:
      throw new Error('kind not supported');
  }
};

const stringifyDomain = (domain: DomainEntity) => {
  return `${domain.data.name}@${domain.data.external_id}`;
};

const parseDomainString = (domainString: string) => {
  const [name, external_id] = domainString.split('@');
  return { data: { name, external_id } };
};

/**
 * @param type like 'dataproduct', 'storage'
 * @param kind like 'system', 'component'
 */
const stringifyTypeAndKind = (type: string, kind: string) => {
  return `${type}@${kind}`;
};

/**
 * @returns type like 'dataproduct', 'storage' and kind like 'system', 'component'
 */
const parseTypeAndKindString = (typeKindString: string) => {
  const [type, kind] = typeKindString.split('@');
  return { type, kind };
};

/**
 * Creates a map of document titles to their occurrence counts.
 *
 * @param results - An array of `WitboostMarketplaceSearchResult` objects.
 * @returns A record where the keys are document titles and the values are the number of times each title appears in the results.
 * @example { 'dp_with_versions': 3, 'dp_with_single_version': 1 }
 */
export const createNameMap = (
  results: WitboostMarketplaceSearchResult[],
): Record<string, number> => {
  return results.reduce((acc, r) => {
    acc[r.document.title] = (acc[r.document.title] ?? 0) + 1;
    return acc;
  }, {} as Record<string, number>);
};

export const enrichSearchResults = async (
  results: WitboostMarketplaceSearchResult[],
  apolloClient: ApolloClient<object>,
  practiceShaperApi: PracticeShaperApi,
): Promise<MarketplaceSearchResultEnriched[]> => {
  // gather all distinct domains to batch the resolutions
  const domains = new Set<string>();

  // gather all distinct types to batch the resolutions
  const types = new Set<string>();

  const resolvedDomainMap = new Map<string, string[]>();
  const resolvedTypeMap = new Map<string, string>();

  const domainsMap = await loadDomainsMap(apolloClient);

  results.forEach(r => {
    domains.add(stringifyDomain({ data: r.document._computedInfo.domain }));
    types.add(
      stringifyTypeAndKind(
        r.document.kind ?? 'Unknown',
        r.document._computedInfo.kind,
      ),
    );
  });

  const domainPromises = Array.from(domains).map(d => {
    const resolvedDomains = resolveDomainRelations(
      parseDomainString(d).data.external_id,
      domainsMap,
    );
    resolvedDomainMap.set(d, resolvedDomains);
  });

  const typePromises = Array.from(types).map(async t => {
    const { kind, type } = parseTypeAndKindString(t);
    const resolvedType = await resolveTypeDisplayName(
      kind,
      type,
      practiceShaperApi,
    );
    resolvedTypeMap.set(t, resolvedType);
  });

  // we use Promise.allSettled so that one single error doesn't invalidate all the results
  await Promise.allSettled([...domainPromises, ...typePromises]);

  const nameMap = createNameMap(results);
  return results.map(r => {
    const { document: searchDocument } = r;
    const nameWithVersion =
      nameMap[searchDocument.title] > 1 && 'version' in searchDocument
        ? `${searchDocument?.title} v${searchDocument.version.split('.')[0]}`
        : searchDocument.title;
    return {
      ...r,
      document: {
        ...searchDocument,
        _resolvedType:
          resolvedTypeMap.get(
            stringifyTypeAndKind(
              r.document.kind,
              r.document._computedInfo.kind,
            ),
          ) ?? 'Unavailable',
        _resolvedDomains: resolvedDomainMap.get(
          stringifyDomain({ data: r.document._computedInfo.domain }),
        ) ?? ['Unavailable'],
        _nameWithVersion: nameWithVersion,
      },
    };
  });
};

export const typeFilterResolver = async (
  api: PracticeShaperApi,
  taxonomyRef?: string,
): Promise<CheckboxValue[]> => {
  const res: CheckboxValue[] = [];
  const [systemResp, componentResp] = await Promise.all([
    api.getSystemTypes({ filter: { taxonomyRef: taxonomyRef } }),
    api.getComponentTypes({ filter: { taxonomyRef: taxonomyRef } }),
  ]);

  systemResp.items.forEach(s =>
    res.push({ value: s.metadata.name, label: s.metadata.displayName }),
  );

  componentResp.items.forEach(s =>
    res.push({ value: s.metadata.name, label: s.metadata.displayName }),
  );

  return res;
};

export function getTopReportIDs(data: ReportDetailsResponse) {
  // Flatten the data to include all report IDs as keys
  const flattenedData = data.reports[0]
    ? data.reports[0].report_entries?.flatMap(item => {
        return Object.keys(item.report as any).map(reportId => {
          const { count, last_timestamp } = (item.report as any)[reportId];
          return { reportId, count, last_timestamp };
        });
      })
    : [];

  // Sort by last_timestamp descending and take top 5 report IDs
  const topByTimestamp = flattenedData
    .sort((a, b) => b.last_timestamp - a.last_timestamp)
    .slice(0, 5)
    .map(item => item.reportId);

  // Sort by count descending and take top 5 report IDs
  const topByCount = flattenedData
    .sort((a, b) => b.count - a.count)
    .slice(0, 5)
    .map(item => item.reportId);

  return {
    topByTimestamp,
    topByCount,
  };
}

export type CompatibleDomainTypesProps = {
  selectedTaxonomyRef: string;
  practiceShaperApi: PracticeShaperApi;
  identityApi: IdentityApi;
};

export async function getCompatibleDomainTypes(
  props: CompatibleDomainTypesProps,
): Promise<DomainType[]> {
  const { selectedTaxonomyRef, practiceShaperApi, identityApi } = props;
  const credentials = await identityApi.getCredentials();
  if (selectedTaxonomyRef === ALL_TAXONOMIES_FILTER) {
    return (await practiceShaperApi.getDomainTypes({}, credentials)).items;
  }
  const taxonomyRef = parseEntityRef(selectedTaxonomyRef);
  const compatibleDomainTypeRefs = (
    await practiceShaperApi.resolveTaxonomyDomainTypes(
      { taxonomyRef },
      credentials,
    )
  ).domainTypeRefs;
  return (
    await practiceShaperApi.getDomainTypes(
      { filter: { refs: compatibleDomainTypeRefs } },
      credentials,
    )
  ).items;
}
