import React from 'react';
import { Navigate } from 'react-router';
import { useHomeUrl } from '../../hooks';
import { useApi } from '@backstage/core-plugin-api';
import { customAlertApiRef } from '../../apis';

export function WbHomeRedirect() {
  const { error, homeURL, loading, isGuestUser } = useHomeUrl();
  const alertApi = useApi(customAlertApiRef);

  if (loading) {
    return <></>;
  }

  if (error) {
    return <>{error}</>;
  }

  if (isGuestUser) {
    alertApi.post({
      severity: 'info',
      timeoutMillis: 5000,
      message:
        'You are currently logged in as a guest. All Witboost features are disabled.',
    });
  }

  return <Navigate state={homeURL} to={homeURL} />;
}
