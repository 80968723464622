import React, { ReactNode } from 'react';
import {
  Checkbox,
  CheckboxProps,
  FormControlLabel,
  FormControlLabelProps,
  makeStyles,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

type BaseCheckboxProps = Pick<FormControlLabelProps, 'label' | 'disabled'> &
  Pick<CheckboxProps, 'checked' | 'onChange' | 'indeterminate'> & {
    // to display an element next to the label
    adornment?: ReactNode;
  };

const useStyles = makeStyles(
  theme => ({
    root: {
      marginRight: 0,
      // base margin was 11, since we decrease the padding around the box by 5, we decrease this by 5 as well
      marginLeft: -6,
      gap: theme.spacing(0.5),
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(0.5),
      width: '100%',
      overflow: 'hidden',
    },
    label: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    icon: {
      fontSize: '20px',
    },
    checkbox: {
      paddingInline: theme.spacing(0.5),
      paddingBlock: theme.spacing(0.5),
    },
  }),
  { name: 'BaseCheckbox' },
);

export const BaseCheckbox = ({
  label,
  checked,
  adornment,
  indeterminate,
  onChange,
  disabled,
}: BaseCheckboxProps) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{
        root: classes.root,
        label: classes.labelRoot,
      }}
      label={
        <>
          <span className={classes.label}>{label}</span> {adornment}
        </>
      }
      control={
        <Checkbox
          color="primary"
          className={classes.checkbox}
          icon={<CheckBoxOutlineBlankIcon className={classes.icon} />}
          checkedIcon={<CheckBoxIcon className={classes.icon} />}
          indeterminateIcon={
            <IndeterminateCheckBoxIcon
              className={classes.icon}
              color={!disabled ? 'primary' : undefined}
            />
          }
          indeterminate={indeterminate}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
        />
      }
    />
  );
};
