import { ResponseError } from '@backstage/errors';
import crossFetch from 'cross-fetch';
import {
  DiscoveryApi,
  FetchApi,
  GetAllUserConfigOptions,
  GetUserConfigOptions,
  StoreUserConfigOptions,
  UserConfigApi,
  UserConfigResponse,
} from './types';
import { UserConfigs } from './types/api';

/**
 * A frontend and backend compatible client for communicating with the Backstage
 * software catalog.
 *
 * @public
 */
export class UserConfigClient implements UserConfigApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi?: FetchApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
  }

  /**
   * {@inheritdoc UserConfigApi.getUserConfig}
   */
  async getUserConfig(
    options: GetUserConfigOptions,
  ): Promise<UserConfigResponse | undefined> {
    const queryParams = new URLSearchParams();
    if (options.redactWith) queryParams.set('redactWith', options.redactWith);
    const queryString = queryParams.toString();
    const url = `${await this.discoveryApi.getBaseUrl(
      'user-config',
    )}/user_config/${options.key}${queryString ? `?${queryString}` : ''}`;
    const headers: Record<string, string> = {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      if (response.status === 404) {
        return undefined;
      }
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  /**
   * {@inheritdoc UserConfigApi.getAllUserConfigs}
   */
  async getAllUserConfigs(
    options: GetAllUserConfigOptions,
  ): Promise<UserConfigs[]> {
    const queryParams = new URLSearchParams();
    if (options.redactWith) queryParams.set('redactWith', options.redactWith);
    const queryString = queryParams.toString();
    const url = `${await this.discoveryApi.getBaseUrl(
      'user-config',
    )}/user_config${queryString ? `?${queryString}` : ''}`;
    const headers: Record<string, string> = {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method: 'GET',
      headers,
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  /**
   * {@inheritdoc UserConfigApi.storeUserConfig}
   */
  async storeUserConfig(
    options: StoreUserConfigOptions,
  ): Promise<UserConfigResponse> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'user-config',
    )}/user_config`;
    const headers = {
      Authorization: `Bearer ${options.token}`,
      'Content-Type': 'application/json',
    };
    const response = await this.fetchApi.fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        key: options.key,
        value: options.value,
        isSensitive: options.isSensitive,
      }),
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }

  /**
   * {@inheritdoc UserConfigApi.deleteUserConfig}
   */
  async deleteUserConfig(options: GetUserConfigOptions): Promise<boolean> {
    const url = `${await this.discoveryApi.getBaseUrl(
      'user-config',
    )}/user_config/${options.key}`;
    const headers: Record<string, string> = {
      Authorization: `Bearer ${options.token}`,
    };
    const response = await this.fetchApi.fetch(url, {
      method: 'DELETE',
      headers,
    });

    if (!response.ok) {
      throw await ResponseError.fromResponse(response);
    }

    return response.json();
  }
}
