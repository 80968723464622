import {
  useEnvironmentsContext,
  WbCard,
  WbCardContent,
} from '@agilelab/plugin-wb-platform';
import { useTaxonomySelection } from '@agilelab/plugin-wb-practice-shaper';
import { ApolloError, useQuery } from '@apollo/client';
import { ErrorPanel } from '@backstage/core-components';
import {
  Box,
  CircularProgress,
  CircularProgressProps,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Skeleton } from '@material-ui/lab';
import pluralize from 'pluralize';
import React from 'react';
import { SYSTEMS_USAGE } from '../../graphql';
import { CustomStatus } from '../CustomStatus/CustomStatus';
import { ChartDetail, OverviewComponent } from './OverviewComponent';
import { DpUsageQuery } from './types/dp-usage';
import {
  buildDashboardWhereQuery,
  extractTaxonomyUrn,
  getCardLabel,
} from './utils';

const useStylesMesh = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
    },
    bottom: {
      color: theme.palette.grey[100],
    },
    top: {
      position: 'absolute',
      left: 0,
      color: theme.palette.charts[0],
    },
  }),
);

function parseData(data: DpUsageQuery) {
  return {
    used: data.inputPort_aggregate?.aggregate?.count || 0,
    total: data.instance_aggregate?.aggregate?.count || 0,
  };
}

function getPercentage(parsedData: { used: number; total: number }) {
  return ((parsedData.used || 0) / (parsedData.total || 1)) * 100;
}
function MeshCircularProgress(props: CircularProgressProps) {
  const classes = useStylesMesh();

  return (
    <div className={classes.root}>
      <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size={200}
        thickness={5}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="determinate"
        className={classes.top}
        size={200}
        thickness={5}
        {...props}
      />
    </div>
  );
}

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number },
) {
  return (
    <Box position="relative" display="inline-flex">
      <MeshCircularProgress value={props.value} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6" component="div">{`${Math.round(
          props.value,
        )}%`}</Typography>
        <CustomStatus getColor={palette => palette.charts[0]}>
          Consumed
        </CustomStatus>
      </Box>
    </Box>
  );
}

function extractChartDetails(
  data: { used: number; total: number },
  colors: string[],
): ChartDetail[] {
  const used = data.used || 0;
  const total = data.total || 1;
  return [
    {
      label: 'Consumed',
      value: data.used,
      percentage: Math.round((used / total) * 100),
      color: colors[0],
    },
    {
      label: 'Not Consumed',
      value: data.total - data.used,
      percentage: 100 - Math.round((used / total) * 100),
      color: colors[1],
    },
  ];
}

export const UsageKpiCard = () => {
  const theme = useTheme();
  const { selectedTaxonomyRef, systemTypes } = useTaxonomySelection();
  const { priorityEnvironment } = useEnvironmentsContext();
  const chartColors = theme.palette.charts;
  const taxonomyUrn = extractTaxonomyUrn(selectedTaxonomyRef);
  const {
    loading,
    error,
    data,
  }: {
    loading: boolean;
    error?: ApolloError;
    data?: DpUsageQuery;
  } = useQuery(SYSTEMS_USAGE, {
    variables: {
      where: buildDashboardWhereQuery(taxonomyUrn, [
        { environment: { name: { _eq: priorityEnvironment.name } } },
      ]),
      inputPortWhere: buildDashboardWhereQuery(taxonomyUrn, [
        { environment: { name: { _eq: priorityEnvironment.name } } },
        {
          relationsByTargetInstanceId: {
            _and: [
              { name: { _eq: 'partOfSystem' } },
              {
                instance: {
                  _and: [
                    {
                      relationsByTargetInstanceId: {
                        name: { _eq: 'readsFrom' },
                      },
                    },
                  ],
                },
              },
            ],
          },
        },
      ]),
    },
  });

  const systemTypeLabel = pluralize(getCardLabel(systemTypes));

  return (
    <WbCard
      title={`${systemTypeLabel} Usage`}
      icon={
        <Tooltip
          title={`Percentage of consumed ${systemTypeLabel} on production environment`}
        >
          <InfoOutlinedIcon color="primary" />
        </Tooltip>
      }
      cardStyle={{ height: '100%' }}
    >
      <WbCardContent
        style={{ height: '425px', display: 'flex', flexDirection: 'column' }}
      >
        {!!error && <ErrorPanel error={error} />}
        {!!loading && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
            }}
          >
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="rect"
              width="35%"
              height="16px"
            />
            <Skeleton
              animation="wave"
              variant="circle"
              width="200px"
              height="200px"
              style={{ alignSelf: 'center' }}
            />
          </Box>
        )}
        {!!data && (
          <Box>
            <Box
              component="div"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgressWithLabel
                value={getPercentage(parseData(data))}
              />
            </Box>
            <OverviewComponent
              chartDetails={extractChartDetails(parseData(data), [
                chartColors[0] as string,
                theme.palette.grey[100],
              ])}
            />
          </Box>
        )}
      </WbCardContent>
    </WbCard>
  );
};
