import { z } from 'zod';

export enum Acceptance_type {
  MANDATORY = 'MANDATORY',
  ACKNOWLEDGMENT = 'ACKNOWLEDGMENT',
  CONSULTANCY = 'CONSULTANCY',
}

export const witboostDocumentZod = z.object({
  id: z.number().nonnegative().optional(),
  name: z.string(),
  description: z.string().optional(),
  document_type: z.string().optional(),
  acceptance_type: z.nativeEnum(Acceptance_type),
  content: z.any(),
  mime_type: z.string().optional(),
  enabled: z.boolean(),
  date: z.date().optional(),
});

export const witboostDocumentWithAcceptanceDateZod = witboostDocumentZod.extend(
  {
    acceptance_date: z.date().optional(),
  },
);

export type WitboostDocument = z.infer<typeof witboostDocumentZod>;

export type WitboostDocumentWithAcceptanceDate = z.infer<
  typeof witboostDocumentWithAcceptanceDateZod
>;
