import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import { TerminatedIcon as Terminated, TerminatedOutlineIcon } from './Icons';
import { IconLabelBox } from '../../IconLabelBox';

interface Props {
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const TerminatedIcon: React.FC<Props> = ({ style, variant }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Tooltip title="The execution has been terminated">
      <span>
        {variant === 'reduced' ? (
          <Terminated className={`${classes.icon}`} style={style} />
        ) : (
          <IconLabelBox
            label="Terminated"
            backgroundColor={theme.palette.blueGrey[200]}
            icon={
              <TerminatedOutlineIcon
                style={style}
                htmlColor={theme.palette.black}
              />
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
