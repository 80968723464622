import { configApiRef, useApi, useApiHolder } from '@backstage/core-plugin-api';
import { CircularProgress } from '@material-ui/core';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  ActionsContextProvider,
  DataRootPathContextProvider,
} from '../../context';
import { useCustomPresentation } from '../../hooks';
import { buildJsx } from '../../utils';
import { CustomViewCtrls } from './ctrls';
import { CustomViewPages } from './pages';
import { CustomViewRegister } from './registry';
import { extractIncludeFromCode } from './utils';
import MyErrorBoundary from './ErrorBoundary';
import { customAlertApiRef } from '../../apis';
import { CustomError } from '@agilelab/plugin-wb-platform-common';
import { ErrorPanel } from '@backstage/core-components';

type Props = {
  id: string;
  typeId?: string;
  templateId?: string;
  data: any;
  actions?: Record<string, Function>;
  transform?: string;
  loader?: ReactNode;
};

export const CustomViewComponentContent = (props: Record<string, any>) => {
  const subComponent = CustomViewRegister.get(props.type);
  if (subComponent) return subComponent(props);
  throw new Error(`${props.type} type not found `);
};

export const CustomViewComponent = (props: Record<string, any>) => {
  const alertApi = useApi(customAlertApiRef);
  const [customViewError, setCustomViewError] = useState<Error>();

  useEffect(() => {
    if (customViewError) {
      alertApi.post({
        error: new CustomError(
          'Page configuration error',
          'Something went wrong with custom view configuration',
          `${customViewError.message} for ${props.label} `,
        ),
        severity: 'error',
      });
    }
  }, [alertApi, customViewError, props.label]);

  return (
    <MyErrorBoundary onError={setCustomViewError}>
      <CustomViewComponentContent {...props} />
    </MyErrorBoundary>
  );
};

export const CustomView = ({
  id,
  typeId,
  templateId,
  data,
  actions,
  transform,
  loader,
}: Props) => {
  const Children: any = CustomViewPages.get(id);
  const included = extractIncludeFromCode(
    Children!() as ReactElement,
    typeId,
    templateId,
  );
  const transformFn =
    CustomViewCtrls.get(transform || id, typeId, templateId) || ((a: any) => a);
  const apis = {
    configApi: useApi(configApiRef),
    apiHolder: useApiHolder(),
  };
  const view = useCustomPresentation(id, typeId, templateId, included);
  const formattedData = transformFn(data, apis); // TOCHECK: use useMemo/useAsync??

  if (view.loading) {
    return loader ?? <CircularProgress />; // TOVERIFY: show loader?
  }

  if (view.error) return <ErrorPanel error={view.error} />;

  const realActions = Object.assign(
    {
      getCustomViewInfo: () => ({ id, typeId, templateId }),
      getInclude: (incId: string, incTypeId: string, incTemplateId: string) =>
        buildJsx(
          CustomViewComponent,
          (
            view.value?.included.find(
              (e: any) =>
                e.id === incId &&
                e.typeId === incTypeId &&
                e.templateId === incTemplateId,
            ) || {}
          ).code,
        ),
    },
    actions || {},
  );

  return (
    <ActionsContextProvider actions={realActions}>
      <DataRootPathContextProvider data={formattedData}>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div style={{ flex: 1 }}>
            {buildJsx(CustomViewComponent, view.value.view) || <Children />}
          </div>
        </div>
      </DataRootPathContextProvider>
    </ActionsContextProvider>
  );
};
