import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const NotStartedBugIcon = createSvgIcon(
  <>
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.75 15.375H11.25V12.625H16.75V15.375ZM22.25 14.0308C19.3895 14.2668 16.9082 15.8405 15.4334 18.125H11.25V20.875H14.2523C14.0874 21.5563 14 22.268 14 23C14 24.7012 14.472 26.2921 15.2921 27.649C14.8714 27.7155 14.4398 27.75 14 27.75C10.9475 27.75 8.29375 26.0863 6.86375 23.625H3V20.875H5.87375C5.805 20.4213 5.75 19.9675 5.75 19.5V18.125H3V15.375H5.75V14C5.75 13.5325 5.805 13.0788 5.87375 12.625H3V9.875H6.86375C7.4825 8.8025 8.335 7.88125 9.3525 7.18L7.125 4.93875L9.06375 3L12.0613 5.98375C13.3398 5.67359 14.674 5.67359 15.9525 5.98375L18.9363 3L20.875 4.93875L18.6338 7.18C19.6605 7.87805 20.5161 8.79939 21.1363 9.875H25V12.625H22.1263C22.195 13.0788 22.25 13.5325 22.25 14V14.0308ZM20.27 29.4487C21.1217 29.8162 22.0317 30 23 30C23.9683 30 24.8783 29.8162 25.73 29.4487C26.5817 29.0812 27.3225 28.5825 27.9525 27.9525C28.5825 27.3225 29.0812 26.5817 29.4487 25.73C29.8162 24.8783 30 23.9683 30 23C30 22.0317 29.8162 21.1217 29.4487 20.27C29.0812 19.4183 28.5825 18.6775 27.9525 18.0475C27.3225 17.4175 26.5817 16.9187 25.73 16.5513C24.8783 16.1838 23.9683 16 23 16C22.0317 16 21.1217 16.1838 20.27 16.5513C19.4183 16.9187 18.6775 17.4175 18.0475 18.0475C17.4175 18.6775 16.9187 19.4183 16.5513 20.27C16.1838 21.1217 16 22.0317 16 23C16 23.9683 16.1838 24.8783 16.5513 25.73C16.9187 26.5817 17.4175 27.3225 18.0475 27.9525C18.6775 28.5825 19.4183 29.0812 20.27 29.4487ZM26.5 23.7H19.5V22.3H26.5V23.7Z"
        fill="currentColor"
      />
    </svg>
  </>,
  'NotStartedBugIcon',
);
