import { Box, Divider, useTheme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

interface Props {
  orientation?: 'vertical' | 'horizontal';
  height?: string;
  className?: string;
  style?: React.CSSProperties;
}

export const WbDivider: React.FC<Props> = ({
  orientation = 'vertical',
  height,
  className,
  style,
}) => {
  const theme = useTheme();
  return orientation === 'vertical' ? (
    <Box
      className={clsx(className)}
      style={{
        background: theme.palette.grey[300],
        width: '1px',
        height: height ?? '24px',
        ...style,
      }}
    />
  ) : (
    <Divider className={clsx(className)} style={style} />
  );
};
