import { useQueries, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { practiceShaperApiRef } from '../plugin';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { extractEntityTypeLabel } from '../utils';
import { EntityTypeLabel } from '../types';

const CACHE_KEY = 'system type';

export const useSystemTypeLabel = (type: string) => {
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);

  const { data: systemType } = useQuery({
    queryKey: [CACHE_KEY, type],
    queryFn: async () => {
      return (
        await practiceShaperApi.getSystemTypes(
          {
            filter: { resourceTypeId: type },
          },
          { token: (await identityApi.getCredentials()).token },
        )
      ).items;
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    // refetches if the data is needed and 10 minutes have passed from the last fetch
    staleTime: 1000 * 60 * 10,
  });

  const kindLabel = useMemo(() => {
    const item = systemType?.[0];

    return extractEntityTypeLabel(item, type);
  }, [systemType, type]);

  return { kindLabel };
};

export const useSystemTypeLabels = (types: string[]) => {
  const identityApi = useApi(identityApiRef);
  const practiceShaperApi = useApi(practiceShaperApiRef);

  const systemTypes = useQueries({
    queries: types.map((type: string) => ({
      queryKey: [CACHE_KEY, type],
      queryFn: async () => {
        return (
          await practiceShaperApi.getSystemTypes(
            {
              filter: { resourceTypeId: type },
            },
            { token: (await identityApi.getCredentials()).token },
          )
        ).items;
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      // refetches if the data is needed and 10 minutes have passed from the last fetch
      staleTime: 1000 * 60 * 10,
    })),
  });

  const kindLabels = useMemo(() => {
    const labels: Record<string, EntityTypeLabel> = {};
    systemTypes
      .map(c => c.data)
      .flat()
      .forEach(item => {
        if (!item) return;
        const label = extractEntityTypeLabel(item, item.metadata.name);
        labels[item.metadata.name] = label;
      });
    return labels;
  }, [systemTypes]);

  return { kindLabels };
};
