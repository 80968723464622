import { gql } from '@apollo/client/core';

export const GET_RESOURCE_TYPES = gql`
  query GET_RESOURCE_TYPES($where: cgp_resource_type_bool_exp) {
    cgp_resource_type(where: $where) {
      uuid
      name
      displayName: display_name
    }
  }
`;
