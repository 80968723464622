export function getFileSizeFromBase64String(file: string): number {
  return Buffer.from(file, 'base64').length;
}

export const toMB = (sizeBytes: number): number => {
  return sizeBytes / 1024 / 1024;
};

export const printFileSize = (options: {
  sizeMegaBytes: number;
  sizeLimitMegaBytes?: number;
  digits?: number;
}): string => {
  const { sizeMegaBytes, sizeLimitMegaBytes, digits } = options;

  const roundedSize = sizeMegaBytes.toFixed(digits);
  const roundedLimit = sizeLimitMegaBytes?.toFixed(digits);
  if (roundedLimit && roundedLimit === roundedSize) {
    /**
     * This is to prevent cases in which the approximation error of the size limit and the uploaded file size match
     * Giving the user a wrong message
     */
    return `~${roundedSize}`;
  }
  return roundedSize;
};
