import { QuestionEntity } from '@agilelab/plugin-wb-marketplace-common';
import {
  WbCard,
  WbCardActionButton,
  WbCardContent,
  WbFieldLabel,
  customAlertApiRef,
} from '@agilelab/plugin-wb-platform';
import { Progress } from '@backstage/core-components';
import {
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import { CardActions, Collapse, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { dataProductRouteRef } from '../../routes';
import { useMarketplaceStyle } from '../../themes';
import { cloneArray } from '../../utils';
import {
  SystemContext,
  UserEntityContext,
} from '../../hooks/DataProductContext';
import EditIcon from '@material-ui/icons/Edit';
import { DataProductPageRoutes } from '../DataProductPage/DataProductPageRoutes';
import { QuestionDetails } from './QuestionDetails';
import { QuestionForm } from './QuestionForm';
import { notificationApiRef } from '@agilelab/plugin-wb-notification';
import { questionNotificationRequestDataZod } from '@agilelab/plugin-wb-notification-common';
import useAsyncFn from 'react-use/lib/useAsyncFn';

export function parseQuestions(data: any): QuestionEntity[] {
  if (!data) return [];
  return data.map((item: any) => {
    return {
      id: item.id,
      asked_by: item.notification_request.asked_by,
      asked_by_display_name: item.notification_request.asked_by_display_name,
      question: item.notification_request.question,
      timestamp: item.created_at,
      answer: item.notification_response,
    } as QuestionEntity;
  });
}

export function sortQuestions(questions: QuestionEntity[]): QuestionEntity[] {
  return cloneArray(questions).sort((a: QuestionEntity, b: QuestionEntity) =>
    b.timestamp > a.timestamp ? 1 : -1,
  );
}

export const QuestionsCard = () => {
  const commonClasses = useMarketplaceStyle();
  const userEntity = useContext(UserEntityContext);
  const identityApi = useApi(identityApiRef);

  const navigate = useNavigate();
  const alertApi = useApi(customAlertApiRef);

  const dpInstance = useContext(SystemContext);
  const dataProductRoute = useRouteRef(dataProductRouteRef);
  const [expanded, setExpanded] = useState(false);
  const [errorText, setErrorText] = useState('');
  const notificationCatalog = useApi(notificationApiRef);

  const [{ loading, error, value: data }, fetchQuestion] = useAsyncFn(
    async () => {
      return notificationCatalog.getNotificationByDataProductInstanceID(
        dpInstance.id.toString(),
        'Question',
        await identityApi.getCredentials(),
      );
    },
  );

  useEffect(() => {
    fetchQuestion();
  }, [dpInstance.id, fetchQuestion]);

  const questions = useMemo(() => sortQuestions(parseQuestions(data)), [data]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  if (loading) {
    return <Progress />;
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>;
  }

  const isUserOwner = (userEntity?.username || '') === dpInstance.owner;
  const canUserMakeQuestion = !isUserOwner;
  const noQuestionsYet = questions.length === 0;

  const latestThreeQuestions =
    questions.length >= 3 ? questions.slice(0, 3) : questions;

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorText('');

    const formData = new FormData(e.currentTarget);

    if (!userEntity?.username) {
      setErrorText('An internal error occurred. Try again later.');
      return;
    }

    const questionBody = formData.get('new-question-body')?.toString() || '';
    if (questionBody.length <= 5) {
      setErrorText('Your question is too short.');
      return;
    }

    if (questionBody.length > 130) {
      setErrorText(
        'Please be more concise when asking a question. A shorter question can be more effective!',
      );
      return;
    }

    const notification_request = {
      question: questionBody,
      asked_by: userEntity.username,
      asked_by_display_name: userEntity.displayName,
      id_dataproduct_instance: dpInstance.id,
      dataproduct: {
        id: dpInstance.id,
        urn: dpInstance.external_id,
        display_name: dpInstance.display_name,
        name: dpInstance.name,
        domain: dpInstance.domains.at(0)?.data.name,
        version: dpInstance.version,
        environment: dpInstance.environment.name,
      },
    };

    try {
      questionNotificationRequestDataZod.parse(notification_request);
      await notificationCatalog.addNotificationRequest(
        userEntity.username,
        dpInstance.owner,
        null,
        'Question',
        notification_request,
        await identityApi.getCredentials(),
      );
      setExpanded(false); // close form on submission success
      fetchQuestion();
      alertApi.post({
        message: 'Question sent, thank you!',
        severity: 'success',
        timeoutMillis: 5000,
      });
    } catch (err) {
      setErrorText(
        `Something went wrong. try again later! Error: ${err.message}`, // TODO should user know the error details?
      );
    }
  };

  return (
    <WbCard
      title="Questions"
      footer={
        !noQuestionsYet || canUserMakeQuestion ? (
          <>
            <CardActions>
              {!noQuestionsYet && (
                <WbCardActionButton
                  style={{ alignSelf: 'self-start' }}
                  label="See all Q&A"
                  variant="text"
                  onClick={() => {
                    navigate(
                      dataProductRoute({ id: dpInstance.id.toString() }) +
                        DataProductPageRoutes.QUESTIONS_ROUTE,
                    );
                  }}
                />
              )}
              {canUserMakeQuestion && (
                <WbCardActionButton
                  style={{ alignSelf: 'end' }}
                  label="Ask a question"
                  variant="contained"
                  startIcon={<EditIcon fontSize="small" />}
                  onClick={handleExpandClick}
                />
              )}
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {userEntity && (
                <QuestionForm
                  askedByDisplayName={userEntity.displayName}
                  onCancel={handleExpandClick}
                  onSubmit={onSubmit}
                  errorText={errorText}
                />
              )}
            </Collapse>
          </>
        ) : undefined
      }
    >
      <WbCardContent>
        <WbFieldLabel label="Last Q&A" />

        {latestThreeQuestions.length > 0 ? (
          <div>
            {latestThreeQuestions.map(question => (
              <QuestionDetails key={question.id} question={question} />
            ))}
          </div>
        ) : (
          <>
            <br />
            <Typography className={commonClasses.text}>
              No questions yet
            </Typography>
          </>
        )}
      </WbCardContent>
    </WbCard>
  );
};
