import {
  ProviderFilters,
  SearchFilter,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import useDomainContext from './DomainProvider';
import React from 'react';

export interface DomainFilters extends ProviderFilters {}

export const DomainFiltersSection = () => {
  const { filters, changeFilters, resetFilters, templateKind } =
    useDomainContext();

  const filterElement = (key: string) => {
    if (key === 'search')
      return (
        <SearchFilter
          key={key}
          value={filters.search ?? ''}
          changeFilters={changeFilters}
        />
      );

    return <></>;
  };

  return (
    <WbTableFilters onClear={resetFilters}>
      {templateKind?.userFilters?.map(filter => filterElement(filter))}
    </WbTableFilters>
  );
};
