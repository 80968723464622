import { Box, makeStyles, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import { Severity } from '@agilelab/plugin-wb-governance-common';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/WarningOutlined';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

interface Props {
  severity: Severity;
  style?: React.CSSProperties;
}

interface SeverityProps {
  label: string;
  icon: React.ReactElement;
  color: string;
  style?: React.CSSProperties;
}

const SeverityElement: React.FC<SeverityProps> = ({ label, icon }) => {
  return (
    <>
      {icon}
      <Typography style={{ fontSize: 12 }}>{label}</Typography>
    </>
  );
};

export const GovernanceRegistryPoliciesSeverity: React.FC<Props> = ({
  severity,
  style,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const severityComponent: Record<Severity, React.ReactElement> = {
    [Severity.Error]: (
      <SeverityElement
        label="Error"
        icon={<ErrorIcon />}
        color={theme.palette.error.main}
      />
    ),
    [Severity.Info]: (
      <SeverityElement
        label="Info"
        icon={<InfoIcon />}
        color={theme.palette.info.main}
      />
    ),
    [Severity.Warning]: (
      <SeverityElement
        label="Warning"
        icon={<WarningIcon />}
        color={theme.palette.warning.main}
      />
    ),
  };

  return (
    <Box
      color={theme.palette[severity].main}
      className={classes.container}
      style={style}
    >
      {severityComponent[severity]}
    </Box>
  );
};
