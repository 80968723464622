import { createTopic } from '../lib';
import { z } from 'zod';

const marketplaceSystemNavigatedEventSchema = z.object({
  entityUrn: z.string(),
  path: z.string(),
});

export type MarketplaceSystemNavigatedEvent = z.infer<
  typeof marketplaceSystemNavigatedEventSchema
>;

export const marketplaceSystemNavigatedTopic =
  createTopic<MarketplaceSystemNavigatedEvent>(
    {
      namespace: 'marketplace',
      name: 'system-navigated',
      description: 'User navigated to a system in the marketplace.',
    },
    marketplaceSystemNavigatedEventSchema,
  );
