import {
  TableContentLoader,
  WbSelect,
  WbTable,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { DeploymentPreview } from '../types';
import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ComponentDependencies } from '../ProvisioningCard/ComponentsTable/ComponentDependencies';
import { DeploymentPreviewChip } from './DeploymentPreviewChip';
import {
  Action,
  DeploymentUnitStatus,
  WitboostSubcomponent,
} from '@agilelab/plugin-wb-builder-common';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { SubComponentRow } from './SubComponentRow';

interface Props {
  rows: Array<DeploymentPreview>;
  loading: boolean;
  enableCleanUpMultiSelection?: boolean;
  setCleanUpPreviews?: React.Dispatch<
    React.SetStateAction<DeploymentPreview[]>
  >;
}

const useStyles = makeStyles(theme => ({
  selectInput: {
    height: '36px',
    minHeight: 'auto',
    fontSize: theme.typography.body1.fontSize,
  },
  selectTextField: {
    minHeight: 0,
  },
}));

export const DeploymentPreviewTable: React.FC<Props> = ({
  rows,
  loading,
  enableCleanUpMultiSelection,
  setCleanUpPreviews,
}) => {
  const theme = useTheme();
  const headStyle = { style: { fontWeight: 500 } };
  const classes = useStyles();

  return (
    <WbTable
      stickyHeader
      components={{
        tableLoader: {
          loading: false,
        },
        tableHeader: (
          <TableHead>
            <TableRow>
              <TableCell {...headStyle}>Name</TableCell>
              <TableCell {...headStyle}>Type</TableCell>
              <TableCell {...headStyle}>Dependencies</TableCell>
              <TableCell {...headStyle}>Current State</TableCell>
              <TableCell {...headStyle}>New State</TableCell>
              <TableCell {...headStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
        ),
        tableContent: {
          body: (
            <TableBody>
              {loading && <TableContentLoader rows={10} tableCells={6} />}

              {!loading &&
                rows.map((preview, i) => {
                  return (
                    <>
                      <TableRow key={i}>
                        <TableCell>
                          <Typography
                            variant="body2"
                            style={{ fontWeight: 700 }}
                          >
                            {preview.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {snakeCaseToTitleCase(preview.kind)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <ComponentDependencies preview={preview} />
                        </TableCell>
                        <TableCell>
                          <DeploymentPreviewChip
                            status={preview.status}
                            version={preview.statusVersion}
                          />
                        </TableCell>
                        <TableCell>
                          <Box
                            style={{
                              display: 'flex',
                              gap: '16px',
                              alignItems: 'center',
                              marginLeft: '-32px',
                            }}
                          >
                            <ArrowForwardIcon
                              htmlColor={theme.palette.accent.main}
                            />
                            <DeploymentPreviewChip
                              status={
                                preview.newState as unknown as DeploymentUnitStatus
                              }
                            />
                          </Box>
                        </TableCell>
                        {enableCleanUpMultiSelection ? (
                          <TableCell
                            style={{ maxWidth: 300, minWidth: 300, width: 300 }}
                          >
                            <WbSelect
                              value={preview.action}
                              className={classes.selectTextField}
                              onChange={e => {
                                const value = e.target.value;
                                preview.action = value as Action;
                                setCleanUpPreviews?.(previews => {
                                  const indexPreview = previews.findIndex(
                                    p => p.id === preview.id,
                                  );
                                  if (indexPreview !== -1) {
                                    previews[indexPreview] = preview;
                                    return [...previews];
                                  }
                                  return [...previews, preview];
                                });
                              }}
                              options={
                                !preview.action ||
                                preview.action === Action.UNDEPLOY ||
                                preview.action ===
                                  Action.UNDEPLOY_AND_REMOVE_DATA
                                  ? [
                                      Action.UNDEPLOY,
                                      Action.UNDEPLOY_AND_REMOVE_DATA,
                                    ]
                                  : [preview.action]
                              }
                              getOptionLabel={value =>
                                snakeCaseToTitleCase(value)
                              }
                              InputProps={{
                                className: classes.selectInput,
                              }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell>
                            <Typography
                              variant="body2"
                              style={{ fontWeight: 700 }}
                            >
                              {preview.action &&
                                snakeCaseToTitleCase(preview.action)}
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>

                      {(preview.components || []).map(
                        (subComponent: WitboostSubcomponent) => (
                          <SubComponentRow
                            keyProp={subComponent.name}
                            subComponent={subComponent}
                            componentPreview={preview}
                          />
                        ),
                      )}
                    </>
                  );
                })}
            </TableBody>
          ),
        },
      }}
      styles={{
        container: { height: '100%' },
      }}
    />
  );
};
