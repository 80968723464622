import React from 'react';
import { Tooltip, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import { NotStartedIcon as NotStarted } from './Icons';
import { IconLabelBox } from '../../IconLabelBox';
import RemoveOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

interface Props {
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

export const NotStartedIcon: React.FC<Props> = ({
  style,
  variant = 'default',
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Tooltip title="At least one error was encountered in this execution due to a previous policy, so this check was not executed at all">
      <span>
        {variant === 'reduced' ? (
          <NotStarted className={`${classes.icon}`} style={style} />
        ) : (
          <IconLabelBox
            label="Not Started"
            backgroundColor={theme.palette.blueGrey[200]}
            icon={
              <RemoveOutlineIcon
                style={style}
                htmlColor={theme.palette.black}
              />
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
