import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-external-resources',
});

export const packageRouteRef = createSubRouteRef({
  id: 'wb-external-resources:package',
  parent: rootRouteRef,
  path: '/package/:packageId',
});

export const createPackageRouteRef = createSubRouteRef({
  id: 'wb-external-resources:create-package',
  parent: rootRouteRef,
  path: '/create-package',
});

export const dashboardPackageRouteRef = createSubRouteRef({
  id: 'wb-external-resources:dashboard',
  parent: rootRouteRef,
  path: '/dashboard',
});
