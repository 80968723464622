import { z } from 'zod';

import {
  Context,
  Engine,
  InteractionType,
  ResultType,
  Strategy,
  Timing,
  Trigger,
  ThresholdExpression,
} from '../../enums';

export const MetricEditRequest = z.object({
  metricId: z.string(),
  groupId: z.string(),
  name: z.string(),
  description: z.string().optional(),
  context: z.nativeEnum(Context),
  strategy: z.nativeEnum(Strategy),
  timing: z.nativeEnum(Timing),
  interactionType: z.nativeEnum(InteractionType),
  resourceType: z.string(),
  resultType: z.nativeEnum(ResultType),
  version: z.number(),
  externalUrl: z.string().optional(),
  selectors: z.array(
    z.object({
      description: z.string(),
      path: z.string(),
      values: z.array(z.string()),
    }),
  ),
  tags: z.array(z.string()),
  environments: z.array(z.string()),
  cronExpression: z.string().optional(),
  preprocessing: z.array(z.string()).optional(),
  engine: z.nativeEnum(Engine),
  thresholds: z.object({
    expression: z.nativeEnum(ThresholdExpression),
    range: z
      .array(
        z.object({
          value: z.number(),
          labelColor: z.string(),
          label: z.string(),
        }),
      )
      .optional(),
  }),
  domain: z.object({
    lowerBound: z.number(),
    upperBound: z.number(),
  }),
  additionalMetadata: z.string(),
  trigger: z.nativeEnum(Trigger),
});

export type MetricEdit = z.infer<typeof MetricEditRequest>;
