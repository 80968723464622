import { createTopic } from '../lib';
import { z } from 'zod';

const entityDocRenderedEventSchema = z.object({
  entityRef: z.string(),
});

export type EntityDocRenderedEvent = z.infer<
  typeof entityDocRenderedEventSchema
>;

export const entityDocRenderedTopic = createTopic<EntityDocRenderedEvent>(
  {
    namespace: 'catalog',
    name: 'entity-doc-rendered',
    description: 'Docs for a system/component have been rendered',
  },
  entityDocRenderedEventSchema,
);
