/*
 * This class is copied verbatim from Backstage 1.24.0.
 */

import React from 'react';
import {
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';

/**
 * Props for {@link PreparePullRequestForm}.
 *
 * @public
 */
export type PreparePullRequestFormProps<
  TFieldValues extends Record<string, any>,
> = Pick<UseFormProps<TFieldValues>, 'defaultValues'> & {
  onSubmit: SubmitHandler<TFieldValues>;

  render: (
    props: Pick<
      UseFormReturn<TFieldValues>,
      'formState' | 'register' | 'control' | 'setValue'
    > & {
      values: TFieldValues;
    },
  ) => React.ReactNode;
};

/**
 * A form wrapper that creates a form that is used to prepare a pull request. It
 * hosts the form logic.
 *
 * @param defaultValues - the default values of the form
 * @param onSubmit - a callback that is executed when the form is submitted
 *   (initiated by a button of type="submit")
 * @param render - render the form elements
 * @public
 */
export const PreparePullRequestForm = <
  TFieldValues extends Record<string, any>,
>(
  props: PreparePullRequestFormProps<TFieldValues>,
) => {
  const { defaultValues, onSubmit, render } = props;

  const methods = useForm<TFieldValues>({ mode: 'onTouched', defaultValues });
  const { handleSubmit, watch, control, register, formState, setValue } =
    methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {render({
          values: watch() as TFieldValues,
          formState,
          register,
          control,
          setValue,
        })}
      </form>
    </FormProvider>
  );
};
