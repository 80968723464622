import React from 'react';

export const useEditorScroll = (scrollContainerClassname: string) => {
  const scroll = React.useCallback(
    (line: number, id: string) => {
      setTimeout(() => {
        const parentElement = document.querySelector(
          `#${id}`,
        ) as HTMLElement | null;
        const editorScrollerElement = parentElement
          ?.getElementsByClassName(scrollContainerClassname)
          .item(0);
        editorScrollerElement?.scroll({
          behavior: 'smooth',
          top: (line - 1) * 19.59,
        });
      }, 100);
    },
    [scrollContainerClassname],
  );
  return scroll;
};
