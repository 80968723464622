import { Entity, EntityMeta } from '@backstage/catalog-model';
import { JsonObject } from '@backstage/types';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/AccessControlRequestTemplate.v1alpha1.schema.json';
import { TemplateStep } from './types';
import { AclVerb } from '@agilelab/plugin-wb-access-control-common';

declare interface AccessControlRequestTemplateEntity extends Entity {
  apiVersion: 'witboost.com/v1';
  kind: 'AccessControlRequestTemplate';
  metadata: EntityMeta;
  spec: {
    type: AclVerb;
    owner?: string;
    /**
     * JSONSchemas which is used to render a form in the frontend
     * to collect user input and validate it against that schema.
     */
    parameters?: JsonObject[];
    steps: Array<TemplateStep>;
  };
}

export type { AccessControlRequestTemplateEntity as AccessControlRequestTemplate };

export const accessControlRequestTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
