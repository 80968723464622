import {
  GuardianPolicyResultDetailsSchema,
  InferredComplianceValidation,
  ParsedGuardianPolicyResultDetail,
  Validation,
} from '../types';

/**
 * Given an array of validations, traverse the children of each parent to determine the parent compliance status
 * (only a parent with a full compliant subtree will be considered compliant)
 * @param validations
 * @returns the enriched validation array
 */
export const inferParentComplianceStatus = (validations: Validation[]) => {
  const dfs = (validation: Validation): InferredComplianceValidation => {
    let compliant = validation.compliant ?? true;
    return {
      ...validation,
      children: validation.children?.map(c => {
        const enriched = dfs(c);
        compliant = compliant && enriched.compliant;
        return enriched;
      }),
      compliant,
    };
  };

  return validations.map(dfs);
};

export const parseGuardianPolicyResult = (
  result: unknown,
  allowedTargets?: string[],
): ParsedGuardianPolicyResultDetail => {
  const parsed = GuardianPolicyResultDetailsSchema.parse(result);
  const allowedTargetsSet = new Set(allowedTargets ?? []);

  return {
    ...parsed,
    results: Object.fromEntries(
      // map each result to an object containing the inferred validations and the compliant status (inferred by the validations)
      Object.entries(parsed.results)
        // filter out the targets that are not allowed if an allowedTargets array is provided
        .filter(([target]) => !allowedTargets || allowedTargetsSet.has(target))
        .map(([target, validations]) => {
          const inferredValidations = inferParentComplianceStatus(validations);
          return [
            target,
            {
              validations: inferredValidations,
              compliant: validations.every(v => v.compliant),
            },
          ];
        }),
    ),
  };
};
