import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

import EditIcon from '@material-ui/icons/Edit';
import CachedIcon from '@material-ui/icons/Cached';
import { DeployAgainIcon } from './DeployAgainIcon';
import { UndeployIcon } from './UndeployIcon';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '24px',
    padding: '16px',
    boxShadow: '0px 4px 4px 0px #0000001F',
    background: theme.palette.grey[50],
  },
  icon: {
    fontSize: '64px',
    color: theme.palette.softened.primary,
  },
  descriptionContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '16px',
  },
}));

type QuickLinkType =
  | 'DEPLOY_AGAIN'
  | 'UNDEPLOY_ALL'
  | 'RESTORE_LATEST_RELEASE'
  | 'GO_TO_EDITOR';

interface Props {
  type: QuickLinkType;
  onClick?: () => void;
}

interface QuickLinkCardProps {
  label: string;
  icon: JSX.Element;
  description: string;
  action: string;
  onClick?: () => void;
}

export const QuickLinkCard: React.FC<QuickLinkCardProps> = ({
  label,
  icon,
  description,
  action,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Typography variant="body1" style={{ fontWeight: 700 }}>
        {label}
      </Typography>
      {React.cloneElement(icon, { className: classes.icon })}
      <Box className={classes.descriptionContainer}>
        <Typography align="center">{description}</Typography>
        <Button
          size="small"
          onClick={onClick}
          variant="contained"
          color="primary"
        >
          {action}
        </Button>
      </Box>
    </Box>
  );
};

const linkTypeToComponent: Record<
  QuickLinkType,
  (onClick: Props['onClick']) => JSX.Element
> = {
  DEPLOY_AGAIN: onClick => (
    <QuickLinkCard
      label="Deploy again this release"
      icon={<DeployAgainIcon />}
      description="Check and correct for errors and try to deploy this release again."
      action="Deploy again"
      onClick={onClick}
    />
  ),
  UNDEPLOY_ALL: onClick => (
    <QuickLinkCard
      label="Undeploy"
      icon={<UndeployIcon />}
      description="Reset the release to a not-deployed state, and try another provisioning."
      action="Undeploy"
      onClick={onClick}
    />
  ),
  RESTORE_LATEST_RELEASE: onClick => (
    <QuickLinkCard
      label="Restore last successful release"
      icon={<CachedIcon />}
      description="Select this option to restore the latest successful and stable release."
      action="Restore latest release"
      onClick={onClick}
    />
  ),
  GO_TO_EDITOR: onClick => (
    <QuickLinkCard
      label="Edit or create draft"
      icon={<EditIcon />}
      description="Open the editor tab to update a release or create a new draft."
      action="Go to Editor"
      onClick={onClick}
    />
  ),
};

export const QuickLink: React.FC<Props> = ({ type, onClick }) => {
  return <>{linkTypeToComponent[type](onClick)}</>;
};
