import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/SystemType.v2alpha1.schema.json';

type SystemTypeEntity = Entity &
  ProjectType & {
    apiVersion: 'witboost.com/v2';
    kind: 'SystemType';
    metadata: {
      displayName?: string;
      pluralizedDisplayName?: string;
      displayPriority?: number;
    } & EntityMeta;
    spec: {
      consumable?: boolean;
      shoppable?: boolean;
      abstract: boolean;
      belongsTo: string;
      partOfDomain: string;
      partiallyDeployableIn?: string[];
      resourceTypeId: string;
      extends?: string;
      ownedBy?: string;
    };
  };

export type { SystemTypeEntity as SystemType };

export const systemTypeEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
