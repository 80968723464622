import React from 'react';
import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { EvaluationResultSpecific } from '@agilelab/plugin-wb-governance-common';
import {
  CodeField,
  GenericField,
  WbCardContent,
  WbWidget,
} from '@agilelab/plugin-wb-platform';
import { OutcomeField } from '../../common/Field/OutcomeField';
import { roundWithoutApprossimation } from '../../../utils/governanceUtils';

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    width: '100%',
    gap: 50,
    flexWrap: 'wrap',
  },
}));

interface Props {
  result: EvaluationResultSpecific;
}

export const GovernanceTestOverviewInfo: React.FC<Props> = ({ result }) => {
  const classes = useStyles();

  return (
    <WbWidget title="Info">
      <WbCardContent>
        <Box className={classes.row}>
          <GenericField
            label="Start Test Date"
            value={result.startTestDate.toISOString()}
            type="date"
          />
          <GenericField
            label="End Test Date"
            value={result.endTestDate.toISOString()}
            type="date"
          />
          {result.truePolicy && (
            <GenericField
              label="Result Status"
              value={<OutcomeField outcome={result.outcome} />}
            />
          )}
          {result.metric && (
            <Tooltip title={result.result?.value || '-'}>
              <Box>
                <GenericField
                  label="Value"
                  value={roundWithoutApprossimation(2, result.result?.value)}
                />
              </Box>
            </Tooltip>
          )}
          {result.metric && (
            <GenericField
              label="Label"
              value={result.result?.thresholdResult?.label || 'No Label'}
            />
          )}
          {result.result?.details && (
            <GenericField
              label="Test Detail"
              value={
                <CodeField
                  title="Test Detail"
                  value={JSON.stringify(result.result.details, null, 4)}
                />
              }
            />
          )}
        </Box>
      </WbCardContent>
    </WbWidget>
  );
};
