import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { userHeadersApiRef } from '@agilelab/plugin-wb-user-headers';
import {
  UserHeader,
  UserHeadersApi,
} from '@agilelab/plugin-wb-user-headers-common';
import {
  IdentityApi,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { CircularProgress, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { HeadersPicker } from './HeadersPicker';

const REDACTED_KEYWORD = '[REDACTED]';

async function loadUserHeaders(
  identityApi: IdentityApi,
  userHeadersApi: UserHeadersApi,
): Promise<UserHeader[]> {
  const { token } = await identityApi.getCredentials();
  return userHeadersApi.getUserHeaders({
    token: token!,
    redactWith: REDACTED_KEYWORD,
  });
}

async function saveUserHeaders(
  identityApi: IdentityApi,
  userHeadersApi: UserHeadersApi,
  userHeaders: UserHeader[],
): Promise<void> {
  const { token } = await identityApi.getCredentials();
  await userHeadersApi.storeUserHeaders({
    token: token!,
    userHeaders: userHeaders,
  });
}

async function deleteUserHeaders(
  identityApi: IdentityApi,
  userHeadersApi: UserHeadersApi,
  userHeadersToDelete: string[],
): Promise<void> {
  const { token } = await identityApi.getCredentials();
  await userHeadersApi.removeUserHeaders({
    token: token!,
    userHeadersToRemove: userHeadersToDelete,
  });
}

export function UserHeadersSettings() {
  const userHeadersApi = useApi(userHeadersApiRef);
  const identityApi = useApi(identityApiRef);
  const alertApi = useApi(customAlertApiRef);

  const {
    loading,
    error: fetchError,
    value: initialUserHeaders,
  } = useAsync(async () => {
    return loadUserHeaders(identityApi, userHeadersApi);
  }, [identityApi, userHeadersApi]);

  useEffect(() => {
    if (fetchError)
      alertApi.post({
        message: fetchError.message,
        severity: 'error',
      });
  }, [alertApi, fetchError]);

  const [isSaving, setIsSaving] = useState(false);

  const saveHeaders = async (updatedUserHeaders: UserHeader[]) => {
    setIsSaving(true);
    let success = false;
    try {
      await saveUserHeaders(identityApi, userHeadersApi, updatedUserHeaders);
      success = true;
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
    return { success };
  };

  const deleteHeaders = async (headersToDelete: string[]) => {
    setIsSaving(true);
    let success = false;
    try {
      await deleteUserHeaders(identityApi, userHeadersApi, headersToDelete);
      success = true;
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsSaving(false);
    }
    return { success };
  };

  return userHeadersApi.areUserHeadersEnabled() ? (
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12}>
        {loading ? (
          <CircularProgress />
        ) : (
          <HeadersPicker
            prefix={userHeadersApi.getUserHeadersPrefix()}
            initialUserHeaders={initialUserHeaders!}
            isSaving={isSaving}
            onHeadersSaved={saveHeaders}
            onHeadersDeleted={deleteHeaders}
          />
        )}
      </Grid>
    </Grid>
  ) : (
    <></>
  );
}
