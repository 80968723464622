import { Entity } from '@backstage/catalog-model';
import React from 'react';
import { Alert } from '@material-ui/lab';
import {
  ConsistencyStatus,
  ENTITY_KIND_PATH,
  ENTITY_NAME_PATH,
} from '@agilelab/plugin-wb-builder-common';
import lodash, { capitalize } from 'lodash';
import { useEntity } from '@backstage/plugin-catalog-react';

export function hasReservedFieldsError(entity: Entity): boolean {
  return (
    !!entity &&
    [
      ConsistencyStatus.DuplicatedInvalid,
      ConsistencyStatus.DuplicatedOriginal,
    ].includes(
      entity.metadata?.annotations?.[
        'witboost/reservedFieldsStatus'
      ] as ConsistencyStatus,
    ) &&
    !!buildReservedFieldError(entity)
  );
}

export function buildReservedFieldError(entity: Entity): string | undefined {
  const messageComponents = [];
  const kind = lodash.get(entity, ENTITY_KIND_PATH);
  const name = lodash.get(entity, ENTITY_NAME_PATH);

  if (kind && kind.length > 0) {
    messageComponents.push(`kind: '${capitalize(kind)}'`);
  }
  if (name && name.length > 0) {
    messageComponents.push(`name: '${name}'`);
  }
  if (messageComponents.length === 0) {
    return undefined;
  }

  return `This entity has been duplicated by editing its yaml descriptor.
    Please restore the original yaml descriptor by setting ${messageComponents.join(
      ' and ',
    )}.`;
}

export function ReservedFieldsError(): JSX.Element {
  const { entity } = useEntity();
  const message = buildReservedFieldError(entity);
  return message ? <Alert severity="error">{message}</Alert> : <></>;
}
