import { useElementFilter } from '@backstage/core-plugin-api';
import { resolvePath } from 'react-router';
import { WbSidebarSubmenuProps } from './WbSidebarSubmenu';
import { WbSidebarSubmenuItemProps } from './WbSidebarSubmenuItem';
import type { Location } from 'history';

export const isLinkActive = (
  location: string,
  to: string,
  caseSensitive?: boolean,
  exact?: boolean,
  extraActivationPaths: string[] = [],
  excludedActivationPaths: string[] = [],
) => {
  let locationPathname = location;

  let activationPaths = [
    ...extraActivationPaths.map(p => resolvePath(p).pathname),
    to,
  ];

  let excludeds = excludedActivationPaths.map(p => resolvePath(p).pathname);

  if (!caseSensitive) {
    locationPathname = locationPathname.toLocaleLowerCase('en-US');
    activationPaths = activationPaths.map(path =>
      path.toLocaleLowerCase('en-US'),
    );
    excludeds = excludeds.map(path => path.toLocaleLowerCase('en-US'));
  }

  if (
    excludeds.some(
      excluded =>
        locationPathname === excluded ||
        (!exact && locationPathname.startsWith(excluded)),
    )
  )
    return false;

  return activationPaths.some(
    path =>
      locationPathname === path ||
      (!exact && locationPathname.startsWith(path)),
  );
};

/**
 * Evaluates the routes of the SubmenuItems & nested DropdownItems.
 * The reevaluation is only triggered, if the `locationPathname` changes, as `useElementFilter` uses memorization.
 *
 * @param submenu SidebarSubmenu component
 * @param location Location
 * @returns boolean
 */
export const useLocationMatch = (
  submenu: React.ReactElement<WbSidebarSubmenuProps>,
  location: Location,
): boolean =>
  useElementFilter(
    submenu.props.children,
    elements => {
      let active = false;
      elements
        .getElements()
        .forEach(
          ({
            props: { to, dropdownItems },
          }: {
            props: Partial<WbSidebarSubmenuItemProps>;
          }) => {
            if (!active) {
              if (dropdownItems?.length) {
                dropdownItems.forEach(
                  ({ to: _to }) =>
                    (active =
                      active ||
                      isLinkActive(
                        location.pathname,
                        resolvePath(_to).pathname,
                      )),
                );
                return;
              }
              if (to) {
                active = isLinkActive(
                  location.pathname,
                  resolvePath(to).pathname,
                );
              }
            }
          },
        );
      return active;
    },
    [location.pathname],
  );
