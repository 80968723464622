import { createTopic } from '../lib';
import { z } from 'zod';

const creationTemplateNavigatedEventSchema = z.object({
  templateRef: z.string(),
});

export type CreationTemplateNavigatedEvent = z.infer<
  typeof creationTemplateNavigatedEventSchema
>;

export const creationTemplateNavigatedTopic =
  createTopic<CreationTemplateNavigatedEvent>(
    {
      namespace: 'builder',
      name: 'creation-template-navigated',
      description: 'User navigated to creation template page',
    },
    creationTemplateNavigatedEventSchema,
  );
