import React from 'react';
import {
  CustomViewRegister,
  DataRootPathContextProvider,
  useDataPath,
} from '@agilelab/plugin-wb-platform';
import { Schema, SchemaList } from '@agilelab/plugin-wb-marketplace-common';

CustomViewRegister.register({
  id: 'schema_list',
  function: function CreateSequenceView(props: Record<string, any>) {
    // semantic linking for schemas
    const semanticLink: Record<string, any> = {};
    const semanticLinkData = useDataPath(
      props.semanticLink || 'semanticLinking',
    );
    if (Array.isArray(semanticLinkData)) {
      (semanticLinkData || []).forEach((s: any) => {
        semanticLink[s.fieldName] = s;
      });
    }

    // normalize schema in multiple schema structure
    const schema = extractSchema(props.value, semanticLink, useDataPath(''));
    return (
      <DataRootPathContextProvider data={schema}>
        {props.children}
      </DataRootPathContextProvider>
    );
  },
});

function extractSchema(
  schema: any[] | undefined,
  semanticLink: Record<string, any>,
  parent: any,
) {
  if (!schema || schema.length === 0) {
    return [];
  }
  const schemasList = !(schema[0] as SchemaList).columns
    ? [
        {
          name: 'Schema',
          columns: schema as Schema[],
        },
      ]
    : schema;
  return schemasList.map((schemaList: SchemaList) => ({
    ...schemaList,
    columns: schemaList.columns.map((column: Schema) => ({
      ...column,
      _semanticlink: semanticLink[column.name],
    })),
    _parent: parent,
  }));
}
