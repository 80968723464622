import {
  Box,
  DrawerProps,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
  useTheme,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import React, { useMemo } from 'react';
import {
  ErrorBox,
  GET_METRIC_BY_METRIC_ID_AND_ENVS,
  GET_POLICY_BY_POLICY_ID_AND_ENVS,
  roundWithoutApprossimation,
  toGovernanceMetric,
  toGovernancePolicy,
  GovernanceTestOverviewMetric,
  GovernanceTestOverviewPolicy,
} from '@agilelab/plugin-wb-governance';
import {
  GovernanceEntityType,
  MetricByIdAndEnvsQuery,
  PolicyByIdAndEnvsQuery,
} from '@agilelab/plugin-wb-governance-common';
import { useQuery } from '@apollo/client';
import { ErrorPanel, Progress } from '@backstage/core-components';
import {
  CodeField,
  GenericField,
  WbDrawer,
} from '@agilelab/plugin-wb-platform';
import { PolicyHeader } from './PolicyHeader';
import { MetricHeader } from './MetricHeader';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { TestStatus } from '@agilelab/plugin-wb-platform';
import { Task } from '../../types';

const useStyles = makeStyles((_theme: Theme) => ({
  drawerPaper: {
    height: '100%',
    width: '50%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    margin: 0,
  },
}));

const ErrorBoxWrapper = (props: { errors: string[] }) => {
  const { errors } = props;
  const [currentErrorIndex, setCurrentErrorIndex] = React.useState<number>(0);
  const handleSwitch = (index: number) => {
    setCurrentErrorIndex(index);
  };

  return (
    <ErrorBox
      error={{
        message: errors.at(currentErrorIndex)!,
      }}
      navigation={{
        current: currentErrorIndex,
        total: errors.length,
        handleSwitch: handleSwitch,
      }}
      headerTitle="Error Info"
    />
  );
};

export const TaskDrawer = (
  props: DrawerProps & {
    task: Task | null;
  },
) => {
  const { task, ...drawerProps } = props;
  const classes = useStyles();
  const theme = useTheme();

  const {
    data: policyData,
    error: policyError,
    loading: loadingPolicy,
  } = useQuery<PolicyByIdAndEnvsQuery>(GET_POLICY_BY_POLICY_ID_AND_ENVS, {
    variables: { policyId: task?.governanceEntityId },
    skip: task?.governanceEntityId
      ? task.governanceEntityType !== GovernanceEntityType.Policy
      : true,
  });

  const {
    data: metricData,
    error: metricError,
    loading: loadingMetric,
  } = useQuery<MetricByIdAndEnvsQuery>(GET_METRIC_BY_METRIC_ID_AND_ENVS, {
    variables: { metricId: task?.governanceEntityId },
    skip: task?.governanceEntityId
      ? task.governanceEntityType !== GovernanceEntityType.Metric
      : true,
  });

  // Replace Policy Status with the one returned from the test
  const policy = useMemo(() => {
    const fetchedPolicy =
      policyData &&
      policyData.cgp_governance_entity[0] &&
      toGovernancePolicy(policyData.cgp_governance_entity[0]);
    if (!fetchedPolicy) return fetchedPolicy;
    return {
      ...fetchedPolicy,
      ...(task?.governanceEntityStatus
        ? { status: task.governanceEntityStatus }
        : {}),
    };
  }, [policyData, task]);

  // Replace Metric Status with the one returned from the test
  const metric = useMemo(() => {
    const fetchedPolicy =
      metricData &&
      metricData.cgp_governance_entity[0] &&
      toGovernanceMetric(metricData.cgp_governance_entity[0]);
    if (!fetchedPolicy) return fetchedPolicy;
    return {
      ...fetchedPolicy,
      ...(task?.governanceEntityStatus
        ? { status: task.governanceEntityStatus }
        : {}),
    };
  }, [metricData, task]);
  return (
    <WbDrawer
      open={props.open!}
      setOpen={props.onClose}
      title="Test Details"
      {...drawerProps}
    >
      <Box>
        {task && (
          <>
            {policy && <PolicyHeader policy={policy} task={task} />}
            {metric && <MetricHeader metric={metric} task={task} />}

            {!task.governanceEntityId && (
              <Box
                className={classes.header}
                style={{ marginBottom: theme.spacing(2) }}
              >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <TestStatus status={task.status} variant="reduced" />
                  <Typography variant="h6">
                    <strong style={{ color: theme.palette.primary.main }}>
                      {task.name}
                    </strong>
                  </Typography>
                </Box>
              </Box>
            )}
            {!task.governanceEntityId && (
              <>
                {task.errors.length > 0 && (
                  <ErrorBoxWrapper errors={task.errors} />
                )}
              </>
            )}
            {(loadingPolicy || loadingMetric) && <Progress />}
            {(metricError || policyError) && (
              <ErrorPanel error={metricError || policyError!} />
            )}

            {metric && (
              <GovernanceTestOverviewMetric
                kind="controlPanel"
                metric={metric}
                test={{
                  errors: task.errors,
                  content: task.validatedDescriptor || '',
                  name: task.displayName,
                }}
                infoContent={
                  <Box style={{ display: 'flex', gap: 50 }}>
                    <Tooltip title={task.metricSpecificResult?.value || ''}>
                      <Box>
                        <GenericField
                          label="Value"
                          value={roundWithoutApprossimation(
                            2,
                            task.metricSpecificResult?.value,
                          )}
                        />
                      </Box>
                    </Tooltip>

                    <>
                      <GenericField
                        label="Latest Update"
                        value={format(
                          parseISO(task.startTime),
                          'yyyy/MM/dd HH:mm:ss',
                        )}
                      />

                      <GenericField
                        label="Label"
                        value={
                          task.metricSpecificResult?.threshold?.label ||
                          'No Label'
                        }
                      />
                      {task.metricSpecificResult?.details && (
                        <GenericField
                          label="Test Detail"
                          value={
                            <CodeField
                              title="Test Detail"
                              value={JSON.stringify(
                                task.metricSpecificResult?.details,
                                null,
                                4,
                              )}
                            />
                          }
                        />
                      )}
                    </>
                  </Box>
                }
              />
            )}

            {policy && (
              <GovernanceTestOverviewPolicy
                kind="controlPanel"
                policy={policy}
                infoContent={
                  <Box style={{ display: 'flex', gap: 50 }}>
                    <>
                      <GenericField
                        label="Result Status"
                        value={snakeCaseToTitleCase(task.status.toLowerCase())}
                      />
                      <GenericField
                        label="Latest Update"
                        value={format(
                          parseISO(task.startTime),
                          'yyyy/MM/dd HH:mm:ss',
                        )}
                      />
                      {task.details && (
                        <GenericField
                          label="Test Detail"
                          value={
                            <CodeField
                              title="Test Detail"
                              value={JSON.stringify(task.details, null, 4)}
                            />
                          }
                        />
                      )}
                    </>
                  </Box>
                }
                test={{
                  errors: task.errors,
                  content: task.validatedDescriptor || '',
                  name: task.displayName,
                }}
              />
            )}
          </>
        )}
      </Box>
    </WbDrawer>
  );
};
