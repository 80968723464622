import { JsonObject } from '@backstage/types';
import { AtomicFilter, ComplexFilter, FilterValue } from '../SearchFilters';
import { SearchFilterVisitor } from './SearchFilterVisitor';

export class JsonObjectSearchFilterVisitor implements SearchFilterVisitor {
  visitComplexFilter(filter: ComplexFilter): JsonObject {
    return {
      operator: filter.operator,
      filters: filter.filters.map(f => {
        return f.accept(this);
      }),
    };
  }

  visitAtomicFilter(filter: AtomicFilter): JsonObject {
    return {
      field: filter.field,
      operator: filter.operator,
      value: this.formatValue(filter.value),
    };
  }

  private formatValue(value: FilterValue | FilterValue[]): any {
    if (Array.isArray(value)) {
      return value.map(v => this.formatValue(v));
    }
    return value;
  }
}
