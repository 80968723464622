import React from 'react';
import { Tooltip, Typography, useTheme } from '@material-ui/core';
import { useStyles } from './style';
import { OkFilledIcon, OkIcon, OkPlusIcon } from './Icons';
import { IconLabelBox } from '../../IconLabelBox';

interface Props {
  okWithWarnings?: boolean;
  filled?: boolean;
  style?: React.CSSProperties;
  variant?: 'reduced' | 'default';
}

const ReducedIcon: React.FC<{
  filled: boolean;
  okWithWarnings: boolean;
  style?: React.CSSProperties;
}> = ({ filled, okWithWarnings, style }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {filled ? (
        <OkFilledIcon
          htmlColor={theme.palette.success.main}
          className={classes.icon}
          style={style}
        />
      ) : okWithWarnings ? (
        <OkPlusIcon
          htmlColor={theme.palette.success.main}
          className={classes.icon}
          style={style}
        />
      ) : (
        <OkIcon
          htmlColor={theme.palette.success.main}
          className={classes.icon}
          style={style}
        />
      )}
    </>
  );
};

export const PassedIcon: React.FC<Props> = ({
  okWithWarnings = false,
  filled = false,
  style,
  variant = 'default',
}) => {
  const theme = useTheme();
  return (
    <Tooltip
      title={
        okWithWarnings ? 'Completed with warnings' : 'Completed with success'
      }
    >
      <span>
        {variant === 'reduced' ? (
          <ReducedIcon
            filled={filled}
            okWithWarnings={okWithWarnings}
            style={style}
          />
        ) : (
          <IconLabelBox
            label="Success"
            backgroundColor={theme.palette.success.main}
            icon={
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.black,
                  fontWeight: '700',
                }}
              >
                OK
              </Typography>
            }
          />
        )}
      </span>
    </Tooltip>
  );
};
