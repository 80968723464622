import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useEntityIcon } from '@agilelab/plugin-wb-platform';
import clsx from 'clsx';

type EntityIconProps = {
  useCaseTemplateId: string;
  kind: string;
  title: string;
  style?: React.CSSProperties;
  className?: string;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '24px',
      height: '24px',
      borderRadius: '4px',
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.grey[200]}`,
      overflow: 'hidden',
    },
    img: {
      width: '100%',
      height: '100%',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
  }),
  { name: 'EntityIcon' },
);

export const EntityIcon = ({
  useCaseTemplateId,
  kind,
  title,
  className,
  style,
}: EntityIconProps) => {
  const classes = useStyles();

  const { getIcon } = useEntityIcon();

  const icon = getIcon(useCaseTemplateId, kind);

  const getIconElement = () => {
    if (icon === undefined) return <></>;
    if (icon.type === 'img')
      return (
        <div
          className={classes.img}
          style={{ backgroundImage: `url(${icon.src})` }}
          role="img"
          aria-label={title}
        />
      );
    return (
      <div className={classes.img}>
        <icon.icon />
      </div>
    );
  };

  return (
    <Box className={clsx(classes.root, className)} style={style}>
      {getIconElement()}
    </Box>
  );
};
