import React, { useState } from 'react';
import { WbCard, WbCardProps } from './WbCard';
import { WbTabs } from '../WbTabs';
import { WbTabProps } from '../WbTabs/WbTab';

interface Props extends WbCardProps {
  label?: string | JSX.Element;
}

export const WbCardWithTabs: React.FC<Props> = props => {
  const tabs = React.Children.map(
    props.children as React.ReactElement<WbTabProps>,
    (child: React.ReactElement<WbTabProps>) => child.props,
  );
  const [selectedTab, setSelectedTab] = useState<string>(tabs[0].name);

  return (
    <WbCard
      {...props}
      toolsComponents={
        <>
          {props.label}
          <WbTabs
            tabs={tabs.map(tab => ({
              name: tab.name,
              action: () => {
                setSelectedTab(tab.name);
                tab.action?.();
              },
              selected: tab.name === selectedTab,
            }))}
          />
        </>
      }
    >
      {React.Children.map(
        props.children as React.ReactElement<WbTabProps>,
        (child: React.ReactElement<WbTabProps>) => child,
      ).filter(child => child.props.name === selectedTab)}
    </WbCard>
  );
};
