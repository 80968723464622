import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';
import {
  AsyncEnumFilter,
  ProviderFilters,
  SearchFilter,
  SelectFilter,
  WbTableFilters,
} from '@agilelab/plugin-wb-platform';
import { stringifyEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback } from 'react';
import useConsumableContext from './ConsumablesProvider';

export interface ConsumablesFilters extends ProviderFilters {
  domain?: { name: string; ref: string }[];
  system?: { name: string; urn: string }[];
  environment?: { name: string; priority: number };
}

interface DomainFilterProps {
  value: { name: string; ref: string }[];
  changeFilters: <K extends keyof ConsumablesFilters>(
    key: K,
    value: ConsumablesFilters[K],
  ) => void;
  getOptions: () => Promise<{ name: string; ref: string }[]>;
}

interface SystemFilterProps {
  value: { name: string; urn: string }[];
  changeFilters: <K extends keyof ConsumablesFilters>(
    key: K,
    value: ConsumablesFilters[K],
  ) => void;
  getOptions: () => Promise<{ name: string; urn: string }[]>;
}

interface EnvironmentFilterProps {
  value?: { name: string; priority: number };
  changeFilters: <K extends keyof ConsumablesFilters>(
    key: K,
    value: ConsumablesFilters[K],
  ) => void;
  options: { name: string; priority: number }[];
}

const EnvironmentFilter: React.FC<EnvironmentFilterProps> = ({
  value,
  options,
  changeFilters,
}: EnvironmentFilterProps) => {
  return (
    <SelectFilter<{ name: string; priority: number }>
      field="Environment"
      options={options}
      value={value ?? options[0]}
      onChange={environment => {
        changeFilters('environment', environment ?? options[0]);
      }}
      renderOption={o => o.name}
      renderValue={o => o.name}
      onSearch={searchValue =>
        options.filter(o => new RegExp(searchValue, 'ig').test(o?.name))
      }
    />
  );
};

const SystemFilter: React.FC<SystemFilterProps> = ({
  value,
  changeFilters,
  getOptions,
}) => {
  return (
    <AsyncEnumFilter<{ name: string; urn: string }>
      field="System"
      value={value ?? undefined}
      onChange={system => {
        changeFilters('system', system);
      }}
      renderOption={o => o.name}
      renderValue={v => v.name}
      onSearch={(searchValue, options) =>
        options.filter(o => new RegExp(searchValue, 'ig').test(o.name))
      }
      getOptions={getOptions}
    />
  );
};

const DomainFilter: React.FC<DomainFilterProps> = ({
  value,
  changeFilters,
  getOptions,
}) => {
  return (
    <AsyncEnumFilter<{ name: string; ref: string }>
      field="Domain"
      value={value ?? undefined}
      onChange={domain => {
        changeFilters('domain', domain);
      }}
      renderOption={o => o.name}
      renderValue={v => v.name}
      onSearch={(searchValue, options) =>
        options.filter(o => new RegExp(searchValue, 'ig').test(o.name))
      }
      getOptions={getOptions}
    />
  );
};

export const ConsumableFiltersSection = () => {
  const { filters, changeFilters, resetFilters, templateKind, availableEnvs } =
    useConsumableContext();

  const catalogApi = useApi(catalogApiRef);

  const fetchAvailableDomains = useCallback(async () => {
    return catalogApi
      .getEntities({ filter: { kind: 'domain' } })
      .then(response =>
        response.items.map(item => {
          const ref = stringifyEntityRef({
            kind: 'domain',
            name: item.metadata.name,
            namespace: item.metadata.namespace,
          });
          return { name: item.metadata.name, ref };
        }),
      );
  }, [catalogApi]);

  const fetchAvailableSystems = useCallback(async () => {
    return catalogApi
      .getEntities({ filter: { kind: 'system' } })
      .then(response =>
        response.items.map(item => {
          const urn = generateURNByKind(item.metadata.name, item.kind);
          return { name: item.metadata.name, urn };
        }),
      );
  }, [catalogApi]);

  const filterElement = (key: string) => {
    if (key === 'search') {
      return (
        <SearchFilter
          key={key}
          value={filters.search ?? ''}
          changeFilters={changeFilters}
        />
      );
    }

    if (key === 'domain') {
      return (
        <DomainFilter
          key={key}
          value={filters.domain ?? []}
          changeFilters={changeFilters}
          getOptions={fetchAvailableDomains}
        />
      );
    }

    if (key === 'system') {
      return (
        <SystemFilter
          key={key}
          value={filters.system ?? []}
          changeFilters={changeFilters}
          getOptions={fetchAvailableSystems}
        />
      );
    }

    if (key === 'environment') {
      return (
        <EnvironmentFilter
          key={key}
          value={filters.environment ?? undefined}
          changeFilters={changeFilters}
          options={availableEnvs ?? []}
        />
      );
    }

    return <></>;
  };

  return (
    <WbTableFilters onClear={resetFilters}>
      {templateKind?.userFilters?.map(filter => filterElement(filter))}
    </WbTableFilters>
  );
};
