import { Entity } from '@backstage/catalog-model';
import {
  EntityStep,
  EntityWithIcon,
  MetricSpecificResult,
  PublicInfo,
  SpecificCoordinatorResult,
  Task,
  Test,
} from './types';
import {
  DagStatus,
  PolicyValidateResult,
  ProvisioningPlan,
  TaskAction,
} from '@agilelab/plugin-wb-builder-common';
import {
  GovernanceEntityType,
  Status as PolicyStatus,
} from '@agilelab/plugin-wb-governance-common';

export const mapToTemplateIcons = (
  templates: Entity[],
  entities: Entity[],
): EntityWithIcon[] => {
  const entitiesWithIcon = entities.map(entity => {
    const relatedTemplate = templates.find(template => {
      const steps = (template.spec?.steps as unknown as EntityStep[]) || [];
      return steps.find(
        step =>
          step.input?.values?.useCaseTemplateId ===
          (entity.spec?.mesh as any)?.useCaseTemplateId,
      );
    });

    return { ...entity, icon: (relatedTemplate?.metadata?.mesh as any)?.icon };
  });

  return entitiesWithIcon;
};

const parseResultString = (
  action: TaskAction,
  obj: string | null,
): Array<{
  displayName?: string;
  errors: string[];
  details?: any;
  status?: DagStatus;
  governanceEntityId?: string;
  governanceEntityStatus?: PolicyStatus;
  validatedDescriptor?: string;
  governanceEntityType?: GovernanceEntityType;
  metricSpecificResult?: MetricSpecificResult;
}> => {
  if (!obj) return [{ errors: [] }];

  try {
    const jsonResult = JSON.parse(obj);

    if (action === TaskAction.POLICY_VALIDATE_COMPONENT)
      return (jsonResult as PolicyValidateResult).evaluationResults.map(
        item => ({
          governanceEntityId: item.governanceEntityId,
          status: item.outcome.toUpperCase() as DagStatus,
          errors: item.result?.errors || [],
          details: item.result?.details,
          governanceEntityStatus: item.governanceEntityStatus,
          governanceEntityType: item.governanceEntityType,
          validatedDescriptor: item.resource?.descriptor,
          displayName: item.resource?.displayName,
          metricSpecificResult:
            item.governanceEntityType === GovernanceEntityType.Metric
              ? {
                  value: item.result?.value,
                  threshold: item.result?.thresholdResult,
                  details: item.result?.details,
                }
              : undefined,
        }),
      );

    // The sync validation has the public info directly in the result, so we don't need to unwrap it
    // We need to check it at runtime since there is no way to understand that the validation was from a sync one
    if (!jsonResult.info) {
      return [
        {
          errors: (jsonResult as PublicInfo).error
            ? (jsonResult as PublicInfo).error!.errors
            : [],
        },
      ];
    }

    return [
      {
        errors: (jsonResult as SpecificCoordinatorResult).info.publicInfo.error
          ? (jsonResult as SpecificCoordinatorResult).info.publicInfo.error!
              .errors
          : [],
      },
    ];
  } catch (error) {
    return [{ errors: [obj] }];
  }
};

export const mapToTests = (provisioningPlans: ProvisioningPlan[]): Test[] => {
  if (!provisioningPlans) return [];
  return provisioningPlans.map(pp => {
    return {
      id: pp.dag.id,
      startDate: pp.dag.startTime,
      environment: pp.environment,
      status: pp.dag.status,
      tasks: pp.dag.dependsOnTasks.flatMap(t => {
        const parsedResultsJson = parseResultString(t.action, t.result);

        return parsedResultsJson.map(result => ({
          id: t.id + result.governanceEntityId,
          action: t.action,
          startTime: t.startTime,
          name: t.name,
          displayName: result?.displayName || t.displayName,
          status: t.status,
          componentName: t.componentName,
          ...result,
        })) as Array<Task>;
      }),
    };
  });
};
