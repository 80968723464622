import { PolicyGql } from '@agilelab/plugin-wb-governance-common';
import { gql } from '@apollo/client/core';

export const GOVERNANCE_ENTITIES = gql`
  query GOVERNANCE_ENTITIES($ids: [String!] = "") {
    cgp_governance_entity(where: { governance_entity_id: { _in: $ids } }) {
      id
      group_id
      version
      name
      description
      context
      strategy
      engine
      timing
      trigger
      resource_type {
        uuid
        name
      }
      status
      selector
      creation_time
      update_time
      governance_entity_id
      cron_expression
      preprocessing
      content
      interaction_type
      additional_metadata
      result_type
      governance_entity_environments {
        environment {
          id
          name
        }
      }
      governance_entity_tags {
        tag {
          id
          name
        }
      }
    }
  }
`;

export type GovernanceEntitiesQueryType = {
  cgp_governance_entity: PolicyGql[];
};
