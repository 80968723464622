import React from 'react';
import { Theme, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { getTruncateMaxLinesStyle } from '@agilelab/plugin-wb-platform';

type ValidationTitleProps = {
  name: string;
  important?: boolean;
};

const useStyles = makeStyles<Theme, { important: boolean | undefined }>(
  () => ({
    root: ({ important }) => ({
      fontWeight: important ? 700 : undefined,
      ...getTruncateMaxLinesStyle(1, false),
    }),
  }),
  { name: 'ValidationTitle' },
);

export const ValidationTitle: React.FC<ValidationTitleProps> = ({
  important,
  name,
}) => {
  const classes = useStyles({ important });
  return (
    <Tooltip title={name}>
      <Typography
        variant={important ? 'body1' : 'body2'}
        className={classes.root}
      >
        {name}
      </Typography>
    </Tooltip>
  );
};
