import React, { useEffect, useMemo } from 'react';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { useBugIcons } from '../../hooks/useBugIcons';
import { Dag, DagStatus } from '@agilelab/plugin-wb-builder-common';

interface Props {
  step: Dag;
}

export const ValidationGatewayStep: React.FC<Props> = ({ step }) => {
  const { fetchTests, tests } = useReleaseDetailPageContext();
  const bugIcons = useBugIcons();

  useEffect(() => {
    fetchTests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validationGatewayStatus = useMemo(() => {
    if (step.status === DagStatus.COMPLETED) {
      const hasNotBlockingError = tests.value?.test?.tasks?.some(
        x =>
          x.status === DagStatus.WARNING ||
          x.status === DagStatus.INFO ||
          x.status === DagStatus.NOT_BLOCKING_ERROR,
      );
      if (hasNotBlockingError) return DagStatus.NOT_BLOCKING_ERROR;

      return step.status;
    }
    return step.status;
  }, [tests, step]);

  return <>{bugIcons[validationGatewayStatus]}</>;
};
