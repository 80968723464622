import React, { useMemo } from 'react';
import { useReleaseDetailPageContext } from '../../context/useReleaseDetailPageContext';
import { DeploymentPreview } from '../../types';
import { Typography } from '@material-ui/core';

interface Props {
  preview: DeploymentPreview;
}

export const ComponentDependencies: React.FC<Props> = ({ preview }) => {
  const { components } = useReleaseDetailPageContext();

  const componentsMap: Record<string, string> = useMemo(() => {
    return components?.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {},
    );
  }, [components]);

  return (
    <>
      {preview?.dependsOn?.map((d: string, index: number) => (
        <Typography key={index} style={{ fontWeight: 500 }} color="primary">
          {componentsMap[d] || ''}
        </Typography>
      ))}
    </>
  );
};
