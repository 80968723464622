import { useEnvironmentsContext } from '@agilelab/plugin-wb-platform';
import React, { useEffect, useState } from 'react';

type Props = {
  children?: React.ReactNode;
};

export const EditorPageEnvironmentLoader: React.FC<Props> = ({ children }) => {
  const { environmentList } = useEnvironmentsContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!environmentList) return;
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [environmentList]);

  return <>{!loading && children}</>;
};
