export const GOVERNANCE_POLICY_FRAGMENT = `
id
group_id
version
name
description
context
strategy
engine
timing
trigger
resource_type {
  uuid
  name
  displayName:display_name
}
status
selector
creation_time
update_time
governance_entity_id
cron_expression
preprocessing
content
interaction_type
additional_metadata
result_type
governance_entity_environments {
  environment {
    id
    name
  }
}
governance_entity_tags {
  tag {
    id
    name
  }
}
`;
