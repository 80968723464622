import { RequestStatusEnum } from './types';

export const getRequestLabel = (r: any): RequestStatusEnum => {
  const { verb, status } = r;

  if (status === 'PROVISIONING' && verb) {
    const requestVerb = verb.toUpperCase() as 'GRANT' | 'REVOKE';
    return RequestStatusEnum[requestVerb];
  }

  const requestStatus = status as 'PROVISIONING' | 'PENDING' | 'WAITING';

  return RequestStatusEnum[requestStatus];
};
