import {
  transformUrnToWitboostId,
  WitboostSystem,
} from '@agilelab/plugin-wb-builder-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogApi, catalogApiRef } from '@backstage/plugin-catalog-react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';
import { parseEntityRef } from '@backstage/catalog-model';
import {
  Column,
  NotFoundException,
  GenericEntityType,
  GenericProviderType,
  getLabelAndValue,
  Pagination,
  Provider,
  selectTemplateEntity,
  KindEnum,
} from '@agilelab/plugin-wb-platform';
import { TemplateEntity } from '../../EntitySearchPicker';
import { contextProviderFactory } from '../entityProvider.factory';
import { SystemFilters, SystemFiltersSection } from './SystemFilters';
import {
  INSTANCE_OF,
  PART_OF,
} from '@agilelab/plugin-wb-practice-shaper-common';
import { expandEntityRefSafe } from '@agilelab/plugin-wb-platform-common';

export type SystemTypeEntity = GenericEntityType & WitboostSystem;

interface SystemContextType
  extends GenericProviderType<SystemTypeEntity[], SystemFilters> {}

const [useSystemContext, SystemContextProvider] =
  contextProviderFactory<SystemContextType>();

export default useSystemContext;

export interface SystemProviderProps {
  templateKind: TemplateEntity | undefined;
  children: React.ReactNode;
  parentTemplateRef?: string;
}

const defaultColumns = new Map<string, string>([
  [
    'name',
    '{% if spec.mesh.name %}{{spec.mesh.name}}{% else %}{{metadata.name}}{% endif %}',
  ],
  ['description', '{{metadata.description}}'],
]);

const toSystemTypeEntity = (system: WitboostSystem): SystemTypeEntity => {
  return {
    ...system,
    __metadata: { kind: KindEnum.system, name: system.metadata.name },
  };
};

export const SystemProvider: React.FC<SystemProviderProps> = props => {
  const { templateKind, children, parentTemplateRef } = props;
  const catalogApi = useApi(catalogApiRef);
  const identityApi = useApi(identityApiRef);
  const [filters, setFilters] = useState<SystemFilters>({});
  const [instanceOfSystemType, setInstanceOfSystemType] = useState<string[]>(
    [],
  );
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined);
  const [prevCursor, setPrevCursor] = useState<string | undefined>(undefined);
  const [count, setCount] = useState<number>(0);
  const [pagination, setPagination] = useState<Pagination>({
    limit: 5,
    currentPage: 0,
  });

  const columns: Column[] = useMemo(() => {
    if (templateKind?.columns) {
      return templateKind?.columns.map(c => ({
        name: c.name,
        path: 'path' in c ? c.path : defaultColumns.get(c.value)!,
      }));
    }
    return Array.from(defaultColumns, ([name, value]) => ({
      name,
      path: value,
    }));
  }, [templateKind?.columns]);

  const preFilters = templateKind?.filter;

  // Function to fetch system types based on the project ref
  // if is a system type return it
  // if is a component type get its partOfSystem
  const getSystemTypeFromProjectRef = useCallback(
    async (projectRef: string) => {
      const { token } = await identityApi.getCredentials();

      const parsedEntityRef = parseEntityRef(projectRef);

      if (parsedEntityRef.kind.toLocaleLowerCase() === 'componenttype') {
        const componentType = await catalogApi.getEntityByRef(projectRef, {
          token,
        });
        const systemTypeRelation = componentType?.relations?.find(
          rel =>
            rel.type === PART_OF &&
            parseEntityRef(rel.targetRef).kind.toLowerCase() === 'systemtype',
        );
        return systemTypeRelation ? [systemTypeRelation.targetRef] : [];
      }

      return undefined;
    },
    [catalogApi, identityApi],
  );

  // Function to fetch domain types compatible with specific types
  const fetchSystemTypesCanBeParentOfType = useCallback(
    async (projectRefs: string[]) => {
      const results = await Promise.allSettled(
        projectRefs.map(projectRef => getSystemTypeFromProjectRef(projectRef)),
      );
      return results
        .filter(result => result.status === 'fulfilled' && result.value)
        .flatMap(result => (result as PromiseFulfilledResult<string[]>).value);
    },
    [getSystemTypeFromProjectRef],
  );

  // Function to fetch system type compatible with the current template
  const fetchSystemTypeCompatibleWithCurrentTemplate = useCallback(async () => {
    const { token } = await identityApi.getCredentials();
    if (!parentTemplateRef) return undefined;

    const parent = await catalogApi.getEntityByRef(parentTemplateRef, {
      token,
    });
    if (!parent || !parent.spec?.generates) return undefined;

    return getSystemTypeFromProjectRef(parent.spec.generates as string);
  }, [catalogApi, getSystemTypeFromProjectRef, identityApi, parentTemplateRef]);

  useEffect(() => {
    const getSystemTypes = async () => {
      let systemTypes: string[] = [];

      const kind = preFilters?.practiceShaper?.kind;

      if (kind === 'canBeParentOfCurrentInstance') {
        systemTypes =
          (await fetchSystemTypeCompatibleWithCurrentTemplate()) || [];
      }

      if (
        kind === 'canBeParentOfType' &&
        preFilters?.practiceShaper?.canBeParentOfType &&
        Array.isArray(preFilters.practiceShaper.canBeParentOfType)
      ) {
        systemTypes = await fetchSystemTypesCanBeParentOfType(
          preFilters?.practiceShaper?.canBeParentOfType,
        );
      }

      if (
        kind === 'instanceOf' &&
        preFilters?.practiceShaper?.instanceOf &&
        Array.isArray(preFilters.practiceShaper.instanceOf)
      ) {
        systemTypes = preFilters.practiceShaper.instanceOf.map(
          (instanceOfEl: string) =>
            expandEntityRefSafe(instanceOfEl) ?? instanceOfEl,
        );
      }

      setInstanceOfSystemType(systemTypes);
    };

    getSystemTypes();
  }, [
    catalogApi,
    fetchSystemTypeCompatibleWithCurrentTemplate,
    fetchSystemTypesCanBeParentOfType,
    identityApi,
    parentTemplateRef,
    preFilters,
  ]);

  const systemState = useAsync(async () => {
    const result = await catalogApi.queryEntities({
      filter: {
        kind: ['System'],
        [`relations.${PART_OF}`]: filters.domain?.map(d => d.ref) || [],
        'spec.type': filters.type?.map(d => d.ref) || [],
        [`relations.${INSTANCE_OF}`]: instanceOfSystemType,
      },
      limit: pagination.limit,
      cursor: pagination.cursor,
      fullTextFilter: {
        term: filters.search!,
        fields: ['spec.mesh.name', 'metadata.name'],
      },
    });
    setNextCursor(result.pageInfo.nextCursor);
    setPrevCursor(result.pageInfo.prevCursor);
    setCount(result.totalItems);
    return result.items.map(i => toSystemTypeEntity(i as WitboostSystem));
  }, [
    templateKind,
    filters,
    instanceOfSystemType,
    pagination.limit,
    pagination.cursor,
  ]);

  return (
    <SystemContextProvider
      value={{
        entitiesState: systemState,
        columns,
        filters,
        changeFilters: <K extends keyof SystemFilters>(
          key: K,
          filterValue: SystemFilters[K],
        ) => {
          setPagination(p => ({ ...p, currentPage: 0, cursor: undefined }));
          setFilters(f => ({ ...f, [key]: filterValue }));
        },
        resetFilters: () => {
          setFilters({});
        },
        templateKind,
        pagination,
        setPagination,
        count,
        nextCursor,
        prevCursor,
      }}
    >
      {children}
    </SystemContextProvider>
  );
};

export class SystemHandlerProvider
  implements Provider<SystemTypeEntity[], SystemFilters>
{
  private readonly catalogApi: CatalogApi;
  private readonly availableKinds: TemplateEntity[];

  constructor(catalogApi: CatalogApi, availableKinds: TemplateEntity[]) {
    this.catalogApi = catalogApi;
    this.availableKinds = availableKinds;
  }

  useContext = useSystemContext;

  renderContextProvider(
    templateKind: TemplateEntity | undefined,
    children: React.ReactNode,
    parentTemplateRef?: string,
  ) {
    return (
      <SystemProvider
        templateKind={templateKind}
        parentTemplateRef={parentTemplateRef}
      >
        {children}
      </SystemProvider>
    );
  }

  filtersSection = SystemFiltersSection;

  async resolve(ref: string) {
    const resolvedRef = transformUrnToWitboostId(ref) || ref;
    const parsedEntityRef = parseEntityRef(resolvedRef);
    try {
      if (parsedEntityRef.kind === 'system') {
        const entity = await this.catalogApi.getEntityByRef(resolvedRef);
        if (entity)
          return getLabelAndValue(
            toSystemTypeEntity(entity as WitboostSystem),
            selectTemplateEntity(this.availableKinds, 'System'),
          );
        throw new NotFoundException(
          `Cannot find a System entity with ref: ${ref}`,
        );
      }

      return undefined;
    } catch (error) {
      throw new Error(`Error while resolve entity with ref: ${ref}`);
    }
  }
}
