import React, { useMemo } from 'react';
import { GovernanceEntitiesQueryType } from '../../../graphql/governanceEntities';
import { Task } from '../../EditorPage/types';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';
import { PolicyRow } from './PolicyRow';
import { MetricRow } from './MetricRow';
import { ComponentRow } from './ComponentRow';

interface Props {
  task: Task;
  governanceEntities: GovernanceEntitiesQueryType['cgp_governance_entity'];
}

export const ValidationTestRow: React.FC<Props> = ({
  task,
  governanceEntities,
}) => {
  const policy = useMemo(
    () =>
      governanceEntities.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Policy,
      ),
    [governanceEntities, task.governanceEntityId, task.governanceEntityType],
  );
  const metric = useMemo(
    () =>
      governanceEntities.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Metric,
      ),
    [governanceEntities, task.governanceEntityId, task.governanceEntityType],
  );

  return (
    <>
      {policy && <PolicyRow policy={policy} task={task} />}
      {metric && <MetricRow metric={metric} task={task} />}
      {!policy && !metric && <ComponentRow task={task} />}
    </>
  );
};
