import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { HorizontalLine } from './HorizontalLine';
import { VerticalLine } from './VerticalLine';
import { Skeleton } from '@material-ui/lab';
import {
  CARD_AVATAR_DIMENSION,
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
} from './const';

const useStyles = makeStyles(theme => ({
  container: {
    border: `1px solid ${theme.palette.grey[300]}`,
    display: 'flex',
    padding: '4px 8px',
    gap: '8px',
    marginBottom: CARD_MARGIN_BOTTOM,
    minHeight: CARD_HEIGHT,
    maxHeight: CARD_HEIGHT,
    height: CARD_HEIGHT,
    borderRadius: '4px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  row: {
    display: 'flex',
  },
}));

const EntityCardLoader: React.FC = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Skeleton
        variant="circle"
        width={CARD_AVATAR_DIMENSION}
        height={CARD_AVATAR_DIMENSION}
      />

      <Box style={{ flex: 1 }} className={classes.column}>
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" />
      </Box>

      <Box style={{ display: 'flex', gap: '2px' }}>
        <Skeleton variant="circle" width={20} height={20} />
        <Skeleton variant="circle" width={20} height={20} />
        <Skeleton variant="circle" width={20} height={20} />
      </Box>
    </Box>
  );
};

export const StructureListLoader: React.FC = () => {
  return (
    <Box style={{ position: 'relative' }}>
      <EntityCardLoader />

      <VerticalLine cards={3} />

      {[1, 2, 3].map(i => (
        <Box key={i} style={{ marginLeft: '16px', position: 'relative' }}>
          <HorizontalLine />
          <EntityCardLoader />
        </Box>
      ))}
    </Box>
  );
};
