import React, { ReactNode } from 'react';
import {
  Box,
  ButtonProps,
  CircularProgress,
  Popover,
  PopoverProps,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { WbSearch, WbSearchProps } from '../WbSearch';
import { EnumButtons } from './EnumButtons';
import { FiltersButton } from './FilterButtons';
import clsx from 'clsx';
import { getOnHoverScrollbarStyle } from '../../styles';

type FilterListModalProps = {
  onSearch?: WbSearchProps['onChange'];
  searchValue?: WbSearchProps['value'];
  searchPlaceholder?: string;
  searchDebounced?: boolean;
  loading?: boolean;
  onSelectAll?: ButtonProps['onClick'];
  onClear?: ButtonProps['onClick'];
  PopoverProps: PopoverProps;
  children: ReactNode;
  onApply?: ButtonProps['onClick'];
  style?: React.CSSProperties;
  label?: ReactNode;
  extraActions?: { label: string; action: () => void }[];
  contentStyle?: React.CSSProperties;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      width: '400px',
      background: 'white',
      overflow: 'hidden',
      display: 'flex',
      paddingTop: theme.spacing(2),
      flexDirection: 'column',
    },
    paddingHorizontal: { paddingInline: theme.spacing(2) },
    marginBottom: { marginBottom: theme.spacing(2) },
    label: {
      fontWeight: 700,
      color: theme.palette.secondary.dark,
    },
    content: {
      ...getOnHoverScrollbarStyle(theme),
      overflowY: 'auto',
      overflowX: 'auto',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    },
  }),
  { name: 'FilterListModal' },
);

export const FilterListModal = ({
  onSearch,
  searchDebounced,
  searchValue,
  loading,
  label,
  onSelectAll,
  onClear,
  PopoverProps: popoverProps,
  children,
  onApply,
  style,
  contentStyle,
  extraActions,
  searchPlaceholder,
}: FilterListModalProps) => {
  const classes = useStyles();
  return (
    <Popover disableRestoreFocus {...popoverProps}>
      <Box className={classes.root} style={style}>
        {typeof label === 'string' ? (
          <Typography
            variant="body2"
            className={clsx(
              classes.label,
              classes.paddingHorizontal,
              classes.marginBottom,
            )}
          >
            {label}
          </Typography>
        ) : (
          label
        )}
        {onSearch && (
          <Box
            className={clsx(classes.paddingHorizontal, classes.marginBottom)}
          >
            <WbSearch
              value={searchValue}
              onChange={onSearch}
              debounced={searchDebounced}
              placeholder={searchPlaceholder}
            />
          </Box>
        )}

        {loading ? (
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress size="2rem" />
          </Box>
        ) : (
          <>
            <Box
              className={clsx(classes.paddingHorizontal, classes.marginBottom)}
            >
              <EnumButtons
                extraActions={extraActions}
                containerStyle={{ padding: '0px' }}
                clear={{
                  onClick: onClear,
                }}
                selectAll={{
                  onClick: onSelectAll,
                }}
              />
            </Box>

            <Box
              className={clsx(classes.paddingHorizontal, classes.content)}
              style={contentStyle}
            >
              {children}
            </Box>
          </>
        )}
        <FiltersButton
          cancel={{
            onClick: popoverProps.onClose as any,
          }}
          apply={{
            onClick: onApply,
          }}
        />
      </Box>
    </Popover>
  );
};
