import { AjvError } from '@rjsf/core';

// See docs here https://react-jsonschema-form.readthedocs.io/en/latest/usage/validation/#custom-error-messages
export function transformErrors(errors: AjvError[]) {
  return errors.map(error => {
    if (error.name === 'format' && error.params.format === 'email') {
      error.message = 'Must be a valid email';
    }
    return error;
  });
}
