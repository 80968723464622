/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useContext, useState } from 'react';
import { WittyMessage } from './Messages';

export interface ChatContextType {
  incomingMessage?: boolean;
  onNewMessage: (message: WittyMessage) => void;
  messages: WittyMessage[];
  setMessages: React.Dispatch<React.SetStateAction<WittyMessage[]>>;
  wittyResponseMock: WittyMessage[];
  setWittyResponseMock: React.Dispatch<React.SetStateAction<WittyMessage[]>>;
}

export const ChatContext = React.createContext<ChatContextType>(
  {} as ChatContextType,
);

export interface ChatContextProviderProps extends Partial<ChatContextType> {
  children?: React.ReactNode;
}

export const ChatContextProvider: React.FC<ChatContextProviderProps> = ({
  children,
  ...props
}) => {
  const [messages, setMessages] = useState<WittyMessage[]>([]);

  const [wittyResponseMock, setWittyResponseMock] = useState<WittyMessage[]>([
    {
      sender: 'witty',
      contentMessage:
        'Hello! To start creating your prototype I need some information.\n\n### **Define the Type of your Prototype**\n\nWhat type of prototype do you need?\n\na. Data Product\n\nb. Type A\n\nc. Type B\n\nd. Type C\n\n*Most used is 1. Data Product*',
    },
    {
      sender: 'witty',
      contentMessage:
        'Good. Now write down the **Purpose** for which you are doing this Data Product',
    },
    {
      sender: 'witty',
      contentMessage:
        'Based on the purpose provided, here is a list of Domains to which it might belong.\n\n### **List of suggested Domains**\n- Finance\n- Customer-risk\n- Marketing\n\nIf none of the suggested domains are correct, respond with the domain name of your choice.',
    },
  ]);

  const onNewMessage = (message: WittyMessage) => {
    setMessages([...messages, message]);

    setTimeout(() => {
      const aiResponse = wittyResponseMock.shift();
      setWittyResponseMock(wittyResponseMock);
      if (aiResponse)
        setMessages(prevMessages => [...prevMessages, aiResponse]);
    }, 1000);
  };

  return (
    <ChatContext.Provider
      value={{
        onNewMessage,
        messages,
        setMessages,
        wittyResponseMock,
        setWittyResponseMock,
        ...props,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChatContext = () => useContext(ChatContext);
