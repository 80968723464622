import { createTopic } from '../lib';
import { z } from 'zod';

const userSignedInEventSchema = z.object({});

export type UserSignedInEvent = z.infer<typeof userSignedInEventSchema>;

export const userSignedInTopic = createTopic<UserSignedInEvent>(
  {
    namespace: 'settings',
    name: 'user-signed-in',
    description: 'User signed in',
  },
  userSignedInEventSchema,
);
