import React, { useEffect, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { ErrorPage, ErrorPanel, Progress } from '@backstage/core-components';
import { Box, Theme, Typography, makeStyles } from '@material-ui/core';
import {
  GET_EVALUATION_RESULT_BY_ID,
  GET_EVALUATION_RESULT_BY_UUID,
} from './queries/evaluation-result-by-id.query';
import {
  EvaluationResultByIdQuery,
  EvaluationResultIdByUUIDQuery,
  PolicyGql,
} from '@agilelab/plugin-wb-governance-common';
import { toEvaluationResultSpecific } from '../../mappers/to-evaluation-result-specific.mapper';
import { GovernanceTestOverviewMetric } from '../GovernanceTestPage/GovernanceTestOverview/GovernanceTestOverviewMetric/GovernanceTestOverviewMetric';
import { GovernanceTestOverviewPolicy } from '../GovernanceTestPage/GovernanceTestOverview/GovernanceTestOverviewPolicy/GovernanceTestOverviewPolicy';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { TestStatus, ThresholdResult } from '@agilelab/plugin-wb-platform';
import useAsync from 'react-use/lib/useAsync';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: '8px',
  },
}));

export const GovernanceSpecificResultComponent: React.FC<{
  evaluationId: number | string;
  idType?: 'pk' | 'external';
  setTitle?: React.Dispatch<React.SetStateAction<React.ReactNode>>;
}> = ({ evaluationId, setTitle, idType = 'pk' }) => {
  const classes = useStyles();
  const apolloClient = useApolloClient();

  const {
    value: data,
    error,
    loading,
  } = useAsync(async () => {
    if (idType === 'external') {
      const res = await apolloClient.query<EvaluationResultIdByUUIDQuery>({
        query: GET_EVALUATION_RESULT_BY_UUID,
        variables: { uuid: evaluationId },
      });
      return {
        cgp_evaluation_result_by_pk: res.data.cgp_evaluation_result[0],
      };
    }
    const res = await apolloClient.query<EvaluationResultByIdQuery>({
      query: GET_EVALUATION_RESULT_BY_ID,
      variables: { resultId: evaluationId },
    });
    return {
      cgp_evaluation_result_by_pk: res.data.cgp_evaluation_result_by_pk,
    };
  });

  const notFound = useMemo(
    () => data && data.cgp_evaluation_result_by_pk === null,
    [data],
  );

  const evaluationResultSpecific = useMemo(
    () =>
      data &&
      data.cgp_evaluation_result_by_pk &&
      toEvaluationResultSpecific(data),
    [data],
  );

  const resultTitle = useMemo(
    () =>
      `${snakeCaseToTitleCase(
        evaluationResultSpecific?.governance_entity_type || '',
      )} ${
        evaluationResultSpecific?.governance_entity?.name ||
        evaluationResultSpecific?.metric?.name
      } on ${evaluationResultSpecific?.resource_display_name}`,
    [
      evaluationResultSpecific?.governance_entity_type,
      evaluationResultSpecific?.metric?.name,
      evaluationResultSpecific?.governance_entity?.name,
      evaluationResultSpecific?.resource_display_name,
    ],
  );

  useEffect(() => {
    if (setTitle) {
      const titleBox = loading ? (
        <Progress />
      ) : (
        <Box className={classes.header}>
          {evaluationResultSpecific?.governance_entity_type === 'metric' ? (
            <ThresholdResult
              value={evaluationResultSpecific?.result?.value || 0}
              hasError={evaluationResultSpecific?.hasError}
              result={evaluationResultSpecific?.result?.thresholdResult}
              variant="reduced"
            />
          ) : (
            <TestStatus
              status={evaluationResultSpecific?.outcome as any}
              filled
              severity={
                (evaluationResultSpecific?.governance_entity as PolicyGql)
                  .content.severity
              }
              policyStatus={evaluationResultSpecific?.governance_entity.status}
              variant="reduced"
            />
          )}
          <Typography variant="h6" color="primary">
            {resultTitle}
          </Typography>
        </Box>
      );

      setTitle(titleBox);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultTitle, evaluationResultSpecific]);

  if (error) {
    return <ErrorPanel error={error} />;
  }

  if (loading) {
    return <Progress />;
  }

  if (notFound) {
    return (
      <ErrorPage
        status="404"
        statusMessage="Result not found"
        additionalInfo={`No result found with ID ${evaluationId}`}
      />
    );
  }

  return (
    <Box>
      {evaluationResultSpecific?.metric && (
        <GovernanceTestOverviewMetric
          kind="tabs"
          metric={evaluationResultSpecific.metric}
          result={evaluationResultSpecific}
        />
      )}
      {evaluationResultSpecific?.truePolicy && (
        <GovernanceTestOverviewPolicy
          kind="tabs"
          policy={evaluationResultSpecific.truePolicy}
          result={evaluationResultSpecific}
        />
      )}
    </Box>
  );
};
