import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Outcome } from '@agilelab/plugin-wb-governance-common';
import { OutcomeIcon } from '../icons/OutcomeIcon';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& svg': {
      width: theme.typography.body1.fontSize,
      height: theme.typography.body1.fontSize,
      marginRight: theme.spacing(0.5),
    },
  },
  ok: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  warning: {
    color: theme.palette.warning.main,
  },
  info: {
    color: theme.palette.info.main,
  },
  pending: {
    color: theme.palette.secondary.main,
  },
  no_resource: {
    color: theme.palette.secondary.main,
  },
  not_blocking_error: {
    color: theme.palette.secondary.main,
  },
  not_executed: {
    color: theme.palette.secondary.main,
  },
}));

interface OutcomeFieldProps {
  outcome: Outcome;
}

export const OutcomeField: React.FC<OutcomeFieldProps> = props => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <OutcomeIcon
        outcome={props.outcome}
        style={{
          display: 'flex',
        }}
      />
      <Typography
        variant="body1"
        component="span"
        className={classes[props.outcome.toLowerCase() as Outcome]}
        style={{
          textTransform: 'capitalize',
        }}
      >
        {snakeCaseToTitleCase(props.outcome)}
      </Typography>
    </Box>
  );
};
