import { CustomViewRegister, getCustomViewInfoFromObject } from '../CustomView';
// CustomViewComponent,

CustomViewRegister.register({
  id: 'automatic_fields_list',
  function: function AutoView(props: Record<string, any>) {
    const options = Object.assign(
      { _exclude: props.exclude || [], priority: props.order },
      props.defaults || {},
    );
    return getCustomViewInfoFromObject(
      props.value,
      props.path, // NB: you can use only property path, not value...
      options,
    );
  },
});
