import { Dialog, IconButton } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { WbCard } from '../WbCard';
import { MultiSelectionDialogContent } from './MultiSelectionDialogContent';
import { GeneralPurposePickerContextProvider } from './MultiSelectionPickerDialogContext';

export interface WbMultiSelectionPickerDialogProps<T> {
  title: string;
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedValues: T[];
  setSelectedValues: React.Dispatch<React.SetStateAction<T>>;
  children: React.ReactNode;
  fetchAiData?: () => Promise<T[]>;
}

export function WbMultiSelectionPickerDialog<T>(
  props: WbMultiSelectionPickerDialogProps<T>,
) {
  const {
    openDialog,
    setOpenDialog,
    selectedValues,
    setSelectedValues,
    children,
    title,
    fetchAiData,
  } = props;

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      maxWidth="xl"
      fullWidth
    >
      <WbCard
        title={`${title} Picker`}
        cardStyle={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
        actions={
          <IconButton
            aria-label="clear"
            onClick={() => setOpenDialog(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        <GeneralPurposePickerContextProvider
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          fetchAIData={fetchAiData!}
        >
          <MultiSelectionDialogContent
            aiEnabled={!!fetchAiData}
            title={title}
            setOpenDialog={setOpenDialog}
          >
            {children}
          </MultiSelectionDialogContent>
        </GeneralPurposePickerContextProvider>
      </WbCard>
    </Dialog>
  );
}
