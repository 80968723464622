import { parseEntityRef } from '@backstage/catalog-model';
import { generateURNByKind } from '@agilelab/plugin-wb-builder-common';

/**
 * Transforms entity name coming from the ReadsFrom array into a valid URN name
 * Entity names coming from ReadsFrom are expected to be in the form of {kind}:{entityName}
 * @param readsFromId - entity name in the format {kind}:{entityName}
 * @returns URN
 */
export function transformReadsFromIdToURN(readsFromId: string): string {
  if (readsFromId.indexOf(':') === -1) {
    throw new TypeError(
      `Unable to parse kind and entityName from readsFromId supplied: ${readsFromId}`,
    );
  }
  const { kind, name } = parseEntityRef(readsFromId);

  return generateURNByKind(name, kind);
}

/**
 * Transforms entity name coming from the DependsOn array into a valid URN name
 * Entity names coming from DependsOn array are ALWAYS components
 * @param dependsOnId - entity name
 * @returns URN
 */
export function transformDependsOnIdToURN(dependsOnId: string): string {
  if (dependsOnId.indexOf('.') === -1) {
    throw new TypeError(`Invalid dependsOn id supplied: ${dependsOnId}`);
  }

  const { kind, name } = parseEntityRef(dependsOnId);

  return generateURNByKind(name, kind);
}

/**
 * This function is used to allow backward compatibility for the use
 * cases where URNs that we are generating are always lower_case but we
 * had some old components that still have the camelCaseUrn
 * @param dependsOnId - entity name
 * @param deserializedObject - object that we need to parse in order to keep capitalisation
 * @returns URN
 */
export function transformDependsOnIdCamelCaseToURN(
  dependsOnId: string,
  deserializedObject: { kind?: string; namespace?: string; name: string },
): string {
  if (dependsOnId.indexOf('.') === -1) {
    throw new TypeError(`Invalid dependsOn id supplied: ${dependsOnId}`);
  }
  if (deserializedObject.name.indexOf('.') === -1) {
    throw new TypeError(
      `Invalid deserializedObject.name id supplied: ${deserializedObject.name}`,
    );
  }

  const { kind, name } = parseEntityRef(dependsOnId);
  const camelCaseName = `${deserializedObject.name}.${name.split('.').pop()}`;

  return generateURNByKind(camelCaseName, kind);
}
