import { WbFieldLabel } from '@agilelab/plugin-wb-platform';
import { useElementFilter } from '@backstage/core-plugin-api';
import { Typography, TypographyProps } from '@material-ui/core';
import React, { Fragment, ReactNode } from 'react';

import { GenericItem } from './GenericItem';

type Props = {
  label?: string;
  value?: string | React.ReactNode;
  type?: string;
  href?: string;
  expandable?: boolean;
  children?: React.ReactNode;
  contentProps?: TypographyProps;
  customLabelRender?: (content: ReactNode) => ReactNode;
};

export const GenericField = ({
  label,
  value,
  type,
  href,
  expandable,
  children,
  contentProps,
  customLabelRender,
}: Props) => {
  const childElements = useElementFilter(children, c => c.getElements());

  const defaultType = 'string';
  const defaultValue = '-';

  // The content can be the children, a ReactNode or a GenericItem
  const Content = () => {
    if (childElements.length > 0) return <Fragment>{childElements}</Fragment>;

    if (typeof value === 'string')
      return (
        <GenericItem
          value={value}
          type={type || defaultType}
          href={href}
          expandable={expandable}
          {...contentProps}
        />
      );

    return (
      <Typography variant="body1" {...contentProps}>
        {value || defaultValue}
      </Typography>
    );
  };

  if (customLabelRender) return <>{customLabelRender(<Content />)}</>;

  return (
    <>
      {label && <WbFieldLabel label={label} />}
      <Content />
    </>
  );
};
