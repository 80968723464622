import { Drawer, Grid, makeStyles, Theme } from '@material-ui/core';

import React from 'react';

const useStyles = makeStyles((_theme: Theme) => ({
  drawerPaper: {
    height: '100%',
    width: '50%',
  },
  drawerContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  chip: {
    margin: 0,
  },
}));

export const DetailDrawer = (props: any) => {
  const { detail, ...drawerProps } = props;
  const classes = useStyles();

  return (
    <Drawer
      anchor="right"
      classes={{
        paper: classes.drawerPaper,
      }}
      PaperProps={{
        style: {
          width: '60%',
        },
      }}
      transitionDuration={200}
      {...drawerProps}
    >
      {props.children ? (
        <Grid container direction="row" spacing={3}>
          {props.children.map((child: any, index: number) => (
            <Grid item xs={12} md={12} key={index.toFixed(0)}>
              {child}
            </Grid>
          ))}
        </Grid>
      ) : (
        <></>
      )}
    </Drawer>
  );
};
