import React from 'react';
import { Box, Theme, makeStyles } from '@material-ui/core';
import { Separator } from '../types';

const useStyles = makeStyles<Theme, { i: number }>(
  theme => ({
    // draws the triangle decorator on the left of each breadcrumb of variant main
    root: props => ({
      width: 16,
      height: '100%',
      position: 'relative',

      '&:before, &:after': {
        left: 0,
        top: 0,
        content: '""',
        display: 'block',
        position: 'absolute',
        width: '100%',
        height: '100%',
      },

      /* this draws the decorator triangle */
      '&:after': {
        clipPath: 'polygon(0% 0%, calc(100% - 1px) 50%, 0% 100%)',
        backgroundColor:
          props.i === 0 ? theme.palette.bkg.secondary : theme.palette.white,
      },

      /* this draws the border around the decorator triangle */
      '&:before': {
        clipPath:
          'polygon(0% 0%, calc(0% + 1px) 0%, 100% 50%, calc(0% + 1px) 100%, 0% 100%, calc(100% - 1px) 50%)',
        backgroundColor: theme.palette.grey[200],
      },
    }),
  }),
  { name: 'MainSeparator' },
);

export const MainSeparator: Separator = ({ i }) => {
  const classes = useStyles({ i });
  return <Box className={classes.root} />;
};
