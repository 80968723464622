import React from 'react';
import { Box, Typography, makeStyles, useTheme } from '@material-ui/core';
import { WittyIcon } from '../../icons/WittyIcon';
import { IncomingMessageIcon } from '../../icons/IncomingMessageIcon';

const useStyles = makeStyles(
  theme => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: theme.spacing(1),
      alignItems: 'auto',
    },
    avatar: {
      width: '24px',
      height: '24px',
    },
    iconWrapper: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(4),
    },
  }),
  { name: 'IncomingMessage' },
);

export const IncomingMessage = () => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
        <WittyIcon style={{ color: theme.palette.witboostLogo }} />

        <Typography style={{ fontWeight: 600 }} variant="body2">
          WITTY:
        </Typography>
      </Box>
      <Box className={classes.iconWrapper}>
        <IncomingMessageIcon />
      </Box>
    </Box>
  );
};
