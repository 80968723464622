import { ReleaseEntityV1alpha1 } from '@agilelab/plugin-wb-builder-common';
import { Entity } from '@backstage/catalog-model';

export function getReleaseInfo(release: ReleaseEntityV1alpha1) {
  const namespace = release.metadata.namespace || 'default';
  const name = release.metadata.name;
  const kind = 'Release';
  return {
    namespace,
    name,
    kind,
  };
}
export interface ProcessorError {
  errorMessage?: string;
  entity?: Entity;
  parent?: boolean;
}

function getProcessorError(processorErrors: ProcessorError[]) {
  const isParent = processorErrors[0].parent;
  const entityName = (
    processorErrors[0]?.entity?.spec?.mesh as Record<string, any>
  )?.name;
  return `An error occured while processing ${
    !isParent ? 'component' : 'project'
  } ${entityName} ${
    !isParent ? 'of this project' : ''
  }. Check the Overview section for additional details.`;
}

export function generateSnapshotButtonTooltip(
  allowed: boolean,
  disabled: boolean,
  processorErrors?: ProcessorError[],
  loading?: boolean,
  actionError?: string,
) {
  if (!!processorErrors?.length) {
    return getProcessorError(processorErrors);
  }
  if (loading) {
    return 'Descriptor is still loading, please wait until it is ready';
  }
  if (actionError) {
    return actionError;
  }
  if (!allowed) {
    return 'You are not allowed to create a new draft release.';
  }
  if (!disabled) {
    return 'Create a new draft release on the main repository. A draft release is an intermediate state between the latest release and the new one. The draft release will have the SNAPSHOT suffix';
  }

  return "You can't create a new draft release since there is already an active one. To create a new draft release, you need to promote to release the current one first.";
}

export const UPDATE_TOOLTIP =
  'Update the main repository branch with the existing draft release files. The previous draft release files will be overwritten by this operation, but you will be able to do additional updates in the future';

export function generateUpdateTooltip(
  canCommit: boolean,
  actionError: string | undefined,
  descriptorLoading: boolean,
  otherOperationsLoading: boolean,
  processorErrors?: ProcessorError[],
  branches?: string[],
) {
  if (!!processorErrors?.length) {
    return getProcessorError(processorErrors);
  }

  if (!canCommit) return `You are not allowed to update this draft release`;

  if (descriptorLoading)
    return 'Descriptor is still loading, please wait until it is ready';

  if (otherOperationsLoading)
    return 'Wait for the current operations to finish';
  if (actionError) {
    return actionError;
  }
  return (
    UPDATE_TOOLTIP +
    (branches
      ? `. These are the branches involved: ${branches?.join(',')}`
      : '')
  );
}
