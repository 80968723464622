import { customAlertApiRef } from '@agilelab/plugin-wb-platform';
import { useApi } from '@backstage/core-plugin-api';
import { useCallback } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import { panelCatalogApiRef } from '../../../api';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import {
  Action,
  generateURNByKind,
  TargetStatus,
} from '@agilelab/plugin-wb-builder-common';
import { Component } from '../types';

export function useDeploymentPreviewOperations(ids: string[]) {
  const { queryParamEnvironment, environment, release } =
    useReleaseDetailPageContext();

  const alertApi = useApi(customAlertApiRef);
  const panelCatalogApi = useApi(panelCatalogApiRef);

  const loadComponent = useCallback(async (componentId: string) => {
    return panelCatalogApi.getComponentDeploymentUnitsProvisioningPreview(
      generateURNByKind(
        release.metadata.projectName ?? release.metadata.dataProductName,
        'system',
      ),
      componentId,
      queryParamEnvironment || environment.name,
      true,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [previewOperations, fetchPreviewOperations] = useAsyncFn(async () => {
    try {
      const descriptors = await Promise.all(ids.map(loadComponent));

      const collectComponents = (components: Component[]): Component[] =>
        components.flatMap(component => [
          component,
          ...(component.components
            ? collectComponents(component.components as any[])
            : []),
        ]);

      const allComponents = descriptors.flatMap(descriptor => {
        const components = collectComponents(
          descriptor.provisioningDetails.descriptor.components as Component[],
        );
        return components
          .filter(component => ids.includes(component.id))
          .map(component => ({
            ...component,
            status: descriptor.status,
            action: Action.UNDEPLOY,
            newState: TargetStatus.NOT_DEPLOYED,
            statusVersion: descriptor.provisioningDetails.descriptorVersion,
          }));
      });

      return allComponents
        .filter(
          (component, index, self) =>
            index === self.findIndex(c => c.id === component.id),
        )
        .map(({ components, ...rest }) => rest);
    } catch (error) {
      alertApi.post({ error, severity: 'error' });
      return [];
    }
  }, [ids]);

  return { previewOperations, fetchPreviewOperations };
}
