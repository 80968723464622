import React, { Component, ErrorInfo } from 'react';

export type ErrorBoundaryProps = React.PropsWithChildren<{
  onError?: React.Dispatch<any>;
}>;

type State = {
  error?: Error;
  errorInfo?: ErrorInfo;
};

class MyErrorBoundary extends Component<ErrorBoundaryProps, State> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: undefined,
      errorInfo: undefined,
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error } = this.state;

    if (!error) {
      return this.props.children;
    }

    this.props.onError?.(error);
    return <></>;
  }
}

export default MyErrorBoundary;
