import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import {
  Box,
  Chip,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';

import { fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { WitBoostOutputPort } from './DataContractEditor';

const useStyles = makeStyles(() => ({
  optionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
}));

export function TagsField(props: {
  field: string;
  values?: string[];
  loading?: boolean;
  businessTerms: string[];
  dataProduct: WitboostEntity;
  outputPort: WitBoostOutputPort;
  onChange: (tags: string[]) => void;
}) {
  const theme = useTheme();
  const {
    field,
    values,
    loading: loadingGenerate,
    businessTerms,
    onChange,
    dataProduct,
    outputPort,
  } = props;
  const classes = useStyles();
  const fetchApi = useApi(fetchApiRef);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<string[]>([]);
  const [fetchedOptions, setFetchedOptions] = useState<string[]>([]);
  const loading = open && options.length === 0;

  useEffect(() => {
    const fetchData = async () => {
      const bestThreeBusinessResponse = await fetchApi.fetch(
        `https://schemaassistant.azurewebsites.net/api/SuggestTop3BusinessTerms?code=B9IMo7UZeUA_hWkO4K9478czmveJ7jUIbmapzD7oALJ1AzFuJR0EZA==`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            dataProduct,
            outputPort,
            field,
            businessTerms,
          }),
        },
      );
      const bestThreeBusiness =
        (await bestThreeBusinessResponse.json()) as Record<string, string[]>;

      const bestThreeBusinessOptions = bestThreeBusiness[field];

      setOptions([
        ...bestThreeBusinessOptions,
        ...businessTerms.filter(o => !bestThreeBusinessOptions.includes(o)),
      ]);
      setFetchedOptions([...bestThreeBusinessOptions]);
    };
    if (!loading) {
      return;
    }
    fetchData();
  }, [fetchApi, businessTerms, dataProduct, field, loading, outputPort]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [businessTerms, open]);

  if (loadingGenerate && !values) {
    return <Skeleton />;
  }

  return (
    <Autocomplete
      multiple
      options={options}
      freeSolo
      limitTags={4}
      style={{ width: '100%' }}
      open={open}
      loading={loading}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      renderOption={option => (
        <Box className={classes.optionContainer}>
          <Typography>{option}</Typography>
          {fetchedOptions.findIndex(o => o === option) > -1 && (
            <Typography
              style={{
                color: theme.palette.success.main,
              }}
            >
              best match
            </Typography>
          )}
        </Box>
      )}
      renderTags={(v: string[], getTagProps) =>
        v.map((option: string, index: number) => (
          <Chip
            variant="default"
            color="primary"
            label={option}
            size="small"
            {...getTagProps({ index })}
          />
        ))
      }
      onChange={(_event, newValue) => {
        onChange(newValue || []);
      }}
      value={values || []}
      renderInput={params => (
        <TextField
          {...params}
          variant="standard"
          size="small"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
