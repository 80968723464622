import React, { useEffect, useState } from 'react';
import { Box, TextFieldProps, makeStyles } from '@material-ui/core';
import { WbTextField } from '../../../WbTextField';
import { BaseSidebarFilter } from '../types';
import { FilterGroup } from '../FilterGroup';
import { WbCardActionButton } from '../../../WbCardActionButton';

type TextFilterGroupProps = BaseSidebarFilter &
  TextFieldProps & {
    onChange: (value: string) => void;
    value: string;
  };

const useStyles = makeStyles(theme => ({
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: theme.spacing(1),
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
  },
}));

export const TextFilterGroup = ({
  label,
  value,
  onChange,
  ...props
}: TextFilterGroupProps) => {
  const [internalValue, setInternalValue] = useState<string>(value);

  const classes = useStyles();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onChange(internalValue);
  };

  useEffect(() => {
    setInternalValue(value ?? '');
  }, [value]);

  return (
    <form onSubmit={onSubmit}>
      <FilterGroup label={label}>
        <WbTextField
          {...props}
          value={internalValue}
          onChange={e => setInternalValue(e.target.value)}
        />
        <Box className={classes.actionsContainer}>
          <WbCardActionButton
            label="Clear"
            variant="outlined"
            onClick={() => {
              onChange('');
              setInternalValue('');
            }}
          />
          <WbCardActionButton label="Apply" type="submit" />
        </Box>
      </FilterGroup>
    </form>
  );
};
