import { Box, Typography, Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { WbCardContent } from '../WbCard';
import { WbNoData } from '../WbNoData';
import { WbChip } from '../WbTextField';

const useStyles = makeStyles(
  theme => ({
    cardContent: {
      height: '100%',
      padding: theme.spacing(0),
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: theme.spacing(1),
    },
    selected: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(0.5),
      overflow: 'auto',
      height: '100%',
    },
  }),
  { name: 'MultiSelectionPicker' },
);

export const SelectedValues = ({
  values,
  setValues,
}: {
  values: any[];
  setValues: (values: any[]) => void;
}) => {
  const classes = useStyles();
  const handleDelete = (indexToDelete: number) => () => {
    setValues(values.filter((_, index) => index !== indexToDelete));
  };

  return (
    <WbCardContent className={classes.cardContent}>
      <Box className={classes.root}>
        <Typography variant="body1"> Your selection</Typography>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          disabled={!values?.length}
          onClick={() => setValues([])}
        >
          Clear all
        </Button>
      </Box>

      {!values?.length ? (
        <WbNoData
          fillContainer
          header="You haven't made any selection yet."
          text="Select what you need from the list on the left."
        />
      ) : (
        <Box className={classes.selected}>
          {values?.map((e: any, index: number) => (
            <WbChip
              key={e.name}
              label={e.name}
              onDelete={handleDelete(index)}
              style={{ width: 'fit-content' }}
            />
          ))}
        </Box>
      )}
    </WbCardContent>
  );
};
