import { TableRow, TableCell } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

export interface TableCellAnimationProps {
  cellProps?: {
    size?: 'small' | 'medium';
    style?: React.CSSProperties;
  };
  skeletonProps?: {
    animation?: false | 'pulse' | 'wave';
    variant?: 'text' | 'circle' | 'rect';
    width?: string | number;
    height?: string | number;
  };
}

export interface TableContentLoaderProps {
  tableCells: Array<TableCellAnimationProps> | number;
  rows: number;
  style?: React.CSSProperties;
}

export const TableContentLoader: React.FC<TableContentLoaderProps> = ({
  tableCells,
  rows,
  style,
}) => {
  const skeletons: Array<TableCellAnimationProps> =
    typeof tableCells === 'number' ? Array(tableCells).fill({}) : tableCells;

  return (
    <>
      {Array(rows)
        .fill(1)
        .map((_, index) => (
          <TableRow
            key={`row-${index}`}
            style={{
              position: 'relative',
              ...style,
            }}
          >
            {skeletons.map((cell, cellIndex) => (
              <TableCell key={`cell-${cellIndex}`} {...cell.cellProps}>
                <Skeleton {...cell.skeletonProps} />
              </TableCell>
            ))}
          </TableRow>
        ))}
    </>
  );
};
