import React from 'react';
import {
  CustomViewComponent,
  CustomViewPages,
  useCustomViewInfoFromConfig,
} from '@agilelab/plugin-wb-platform';

CustomViewPages.register({
  id: 'marketplace_system_general',
  function: function DataProductGeneral() {
    let sideInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.dataProductPage.sideInfo',
      { showOnlyWithValues: true, defaults: { colSpan: '4' } },
    );
    if (sideInfo.length === 0) {
      sideInfo = [
        <CustomViewComponent
          label="Owner"
          value="{{ dataProductOwnerDisplayName }}"
          showWhenExists="dataProductOwnerDisplayName"
          type="string"
          colSpan="4"
        />,
        <CustomViewComponent
          label="Environment"
          value="{{ _computedInfo.environment.name }}"
          type="string"
          colSpan="4"
        />,
      ];
    }
    const defaultGridInfo = [
      <CustomViewComponent
        type="date"
        value="{{ _computedInfo.published_at }}"
        label="Published Date"
      />,
    ];
    let gridInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.dataProductPage.gridInfo',
      { showOnlyWithValues: true },
    );
    gridInfo = gridInfo.length === 0 ? defaultGridInfo : gridInfo;

    const additionalGeneralInfo = useCustomViewInfoFromConfig(
      'mesh.marketplace.ui.dataProductPage.additionalGeneralInfo',
      { showOnlyWithValues: true },
    ).concat(gridInfo);
    return (
      <CustomViewComponent
        type="marketplace_info_card"
        title="General Information"
      >
        <CustomViewComponent type="hbox">
          <CustomViewComponent type="container" size={3}>
            <CustomViewComponent type="automatic_fields_list" path="" />
            {additionalGeneralInfo}
          </CustomViewComponent>
          <CustomViewComponent type="container" borderLeft>
            {sideInfo}
          </CustomViewComponent>
        </CustomViewComponent>
        <CustomViewComponent
          type="hline"
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
        />
        <CustomViewComponent
          showWhen={{
            value: '{{ tags.length }}',
            notEquals: 0,
          }}
          label="Tags"
          type="tags"
          path="tags"
        />
        <CustomViewComponent type="marketplace_flag_and_score" />
      </CustomViewComponent>
    );
  },
});
