import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { ReleaseEntityV1alpha1 } from '@agilelab/plugin-wb-builder-common';
import { Row } from './ReleaseRow';

type ReleaseTableProps = {
  releases: ReleaseEntityV1alpha1[];
};

export function ReleaseTable(props: ReleaseTableProps) {
  const { releases } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);

  const [isReleasing, setIsReleasing] = useState(false);
  const [isCommitting, setIsCommitting] = useState(false);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <TableContainer style={{ flex: '1 1 0' }}>
      <Table aria-label="releases table">
        <TableHead>
          <TableRow>
            <TableCell>Release</TableCell>
            <TableCell>Link</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {releases
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(release => (
              <Row
                key={release.metadata.name}
                release={release}
                isReleasing={isReleasing}
                isCommitting={isCommitting}
                setIsCommitting={setIsCommitting}
                setIsReleasing={setIsReleasing}
              />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={releases.length}
        page={page}
        rowsPerPageOptions={[3, 5, 10]}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}
