import React from 'react';
import { WbSearch } from '../../../WbSearch';
import { CommonFilters } from './types';

interface SearchFilterProps<T> {
  value: string;
  changeFilters: <K extends keyof T>(key: K, value: T[K]) => void;
}

export const SearchFilter = <T extends CommonFilters>({
  value,
  changeFilters,
}: SearchFilterProps<T>) => {
  return (
    <WbSearch
      fullWidth={false}
      value={value}
      onChange={(search: string) => changeFilters('search', search)}
      debounced
    />
  );
};
