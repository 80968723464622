import {
  AvailableSearchFilterValues,
  CollatorType,
  SearchFilterConfig,
  SearchFilterConfigType,
} from '@agilelab/plugin-wb-search-common';
import { JsonObject } from '@backstage/types';
import { WitboostSearchApi } from './api';
import {
  AvailableValuesBuildersByType,
  FilterAvailableValuesByLabel,
  FilterResolversByType,
  FilterResolversOverride,
} from './types';

const DEFAULT_CHOICE_RESOLVER: FilterResolversByType[SearchFilterConfigType.CHOICE] =
  async (values: AvailableSearchFilterValues) =>
    Promise.resolve((filter: SearchFilterConfig) =>
      (values[filter.label] ?? []).map(v => ({
        value: v.value,
        label: v.displayValue,
      })),
    );

const DEFAULT_DOMAIN_RESOLVER: FilterResolversByType[SearchFilterConfigType.DOMAIN] =
  async (values: AvailableSearchFilterValues) =>
    Promise.resolve((filter: SearchFilterConfig) => {
      const v = values[filter.label] ?? [];
      const nodes = v.map(i => ({
        id: i.value,
        label: i.displayValue ?? i.value,
        children: [],
      }));
      return nodes;
    });

const DEFAULT_BASE_RESOLVER = async (values: AvailableSearchFilterValues) =>
  Promise.resolve((filter: SearchFilterConfig) => values[filter.label]);

export const DEFAULT_TYPE_RESOLVERS: FilterResolversByType = {
  [SearchFilterConfigType.DOMAIN]: DEFAULT_DOMAIN_RESOLVER,
  [SearchFilterConfigType.CHOICE]: DEFAULT_CHOICE_RESOLVER,
  [SearchFilterConfigType.CONSUMABLE]: DEFAULT_BASE_RESOLVER,
  [SearchFilterConfigType.FAVORITES]: DEFAULT_BASE_RESOLVER,
  [SearchFilterConfigType.BOOLEAN]: DEFAULT_BASE_RESOLVER,
  [SearchFilterConfigType.DATE]: DEFAULT_BASE_RESOLVER,
  [SearchFilterConfigType.TEXT]: DEFAULT_BASE_RESOLVER,
  [SearchFilterConfigType.TYPE]: DEFAULT_BASE_RESOLVER,
};

/**
 *
 * @param type - The collator type
 * @param filterConfig - The config for the filters
 * @param searchApi - A reference to a searchApi
 * @param typeResolversOverride - An object that allows to override the default resolver function used for each type of filter
 * @param currentFilters - The current filters
 * @param queryTerm - The query term written in the search bar
 * @returns an object where each key is a filter label and the value its resolved available values
 */
export const resolveAvailableValues = async (
  type: CollatorType,
  filterConfig: SearchFilterConfig[],
  searchApi: WitboostSearchApi,
  typeResolversOverride: FilterResolversOverride = {},
  currentFilters?: JsonObject,
  queryTerm?: string,
): Promise<FilterAvailableValuesByLabel> => {
  const baseValues = await searchApi.getAvailableFilterValues(type, {
    currentFilters,
    queryTerm,
  });

  const overriddenResolvers = {
    ...DEFAULT_TYPE_RESOLVERS,
    ...typeResolversOverride,
  };

  const types = new Set(filterConfig.map(f => f.type));

  // call the resolver for each type
  const builders = await Promise.all(
    Array.from(types).map(async t => ({
      filterType: t,
      getterFn: await overriddenResolvers[t](baseValues),
    })),
  );

  const availableValuesBuildersByType = builders.reduce<any>((prev, curr) => {
    prev[curr.filterType] = curr.getterFn;
    return prev;
  }, {} as AvailableValuesBuildersByType);

  const availableValuesByLabel: FilterAvailableValuesByLabel = {};

  // build the available values for each filter
  filterConfig.forEach(
    f =>
      (availableValuesByLabel[f.label] =
        availableValuesBuildersByType[f.type](f)),
  );

  return availableValuesByLabel;
};
