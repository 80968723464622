import { ComponentType, SystemType } from '@agilelab/plugin-wb-builder-common';
import pluralize from 'pluralize';
import { EntityTypeLabel } from './types';

export const extractEntityTypeLabel = (
  entity: ComponentType | SystemType | undefined,
  type: string,
): EntityTypeLabel => {
  const label = {
    standard: type,
    pluralized: pluralize(type),
  };

  if (entity?.metadata.displayName) {
    label.standard = entity.metadata.displayName;
    label.pluralized = pluralize(entity.metadata.displayName);
  }

  if (entity?.metadata.pluralizedDisplayName)
    label.pluralized = entity.metadata.pluralizedDisplayName;

  return label;
};
