import { Action, TargetStatus } from '@agilelab/plugin-wb-builder-common';
import { DeploymentPreview } from '../types';

export type Preview = {
  action: Action;
  targetStatus: TargetStatus;
  componentId: string;
};

/**
 * Converts an array of DeploymentPreview objects to an array of Preview objects
 * @param  {DeploymentPreview[]} previews The input array
 * @param cleanUpPreviews
 * @param  {Boolean} extractSubcomponents If true, subcomponents from each DeploymentPreview will be extracted and added to the result array as well (in a flattened way)
 * @return {Preview[]} The result array
 */
export const createPreviews = (
  previews: DeploymentPreview[],
  cleanUpPreviews: DeploymentPreview[] = [],
  extractSubcomponents: boolean = true,
): Preview[] => {
  const addPreviews = (items: DeploymentPreview[], result: Preview[]) => {
    items.forEach(p => {
      result.push({
        action: p.action!,
        targetStatus: p.newState!,
        componentId: p.id,
      });
      if (extractSubcomponents && p.components) {
        p.components.forEach(c => {
          result.push({
            action: p.action!,
            targetStatus: p.newState!,
            componentId: c.id,
          });
        });
      }
    });
    return result;
  };

  let newPreviews: Preview[] = [];
  newPreviews = addPreviews(previews, newPreviews);
  newPreviews = addPreviews(cleanUpPreviews, newPreviews);

  return newPreviews;
};
