import React, { Fragment } from 'react';
import { WbShowMoreElement } from '../WbShowMoreElement';
import { WbLink } from '../WbLink';

type Props = {
  value: string;
  href?: string;
  maxLines?: number;
};

export const ExpandableItem = ({ value, href, maxLines }: Props) => {
  const renderedValue = (
    <WbShowMoreElement maxLines={maxLines}>{value}</WbShowMoreElement>
  );

  return (
    <Fragment>
      {href ? (
        <WbLink
          route={href}
          externalLink={
            href.startsWith('http') || href.startsWith('mailto')
              ? href
              : undefined
          }
          underlineLink="hover"
          text={value}
          showTooltip
        />
      ) : (
        renderedValue
      )}
    </Fragment>
  );
};
