import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-prototype',
});

export const editPrototypeRouteRef = createSubRouteRef({
  id: 'catalog-release',
  parent: rootRouteRef,
  path: '/edit',
});
