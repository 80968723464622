import { gql } from '@apollo/client/core';

export const GET_DATA_PRODUCT_INSTANCE_DEPENDENCIES_BY_ID_AND_ENV_NAME = gql`
  query GetDataProductInstanceDependenciesByIdAndEnvName(
    $id: bigint!
    $envName: String!
  ) {
    instances: marketplace_instances(
      where: { id: { _eq: $id }, environment: { name: { _eq: $envName } } }
    ) {
      id
      name
      display_name
      domain: relations(where: { name: { _eq: "partOfDomain" } }) {
        data: instanceByTargetInstanceId {
          name
        }
      }
      owner
      owner_display_name
      inputPorts: relations(
        where: {
          _and: [
            { name: { _eq: "readsFrom" } }
            {
              _or: [
                { instanceByTargetInstanceId: { kind: { _eq: "resource" } } }
                {
                  instanceByTargetInstanceId: {
                    kind: { _eq: "component" }
                    relations: {
                      _or: [
                        {
                          name: { _eq: "partOfSystem" }
                          instanceByTargetInstanceId: {
                            kind: { _eq: "system" }
                          }
                        }
                        {
                          name: { _eq: "partOfComponent" }
                          instanceByTargetInstanceId: {
                            kind: { _eq: "component" }
                            relations: {
                              name: { _eq: "partOfSystem" }
                              instanceByTargetInstanceId: {
                                kind: { _eq: "system" }
                              }
                            }
                          }
                        }
                      ]
                    }
                  }
                }
              ]
            }
          ]
        }
      ) {
        component: instanceByTargetInstanceId {
          id
          name
          display_name
          kind
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
          parentComponent: relations(
            where: { name: { _eq: "partOfComponent" } }
          ) {
            data: instanceByTargetInstanceId {
              system: relations(where: { name: { _eq: "partOfSystem" } }) {
                data: instanceByTargetInstanceId {
                  id
                  version
                  name
                  display_name
                  domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                    data: instanceByTargetInstanceId {
                      name
                    }
                  }
                }
              }
            }
          }
          system: relations(where: { name: { _eq: "partOfSystem" } }) {
            data: instanceByTargetInstanceId {
              id
              version
              name
              display_name
              domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                data: instanceByTargetInstanceId {
                  name
                }
              }
            }
          }
        }
      }
      outputPorts: relationsByTargetInstanceId(
        where: {
          _and: [
            { name: { _eq: "partOfSystem" } }
            {
              instance: {
                _or: [
                  {
                    relationsByTargetInstanceId: { name: { _eq: "readsFrom" } }
                  }
                  {
                    relationsByTargetInstanceId: {
                      name: { _eq: "partOfComponent" }
                      instance: {
                        relationsByTargetInstanceId: {
                          name: { _eq: "readsFrom" }
                        }
                      }
                    }
                  }
                ]
              }
            }
          ]
        }
      ) {
        data: instance {
          id
          kind
          type
          childComponent: relationsByTargetInstanceId(
            where: {
              name: { _eq: "partOfComponent" }
              instance: { kind: { _eq: "component" } }
            }
          ) {
            data: instance {
              system: relationsByTargetInstanceId(
                where: {
                  name: { _eq: "readsFrom" }
                  instance: { kind: { _eq: "system" } }
                }
              ) {
                data: instance {
                  id
                  version
                  environment {
                    name
                  }
                  name
                  display_name
                  domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                    data: instanceByTargetInstanceId {
                      name
                    }
                  }
                }
              }
            }
          }
          system: relationsByTargetInstanceId(
            where: {
              name: { _eq: "readsFrom" }
              instance: { kind: { _eq: "system" } }
            }
          ) {
            data: instance {
              id
              version
              environment {
                name
              }
              name
              display_name
              domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                data: instanceByTargetInstanceId {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;
