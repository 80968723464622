import { matchRoutes, useParams, useRoutes } from 'react-router';

/*
 * This hook is copied from Backstage 1.19.0 (packages/core-components/src/components/TabbedLayout/RoutedTabs.tsx)
 * CHANGES:
 *  - make the input array subRoutes generic as long as it has the required base properties defined in SubRouteBase
 */

type SubRouteBase = {
  path: string;
  title: string;
  children: JSX.Element;
};

// Given the same routes passed to RoutedTabs (by backstage), returns which of them is the currently selected one.
// Useful for ancestors of <RoutedTabs /> to know what the selected tab is and change rendering logic according to that
export function useSelectedRoutedTab<T extends SubRouteBase>(
  subRoutes: T[],
): {
  index: number;
  route?: T;
  element?: JSX.Element;
} {
  const params = useParams();

  const routes = subRoutes.map(({ path, children }) => ({
    caseSensitive: false,
    path: `${path}/*`,
    element: children,
  }));

  // TODO: remove once react-router updated
  const sortedRoutes = routes.sort((a, b) =>
    // remove "/*" symbols from path end before comparing
    b.path.replace(/\/\*$/, '').localeCompare(a.path.replace(/\/\*$/, '')),
  );

  const element = useRoutes(sortedRoutes) ?? subRoutes[0]?.children;

  // TODO(Rugvip): Once we only support v6 stable we can always prefix
  // This avoids having a double / prefix for react-router v6 beta, which in turn breaks
  // the tab highlighting when using relative paths for the tabs.
  let currentRoute = params['*'] ?? '';
  if (!currentRoute.startsWith('/')) {
    currentRoute = `/${currentRoute}`;
  }

  const [matchedRoute] = matchRoutes(sortedRoutes, currentRoute) ?? [];
  const foundIndex = matchedRoute
    ? subRoutes.findIndex(t => `${t.path}/*` === matchedRoute.route.path)
    : 0;

  return {
    index: foundIndex === -1 ? 0 : foundIndex,
    element,
    route: subRoutes[foundIndex] ?? subRoutes[0],
  };
}
