import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import { CatalogApi } from '@backstage/plugin-catalog-react';

function chunkArray<T>(array: T[], chunkSize: number = 25): T[][] {
  const chunks: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }

  return chunks;
}

export const getEntitiesDisplayNames = async (
  catalogApi: CatalogApi,
  entitiesFilters: { 'metadata.name': string }[],
  options?: {
    chunkLimit?: number;
  },
): Promise<Map<string, string>> => {
  const chunkLimit = options?.chunkLimit ?? 50;

  const requests = (
    entitiesFilters.length > chunkLimit
      ? chunkArray<{ 'metadata.name': string }>(entitiesFilters, chunkLimit)
      : [entitiesFilters]
  ).map(filtersChunk =>
    catalogApi.getEntities({
      filter: [filtersChunk as any],
    }),
  );

  const entities = (await Promise.all(requests))
    .flat()
    .map(getEntitiesResponse => getEntitiesResponse.items)
    .flat();

  return new Map<string, string>(
    entities.map(entity => [
      entity.metadata.name.toLowerCase(),
      (entity as WitboostEntity).spec?.mesh?.name ?? entity.metadata.name,
    ]),
  );
};
