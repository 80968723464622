import React, { useMemo } from 'react';
import { createStyles, ListItem, makeStyles, Theme } from '@material-ui/core';
import { ComponentItem } from './ComponentItem';
import { PolicyItem } from './PolicyItem';
import { DagStatus } from '@agilelab/plugin-wb-builder-common';
import { GovernanceEntityType } from '@agilelab/plugin-wb-governance-common';
import { MetricItem } from './MetricItem';
import { Task } from '@agilelab/plugin-wb-builder-catalog';
import { useEditorPageContext } from '../../context/useEditorPageContext';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';

interface Props {
  task: Task;
  governanceEntities: GovernanceEntitiesQueryType['cgp_governance_entity'];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1),
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: theme.palette.bkg.primary,
      },
    },
  }),
);

export const TaskListItem: React.FC<Props> = ({ task, governanceEntities }) => {
  const { setSelectedTask } = useEditorPageContext();
  const classes = useStyles();
  const policy = useMemo(
    () =>
      governanceEntities.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Policy,
      ),
    [governanceEntities, task.governanceEntityId, task.governanceEntityType],
  );
  const metric = useMemo(
    () =>
      governanceEntities.find(
        p =>
          task.governanceEntityId === p.governance_entity_id &&
          task.governanceEntityType === GovernanceEntityType.Metric,
      ),
    [governanceEntities, task.governanceEntityId, task.governanceEntityType],
  );

  const isValid = !!policy || !!metric || task.status === DagStatus.FAILED;

  return (
    <ListItem
      onClick={() => {
        if (isValid) setSelectedTask(task);
      }}
      className={classes.detailWrapper}
      style={{
        cursor: isValid ? 'pointer' : 'auto',
      }}
    >
      {policy && <PolicyItem policy={policy} task={task} />}
      {metric && <MetricItem metric={metric} task={task} />}
      {!policy && !metric && (
        <ComponentItem
          description={task.displayName ?? task.name}
          componentName={task.componentName}
          status={task.status}
        />
      )}
    </ListItem>
  );
};
