import React from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { Separator } from '../types';

const useStyles = makeStyles(
  theme => ({
    root: {
      ...theme.typography.body2,
      color: theme.palette.secondary.light,
    },
  }),
  { name: 'SecondarySeparator' },
);

export const SecondarySeparator: Separator = () => {
  const classes = useStyles();

  // '&gt;' is '>'
  return <Typography className={classes.root}>&gt;</Typography>;
};
