import { useCallback, useMemo } from 'react';
import { CheckboxNodeWithParent, CheckboxTreeProps } from '../types';
import {
  buildItemsWithParents,
  getFlatSelectionPreview,
  getLeaves,
  getSubtreeSet,
} from '../utils';

export function useCheckboxTree<T>({
  items,
  selected,
  onSelectionChange,
  expanded,
  disabled,
  onExpandChange,
}: Pick<
  CheckboxTreeProps<T>,
  | 'items'
  | 'selected'
  | 'onExpandChange'
  | 'expanded'
  | 'onSelectionChange'
  | 'disabled'
>) {
  const expandedSet = useMemo(() => new Set(expanded), [expanded]);

  const selectedSet = useMemo(() => new Set(selected), [selected]);

  const disabledSet = useMemo(() => new Set(disabled ?? []), [disabled]);

  const itemsWithParent = useMemo<CheckboxNodeWithParent<T>[]>(
    () => buildItemsWithParents(items),
    [items],
  );
  const flatSelectionPreview = useMemo(
    () =>
      getFlatSelectionPreview(
        itemsWithParent,
        selectedSet,
        onSelectionChange,
        onExpandChange,
      ),
    [itemsWithParent, selectedSet, onSelectionChange, onExpandChange],
  );

  const clearAll = useCallback(() => {
    onSelectionChange([]);
  }, [onSelectionChange]);

  const selectAll = useCallback(() => {
    const selectedAll = items
      .map(i =>
        Array.from(
          getLeaves(i, node => !node.disabled && !disabledSet.has(node.id)),
        ),
      )
      .flat();
    onSelectionChange(selectedAll);
  }, [onSelectionChange, items, disabledSet]);

  const expandAll = useCallback(() => {
    const expandedAll = items.map(i => Array.from(getSubtreeSet(i))).flat();
    onExpandChange(expandedAll);
  }, [onExpandChange, items]);

  const collapseAll = useCallback(() => {
    onExpandChange([]);
  }, [onExpandChange]);

  return {
    expandedSet,
    selectedSet,
    disabledSet,
    flatSelectionPreview,
    itemsWithParent,
    clearAll,
    selectAll,
    collapseAll,
    expandAll,
  };
}
