import { Observable } from '../../lib/observable';
import { PublishSubject } from '../../lib/subjects';
import { CustomAlertApi, CustomAlertMessage } from './CustomAlertApi';

export class CustomAlertApiForwarder implements CustomAlertApi {
  private readonly subject = new PublishSubject<CustomAlertMessage>();

  post(alert: CustomAlertMessage) {
    this.subject.next(alert);
  }

  alert$(): Observable<CustomAlertMessage> {
    return this.subject;
  }
}
