import { RefObject, useLayoutEffect, useRef } from 'react';

export function useResizeObserver<T extends HTMLElement>(
  callback: (target: T, entry: ResizeObserverEntry) => void,
  ref?: RefObject<T>,
) {
  const internalRef = useRef<T>(null);

  const usedRef = ref || internalRef;

  useLayoutEffect(() => {
    const element = usedRef?.current;

    if (!element) {
      return undefined;
    }

    const observer = new ResizeObserver(entries => {
      callback(element, entries[0]);
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [callback, usedRef]);

  return usedRef;
}

export default useResizeObserver;
