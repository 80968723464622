import { createTopic } from '../lib';
import { z } from 'zod';

const entityNavigatedEventSchema = z.object({
  entityRef: z.string(),
});

export type EntityNavigatedEvent = z.infer<typeof entityNavigatedEventSchema>;

export const entityNavigatedTopic = createTopic<EntityNavigatedEvent>(
  {
    namespace: 'catalog',
    name: 'entity-navigated',
    description: 'A system/component has been navigated',
  },
  entityNavigatedEventSchema,
);
