import React from 'react';
import { Typography, TypographyProps, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type WbSelectInlineHeaderProps = {} & TypographyProps;

const useStyles = makeStyles(
  theme => ({
    root: {
      color: theme.palette.secondary.light,
    },
  }),
  { name: 'WbSelectInlineHeader' },
);

// wraps the typography component and forward all props except for onClick and role,
// which would otherwise be added when cloned by <Select /> and this element would mistakenly be clickable as option in the select menu
export const WbSelectInlineHeader = ({
  className,
  ...props
}: WbSelectInlineHeaderProps) => {
  const classes = useStyles();
  return (
    <Typography
      variant="body2"
      className={clsx(classes.root, className)}
      {...props}
      onClick={undefined}
      role="presentation"
    />
  );
};
