import { z } from 'zod';

const NoteSchema = z.object({
  message: z.string(),
});

const NotesSchema = z.object({
  errorSummary: NoteSchema.optional(),
});

export type Validation = {
  key: string;
  name?: string;
  description?: string;
  compliant?: boolean;
  issues?: string[];
  children?: Validation[];
};

const ValidationSchema: z.ZodType<Validation> = z.lazy(() =>
  z.object({
    key: z.string(),
    name: z.string().optional(),
    description: z.string().optional(),
    compliant: z.boolean().optional(),
    issues: z.array(z.string()).optional(),
    children: z.array(ValidationSchema).optional(),
  }),
);

const ResultSchema = z.array(ValidationSchema);

const GuardianPolicyResultDetailsSchema = z.object({
  notes: NotesSchema.optional(),
  results: z.record(z.string(), ResultSchema),
});

export {
  GuardianPolicyResultDetailsSchema,
  NoteSchema,
  NotesSchema,
  ValidationSchema,
  ResultSchema,
};

export type GuardianPolicyResultDetails = z.infer<
  typeof GuardianPolicyResultDetailsSchema
>;

export type StatusNotes = z.infer<typeof NotesSchema>;
export type Result = z.infer<typeof ResultSchema>;

// A validation whose compliance status of parents is inferred by their subtree compliance
export type InferredComplianceValidation = Omit<Validation, 'children'> & {
  compliant: boolean;
  children?: InferredComplianceValidation[];
};
export type ParsedGuardianPolicyResultDetail = {
  results: Record<
    string,
    { compliant: boolean; validations: InferredComplianceValidation[] }
  >;
  notes?: StatusNotes;
};
