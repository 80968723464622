import { Box, makeStyles } from '@material-ui/core';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import React from 'react';
import { NewDraftReleaseButton } from './NewDraftReleaseButton';
import { WbNoData } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(theme => ({
  iconContainer: {
    border: `1px solid ${theme.palette.softened.primary}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '32px',
    borderRadius: '100%',
    borderStyle: 'dashed',
    // to separate the icon from the header and text
    margin: theme.spacing(2.5),
  },
  icon: {
    fontSize: '105px',
    color: theme.palette.softened.primary,
  },
}));

export const ReleaseTableNoData: React.FC = () => {
  const classes = useStyles();

  return (
    <WbNoData
      fillContainer
      header=" No release seems to have been created."
      icon={
        <Box className={classes.iconContainer}>
          <LayersClearIcon className={classes.icon} />
        </Box>
      }
      text="Let’s start and create a new release"
      primaryAction={<NewDraftReleaseButton size="large" />}
    />
  );
};
