import { gql } from '@apollo/client/core';

export const getProjectsGraphBuildWhere = (
  env: string,
  descriptorFilter: Record<string, any>,
  taxonomy?: string,
) => {
  const where = {
    _and: [
      { environment: { name: { _eq: env } } },
      { kind: { _eq: 'system' } },
      { consumable: { _is_null: false } },
      { descriptor: { _contains: descriptorFilter } },
    ],
  };

  if (taxonomy)
    (where._and as any[]).push({
      taxonomy: { external_id: { _eq: taxonomy } },
    });

  return where;
};

export const GET_PROJECTS_GRAPH = gql`
  query GetProjectsGraph(
    $where: marketplace_instances_bool_exp
    $_descriptorFilter: jsonb
  ) {
    dataProductInstance: marketplace_instances(where: $where) {
      id
      version
      descriptor
      name
      display_name
      domain: relations(where: { name: { _eq: "partOfDomain" } }) {
        data: instanceByTargetInstanceId {
          external_id
        }
      }
      environment {
        name
      }
      inputPorts: relations(
        where: {
          _and: [
            { name: { _eq: "readsFrom" } }
            {
              instanceByTargetInstanceId: {
                kind: { _eq: "component" }
                relations: {
                  _or: [
                    {
                      name: { _eq: "partOfSystem" }
                      instanceByTargetInstanceId: { kind: { _eq: "system" } }
                    }
                    {
                      name: { _eq: "partOfComponent" }
                      instanceByTargetInstanceId: {
                        kind: { _eq: "component" }
                        relations: {
                          name: { _eq: "partOfSystem" }
                          instanceByTargetInstanceId: {
                            kind: { _eq: "system" }
                          }
                        }
                      }
                    }
                  ]
                }
              }
            }
          ]
        }
      ) {
        data: instanceByTargetInstanceId {
          parentComponent: relations(
            where: { name: { _eq: "partOfComponent" } }
          ) {
            data: instanceByTargetInstanceId {
              partOfSystem: relations(
                where: { name: { _eq: "partOfSystem" } }
              ) {
                system: instanceByTargetInstanceId {
                  id
                  environment_id
                }
              }
            }
          }
          partOfSystem: relations(where: { name: { _eq: "partOfSystem" } }) {
            system: instanceByTargetInstanceId {
              id
              environment_id
            }
          }
        }
      }
    }
  }
`;

export const getDataContractsBuildWhere = (env: string, taxonomy?: string) => {
  const taxonomyFilter = {
    taxonomy: { external_id: { _eq: taxonomy } },
  };

  const where = {
    _and: [
      { environment: { name: { _eq: env } } },
      { kind: { _neq: 'resource' } },
      {
        relationsByTargetInstanceId: {
          name: { _eq: 'readsFrom' },
          instance: taxonomy ? taxonomyFilter : undefined,
        },
      },
    ],
  };

  return where;
};

export const GET_DATA_CONTRACTS = gql`
  query GetDataContracts(
    $where: marketplace_instances_bool_exp
    $consumableInterfaceTypeField: String
  ) {
    marketplace_instances(where: $where) {
      dataProduct: relationsByTargetInstanceId(
        where: { _or: [{ name: { _eq: "readsFrom" } }] }
      ) {
        data: instance {
          id
          version
          display_name
          external_id
          kind
          type
          owner_display_name
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
        }
      }
      id
      mode: additional_attributes(path: "$.mode")
      display_name
      external_id
      outputporttype: descriptor(path: $consumableInterfaceTypeField)
      version
      parentComponent: relations(where: { name: { _eq: "partOfComponent" } }) {
        data: instanceByTargetInstanceId {
          system: relations(where: { name: { _eq: "partOfSystem" } }) {
            data: instanceByTargetInstanceId {
              id
              version
              display_name
              kind
              type
              external_id
              owner_display_name
              domain: relations(where: { name: { _eq: "partOfDomain" } }) {
                data: instanceByTargetInstanceId {
                  name
                }
              }
            }
          }
        }
      }
      dataProductInstance: relations(where: { name: { _eq: "partOfSystem" } }) {
        data: instanceByTargetInstanceId {
          id
          version
          display_name
          kind
          type
          external_id
          owner_display_name
          domain: relations(where: { name: { _eq: "partOfDomain" } }) {
            data: instanceByTargetInstanceId {
              name
            }
          }
        }
      }
      port_warnings {
        id
        kind
        message
        content
      }
    }
  }
`;

export const GET_DOMAINS_BY_TYPES = gql`
  query GetDomainsByTypes($compatibleTypes: [String!]) {
    Domains: marketplace_instances(
      where: {
        _and: [{ kind: { _eq: "domain" } }, { type: { _in: $compatibleTypes } }]
      }
    ) {
      id: external_id
      name
      type
      sub_domain_of: relations(where: { name: { _eq: "subDomainOf" } }) {
        data: instanceByTargetInstanceId {
          id: external_id
        }
      }
    }
  }
`;

export const GET_DOMAINS = gql`
  query GetDomains {
    Domains: marketplace_instances(where: { kind: { _eq: "domain" } }) {
      id: external_id
      name
      type
      sub_domain_of: relations(where: { name: { _eq: "subDomainOf" } }) {
        data: instanceByTargetInstanceId {
          id: external_id
        }
      }
    }
  }
`;

export const GET_ENVIRONMENTS = gql`
  query getEnvs {
    marketplace_environments {
      name
      id
    }
  }
`;

export const GET_OWNERS = gql`
  query getOwners($_env: String!) {
    marketplace_instances(
      distinct_on: owner
      where: {
        _and: [
          { environment: { name: { _eq: $_env } } }
          { owner: { _is_null: false } }
        ]
      }
    ) {
      owner
      owner_display_name
    }
  }
`;
