import { createSvgIcon } from '@material-ui/core';
import React from 'react';

export const NotBlockingErrorIcon = createSvgIcon(
  <>
    <svg
      width="24"
      height="24"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="8" fill="currentColor" />
      <circle
        cx="16"
        cy="16"
        r="12.25"
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeDasharray="2 6"
      />
    </svg>
  </>,
  'NotBlockingErrorIcon',
);
