import { WbTable } from '@agilelab/plugin-wb-platform';
import { TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useState } from 'react';
import { TaskDrawer } from '../TaskDrawer/TaskDrawer';
import { useTestCard } from '../TestCard/useTestCard';
import { Task, Test } from '../types';
import { TestTableLoader } from './Loaders/TestTableLoader';
import { TestRow } from './TestRow';

export type SelectedRow = {
  testId: string;
  detailIndex?: string;
};

export function TestTable() {
  const { tests, pagination, setPagination, fetchTestsState, totalTestCount } =
    useTestCard();
  const [selectedRow, setSelectedRow] = useState<SelectedRow | null>(null);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const handleChangePage = (newPage: number) => {
    setPagination(v => ({
      limit: v.limit,
      offset: newPage * v.limit,
    }));
  };

  const handleChangeRowsPerPage = (limit: number) => {
    setPagination({
      limit: limit,
      offset: 0,
    });
  };

  if (!tests) return <></>;

  return (
    <>
      <WbTable<Test>
        stickyHeader
        components={{
          tableLoader: {
            loading: fetchTestsState.loading,
            loader: <TestTableLoader rows={pagination.limit} />,
          },
          tableHeader: (
            <TableHead>
              <TableRow>
                <TableCell>Status</TableCell>
                <TableCell>ID / Date</TableCell>
                <TableCell>Results</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
          ),
          tableContent: {
            body: (
              <TableBody>
                {tests.map((t, i) => (
                  <TestRow
                    key={i}
                    test={t}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                    setSelectedTask={setSelectedTask}
                    openDrawer={() => setOpenDetailDrawer(true)}
                  />
                ))}
              </TableBody>
            ),
          },
        }}
        pagination={{
          rowsPerPageOptions: [5, 10, 25],
          count: totalTestCount,
          limit: pagination.limit,
          offset: pagination.offset,
          onPageChange: handleChangePage,
          onRowsPerPageChange: handleChangeRowsPerPage,
        }}
        styles={{
          container: { height: '100%', flex: '1 1 0' },
        }}
      />
      <TaskDrawer
        task={selectedTask}
        open={openDetailDrawer}
        onClose={() => {
          setOpenDetailDrawer(false);
          setSelectedRow(v => (v ? { ...v, detailIndex: undefined } : null));
        }}
      />
    </>
  );
}
