import { Box, useTheme } from '@material-ui/core';
import React from 'react';
import {
  BrandIcon,
  ContentCopyIcon,
  DownloadIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from '../icons/icons';

export const Feedbacks: React.FC = () => {
  const theme = useTheme();
  const style: React.CSSProperties = { color: theme.palette.secondary.main };
  return (
    <Box display="flex" alignItems="center" style={{ gap: theme.spacing(1) }}>
      <BrandIcon style={style} />
      <ContentCopyIcon style={style} />
      <DownloadIcon style={style} />
      <ThumbDownIcon style={style} />
      <ThumbUpIcon style={style} />
    </Box>
  );
};
