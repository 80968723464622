/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import { rbacApiRef } from '@agilelab/plugin-wb-rbac';
import { RbacApi } from '@agilelab/plugin-wb-rbac-common';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import FormControl from '@material-ui/core/FormControl';
import React, { useCallback, useEffect, useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

import { WbAutocomplete } from '@agilelab/plugin-wb-platform';
import {
  WmCompleteUiSchema,
  WmFieldExtensionComponentProps,
} from '../../../extensions/types';
import { extractCustomProperties, isHidden } from '../../utils';

type RoleOption = { label: string; id: string };

/**
 * The input props that can be specified under `ui:options` for the
 * `EntityPicker` field extension.
 *
 * @public
 */
export interface RbacRolePickerUiOptions {
  allowArbitraryValues?: boolean;
  'ui:widget'?: string;
}

export const allowArbitraryValues = (
  uiSchema: WmCompleteUiSchema<RbacRolePickerUiOptions>,
): boolean => (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;

/**
 * The underlying component that is rendered in the form for the `EntityPicker`
 * field extension.
 *
 * @public
 */
export const RbacRolePicker = (
  props: WmFieldExtensionComponentProps<string, RbacRolePickerUiOptions>,
) => {
  const {
    onChange,
    schema: { title = 'Role', description = 'A defined role from RBAC' },
    required,
    uiSchema,
    rawErrors,
    idSchema,
    formContext,
  } = props;
  const rbacApi = useApi(rbacApiRef);
  const identityApi = useApi(identityApiRef);
  const customProps = extractCustomProperties(uiSchema);
  const [autocompleteValue, setAutocompleteValue] = useState<RoleOption | null>(
    null,
  );

  const { value: rolesOptions, loading } = useAsync(async () => {
    return fetchRoles(rbacApi, '', (await identityApi.getCredentials()).token);
  }, [formContext]);

  useEffect(() => {
    if (rolesOptions?.length === 1) {
      setAutocompleteValue(rolesOptions[0]);
      return;
    }

    if (!autocompleteValue) {
      setAutocompleteValue(
        rolesOptions?.find(option => option.id === props.schema?.default) ??
          null,
      );
    }
  }, [rolesOptions, props.schema, props.schema?.default, autocompleteValue]);

  const onSelect = useCallback(
    (_: any, val: string | RoleOption | null) => {
      if (typeof val === 'string') {
        return;
      }

      onChange(val?.id ?? null);
      setAutocompleteValue(val);
    },
    [onChange],
  );
  const hiddenFieldValue = uiSchema['ui:options']?.['ui:widget'];
  const isHiddenField = hiddenFieldValue === 'hidden';

  return (
    <FormControl
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      required={required}
      error={rawErrors?.length > 0 && !rolesOptions}
    >
      <WbAutocomplete
        style={{
          display: isHiddenField ? 'none' : 'flex',
          width: '100%',
          height: '100%',
        }}
        disabled={!rolesOptions || rolesOptions?.length <= 1 || loading}
        id={idSchema?.$id}
        loading={loading}
        onChange={onSelect}
        options={rolesOptions || []}
        getOptionLabel={(role: any) => role.label}
        value={autocompleteValue}
        freeSolo={allowArbitraryValues(uiSchema)}
        getOptionSelected={(option, val) => option === val}
        label={title}
        helperText={description}
        required={required}
        {...customProps}
      />
    </FormControl>
  );
};

async function fetchRoles(
  rbacApi: RbacApi,
  value?: string,
  token?: string,
): Promise<RoleOption[]> {
  const roles = await rbacApi.getRoles({
    searchKeyword: value,
    options: { token, visibility: 'user' },
  });
  return roles.map(role => ({
    label: role.displayName ?? role.id,
    id: role.id,
  }));
}
