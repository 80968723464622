import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useNavigate } from 'react-router';
import { MarketplaceSearchResultEnriched } from '../types';
import { EntityIcon } from '../../Entity';

const useStyles = makeStyles(theme => ({
  listItem: {
    height: '8vh',
    minHeight: '8vh',
    padding: theme.spacing(1, 2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    rowGap: theme.spacing(1),
    maxWidth: '100%',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: `${theme.palette.bkg.primary}`,
    },
  },
  contentContainer: {
    display: 'flex',
    columnGap: theme.spacing(1),
  },
  icon: {
    flexShrink: 0,
    width: '1.5rem',
    height: '1.5rem',
  },
  description: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    fontSize: theme.typography.body1.fontSize,
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block',
    fontWeight: 700,
    fontSize: theme.typography.body1.fontSize,
    color: theme.palette.primary.main,
  },
}));

export const SearchFavoriteListItem = ({
  entity,
}: {
  entity: MarketplaceSearchResultEnriched | undefined;
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  if (!entity) return null;

  const handleRedirect = () => {
    navigate(entity.document.location);
  };

  return (
    <Box className={classes.listItem} onClick={handleRedirect}>
      <Box className={classes.contentContainer}>
        <EntityIcon
          title={entity.document._nameWithVersion}
          useCaseTemplateId={entity.document.useCaseTemplateId}
          kind={entity.document._computedInfo.kind}
          className={classes.icon}
        />
        <Typography variant="body1" className={classes.title}>
          {entity.document._nameWithVersion}
        </Typography>
      </Box>
      <Typography variant="body1" className={classes.description}>
        {entity.document.description}
      </Typography>
    </Box>
  );
};
