import React, { useEffect } from 'react';
import { WmUiSchema } from '../../../extensions/types';
import { WmFieldExtensionComponentProps } from '../../../extensions/types';
import { WbTextField } from '@agilelab/plugin-wb-platform';
import { generateIdentifier } from '@agilelab/plugin-wb-platform-common';
import { isHidden } from '../../utils';

export const allowArbitraryValues = (uiSchema: WmUiSchema): boolean =>
  (uiSchema['ui:options']?.allowArbitraryValues as boolean) ?? true;

export const IdentifierPicker = (
  props: WmFieldExtensionComponentProps<string, any>,
) => {
  const {
    onChange,
    schema: {
      title = 'Identifier',
      description = 'Unique identifier of the component',
    },
    required,
    rawErrors,
    formData,
    uiSchema,
    idSchema,
    placeholder,
    formContext,
  } = props;
  const autoFocus = uiSchema['ui:autofocus'];
  useEffect(() => {
    onChange(generateIdentifier(formContext.name, formContext.domain) ?? '');
  }, [formContext.domain, formContext.name, onChange]);

  return (
    <WbTextField
      id={idSchema?.$id}
      style={{ display: isHidden(uiSchema) ? 'none' : undefined }}
      label={title}
      placeholder={placeholder}
      helperText={description}
      disabled={!allowArbitraryValues(uiSchema)}
      required={required}
      value={formData ?? ''}
      onChange={({ target: { value } }) => onChange(value)}
      margin="normal"
      error={rawErrors?.length > 0 && !formData}
      inputProps={{ autoFocus }}
    />
  );
};
