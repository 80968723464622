import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const GraceIcon = createSvgIcon(
  <>
    <g id="Layer_1-2">
      <g id="Group_535">
        <path d="m12,0C5.37,0,0,5.37,0,12s5.37,12,12,12,12-5.37,12-12S18.62,0,12,0Zm-5.65,6.47c1.51-1.5,3.52-2.33,5.65-2.33.55,0,1.01.45,1.01,1s-.44,1-.99,1h-.02c-1.6,0-3.11.62-4.24,1.75-.2.19-.45.29-.71.29s-.51-.1-.71-.3c-.39-.39-.38-1.02.01-1.41Zm1.35,11.34c-.2.19-.45.29-.71.29s-.51-.1-.71-.3c-1.49-1.51-2.31-3.51-2.31-5.63,0-.56.45-1.02,1-1.02s1,.42,1,.98v.04c0,1.59.62,3.09,1.74,4.23.38.39.38,1.02,0,1.41Zm9.91.09c-1.51,1.48-3.5,2.29-5.61,2.29-.56,0-1.03-.45-1.03-1s.41-1,.96-1h.07c1.58,0,3.08-.61,4.21-1.72.4-.39,1.03-.38,1.42.01.38.4.38,1.03-.02,1.42Zm.41-5.73c0-1.61-.62-3.12-1.76-4.26-.39-.39-.39-1.02,0-1.41s1.03-.39,1.42,0c1.51,1.52,2.34,3.53,2.34,5.67v.1c0,.54-.46.98-1,.98h-.02c-.55-.01-.99-.47-.98-1.02" />
      </g>
    </g>
  </>,
  'GraceIcon',
);
