import React, { useState, useEffect } from 'react';
import { Button, ButtonProps, Tooltip } from '@material-ui/core';
import { errorApiRef, useApi } from '@backstage/core-plugin-api';
import useCopyToClipboard from 'react-use/lib/useCopyToClipboard';
import FileCopyIcon from '@material-ui/icons/FileCopy';

export function CopyButton({
  text,
  tooltipDelay = 1e3,
  tooltipText = 'Text copied to clipboard',
  children,
  hideIcon,
  ...buttonProps
}: {
  text: string;
  tooltipDelay?: number;
  tooltipText?: string;
  hideIcon?: boolean;
} & ButtonProps) {
  const errorApi = useApi(errorApiRef);
  const [open, setOpen] = useState(false);
  const [{ error }, copyToClipboard] = useCopyToClipboard();
  useEffect(() => {
    if (error) {
      errorApi.post(error);
    }
  }, [error, errorApi]);
  const handleCopyClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    setOpen(true);
    copyToClipboard(text);
  };
  return (
    <Tooltip
      title={tooltipText}
      id="copy-test-tooltip"
      placement="top"
      leaveDelay={tooltipDelay}
      onClose={() => setOpen(false)}
      open={open}
    >
      <Button
        {...buttonProps}
        onClick={handleCopyClick}
        startIcon={hideIcon ? null : <FileCopyIcon />}
      >
        {children || 'Copy'}
      </Button>
    </Tooltip>
  );
}
