import {
  DomainEntity,
  entityEnvelopeSchemaValidator,
} from '@backstage/catalog-model';
import schema from '../schema/WitboostDomain.v1alpha1.schema.json';
import { generateAjvJsonSchemaValidator } from '../utils';
import { WitboostEntity } from './WitboostEntity';

export interface WitboostDomain extends DomainEntity, WitboostEntity {
  apiVersion: 'backstage.io/v1alpha1' | 'backstage.io/v1beta1';
  kind: 'Domain';
  spec: {
    owner: string;
    instanceOf?: string;
    linkedToGroup?: string;
    subDomainOf?: string;
    mesh: {
      name: string;
    } & Record<string, any>;
  } & Record<string, any>;
}

export const applyWitboostDomain =
  entityEnvelopeSchemaValidator<WitboostDomain>(schema);

export const witboostDomainV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
