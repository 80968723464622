type GetTruncateMaxLinesStyle = (maxLines?: number, wordBreak?: boolean) => any;

export const getTruncateMaxLinesStyle: GetTruncateMaxLinesStyle = (
  maxLines = 1,
  wordBreak = true,
) => ({
  WebkitBoxOrient: 'vertical',
  WebkitLineClamp: maxLines,
  overflow: 'hidden',
  display: '-webkit-box',
  wordBreak: wordBreak ? 'break-all' : 'normal',
});
