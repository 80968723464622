import { BusinessConcept, DataSource, KeyResult } from './types';

export const KEY_RESULTS: KeyResult[] = [
  {
    name: 'KR Account Management',
    description:
      'Handles the creation, modification, and closure of customer accounts.',
  },
  {
    name: 'KR Loan Processing',
    description:
      'Manages the application, approval, and disbursement of loans.',
  },
  {
    name: 'KR Payment Processing',
    description:
      'Facilitates the processing and settlement of customer payments.',
  },
  {
    name: 'KR Fraud Detection',
    description:
      'Identifies and prevents fraudulent activities within the banking system.',
  },
];

export const BUSINESS_CONCEPTS: BusinessConcept[] = [
  {
    name: 'Account Management',
    domain: 'Retail Banking',
    description:
      'Handles the creation, modification, and closure of customer accounts.',
  },
  {
    name: 'Loan Processing',
    domain: 'Retail Banking',
    description:
      'Manages the application, approval, and disbursement of loans.',
  },
  {
    name: 'Payment Processing',
    domain: 'Retail Banking',
    description:
      'Facilitates the processing and settlement of customer payments.',
  },
  {
    name: 'Fraud Detection',
    domain: 'Risk Management',
    description:
      'Identifies and prevents fraudulent activities within the banking system.',
  },
  {
    name: 'Risk Management',
    domain: 'Risk Management',
    description:
      'Evaluates and mitigates potential risks associated with banking operations.',
  },
  {
    name: 'Customer Relationship Management (CRM)',
    domain: 'Retail Banking',
    description:
      'Manages customer interactions, inquiries, and relationship building.',
  },
  {
    name: 'Wealth Management',
    domain: 'Investment Banking',
    description:
      'Provides personalized financial planning and investment advice.',
  },
  {
    name: 'Credit Card Processing',
    domain: 'Retail Banking',
    description:
      'Handles the authorization and processing of credit card transactions.',
  },
  {
    name: 'Treasury Management',
    domain: 'Corporate Banking',
    description:
      'Manages cash flow, liquidity, and financial assets for businesses.',
  },
  {
    name: 'Online Banking',
    domain: 'Retail Banking',
    description:
      'Enables customers to perform banking transactions through digital channels.',
  },
  {
    name: 'Mobile Banking',
    domain: 'Retail Banking',
    description:
      'Allows customers to access banking services through mobile devices.',
  },
  {
    name: 'Mortgage Lending',
    domain: 'Retail Banking',
    description:
      'Offers loans for purchasing or refinancing real estate properties.',
  },
  {
    name: 'Investment Banking',
    domain: 'Investment Banking',
    description:
      'Provides financial advisory and capital raising services to corporations.',
  },
  {
    name: 'Foreign Exchange (Forex) Trading',
    domain: 'Investment Banking',
    description: 'Facilitates the buying and selling of foreign currencies.',
  },
  {
    name: 'ATM Management',
    domain: 'Retail Banking',
    description:
      'Oversees the operation and maintenance of automated teller machines.',
  },
  {
    name: 'Compliance Monitoring',
    domain: 'Risk Management',
    description:
      'Ensures adherence to regulatory requirements and internal policies.',
  },
  {
    name: 'Data Analytics',
    domain: 'Risk Management',
    description:
      'Analyzes large volumes of data to gain insights and make informed decisions.',
  },
  {
    name: 'Anti-Money Laundering (AML)',
    domain: 'Risk Management',
    description:
      'Detects and prevents money laundering activities within the banking system.',
  },
  {
    name: 'Trade Finance',
    domain: 'Corporate Banking',
    description:
      'Provides financing and risk mitigation services for international trade transactions.',
  },
  {
    name: 'Branch Operations',
    domain: 'Retail Banking',
    description: 'Manages the day-to-day operations of physical bank branches.',
  },
];

export const DATA_SOURCES: DataSource[] = [
  { name: 'Customers', domain: 'organization' },
  { name: 'Leads', domain: 'marketing' },
  { name: 'Sales Customers', domain: 'sales' },
  { name: 'Sales Orders', domain: 'sales' },
  { name: 'Sales Products', domain: 'sales' },
  { name: 'Accounts', domain: 'finance' },
  { name: 'Invoices', domain: 'finance' },
  { name: 'Payments', domain: 'finance' },
  { name: 'Cash Flows', domain: 'finance' },
  { name: 'Movements', domain: 'finance' },
  { name: 'Transactions', domain: 'finance' },
  { name: 'Loans', domain: 'finance' },
  { name: 'Credit Cards', domain: 'finance' },
  { name: 'Investments', domain: 'finance' },
  { name: 'Insurance Policies', domain: 'finance' },
  { name: 'Bank Accounts', domain: 'finance' },
  { name: 'ATM Transactions', domain: 'finance' },
  { name: 'Online Banking', domain: 'finance' },
];
