import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { BackstageTheme } from '@backstage/theme';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import getTheme from './theme';

/**
 * Properties for {@link CodeSnippet}
 *
 * @public
 */
export interface CodeSnippetProps {
  /**
   * Code Snippet wrapper id
   */
  id?: string;
  /**
   * Code Snippet text
   */
  text: string;
  /**
   * Language used by {@link CodeSnippetProps.text}
   */
  language: string;
  /**
   * Whether to show line number
   *
   * @remarks
   *
   * Default: false
   */
  showLineNumbers?: boolean;
  /**
   * Array of line numbers to highlight
   */
  highlightedNumbers?: number[];
  /**
   * Custom styles applied to code
   *
   * @remarks
   *
   * Passed to {@link https://react-syntax-highlighter.github.io/react-syntax-highlighter/ | react-syntax-highlighter}
   */
  customStyle?: any;
  style?: React.CSSProperties;
  highlightLineColor?: string;
}

/**
 * Thin wrapper on top of {@link https://react-syntax-highlighter.github.io/react-syntax-highlighter/ | react-syntax-highlighter}
 * providing consistent theming
 *
 * @public
 */
export function CodeSnippet(props: CodeSnippetProps) {
  const {
    id,
    text,
    language,
    showLineNumbers = false,
    highlightedNumbers,
    customStyle,
    highlightLineColor,
    style,
  } = props;
  const theme = useTheme<BackstageTheme>();
  const highlightColor = highlightLineColor ? highlightLineColor : '#e6ffed';

  return (
    <div
      style={{
        position: 'relative',
        height: '100%',
        overflow: 'auto',
        ...style,
      }}
      id={id}
    >
      <SyntaxHighlighter
        wrapLongLines
        customStyle={customStyle}
        language={language}
        style={getTheme(theme)}
        showLineNumbers={showLineNumbers}
        wrapLines
        lineNumberStyle={{ color: theme.palette.textVerySubtle }}
        lineProps={(lineNumber: number) =>
          highlightedNumbers?.includes(lineNumber)
            ? {
                style: {
                  backgroundColor: highlightColor,
                },
              }
            : {}
        }
      >
        {text}
      </SyntaxHighlighter>
    </div>
  );
}
