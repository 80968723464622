import React from 'react';
import { WbCardProps } from '../WbCard';
import { makeStyles, useTheme } from '@material-ui/core';
import { WbWidget } from './WbWidget';

const useStyles = makeStyles(theme => ({
  textColor: {
    color: theme.palette.banner.text,
  },
}));

export const WbErrorWidget: React.FC<WbCardProps> = ({
  children,
  cardStyle,
  headerStyle,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <WbWidget
      headerStyle={{
        background: theme.palette.banner.error,
        ...headerStyle,
      }}
      cardStyle={cardStyle}
      typographyProps={{
        className: classes.textColor,
      }}
      {...props}
    >
      {children}
    </WbWidget>
  );
};
