import React from 'react';
import { makeStyles, createStyles, Box, useTheme } from '@material-ui/core';
import {
  ThresholdResult,
  WbTag,
  WbTruncatedTypographyWrapper,
} from '@agilelab/plugin-wb-platform';
import { Task } from '../../types';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import clsx from 'clsx';

interface Props {
  metric: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles(() =>
  createStyles({
    detailName: {
      flex: 1,
    },
    chip: {
      margin: 0,
    },
  }),
);

export const MetricItem: React.FC<Props> = ({ metric, task }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{ width: '100%', gap: theme.spacing(1) }}
    >
      <Box display="flex" justifyContent="space-between">
        <ThresholdResult
          value={task.metricSpecificResult?.value || 0}
          result={task.metricSpecificResult?.threshold}
          hasError={task.errors && task.errors.length > 0}
        />
        <WbTag
          tag={{
            tagFQN: 'Metric',
          }}
        />
      </Box>
      <div className={clsx(classes.detailName)}>
        <WbTruncatedTypographyWrapper
          style={{
            fontWeight: 700,
          }}
          value={metric.name}
        />
        <WbTruncatedTypographyWrapper value={metric.description ?? ''} />
      </div>
    </Box>
  );
};
