import React, { useMemo } from 'react';
import { StepLabel, Typography } from '@material-ui/core';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import {
  ImportStepper,
  StepFinishImportLocation,
  StepInitAnalyzeUrl,
  StepperProvider,
  defaultStepper,
} from '@agilelab/plugin-wb-catalog-import';
import { PRACTICE_SHAPER_ENTITES } from '@agilelab/plugin-wb-practice-shaper-common';
import { useApi, configApiRef } from '@backstage/core-plugin-api';

export const PracticeShaperImport = ({
  practiceShaperPageRef,
}: {
  practiceShaperPageRef: string;
}) => {
  const configApi = useApi(configApiRef);
  const appTitle = configApi.getOptionalString('app.title') || 'Witboost';

  const stepper: StepperProvider = useMemo(
    () => ({
      ...defaultStepper,
      analyze: (state, { apis }) => ({
        stepLabel: <StepLabel>Select URL</StepLabel>,
        content: (
          <StepInitAnalyzeUrl
            key="analyze"
            analysisUrl={state.analysisUrl}
            onAnalysis={state.onAnalysis}
            disablePullRequest={!apis.catalogImportApi.preparePullRequest}
            locationKindsToImport={[
              ...Array.from(PRACTICE_SHAPER_ENTITES),
              'Location',
            ]}
          />
        ),
      }),
      finish: state => ({
        stepLabel: <StepLabel>Finish</StepLabel>,
        content: (
          <StepFinishImportLocation
            prepareResult={state.prepareResult}
            onReset={state.onReset}
            redirectLabel="Back to Practice Shaper"
            redirectRoute={practiceShaperPageRef}
          />
        ),
      }),
    }),
    [practiceShaperPageRef],
  );

  return (
    <WbCard title={`Start tracking your Entity in ${appTitle}`}>
      <WbCardContent>
        <Typography variant="h6" paragraph>
          Enter the URL to your source code repository to add it to {appTitle}.
        </Typography>
        <Typography paragraph>
          The wizard analyzes the file, previews the entities, and adds them to
          the {appTitle} catalog. You can upload entities of the following
          kinds:
          <Typography component="span" style={{ fontWeight: 700 }}>
            {' '}
            {Array.from(PRACTICE_SHAPER_ENTITES).join(', ')}
          </Typography>
          . These YAML files must be uploaded to a specific repository of which{' '}
          {appTitle} has access. Each repository can have multiple YAML files,
          and for each one of them you have to upload it separately.
        </Typography>{' '}
        <ImportStepper defaultStepper={stepper || defaultStepper} />
      </WbCardContent>
    </WbCard>
  );
};
