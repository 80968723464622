import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import {
  PanelResizeHandle,
  PanelResizeHandleProps,
} from 'react-resizable-panels';
import { DragIcon } from './icons/DragIcon';
import clsx from 'clsx';
import './styles.css';

type Props = PanelResizeHandleProps & {
  direction: WbDockableHandleDirection;
  size?: number;
  showIconOnHover?: boolean;
  divider?: boolean;
  widget?: boolean;
};

type WbDockableHandleDirection = 'horizontal' | 'vertical';

const WbDockableResizeHandleVertical: React.FC<Props> = ({
  size = 16,
  showIconOnHover,
  divider,
}) => {
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    const onMouseUp = () => {
      setActive(false);
    };
    window.addEventListener('mouseup', onMouseUp);
    return () => window.removeEventListener('click', onMouseUp);
  }, []);

  return (
    <Box
      className={clsx(
        'handle-vertical',
        showIconOnHover && 'no-icon',
        active && 'handle-vertical-active',
        divider && 'handle-horizontal-divider',
      )}
      style={{ height: size }}
      onMouseDown={() => {
        setActive(true);
      }}
      onMouseUp={() => {
        setActive(false);
      }}
    >
      <DragIcon
        color="primary"
        fontSize="small"
        style={{ transform: 'rotate(90deg)' }}
      />
    </Box>
  );
};

const WbDockableResizeHandleHorizontal: React.FC<Props> = ({
  size = 16,
  showIconOnHover,
  divider,
}) => {
  const [active, setActive] = React.useState(false);

  React.useEffect(() => {
    const onMouseUp = () => {
      setActive(false);
    };
    window.addEventListener('mouseup', onMouseUp);
    return () => window.removeEventListener('click', onMouseUp);
  }, []);

  return (
    <Box
      className={clsx(
        'handle-horizontal',
        showIconOnHover && 'no-icon',
        active && 'handle-horizontal-active',
        divider && 'handle-horizontal-divider',
      )}
      style={{ width: size }}
      onMouseDown={() => {
        setActive(true);
      }}
    >
      <DragIcon color="primary" fontSize="small" />
    </Box>
  );
};

const handleByDirection: Record<
  WbDockableHandleDirection,
  (props: Props) => JSX.Element
> = {
  vertical: props => <WbDockableResizeHandleVertical {...props} />,
  horizontal: props => <WbDockableResizeHandleHorizontal {...props} />,
};

export const WbDockableResizeHandle: React.FC<Props> = props => {
  const theme = useTheme();
  const style = {
    '--primary-main': theme.palette.primary.main,
    '--grey-300': theme.palette.grey[300],
  } as React.CSSProperties;

  return (
    <PanelResizeHandle
      {...props}
      style={{ ...style, ...props.style }}
      className="handle-outer"
    >
      {handleByDirection[props.direction](props)}
    </PanelResizeHandle>
  );
};
