import React, { createContext, useContext, useMemo } from 'react';

const ActionContext = createContext({});
export const ActionsContextProvider = (props: Record<string, any>) => {
  const contextActions = useContext(ActionContext);
  const newActions = useMemo(() => {
    return {
      ...contextActions,
      ...(props.actions || {}),
    };
  }, [contextActions, props.actions]);

  return (
    <ActionContext.Provider value={newActions}>
      {props.children}
    </ActionContext.Provider>
  );
};

export const useAction = (action: string): Function => {
  const actions: Record<string, Function> = useContext(ActionContext);
  return actions[action] || (() => false);
};
