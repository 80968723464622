import React from 'react';
import { createSvgIcon } from '@material-ui/core';

export const NoResourcesFoundIcon = createSvgIcon(
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24"
    viewBox="0 96 960 960"
    width="24"
  >
    <path d="m733 741-54-54 112-112-313-313-112 112-54-55 125-125q8-8 19.5-12t22.5-4q12 0 22.5 4t18.5 12l337 338q17 17 16.5 43T857 617L733 741Zm89 259L648 826 521 954q-8 8-19 12t-23 4q-12 0-23.5-4T436 954L99 617q-8-8-12-19.5T83 574q0-12 4-23t12-19l127-127L58 236l42-42 764 764-42 42ZM165 575l313 313 115-115-45-45-70 70-223-223 70-70-45-45-115 115Zm469 67L411 419l67-67 223 223-67 67Z" />
  </svg>,
  'NoResourcesFoundIcon',
);

export const KoFilledIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_155" data-name="Rectangle 155" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="Group_256" data-name="Group 256" clipPath="url(#clip-path)">
      <path
        id="Path_67"
        data-name="Path 67"
        d="M23.623 9c-.034-.137-.074-.269-.114-.406a11.958 11.958 0 0 0-3.023-5.074c-.274-.269-.554-.531-.851-.777s-.606-.474-.926-.691c-.16-.109-.32-.211-.486-.314-.331-.2-.669-.383-1.017-.554A12 12 0 0 0 12.006 0a12.115 12.115 0 0 0-3.572.537 11.386 11.386 0 0 0-1.1.406l-.537.24c-.251.12-.5.251-.737.389s-.48.286-.709.44c-.114.08-.234.16-.343.234-.223.16-.44.326-.651.509l-.32.269a11.982 11.982 0 0 0-1.674 16.12c.006.006 0 .006 0 .006a11.377 11.377 0 0 0 1.08 1.257c.189.194.389.383.594.571a12.725 12.725 0 0 0 1.314 1.011c.234.154.469.3.709.44s.486.269.737.389l.537.24a11.385 11.385 0 0 0 1.1.406 12.115 12.115 0 0 0 3.572.536 12 12 0 0 0 5.2-1.183c.349-.171.686-.354 1.017-.554.166-.1.326-.206.486-.314a12.2 12.2 0 0 0 1.777-1.469 11.955 11.955 0 0 0 2.977-4.914c.046-.143.086-.28.126-.423.074-.291.143-.577.2-.874A12.315 12.315 0 0 0 24 12a12.088 12.088 0 0 0-.377-3M8.149 12.663l-.874.926V16H5.931V8.28h1.343v3.62l.737-.914 2.263-2.7H11.9L9.029 11.7l3.028 4.3h-1.588Zm10.709-.32a4.714 4.714 0 0 1-.394 1.989 2.918 2.918 0 0 1-1.12 1.314 3.295 3.295 0 0 1-3.349 0 3 3 0 0 1-1.137-1.309 4.678 4.678 0 0 1-.406-1.954v-.434a4.759 4.759 0 0 1 .4-1.994 3 3 0 0 1 1.131-1.32 3.267 3.267 0 0 1 3.343-.006 2.965 2.965 0 0 1 1.126 1.3 4.66 4.66 0 0 1 .406 1.977Zm-3.2-3.057a1.583 1.583 0 0 0-1.36.68 3.279 3.279 0 0 0-.5 1.931v.446a3.342 3.342 0 0 0 .5 1.966 1.592 1.592 0 0 0 1.365.691 1.569 1.569 0 0 0 1.371-.68 3.4 3.4 0 0 0 .48-1.977v-.406a3.351 3.351 0 0 0-.486-1.966 1.573 1.573 0 0 0-1.371-.686"
      />
    </g>
  </>,
  'KoFilledIcon',
);

export const OkFilledIcon = createSvgIcon(
  <>
    <defs>
      <clipPath id="clip-path">
        <path id="Rectangle_156" data-name="Rectangle 156" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
    <g id="Group_258" data-name="Group 258" clipPath="url(#clip-path)">
      <path
        id="Path_68"
        data-name="Path 68"
        d="M14.534 16.936a3.351 3.351 0 0 1 .486 1.964v.406a3.4 3.4 0 0 1-.48 1.977 1.569 1.569 0 0 1-1.371.68 1.585 1.585 0 0 1-1.371-.691 3.342 3.342 0 0 1-.5-1.966v-.446a3.279 3.279 0 0 1 .5-1.931 1.707 1.707 0 0 1 2.731.006"
        transform="translate(-4.843 -6.964)"
      />
      <path
        id="Path_69"
        data-name="Path 69"
        d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0m-.48 12.343a4.714 4.714 0 0 1-.394 1.989 2.953 2.953 0 0 1-1.12 1.314 3.11 3.11 0 0 1-1.68.457 3.1 3.1 0 0 1-1.669-.457 3 3 0 0 1-1.137-1.309 4.591 4.591 0 0 1-.406-1.954v-.434a4.672 4.672 0 0 1 .4-1.994 3 3 0 0 1 1.131-1.32 3.04 3.04 0 0 1 1.669-.463 3.091 3.091 0 0 1 1.674.457 2.965 2.965 0 0 1 1.126 1.3 4.66 4.66 0 0 1 .406 1.977ZM17.48 16l-2.32-3.337-.874.926V16h-1.343V8.28h1.343v3.62l.737-.914 2.263-2.7h1.623L16.04 11.7l3.029 4.3Z"
      />
    </g>
  </>,
  'OkFilledIcon',
);
