import { SearchFilterConfigType, TextFilterMatch } from '../filters';

export const PROVISIONING_CONSUMABLE_URN = {
  field: '_computedInfo.urn',
  label: 'ID',
  type: SearchFilterConfigType.TEXT,
  match: TextFilterMatch.EXACT,
};

export const PROVISIONING_CONSUMABLE_DOMAIN = {
  field: 'domain',
  label: 'Domain',
  type: SearchFilterConfigType.CHOICE,
};

export const PROVISIONING_CONSUMABLE_PARENT = {
  field: 'deploymentUnitId',
  label: 'Parent',
  type: SearchFilterConfigType.CHOICE,
};

export const PROVISIONING_CONSUMABLE_ENVIRONMENT = {
  field: 'environment',
  label: 'Environment',
  type: SearchFilterConfigType.CHOICE,
};

export const PROVISIONING_CONSUMABLE_KIND = {
  field: 'kind',
  label: 'Kind',
  type: SearchFilterConfigType.CHOICE,
};

export const PROVISIONING_CONSUMABLE_DEFAULT_FILTERS = [
  PROVISIONING_CONSUMABLE_URN,
  PROVISIONING_CONSUMABLE_DOMAIN,
  PROVISIONING_CONSUMABLE_PARENT,
  PROVISIONING_CONSUMABLE_ENVIRONMENT,
  PROVISIONING_CONSUMABLE_KIND,
];
