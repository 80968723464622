import { useApi } from '@backstage/core-plugin-api';
import {
  AuthorizeResult,
  isResourcePermission,
  Permission,
  PolicyDecision,
  ResourcePermission,
} from '@backstage/plugin-permission-common';
import { permissionApiRef } from '@backstage/plugin-permission-react';
import useSWR from 'swr';

interface PermissionData {
  permission: string;
  result:
    | AuthorizeResult.ALLOW
    | AuthorizeResult.DENY
    | AuthorizeResult.CONDITIONAL;
}

/** @public */
export type AsyncPermissionResults = {
  loading: boolean;
  allAllowed: boolean;
  anyAllowed: boolean;
  error?: Error;
  data?: PermissionData[];
};

type PermissionRequest =
  | {
      permission: Exclude<Permission, ResourcePermission>;
      resourceRef?: never;
    }
  | {
      permission: ResourcePermission;
      resourceRef: string | undefined;
    };

/**
 * React hook utility for multiple authorization. Given an array of
 * {@link @backstage/plugin-permission-common#Permission} or
 * {@link @backstage/plugin-permission-common#ResourcePermission} and an
 * optional resourceRef, it will return an AsyncPermissionResults object.
 *
 * @public
 */
export function usePermissions(
  input: PermissionRequest[],
): AsyncPermissionResults {
  const permissionApi = useApi(permissionApiRef);

  const { data, error } = useSWR(
    { permissions: input },
    fetchPermissionResults,
  );

  if (error) {
    return {
      error,
      loading: false,
      allAllowed: false,
      anyAllowed: false,
    };
  }

  if (data === undefined) {
    return {
      loading: true,
      allAllowed: false,
      anyAllowed: false,
    };
  }

  return {
    loading: false,
    allAllowed: data.every(
      decision => decision.result === AuthorizeResult.ALLOW,
    ),
    anyAllowed: data.some(
      decision => decision.result === AuthorizeResult.ALLOW,
    ),
    data: data.map((d, i) => ({
      permission: input[i].permission.name!,
      result: d.result,
    })),
  };

  async function fetchPermissionResults(args: {
    permissions: PermissionRequest[];
  }): Promise<PolicyDecision[]> {
    const { permissions } = args;
    const { withResourceList, withoutResourceList } =
      partitionPermissions(permissions);

    const results = await Promise.all(
      withResourceList.map(async arg => {
        return permissionApi.authorize(arg);
      }),
    );
    const deniedResults = withoutResourceList.map(() => ({
      result: AuthorizeResult.DENY,
    })) as PolicyDecision[];

    return results.concat(deniedResults);
  }

  function partitionPermissions(permissions: PermissionRequest[]): {
    withResourceList: PermissionRequest[];
    withoutResourceList: PermissionRequest[];
  } {
    const result = {
      withResourceList: [] as PermissionRequest[],
      withoutResourceList: [] as PermissionRequest[],
    };

    permissions.forEach(obj => {
      if (isResourcePermission(obj.permission) && !obj.resourceRef) {
        result.withoutResourceList.push(obj);
      } else {
        result.withResourceList.push(obj);
      }
    });

    return result;
  }
}
