import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import {
  GetConsumersRequest,
  GetConsumersResponse,
  GetDataContractRequest,
  GetDataContractResponse,
  MarketplaceApi,
  RequestOptions,
} from './api';
import crossFetch from 'cross-fetch';
import {
  encodeQueryParams,
  handleFailedResponse,
} from '@agilelab/plugin-wb-platform-common';

/**
 * A frontend and backend compatible client for communicating with the Marketplace
 * service.
 *
 * @public
 */
export class MarketplaceClient implements MarketplaceApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly baseUrlPromise: Promise<string>;

  constructor(options: {
    discoveryApi: DiscoveryApi;
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('marketplace');
  }

  async getConsumers(
    request: GetConsumersRequest,
    options?: RequestOptions,
  ): Promise<GetConsumersResponse> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      environment: request.environment,
      includeChildren: request.includeChildren,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v1/consumers/projects/reading-from/${encodeURIComponent(
        request.externalId,
      )}${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    await handleFailedResponse(response);
    return response.json();
  }

  async getDataContract(
    request: GetDataContractRequest,
    options?: RequestOptions,
  ): Promise<GetDataContractResponse | undefined> {
    const baseUrl = await this.baseUrlPromise;
    const queryParams = {
      environment: request.identifier.environment,
    };
    const response = await this.fetchApi.fetch(
      `${baseUrl}/v1/data-contracts/${encodeURIComponent(
        request.identifier.externalId,
      )}${encodeQueryParams(queryParams)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(options?.token && { Authorization: `Bearer ${options?.token}` }),
        },
      },
    );
    if (response.status === 404) return undefined;
    await handleFailedResponse(response);
    return response.json();
  }
}
