const wait = (ms: number = 1000) => {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
};

export const poll = async <T,>(
  fn: () => Promise<T>,
  fnCondition: (result: T) => boolean,
  onResult: (result: T) => void,
  onFinish?: (result: T) => void,
  options?: { ms?: number; signal?: AbortController['signal'] },
) => {
  if (options?.signal?.aborted) return;

  let result = await fn();
  onResult(result);

  while (fnCondition(result)) {
    await wait(options?.ms);
    if (options?.signal?.aborted) return;
    result = await fn();
    onResult(result);
  }

  onFinish?.(result);
};
