import React, { useEffect } from 'react';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { usePublishEvent } from '@agilelab/plugin-wb-events';
import { entityDocRenderedTopic } from '@agilelab/plugin-wb-events-common';
import { Entity, stringifyEntityRef } from '@backstage/catalog-model';

interface TechdocsWrapperProps {
  entity: Entity;
}

/**
 * this component only applies on system and components:
 * it published a doc rendered event on visits.
 * @param entity
 * @constructor
 */
export const TechdocsWrapper: React.FC<TechdocsWrapperProps> = ({ entity }) => {
  const entityRef = stringifyEntityRef(entity);
  const { publish } = usePublishEvent({ generatorSystem: 'builder' });

  useEffect(() => {
    if (entityRef) publish(entityDocRenderedTopic, { entityRef });
  }, [publish, entityRef]);

  return <EntityTechdocsContent />;
};
