import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

type IncomingMessageIconProps = {
  className?: string;
  style?: React.CSSProperties;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      width: theme.spacing(2),
      aspectRatio: '4',
      background: `radial-gradient(circle closest-side,${theme.palette.secondary.main} 90%,#0000) 0/calc(100%/3) 100% space`,
      clipPath: 'inset(0 100% 0 0)',
      animation: '$l1 1s steps(4) infinite',
    },
    '@keyframes l1': { to: { clipPath: 'inset(0 -34% 0 0)' } },
  }),
  { name: 'IncomingMessageIcon' },
);

export const IncomingMessageIcon = ({
  className,
  style,
}: IncomingMessageIconProps) => {
  const classes = useStyles();
  return <Box className={clsx(classes.root, className)} style={style} />;
};
