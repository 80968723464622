import React, { useCallback, useState } from 'react';
import './styles.css';
import { IconButton, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { useResizeObserver } from '../../hooks';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },
  scrollable: {
    overflowX: 'scroll',
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    padding: '4px 8px',
    margin: '4px',
    whiteSpace: 'nowrap',
    display: 'flex',
  },
  arrow: {
    padding: '0px',
  },
}));

interface Props {
  children?: React.ReactNode;
  offset?: number;
}

export const WbScrollable: React.FC<Props> = ({ children, offset = 100 }) => {
  const classes = useStyles();
  const [overflow, setOverflow] = useState<boolean>(false);
  const [isStart, setIsStart] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const reset = useCallback(() => {
    setIsStart(false);
    setIsEnd(false);
  }, [setIsStart, setIsEnd]);

  const onResize = useCallback((target: HTMLDivElement) => {
    const hasOverflow =
      target.offsetHeight < target.scrollHeight ||
      target.offsetWidth < target.scrollWidth;
    setOverflow(hasOverflow);
  }, []);

  const ref = useResizeObserver(onResize);
  const scroll = (scrollOffset: number) => {
    if (ref && ref.current) {
      const newOffset = ref.current.scrollLeft + scrollOffset;
      ref.current.scrollLeft = newOffset;
      reset();
      if (newOffset <= 0) setIsStart(true);
      if (ref.current.scrollWidth - newOffset <= ref.current.clientWidth)
        setIsEnd(true);
    }
  };

  return (
    <div className={classes.container}>
      {overflow && (
        <IconButton
          size="small"
          disabled={isStart}
          className={classes.arrow}
          onClick={() => {
            scroll(-offset);
          }}
        >
          <KeyboardArrowLeftIcon />
        </IconButton>
      )}

      <div
        ref={ref}
        className={clsx(classes.scrollable, 'scrollable')}
        style={{ ...(!overflow ? { border: 'none' } : {}) }}
      >
        {children}
      </div>

      {overflow && (
        <IconButton
          size="small"
          disabled={isEnd}
          className={classes.arrow}
          onClick={() => {
            scroll(offset);
          }}
        >
          <KeyboardArrowRightIcon />
        </IconButton>
      )}
    </div>
  );
};
