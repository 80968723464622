/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useEffect } from 'react';
import { SelectItem } from '@backstage/core-components';
import FormControl from '@material-ui/core/FormControl';

import { RepoUrlPickerState } from './types';
import { WbSelect, WbTextField } from '@agilelab/plugin-wb-platform';
import { MenuItem } from '@material-ui/core';
import { useFormExtraConfigContext } from '../../../contexts/FormExtraConfigContext';

export const BitbucketRepoPicker = (props: {
  fieldsLabels?: {
    allowedOwnersLabel?: string;
    workspaceInputLabel?: string;
    workspaceInputHelper?: string;
    projectInputLabel?: string;
    projectInputHelper?: string;
  };
  allowedOwners?: string[];
  onChange: (state: RepoUrlPickerState) => void;
  state: RepoUrlPickerState;
  rawErrors: string[];
}) => {
  const {
    allowedOwners = [],
    onChange,
    rawErrors,
    state,
    fieldsLabels,
  } = props;
  const { host, workspace, project } = state;
  const ownerItems: SelectItem[] = allowedOwners
    ? allowedOwners?.map(i => ({ label: i, value: i }))
    : [];

  useEffect(() => {
    if (host === 'bitbucket.org' && allowedOwners.length) {
      onChange({ workspace: allowedOwners[0] });
    }
  }, [allowedOwners, host, onChange]);

  const { debounceMs } = useFormExtraConfigContext();

  return (
    <>
      {host === 'bitbucket.org' && (
        <FormControl
          margin="normal"
          required
          error={rawErrors?.length > 0 && !workspace}
        >
          {allowedOwners?.length ? (
            <WbSelect
              label={fieldsLabels?.allowedOwnersLabel ?? 'Allowed Workspaces'}
              onChange={e => onChange({ workspace: e.target.value })}
              disabled={allowedOwners.length === 1}
              value={workspace}
              helperText={
                fieldsLabels?.workspaceInputHelper ??
                'The Organization that this repo will belong to'
              }
            >
              {ownerItems.map(o => (
                <MenuItem key={o.value} value={o.value}>
                  {o.label}
                </MenuItem>
              ))}
            </WbSelect>
          ) : (
            <WbTextField
              label={fieldsLabels?.workspaceInputLabel ?? 'Workspace'}
              id="workspaceInput"
              onChange={e => onChange({ workspace: e.target.value })}
              value={workspace}
              debounceMs={debounceMs}
              helperText={
                fieldsLabels?.workspaceInputHelper ??
                'The Organization that this repo will belong to'
              }
            />
          )}
        </FormControl>
      )}
      <FormControl
        margin="normal"
        required
        error={rawErrors?.length > 0 && !project}
      >
        <WbTextField
          label={fieldsLabels?.projectInputLabel ?? 'Project'}
          helperText={
            fieldsLabels?.projectInputHelper ??
            'The Project that this repo will belong to'
          }
          id="projectInput"
          onChange={e => onChange({ project: e.target.value })}
          value={project}
          debounceMs={debounceMs}
        />
      </FormControl>
    </>
  );
};
