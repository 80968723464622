import { EnumFilter } from '@agilelab/plugin-wb-platform';
import {
  // @ts-ignore
  PracticeShaperFilters,
  useResourceTypeFilter,
} from '@agilelab/plugin-wb-practice-shaper';
import React from 'react';
// @ts-ignore
import { EntityListProvider } from '@backstage/plugin-catalog-react';

export interface ResourceTypePickerProps {
  availableResourceTypes: Map<string, { displayName: string }>;
  allowedResourceTypes?: string[];
  initialFilter?: string[];
  hidden?: boolean;
}

/**
 * For a correct usage, this picker should rendered within the context of an entity list provided by {@link EntityListProvider} and filtered with {@link PracticeShaperFilters}.
 * @public
 */
export const ResourceTypePicker = (props: ResourceTypePickerProps) => {
  const {
    initialFilter,
    availableResourceTypes,
    allowedResourceTypes,
    hidden,
  } = props;
  const { setSelectedTypes, selectedTypes } = useResourceTypeFilter({
    allowedResourceTypes,
    initialValues: initialFilter ?? 'all',
  });

  const items = [...availableResourceTypes.keys()];

  const getLabel = (option: string) => {
    return availableResourceTypes.get(option)?.displayName ?? '';
  };

  const computeEnumFilterValue = () =>
    selectedTypes === 'all' ? [] : selectedTypes.filter(v => items.includes(v));

  return hidden ? null : (
    <EnumFilter<string>
      field="Project type"
      value={computeEnumFilterValue()}
      options={items}
      onChange={v => {
        if (v === undefined || v.length === 0) setSelectedTypes('all');
        else setSelectedTypes(v);
      }}
      renderOption={getLabel}
      renderValue={getLabel}
      onSearch={v => items.filter(o => new RegExp(v, 'ig').test(getLabel(o)))}
    />
  );
};
