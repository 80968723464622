import { Link, Tooltip } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import React from 'react';

interface WbLinkProps {
  /**
   * the route you want to navigate to.
   * if it starts with `/`, it places the route after the host,
   * otherwise it appends the route after the current link.
   * @example current route: `localhost:3000/other_route`
   *          route = `/marketplace/test` -> will navigate to `localhost:3000/marketplace/test`
   *          route = `marketplace/test` -> will navigate to `localhost:3000/other_route/marketplace/test`
   */
  route?: string;
  /**
   * optionally provide an external link, it will have priority.
   * @example https://testsite.com
   */
  externalLink?: string;
  /**
   * the text to display instead of the raw link.
   * If not provided, show raw link instead.
   */
  text?: string;
  /**
   * defaults to `always`
   */
  underlineLink?: 'none' | 'hover' | 'always';
  className?: string;
  showTooltip?: boolean;
}

export const WbLink: React.FC<WbLinkProps> = ({
  route,
  externalLink,
  text,
  underlineLink,
  className,
  showTooltip,
}) => {
  const toDisplay = text ?? externalLink ?? route ?? '';

  const commonProps = {
    className,
    style: { cursor: 'pointer' },
    underline: underlineLink ?? 'always',
    children: toDisplay,
  };

  return (
    <Tooltip title={showTooltip ? toDisplay : ''}>
      {/* if the link is external render it as standard link with href, otherwise as react-router Link with to */}
      {externalLink ? (
        <Link {...commonProps} href={externalLink} />
      ) : (
        <Link {...commonProps} component={RouterLink} to={route ?? ''} />
      )}
    </Tooltip>
  );
};
