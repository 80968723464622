import React from 'react';
import { RELATION_MEMBER_OF, UserEntity } from '@backstage/catalog-model';
import {
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import { Alert } from '@material-ui/lab';
import { Avatar, Link } from '@backstage/core-components';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import { LinksGroup } from '../LinksGroup';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';

/**
 * Exported publicly via the EntityUserProfileCard
 */
export function UserProfileCard() {
  const { entity: user } = useEntity<UserEntity>();
  if (!user) {
    return <Alert severity="error">User not found</Alert>;
  }

  const {
    metadata: { name: metaName, links },
    spec: { profile },
  } = user;
  const displayName = profile?.displayName ?? metaName;
  const emailHref = profile?.email ? `mailto:${profile.email}` : undefined;
  const memberOfRelations = getEntityRelations(user, RELATION_MEMBER_OF, {
    kind: 'Group',
  });

  return (
    <WbCard
      cardStyle={{ height: '100%' }}
      headerStyle={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
      }}
      title={displayName}
      leading={<PersonIcon color="primary" />}
    >
      <WbCardContent>
        <Grid container spacing={3} alignItems="flex-start">
          <Grid item xs={12} sm={2} xl={1}>
            <Avatar displayName={displayName} picture={profile?.picture} />
          </Grid>

          <Grid item md={10} xl={11}>
            <List>
              {profile?.email && (
                <ListItem>
                  <ListItemIcon>
                    <Tooltip title="Email">
                      <EmailIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText>
                    <Link to={emailHref ?? ''}>{profile.email}</Link>
                  </ListItemText>
                </ListItem>
              )}

              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Member of">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText>
                  <EntityRefLinks
                    entityRefs={memberOfRelations}
                    defaultKind="Group"
                  />
                </ListItemText>
              </ListItem>

              <LinksGroup links={links} />
            </List>
          </Grid>
        </Grid>
      </WbCardContent>
    </WbCard>
  );
}
