/*
 * Copied from Backstage 1.8.2 "/OwnershipCard/ComponentsGrid.ts"
 */

import { Entity } from '@backstage/catalog-model';
import { Link, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useRouteRef } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import pluralize from 'pluralize';
import { useGetEntities } from './useGetEntities';
import { rootRouteRef } from '../../routes';

const useStyles = makeStyles((theme: BackstageTheme) =>
  createStyles({
    card: {
      border: `1px solid ${theme.palette.divider}`,
      boxShadow: theme.shadows[2],
      borderRadius: '4px',
      padding: theme.spacing(2),
      color: theme.palette.white,
      transition: `${theme.transitions.duration.standard}ms`,
      '&:hover': {
        boxShadow: theme.shadows[4],
      },
    },
    bold: {
      fontWeight: theme.typography.fontWeightBold as unknown as any,
    },
    entityTypeBox: {
      background: (props: { type: string }) =>
        theme.getPageTheme({ themeId: props.type }).backgroundImage,
    },
  }),
);

const EntityCountTile = ({
  counter,
  type,
  kind,
  url,
}: {
  counter: number;
  type?: string;
  kind: string;
  url: string;
}) => {
  const classes = useStyles({ type: type ?? kind });

  const rawTitle = type ?? kind;

  return (
    <Link to={url}>
      <Box
        className={`${classes.card} ${classes.entityTypeBox}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography className={classes.bold} variant="h6">
          {counter}
        </Typography>
        <Typography className={classes.bold} variant="h6">
          {pluralize(rawTitle.toLocaleUpperCase('en-US'), counter)}
        </Typography>
        {type && <Typography variant="subtitle1">{kind}</Typography>}
      </Box>
    </Link>
  );
};

export const ComponentsGrid = ({
  entity,
  relationsType,
  isGroup,
  entityFilterKind,
}: {
  entity: Entity;
  relationsType: string;
  isGroup: boolean;
  entityFilterKind?: string[];
}) => {
  const catalogLink = useRouteRef(rootRouteRef);
  const { componentsWithCounters, loading, error } = useGetEntities(
    entity,
    relationsType,
    isGroup,
    entityFilterKind,
  );

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <Grid container>
      {componentsWithCounters?.map(
        (c: {
          type: string | undefined;
          kind: string;
          counter: number;
          queryParams: any;
        }) => (
          <Grid item xs={6} md={6} lg={4} key={c.type ?? c.kind}>
            <EntityCountTile
              counter={c.counter}
              kind={c.kind}
              type={c.type}
              url={`${catalogLink()}/?${c.queryParams}`}
            />
          </Grid>
        ),
      )}
    </Grid>
  );
};
