import { makeStyles, useTheme } from '@material-ui/core';
import React from 'react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
} from 'reactflow';
import clsx from 'clsx';
import { ENTITY_NODE_HEIGHT } from '../nodes/EntityNode';

const getSelfConnectingEdgepath = (sourceX: number, sourceY: number) => {
  const radiusY = ENTITY_NODE_HEIGHT * 0.6;
  const radiusX = radiusY;
  const X = sourceX - 4;
  const offsetY = ENTITY_NODE_HEIGHT / 3;
  const edgePath = `M ${X} ${
    sourceY + offsetY
  } A ${radiusX} ${radiusY} 0 1 0 ${X} ${sourceY - offsetY}`;

  const labelX = X + radiusX * 2;
  const labelY = sourceY;

  return [edgePath, labelX, labelY] as [string, number, number];
};

const useStyle = makeStyles(
  theme => ({
    label: {
      position: 'absolute',
      backgroundColor: 'white',
      whiteSpace: 'pre',
      fontSize: '10px',
      color: theme.palette.black,
      paddin: 0,
      margin: 0,
      '&$primary': {
        fontWeight: 600,
      },
    },

    primary: {},
  }),
  { name: 'entityEdgeLabel' },
);

export function EntityEdge(props: EdgeProps) {
  const {
    sourceX,
    sourceY,
    label,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    data,
  } = props;

  const classes = useStyle();

  const theme = useTheme();

  const edgeStyle = {
    stroke: data?.primary
      ? theme.palette.secondary.dark
      : theme.palette.grey[500],
  };

  const selfConnecting = props.source === props.target;
  // we are using the default bezier edge when source and target ids are different (aka it's not a self connecting node)
  const [edgePath, labelX, labelY] = selfConnecting
    ? getSelfConnectingEdgepath(sourceX, sourceY)
    : getBezierPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
      });

  return (
    <>
      <EdgeLabelRenderer>
        <div
          style={{
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
          }}
          className={clsx(
            'nodrag',
            'nopan',
            classes.label,
            data?.primary && classes.primary,
          )}
        >
          {label}
        </div>
      </EdgeLabelRenderer>
      <BaseEdge path={edgePath} {...props} style={edgeStyle} />;
    </>
  );
}
