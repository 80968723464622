import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { MarketplaceSearchListItemLoader } from '../../SearchResult/MarketplaceSearchListItemLoader';

const useStyles = makeStyles(
  theme => ({
    flexHorizontal: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },
  }),
  { name: 'SearchResultsLoader' },
);

export const SearchResultsLoader = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.flexHorizontal}>
        <Skeleton variant="text" width={50} />
        <Skeleton variant="text" width={100} />
      </Box>
      {new Array(10).fill(0).map((_, i) => (
        <MarketplaceSearchListItemLoader key={i} />
      ))}
    </Box>
  );
};
