import React from 'react';
import { FlagBox, FlagBoxProps } from '@agilelab/plugin-wb-governance';
import { useSearchContext } from '../context/SearchContext';

export const SearchFlagBox = (props: FlagBoxProps) => {
  const { policyViolationsInfo } = useSearchContext();
  const { error, value, loading } = policyViolationsInfo;

  return (
    <FlagBox
      error={error}
      loading={loading}
      policyViolationsCountMap={value}
      {...props}
    />
  );
};
