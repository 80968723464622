import React from 'react';
import { makeStyles } from '@material-ui/core';
import { WbTable } from '@agilelab/plugin-wb-platform';
import { MarketplaceSearchResultEnriched } from '../../types';
import { useNavigate } from 'react-router';
import { Row, adaptData } from './utils';
import { columns } from './columns';

type SearchResultsTableProps = {
  rows: MarketplaceSearchResultEnriched[];
  loading?: boolean;
};

const useStyles = makeStyles(
  theme => ({
    root: {
      flex: 1,
      paddingRight: theme.spacing(2),
      overflowY: 'scroll',
    },
    descriptionCell: {},
  }),

  { name: 'SearchResultsTable' },
);

export const SearchResultsTable = ({
  rows,
  loading,
}: SearchResultsTableProps) => {
  const classes = useStyles();
  const adaptedRows = rows.map(adaptData);

  const navigate = useNavigate();

  return (
    <WbTable<Row>
      stickyHeader
      components={{
        tableLoader: {
          rowCount: 30,
          loading: !!loading,
        },
        tableContent: {
          columns,
          rows: adaptedRows,
          onRowClick: res => navigate(res.location),
        },
      }}
      styles={loading ? { container: { overflowY: 'hidden' } } : {}}
      classNames={{ container: classes.root }}
    />
  );
};
