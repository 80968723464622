import {
  getCompoundEntityRef,
  parseEntityRef,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import { Link } from '@backstage/core-components';

import { useRouteRef, useAnalytics } from '@backstage/core-plugin-api';
import {
  entityRouteRef,
  humanizeEntityRef,
  useEntity,
} from '@backstage/plugin-catalog-react';
import React, { useCallback, MouseEvent } from 'react';
import { useNavigate } from 'react-router';
import {
  EntityRelationsGraph,
  catalogGraphRouteRef,
  ALL_RELATION_PAIRS,
  Direction,
  RelationPairs,
  EntityNode,
} from '@backstage/plugin-catalog-graph';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { IconButton, Theme, makeStyles } from '@material-ui/core';
import qs from 'qs';
import { InfoCardVariants } from '@backstage/core-components';
import AssessmentIcon from '@material-ui/icons/Assessment';
import { CatalogGraphRenderNode } from './CatalogGraphRenderNode';

const useStyles = makeStyles<Theme, { height: number | undefined }>(
  {
    card: () => ({
      height: '100%',
    }),
    graph: {
      flex: 1,
      height: '100%',
      minHeight: 0,
    },
  },
  { name: 'PluginCatalogGraphCatalogGraphCard' },
);

/**
 * Props for {@link EntityCatalogGraphCard }
 *
 * @public
 */
export interface CatalogGraphCardProps {
  variant?: InfoCardVariants;
  height?: number;
  title?: string;
  relationPairs?: RelationPairs;
  maxDepth?: number;
  unidirectional?: boolean;
  mergeRelations?: boolean;
  kinds?: string[];
  relations?: string[];
  direction?: Direction;

  zoom?: 'enabled' | 'disabled' | 'enable-on-click';
}

/**
 * Exported publicly via the EntityCatalogGraphCard
 */
export function CatalogGraphCard(props: CatalogGraphCardProps) {
  const {
    relationPairs = ALL_RELATION_PAIRS,
    maxDepth = 1,
    unidirectional = true,
    mergeRelations = true,
    kinds,
    relations,
    direction = Direction.LEFT_RIGHT,
    height = 400,
    title = 'Relations',
    zoom = 'enable-on-click',
  } = props;

  const { entity } = useEntity();
  const entityName = getCompoundEntityRef(entity);
  const catalogEntityRoute = useRouteRef(entityRouteRef);
  const catalogGraphRoute = useRouteRef(catalogGraphRouteRef);
  const navigate = useNavigate();
  const classes = useStyles({ height });
  const analytics = useAnalytics();

  const onNodeClick = useCallback(
    (node: EntityNode, _: MouseEvent<unknown>) => {
      const nodeEntityName = parseEntityRef(node.id);
      const path = catalogEntityRoute({
        kind: nodeEntityName.kind.toLocaleLowerCase('en-US'),
        namespace: nodeEntityName.namespace.toLocaleLowerCase('en-US'),
        name: nodeEntityName.name,
      });
      analytics.captureEvent(
        'click',
        node.title ?? humanizeEntityRef(nodeEntityName),
        { attributes: { to: path } },
      );
      navigate(path);
    },
    [catalogEntityRoute, navigate, analytics],
  );
  const catalogGraphParams = qs.stringify(
    {
      rootEntityRefs: [stringifyEntityRef(entity)],
      maxDepth: maxDepth + 1,
      unidirectional,
      mergeRelations,
      kinds,
      relations,
      direction,
    },
    { arrayFormat: 'brackets', addQueryPrefix: true },
  );
  const catalogGraphUrl = `${catalogGraphRoute()}${catalogGraphParams}`;

  return (
    <>
      <WbCard
        title={title}
        cardClassName={classes.card}
        actions={
          <IconButton
            component={Link}
            aria-label="View graph"
            title="View graph"
            to={catalogGraphUrl ?? '#'}
            size="small"
          >
            <AssessmentIcon />
          </IconButton>
        }
      >
        <WbCardContent style={{ height: '350px' }}>
          <EntityRelationsGraph
            rootEntityNames={entityName}
            maxDepth={maxDepth}
            unidirectional={unidirectional}
            mergeRelations={mergeRelations}
            kinds={kinds}
            relations={relations}
            direction={direction}
            onNodeClick={onNodeClick}
            renderNode={CatalogGraphRenderNode}
            className={classes.graph}
            relationPairs={relationPairs}
            zoom={zoom}
          />
        </WbCardContent>
      </WbCard>
    </>
  );
}
