import React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { WbCardContent } from '@agilelab/plugin-wb-platform';

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
    row: {
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(1),
      width: '100%',
    },
  }),
  { name: 'Loader' },
);

export const Row = () => {
  const classes = useStyles();

  return (
    <Box className={classes.row}>
      <Skeleton variant="text" width={50} />
      <Skeleton variant="text" style={{ flex: 1 }} />
    </Box>
  );
};

export const Loader = () => {
  const classes = useStyles();
  return (
    <WbCardContent>
      <Box className={classes.root}>
        <Row />
        <Row />
      </Box>
    </WbCardContent>
  );
};
