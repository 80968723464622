import { Grid, GridSize, Theme, makeStyles, useTheme } from '@material-ui/core';
import React, { ReactNode, useRef } from 'react';

export type GenericGridItem = {
  type?: 'text' | 'date' | 'string';
  label: string;
  value: ReactNode;
  href?: string;
  colSpan?: number | Partial<Record<Breakpoint, number>>;
};

export type AdjustedGenericGridItem = GenericGridItem & {
  colSpan?: number;
};

import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { useElementSize } from '../../hooks';
import { GenericField } from '../DisplayValue';

const useStyles = makeStyles(() => ({
  grid: {
    margin: 0,
    width: '100%',
  },
}));

type Props = {
  fields: GenericGridItem[];
  columns?: 4 | 3 | 2;
  maxLines?: number;
  maxCharactersBeforeColSpanExpansion?: number;
  borderAfterLastRow?: boolean;
};

export function adjustColSpan(
  elements: GenericGridItem[],
  viewportWidth: number,
  theme: Theme,
  charactersThresholdForExpansion?: number,
): AdjustedGenericGridItem[] {
  return elements.map(e => {
    // if maxCharactersBeforeColSpanExpansion is defined in config, expand undefined colSpans according to it
    if (
      e.colSpan === undefined &&
      typeof e.value === 'string' &&
      charactersThresholdForExpansion
    )
      return {
        ...e,
        colSpan:
          (e.value?.length || 0) > charactersThresholdForExpansion ? 2 : 1,
      } as AdjustedGenericGridItem;

    if (e.colSpan === undefined) return e as AdjustedGenericGridItem;

    // if colSpan is a number and defined, clamp it between 1 and 4
    if (typeof e.colSpan === 'number')
      return {
        ...e,
        colSpan: Math.min(Math.max(e.colSpan, 1), 4),
      } as AdjustedGenericGridItem;

    // the colSpan is defined based on breakpoints
    const defaultColSpan = 1;
    // take all breakpoints and sort them by value
    const bks = Object.entries(theme.breakpoints.values).sort(
      ([, va], [, vb]) => vb - va,
    );
    for (let i = 0; i < bks.length; i++) {
      const bk = bks[i];
      // use the colSpan of the largest breakpoint provided that matches with viewport width
      if (bk[0] in e.colSpan && viewportWidth > bk[1])
        return {
          ...e,
          colSpan: e.colSpan[bk[0] as Breakpoint],
        } as AdjustedGenericGridItem;
    }

    // default if there are no matches
    return { ...e, colSpan: defaultColSpan } as AdjustedGenericGridItem;
  });
}

function computeItemXs(colSpan: number, gridColumns = 4) {
  // 12 is a full row in MUI grid
  const factor = 12 / gridColumns;

  return (colSpan * factor) as GridSize;
}

// get the index of the first element of the last row of the grid made by fields
const findLastRowStartIndex = (
  fields: AdjustedGenericGridItem[],
  gridCols = 4,
) => {
  let span = 0;
  for (let i = fields.length - 1; i >= 0; i--) {
    const e = fields[i];
    span += e.colSpan || 1;
    if (span === gridCols) return i;
  }

  return -1;
};

export function fillGridHoles<
  T extends Pick<AdjustedGenericGridItem, 'colSpan'>,
>(elements: T[], gridCols = 4): T[] {
  let availableCols = gridCols;
  const correctedColsSpans: T[] = [];
  elements.forEach((e, i) => {
    // if colSpan is negative or 0 or undefined, set it to 1
    let currSpan = Math.max(1, e.colSpan || 1);
    const availableColsProjection = availableCols - currSpan;
    if (
      i === elements.length - 1 ||
      (elements[i + 1].colSpan || 1) > availableColsProjection
    ) {
      currSpan = availableCols;
    }
    availableCols -= currSpan;
    if (availableCols === 0) availableCols = gridCols;
    correctedColsSpans.push({ ...e, colSpan: currSpan });
  });

  return correctedColsSpans;
}

export const WbGenericFieldGrid = ({
  fields,
  columns = 4,
  maxLines = 1,
  maxCharactersBeforeColSpanExpansion,
  borderAfterLastRow = false,
}: Props) => {
  const classes = useStyles();

  const theme = useTheme();

  const ref = useRef<HTMLDivElement>(null);

  const { clientWidth: width } = useElementSize(ref);

  const generalInfos = adjustColSpan(
    fields,
    width,
    theme,
    maxCharactersBeforeColSpanExpansion,
  );

  const filledInfos = fillGridHoles(generalInfos, columns);

  const lastRowStartIndex = findLastRowStartIndex(filledInfos, columns);

  return (
    <Grid container className={classes.grid} ref={ref}>
      {filledInfos.map((info, i) => {
        return (
          <Grid
            key={info.label}
            item
            xs={computeItemXs(info.colSpan || 1, columns)}
            style={{
              padding: `${theme.spacing(2)}px 0`,
              paddingRight:
                info.label.toLowerCase() === 'description'
                  ? '0'
                  : `${theme.spacing(4)}px`,
              paddingLeft: 0,
              borderBottom:
                borderAfterLastRow || i < lastRowStartIndex
                  ? `1px solid ${theme.palette.grey[200]}`
                  : undefined,
            }}
          >
            <GenericField maxLines={maxLines} gridItem={false} {...info} />
          </Grid>
        );
      })}
    </Grid>
  );
};
