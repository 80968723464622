import { useTheme, Box } from '@material-ui/core';
import React from 'react';
import {
  CARD_HEIGHT,
  CARD_MARGIN_BOTTOM,
  CARD_REDUCED_HEIGHT,
  CARD_TOP,
} from './const';

export const VerticalLine: React.FC<{ cards: number; i?: number }> = ({
  cards,
  i,
}) => {
  const HEIGHT = i ? CARD_REDUCED_HEIGHT : CARD_HEIGHT;
  const height =
    CARD_TOP +
    // eslint-disable-next-line no-nested-ternary
    (i
      ? i === cards
        ? CARD_REDUCED_HEIGHT * (cards - 1)
        : CARD_HEIGHT + CARD_REDUCED_HEIGHT * (cards - 2)
      : (cards - 1) * CARD_HEIGHT) +
    (cards - 1) * CARD_MARGIN_BOTTOM;

  const theme = useTheme();
  return (
    <Box
      style={{
        background: theme.palette.grey[300],
        height: height + 11,
        position: 'absolute',
        width: '2px',
        top: HEIGHT - 1,
        left: 10,
      }}
    />
  );
};
