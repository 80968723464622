import React from 'react';
import { ObjectFieldTemplateProps, utils } from '@rjsf/core';
import { Box, Button, Grid, useTheme } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import { isHidden } from '../../utils';

const { canExpand } = utils;

interface DefaultObjectFieldTemplateProperties {
  displayTitle: boolean;
}

function parseDefaultObjectFieldTemplateProperties(
  props: ObjectFieldTemplateProps,
): DefaultObjectFieldTemplateProperties {
  const inputDisplayTitle = props?.uiSchema?.['ui:options']?.displayTitle;

  const displayTitle: boolean =
    typeof inputDisplayTitle === 'boolean' ? inputDisplayTitle : true;
  return {
    displayTitle,
  };
}

export const DefaultObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {
  const {
    DescriptionField,
    description,
    TitleField,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
  } = props;
  const { displayTitle } = parseDefaultObjectFieldTemplateProperties(props);
  const theme = useTheme();
  return (
    <Box style={{ display: isHidden(props.uiSchema) ? 'none' : undefined }}>
      {displayTitle && (
        <Box margin={`${theme.spacing(1)}px 0px`}>
          {(uiSchema['ui:title'] || title) && (
            <TitleField
              id={`${idSchema.$id}-title`}
              title={title}
              required={required}
            />
          )}
          {description && (
            <DescriptionField
              id={`${idSchema.$id}-description`}
              description={description}
            />
          )}
        </Box>
      )}
      <Grid container spacing={2} style={{ marginTop: '10px' }}>
        {properties.map((element, index) =>
          // Remove the <Grid> if the inner element is hidden as the <Grid>
          // itself would otherwise still take up space.
          element.hidden ? (
            element.content
          ) : (
            <Grid item xs={12} key={index} style={{ marginBottom: '10px' }}>
              {element.content}
            </Grid>
          ),
        )}
        {canExpand(schema, uiSchema, formData) && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                className="object-property-expand"
                onClick={onAddClick(schema)}
                disabled={disabled || readonly}
                color="secondary"
              >
                <AddIcon /> Add Item
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
