import {
  Box,
  Theme,
  capitalize,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import React from 'react';
import { WbTruncatedTypographyWrapper } from '../WbTruncatedTypographyWrapper';

interface IconLabelBoxProps {
  label: string;
  backgroundColor: string;
  icon?: React.ReactElement;
}

const useStyles = makeStyles<
  Theme,
  { backgroundColor: string; maxWidth?: string }
>(theme => ({
  defaultBox: {
    display: 'flex',
    gap: '6px',
    width: 'fit-content',
    borderRadius: '4px',
    padding: '6px 8px 6px 6px',
  },
  resultBox: {
    backgroundColor: ({ backgroundColor }) => backgroundColor,
    color: ({ backgroundColor }) =>
      theme.palette.getContrastText(backgroundColor),
  },
}));

export const IconLabelBox: React.FC<IconLabelBoxProps> = ({
  label,
  icon,
  backgroundColor,
}) => {
  const classes = useStyles({ backgroundColor });
  const theme = useTheme();

  const textColor = theme.palette.black;

  return (
    <Box className={classes.defaultBox} style={{ backgroundColor }}>
      {icon && (
        <Box minWidth="24px" display="flex" justifyContent="center">
          {React.cloneElement(icon, {
            fontSize: 'small',
            height: '20px',
            width: '20px',
          })}
        </Box>
      )}
      <WbTruncatedTypographyWrapper
        maxLines={1}
        value={capitalize(label)}
        enableTooltip={false}
        style={{
          color: textColor,
          fontWeight: '400',
        }}
      />
    </Box>
  );
};
