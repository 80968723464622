/**
 * This list is 1:1 with what react-idle-timer supports.
 */
export const supportedEvents: string[] = [
  'abort',
  'afterprint',
  // 'animationend',
  // 'animationiteration',
  // 'animationstart',
  'beforeprint',
  // 'beforeunload',
  'blur',
  // 'canplay',
  // 'canplaythrough',
  'change',
  'click',
  'contextmenu',
  'copy',
  'cut',
  'dblclick',
  'DOMMouseScroll',
  'drag',
  'dragend',
  'dragenter',
  'dragleave',
  'dragover',
  'dragstart',
  'drop',
  // 'durationchange',
  // 'ended',
  // 'error',
  'focus',
  'focusin',
  'focusout',
  'fullscreenchange',
  'fullscreenerror',
  'gotpointercapture',
  'hashchange',
  'input',
  'invalid',
  'keydown',
  'keypress',
  'keyup',
  'load',
  'loadeddata',
  'loadedmetadata',
  'loadstart',
  'lostpointercapture',
  'message',
  'mousedown',
  'mouseenter',
  'mouseleave',
  'mousemove',
  'mouseover',
  'mouseout',
  'mouseup',
  'mousewheel',
  'MSPointerDown',
  'MSPointerMove',
  'offline',
  'online',
  'open',
  // 'pagehide',
  // 'pageshow',
  'paste',
  'pause',
  'play',
  'playing',
  'pointercancel',
  'pointerdown',
  'pointerenter',
  'pointerleave',
  'pointermove',
  'pointerout',
  'pointerover',
  'pointerup',
  'popstate',
  'progress',
  // 'ratechange',
  'resize',
  'reset',
  'scroll',
  'search',
  'seeked',
  'seeking',
  'select',
  'show',
  // 'stalled',
  'storage',
  'submit',
  // 'suspend',
  // 'timeupdate',
  'toggle',
  'touchcancel',
  'touchend',
  'touchmove',
  'touchstart',
  'transitionend',
  'unload',
  // 'volumechange',
  // 'waiting',
  'wheel',
  'visibilitychange',
];
