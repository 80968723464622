/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { Entity } from '@backstage/catalog-model';
import {
  entityRouteRef,
  useEntity,
  useRelatedEntities,
} from '@backstage/plugin-catalog-react';
import React from 'react';
import {
  InfoCard,
  Progress,
  ResponseErrorPanel,
} from '@backstage/core-components';
import { TableCellProps, WbCard, WbTable } from '@agilelab/plugin-wb-platform';
import { DEFAULT_NAMESPACE, parseEntityRef } from '@backstage/catalog-model';
import { useRouteRef } from '@backstage/core-plugin-api';
import { useNavigate } from 'react-router';

/** @public */
export type RelatedEntitiesCardProps<T extends Entity> = {
  title: string;
  columns: TableCellProps<Entity>[];
  entityKind?: string;
  relationType: string;
  emptyMessage: string;
  emptyHelpLink: string;
  asRenderableEntities: (entities: Entity[]) => T[];
};

/**
 * A low level card component that can be used as a building block for more
 * specific cards.
 *
 * @remarks
 *
 * You probably want to make a dedicated component for your needs, which renders
 * this card as its implementation with some of the props set to the appropriate
 * values.
 *
 * @public
 */
export function RelatedEntitiesCard<T extends Entity>(
  props: RelatedEntitiesCardProps<T>,
) {
  const { title, columns, entityKind, relationType, asRenderableEntities } =
    props;

  const { entity } = useEntity();
  const entityRoute = useRouteRef(entityRouteRef);
  const navigate = useNavigate();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: relationType,
    kind: entityKind,
  });

  if (!entities || !entities.length) return <></>;

  return (
    <WbCard title={title} cardStyle={{ height: '100%' }}>
      {error && (
        <InfoCard title={title}>
          <ResponseErrorPanel error={error} />
        </InfoCard>
      )}

      <WbTable<Entity>
        components={{
          tableLoader: {
            loading: loading,
            loader: <Progress />,
          },
          tableContent: {
            columns: columns,
            rows: asRenderableEntities(entities || []),
            onRowClick: (e, ev) => {
              const tag = (ev.target as HTMLElement).tagName;
              if (tag !== 'A') {
                const data = {
                  name: e?.metadata.name || '',
                  kind: e?.kind,
                  namespace: e?.metadata.namespace || '',
                };

                data.kind = data.kind.toLocaleLowerCase('en-US');
                data.namespace =
                  data.namespace?.toLocaleLowerCase('en-US') ??
                  DEFAULT_NAMESPACE;

                const ref = parseEntityRef(data);
                navigate(entityRoute(ref));
              }
            },
          },
        }}
      />
    </WbCard>
  );
}
