import { gql } from '@apollo/client';
import { GOVERNANCE_POLICY_FRAGMENT } from '../../../fragments/governance-policy';

export const GET_POLICY_BY_POLICY_ID_AND_ENVS = gql`
  query GET_POLICY_BY_POLICY_ID_AND_ENVS($policyId: String!) {
    cgp_governance_entity(where: { governance_entity_id: { _eq: $policyId } }) {
      ${GOVERNANCE_POLICY_FRAGMENT}
    }
  }
`;
