import { makeStyles } from '@material-ui/core';
import { Editor, EditorProps, Monaco as MonacoLib } from '@monaco-editor/react';
import type monaco from 'monaco-editor';
import React, { useEffect, useRef } from 'react';

export type IOverlayWidget = monaco.editor.IOverlayWidget;
export type MonacoEditor = monaco.editor.IStandaloneCodeEditor;
export type Monaco = MonacoLib;

const useStyles = makeStyles(theme => ({
  error: {
    background: theme.palette.error.light,
  },
}));

export interface WbEditorProps extends EditorProps {
  linesWithSeverities?: number[];
}

export const WbEditor = (props: WbEditorProps): JSX.Element => {
  const classes = useStyles();
  const {
    value,
    theme,
    language,
    onMount: customOnMount,
    linesWithSeverities,
    ...wbProps
  } = props;
  const editorRef = useRef<MonacoEditor | null>(null);
  const monacoRef = useRef<Monaco | null>(null);
  const decorationsRef =
    useRef<monaco.editor.IEditorDecorationsCollection | null>(null);
  useEffect(() => {
    const applyLineDecorations = () => {
      if (!editorRef.current || !monacoRef.current) return;
      const severityStyles: Record<
        string,
        monaco.editor.IModelDecorationOptions
      > = {
        error: {
          isWholeLine: true,
          className: classes.error,
        },
      };
      const newDecorations = linesWithSeverities?.map(line => ({
        range: new monacoRef.current!.Range(line, 1, line, 1),
        options: severityStyles.error,
      }));
      if (!decorationsRef.current) {
        decorationsRef.current =
          editorRef.current.createDecorationsCollection(newDecorations);
      } else {
        decorationsRef.current.set(newDecorations ?? []);
      }
    };
    if (editorRef.current && monacoRef.current && linesWithSeverities)
      applyLineDecorations();
  }, [classes.error, value, linesWithSeverities]);

  return (
    <Editor
      value={value}
      theme={theme ?? 'default'}
      language={language ?? 'yaml'}
      onMount={(editorInstance, monaco) => {
        editorRef.current = editorInstance;
        monacoRef.current = monaco;
        if (editorInstance) {
          editorInstance.updateOptions({ scrollBeyondLastLine: false });
          if (customOnMount) customOnMount(editorInstance, monaco);
        }
      }}
      options={{
        wordWrap: 'on',
        readOnly: false,
        lineNumbers: 'on',
        minimap: {
          enabled: true,
        },
        ...wbProps.options,
      }}
      {...wbProps}
    />
  );
};
