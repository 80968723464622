import {
  GroupEntity,
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
  ANNOTATION_LOCATION,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  catalogApiRef,
  EntityRefLinks,
  getEntityRelations,
  useEntity,
} from '@backstage/plugin-catalog-react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import EmailIcon from '@material-ui/icons/Email';
import GroupIcon from '@material-ui/icons/Group';
import CachedIcon from '@material-ui/icons/Cached';
import Alert from '@material-ui/lab/Alert';
import React, { useCallback } from 'react';
import { Avatar, Link } from '@backstage/core-components';
import { alertApiRef, useApi } from '@backstage/core-plugin-api';
import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { LinksGroup } from '../LinksGroup';

/**
 * Exported publicly via the EntityUserProfileCard
 */
export function GroupProfileCard() {
  const catalogApi = useApi(catalogApiRef);
  const alertApi = useApi(alertApiRef);
  const { entity: group } = useEntity<GroupEntity>();

  const refreshEntity = useCallback(async () => {
    await catalogApi.refreshEntity(stringifyEntityRef(group));
    alertApi.post({ message: 'Refresh scheduled', severity: 'info' });
  }, [catalogApi, alertApi, group]);

  if (!group) {
    return <Alert severity="error">Group not found</Alert>;
  }

  const {
    metadata: { name, annotations, links },
    spec: { profile },
  } = group;

  const childRelations = getEntityRelations(group, RELATION_PARENT_OF, {
    kind: 'Group',
  });
  const parentRelations = getEntityRelations(group, RELATION_CHILD_OF, {
    kind: 'group',
  });

  const entityLocation = annotations?.[ANNOTATION_LOCATION];
  const allowRefresh =
    entityLocation?.startsWith('url:') || entityLocation?.startsWith('file:');

  const displayName = profile?.displayName ?? name;
  const emailHref = profile?.email ? `mailto:${profile.email}` : '#';

  return (
    <WbCard
      cardStyle={{ height: '100%' }}
      headerStyle={{
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '16px',
      }}
      title={displayName}
      leading={<GroupIcon color="primary" />}
      actions={
        <>
          {allowRefresh && (
            <IconButton
              aria-label="Refresh"
              title="Schedule entity refresh"
              onClick={refreshEntity}
            >
              <CachedIcon />
            </IconButton>
          )}
        </>
      }
    >
      <WbCardContent>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2} xl={1}>
            <Avatar displayName={displayName} picture={profile?.picture} />
          </Grid>
          <Grid item md={10} xl={11}>
            <List>
              {profile?.email && (
                <ListItem>
                  <ListItemIcon>
                    <Tooltip title="Email">
                      <EmailIcon />
                    </Tooltip>
                  </ListItemIcon>
                  <ListItemText
                    primary={<Link to={emailHref}>{profile.email}</Link>}
                    secondary="Email"
                  />
                </ListItem>
              )}
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Parent Group">
                    <AccountTreeIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    parentRelations.length ? (
                      <EntityRefLinks
                        entityRefs={parentRelations}
                        defaultKind="Group"
                      />
                    ) : (
                      'N/A'
                    )
                  }
                  secondary="Parent Group"
                />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Tooltip title="Child Groups">
                    <GroupIcon />
                  </Tooltip>
                </ListItemIcon>
                <ListItemText
                  primary={
                    childRelations.length ? (
                      <EntityRefLinks
                        entityRefs={childRelations}
                        defaultKind="Group"
                      />
                    ) : (
                      'N/A'
                    )
                  }
                  secondary="Child Groups"
                />
              </ListItem>
              <LinksGroup links={links} />
            </List>
          </Grid>
        </Grid>
      </WbCardContent>
    </WbCard>
  );
}
