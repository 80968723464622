import { Entity, EntityMeta } from '@backstage/catalog-model';
import { generateAjvJsonSchemaValidator } from '../utils';
import schema from '../schema/Template.v1alpha1.schema.json';

export const WitboostTemplateFileName = 'template.yaml';
export interface WitboostTemplate extends Entity {
  formDataDefaultValues?: Record<string, string>;
  metadata: EntityMeta & {
    classDetails?: {
      pluralizedDisplayName?: string;
      displayName?: string;
      type?: string;
      displayPriority?: number;
    };
  };
  spec: {
    generates: string;
    owner: string;
    type: string;
    steps: [
      {
        input: {
          values: {
            useCaseTemplateId: string;
          } & Record<string, any>;
        } & Record<string, any>;
      } & Record<string, any>,
    ];
  };
}

export const creationTemplateEntityV1alpha1Validator =
  generateAjvJsonSchemaValidator(schema);
