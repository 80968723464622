import { createApiRef } from '@backstage/core-plugin-api';
import { UserConfigApi } from '@agilelab/plugin-wb-user-config-client';

/**
 * The API reference for the UserConfigApi.
 * @public
 */
export const userConfigApiRef = createApiRef<UserConfigApi>({
  id: 'plugin.auth.user-config',
});
