import { renderMessage, WbAutocomplete } from '@agilelab/plugin-wb-platform';
import { FormControl } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { parseEntityRef } from '@backstage/catalog-model';

function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function extractGroup(option: string): string {
  try {
    const entity = parseEntityRef(option);
    return capitalize(entity.kind);
  } catch (error) {
    return 'Unknown Entity';
  }
}

function composePlaceholder(placeholder?: string, maxItems?: number): string {
  return `${placeholder ?? 'Type to search'} ${
    maxItems ? `(Max ${maxItems} item${maxItems > 1 ? 's' : ''})` : ''
  }`;
}

export type EntityRefBasePickerProps = {
  /**
   * List of available options. must be in the form of a backstage entity reference. e.g. group:default/team-a or group:team-a
   */
  options: string[];

  /**
   * List of selected options. pass them here to prefill the field
   */
  prefillValues: string[];

  /**
   * The onChange function given by the rfjs library and the backstage implementation
   */
  onChange: any;

  /**
   * The maximum number of items that can be selected
   */
  maxItems?: number;

  required?: boolean;
  disabled?: boolean;

  /**
   * id of the field for React to use as a key
   */
  fieldId?: string;

  title: string;
  helperText?: string;
  placeholder?: string;
  error?: boolean;

  /**
   * An additional list of errors to display, if any
   */
  additionalErrors?: string[];

  customProps?: any;
  /**
   * If true, the element will not be displayed
   */
  hidden?: boolean;
};

export const EntityRefBasePicker = (props: EntityRefBasePickerProps) => {
  const {
    prefillValues,
    onChange,
    options,
    maxItems,
    required,
    fieldId,
    disabled,
    title,
    helperText,
    placeholder,
    error,
    hidden,
    additionalErrors,
    customProps,
  } = props;

  const [values, setValues] = useState<string[]>(prefillValues);
  const [errors, setErrors] = useState<string[]>(additionalErrors ?? []);

  const isOptionDisabled = useCallback(
    (option: any) =>
      values.includes(option) || (!!maxItems && values.length >= maxItems),
    [maxItems, values],
  );

  const onSelect = useCallback(
    (_: unknown, val: any) => {
      setValues(val);
      onChange(val.length === 0 ? undefined : val);
      setErrors([]);

      if (required && val.length === 0) {
        setErrors([
          ...(additionalErrors ?? []),
          `You must select at least one element.`,
        ]);
      }

      if (maxItems && val.length > maxItems) {
        setErrors([
          ...(additionalErrors ?? []),
          `You have selected too many elements. You can select at most ${maxItems} item${
            maxItems > 1 ? 's' : ''
          }.`,
        ]);
      }
    },
    [onChange, required, maxItems, additionalErrors],
  );

  return (
    <FormControl
      style={{ display: hidden ?? false ? 'none' : undefined }}
      required={required}
      error={errors.length > 0}
    >
      <WbAutocomplete<string, any, any, any>
        size="small"
        style={{ display: 'flex', width: '100%', height: '100%' }}
        id={fieldId}
        multiple
        getOptionDisabled={isOptionDisabled}
        disableCloseOnSelect={!maxItems || maxItems > 1}
        options={options}
        disabled={disabled || options.length === 0}
        label={title}
        limitTags={5}
        placeholder={
          values.length > 0 ? '' : composePlaceholder(placeholder, maxItems)
        }
        groupBy={extractGroup}
        renderOption={(option: any, { selected }) => (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            {option}
            {selected && <CheckIcon />}
          </div>
        )}
        value={values}
        error={error || errors.length > 0}
        helperText={
          errors.length > 0 ? renderMessage(errors.join('\n')) : helperText
        }
        onChange={onSelect}
        required={required}
        {...customProps}
      />
    </FormControl>
  );
};
