import {
  customAlertApiRef,
  WbCardActionButton,
  WbLink,
} from '@agilelab/plugin-wb-platform';
import React from 'react';
import { useReleasePageContext } from '../../context/useReleasePageContext';
import { useApi, useRouteRef } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../../api';
import { ReleaseAndDeployments } from '@agilelab/plugin-wb-builder-common';
import { Tooltip } from '@material-ui/core';
import { releaseDetailRouteRef } from '../../../../routes';
import { getReleaseInfo } from '../../../../utils';
import { usePermission } from '@backstage/plugin-permission-react';
import { builderDpReleasePermission } from '@agilelab/plugin-wb-rbac-common';
import { stringifyEntityRef } from '@backstage/catalog-model';

interface Props {
  release: ReleaseAndDeployments;
}

const RELEASE_TOOLTIP =
  'Create a new release from the existing draft release. This operation is like an update operation, but creates an immutable release that cannot be updated anymore.';

export function generateReleaseTooltip(canRelease: boolean, loading: boolean) {
  if (!canRelease)
    return `You are not allowed to promote this draft to release`;

  if (loading) return 'Wait for the current operations to finish';
  return RELEASE_TOOLTIP;
}

export const PromoteToReleaseButton: React.FC<Props> = ({ release }) => {
  const { isLoading, isPromoting, setIsPromoting, addRelease, entity } =
    useReleasePageContext();
  const panelCatalog = useApi(panelCatalogApiRef);
  const alertApi = useApi(customAlertApiRef);

  const releaseDetailRoute = useRouteRef(releaseDetailRouteRef);

  const { allowed: canRelease } = usePermission({
    permission: builderDpReleasePermission,
    resourceRef: stringifyEntityRef({
      kind: entity?.kind ?? 'system',
      namespace: 'default',
      name: entity?.metadata.name ?? '',
    }),
  });

  const handleRelease = async () => {
    setIsPromoting(true);
    try {
      const newRelease = await panelCatalog.promoteToRelease(
        release.metadata.name,
        release.metadata.projectName ?? release.metadata.dataProductName,
        release.metadata.projectKind,
      );
      addRelease({ ...newRelease, deployments: release.deployments });

      const { kind, namespace, name } = getReleaseInfo(newRelease);

      alertApi.post({
        actions: (
          <WbLink
            route={releaseDetailRoute({ kind, namespace, name })}
            text={`Open V${newRelease?.metadata?.version}`}
          />
        ),
        message: 'New Release created successfully.',
        severity: 'success',
      });
    } catch (error) {
      alertApi.post({
        error,
        severity: 'error',
      });
    } finally {
      setIsPromoting(false);
    }
  };

  return (
    <Tooltip title={generateReleaseTooltip(canRelease, isLoading)}>
      <span>
        <WbCardActionButton
          label="Promote to Release"
          color="primary"
          size="small"
          variant="outlined"
          loading={isPromoting}
          disabled={!canRelease || isLoading}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            handleRelease();
          }}
        />
      </span>
    </Tooltip>
  );
};
