import { UiSchema } from '@rjsf/core';
import { WmCompleteUiSchema } from '../extensions/types';
import { Subscription } from '@backstage/types';
import { ScaffolderApi } from '../types';
import { ReducerLogEntry } from './hooks/useEventStream';
import { CustomAlertApi } from '@agilelab/plugin-wb-platform';

export function extractCustomProperties<
  T extends Record<string, any> = UiSchema,
>(uiSchema: T) {
  const customProps: any = {};
  const customStyle = uiSchema && uiSchema['ui:style'];
  if (customStyle) {
    customProps.InputProps = {
      style: customStyle,
    };
  }
  return customProps;
}

export function isHidden<T extends UiSchema, U extends WmCompleteUiSchema>(
  uiSchema: T | U,
): boolean {
  const isHiddenWidget = (s: T | U) =>
    s?.['ui:widget'] ? s['ui:widget'] === 'hidden' : false;
  // checks for legacy reasons if the ui:widget property is inside ui:options as well(deprecated, don't do that)
  const isLegacyHidden = (s: T | U) =>
    (s?.['ui:options'] as any)?.['ui:widget']
      ? (s['ui:options'] as any)?.['ui:widget'] === 'hidden'
      : false;
  return isHiddenWidget(uiSchema) || isLegacyHidden(uiSchema);
}

type SimplifiedTaskStream = {
  error?: Error;
  completed: boolean;
  failed: boolean;
};

/**
 * Finalizes the tasks and triggers the alertApi.
 * The whole alertApi object needs to be passed or it will break
 */
export const scaffolderTasksFinalizer = (
  taskIds: string[],
  scaffolderApi: ScaffolderApi,
  alertApi: CustomAlertApi,
) => {
  if (!taskIds) {
    return;
  }
  const completionRecord: Map<string, SimplifiedTaskStream> = new Map();
  taskIds.forEach(taskId => {
    completionRecord.set(taskId, {
      completed: false,
      failed: false,
    });
  });

  let finalized = false;

  const finalize = () => {
    if (
      !finalized &&
      Array.from(completionRecord.values()).every(
        taskStream => taskStream.completed,
      )
    ) {
      const failed = Array.from(completionRecord.values()).some(
        taskStream => taskStream.failed,
      );
      if (failed) {
        const error = failed
          ? Array.from(completionRecord.values()).find(
              taskStream => taskStream.failed,
            )?.error
          : undefined;
        alertApi.post({ error, severity: 'error' });
      } else {
        alertApi.post({
          message: 'An access request has been sent!',
          severity: 'success',
        });
      }
      finalized = true;
    }
  };

  const subscriptions: Record<string, Subscription> = {};

  taskIds.forEach(taskId => {
    const observable = scaffolderApi.streamLogs({ taskId });
    subscriptions[taskId] = observable.subscribe({
      next: event => {
        switch (event.type) {
          case 'completion':
            completionRecord.set(taskId, {
              completed: true,
              failed: (event as ReducerLogEntry).body.error ? true : false,
              error: (event as ReducerLogEntry).body.error,
            });
            finalize();
            return;
          default:
            return;
        }
      },
      error: error => {
        completionRecord.set(taskId, {
          completed: true,
          failed: true,
          error,
        });
        finalize();
      },
    });
  });
};
