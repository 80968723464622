import { DiscoveryApi, IdentityApi } from '@backstage/core-plugin-api';
import { MeshAuditApi } from './MeshAuditApi';
import fetch from 'cross-fetch';
import { AuditEntry } from '@agilelab/plugin-wb-audit-common';

export class MeshAuditClient implements MeshAuditApi {
  constructor(
    private readonly discoveryApi: DiscoveryApi,
    private readonly identityApi: IdentityApi,
  ) {}

  async write(audit: AuditEntry): Promise<void> {
    const auditUrl = await this.discoveryApi.getBaseUrl('audit');
    const credentials = await this.identityApi.getCredentials();

    fetch(`${auditUrl}/audit`, {
      method: 'POST',
      body: JSON.stringify(audit),
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${credentials.token}`,
      },
    });
  }
}
