import { WbHeader } from '@agilelab/plugin-wb-platform';
import { Content, Page } from '@backstage/core-components';
import { makeStyles } from '@material-ui/core';
import React from 'react';
import { SearchPageContent } from './components/SearchPageContent';

const useStyles = makeStyles(
  () => ({
    content: {
      minHeight: 0,
    },
  }),
  { name: 'SearchPage' },
);

export const SearchPage = () => {
  const classes = useStyles();

  return (
    <Page themeId="home">
      <Content className={classes.content}>
        <WbHeader title="Search" hidden />
        <SearchPageContent />
      </Content>
    </Page>
  );
};
