import React, { ReactNode } from 'react';
import {
  Box,
  Theme,
  makeStyles,
  withStyles,
  createStyles,
  InputBase,
  Select,
  InputLabel,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import clsx from 'clsx';
import { WbSelectInlineHeader } from './WbSelectInlineHeader';

interface Props {
  onChange: (value: string) => void;
  title?: string;
  value?: string;
  menuTitle?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  children: ReactNode;
}

const useStyles = makeStyles(
  (theme: Theme) => ({
    container: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    divider: {
      height: '20px',
      width: '1px',
      pointerEvents: 'none',
      backgroundColor: theme.palette.grey[300],
    },
    iconContainer: {
      position: 'absolute',
      right: 0,
      bottom: 0,
      top: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingInline: theme.spacing(1),
      gap: theme.spacing(1),
      pointerEvents: 'none',
    },
    icon: {
      fontSize: '13px',
      color: theme.palette.secondary.main,
      position: 'static',
      backgroundColor: 'transparent',
    },
    menu: {
      paddingInline: theme.spacing(1),
      minWidth: '240px',
      backgroundColor: theme.palette.white,
    },
    menuPopover: {
      boxShadow: theme.shadows[2],
    },
  }),
  { name: 'WbCardSelect' },
);

const CustomSelectIconComponent = (props: any) => {
  const classes = useStyles();
  return (
    <Box className={classes.iconContainer}>
      <Box className={classes.divider} />
      <KeyboardArrowDownIcon className={clsx(props.className, classes.icon)} />
    </Box>
  );
};

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      // define here to have higher priority than MUI class
      '& > $input': {
        paddingRight: '38px',
      },
      width: '190px',
      '&$disabled': {
        opacity: 0.5,
        '& > $input > p': {
          color: 'rgba(0, 0, 0, 0.6)',
        },
      },
    },
    disabled: {},
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      alignItems: 'center',
      padding: 0,
      paddingLeft: 8,
      height: 32,
      boxSizing: 'border-box',
      ...theme.typography.body1,
      color: theme.palette.secondary.dark,
      '& p': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        paddingRight: '0',
        fontWeight: theme.typography.fontWeightMedium as number,
      },
      '&:focus': {
        borderRadius: 4,
        backgroundColor: theme.palette.white,
        borderColor: theme.palette.primary.main,
        borderWidth: '2px',
      },
    },
  }),
)(InputBase);

export const WbSelectInline: React.FC<Props> = ({
  onChange,
  title,
  value,
  children,
  style,
  disabled,
  menuTitle,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {title && (
        <InputLabel id={`${title}-label`} color="secondary">
          {title}
        </InputLabel>
      )}
      <Select
        labelId={`${title}-label`}
        value={value}
        IconComponent={CustomSelectIconComponent}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          PaperProps: {
            className: classes.menuPopover,
          },
          MenuListProps: { className: classes.menu },
          getContentAnchorEl: null,
        }}
        input={<CustomInput />}
        onChange={(e: any) => {
          onChange(e.target.value);
        }}
        style={style}
        disabled={disabled}
      >
        {menuTitle && <WbSelectInlineHeader>{menuTitle}</WbSelectInlineHeader>}
        {children}
      </Select>
    </Box>
  );
};
