/**
 * Transforms an interaction type string to spaced case (e.g. "user2user" to "User to User").
 *
 * @param value the input interaction type string
 * @returns the resulting spaced case string
 */
export function toHumanReadableInteractionType(value: string): string {
  switch (value) {
    case 'user2user': {
      return 'User to User';
    }
    case 'user2platform': {
      return 'User to Platform';
    }
    default: {
      return value;
    }
  }
}
