/*
 * This refershing session implementation checks the expiration given an external function
 * and issues a refresh command on the connector.
 * This implementation differs from the Backstage one since there is no login popup and
 * the refresh operation takes the session as input.
 */

import {
  SessionManager,
  SessionShouldRefreshFunc,
  GetSessionOptions,
  AuthConnector,
} from './types';
import { SessionStateTracker } from './SessionStateTracker';

type Options<T> = {
  /** The connector used for acting on the auth session */
  connector: AuthConnector<T>;
  /** Storage key to use to store sessions */
  storageKey: string;
  /** Used to check if the session needs to be refreshed */
  sessionShouldRefresh: SessionShouldRefreshFunc<T>;
};

/**
 * RefreshingAuthSessionManager manages an underlying session that has
 * and expiration time and needs to be refreshed periodically.
 */
export class LdapRefreshingAuthSessionManager<T> implements SessionManager<T> {
  private readonly connector: AuthConnector<T>;
  private readonly storageKey: string;
  private readonly sessionShouldRefreshFunc: SessionShouldRefreshFunc<T>;
  private readonly stateTracker = new SessionStateTracker();

  private refreshPromise?: Promise<T>;
  private currentSession: T | undefined;

  constructor(options: Options<T>) {
    const { connector, storageKey, sessionShouldRefresh } = options;

    this.connector = connector;
    this.storageKey = storageKey;
    this.sessionShouldRefreshFunc = sessionShouldRefresh;
  }

  setSession(session: T | undefined): void {
    this.currentSession = session;
    this.saveSession(session);
  }

  async getSession(options: GetSessionOptions): Promise<T | undefined> {
    const storedSession = this.loadSession();
    if (!this.currentSession && storedSession) {
      this.currentSession = storedSession;
    }

    if (this.currentSession) {
      const shouldRefresh = this.sessionShouldRefreshFunc(this.currentSession!);
      if (!shouldRefresh) {
        return this.currentSession!;
      }

      try {
        const refreshedSession = await this.collapsedSessionRefresh();
        this.setSession(refreshedSession);
        return refreshedSession;
      } catch (error) {
        // in case the refresh operation fails, we clear the current session and return an undefined session.
        // TODO: route the current page to "/" so the login page is presented to the user.
        this.removeSession();
        return undefined;
      }
    }

    // If we continue here we will show a popup, so exit if this is an optional session request.
    if (options.optional) {
      return undefined;
    }

    // We can call authRequester multiple times, the returned session will contain all requested scopes.
    this.setSession(await this.connector.createSession(options));
    this.stateTracker.setIsSignedIn(true);
    return this.currentSession;
  }

  async removeSession() {
    this.setSession(undefined);
    localStorage.removeItem(this.storageKey);
    await this.connector.removeSession();
    this.stateTracker.setIsSignedIn(false);
  }

  sessionState$() {
    return this.stateTracker.sessionState$();
  }

  private async collapsedSessionRefresh(): Promise<T> {
    if (this.refreshPromise) {
      return this.refreshPromise;
    }

    this.refreshPromise = this.connector.refreshSession(this.currentSession!);

    try {
      const session = await this.refreshPromise;
      this.stateTracker.setIsSignedIn(true);
      return session;
    } finally {
      delete this.refreshPromise;
    }
  }

  private loadSession(): T | undefined {
    try {
      const sessionJson = localStorage.getItem(this.storageKey);
      if (sessionJson) {
        const session = JSON.parse(sessionJson, (_key, value) => {
          if (value?.__type === 'Set') {
            return new Set(value.__value);
          }
          return value;
        });
        return session;
      }

      return undefined;
    } catch (error) {
      localStorage.removeItem(this.storageKey);
      return undefined;
    }
  }

  private saveSession(session: T | undefined) {
    if (session === undefined) {
      localStorage.removeItem(this.storageKey);
      return;
    }

    localStorage.setItem(
      this.storageKey,
      JSON.stringify(session, (_key, value) => {
        if (value instanceof Set) {
          return {
            __type: 'Set',
            __value: Array.from(value),
          };
        }
        return value;
      }),
    );
  }
}
