import { Box, IconButton, useTheme } from '@material-ui/core';
import React from 'react';
import { WbSelectInlineDivider } from '../WbSelectInline';
import { ClearButton } from '../WbTextField/ClearButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export const FieldActions = ({
  onClear,
  onOpen,
}: {
  onClear: () => void;
  onOpen: () => void;
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      style={{ gap: '2px', position: 'absolute', right: '9px' }}
    >
      <ClearButton
        onClear={e => {
          const event = e as unknown as React.ChangeEvent<{
            value: string[];
          }>;
          onClear();
          event.preventDefault();
          event.stopPropagation();

          event.target.value = [];
        }}
      />
      <WbSelectInlineDivider
        style={{
          height: '15px',
          width: '1px',
          pointerEvents: 'none',
          backgroundColor: theme.palette.grey[400],
        }}
      />
      <IconButton
        style={{
          padding: '0px',
          transform: 'rotate(-90deg)',
        }}
        onClick={onOpen}
      >
        <ExpandMoreIcon />
      </IconButton>
    </Box>
  );
};
