import { parseEntityRef } from '@backstage/catalog-model';
import { capitalize } from 'lodash';
import { DateTime } from 'luxon';
import { initNunjucks } from './nunjucks.utils';

export const isLowercase = (input: string) => {
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) < 97 || input.charCodeAt(i) > 122) return false;
  }

  return true;
};

export const isUppercase = (input: string) => {
  for (let i = 0; i < input.length; i++) {
    if (input.charCodeAt(i) < 65 || input.charCodeAt(i) > 90) return false;
  }

  return true;
};

export const isURL = (value: string): boolean => {
  let url;

  try {
    url = new URL(value);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
};

export const isEntityRef = (value: string): boolean => {
  try {
    return parseEntityRef(value) ? true : false;
  } catch (error) {
    return false;
  }
};

export const isEmail = (email: string): boolean => {
  try {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
      ? true
      : false;
  } catch (error) {
    return false;
  }
};

export const isISODate = (dateString: string): boolean => {
  if (typeof dateString !== 'string') {
    return false;
  }

  const isNum = /^\d+$/.test(dateString);
  if (isNum || typeof dateString === 'boolean') {
    return false;
  }
  return DateTime.fromISO(dateString).isValid;
};

/**
 * Transforms a camel case string to snake case (e.g. "helloWorld" to "hello_world").
 * This transformation does not handle spaces in the input string.
 *
 * @param str the input camel case string
 * @returns the resulting snake case string
 */
export const transformCamelToSnakeCase = (str: string): string =>
  str.substring(0, 1).toLowerCase() +
  str.substring(1).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

/**
 * Transforms a camel case string to spaced case (e.g. "helloWorld" to "Hello World").
 * This transformation does not handle spaces in the input string.
 * If the keepUpperCaseWords parameter is set to false, each word will have a lower case starting letter
 * (e.g. "helloWorld" is converted into "hello world").
 *
 * @param str the input camel case string
 * @param keepUpperCaseWords tells if each word should have a capital starting letter
 * @returns the resulting spaced case string
 */
export const transformCamelToSpacedCase = (
  str: string,
  keepUpperCaseWords: boolean = true,
): string => {
  const changeFirstLetter = (letter: string) =>
    keepUpperCaseWords ? letter.toUpperCase() : letter.toLocaleLowerCase();
  return (
    changeFirstLetter(str.substring(0, 1)) +
    str
      .substring(1)
      .replace(/[A-Z]/g, letter => ` ${changeFirstLetter(letter)}`)
  );
};

export const applyConversionToCamelCasedWithSpacesInput = (
  str: string,
  f: (s: string) => string,
  joinString: string,
) => {
  return str.trim().split(/\s+/).map(f).join(joinString);
};

/**
 * Transforms a camel case string to snake case (e.g. "helloWorld" to "hello_world").
 * This transformation handles also strings containing spaces: leading and trailing spaces are removed,
 * and spaces in between camel cased sections are translated into "_" (e.g. " firstOne  secondOne  " is converted into "first_one_second_one").
 *
 * @param str the input camel case string
 * @returns the resulting snake case string
 */
export const camelToSnakeCase = (str: string): string =>
  applyConversionToCamelCasedWithSpacesInput(
    str,
    transformCamelToSnakeCase,
    '_',
  );

/**
 * Transforms a camel case string to spaced case (e.g. "helloWorld" to "Hello World").
 * This transformation handles also strings containing spaces: leading and trailing spaces are removed,
 * and spaces in between camel cased sections are translated into spaces (e.g. " firstOne  secondOne  " is converted into "First One Second One").
 * If the keepUpperCaseWords parameter is set to false, each word will have a lower case starting letter
 * (e.g. " firstOne  secondOne  " is converted into "first one second one").
 *
 * @param str the input camel case string
 * @param keepUpperCaseWords tells if each word should have a capital starting letter
 * @returns the resulting spaced case string
 */
export const camelToSpacedCase = (
  str: string,
  keepUpperCaseWords: boolean = true,
): string =>
  applyConversionToCamelCasedWithSpacesInput(
    str,
    s => transformCamelToSpacedCase(s, keepUpperCaseWords),
    ' ',
  );

/**
 * Transforms a snake case string to spaced case (e.g. "hello_World" to "Hello World").
 *
 * @param value the input snake case string
 * @returns the resulting spaced case string
 */
export function snakeCaseToTitleCase(value: string): string {
  return value
    .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
    .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);
}

/**
 * A method to replace in a string all the occurrences of {{ descriptor.field }}
 * with the corresponding value retrieved from the descriptor parameter leveraging Nunjucks.
 *
 * This method will not throw exceptions for non-existing fields, even if one or more fields defined in the template
 * are not present in the input object.
 *
 * Any unsafe character will not be escaped (set 'autoscape:true' to safe all output).
 *
 * @param template the Nunjucks template that will be applied to the input object
 * @param input a generic object that will be used as input for the Nunjucks template
 * @param canFail specify if this method must throw an error if nunjucks is incorrect, or ignore the error otherwise.
 * @returns the parsed input object or undefined if the template is not passed
 */
export function parseNunjucks(
  template: string | undefined,
  input: any,
  canFail: boolean = true,
): string | undefined {
  if (template && template !== '') {
    try {
      const env = initNunjucks({ autoescape: false, throwOnUndefined: true });
      return env.renderString(template, input);
    } catch (e) {
      if (canFail) return undefined;
      throw new Error(e);
    }
  }

  return undefined;
}

/* Checks whether a given string is a valid base64-encoded string.
 *
 * @param {string} str - The input string to be checked for base64 encoding.
 * @returns {boolean} Returns true if the input string is a valid base64-encoded string; otherwise, returns false.
 *
 * @notes
 * - The function uses a regular expression to validate the base64 encoding format.
 * - It checks for both standard and URL-safe base64 encoding.
 */
export function isBase64Encoded(str: string): boolean {
  return /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/.test(
    str,
  );
}

/**
 * Converts a camelCase string to Capital Case.
 *
 * @param {string} input - The input camelCase string to be converted.
 * @returns {string} The converted Capital Case string.
 *
 * @example
 * // Example usage:
 * const camelCaseString = "myVariableName";
 * const capitalCaseString = camelCaseToCapitalCase(camelCaseString);
 * console.log(capitalCaseString); // Output: "My Variable Name"
 *
 * thanks chatgpt <3
 */
export function camelCaseToCapitalCase(input: string): string {
  if (input.includes(' ')) {
    return input; // not a camelCase string
  }

  // Replace each uppercase letter with a space followed by the uppercase letter
  const spacedString = input.replace(/([A-Z])/g, ' $1');
  const words = spacedString.split(' ');

  // Capitalize the first letter and remove leading spaces
  return words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).trim())
    .join(' ');
}

/**
 * Converts a string belonging to any (snake, camel, kebab, pascal, spaced, capital, mixed) case to an array of words, ready to be converted to any other case.
 *
 * @param {string} input - The input string to be converted.
 * @returns {string[]} The array of words.
 */
export function anyCaseToWordList(input: string): string[] {
  const words = [];
  let currWordStart: number | null = null;
  for (let i = 0; i < input.length; i++) {
    const curr = input[i];
    // check if separator character
    if (['-', ' ', '_'].includes(curr)) {
      if (currWordStart !== null) {
        words.push(input.slice(currWordStart, i));
        currWordStart = null;
      }
      continue;
    }

    // check if there is a change in lowerCase|upperCase to signal the start of a new word in camel/pascal case
    if (
      currWordStart !== null &&
      isLowercase(input[i - 1]) &&
      isUppercase(curr)
    ) {
      words.push(input.slice(currWordStart, i));
      currWordStart = null;
    }

    if (currWordStart === null) currWordStart = i;
  }

  // add the last word
  if (currWordStart !== null)
    words.push(input.slice(currWordStart, input.length));

  return words;
}

/**
 * Converts a string belonging to any (snake, camel, kebab, pascal, spaced, capital, mixed) cases to Capital Case.
 *
 * @param {string} input - The input string to be converted.
 * @param {boolean} keepUppercaseWords - If true, keep the words that were completely uppercase (ex: acronyms) unaltered.
 * @returns {string} The converted Capital Case string.
 *
 * @example
 * // Example usage:
 * const string = "myVariableName";
 * const capitalCaseString = anyCaseToCapitalCase(string);
 * console.log(capitalCaseString); // Output: "My Variable Name"
 */
export function anyCaseToCapitalCase(
  input: string,
  keepUppercaseWords: boolean = true,
): string {
  return anyCaseToWordList(input)
    .map(w => (keepUppercaseWords && isUppercase(w) ? w : capitalize(w)))
    .join(' ');
}
