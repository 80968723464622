import {
  EvaluationResultSpecific,
  Metric,
} from '@agilelab/plugin-wb-governance-common';
import React from 'react';
import { GovernanceTestOverviewMetricTabs } from './GovernanceTestOverviewMetricTabs';
import { GovernanceTestOverviewMetricSimple } from './GovernanceTestOverviewMetricSimple';
import { GovernanceTestOverviewMetricControlPanel } from './GovernanceTestOverviewMetricControlPanel';

interface CommonProps {
  children?: React.ReactNode;
}

type ConditionalProps =
  | {
      kind: 'simple';
      metric: Metric;
    }
  | {
      kind: 'tabs';
      result: EvaluationResultSpecific;
      metric: Metric;
    }
  | {
      kind: 'controlPanel';
      infoContent?: JSX.Element;
      metric: Metric;
      test: {
        errors?: string[];
        content: string;
        name: string;
      };
    };

type Props = CommonProps & ConditionalProps;

export const GovernanceTestOverviewMetric: React.FC<Props> = props => {
  switch (props.kind) {
    case 'simple':
      return <GovernanceTestOverviewMetricSimple {...props} />;
    case 'tabs':
      return <GovernanceTestOverviewMetricTabs {...props} />;
    case 'controlPanel':
      return <GovernanceTestOverviewMetricControlPanel {...props} />;
    default:
      return <></>;
  }
};
