import React from 'react';
import { IconButton, TableCell, TableRow } from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { TestResults } from './TestResults';
import { TestStatus } from '@agilelab/plugin-wb-platform';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { TaskAccordion } from './TaskAccordion';
import { useTestResults } from './hooks/useTestResult';
import { Test } from '../types';
import { useEditorPageContext } from '../context/useEditorPageContext';

interface Props {
  test: Test;
}

export const TestTableRow: React.FC<Props> = ({ test }) => {
  const { policiesWithWarnings } = useTestResults(test);
  const { setSelectedTestRow, selectedTestRow } = useEditorPageContext();
  const open = selectedTestRow === test.id;

  const toggle = () => {
    setSelectedTestRow(open ? null : test.id);
  };

  return (
    <>
      <TableRow selected={selectedTestRow === test.id}>
        <TableCell width="5%">
          <TestStatus
            status={test.status}
            okWithWarnings={!!policiesWithWarnings}
            variant="reduced"
          />
        </TableCell>
        <TableCell>
          {format(parseISO(test.startDate), 'yyyy/MM/dd HH:mm:ss')}
        </TableCell>
        <TableCell>
          <TestResults test={test} />
        </TableCell>
        <TableCell width="5%">
          <IconButton aria-label="expand row" size="small" onClick={toggle}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TaskAccordion test={test} />
    </>
  );
};
