import { handleFailedResponse } from '@agilelab/plugin-wb-platform-common';
import { ReportDetailsRequest, ReportDetailsResponse } from '../types';
import { DiscoveryApi, FetchApi } from '@backstage/core-plugin-api';
import crossFetch from 'cross-fetch';

export interface MetricsClient {
  getReportDetails(
    options: ReportDetailsRequest,
    credentials: { token?: string },
  ): Promise<ReportDetailsResponse>;
}

export class DefaultMetricsClient implements MetricsClient {
  private readonly discoveryApi: DiscoveryApi;
  private readonly baseUrlPromise: Promise<string>;
  private readonly fetchApi: FetchApi;

  constructor(options: {
    discoveryApi: { getBaseUrl(pluginId: string): Promise<string> };
    fetchApi?: { fetch: typeof fetch };
  }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi || { fetch: crossFetch };
    this.baseUrlPromise = this.discoveryApi.getBaseUrl('metrics');
  }

  async getReportDetails(
    options: ReportDetailsRequest,
    credentials: { token?: string },
  ): Promise<ReportDetailsResponse> {
    const baseUrl = await this.baseUrlPromise;

    const queryParams = new URLSearchParams();
    Object.entries(options).forEach(([key, value]) => {
      if (value) {
        queryParams.append(key, value.toString());
      }
    });

    const response = await this.fetchApi.fetch(
      `${baseUrl}/report-details?${queryParams}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${credentials.token}`,
        },
      },
    );
    await handleFailedResponse(response);

    return response.json();
  }
}
