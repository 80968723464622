import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import { FieldProps } from '@rjsf/core';

export const TitleField = (props: FieldProps) => {
  return props?.title ? (
    <>
      <Typography variant="h4">{props.title}</Typography>
      <Divider />
    </>
  ) : (
    <></>
  );
};
