import {
  ConfigApi,
  configApiRef,
  identityApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import React, { useMemo, useState } from 'react';
import {
  EventsType,
  IdleTimerProvider,
  IIdleTimer,
  workerTimers,
} from 'react-idle-timer';
import { z } from 'zod';

import { useLogoutDisconnectedUserEffect } from './disconnectedUsers';
import { StillTherePrompt } from './StillTherePrompt';
import { supportedEvents } from './supportedEvents';
import { DefaultTimestampStore, TimestampStore } from './timestampStore';

export const LAST_SEEN_ONLINE_STORAGE_KEY =
  '@witboost/autologout:lastSeenOnline';

const minimumAllowedConfig = {
  idleTimeoutMinutes: 0.5,
};

const defaultConfig = {
  enabled: false,
  idleTimeoutMinutes: 60,
  promptBeforeIdleSeconds: 10,
  useWorkerTimers: true,
  logoutIfDisconnected: true,
  events: [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange',
  ],
};

export const AutologoutOptionsZod = z
  .object({
    enabled: z.boolean().optional().default(defaultConfig.enabled),
    idleTimeoutMinutes: z
      .number()
      .positive()
      .min(minimumAllowedConfig.idleTimeoutMinutes)
      .optional()
      .default(defaultConfig.idleTimeoutMinutes),
    promptBeforeIdleSeconds: z
      .number()
      .nonnegative()
      .optional()
      .default(defaultConfig.promptBeforeIdleSeconds),
    useWorkerTimers: z
      .boolean()
      .optional()
      .default(defaultConfig.useWorkerTimers),
    events: z.array(z.string()).optional().default(defaultConfig.events),
    logoutIfDisconnected: z
      .boolean()
      .optional()
      .default(defaultConfig.logoutIfDisconnected),
  })
  .refine(
    schema => schema.idleTimeoutMinutes * 60 > schema.promptBeforeIdleSeconds,
    `'auth.autologout.promptBeforeIdleSeconds' should be smaller than 'auth.autologout.idleTimeoutMinutes'`,
  );

export type AutologoutOptions = z.infer<typeof AutologoutOptionsZod>;

const toEventsType = (eventName: string): EventsType => {
  if (supportedEvents.includes(eventName)) {
    return eventName as EventsType;
  }
  throw new Error(
    `An unknown or not supported DOM event has been found in the Autologout configuration ('${eventName}'). Check your configuration under 'auth.autologout.events'.`,
  );
};

const minutesToMillis = (minutes: number): number => {
  return minutes * 60 * 1000;
};

const parseConfig = (configApi: ConfigApi): AutologoutOptions => {
  const configRead = AutologoutOptionsZod.safeParse({
    enabled: configApi.getOptionalBoolean('auth.autologout.enabled'),
    idleTimeoutMinutes: configApi.getOptionalNumber(
      'auth.autologout.idleTimeoutMinutes',
    ),
    promptBeforeIdleSeconds: configApi.getOptionalNumber(
      'auth.autologout.promptBeforeIdleSeconds',
    ),
    useWorkerTimers: configApi.getOptionalBoolean(
      'auth.autologout.useWorkerTimers',
    ),
    events: configApi.getOptionalStringArray('auth.autologout.events'),
    logoutIfDisconnected: configApi.getOptionalBoolean(
      'auth.autologout.logoutIfDisconnected',
    ),
  });

  if (!configRead.success) {
    const errors = configRead.error.issues.map(issue =>
      issue.path.length > 0
        ? `'${issue.path.map(path => `auth.autologout.${path}'`)}. Issue was: ${
            issue.message
          }`
        : issue.message,
    );
    throw new Error(
      [
        'Invalid configuration for Autologout at these paths: \n',
        ...errors,
        '\nPlease fix the configuration in your app-config files and restart the server.',
      ].join('\n'),
    );
  }

  return configRead.data;
};

export type AutoLogoutProviderProps = {
  children: JSX.Element[] | JSX.Element;
};

export const AutoLogoutProvider = ({
  children,
}: AutoLogoutProviderProps): JSX.Element => {
  const identityApi = useApi(identityApiRef);
  const configApi = useApi(configApiRef);
  const lastSeenOnlineStore: TimestampStore = useMemo(
    () => new DefaultTimestampStore(LAST_SEEN_ONLINE_STORAGE_KEY),
    [],
  );
  const [promptOpen, setPromptOpen] = useState<boolean>(false);

  const [remainingTimeCountdown, setRemainingTimeCountdown] =
    useState<number>(0);

  const {
    enabled,
    promptBeforeIdleSeconds,
    idleTimeoutMinutes,
    useWorkerTimers,
    events,
    logoutIfDisconnected,
  } = parseConfig(configApi);

  useLogoutDisconnectedUserEffect({
    enableEffect: logoutIfDisconnected,
    autologoutIsEnabled: enabled,
    idleTimeoutSeconds: idleTimeoutMinutes * 60,
    lastSeenOnlineStore,
    identityApi,
  });

  if (!enabled) {
    return <>{children}</>;
  }

  const promptBeforeIdleMillis = promptBeforeIdleSeconds * 1000;
  const promptBeforeIdle = promptBeforeIdleMillis > 0 ? true : false;

  const onPrompt = () => {
    // onPrompt will be called `promptBeforeIdle` milliseconds before `timeout`.
    // All events are disabled while the prompt is active.
    // If the user wishes to stay active, call the `activate()` method.
    // You can get the remaining prompt time with the `getRemainingTime()` method,
    setPromptOpen(true);
    setRemainingTimeCountdown(promptBeforeIdleMillis);
  };

  const onIdle = () => {
    // onIdle will be called after the timeout is reached.
    // Events will be rebound as long as `stopOnMount` is not set.
    setPromptOpen(false);
    setRemainingTimeCountdown(0);
    identityApi.signOut();
  };

  const onActive = () => {
    // onActive will only be called if `activate()` is called while `isPrompted()`
    // is true. Here you will also want to close your modal and perform
    // any active actions.
    setPromptOpen(false);
    setRemainingTimeCountdown(0);
  };

  const onAction = (
    _event?: Event | undefined,
    _idleTimer?: IIdleTimer | null,
  ) => {
    // onAction will be called if any user event is detected. The list of events that triggers a user event detection is the list of configured events
    // If any user event is detected we update the Last seen online in storage
    lastSeenOnlineStore.save(new Date());
  };

  return (
    <IdleTimerProvider
      timeout={minutesToMillis(idleTimeoutMinutes)}
      events={events.map(toEventsType)}
      crossTab
      name="autologout-timer"
      timers={useWorkerTimers ? workerTimers : undefined}
      onIdle={onIdle}
      onActive={promptBeforeIdle ? onActive : undefined}
      onAction={logoutIfDisconnected ? onAction : undefined}
      onPrompt={promptBeforeIdle ? onPrompt : undefined}
      promptBeforeIdle={promptBeforeIdle ? promptBeforeIdleMillis : undefined}
      syncTimers={1000}
    >
      {promptBeforeIdle && (
        <StillTherePrompt
          open={promptOpen}
          setOpen={setPromptOpen}
          remainingTime={remainingTimeCountdown}
          setRemainingTime={setRemainingTimeCountdown}
          promptTimeoutMillis={promptBeforeIdleMillis}
        />
      )}
      {children}
    </IdleTimerProvider>
  );
};
