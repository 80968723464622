import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400-italic.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/700.css';
import './styles/styles.css';

import { AutoLogoutProvider, SignInPage } from '@agilelab/plugin-wb-auth';
import {
  CatalogEntityPage,
  CatalogGraphRenderNode,
  CatalogIndexPage,
  catalogPlugin,
  MyProjectsPage,
  ReleaseDetailPage,
} from '@agilelab/plugin-wb-builder-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@agilelab/plugin-wb-catalog-import';
import { eventsApiRef } from '@agilelab/plugin-wb-events';
import { userSignedInTopic } from '@agilelab/plugin-wb-events-common';
import { PackageListPage } from '@agilelab/plugin-wb-external-resources';
import { GovernancePage } from '@agilelab/plugin-wb-governance';
import { MarketplacePage } from '@agilelab/plugin-wb-marketplace';
import {
  NotificationPage,
  NotificationProvider,
} from '@agilelab/plugin-wb-notification';
import {
  CustomAlertDisplay,
  EnabledFeaturesProvider,
  EnvironmentsContextProvider,
  LightAgileTheme,
  MeshApolloProvider,
  useCustomTheme,
  WbHomeRedirect,
  WbRequirePermission,
} from '@agilelab/plugin-wb-platform';
import { PlatformSettingsPage } from '@agilelab/plugin-wb-platform-settings';
import { WbPrototypePage } from '@agilelab/plugin-wb-prototype';
import {
  ArrayTableTemplateLayout,
  HorizontalTemplateLayout,
  ScaffolderLayouts,
  ScaffolderPage,
  scaffolderPlugin,
  TableRowTemplateLayout,
} from '@agilelab/plugin-wb-scaffolder';
import {
  DocumentsViewerTab,
  UserSettingsPage,
  UserSettingsTab,
} from '@agilelab/plugin-wb-user-settings';
import { createApp } from '@backstage/app-defaults';
import {
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_OWNED_BY,
  RELATION_OWNER_OF,
  RELATION_PART_OF,
} from '@backstage/catalog-model';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { OAuthRequestDialog } from '@backstage/core-components';
import { IdentityApi, useApi } from '@backstage/core-plugin-api';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import {
  CatalogGraphPage,
  catalogGraphPlugin,
} from '@backstage/plugin-catalog-graph';
import { orgPlugin } from '@backstage/plugin-org';
import { RequirePermission } from '@backstage/plugin-permission-react';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { DocumentsPopup } from './components/documents/DocumentsPopup';
import { Root } from './components/Root';
import { UserConfigSettings } from './components/settings/UserConfigSettings';
import { UserHeadersSettings } from './components/settings/UserHeadersSettings/UserHeadersSettings';
import * as plugins from './plugins';

const queryClient = new QueryClient();

const SignInComponent: any = (props: any) => {
  const eventsApi = useApi(eventsApiRef);
  return (
    <SignInPage
      {...props}
      auto
      title="Select a sign-in method"
      align="center"
      onSignInSuccess={async (identityApi: IdentityApi) => {
        const identity = await identityApi.getBackstageIdentity();
        eventsApi.publish(
          userSignedInTopic.createEvent(
            {},
            {
              generatorSystem: 'settings',
              originatorUri: identity.userEntityRef,
            },
          ),
          await identityApi.getCredentials(),
        );
        props.onSignInSuccess(identityApi);
      }}
    />
  );
};

function routes() {
  return (
    <FlatRoutes>
      <Route path="/" element={<WbHomeRedirect />} />
      <Route
        path="/catalog"
        element={
          <WbRequirePermission
            when={ctx => ctx.catalogEnabled && ctx.softwareCatalogEnabled}
          >
            <CatalogIndexPage hiddenKinds={['release', 'location']} />{' '}
          </WbRequirePermission>
        }
      />
      <Route
        path="/my-projects/catalog"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <MyProjectsPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/my-projects/catalog/:namespace/:kind/:name"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogEntityPage />
          </WbRequirePermission>
        }
      >
        {entityPage}
      </Route>
      <Route
        path="/catalog/release/:kind/:namespace/:name"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <ReleaseDetailPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog/:namespace/:kind/:name"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogEntityPage />
          </WbRequirePermission>
        }
      >
        {entityPage}
      </Route>
      <Route
        path="/catalog-graph"
        element={
          <WbRequirePermission when={ctx => ctx.catalogEnabled}>
            <CatalogGraphPage
              renderNode={CatalogGraphRenderNode}
              initialState={{
                selectedKinds: ['component', 'domain', 'system', 'group'],
                selectedRelations: [
                  RELATION_OWNER_OF,
                  RELATION_OWNED_BY,
                  RELATION_HAS_PART,
                  RELATION_PART_OF,
                  RELATION_DEPENDS_ON,
                  RELATION_DEPENDENCY_OF,
                ],
              }}
            />
          </WbRequirePermission>
        }
      />
      <Route path="/docs" element={<TechDocsIndexPage />}>
        <DefaultTechDocsHome />
      </Route>
      <Route
        path="/docs/:namespace/:kind/:name/*"
        element={<TechDocsReaderPage />}
      />
      <Route
        path="/create"
        element={
          <WbRequirePermission when={ctx => ctx.templatesEnabled}>
            <ScaffolderPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/catalog-import"
        element={
          <RequirePermission permission={catalogEntityCreatePermission}>
            <CatalogImportPage />
          </RequirePermission>
        }
      />
      <Route path="/settings" element={<UserSettingsPage />}>
        <UserSettingsTab path="/advanced" title="Advanced">
          <UserConfigSettings />
          <UserHeadersSettings />
        </UserSettingsTab>
        <UserSettingsTab path="documents-table" title="Documents">
          <DocumentsViewerTab />
        </UserSettingsTab>
      </Route>
      <Route
        path="/marketplace"
        element={
          <WbRequirePermission when={ctx => ctx.marketplaceEnabled}>
            <MarketplacePage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/wb-scaffolder"
        element={
          <WbRequirePermission when={ctx => ctx.templatesEnabled}>
            <ScaffolderPage />
          </WbRequirePermission>
        }
      >
        <ScaffolderLayouts>
          <TableRowTemplateLayout />
          <ArrayTableTemplateLayout />
          <HorizontalTemplateLayout />
        </ScaffolderLayouts>
      </Route>
      <Route
        path="/governance"
        element={
          <WbRequirePermission when={ctx => ctx.governanceEnabled}>
            <GovernancePage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/external-resources"
        element={
          <WbRequirePermission when={ctx => ctx.externalResourcesEnabled}>
            <PackageListPage />
          </WbRequirePermission>
        }
      />
      <Route path="/notifications" element={<NotificationPage />} />
      <Route
        path="/platform-settings"
        element={
          <WbRequirePermission when={ctx => ctx.platformSettingsEnabled}>
            <PlatformSettingsPage />
          </WbRequirePermission>
        }
      />
      <Route
        path="/prototypes"
        element={
          <WbRequirePermission when={ctx => ctx.prototypePageEnabled}>
            <WbPrototypePage />
          </WbRequirePermission>
        }
      />
    </FlatRoutes>
  );
}

const WitboostApp = () => {
  const { error, loading, theme } = useCustomTheme();
  const themes = useMemo(() => {
    if (theme && !loading && !error) return [theme];
    return [LightAgileTheme];
  }, [error, loading, theme]);

  if (loading) return <></>;

  const app = createApp({
    apis,
    plugins: Object.values(plugins),
    themes: themes,
    components: {
      SignInPage: SignInComponent,
    },
    bindRoutes({ bind }) {
      bind(catalogPlugin.externalRoutes, {
        registerComponent: catalogImportPlugin.routes.importPage,
        createComponent: scaffolderPlugin.routes.root,
        viewTechDoc: techdocsPlugin.routes.docRoot,
      });
      bind(catalogGraphPlugin.externalRoutes, {
        catalogEntity: catalogPlugin.routes.catalogEntity,
      });
      bind(scaffolderPlugin.externalRoutes, {
        registerComponent: catalogImportPlugin.routes.importPage,
      });
      bind(catalogImportPlugin.externalRoutes, {
        templates: scaffolderPlugin.routes.root,
      });
      bind(orgPlugin.externalRoutes, {
        catalogIndex: catalogPlugin.routes.catalogIndex,
      });
    },
  });

  const AppRoot = app.createRoot(
    <>
      <DocumentsPopup />
      <OAuthRequestDialog />
      <AppRouter>
        <CustomAlertDisplay />
        <MeshApolloProvider>
          <QueryClientProvider client={queryClient}>
            <NotificationProvider>
              <AutoLogoutProvider>
                <EnvironmentsContextProvider>
                  <EnabledFeaturesProvider>
                    <Root>{routes()}</Root>
                  </EnabledFeaturesProvider>
                </EnvironmentsContextProvider>
              </AutoLogoutProvider>
            </NotificationProvider>
          </QueryClientProvider>
        </MeshApolloProvider>
      </AppRouter>
    </>,
  );

  return <AppRoot />;
};

export default WitboostApp;
