export type GetRowChildren<T> = (row: T) => T[];
export type DepthMap = Map<string, number>;

export const explodeTreeRows = <T>(
  rows: T[],
  getRowId: (row: T) => string,
  getRowChildren: GetRowChildren<T>,
  expandedRowsIds: string[],
) => {
  let isAnyRowNested = false;
  const depthMap: DepthMap = new Map();
  const expandedSet = new Set(expandedRowsIds);
  const newRows: T[] = [];

  const dfs = (row: T, depth = 0) => {
    const id = getRowId(row);
    newRows.push(row);
    depthMap.set(id, depth);
    const children = getRowChildren(row);
    if (children.length > 0) isAnyRowNested = true;
    if (expandedSet.has(id)) {
      getRowChildren(row).forEach(r => dfs(r, depth + 1));
    }
  };

  rows.forEach(r => dfs(r));

  return { rows: newRows, isAnyRowNested, depthMap };
};
