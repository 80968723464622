import React, { useEffect, useState } from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { WbCard } from '../../WbCard';
import CloseIcon from '@material-ui/icons/Close';
import { useGeneralPurposePickerContext } from '../GeneralPurposePickerContext';
import { GenericEntityType, Provider, TemplateEntity } from '../types';
import { SingleSelection } from './SingleSelection';
import { MultiSelection } from './MultiSelection/MultiSelection';
import { CustomSnackbarError } from '../../CustomSnackbarError';
import { selectTemplateEntity } from './utils';
import { ProviderFilters } from './CommonFilter';

export interface GeneralEntityPickerDialogProps {
  title: string;
  multiSelection?: boolean;
  availableKinds: TemplateEntity[];
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  parentTemplateRef?: string;
}

export const GeneralEntityPickerDialog = (
  props: GeneralEntityPickerDialogProps,
) => {
  const {
    openDialog,
    setOpenDialog,
    errorMessage,
    setErrorMessage,
    selectedKind,
  } = useGeneralPurposePickerContext();

  const [entityProvider, setEntityProvider] = useState<JSX.Element>();
  const [renderTable, setRenderTable] = useState<JSX.Element | undefined>();

  useEffect(() => {
    const provider = props.providerMapper
      .get(selectedKind)
      ?.renderContextProvider(
        selectTemplateEntity(props.availableKinds, selectedKind),
        renderTable,
        props.parentTemplateRef,
      );

    if (provider) {
      setEntityProvider(provider);
      setErrorMessage(undefined);
    } else {
      setEntityProvider(undefined);
      setErrorMessage({
        type: 'error',
        title: 'Error',
        content:
          'Error while loading entity provider. Please contact the platform team.',
      });
    }
  }, [
    props.availableKinds,
    props.parentTemplateRef,
    props.providerMapper,
    renderTable,
    selectedKind,
    setErrorMessage,
  ]);

  return (
    <Dialog
      open={openDialog}
      onClose={() => {
        setOpenDialog(false);
      }}
      maxWidth="xl"
      fullWidth
    >
      <WbCard
        title={props.title}
        cardStyle={{
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          height: '70vh',
        }}
        actions={
          <IconButton
            aria-label="clear"
            onClick={() => setOpenDialog(false)}
            size="small"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        {errorMessage && <CustomSnackbarError message={errorMessage} />}
        {props.multiSelection ? (
          <MultiSelection
            {...props}
            selectedEntityProvider={entityProvider}
            setRenderTable={setRenderTable}
          />
        ) : (
          <SingleSelection
            {...props}
            selectedEntityProvider={entityProvider}
            setRenderTable={setRenderTable}
          />
        )}
      </WbCard>
    </Dialog>
  );
};
