import {
  createStyles,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import type monaco from 'monaco-editor';
import React, { MutableRefObject } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    panels: {
      width: '100%',
      height: '30px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
    },
    arrowIcon: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    side: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontWeight: 'bold',
    },
  }),
);

interface PanelNamesProps {
  leftPanelTitle: string;
  rightPanelTitle: string;
  comparisonEditorRef?: MutableRefObject<monaco.editor.IStandaloneDiffEditor | null>;
}

export const PanelNames = ({
  rightPanelTitle,
  leftPanelTitle,
  comparisonEditorRef,
}: PanelNamesProps) => {
  const classes = useStyles();
  return (
    <div className={classes.panels}>
      <div className={classes.side}>
        <span className={classes.text}>{leftPanelTitle}</span>
      </div>
      {comparisonEditorRef && (
        <Tooltip title="Click to move all text from left to right panel">
          <IconButton
            disableRipple
            classes={{ root: classes.arrowIcon }}
            onClick={() => {
              comparisonEditorRef?.current
                ?.getModifiedEditor()
                .setValue(
                  comparisonEditorRef?.current?.getOriginalEditor().getValue(),
                );
            }}
          >
            <ArrowForwardIcon />
          </IconButton>
        </Tooltip>
      )}
      <div className={classes.side}>
        <span className={classes.text}>{rightPanelTitle}</span>
      </div>
    </div>
  );
};
