import React from 'react';
import {
  Box,
  CardHeader,
  CardHeaderProps,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {},
    wrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    text: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.primary.main,
      '& svg': {
        color: theme.palette.primary.main,
      },
    },
  }),
);

export function MeshCardHeader(
  props: {
    title: string;
    children?: JSX.Element[] | JSX.Element;
  } & CardHeaderProps,
) {
  const { title, children, ...cardHeaderProps } = props;
  const classes = useStyles();

  const titleContent = (
    <Box className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <Typography variant="body1" style={{ margin: 0, fontWeight: 'bold' }}>
          {title}
        </Typography>
      </div>
      <Box>{children}</Box>
    </Box>
  );

  return (
    <CardHeader
      title={titleContent}
      className={classes.cardHeader}
      {...cardHeaderProps}
    />
  );
}
