import { WbCard, WbCardContent } from '@agilelab/plugin-wb-platform';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Switch,
  Tooltip,
} from '@material-ui/core';
import React, { useState } from 'react';
import { ComponentsGrid } from './ComponentsGrid';

const useStyles = makeStyles(theme => ({
  list: {
    [theme.breakpoints.down('xs')]: {
      padding: `0 0 12px`,
    },
  },
  listItemText: {
    [theme.breakpoints.down('xs')]: {
      paddingRight: 0,
      paddingLeft: 0,
    },
  },
  listItemSecondaryAction: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      top: 'auto',
      right: 'auto',
      position: 'relative',
      transform: 'unset',
    },
  },
}));

/**
 * Props for {@link EntityOwnershipCard}.
 *
 * @public
 */
export interface OwnershipCardProps {
  entityFilterKind?: string[];
  hideRelationsToggle?: boolean;
  relationsType?: string;
}

export function OwnershipCard(props: OwnershipCardProps) {
  const { entityFilterKind, hideRelationsToggle, relationsType } = props;
  const relationsToggle =
    hideRelationsToggle === undefined ? false : hideRelationsToggle;
  const classes = useStyles();
  const { entity } = useEntity();
  const isGroup = entity.kind === 'Group';
  const [getRelationsType, setRelationsType] = useState(
    relationsType || 'direct',
  );

  return (
    <WbCard title="Ownership" cardStyle={{ height: '100%' }}>
      {!relationsToggle && (
        <List dense>
          <ListItem className={classes.list}>
            <ListItemText className={classes.listItemText} />
            <ListItemSecondaryAction
              className={classes.listItemSecondaryAction}
            >
              Direct Relations
              <Tooltip
                placement="top"
                arrow
                title={`${
                  getRelationsType === 'direct' ? 'Direct' : 'Aggregated'
                } Relations`}
              >
                <Switch
                  color="primary"
                  checked={getRelationsType !== 'direct'}
                  onChange={() =>
                    getRelationsType === 'direct'
                      ? setRelationsType('aggregated')
                      : setRelationsType('direct')
                  }
                  name="pin"
                  inputProps={{ 'aria-label': 'Ownership Type Switch' }}
                  disabled={!isGroup}
                />
              </Tooltip>
              Aggregated Relations
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      )}
      <WbCardContent>
        <ComponentsGrid
          entity={entity}
          relationsType={getRelationsType}
          isGroup={isGroup}
          entityFilterKind={entityFilterKind}
        />
      </WbCardContent>
    </WbCard>
  );
}
