import {
  TableContentLoader,
  WbSelect,
  WbTable,
} from '@agilelab/plugin-wb-platform';
import React, { useCallback, useMemo } from 'react';
import { DeploymentPreview, PreviewMessage } from '../types';
import {
  Box,
  makeStyles,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { snakeCaseToTitleCase } from '@agilelab/plugin-wb-platform-common';
import { ComponentDependencies } from '../ProvisioningCard/ComponentsTable/ComponentDependencies';
import { DeploymentPreviewChip } from './DeploymentPreviewChip';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Action,
  DeploymentUnitStatus,
  TargetStatus,
  WitboostSubcomponent,
} from '@agilelab/plugin-wb-builder-common';
import { buildChildrenMap, buildParentMap } from '../utils';
import LinkIcon from '@material-ui/icons/Link';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { SubComponentRow } from './SubComponentRow';

const updateParents = (
  newRow: DeploymentPreview,
  rows: DeploymentPreview[],
) => {
  let hasAutoUpdate = false;

  const parentMap = buildParentMap(rows);
  const parentIds = parentMap[newRow.id];
  for (const row of rows) {
    if (parentIds.includes(row.id) && row.newState !== TargetStatus.DEPLOYED) {
      row.newState = TargetStatus.DEPLOYED;
      row.action = Action.DEPLOY;
      row.autoUpdated = true;
      hasAutoUpdate = true;
    } else {
      row.autoUpdated = false;
    }
  }

  return { rows, hasAutoUpdate };
};

const updateChildren = (
  newRow: DeploymentPreview,
  rows: DeploymentPreview[],
) => {
  let hasAutoUpdate = false;

  const childrenMap = buildChildrenMap(rows);
  const childrenIds = childrenMap[newRow.id];
  for (const row of rows) {
    if (
      childrenIds.includes(row.id) &&
      row.newState !== TargetStatus.NOT_DEPLOYED
    ) {
      row.newState = TargetStatus.NOT_DEPLOYED;
      row.action = Action.UNDEPLOY;
      row.autoUpdated = true;
      hasAutoUpdate = true;
    } else {
      row.autoUpdated = false;
    }
  }
  return { rows, hasAutoUpdate };
};

const useStyles = makeStyles(theme => ({
  selectInput: {
    height: '36px',
    minHeight: 'auto',
    fontSize: theme.typography.body1.fontSize,
  },
  selectTextField: {
    minHeight: 0,
  },
  nameCell: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface Props {
  previews: Array<DeploymentPreview>;
  setPreviews: React.Dispatch<React.SetStateAction<DeploymentPreview[]>>;
  setMessage: React.Dispatch<React.SetStateAction<PreviewMessage | undefined>>;
  loading: boolean;
}

export const DeploymentPreviewAdvancedTable: React.FC<Props> = ({
  previews,
  setPreviews,
  setMessage,
  loading,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const headStyle = { style: { fontWeight: 500 } };
  const { queryParamEnvironment, environment, systemType } =
    useReleaseDetailPageContext();

  const enabledEnvs = systemType.spec.partiallyDeployableIn;

  const isPartialProvisioningEnabled = useMemo(
    () =>
      Boolean(enabledEnvs?.includes(queryParamEnvironment || environment.name)),
    [enabledEnvs, environment.name, queryParamEnvironment],
  );

  const getAllowedActions = useCallback(
    (currentState: DeploymentUnitStatus, newState: TargetStatus) => {
      const all = [
        Action.DEPLOY,
        Action.NO_ACTION,
        Action.UNDEPLOY,
        Action.UNDEPLOY_AND_REMOVE_DATA,
      ];
      switch (currentState) {
        case DeploymentUnitStatus.CORRUPT:
          return newState === TargetStatus.DEPLOYED
            ? [Action.DEPLOY]
            : [Action.UNDEPLOY, Action.UNDEPLOY_AND_REMOVE_DATA];
        case DeploymentUnitStatus.NOT_DEPLOYED:
          return newState === TargetStatus.DEPLOYED
            ? [Action.DEPLOY]
            : [Action.NO_ACTION, Action.UNDEPLOY];
        case DeploymentUnitStatus.DEPLOYED:
          return newState === TargetStatus.DEPLOYED
            ? [Action.DEPLOY, Action.NO_ACTION]
            : [Action.UNDEPLOY, Action.UNDEPLOY_AND_REMOVE_DATA];
        case DeploymentUnitStatus.PARTIALLY_DEPLOYED:
          return all;
        case DeploymentUnitStatus.PROVISIONING_IN_PROGRESS:
          return all;
        default:
          return all;
      }
    },
    [],
  );

  const checkRows = useCallback(
    (newRow: DeploymentPreview, rows: DeploymentPreview[]) => {
      let hasAutoUpdate = false;
      let newRows: DeploymentPreview[] = [];

      if (newRow.newState === TargetStatus.DEPLOYED) {
        const response = updateParents(newRow, rows);
        newRows = response.rows;
        hasAutoUpdate = response.hasAutoUpdate;
      }
      if (newRow.newState === TargetStatus.NOT_DEPLOYED) {
        const response = updateChildren(newRow, rows);
        newRows = response.rows;
        hasAutoUpdate = response.hasAutoUpdate;
      }

      const type: PreviewMessage['type'] = 'info';
      const title = 'Dependencies Highlighted';
      const content = (
        <Typography>
          Due to some dependencies, some previously unselected components will
          also be updated. Those components are highlighted in grey in the table
          below.
        </Typography>
      );
      const message = hasAutoUpdate ? { type, title, content } : undefined;
      setMessage(message);

      return newRows;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <WbTable
      stickyHeader
      components={{
        tableLoader: {
          loading: false,
        },
        tableHeader: (
          <TableHead>
            <TableRow>
              <TableCell {...headStyle}>Name</TableCell>
              <TableCell {...headStyle}>Type</TableCell>
              <TableCell {...headStyle}>Dependencies</TableCell>
              <TableCell {...headStyle}>Current State</TableCell>
              <TableCell {...headStyle}>New State</TableCell>
              <TableCell {...headStyle}>Action</TableCell>
            </TableRow>
          </TableHead>
        ),
        tableContent: {
          body: (
            <TableBody>
              {loading && <TableContentLoader rows={10} tableCells={6} />}

              {!loading &&
                previews.map((preview, i) => (
                  <>
                    <TableRow
                      key={i}
                      style={{
                        background: preview.autoUpdated
                          ? theme.palette.grey[100]
                          : 'inherit',
                      }}
                    >
                      <TableCell>
                        <Box className={classes.nameCell}>
                          {preview.autoUpdated && <LinkIcon color="primary" />}
                          <Typography
                            variant="body2"
                            style={{ fontWeight: 700 }}
                          >
                            {preview.name}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">
                          {snakeCaseToTitleCase(preview.kind)}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <ComponentDependencies preview={preview} />
                      </TableCell>
                      <TableCell>
                        <DeploymentPreviewChip
                          status={preview.status}
                          version={preview.statusVersion}
                        />
                      </TableCell>
                      <TableCell
                        style={{ maxWidth: 220, minWidth: 220, width: 220 }}
                      >
                        <Box
                          style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'center',
                            marginLeft: '-32px',
                          }}
                        >
                          <ArrowForwardIcon
                            htmlColor={theme.palette.accent.main}
                          />

                          {!isPartialProvisioningEnabled ? (
                            <DeploymentPreviewChip
                              status={
                                preview.newState! as unknown as DeploymentUnitStatus
                              }
                            />
                          ) : (
                            <WbSelect
                              value={preview?.newState}
                              onChange={e => {
                                const value = e.target.value;
                                const newRows = [...previews];
                                const row = { ...newRows[i] };
                                row.newState = value as TargetStatus;

                                const allowedActions = getAllowedActions(
                                  row.status,
                                  row.newState,
                                );

                                row.action = allowedActions.includes(
                                  preview.action!,
                                )
                                  ? preview.action
                                  : allowedActions[0];

                                newRows[i] = row;

                                setPreviews(checkRows(row, newRows));
                              }}
                              options={[
                                TargetStatus.DEPLOYED,
                                TargetStatus.NOT_DEPLOYED,
                              ]}
                              InputProps={{
                                className: classes.selectInput,
                              }}
                              getOptionLabel={value =>
                                snakeCaseToTitleCase(value)
                              }
                              className={classes.selectTextField}
                              SelectProps={{
                                renderValue: value => (
                                  <DeploymentPreviewChip
                                    status={value as DeploymentUnitStatus}
                                  />
                                ),
                              }}
                            />
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        style={{ maxWidth: 300, minWidth: 300, width: 300 }}
                      >
                        <WbSelect
                          value={preview.action}
                          className={classes.selectTextField}
                          disabled={
                            getAllowedActions(preview.status, preview.newState!)
                              .length <= 1
                          }
                          onChange={e => {
                            const value = e.target.value;
                            const newRows = [...previews];
                            const row = { ...newRows[i] };
                            row.action = value as Action;
                            newRows[i] = row;
                            setPreviews(newRows);
                          }}
                          options={getAllowedActions(
                            preview.status,
                            preview.newState!,
                          )}
                          getOptionLabel={value => snakeCaseToTitleCase(value)}
                          InputProps={{
                            className: classes.selectInput,
                          }}
                        />
                      </TableCell>
                    </TableRow>

                    {(preview.components || []).map(
                      (subComponent: WitboostSubcomponent) => (
                        <SubComponentRow
                          keyProp={subComponent.name}
                          subComponent={subComponent}
                          componentPreview={preview}
                          advanced
                        />
                      ),
                    )}
                  </>
                ))}
            </TableBody>
          ),
        },
      }}
      styles={{
        container: { height: '100%' },
      }}
    />
  );
};
