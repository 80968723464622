import React from 'react';
import {
  makeStyles,
  createStyles,
  Chip,
  Tooltip,
  Typography,
  Theme,
  useTheme,
} from '@material-ui/core';
import { DetailIcon } from '../DetailIcon';
import { GovernanceEntitiesQueryType } from '../../../../graphql/governanceEntities';
import { Task } from '../../types';
import { WbTruncatedTypographyWrapper } from '@agilelab/plugin-wb-platform';

interface Props {
  policy: GovernanceEntitiesQueryType['cgp_governance_entity'][0];
  task: Task;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailName: {
      width: '150px',
      minWidth: '150px',
    },
    policyDesc: {
      flex: 1,
    },
    version: {
      padding: theme.spacing(0, 2),
    },
    chip: {
      margin: 0,
    },
  }),
);

export const PolicyComponentItem: React.FC<Props> = ({ policy, task }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <DetailIcon
        status={task.status}
        filled
        severity={policy.content.severity}
        policyStatus={task.governanceEntityStatus}
      />
      <div className={classes.detailName}>
        <WbTruncatedTypographyWrapper
          style={{
            marginRight: theme.spacing(1),
          }}
          value={policy.name ?? ''}
        />
      </div>
      <div className={classes.version}>
        <Tooltip title={`version: ${policy.version}`}>
          <Typography>{`v:${policy.version}`}</Typography>
        </Tooltip>
      </div>
      <div className={classes.policyDesc}>
        <WbTruncatedTypographyWrapper
          style={{
            marginRight: theme.spacing(1),
          }}
          value={policy.description ?? ''}
        />
      </div>
      <Chip label="Policy" className={classes.chip} color="primary" />
    </>
  );
};
