import { CustomView, useDialog } from '@agilelab/plugin-wb-platform';
import React from 'react';
import { SemanticLinkingTable } from '../../ComponentPage/OverviewPage/SemanticLinkingTable';
import { ComponentDetailsData } from '../utils';

export const DataContractDrawer = (props: {
  data: ComponentDetailsData;
  makeSpaceForDownloader?: boolean;
  actions?: Record<string, any>;
}) => {
  const data = props.data;
  const semanticLinkingDialog = useDialog({ title: 'Semantic Linking' });

  return (
    <CustomView
      id="marketplace_data_contract_drawer"
      typeId={data.componentInstance.descriptor.kind}
      templateId={data.componentInstance.descriptor.useCaseTemplateId}
      actions={Object.assign({}, props.actions || {}, {
        showSemanticLink: async (field: any) => {
          await semanticLinkingDialog(
            <SemanticLinkingTable descriptor={data as any} schema={field} />,
          );
        },
      })}
      data={data}
    />
  );
};
