import {
  CustomViewDownloader,
  WbBreadcrumb,
  WbBreadcrumbs,
  WbHeader,
  usePermissions,
} from '@agilelab/plugin-wb-platform';
import { practiceShaperRouteRef } from '@agilelab/plugin-wb-platform-settings';
import {
  practiceShaperEditPermission,
  practiceShaperImportPermission,
} from '@agilelab/plugin-wb-rbac-common';
import { Page, RoutedTabs } from '@backstage/core-components';
import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
import React, { useMemo } from 'react';
import { PracticeShaperImport } from '../PracticeShaperImport';
import { MigrationCard } from './components/MigrationCard/MigrationCard';
import { PracticeShaperOverview } from './components/PracticeShaperOverview/PracticeShaperOverview';
import { PracticeShaperPreset } from './components/PracticeShaperPreset/PracticeShaperPreset';
import { MigrationContextProvider } from './context/MigrationContext';
import { PracticeShaperGraphProvider } from './context/PracticeShaperGraphContext';

export const PracticeShaperSettingsPage = ({
  importPageRef,
  graphPageRef,
}: {
  importPageRef: string;
  graphPageRef: string;
}) => {
  const configApi = useApi(configApiRef);
  const migrationEnabled = configApi.getOptionalBoolean(
    'practiceShaper.migration.migrationSectionEnabled',
  );
  const practiceShaperPageRef = useRouteRef(practiceShaperRouteRef);

  const { allAllowed } = usePermissions([
    {
      permission: practiceShaperEditPermission,
    },
    {
      permission: practiceShaperImportPermission,
    },
  ]);

  const routes = useMemo(() => {
    const migrationSection = {
      path: '/migration',
      title: 'Migration',
      children: (
        <MigrationContextProvider>
          <MigrationCard importPageRef={importPageRef} />
        </MigrationContextProvider>
      ),
    };

    const presetSection = {
      path: 'preset',
      title: 'Presets',
      children: (
        <PracticeShaperPreset
          importPageRef={importPageRef}
          graphPageRef={graphPageRef}
        />
      ),
    };

    const graphSection = {
      path: '/graph',
      title: 'Graph',
      children: (
        <PracticeShaperGraphProvider>
          <PracticeShaperOverview importPageRef={importPageRef} />
        </PracticeShaperGraphProvider>
      ),
    };

    const registrationSection = {
      path: '/import',
      title: 'Entity registration',
      children: (
        <PracticeShaperImport practiceShaperPageRef={practiceShaperPageRef()} />
      ),
    };

    const enabledRoutes = [presetSection];
    if (migrationEnabled) enabledRoutes.push(migrationSection);
    if (allAllowed) enabledRoutes.push(registrationSection);
    enabledRoutes.push(graphSection);

    return enabledRoutes;
  }, [
    allAllowed,
    graphPageRef,
    importPageRef,
    migrationEnabled,
    practiceShaperPageRef,
  ]);

  return (
    <Page themeId="home">
      <WbHeader
        title="Practice Shaper"
        titleContainerStyle={{ flex: 'none' }}
        breadcrumbs={
          <WbBreadcrumbs aria-label="breadcrumb">
            <WbBreadcrumb>Administration</WbBreadcrumb>
            <WbBreadcrumb>Practice Shaper</WbBreadcrumb>
          </WbBreadcrumbs>
        }
      >
        <CustomViewDownloader
          withMenu
          customViewIds={['practice_shaper_content']}
        />
      </WbHeader>
      <RoutedTabs routes={routes} />
    </Page>
  );
};
