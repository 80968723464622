import { makeStyles, Theme } from '@material-ui/core';

export const useDependenciesStyle = makeStyles(
  (theme: Theme) => ({
    graph: {
      '& path[data-testid="edge"]': {
        transform: 'translate(0, -10px)',
      },
    },
    dataproduct: {
      '& rect': {
        fill: theme.palette.white,
        stroke: theme.palette.relationsGraph.system,
        strokeWidth: '3px',
      },
      '& text': {
        fill: theme.palette.relationsGraph.system,
      },
      '& svg': {
        fill: theme.palette.relationsGraph.system,
      },
    },
    main: {
      '& rect': {
        fill: theme.palette.relationsGraph.system,
        stroke: theme.palette.relationsGraph.system,
      },
      '& text': {
        fill: theme.palette.white,
      },
      '& svg': {
        fill: theme.palette.white,
      },
    },
    external: {
      '& rect': {
        fill: theme.palette.white,
        stroke: theme.palette.relationsGraph.resource,
        strokeWidth: '3px',
      },
      '& text': {
        fill: theme.palette.relationsGraph.resource,
      },
      '& svg': {
        fill: theme.palette.relationsGraph.resource,
      },
    },
    componentNode: {
      '& rect': {
        fill: theme.palette.relationsGraph.system,
        stroke: theme.palette.relationsGraph.system,
      },
      '& text': {
        fill: theme.palette.white,
      },
      '& svg': {
        fill: theme.palette.white,
      },
    },
  }),
  { name: 'PluginCatalogSystemDiagramCard' },
);
