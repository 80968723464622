import React, { useEffect, useMemo, useState } from 'react';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { useDeploymentPreviews } from '../hooks/useDeploymentPreviews';
import {
  Box,
  Button,
  DialogActions,
  Typography,
  useTheme,
} from '@material-ui/core';
import { CleanUpAccordion } from './CleanUpAccordion';
import { NotInTargetDescriptorAccordion } from './NotInTargetDescriptorAccordion';
import { DeploymentPreviewAdvancedTable } from './DeploymentPreviewAdvancedTable';
import {
  CustomSnackbarError,
  WbCardActionButton,
} from '@agilelab/plugin-wb-platform';
import { DeploymentPreview, PreviewMessage } from '../types';
import { Outcome } from '@agilelab/plugin-wb-builder-common';
import { useDeploymentPreviewContext } from './context/useDeploymentPreviewContext';
import { DeploymentPreviewTitle } from './DeploymentPreviewTitle';
import { createPreviews } from './utils';

export const AdvancedProvisioning: React.FC = () => {
  const { data, loadData, loading, error } = useDeploymentPreviews();
  const { fetchDeploys, setIsDeploymentPreviewOpen } =
    useReleaseDetailPageContext();

  const { setIsAdvanced, partialProvision, checkPreview } =
    useDeploymentPreviewContext();

  const theme = useTheme();
  const [previews, setPreviews] = useState<DeploymentPreview[]>([]);
  const [cleanUpPreviews, setCleanUpPreviews] = useState<DeploymentPreview[]>(
    [],
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<PreviewMessage | undefined>();

  const hasCleanUpOperations = useMemo(() => {
    return Boolean(
      data?.notInTargetDescriptorOperations.length ||
        data?.cleanUpOperations.length,
    );
  }, [data]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (!loading) {
      const loadedData = [
        ...(data?.rootComponent ? [data.rootComponent] : []),
        ...(data?.previews || []),
      ];
      setPreviews(loadedData);
    }
  }, [loading, data]);

  useEffect(() => {
    if (error)
      setMessage({
        type: 'error',
        title: 'Error',
        content: <Typography>{error.message}</Typography>,
      });
    else setMessage(undefined);
  }, [error]);

  return (
    <Box display="flex" flexDirection="column" flex="1" overflow="hidden">
      <DeploymentPreviewTitle />

      <Box display="flex" flexDirection="column" flex="1" overflow="auto">
        {message && <CustomSnackbarError message={message} />}

        {!error && (
          <Box style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <DeploymentPreviewAdvancedTable
              previews={previews}
              setPreviews={setPreviews}
              setMessage={setMessage}
              loading={loading}
            />

            {hasCleanUpOperations && (
              <Box padding="16px">
                <Typography style={{ marginBottom: '16px', fontWeight: 500 }}>
                  Clean-up operations
                </Typography>

                <NotInTargetDescriptorAccordion
                  ids={data?.notInTargetDescriptorOperations || []}
                  setCleanUpPreviews={setCleanUpPreviews}
                />
                <CleanUpAccordion
                  ids={data?.cleanUpOperations || []}
                  setCleanUpPreviews={setCleanUpPreviews}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>

      <DialogActions
        style={{
          height: '56px',
          position: 'sticky',
          bottom: 0,
          borderTop: `1px solid ${theme.palette.grey[300]}`,
          background: theme.palette.white,
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            setIsAdvanced(false);
            setIsDeploymentPreviewOpen(false);
          }}
        >
          Cancel
        </Button>
        <WbCardActionButton
          disabled={Boolean(isLoading || error)}
          loading={isLoading}
          onClick={async () => {
            const newPreviews = createPreviews(previews, cleanUpPreviews);

            setIsLoading(true);
            try {
              const previewResponse = await checkPreview(newPreviews);

              if (previewResponse.outcome !== Outcome.ABORTED_FOR_CONFLICTS) {
                await partialProvision(newPreviews);

                setIsDeploymentPreviewOpen(false);
                setIsAdvanced(false);
                fetchDeploys();
              } else {
                setMessage({
                  type: 'error',
                  title: 'Error',
                  content: <Typography>Aborted for conflicts</Typography>,
                });
              }
            } catch (e) {
              const errorList: string[] = [
                ...(e.moreInfo?.problems ?? []),
                ...(e.moreInfo?.solutions ?? []),
              ];
              setMessage({
                type: 'error',
                title: e.message,
                content: (
                  <>
                    {errorList.map((msg, i) => {
                      return <Typography key={i}>{msg}</Typography>;
                    })}
                  </>
                ),
              });
            } finally {
              setIsLoading(false);
            }
          }}
          variant="contained"
          size="small"
          color="primary"
          label="Confirm"
        />
      </DialogActions>
    </Box>
  );
};
