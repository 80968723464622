import {
  Result,
  ResultSet,
  SearchDocument,
} from '@backstage/plugin-search-common';
import { z } from 'zod';

/**
 * Only technical rows from DB
 */
const witboostMarketplaceComputedInfoZod = z.object({
  kind: z.string(),
  taxonomy: z.object({
    name: z.string(),
    external_id: z.string(),
  }),
  publishedAt: z.string().optional(),
  consumable: z.string().optional(),
  environment: z.string(),
  domain: z.object({
    name: z.string(),
    external_id: z.string(),
  }),
  owner: z.object({
    ref: z.string(),
    displayName: z.string(),
  }),
  parent: z
    .object({
      displayName: z.string(),
    })
    .optional(),
});

export type WitboostMarketplaceComputedInfo = z.infer<
  typeof witboostMarketplaceComputedInfoZod
>;

const witboostMarketplaceSearchResultDataZod = z.intersection(
  z.object({
    _computedInfo: witboostMarketplaceComputedInfoZod,
    id: z.string(),
    documentId: z.string(),
  }),
  z.record(z.any()),
);

export type WitboostMarketplaceSearchResultData = z.infer<
  typeof witboostMarketplaceSearchResultDataZod
>;

export type WitboostMarketplaceSearchDocument =
  WitboostMarketplaceSearchResultData & SearchDocument;

export type WitboostMarketplaceSearchResult =
  Result<WitboostMarketplaceSearchDocument>;

export type WitboostMarketplaceSearchResultSet =
  ResultSet<WitboostMarketplaceSearchDocument>;
