import { PolicyGql } from '@agilelab/plugin-wb-governance-common';
import { gql } from '@apollo/client/core';

export const GET_LAST_FAILED_EVALUATION_POLICIES = gql`
  query GET_LAST_FAILED_EVALUATION_POLICIES(
    $dataProductUrn: String!
    $environment: String!
  ) {
    cgp_evaluation_result(
      distinct_on: [governance_entity_id]
      order_by: [
        { governance_entity_id: desc }
        { evaluation_report: { creation_time: desc } }
      ]
      where: {
        evaluation_report: {
          scope: { _eq: "Evaluation" }
          environmentByEnvironment: { name: { _eq: $environment } }
        }
        governance_entity: {
          result_type: { _eq: "policy" }
          status: { _in: ["grace", "enabled", "deprecated"] }
        }
        resource_name: { _eq: $dataProductUrn }
        outcome: { _nin: ["pending"] }
      }
    ) {
      id
      outcome
      governance_entity {
        governance_entity_id
        name
        content
        description
        version
      }
      evaluation_report {
        creation_time
      }
    }
  }
`;

export const GET_LAST_FAILED_EVALUATION_POLICIES_COUNT = gql`
  query GetLastFailedEvaluationPoliciesCountByDataProductInstanceGroupBySeverity(
    $dataProductUrn: String!
    $environment: String!
  ) {
    cgp_evaluation_result(
      distinct_on: [governance_entity_id]
      order_by: [
        { governance_entity_id: desc }
        { evaluation_report: { creation_time: desc } }
      ]
      where: {
        evaluation_report: {
          scope: { _eq: "Evaluation" }
          environmentByEnvironment: { name: { _eq: $environment } }
        }
        governance_entity: {
          result_type: { _eq: "policy" }
          status: { _in: ["grace", "enabled", "deprecated"] }
        }
        resource_name: { _eq: $dataProductUrn }
        outcome: { _nin: ["pending"] }
      }
    ) {
      id
      outcome
    }
  }
`;

export interface IGetLastFailedEvaluationPolicies {
  cgp_evaluation_result: {
    id: number;
    outcome: string;
    governance_entity: Pick<
      PolicyGql,
      'governance_entity_id' | 'content' | 'name' | 'version' | 'description'
    >;
    evaluation_report: {
      creation_time: string;
    };
  }[];
}

export interface IGetLastFailedEvaluationPoliciesCount {
  cgp_evaluation_result: {
    id: number;
    outcome: string;
  }[];
}
