import { useApi } from '@backstage/core-plugin-api';
import { panelCatalogApiRef } from '../../../api';
import { useReleaseDetailPageContext } from '../context/useReleaseDetailPageContext';
import { Target } from '@agilelab/plugin-wb-builder-backend';
import {
  Action,
  DeploymentUnitStatus,
  generateURNByKind,
  ProvisioningPreviewResponseOperation,
  Reason,
  TargetStatus,
} from '@agilelab/plugin-wb-builder-common';
import { DeploymentPreview, DeploymentPreviewData } from '../types';
import yaml from 'yaml';
import { useCallback, useState } from 'react';

const getMatchedComponents = (
  components: Record<string, any>[],
  operations: ProvisioningPreviewResponseOperation[],
): DeploymentPreview[] =>
  components
    .filter(c => operations.find(op => op.componentId === c.id))
    .map(c => {
      const operation = operations.find(op => op.componentId === c.id);

      return {
        ...c,
        newState: operation?.targetStatus,
        action: operation?.action,
      } as DeploymentPreview;
    });

const getNotInTargetDescriptorOperations = (
  operations: ProvisioningPreviewResponseOperation[],
) =>
  operations.filter(
    op =>
      op.reason === Reason.NOT_IN_TARGET_DESCRIPTOR ||
      op.additionalReasons?.includes(Reason.NOT_IN_TARGET_DESCRIPTOR),
  );

const getCleanUpOperations = (
  operations: ProvisioningPreviewResponseOperation[],
) =>
  operations.filter(
    op =>
      op.reason === Reason.CLEANUP ||
      op.additionalReasons?.includes(Reason.CLEANUP),
  );

export function useDeploymentPreviews() {
  const {
    releaseDetailAction,
    components,
    release,
    currentDescriptor,
    entity,
    queryParamEnvironment,
    environment,
    deploymentUnitStatusState,
    projectName,
  } = useReleaseDetailPageContext();

  const panelCatalogApi = useApi(panelCatalogApiRef);

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<DeploymentPreviewData | undefined>();
  const [error, setError] = useState<Error>();

  const loadData = useCallback(
    async (targetStatus?: Target[]) => {
      if (releaseDetailAction && deploymentUnitStatusState.value) {
        setError(undefined);
        setLoading(true);
        const rootComponentId = generateURNByKind(
          entity.metadata.name,
          entity.kind ?? 'dp',
        );

        try {
          const entityTarget: Target = {
            action: releaseDetailAction,
            targetStatus:
              releaseDetailAction === Action.DEPLOY
                ? 'DEPLOYED'
                : 'NOT_DEPLOYED',
            componentId: rootComponentId,
          };

          const componentTargets: Target[] = components.map(c => ({
            action:
              releaseDetailAction === Action.DEPLOY
                ? Action.DEPLOY
                : Action.UNDEPLOY,
            targetStatus:
              releaseDetailAction === Action.DEPLOY
                ? TargetStatus.DEPLOYED
                : TargetStatus.NOT_DEPLOYED,
            componentId: c.id,
          }));

          const subcomponentsTargets: Target[] = components.flatMap(c =>
            (c.components ?? []).map(({ id: componentId }) => ({
              action:
                releaseDetailAction === Action.DEPLOY
                  ? Action.DEPLOY
                  : Action.UNDEPLOY,
              targetStatus:
                releaseDetailAction === Action.DEPLOY
                  ? TargetStatus.DEPLOYED
                  : TargetStatus.NOT_DEPLOYED,
              componentId,
            })),
          );

          const deploymentUnits =
            await panelCatalogApi.getDeploymentUnitsProvisioningPreview(
              generateURNByKind(
                release.metadata.projectName ??
                  release.metadata.dataProductName,
                release.metadata.projectKind ?? 'system',
              ),
              queryParamEnvironment ?? environment.name,
              {
                descriptor: yaml.parse(currentDescriptor),
                targetStatus: targetStatus ?? [
                  entityTarget,
                  ...componentTargets,
                  ...subcomponentsTargets,
                ],
                removeData: false,
                preventRedeploy: false,
              },
            );

          const rootComponentOperation = deploymentUnits.operations.find(
            op => op.componentId === rootComponentId,
          );

          const rootComponentFromStatus =
            deploymentUnitStatusState.value.provisioningDetails?.componentsStatus?.find(
              c =>
                c?.componentId ===
                generateURNByKind(
                  release.metadata.projectName ??
                    release.metadata.dataProductName,
                  release.metadata.projectKind ?? 'dp',
                ),
            );

          const rootComponent: DeploymentPreview = {
            kind: entity?.spec?.type ?? entity.kind,
            name: projectName,
            status:
              (rootComponentFromStatus?.status as DeploymentUnitStatus) ??
              DeploymentUnitStatus.NOT_DEPLOYED,
            statusVersion:
              rootComponentFromStatus?.descriptorVersion ??
              release.metadata.version,
            action: rootComponentOperation?.action,
            newState: rootComponentOperation?.targetStatus,
            id: rootComponentId,
            description: entity.metadata.description ?? '',
            fullyQualifiedName: null,
            version: release.metadata.version,
            infrastructureTemplateId: '',
            useCaseTemplateId: '',
            dependsOn: rootComponentOperation?.dependantOn ?? [],
            platform: '',
            technology: '',
            outputPortType: '',
            creationDate: new Date(release.metadata.createdAt),
            startDate: new Date(release.metadata.createdAt),
            processDescription: null,
            tags: entity.metadata.tags ?? [],
          };

          const subcomponents: Record<string, any>[] = components.flatMap(c =>
            (c.components ?? []).map(subcomponent => ({
              ...subcomponent,
              status:
                (deploymentUnitStatusState.value?.provisioningDetails?.componentsStatus.find(
                  child => child.componentId === `${c.id}:${subcomponent.name}`,
                )?.status as DeploymentUnitStatus) ?? c.status,
              statusVersion:
                deploymentUnitStatusState.value?.provisioningDetails?.componentsStatus.find(
                  child => child.componentId === `${c.id}:${subcomponent.name}`,
                )?.descriptorVersion ?? c.statusVersion,
              parent: c.id,
            })),
          );

          const fullComponents = components.map(c => ({
            ...c,
            components: subcomponents.filter(
              subcomponent => subcomponent.parent === c.id,
            ),
            dependsOn: [...c.dependsOn, rootComponentId],
          }));

          const newData = {
            previews: getMatchedComponents(
              fullComponents,
              deploymentUnits.operations,
            ),
            subcomponentsPreviews: getMatchedComponents(
              subcomponents,
              deploymentUnits.operations,
            ),
            notInTargetDescriptorOperations: getNotInTargetDescriptorOperations(
              deploymentUnits.operations,
            ).map(op => op.componentId),
            cleanUpOperations: getCleanUpOperations(
              deploymentUnits.operations,
            ).map(op => op.componentId),
            rootComponent: rootComponentOperation ? rootComponent : undefined,
            outcome: deploymentUnits.outcome,
          };

          setData(newData);
        } catch (e) {
          setError(e);
          return;
        } finally {
          setLoading(false);
        }
      }
    },
    [
      releaseDetailAction,
      deploymentUnitStatusState.value,
      entity.metadata.name,
      entity.metadata.description,
      entity.metadata.tags,
      entity.kind,
      entity?.spec?.type,
      components,
      panelCatalogApi,
      release.metadata.projectName,
      release.metadata.dataProductName,
      release.metadata.projectKind,
      release.metadata.version,
      release.metadata.createdAt,
      queryParamEnvironment,
      environment.name,
      currentDescriptor,
      projectName,
    ],
  );

  return { data, loadData, loading, error };
}
