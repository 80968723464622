import React from 'react';
import {
  EntitySearchTextFilter,
  UserListPicker,
  WbTableFilters,
  EntityOwnerPicker,
  EntityTypePicker,
  EntityTagPicker,
  EntityProcessingStatusPicker,
} from '@agilelab/plugin-wb-platform';
import { useEntityList } from '@backstage/plugin-catalog-react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';

interface Props {
  isSoftwareCatalog: boolean;
  filtersNode?: JSX.Element;
}

export const CatalogTableFilters: React.FC<Props> = ({
  isSoftwareCatalog,
  filtersNode,
}) => {
  const { filters, updateFilters } = useEntityList();
  const configApi = useApi(configApiRef);

  const filtersToRender = () => {
    if (filtersNode) return filtersNode;
    else if (isSoftwareCatalog)
      return (
        <>
          <UserListPicker />
          <EntityTagPicker />
          <EntityTypePicker />
          <EntityProcessingStatusPicker />
        </>
      );
    return (
      <>
        <UserListPicker />
        <EntityTypePicker />
        <EntityOwnerPicker />
      </>
    );
  };

  return (
    <WbTableFilters
      searchValue={filters.text?.value}
      onSearch={value => {
        updateFilters({ text: new EntitySearchTextFilter(value, configApi) });
      }}
    >
      {filtersToRender()}
    </WbTableFilters>
  );
};
