import _ from 'lodash';

/**
 * Recursively transforms the keys of an object or elements of an array by applying
 * a regular expression and replacing matched parts with a specified string.
 *
 * @param {Record<string, any> | any[]} obj - The object or array whose keys or elements will be transformed.
 * @param {RegExp} regex - The regular expression to match parts of the keys.
 * @param {string} replaceStr - The string to replace the matched parts with.
 * @returns {Record<string, any> | any[]} A new object or array with transformed keys.
 * * @example
 * const obj = {
 *   'key-1': {
 *     'key-2': 'value',
 *     'key-3': [{'key-4': 'value4'}, 'value2']
 *   }
 * };
 * const regex = /key-/g;
 * const replaceStr = 'newKey-';
 * console.log(transformKeys(obj, regex, replaceStr));
 * // Output:
 * // {
 * //   'newKey-1': {
 * //     'newKey-2': 'value',
 * //     'newKey-3': [{'newKey-4': 'value4'}, 'value2']
 * //   }
 * // }
 */
export function transformKeys(
  obj: Record<string, any> | any[],
  regex: RegExp,
  replaceStr: string,
): Record<string, any> | any[] {
  if (Array.isArray(obj)) {
    return obj.map(item => transformKeys(item, regex, replaceStr));
  } else if (typeof obj === 'object' && obj !== null) {
    return _.transform(
      obj,
      (result, value, key) => {
        const newKey = key.replace(regex, replaceStr);
        if (typeof value === 'object' && value !== null) {
          result[newKey] = transformKeys(value, regex, replaceStr);
        } else {
          result[newKey] = value;
        }
        return result;
      },
      {} as Record<string, any>,
    );
  }
  return obj;
}
