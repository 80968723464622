import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const platformSettingsPlugin = createPlugin({
  id: 'wb-platform-settings',
  routes: {
    root: rootRouteRef,
  },
});

/**
 * The Router and main entrypoint to the plugin.
 *
 * @public
 */
export const PlatformSettingsPage = platformSettingsPlugin.provide(
  createRoutableExtension({
    name: 'PlatformSettingsPage',
    component: () => import('./components/Router').then(m => m.Router),
    mountPoint: rootRouteRef,
  }),
);
