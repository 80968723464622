import { createRouteRef, createSubRouteRef } from '@backstage/core-plugin-api';

export const rootRouteRef = createRouteRef({
  id: 'wb-platform-settings',
});

export const practiceShaperRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-practice-shaper',
  parent: rootRouteRef,
  path: '/practice-shaper/*',
});

export const importPracticeShaperRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-practice-shaper-import',
  parent: rootRouteRef,
  path: '/practice-shaper/import',
});

export const graphPracticeShaperRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-practice-shaper-graph',
  parent: rootRouteRef,
  path: '/practice-shaper/graph',
});

export const supervisionRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-supervision',
  parent: rootRouteRef,
  path: '/supervision/*',
});

export const accessControlRouteRef = createSubRouteRef({
  id: 'wb-platform-settings-access-control',
  parent: rootRouteRef,
  path: '/access-control',
});
