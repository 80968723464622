import React, { useMemo } from 'react';
import { GenericEntityType, Provider, TextFieldValue } from '../../types';
import { SelectableEntityTable } from '../SelectableEntityTable';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { WbCardContent } from '../../../WbCard';
import { EntityKindSelector } from '../EntityKindSelector';
import { WbNoData } from '../../../WbNoData';
import { ProviderFilters } from '../CommonFilter';

const useStyles = makeStyles(
  () => ({
    cardContent: {
      overflow: 'auto',
      padding: '0px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
  { name: 'EntityListMultiPicker' },
);

const EntityTable = ({
  selectedKind,
  providerMapper,
  entities,
  setEntities,
}: {
  selectedKind: string;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  entities: TextFieldValue[];
  setEntities: React.Dispatch<React.SetStateAction<TextFieldValue[]>>;
}) => {
  const provider = providerMapper.get(selectedKind);
  const context = provider?.useContext();
  return (
    <SelectableEntityTable
      multiple
      columns={context?.columns ?? []}
      loading={context?.entitiesState.loading ?? false}
      data={(context?.entitiesState.value as GenericEntityType[]) ?? []}
      entities={entities}
      onChangeHandler={setEntities}
      filtersSection={provider?.filtersSection!}
      pagination={context?.pagination!}
      setPagination={context?.setPagination!}
      count={context?.count ?? 0}
      nextCursor={context?.nextCursor}
      prevCursor={context?.prevCursor}
      templateEntity={context?.templateKind!}
    />
  );
};

export const EntityList = ({
  availableKinds,
  selectedEntityProvider,
  setRenderTable,
  selectedKind,
  providerMapper,
  entities,
  setEntities,
}: {
  availableKinds: Record<string, any>[];
  selectedKind: string;
  providerMapper: Map<string, Provider<GenericEntityType[], ProviderFilters>>;
  entities: TextFieldValue[];
  setEntities: React.Dispatch<React.SetStateAction<TextFieldValue[]>>;
  selectedEntityProvider?: JSX.Element;
  setRenderTable: React.Dispatch<React.SetStateAction<JSX.Element | undefined>>;
}) => {
  const classes = useStyles();

  useMemo(() => {
    setRenderTable(
      <EntityTable
        selectedKind={selectedKind}
        providerMapper={providerMapper}
        entities={entities}
        setEntities={setEntities}
      />,
    );
  }, [entities, providerMapper, selectedKind, setEntities, setRenderTable]);

  return (
    <WbCardContent className={classes.cardContent}>
      <Box display="flex" justifyContent="space-between" paddingY="8px">
        <Typography variant="body1"> Search for entities</Typography>
        {availableKinds.length > 1 && (
          <EntityKindSelector availableKinds={availableKinds} />
        )}
      </Box>
      {selectedEntityProvider ?? <WbNoData />}
    </WbCardContent>
  );
};
