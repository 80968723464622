import { z } from 'zod';

export const ResourceIdentifierSchema = z.object({
  resourceExternalId: z.string(),
  environment: z.string(),
});

export type ResourceIdentifier = {
  resourceExternalId: string;
  environment: string;
};
