import { WitboostEntity } from '@agilelab/plugin-wb-builder-common';
import { RELATION_PART_OF } from '@backstage/catalog-model';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useEntity, useRelatedEntities } from '@backstage/plugin-catalog-react';
import React from 'react';
import useAsync from 'react-use/lib/useAsync';

import { DataContractCard } from './DataContractCard';
import { ApprovalsCard } from './ApprovalsCard';
import { DataContractEditorContextProvider } from './context/DataContractEditorContext';
import { DataSharingAgreementCard } from './DataSharingAgreementCard';
import { DataQualityCard } from './DataQualityCard';
import { configApiRef, fetchApiRef, useApi } from '@backstage/core-plugin-api';
import { ApprovalsCardIfOptionEnabled } from './ApprovalsCardIfOptionEnabled';

export type Tag = {
  tagFQN: string;
  source?: string;
  labelType?: string;
  state?: string;
};

export type Schema = {
  name: string;
  dataType: string;
  description?: string;
  tags?: Tag[];
}[];

export interface WitBoostOutputPort extends WitboostEntity {
  spec: {
    mesh: {
      name: string;
      dataContract?: { schema: Schema } & Record<string, any>;
      approvals?: string[];
      quality?: Record<string, any>[];
    } & Record<string, any>;
  } & Record<string, any>;
}

export function DataContractEditor() {
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, {
    type: RELATION_PART_OF,
    kind: 'system',
  });
  const configApi = useApi(configApiRef);
  const fetchApi = useApi(fetchApiRef);
  const realDpApproving = configApi.getOptionalBoolean(
    'mesh.builder.realDpApproving',
  );

  const {
    loading: loadingBusinessTerms,
    error: errorBusinessTerms,
    value: businessTerms,
  } = useAsync(async () => {
    const response = await fetchApi.fetch(
      `https://schemaassistant.azurewebsites.net/api/BusinessTermsList?code=1Wm2Bio3QPrDkGCjL84Jsc1b1ADHvLrhLek2S7SDp-cvAzFuGkyldA==`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    const fetchedTerms = await response.json();
    return fetchedTerms as string[];
  }, []);

  if (error || errorBusinessTerms) {
    return <ResponseErrorPanel error={error || errorBusinessTerms!} />;
  }

  if (entities && entities.length < 1) {
    return (
      <ResponseErrorPanel error={new Error('No Dataproduct associated')} />
    );
  }

  if (loading || loadingBusinessTerms || !entities || !businessTerms) {
    return <Progress />;
  }

  return (
    <DataContractEditorContextProvider entity={entity as WitBoostOutputPort}>
      {realDpApproving ? (
        <ApprovalsCardIfOptionEnabled component={entity.metadata.name} />
      ) : (
        <ApprovalsCard />
      )}
      <DataContractCard
        dataProduct={entities[0] as WitboostEntity}
        businessTerms={businessTerms}
      />
      <DataSharingAgreementCard />
      <DataQualityCard />
    </DataContractEditorContextProvider>
  );
}
